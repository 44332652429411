/**
 * 一体化,关贸云 业务操作类型 对应的菜单
 */
export enum EnumYitiHuaCustomType {
  LiaojianLiuZhuan = '料件流转',
  WAIFAJIAGONG = '外发加工',
  SHIYANGONGDAN= '试验工单',
  AOGONGDAN= 'AO工单'
}

/**
 * 一体化 两地的标志 对应账号不一样
 */
export enum EnumYitiHuaAreaType {
  PUDONG = '浦东',
  DACHANG = '大场',
}



export enum orgAreaType {
  J = '104622',
  D = '104623',
}
