import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent, NzDemoTabContentLazyComponent } from './login/login.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    NzTabsModule,
    NzButtonModule,
    NzFormModule,
    NzInputModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [LoginComponent,NzDemoTabContentLazyComponent],
  exports: [LoginComponent],
})
export class SharedLoginModule {}
