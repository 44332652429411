export const menuDef = [
  {
    iconType: 'global',
    label: '报关业务',
    memo: 'newAEO',

    submenuFlag: true,
    submenus: [
      {
        iconType: 'mail',
        label: '委托',
        memo: '邮件委托',
        route: 'mail-erp-manage/list',
        fragment: '委托',
      },
      {
        iconType: 'ordermain',
        label: '接单',
        memo: '接单',
        route: 'ordermain',
        fragment: '接单',
      },
      {
        iconType: 'kddpreorder',
        label: '制单',
        memo: '制单',
        route: 'kddpreorder/pre-dec-list',
        fragment: '制单',
      },
      {
        iconType: 'shendan',
        label: '初审',
        memo: '初审',
        route: 'shendan/shendan-list',
        fragment: '初审',
      },
      {
        iconType: 'shendan',
        label: '复审',
        memo: '复审',
        route: 'shendan/fushendan-list',
        fragment: '复审',
      },

     
      {
        iconType: 'mail',
        label: '报关',
        memo: '单一窗口',
        route: 'singlewindow/list',
        fragment: '报关',
      },
      {
        iconType: 'setting',
        label: '商检',
        memo: '商检记录',
        route: 'dec-track/shangjian-list',
        fragment: '商检台账',
      },
      {
        iconType: 'setting',
        label: '快件',
        memo: '快件出口',
        route: 'express-manage/list',
        fragment: '快件出口',
      },
    ],
  },
  {
    iconType: 'send',
    label: '上飞SCM',
    memo: 'SCM',
    submenuFlag: true,
    submenus: [
      {
        iconType: 'mail',
        label: '委托邮件',
        memo: '委托邮件',
        route: 'mail-erp-manage/attorney-list',
        fragment: '委托邮件',
      },
      {
        iconType: 'environment',
        label: '业务跟踪',
        memo: '业务跟踪',
        route: 'scm-track/scm-samc-track-info',
        fragment: '业务跟踪（清关）',
      },
      {
        iconType: 'environment',
        label: '业务跟踪（收货）',
        memo: '业务跟踪（收货）',
        route: "scm-track/scm-samc-track-info",
        fragment: '业务跟踪（收货）',
      },
      {
        iconType: 'file-text',
        label: '报关单',
        memo: '单一窗口',
        route: 'singlewindow/attorney-list',
        fragment: '报关单',
      },
      {
        iconType: 'mail',
        label: '委托复审',
        memo: '委托复审',
        route: 'custshendan/custlist',
        fragment: '',
      },
      {
        iconType: 'setting',
        label: '归档资料',
        memo: '',
        route: 'singledoc/attorney-list',
        fragment: '归档资料',
      },
      {
        iconType: 'mail',
        label: '退单',
        memo: '退单明细',
        route: 'hgreject',
        fragment: '退单明细',
      },
    ],
  },
  {
    iconType: 'security-scan',
    label: '质量控制',
    memo: '异常管理',
    submenuFlag: true,
    submenus: [
      {
        iconType: 'mail',
        label: '放行跟踪',
        memo: '放行跟踪',
        route: 'dec-track/list',
        fragment: '放行跟踪',
      },
      {
        iconType: 'mail',
        label: '删改单',
        memo: '删改单',
        route: 'modify',
        fragment: '删改单',
      },
      {
        iconType: 'mail',
        label: '退单',
        memo: '退单明细',
        route: 'hgreject',
        fragment: '退单明细',
      },
      {
        iconType: 'mail',
        label: '查验',
        memo: '查验台账',
        route: 'hgcheck',
        fragment: '查验台账',
      },
      {
        iconType: 'mail',
        label: '扣分',
        memo: '扣分',
        route: 'chacuo',
        fragment: '扣分',
      },
    ],
  },

  {
    iconType: 'deployment-unit',
    label: '一体化',
    memo: '一体化',
    submenuFlag: true,
    submenus: [
     

      {
        iconType: 'setting',
        label: '关贸云商品库',
        memo: '',
        route: 'net-transit/productlist',
        fragment: '关贸云商品库',
      },
      {
        iconType: 'setting',
        label: '翔运修订商品',
        memo: '',
        route: 'net-transit/refixlist',
        fragment: '翔运修订商品',
      },
      {
        iconType: 'setting',
        label: '区内流转',
        memo: '',
        route: 'net-transit/list',
        fragment: '区内流转',
      },
      {
        iconType: 'setting',
        label: '提发货',
        memo: '',
        route: 'dec-track/special',
        fragment: '区内流转',
      },
      {
        iconType: 'setting',
        label: '卡口验放',
        memo: '',
        route: 'special-area/port-release',
        fragment: '卡口验放',
      },
      {
        iconType: 'setting',
        label: '提发货凭证',
        memo: '',
        route: 'special-area/warehouse',
        fragment: '提发货凭证',
      },

      {
        iconType: 'setting',
        label: '库存汇总',
        memo: '',
        route: 'special-area/inventory-group',
        fragment: '库存汇总',
      },
      {
        iconType: 'setting',
        label: '库存明细',
        memo: '',
        route: 'special-area/inventory',
        fragment: '库存明细',
      },
      {
        iconType: 'setting',
        label: '异常汇总',
        memo: '',
        route: 'special-area/excption-statistics',
        fragment: '特殊监管区域异常汇总',
      },
    ],
  },

  {
    iconType: 'database',
    label: '知识库',
    memo: '知识库',
    submenuFlag: true,
    submenus: [
      {
        iconType: 'setting',
        label: '产品库',
        memo: '',
        route: 'productmanage/productmanageList',
        fragment: '产品库',
      },
      {
        iconType: 'setting',
        label: '重装目录',
        memo: '重装目录',
        route: 'heavyeqp/list',
        fragment: '重装目录',
      },
      {
        iconType: 'setting',
        label: '税则办证',
        memo: '税则办证',
        route: 'tms/hsCustom-regulation',
        fragment: '税则办证',
      },
      {
        iconType: 'setting',
        label: '模板',
        memo: '模板管理',
        route: 'kdd-pre-order-template/list',
        fragment: '模板管理',
      },
      {
        iconType: 'setting',
        label: '归档资料',
        memo: '',
        route: 'singledoc/list',
        fragment: '归档资料',
      },
      {
        iconType: '',
        label: '校验规则',
        memo: '',
        route: 'validationrule',
        fragment: '校验规则',
      },
      {
        iconType: 'setting',
        label: '税则预警',
        memo: '税则预警',
        route: 'hsregex',
        fragment: '税则预警',
      },
      {
        iconType: 'setting',
        label: '文件管理',
        memo: '文件管理',
        route: 'file-manage/list',
        fragment: '文件管理',
      },
      {
        iconType: 'setting',
        label: '合同管理',
        memo: '合同管理',
        route: 'purchase-contract',
        fragment: '合同管理',
      },
    ],
  },

  {
    iconType: 'tool',
    label: 'TMS',
    memo: 'TMS',

    submenuFlag: true,
    submenus: [
      {
        iconType: 'tms',
        label: '港区待提货',
        memo: '放行未提货清单和已提货的清单',
        route: 'tms/gen-tms',
        fragment: '港区待提货',
      },
      {
        iconType: 'tms',
        label: '上飞收货计划 ',
        memo: '放行未提货清单和已提货的清单',
        route: 'tms/comac-gen-tms',
        fragment: '上飞收货计划 ',
      },
      {
        iconType: 'tms',
        label: '运输委托',
        memo: '运输委托',
        route: 'tms/trans-apply',
        fragment: '提货委托',
      },
      {
        iconType: 'tms',
        label: '便捷管理',
        memo: '便捷管理',
        route: 'tms/pass-portAssign',
        fragment: '便捷管理',
      },
      {
        iconType: 'tms',
        label: '便捷委托',
        memo: '便捷委托',
        route: 'tms/pass-portOrder',
        fragment: '便捷委托',
      },
     
    ],
  },

  {
    iconType: 'tool',
    label: 'WMS',
    memo: 'WMS',

    submenuFlag: true,
    submenus: [
      {
        iconType: 'RPA',
        label: '待进仓清单',
        memo: '放行未提货清单和已提货的清单',
        route: 'wms/inward-plan',
        fragment: 'Tasks',
      },
      {
        iconType: 'RPA',
        label: '库存查询',
        memo: '查看当前库存',
        route: 'wms/stocklist',
        fragment: 'Jobs',
      },

     
    ],
  },

  {
    iconType: 'tool',
    label: 'RPA',
    memo: 'RPA',

    submenuFlag: true,
    submenus: [
      {
        iconType: 'RPA',
        label: 'Jobs',
        memo: 'RPA',
        route: 'rpa/list',
        fragment: 'Jobs',
      },
      {
        iconType: 'RPA',
        label: 'Tasks',
        memo: '接单处理',
        route: 'rpa-job-info/tasklist',
        fragment: 'Tasks',
      },
    ],
  },

  {
    iconType: 'team',
    label: '往来单位',
    memo: 'AEO其他',
    submenuFlag: true,
    submenus: [
      {
        iconType: '',
        label: '客户',
        memo: '',
        route: 'company',
        fragment: '客户管理',
      },
      {
        iconType: '',
        label: '供应商',
        memo: '',
        route: 'supplier',
        fragment: '供应商管理',
      },
      {
        iconType: '',
        label: '企业',
        memo: '',
        route: 'app-admin/company-list',
        fragment: '企业管理',
      },
      {
        iconType: '',
        label: '货主',
        memo: '',
        route: 'owner',
        fragment: '货主管理',
      },
    ],
  },
  {
    iconType: 'money-collect',
    label: '账务管理',
    memo: '对账单管理',
    submenuFlag: true,
    submenus: [
      {
        iconType: 'money-collect',
        label: '对账清单',
        memo: '对账单',
        route: 'kdd-pre-order/singleWindowDataList',
        fragment: '对账清单',
      },
      {
        iconType: 'setting',
        label: '成本费用',
        memo: '',
        route: 'kdd-pre-order/singleWindowDataList',
        fragment: '成本费用',
      },
      // {
      //   iconType: 'setting',
      //   label: '合同管理',
      //   memo: '合同管理',
      //   route: 'kdd-pre-order/singleWindowDataList',
      //   fragment: '合同管理',
      // },
      {
        iconType: 'setting',
        label: '税单管理',
        memo: '税单管理',
        route: 'taxinfo',
        fragment: '税单管理',
      },
    ],
  },
  {
    iconType: 'bar-chart',
    label: '统计分析',
    memo: '统计分析',
    submenuFlag: true,
    submenus: [
      {
        iconType: '',
        label: '删改单',
        memo: '删改单分析',
        route: 'modify/stats',
        fragment: '删改单分析',
      },
      {
        iconType: '',
        label: '退单分析',
        memo: '',
        route: 'hgreject/stats',
        fragment: '退单分析',
      },
      {
        iconType: 'setting',
        label: '查验分析',
        memo: '',
        route: 'hgcheck/stats',
        fragment: '查验分析',
      },
      {
        iconType: 'setting',
        label: '差错分析',
        memo: '',
        route: 'chacuo/stats',
        fragment: '差错分析',
      },
      {
        iconType: 'setting',
        label: '通关时效分析',
        memo: '时效分析',
        route: 'dec-track/stats',
        fragment: '时效分析',
      },

      // {
      //   iconType: 'setting',
      //   label: '海关查验',
      //   memo: '海关查验',
      //   route: 'hgcheck/stats',
      //   fragment: '海关查验',
      // },
      // {
      //   iconType: 'setting',
      //   label: '海关退单',
      //   memo: '海关查验',
      //   route: 'hgreject/stats',
      //   fragment: '海关退单',
    ],
  },

  {
    iconType: 'setting',
    label: '基础设置',
    memo: '系统设置',
    submenuFlag: true,
    submenus: [
      // {
      //   iconType: 'setting',
      //   label: '用户',
      //   memo: '用户管理',
      //   route: 'common/app-user-list',
      //   fragment: '用户',
      // },
      // {
      //   iconType: 'setting',
      //   label: '权限管理',
      //   memo: '角色权限管理',
      //   route: 'common/app-user-list',
      //   fragment: '用户',
      // },
      {
        iconType: 'setting',
        label: '操作日志',
        memo: '日志管理',
        route: 'logs',
        fragment: '日志管理',
      },
      {
        iconType: 'setting',
        label: '用户中心',
        memo: '用户中心',
        route: 'profile',
        fragment: '用户中心',
      },
      {
        iconType: '',
        label: '权限',
        memo: '权限',
        route: 'app-admin/role-policy-list',
        fragment: '权限',
      },
    ],
  },

  {
    iconType: 'gateway',
    label: '围网业务',
    memo: '围网业务',
    submenuFlag: true,
    submenus: [
      {
        iconType: '',
        label: '提发货凭证',
        memo: '提发货凭证',
        route: 'wareHouse/list',
        fragment: '提发货凭证',
      },
    ],
  },
];
