import { comac,KeyValue } from '@ternsorinfo/data';

export interface IMailNlpResult {
  supplier: string;
  billno: string;
  contractNo: string;
  supplierOrigin?: string;
  billnoOrigin?: string;
  contractNoOrigin?: string;
  attachFiles?: KeyValue[];
}

export enum NlpMailInfoClassifyType {
  SupplierName = 'supplier',
  OrderType = 'orderType', //进仓出仓
  ContractNo = 'contractNo', //合同号
  BillNo = 'billNo', //
  IeDate = 'iedate',
  Other = 'other', // 没分出来的。
  MAWB = '总运单号',
}

export function splitBillNo(strinput: string): IMailNlpResult[] {
  const result: IMailNlpResult[] = [];
  const billnos = getBillNo(strinput);

  for (let index = 0; index < billnos.length; index++) {
    const element = billnos[index];
    result.push({
      supplier: '',
      billno: element,
      contractNo: '',
      attachFiles: [],
    });
  }
  if (result.length == 0) {
    result.push({
      supplier: '',
      billno: strinput,
      contractNo: '',
      attachFiles: [],
    });
  }
  return result;
}

export function getBillNo(strinput: string): string[] {
  const regex = /[\w-]+/gm;

  console.log(strinput);
  let m;
  let result: string[] = [];

  let mawb = '';
  while ((m = regex.exec(strinput)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches

    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    // The result can be accessed through the `m`-variable.
    m.forEach((match, groupIndex) => {
      let nostr = formatBillNo(match);

      let billType = judgeType(nostr);

      switch (billType) {
        case NlpMailInfoClassifyType.MAWB:
        //  mawb = nostr;
          break;
        case NlpMailInfoClassifyType.BillNo:
          result.push(nostr);

          break;
      }
    });
  }
  return result;
}

export function formatBillNo(billNo: string): string {
  const regex = /^[-]|[-]$/gm;

  return billNo.replace(regex, '');
}

export function judgeType(inputstr: string): NlpMailInfoClassifyType {
  console.log(inputstr, NlpMailInfoClassifyType.ContractNo);
  const regex = /\d/gm;
  const mawbRegex = /\d{3}-{1}\d{8}/gm;

  if (
    inputstr.toUpperCase().startsWith('SAMC') ||
    inputstr.toUpperCase().startsWith('-SAMC')
  ) {
    console.log(inputstr, NlpMailInfoClassifyType.ContractNo);

    return NlpMailInfoClassifyType.ContractNo;
  } else if (inputstr.match(mawbRegex)) {
    return NlpMailInfoClassifyType.MAWB;
  } else if (inputstr.length < 7) {
    return NlpMailInfoClassifyType.Other;
  } else if (inputstr.match(regex)) {
    return NlpMailInfoClassifyType.BillNo;
  } else {
    return NlpMailInfoClassifyType.Other;
  }
}

export function autoSelectAttachments(
  billnos: IMailNlpResult[],
  attatchments: comac.KeyValue[]
): IMailNlpResult[] {


  const regex = /\s/gm;
  if (billnos.length == 1) {
    billnos[0].attachFiles = JSON.parse(JSON.stringify(attatchments));
    console.log();
  } else {
    billnos.forEach((element) => {
        console.debug('mail billNo',element)
      const zz = attatchments.filter(
        (ele) => ele.name.replace(regex,'').indexOf(element.billno) >= 0
      );
      element.attachFiles = JSON.parse(JSON.stringify(zz));
    });
  }

  return billnos;
}
