//判断单位是否计件
export function getunitinfo(convertunit) {
  let localmes = sessionStorage.getItem('unit');
  let localpara = [];
  if (localmes) {
    localpara = JSON.parse(localmes);
  }
  var uninfo = localpara.filter((p) => {
    return p.code == convertunit;
  });
  if (uninfo && uninfo.length > 0) {
    return uninfo[0];
  } else {
    return null;
  }
}

/**
 * round(10.003.2)= 10.00
 * @param number input numb
 * @param precision  fix precision
 */
export function round(number: number, precision: number) {
  const result =
    Math.round(parseFloat(+number + 'e' + precision)) / Math.pow(10, precision);
  return isNaN(result) ? 0 : result;
  //same as:
  // return Number(Math.round(+number.toString() + 'e' + precision.toString()) + 'e-' + precision);
}

export function getQtybyUnit(
  pcs: any,
  convertunit: string,
  netweight: any,
  hscode: any
) {
  //重量
  //pcs
  //体积
  var result = 0;
  console.log('getQtybyUnit');
  var re = new RegExp('\\[(\\d*)\\]');
  if (convertunit) {
    var modelval = convertunit.match(re);
    if (modelval) {
      convertunit = modelval[1];
    }
  }
  else {
    convertunit = ''
  }
  // var modelval = convertunit.match(re);
  // if (modelval) {
  //   convertunit = modelval[1];
  // }
  if (convertunit == '000' || convertunit == '') {
    return 0;
  }
  var unitinfo = getunitinfo(convertunit);

  if (unitinfo) {
    if (unitinfo.remark3 == 'piece') {
      if (!unitinfo.ciqCode) {
        result = pcs;
      } else {
        result = pcs / unitinfo.ciqCode;
      }
    } else if (unitinfo.remark3 == 'weight') {
      if (!unitinfo.ciqCode) {
        result = netweight;
      } else {
        result = netweight / unitinfo.ciqCode;
      }
    } else {
      switch (convertunit.trim()) {
        case '095':
          // if (hscode.startsWith("27101999"))
          //     result = netweight * 1.015;
          // else
          result = pcs;
          //  result = netweight * 1.126m;

          break;
        case '030':
          if (hscode == '8544421100') {
            result = Math.ceil(netweight / 0.3);
          } else {
            result = 0;
          }
          break;
        default:
          if (convertunit === '') result = 0;
          else result = 0; // pcs;
          break;
      }
    }
  } else {
    if (convertunit == '035') {
      result = netweight;
    }
  }
  return result;
}

export function sum(sourcelist, arg = '') {
  var slen = sourcelist.length;
  var amount = 0;
  while (slen--) {
    if (arg) {
      if (sourcelist[slen][arg]) {
        amount += Number(sourcelist[slen][arg].toString());
      }
    } else {
      if (sourcelist[slen]) {
        amount += Number(sourcelist[slen].toString());
      }
    }
  }
  //.toFixed(5)
  return round(amount, 5).toString();
}

//判断是否为数字
export function judgenum(numstr) {
  var reg = /^[0-9]+.?[0-9]*$/;
  var paten = new RegExp(reg);
  return paten.test(numstr);
}

export function decsort(formlist) {
  // formlist = formlist.sort(by('g_no'));
  var fqxflag = []; //反倾销 flag

  var oaflag = []; //oa flag
  var aflag = [];
  var cflag = [];
  var left = [];
  var free = [];
  var dianq = [];
  var nonefree = [];

  var oldarray = []; //旧商品
  //_.endsWith(q1[ap].HSCode, '00')
  // decMerge.ControlMark = prodinfo.controlmark;
  // decMerge.TarifFlag = prodinfo.tarifflag;
  var larray = [];
  var nonlarray = [];
  //不需要按照重装目录排序
  // formlist = formlist.sort(by('firstlevel'));

  var specCCC = ['8538900000','8518400090','8504401400'];

  formlist.forEach((fl) => {
    if (fl.gName.indexOf('垫圈') != -1) {
      if (!fl.freeFlag || fl.freeFlag == '免表') {
        fl.freeFlag = '缴税';
      }
    }
    if (
      (fl.TarifFlag && fl.TarifFlag.indexOf('L') != -1) || specCCC.indexOf(fl.codeTs)!=-1
    ) {
      larray.push(fl);
    } else {
      nonlarray.push(fl);
    }
  });
  var lresult = larray.concat(nonlarray);
  lresult.forEach((fl) => {
    if (fl.freeFlag == '免缴') {
      free.push(fl);
    } else if (fl.freeFlag == '缴税') {
      nonefree.push(fl);
    } else {
      if (
        fl.gModel &&
        fl.gModel.indexOf('|<') != -1 &&
        fl.gModel.indexOf('>') != -1
      ) {
        fqxflag.push(fl);
      } else if (
        fl.ControlMark &&
        fl.ControlMark.indexOf('A') != -1 &&
        fl.ControlMark.indexOf('O') != -1
      ) {
        oaflag.push(fl);
      } else if (fl.ControlMark && fl.ControlMark.indexOf('A') != -1) {
        aflag.push(fl);
      } else {
        left.push(fl);
      }
    }
  });
  var result = fqxflag
    .concat(oaflag)
    .concat(aflag)
    .concat(cflag)
    .concat(left)
    .concat(nonefree)
    .concat(free);
  return result;
}

export function desby(name: any) {
  return function (o: any, p: any) {
    var a, b;
    if (typeof o === 'object' && typeof p === 'object' && o && p) {
      a = o[name];
      b = p[name];
      if (a === b) {
        return 0;
      }
      if (typeof a === typeof b) {
        if (isNaN(a)) {
          return a > b ? -1 : 1;
        } else {
          return Number(a) > Number(b) ? -1 : 1;
        }
      }
      return typeof a > typeof b ? -1 : 1;
    } else {
      throw 'error';
    }
  };
}

//使用品名备案合并排序
export function memocombinesort(decMergeListVo: any, groupkey) {
  console.log('合并排序');

  // "不合并", "忽略合同", "忽略材质"
  var groupargs = [groupkey];
  var mlist = JSON.parse(JSON.stringify(decMergeListVo));
  mlist.map((ml) => {
    // if (ml.freeFlag != "免缴") {
    //     ml.freeFlag = "缴税";
    // }
    if (!ml.freeFlag) {
      ml.freeFlag = '免表';
    }
    ml.confidence = '1';
    if (ml.loctioninfo) {
      ml.loctioninfo.map((eloc) => {
        eloc.confidence = 1;
      });
    }
  });
  var combineresult = memocmbcombine(mlist, groupargs);
  // var sortresult = decsort(combineresult);
  // var sortresult = combineresult.sort(by('namememo'));
  var sortresult = combineresult;
  for (var z = 0; z < sortresult.length; z++) {
    sortresult[z].gNo = z + 1;
  }
  var cmbdecMergeListVo = sortresult;
  return cmbdecMergeListVo;
}

//使用品名备案合并排序  上限500
export function limitmemocombinesort(decMergeListVo: any, groupkey, limitnum) {
  console.log('limitmemocombinesort    合并排序');

  // "不合并", "忽略合同", "忽略材质"
  var groupargs = [groupkey];
  var mlist = JSON.parse(JSON.stringify(decMergeListVo));
  mlist.map((ml) => {
    // if (ml.freeFlag != "免缴") {
    //     ml.freeFlag = "缴税";
    // }
    if (!ml.freeFlag) {
      ml.freeFlag = '免表';
    }
    ml.confidence = '1';
    if (ml.loctioninfo) {
      ml.loctioninfo.map((eloc) => {
        eloc.confidence = 1;
      });
    }
  });
  var combineresult = limitmemocmbcombine(mlist, groupargs, limitnum);
  // var sortresult = decsort(combineresult);
  // var sortresult = combineresult.sort(by('namememo'));
  var sortresult = combineresult;
  for (var z = 0; z < sortresult.length; z++) {
    sortresult[z].gNo = z + 1;
  }
  var cmbdecMergeListVo = sortresult;
  return cmbdecMergeListVo;
}

export function limitmemocmbcombine(decMergeListVo, groupargs, limitnum) {
  try {
    if (decMergeListVo) {
      for (var i = 0; i < decMergeListVo.length; i++) {
        var decMerge = decMergeListVo[i];
        if (!decMerge.declPrice || decMerge.declPrice == 'Infinity') {
          decMerge.realdeclTotal = decMerge.declTotal;
          decMerge.declPrice = Number(decMerge.declTotal / decMerge.qty);
        }
      }
    }

    // var ignorepat = [",", ":", "：", "，", "。", ";", "；"];
    var ignorepat = /[,|:|：|，|。|;|；]/;

    // var groupargs = data.groupargs;
    var origroupresult = cmbgroupBy(decMergeListVo, groupargs);
    var groupresult = [];
    for (var i = 0; i < origroupresult.length; i++) {
      if (origroupresult[i].length <= limitnum) {
        groupresult.push(origroupresult[i]);
      } else {
        //安装limitnum切割数组
        origroupresult[i].slice(0, limitnum);
        var l = 0;
        var slicels = origroupresult[i].slice(l, limitnum);
        while (slicels.length > 0) {
          groupresult.push(JSON.parse(JSON.stringify(slicels)));
          l++;
          slicels = origroupresult[i].slice(l * limitnum, (l + 1) * limitnum);
        }
      }
    }
    var flgroup = [];
    var gr = groupresult.length;
    var grlen = groupresult.length;
    //limitnum 一次上限500

    var befindex = 1;
    while (gr--) {
      var flz: any = {};
      for (var key in groupresult[grlen - gr - 1][0]) {
        if (key.indexOf(',') == -1) {
          flz[key] = groupresult[grlen - gr - 1][0][key];
        }
      }
      flz.extendjson = {};
      flz.extendjson.befindex =
        befindex + '-' + (befindex + groupresult[grlen - gr - 1].length);
      befindex = befindex + groupresult[grlen - gr - 1].length + 1;
      flz.gQty = sum(groupresult[grlen - gr - 1], 'gQty');
      flz.declTotal = Number(
        sum(groupresult[grlen - gr - 1], 'declTotal')
      ).toFixed(2);
      flz.realdeclTotal = Number(
        sum(groupresult[grlen - gr - 1], 'realdeclTotal')
      ).toFixed(2);

      flz.qty1 = sum(groupresult[grlen - gr - 1], 'qty1');
      if (flz.unit2Name) {
        flz.qty2 = sum(groupresult[grlen - gr - 1], 'qty2');
      }

      if (flz.netWeight) {
        flz.netWeight = sum(groupresult[grlen - gr - 1], 'netWeight');
        //合并后的净重舍去保留两位小数
        //上次修改作废 合并后至少取三位小数
        if (flz.netWeight.indexOf('.') != -1) {
          var qnet = flz.netWeight.substr(flz.netWeight.indexOf('.') + 1);
          if (qnet.length < 3) {
            flz.netWeight = parseFloat(flz.netWeight).toFixed(3);
          }
        }
      }
      if (flz.qty) {
        flz.qty = sum(groupresult[grlen - gr - 1], 'qty');
      }

      var priceqty = flz.gQty;
      if (priceqty == 0) {
        priceqty = flz.qty;
      }
      flz.declPrice = Number(flz.declTotal / priceqty).toFixed(4);
      if (flz.secondQty == 0) {
        flz.secondQty = '';
      }
      // var slen = groupresult[grlen - gr - 1].length;
      var slen = 0;
      var combinebf = [];
      // 需要正排
      while (slen < groupresult[grlen - gr - 1].length) {
        var cbf: any = {};
        cbf.gNo = groupresult[grlen - gr - 1][slen].gNo;
        cbf.sku = groupresult[grlen - gr - 1][slen].sku;
        cbf.qty = groupresult[grlen - gr - 1][slen].qty;
        cbf.netWeight = groupresult[grlen - gr - 1][slen].netWeight;
        cbf.declPrice = groupresult[grlen - gr - 1][slen].declPrice;
        cbf.declTotal = groupresult[grlen - gr - 1][slen].declTotal;
        cbf.contrNo = groupresult[grlen - gr - 1][slen].contrNo;

        //需要合并前的更多信息
        cbf.gQty = groupresult[grlen - gr - 1][slen].gQty;
        cbf.gUnit = groupresult[grlen - gr - 1][slen].gUnit;
        cbf.qty1 = groupresult[grlen - gr - 1][slen].qty1;
        cbf.unit1 = groupresult[grlen - gr - 1][slen].unit1;
        cbf.qty2 = groupresult[grlen - gr - 1][slen].qty2;
        cbf.unit2 = groupresult[grlen - gr - 1][slen].unit2;
        cbf.gName = groupresult[grlen - gr - 1][slen].gName;
        cbf.gModel = groupresult[grlen - gr - 1][slen].gModel;
        cbf.mbgModel = groupresult[grlen - gr - 1][slen].mbgModel;
        cbf.productid= groupresult[grlen - gr - 1][slen].productid;

        combinebf.push(cbf);
        slen++;
      }
      flz.combinebf = JSON.stringify(combinebf);

      // mulwithcombef(flz, combinebf);
      delete flz.combgname;
      delete flz.combgModel;
      flgroup.push(flz);
    }
    return flgroup;
  } catch (e) {
    console.log('combine', e);
    return [];
  }
}

export function memocmbcombine(decMergeListVo, groupargs) {
  try {
    if (decMergeListVo) {
      for (var i = 0; i < decMergeListVo.length; i++) {
        var decMerge = decMergeListVo[i];
        if (!decMerge.declPrice || decMerge.declPrice == 'Infinity') {
          decMerge.realdeclTotal = decMerge.declTotal;
          decMerge.declPrice = Number(decMerge.declTotal / decMerge.qty);
        }
      }
    }

    // var ignorepat = [",", ":", "：", "，", "。", ";", "；"];
    var ignorepat = /[,|:|：|，|。|;|；]/;

    // var groupargs = data.groupargs;
    var groupresult = cmbgroupBy(decMergeListVo, groupargs);

    var flgroup = [];
    var gr = groupresult.length;
    var grlen = groupresult.length;

    var totalnet = 0;
    while (gr--) {
      var flz: any = {};
      for (var key in groupresult[grlen - gr - 1][0]) {
        if (key.indexOf(',') == -1) {
          flz[key] = groupresult[grlen - gr - 1][0][key];
        }
      }
      flz.gQty = sum(groupresult[grlen - gr - 1], 'gQty');
      flz.declTotal = Number(
        sum(groupresult[grlen - gr - 1], 'declTotal')
      ).toFixed(2);
      flz.realdeclTotal = Number(
        sum(groupresult[grlen - gr - 1], 'realdeclTotal')
      ).toFixed(2);

      flz.qty1 = sum(groupresult[grlen - gr - 1], 'qty1');
      if (flz.unit2Name) {
        flz.qty2 = sum(groupresult[grlen - gr - 1], 'qty2');
      }

      if (flz.netWeight) {
        flz.netWeight = sum(groupresult[grlen - gr - 1], 'netWeight');
        //合并后的净重舍去保留两位小数
        //上次修改作废 合并后至少取三位小数
        if (flz.netWeight.indexOf('.') != -1) {
          var qnet = flz.netWeight.substr(flz.netWeight.indexOf('.') + 1);
          if (qnet.length < 3) {
            flz.netWeight = parseFloat(flz.netWeight).toFixed(3);
          }
        }
        // flz.netWeight = (parseInt((parseFloat(flz.netWeight) * 100).toString()) / 100).toString()
        totalnet += Number(flz.netWeight);
      }
      if (flz.qty) {
        flz.qty = sum(groupresult[grlen - gr - 1], 'qty');
      }

      var priceqty = flz.gQty;
      if (priceqty == 0) {
        priceqty = flz.qty;
      }
      flz.declPrice = Number(flz.declTotal / priceqty).toFixed(4);
      if (flz.secondQty == 0) {
        flz.secondQty = '';
      }
      // var slen = groupresult[grlen - gr - 1].length;
      var slen = 0;
      var combinebf = [];
      // 需要正排
      while (slen < groupresult[grlen - gr - 1].length) {
        var cbf: any = {};
        cbf.gNo = groupresult[grlen - gr - 1][slen].gNo;
        cbf.sku = groupresult[grlen - gr - 1][slen].sku;
        cbf.qty = groupresult[grlen - gr - 1][slen].qty;
        cbf.netWeight = groupresult[grlen - gr - 1][slen].netWeight;
        cbf.declPrice = groupresult[grlen - gr - 1][slen].declPrice;
        cbf.declTotal = groupresult[grlen - gr - 1][slen].declTotal;
        cbf.contrNo = groupresult[grlen - gr - 1][slen].contrNo;

        //需要合并前的更多信息
        cbf.gQty = groupresult[grlen - gr - 1][slen].gQty;
        cbf.gUnit = groupresult[grlen - gr - 1][slen].gUnit;
        cbf.qty1 = groupresult[grlen - gr - 1][slen].qty1;
        cbf.unit1 = groupresult[grlen - gr - 1][slen].unit1;
        cbf.qty2 = groupresult[grlen - gr - 1][slen].qty2;
        cbf.unit2 = groupresult[grlen - gr - 1][slen].unit2;
        cbf.gName = groupresult[grlen - gr - 1][slen].gName;
        cbf.gModel = groupresult[grlen - gr - 1][slen].gModel;
        cbf.mbgModel = groupresult[grlen - gr - 1][slen].mbgModel;
        cbf.productid= groupresult[grlen - gr - 1][slen].productid;

        combinebf.push(cbf);
        slen++;
      }
      flz.combinebf = JSON.stringify(combinebf);
      mulwithcombef(flz, combinebf);
      delete flz.combgname;
      delete flz.combgModel;
      flgroup.push(flz);
    }
    return flgroup;
  } catch (e) {
    console.log('combine', e);
    return [];
  }
}

//合并排序
export function deccombinesort(decMergeListVo: any, cmbtype: any) {
  console.log('合并排序');

  // "不合并", "忽略合同", "忽略材质"
  // var groupargs = [
  //   'codeTs',
  //   'gName',
  //   'tradeCurr',
  //   'tradeCurrName',
  //   'cusOriginCountry',
  //   'cusOriginCountryName',
  //   'instockSku',
  //   'subsystem',
  //   'freeFlag',
  //   'mbgModel',
  //   'namememo',
  // ];

  // 申报单位不一致不合并
  var groupargs = [
    'codeTs',
    'gName',
    'tradeCurr',
    'tradeCurrName',
    'cusOriginCountry',
    'cusOriginCountryName',
    'instockSku',
    'freeFlag',
    'gModel',
    'namememo',
    'gUnit'
  ];

  if (cmbtype == '锁定合并') {
    groupargs.push('gNo');
  }
  if (cmbtype != '忽略合同') {
    groupargs.push('contrNo');
  }
  if (cmbtype == '根据项号') {
    groupargs = ['instockSku', 'cusOriginCountry', 'tradeCurr', 'contrNo'];
  }
  if (cmbtype == '备案合并') {
    //如果有数量和商品库就
    groupargs = ['namememo'];
  }
  if (cmbtype == '品名合并') {
    //如果有数量和商品库就
    groupargs = ['gName'];
  }

  var mlist = JSON.parse(JSON.stringify(decMergeListVo));
  mlist.map((ml) => {
    // if (ml.freeFlag != "免缴") {
    //     ml.freeFlag = "缴税";
    // }
    if (!ml.freeFlag) {
      ml.freeFlag = '免表';
    }
    ml.confidence = '1';
    if (ml.loctioninfo) {
      ml.loctioninfo.map((eloc) => {
        eloc.confidence = 1;
      });
    }
  });
  var combineresult = cmbcombine(mlist, groupargs, cmbtype);
  var sortresult = decsort(combineresult);
  if (cmbtype == '锁定合并') {
    sortresult = combineresult;
  }

  for (var z = 0; z < sortresult.length; z++) {
    sortresult[z].gNo = z + 1;
  }
  var cmbdecMergeListVo = sortresult;
  return cmbdecMergeListVo;
}

export function revertsku(dec) {
  if (dec.sku.indexOf('@') != -1) {
    dec.sku = dec.sku.substr(0, dec.sku.indexOf('@'));
  }
}

export function cmbcombine(decMergeListVo, groupargs, cmbtype) {
  try {
    if (decMergeListVo) {
      for (var i = 0; i < decMergeListVo.length; i++) {
        var decMerge = decMergeListVo[i];
        if (!decMerge.declPrice || decMerge.declPrice == 'Infinity') {
          decMerge.realdeclTotal = decMerge.declTotal;
          decMerge.declPrice = Number(decMerge.declTotal / decMerge.qty);
        }
      }
    }

    // var ignorepat = [",", ":", "：", "，", "。", ";", "；"];
    var ignorepat = /[,|:|：|，|。|;|；]/;
    if (groupargs.indexOf('gName') != -1) {
      for (var i = 0; i < decMergeListVo.length; i++) {
        var dmg = decMergeListVo[i];
        if (dmg.gName) {
          dmg.combgname = dmg.gName.replace('（', '(').replace('）', ')');
          if (dmg.combgname.indexOf('(') != -1) {
            // dmg.combgname = dmg.gName.replace(dmg.sku, "");
            //料号带@ 品名里不会带@ 不能替换掉
            var revsku = dmg.sku;
            if (dmg.sku.indexOf('@') != -1) {
              revsku = dmg.sku.substr(0, dmg.sku.indexOf('@'));
            }
            dmg.combgname = dmg.gName.replace(revsku, '');
            //有的品名没括号 为什么改用清除料号？
            // dmg.combgname = dmg.gName.substr(0, dmg.gName.indexOf('('));
          } else {
            dmg.combgname = dmg.gName;
          }
        } else {
          dmg.combgname = '';
        }
        dmg.combgname = dmg.combgname.replace(ignorepat, '');
      }
      groupargs[groupargs.indexOf('gName')] = 'combgname';
    }
    console.log('mbgModel');
    var gjobj = {};
    var ggobj = {};
    var materobj = {};
    var rateobj = {};
    if (groupargs.indexOf('gModel') != -1) {
      for (var i = 0; i < decMergeListVo.length; i++) {
        var dmg = decMergeListVo[i];
        if (dmg.extendjson && dmg.extendjson.declareElements) {
          var declareElements = dmg.extendjson.declareElements;
          var materindex = -1;
          var raindex = -1;
          var gjindex = -1;
          var ggindex = -1;
          for (var m = 0; m < declareElements.length; m++) {
            if (declareElements[m].key.indexOf('材质') != -1) {
              materindex = m - 1;
            }
            if (declareElements[m].key.indexOf('成分含量') != -1) {
              raindex = m - 1;
            }
            if (declareElements[m].key.indexOf('杆径') != -1) {
              gjindex = m - 1;
            }
            if (declareElements[m].key.indexOf('规格尺寸') != -1) {
              ggindex = m - 1;
            }
          }
          if (dmg.gModel) {
            var gmodells = dmg.gModel.split('|');
            dmg.combgModel = '';
            if (cmbtype != '忽略材质') {
              if (materindex != -1 && gmodells.length > materindex) {
                dmg.combgModel += gmodells[materindex];
              }
              if (raindex != -1 && gmodells.length > raindex) {
                dmg.combgModel += gmodells[raindex];
              }
            }
            if (materindex != -1 && gmodells.length > materindex) {
              materobj[dmg.sku] = gmodells[materindex];
            }
            if (raindex != -1 && gmodells.length > raindex) {
              rateobj[dmg.sku] = gmodells[raindex];
            }
            if (gjindex != -1) {
              gjobj[dmg.sku] = gmodells[gjindex];
            }
            if (ggindex != -1) {
              ggobj[dmg.sku] = gmodells[ggindex];
            }
          } else {
            dmg.combgModel = '';
          }
        } else {
          if (dmg.gModel) {
            var gmodells = dmg.gModel.split('|');
            for (var m = 0; m < gmodells.length; m++) {
              if (gmodells[m].indexOf('用于') != -1) {
                gmodells[m] = '';
              }
            }
            var combgModel = gmodells.join('|');
            dmg.combgModel = combgModel.replace(dmg.sku, '');
            dmg.combgModel = dmg.combgModel.replace(ignorepat, '');
          } else {
            dmg.combgModel = '';
          }
        }
      }
      groupargs[groupargs.indexOf('gModel')] = 'combgModel';
    }

    // var groupargs = data.groupargs;
    var groupresult = cmbgroupBy(decMergeListVo, groupargs);

    var flgroup = [];
    var gr = groupresult.length;
    var grlen = groupresult.length;

    var totalnet = 0;
    while (gr--) {
      var flz: any = {};
      for (var key in groupresult[grlen - gr - 1][0]) {
        if (key.indexOf(',') == -1) {
          flz[key] = groupresult[grlen - gr - 1][0][key];
        }
      }
      flz.gQty = sum(groupresult[grlen - gr - 1], 'gQty');
      flz.declTotal = Number(
        sum(groupresult[grlen - gr - 1], 'declTotal')
      ).toFixed(2);
      flz.realdeclTotal = Number(
        sum(groupresult[grlen - gr - 1], 'realdeclTotal')
      ).toFixed(2);

      flz.qty1 = sum(groupresult[grlen - gr - 1], 'qty1');
      if (flz.unit2 && flz.unit2 != '000') {
        flz.qty2 = sum(groupresult[grlen - gr - 1], 'qty2');
      }
      if (flz.netWeight) {
        flz.netWeight = sum(groupresult[grlen - gr - 1], 'netWeight');
        //合并后的净重舍去保留两位小数
        //上次修改作废 合并后至少取三位小数
        if (flz.netWeight.indexOf('.') != -1) {
          var qnet = flz.netWeight.substr(flz.netWeight.indexOf('.') + 1);
          if (qnet.length < 3) {
            flz.netWeight = parseFloat(flz.netWeight).toFixed(3);
          }
        }
        // flz.netWeight = (parseInt((parseFloat(flz.netWeight) * 100).toString()) / 100).toString()
        totalnet += Number(flz.netWeight);
      }
      if (flz.qty) {
        flz.qty = sum(groupresult[grlen - gr - 1], 'qty');
      }

      var priceqty = flz.gQty;
      if (priceqty == 0) {
        priceqty = flz.qty;
      }
      flz.declPrice = Number(flz.declTotal / priceqty).toFixed(4);
      if (flz.secondQty == 0) {
        flz.secondQty = '';
      }
      // var slen = groupresult[grlen - gr - 1].length;
      var slen = 0;
      var combinebf = [];
      var mulskuflag = false;
      var mulsku = '';

      var curgj = '';
      var curgg = '';
      var mulgjflag = false;
      var mulggflag = false;

      //多个材质加等
      var curcz = '';
      var mulczflag = false;
      //多个成分加等
      var currate = '';
      var mulrateflag = false;
      // 需要正排
      while (slen < groupresult[grlen - gr - 1].length) {
        var cbf: any = {};
        cbf.gNo = groupresult[grlen - gr - 1][slen].gNo;
        cbf.sku = groupresult[grlen - gr - 1][slen].sku;
        cbf.qty = groupresult[grlen - gr - 1][slen].qty;
        cbf.netWeight = groupresult[grlen - gr - 1][slen].netWeight;
        cbf.declPrice = groupresult[grlen - gr - 1][slen].declPrice;
        cbf.declTotal = groupresult[grlen - gr - 1][slen].declTotal;
        cbf.contrNo = groupresult[grlen - gr - 1][slen].contrNo;

        //需要合并前的更多信息
        cbf.gQty = groupresult[grlen - gr - 1][slen].gQty;
        cbf.gUnit = groupresult[grlen - gr - 1][slen].gUnit;
        cbf.qty1 = groupresult[grlen - gr - 1][slen].qty1;
        cbf.unit1 = groupresult[grlen - gr - 1][slen].unit1;
        cbf.qty2 = groupresult[grlen - gr - 1][slen].qty2;
        cbf.unit2 = groupresult[grlen - gr - 1][slen].unit2;
        cbf.gName = groupresult[grlen - gr - 1][slen].gName;
        cbf.gModel = groupresult[grlen - gr - 1][slen].gModel;
        cbf.mbgModel = groupresult[grlen - gr - 1][slen].mbgModel;
        cbf.productid= groupresult[grlen - gr - 1][slen].productid;

        //杆径是否有多个
        // var a = groupresult[grlen - gr - 1][slen].sku;
        if (cmbtype) {
          cbf.cmbtype = cmbtype;
        }
        if (mulsku == '') {
          mulsku = cbf.sku;
        } else {
          if (mulsku != cbf.sku) {
            mulskuflag = true;
          }
        }

        if (!curgj) {
          if (gjobj[cbf.sku]) {
            curgj = gjobj[cbf.sku];
          }
        } else {
          if (curgj != gjobj[cbf.sku]) {
            // curgj = gjobj[cbf.sku];
            mulgjflag = true;
          }
        }
        cbf.curgj = curgj;

        //多个规格加等
        if (!curgg) {
          if (ggobj[cbf.sku]) {
            curgg = ggobj[cbf.sku];
          }
        } else {
          if (curgg != ggobj[cbf.sku]) {
            // curgj = gjobj[cbf.sku];
            mulggflag = true;
          }
        }

        //多个成分含量加等
        if (!currate) {
          if (rateobj[cbf.sku]) {
            currate = rateobj[cbf.sku];
          }
        } else {
          if (currate != rateobj[cbf.sku]) {
            mulrateflag = true;
          }
        }
        // 多个材质加等
        if (!curcz) {
          if (materobj[cbf.sku]) {
            curcz = materobj[cbf.sku];
          }
        } else {
          if (curcz != materobj[cbf.sku]) {
            mulczflag = true;
          }
        }
        cbf.curgj = gjobj[cbf.sku];
        cbf.curgg = ggobj[cbf.sku];

        combinebf.push(cbf);
        slen++;
      }
      flz.combinebf = JSON.stringify(combinebf);

      mulwithcombef(flz, combinebf);
      //合并多个sku带等
      // if (mulskuflag) {
      //     if (flz.gName && flz.gName.indexOf(flz.sku + "等") == -1) {
      //         flz.gName = flz.gName.replace(flz.sku, flz.sku + "等");
      //     }
      //     if (flz.gModel && flz.gModel.indexOf(flz.sku + "等") == -1) {
      //         flz.gModel = flz.gModel.replace(flz.sku, flz.sku + "等");
      //     }
      //     if (flz.mbgModel && flz.mbgModel.indexOf(flz.sku + "等") == -1) {
      //         flz.mbgModel = flz.mbgModel.replace(flz.sku, flz.sku + "等");
      //     }
      // }
      //杆径加等
      // if (mulgjflag) {
      //     if (flz.gModel) {
      //         flz.gModel = flz.gModel.replace("|" + curgj + "|", "|" + curgj + "等|");
      //     }
      //     if (flz.mbgModel) {
      //         flz.mbgModel = flz.mbgModel.replace("|" + curgj + "|", "|" + curgj + "等|");
      //     }
      // }
      // //规格尺寸加等
      // if (mulggflag) {
      //     if (flz.gModel) {
      //         flz.gModel = flz.gModel.replace("|" + curgg + "|", "|" + curgg + "等|");
      //     }
      //     if (flz.mbgModel) {
      //         flz.mbgModel = flz.mbgModel.replace("|" + curgg + "|", "|" + curgg + "等|");
      //     }
      // }
      // //成分含量加等
      // if (mulrateflag) {
      //     if (flz.gModel) {
      //         flz.gModel = flz.gModel.replace("|" + currate + "|", "|" + currate + "等|");
      //     }
      //     if (flz.mbgModel) {
      //         flz.mbgModel = flz.mbgModel.replace("|" + currate + "|", "|" + currate + "等|");
      //     }
      // }
      // //材质加等
      // if (mulczflag) {
      //     if (flz.gModel) {
      //         flz.gModel = flz.gModel.replace("|" + curcz + "|", "|" + curcz + "等|");
      //     }
      //     if (flz.mbgModel) {
      //         flz.mbgModel = flz.mbgModel.replace("|" + curcz + "|", "|" + curcz + "等|");
      //     }
      // }
      delete flz.combgname;
      delete flz.combgModel;

      flgroup.push(flz);
    }
    return flgroup;
  } catch (e) {
    console.log('combine', e);
    return [];
  }
}

// 合并多个加等  前端实际使用
export function mulwithcombef_bak(decmerge, combinebf) {
  if (combinebf && combinebf.length > 11) {
    //sku
    var skuflag = false;
    //杆径
    var gjobj = {};
    var gjflag = false;
    //规格
    var ggobj = {};
    var ggflag = false;
    //材质
    var materobj = {};
    var materflag = false;
    //成分含量
    var rateobj = {};
    var rateflag = false;
    // 规格（长x宽x厚）
    var ccobj = {};
    var ccflag = false;
    // 铝合金条、杆请注明截面周长
    var jmobj = {};
    var jmflag = false;

    var dmg = decmerge;
    var mbdeclareElements = decmerge.extendjson?.mbdeclareElements;
    var declareElements = decmerge.extendjson?.declareElements;
    var materindex = -1;
    var raindex = -1;
    var gjindex = -1;
    var ggindex = -1;
    var ccindex = -1;



    if (declareElements) {
      for (var m = 0; m < declareElements.length; m++) {
        if (declareElements[m].key.indexOf('材质') != -1) {
          materindex = m - 1;
        }
        if (declareElements[m].key.indexOf('成分含量') != -1) {
          raindex = m - 1;
        }
        if (declareElements[m].key.indexOf('杆径') != -1) {
          gjindex = m - 1;
        }
        if (declareElements[m].key.indexOf('规格尺寸') != -1) {
          ggindex = m - 1;
        }
        if (declareElements[m].key.indexOf('规格（长x宽x厚）') != -1) {
          ccindex = m - 1;
        }
      }
      if (dmg.gModel) {
        var gmodells = dmg.gModel.split('|');
        if (materindex != -1 && gmodells.length > materindex) {
          materobj[dmg.sku] = gmodells[materindex];
        }
        if (raindex != -1 && gmodells.length > raindex) {
          rateobj[dmg.sku] = gmodells[raindex];
        }
        if (gjindex != -1) {
          gjobj[dmg.sku] = gmodells[gjindex];
        }
        if (ggindex != -1) {
          ggobj[dmg.sku] = gmodells[ggindex];
        }
        if (ccindex != -1) {
          ccobj[dmg.sku] = gmodells[ccindex];
        }
      }
    }
    if (decmerge.gModel && decmerge.gModel.replace(/\|/g, '')) {
      skuflag = false;
      gjflag = false;
      ggflag = false;
      materflag = false;
      rateflag = false;
      ccflag = false;
      for (var c = 0; c < combinebf.length; c++) {
        var cb = combinebf[c];
        var cmbmbdeclareElementsStr = cb.gModel;
        if (cb.sku != dmg.sku) {
          skuflag = true;
        }
        if (cmbmbdeclareElementsStr) {
          var bls = cmbmbdeclareElementsStr.split('|');
          if (bls[gjindex] && gjobj[dmg.sku] != bls[gjindex]) {
            gjflag = true;
          }
          if (bls[ggindex] && ggobj[dmg.sku] != bls[ggindex]) {
            ggflag = true;
          }
          if (bls[materindex] && materobj[dmg.sku] != bls[materindex]) {
            materflag = true;
          }
          if (bls[raindex] && rateobj[dmg.sku] != bls[raindex]) {
            rateflag = true;
          }
          if (bls[ccindex] && ccobj[dmg.sku] != bls[ccindex]) {
            ccflag = true;
          }
        }
      }
      if (skuflag) {
        decmerge.gModel = decmerge.gModel.replace(
          '|' + dmg.sku + '|',
          '|' + dmg.sku + '等|'
        );
        if (decmerge.gName.indexOf(dmg.sku + '等') == -1) {
          decmerge.gName = decmerge.gName.replace(dmg.sku, dmg.sku + '等');
        }
      }
      if (gjflag) {
        decmerge.gModel = decmerge.gModel.replace(
          '|' + gjobj[dmg.sku] + '|',
          '|' + gjobj[dmg.sku] + '等|'
        );
      }
      if (ggflag) {
        decmerge.gModel = decmerge.gModel.replace(
          '|' + ggobj[dmg.sku] + '|',
          '|' + ggobj[dmg.sku] + '等|'
        );
        decmerge.gName = decmerge.gName.replace(dmg.sku, dmg.sku + '等');
      }
      if (materflag) {
        decmerge.gModel = decmerge.gModel.replace(
          '|' + materobj[dmg.sku] + '|',
          '|' + materobj[dmg.sku] + '等|'
        );
      }
      if (rateflag) {
        decmerge.gModel = decmerge.gModel.replace(
          '|' + rateobj[dmg.sku] + '|',
          '|' + rateobj[dmg.sku] + '等|'
        );
      }
      if (ccflag) {
        decmerge.gModel = decmerge.gModel.replace(
          '|' + ccobj[dmg.sku] + '|',
          '|' + ccobj[dmg.sku] + '等|'
        );
      }
    }

  }
}


// 合并多个加等  前端实际使用
export function mulwithcombef(decmerge, combinebf) {
  if (combinebf && combinebf.length > 1) {

    var dmg = decmerge;
    var declareElements = decmerge.extendjson?.declareElements;

    var calkeys = ['材质', '成分含量', '杆径', '规格尺寸', '规格（长x宽x厚）', '铝合金条、杆请注明截面周长', '规格（板材的厚度、宽度）'];
    var calindex = [];
    for (var c = 0; c < calkeys.length; c++) {
      calindex.push(-1);
    }

    if (declareElements) {
      for (var m = 0; m < declareElements.length; m++) {
        for (var c = 0; c < calkeys.length; c++) {
          if (declareElements[m].key.indexOf(calkeys[c]) != -1) {
            calindex[c] = m - 1;
          }
        }
      }
      if (dmg.gModel && dmg.gModel.replace(/\|/g, '')) {
        var gmodells = dmg.gModel.split('|');
        for (var c = 0; c < combinebf.length; c++) {
          var cb = combinebf[c];
          var cmbmbdeclareElementsStr = cb.gModel;
          //sku带@的去掉
          let dsku=dmg.sku;
          if(dsku.indexOf('@')!=-1){
            dsku=dsku.split('@')[0];
          }
          let cbefsku=cb.sku;
          if(cbefsku.indexOf('@')!=-1){
            cbefsku=cbefsku.split('@')[0];
          }
          if (cbefsku != dsku) {
            if (dmg.gModel.indexOf(dsku + '等') == -1) {
              dmg.gModel = dmg.gModel.replace(
                '|' + dsku + '|',
                '|' + dsku + '等|'
              );
            }
            if (dmg.gName.indexOf(dsku + '等') == -1) {
              dmg.gName = dmg.gName.replace(dsku, dsku + '等');
            }
          }
          if (cmbmbdeclareElementsStr) {
            var bls = cmbmbdeclareElementsStr.split('|');
            for (var d = 0; d < calindex.length; d++) {
              if (calindex[d] != -1) {
                if (bls[calindex[d]] && gmodells[calindex[d]] && gmodells[calindex[d]] != bls[calindex[d]]) {
                  if (dmg.gModel.indexOf('|' + gmodells[calindex[d]] + '等|') == -1) {
                    dmg.gModel = dmg.gModel.replace(
                      '|' + gmodells[calindex[d]] + '|',
                      '|' + gmodells[calindex[d]] + '等|'
                    );
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

export function cmbgroupBy(sourcelist: any[], groupargs: any[]) {
  var groupkey = groupargs.join(',');
  if (groupargs.length == 1) {
    groupkey += ',';
  }
  var gouplist: any[] = [];
  console.log('cmbgroupBy');
  sourcelist.forEach((sor) => {
    sor[groupkey] = '';
    groupargs.forEach((gr) => {
      if (sor[gr]) {
        sor[groupkey] += sor[gr];
      } else {
        sor[groupkey] += 'empty';
      }
    });
    var keylist = [];
    for (var key in gouplist) {
      keylist.push(key);
    }
    if (keylist.indexOf(sor[groupkey]) == -1) {
      gouplist[sor[groupkey]] = [];
      gouplist[sor[groupkey]].push(sor);
    } else {
      gouplist[sor[groupkey]].push(sor);
    }
  });
  var result = [];
  for (var key in gouplist) {
    console.log('cmbgroupBy', key);
    result.push(gouplist[key]);
  }
  return result;
}

export function judgesku(prod) {
  var sku = prod.materialPartNo;
  var declareElements = prod.declareElements;
  var mbdeclareElements = prod.mbdeclareElements;
  var skufield = [];
  skufield.push('规格型号');
  skufield.push('规格和型号');
  skufield.push('规格或型号');
  skufield.push('货号');
  skufield.push('型号');
  var skufitflag = true;
  if (declareElements) {
    declareElements.map((dec) => {
      if (skufield.indexOf(dec.key) != -1) {
        if (sku != dec.value) {
          skufitflag = false;
        }
      }
    });
  } else if (prod.declareElementsStr) {
    skufitflag = false;
  }
  if (mbdeclareElements) {
    mbdeclareElements.map((dec) => {
      if (skufield.indexOf(dec.key) != -1) {
        if (sku != dec.value) {
          skufitflag = false;
        }
      }
    });
  } else if (prod.mbdeclareElementsStr) {
    skufitflag = false;
  }
  return skufitflag;
}

export function parsefeet(data) {
  if (data.gUnit == '067') {
    if (data.unit1 == '030') {
      data.qty1 = parseFloat(
        (Number(data.gQty) * 0.3048).toFixed(2)
      ).toString();
    }
    if (data.unit2 == '030') {
      data.qty2 = parseFloat(
        (Number(data.gQty) * 0.3048).toFixed(2)
      ).toString();
    }
  }
  if (data.gUnit == '088') {
    if (data.unit1 == '030') {
      data.qty1 = parseFloat(
        (Number(data.gQty) * 0.0254).toFixed(2)
      ).toString();
    }
    if (data.unit2 == '030') {
      data.qty2 = parseFloat(
        (Number(data.gQty) * 0.0254).toFixed(2)
      ).toString();
    }
  }
}


export function mround(number, decimalPlaces) {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(number * factor) / factor;
}


export function qtychange(decMerge) {
  console.log('qtychange');
  if (decMerge.qty && decMerge.qty != 0) {
    decMerge.declPrice = mround(Number(decMerge.declTotal / decMerge.qty),4).toString();
  }
  if (decMerge.gUnit && decMerge.gUnit != '000') {
    var parseqty = getQtybyUnit(
      decMerge.qty ? decMerge.qty : 0,
      decMerge.gUnit,
      decMerge.netWeight,
      decMerge.codeTs
    );
    if (parseqty) {
      decMerge.gQty = parseFloat(
        Number(parseqty.toString()).toFixed(6)
      ).toString();
    }
    else {
      if (!decMerge.gQty) {
        decMerge.gQty = parseFloat(
          Number((decMerge.qty ? decMerge.qty : 0).toString()).toFixed(6)
        ).toString();
      }

    }

  }
  if (decMerge.unit1 && decMerge.unit1 != '000') {
    var parseqty = getQtybyUnit(
      decMerge.qty ? decMerge.qty : 0,
      decMerge.unit1,
      decMerge.netWeight,
      decMerge.codeTs
    );
    if (parseqty) {
      decMerge.qty1 = parseFloat(
        Number(parseqty.toString()).toFixed(6)
      ).toString();
    }
  }
  console.log('qtychange');
  if (
    decMerge.unit2 &&
    decMerge.unit2 != '035' &&
    decMerge.unit2 != decMerge.unit1 &&
    decMerge.unit2 != decMerge.gUnit
  ) {
    //这里也应该赋值
    var parseqty = getQtybyUnit(
      decMerge.qty ? decMerge.qty : 0,
      decMerge.unit2,
      decMerge.netWeight,
      decMerge.codeTs
    );
    //qty不一定是数量 qty2应该维持原状?
    if (parseqty) {
      if(!decMerge.qty2){
        decMerge.qty2 = parseFloat(
          Number(parseqty.toString()).toFixed(6)
        ).toString();
      }
     
    }


  } else if (decMerge.unit2 && decMerge.unit2 != '000') {
    var parseqty = getQtybyUnit(
      decMerge.qty ? decMerge.qty : 0,
      decMerge.unit2,
      decMerge.netWeight,
      decMerge.codeTs
    );
    //qty不一定是数量 qty2应该维持原状?
    if (parseqty) {
      decMerge.qty2 = parseFloat(
        Number(parseqty.toString()).toFixed(6)
      ).toString();
    }
  } else {
    decMerge.qty2 = null;
    decMerge.unit2 = null;
    decMerge.unit2Name = null;
  }

  parsefeet(decMerge);
}

export function contrComb(contrls) {
  contrls = contrls.filter((ct: any) => {
    return ct;
  });
  contrls = contrls.sort(function (a: any, b: any) {
    return a.length - b.length;
  });
  //先提取公用头
  var commonhead = '';
  for (var i = 0; i < contrls.length; i++) {
    var spc = contrls[i].split('-');
    if (i == 0) {
      commonhead = spc;
    }
    else {
      for (var j = 0; j < commonhead.length; j++) {
        if (spc.length > j && spc[j] != commonhead[j]) {
          commonhead = commonhead.slice(0, j);
          break;
        }
      }
    }
  }
  var cmbcontr = [];
  for (var i = 0; i < contrls.length; i++) {
    if (i == 0) {
      cmbcontr.push(contrls[i]);
    }
    else {
      var spc = contrls[i].split('-');
      var cmb = [];
      for (var j = commonhead.length; j < spc.length; j++) {
        cmb.push(spc[j]);
      }
      cmbcontr.push(cmb.join('-'));
    }
  }
  return cmbcontr.join('/');
}

export function contrComb_bak(contrls) {
  contrls = contrls.filter((ct: any) => {
    return ct;
  });
  contrls = contrls.sort(function (a: any, b: any) {
    return a.length - b.length;
  });
  var cmbcontr = [];
  for (var i = 0; i < contrls.length; i++) {
    if (i == 0) {
      cmbcontr.push(contrls[i]);
    } else {
      var spc = contrls[i].split('-');
      var matchspc = [];
      var unmatchspc = [];
      for (var j = 0; j < spc.length; j++) {
        if (unmatchspc.length == 0) {
          matchspc.push(spc[j]);
          if (cmbcontr[0].indexOf(matchspc.join('-')) != 0) {
            unmatchspc.push(spc[j]);
          }
        } else {
          unmatchspc.push(spc[j]);
        }
      }
      if (unmatchspc.length != 0) {
        cmbcontr.push(unmatchspc.join('-'));
      }
    }
  }
  return cmbcontr.join('/');
}
