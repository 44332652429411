import { Apollo, ApolloBase, QueryRef } from 'apollo-angular';
import { Observable, of } from 'rxjs';
// import gql from 'graphql-tag';
// import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { map, tap, catchError } from 'rxjs/operators';
import {
  KeyValue,
  Paging,
  MutationResponse,
  IDataInfo,
} from '@ternsorinfo/data';
import { DocumentNode } from 'graphql';
import { ApolloQueryResult } from '@apollo/client/core';

const rowsDefault = 10;
export type ListResult<D> = {
  totalCount: number;
  dataList: D[];
};

export interface IBaseService<T extends IDataInfo> {
  searchFecth(
    searchStr: string,
    cond: KeyValue[],
    pageInfo: Paging
  ): Promise<ApolloQueryResult<T>>;
  doSearch(
    searchStr: string,
    cond: KeyValue[],
    pageInfo: Paging
  ): Observable<T>;
  getList(conditionMap: KeyValue[], pageInfo: Paging);
  updateDetail(id: string, input: T): Observable<MutationResponse>;
  deleteDetail(id: string, lastupdateDate: Date): Observable<MutationResponse>;
  getDetailById(id: string): Observable<T>;
}

export interface IGQLDefs {
  searchGQL: {
    gql: DocumentNode;
    resultName: string;
  };
  getGQL: {
    gql: DocumentNode;
    resultName: string;
  };
  deleteGQL?: {
    gql: DocumentNode;
    resultName: string;
  };
  updateGQL?: {
    gql: DocumentNode;
    resultName: string;
  };
  getDetailGQL?: {
    gql: DocumentNode;
    resultName: string;
  };
}

export abstract class ServiceBase {
  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
export class BaseGraphService<T> extends ServiceBase
  implements IBaseService<T> {
  protected apollo: ApolloBase;
  gqlDef: IGQLDefs;
  protected searchQueryRef: QueryRef<T>;

  protected GetByIDRef: QueryRef<T>;

  constructor(gqlDdf: IGQLDefs) {
    super();
    this.gqlDef = gqlDdf;
  }

  searchFecth(
    searchStr: string,
    conditionMap: KeyValue[],
    pageInfo: Paging
  ): Promise<ApolloQueryResult<T>> {
    return this.searchQueryRef.refetch({ searchStr, pageInfo, conditionMap });
  }

  doSearch(searchStr: string, conditionMap: KeyValue[], pageInfo: Paging) {
    if (!!!this.searchQueryRef) {
      this.searchQueryRef = this.apollo.watchQuery({
        query: this.gqlDef.searchGQL.gql,
        variables: { searchStr, pageInfo, conditionMap },
        fetchPolicy:"network-only"
      });
    }
    return this.searchQueryRef.valueChanges.pipe(
      tap((value) => {
        console.log('fetch search');
      }),

      map(
        (result) =>
          result && result.data && result.data[this.gqlDef.searchGQL.resultName]
      ),
      catchError(this.handleError(`gettest`))
    );
  }

  getList(conditionMap: KeyValue[], pageInfo: Paging) {
    // var tmpsearchStr = this.formSearchStr.value.replace('(', '\\(').replace(')', '\\)');

    return (
      this.apollo
        .query({
          query: this.gqlDef.getGQL.gql,

          variables: { conditionMap, pageInfo },
          fetchPolicy: 'network-only',
        })
        // .valueChanges.pipe(
        //   tap((value) => {
        //     console.log(`fetch getlist`);
        //   }),

        .pipe(
          map(
            (result) =>
              result &&
              result.data &&
              result.data[this.gqlDef.getGQL.resultName]
          )
        )
      // catchError(this.handleError<CpcCreditDetail[]>(`gettest`))
    );
  }

  updateDetail(id: string, input: T) {
    input = JSON.parse(JSON.stringify(input));
    delete input['__typename'];
    return this.apollo
      .mutate({
        mutation: this.gqlDef.updateGQL.gql,
        variables: { id: id, input: input },
      })
      .pipe(
        map(
          (result) =>
            result.data && result.data[this.gqlDef.updateGQL.resultName]
        )
      );
  }

  deleteDetail(id: string, lastUpdateDate: Date) {
    return this.apollo
      .mutate({
        mutation: this.gqlDef.deleteGQL.gql,
        variables: { id, lastUpdateDate },
      })
      .pipe(
        map(
          (result) =>
            result.data && result.data[this.gqlDef.deleteGQL.resultName]
        )
      );
  }
  getDetailById(id: string): Observable<T> {
    // if (!!!this.GetByIDRef) {
    //   this.GetByIDRef = this.apollo.watchQuery({
    //     query: this.gqlDef.getDetailGQL.gql,
    //     variables: { id },
    //   });
    return this.apollo
      .query({
        query: this.gqlDef.getDetailGQL.gql,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map(
          (result) =>
            result.data && result.data[this.gqlDef.getDetailGQL.resultName]
        )
      );
  }
}
