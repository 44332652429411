export const menuDef = [
  {
    iconType: 'global',
    label: '市场部运输',
    memo: '运输效果',

    submenuFlag: true,
    submenus: [
      {
        iconType: 'mail',
        label: '客户委托',
        memo: '客户委托单',
        route: 'tms-cust/cust-apply',
        fragment: '客户委托',
      },
      {
        iconType: '',
        label: '供应商派单',
        memo: '排单',
        route: 'tms-cust/trans-forwarding-order',
        fragment: '供应商派单',
      },
    ],
  },
  {
    iconType: 'global',
    label: '关务部',
    memo: '关务',

    submenuFlag: true,
    submenus: [
      // {
      //   iconType: 'mail',
      //   label: '关务委托',
      //   memo: '客户委托单',
      //   route: 'tms-cust/custom-apply-business',
      //   fragment: '关务委托',
      // },
      // {
      //   iconType: 'mail',
      //   label: '运输管理',
      //   memo: '运输管理',
      //   route: 'tms-cust/tms-manage',
      //   fragment: '运输管理',
      // },
      {
        iconType: 'mail',
        label: '运输委托',
        memo: '运输委托',
        route: 'tms-cust/tms-material',
        fragment: '运输委托',
      },
      // {
      //   iconType: 'mail',
      //   label: '产出管理',
      //   memo: '产出管理',
      //   route: 'tms-cust/tms-produce-manage',
      //   fragment: '产出管理',
      // },
      {
        iconType: 'mail',
        label: '产出入库',
        memo: '产出入库',
        route: 'tms-cust/tms-produce-warehouse',
        fragment: '产出入库',
      },

      
      // {
      //   iconType: '',
      //   label: '供应商派单',
      //   memo: '排单',
      //   route: 'tms-cust/trans-forwarding-order',
      //   fragment: '供应商派单',
      // },
    ],
  },
  // {
  //   iconType: 'send',
  //   label: '基础资料',
  //   memo: '基础资料',
  //   submenuFlag: true,
  //   submenus: [
  //     {
  //       iconType: 'mail',
  //       label: '销售合同',
  //       memo: '销售合同',
  //       route: 'tms-cust/biz-sales-contract',
  //       fragment: '销售合同',
  //     },
  //     {
  //       iconType: 'environment',
  //       label: '供应商合同',
  //       memo: '供应商合同',
  //       route: 'tms-cust/biz-supplier-contract',
  //       fragment: '供应商合同',
  //     },

  //     {
  //       iconType: 'environment',
  //       label: '对账单',
  //       memo: '对账单',
  //       route: 'tms-cust/biz-cust-bill-info',
  //       fragment: '对账单',
  //     },

  //     {
  //       iconType: 'file-text',
  //       label: '开票申请',
  //       memo: '开票申请',
  //       route: 'tms-cust/biz-invoice-apply',

  //       fragment: '开票',
  //     },
  //     {
  //       iconType: 'mail',
  //       label: '付款申请',
  //       memo: '付款申请',
  //       route: 'tms-cust/biz-pay-supplier-appy',
  //       fragment: '付款申请',
  //     },
  //     {
  //       iconType: 'setting',
  //       label: '应收应付结转',
  //       memo: '',
  //       route: 'tms-cust/fund-transfer',
  //       fragment: '归档资料',
  //     },
  //   ],
  // },

  {
    iconType: 'deployment-unit',
    label: '其他',
    memo: '其他',
    submenuFlag: true,
    submenus: [
      {
        iconType: 'setting',
        label: '其他',
        memo: '',
        route: 'net-transit/productlist',
        fragment: '其他',
      },
    ],
  },
];
