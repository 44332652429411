export class CustomError extends Error {
  code: string;

  constructor(message: string, code: string) {
    super(message);
    this.name = this.constructor.name;
    this.code = code;
    // Error.captureStackTrace(this, this.constructor);
  }
}
/**
 * tms
 */
export class TmsCustomsBusinessBoxNoRequiredError extends CustomError {
  constructor(
    message: string,
    code: string = 'TMS_CUSTOMS_BUSINESS_BOX_NO_ERROR'
  ) {
    super(message, code);
  }
}

export class TmsCustomBusinessNoUniqueError extends CustomError {
  constructor(
    message: string,
    code: string = 'TMS_CUSTOM_BUSINESS_NO_UNIQUE_ERROR'
  ) {
    super(message, code);
  }
}

export class TmsCustomsMaterailFONoRequiredError extends CustomError {
  constructor(
    message: string,
    code: string = 'TMS_CUSTOMS_BUSINESS_NO_REQUIRED_ERROR'
  ) {
    super(message, code);
  }
}

export class TmsCustomsTmsApplyNoRequiredError extends CustomError {
  constructor(
    message: string,
    code: string = 'TMS_CUSTOMS_TMS_APPLY_NO_REQUIRED_ERROR'
  ) {
    super(message, code);
  }
}
