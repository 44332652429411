export namespace comac {
  export type Maybe<T> = T | null;
  export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
  /** All built-in and custom scalars, mapped to their actual values */
  export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Date: any;
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: any;
    /** The `Upload` scalar type represents a file upload. */
    Upload: any;
  };

  export type Query = {
    __typename?: 'Query';
    _empty?: Maybe<Scalars['String']>;
    getAppUsers?: Maybe<Array<Maybe<AppUserInfo>>>;
    getAPPUserByID?: Maybe<AppUserInfo>;
    getCsActiveOrder?: Maybe<Array<Maybe<ActiveOrder>>>;
    getCsActiveCount?: Maybe<CsActiverOrderCount>;
    getClientStatus?: Maybe<KdcSign>;
    searchDecHead?: Maybe<Array<Maybe<SingleWindowDecHead>>>;
    getClientCountStatus?: Maybe<ClientStatCount>;
    getDecDetailbyId?: Maybe<SingleWindowDecDetail>;
    getParaByType?: Maybe<Array<Maybe<ParaKeyValue>>>;
    getParas?: Maybe<Array<Maybe<ParaValue>>>;
    judgeRoleUrl?: Maybe<Scalars['Boolean']>;
    getHscodePara?: Maybe<Array<Maybe<ClassifyInfo>>>;
    getAppAppName?: Maybe<Array<Maybe<AppAppName>>>;
    getAppAppNameCount?: Maybe<Array<Maybe<SearchTypeReponse>>>;
    getAppAppNameByID?: Maybe<AppAppName>;
    getAppRolePolicy?: Maybe<Array<Maybe<AppRolePolicy>>>;
    getAppRolePolicyCount?: Maybe<Scalars['Int']>;
    getAppRolePolicyByID?: Maybe<AppRolePolicy>;
    testAppRolePolicy?: Maybe<Scalars['Int']>;
    getAppCompany?: Maybe<Array<Maybe<AppCompany>>>;
    getAppCompanyCount?: Maybe<Scalars['Int']>;
    getAppCompanyByID?: Maybe<AppCompany>;
    testAppCompany?: Maybe<Scalars['Int']>;
    getproductmanageByID?: Maybe<Productmanage>;
    searchProductManage?: Maybe<ListProductManage>;
    productManageList?: Maybe<ListProductManage>;
    testproductmanage?: Maybe<Scalars['Int']>;
    getprodhistory: Array<Maybe<Producthistory>>;
    searchProductBySku?: Maybe<Array<Maybe<Productmanage>>>;
    searchProductBySkufit?: Maybe<Array<Maybe<Productmanage>>>;
    searchProductByallSku?: Maybe<Array<Maybe<Productmanage>>>;
    autofillProduct?: Maybe<Array<Maybe<Productmanage>>>;
    getproductproto?: Maybe<Array<Maybe<Productproto>>>;
    getproductprotoCount?: Maybe<Scalars['Int']>;
    getproductprotoByID?: Maybe<Productproto>;
    testproductproto?: Maybe<Scalars['Int']>;
    getregistration?: Maybe<Array<Maybe<Registration>>>;
    getregistrationCount?: Maybe<Scalars['Int']>;
    getregistrationByID?: Maybe<Registration>;
    testregistration?: Maybe<Scalars['Int']>;
    getbox?: Maybe<Array<Maybe<Box>>>;
    getboxCount?: Maybe<Scalars['Int']>;
    getboxByID?: Maybe<Box>;
    testbox?: Maybe<Scalars['Int']>;
    getregtrans?: Maybe<Array<Maybe<Regtrans>>>;
    getregtransCount?: Maybe<Scalars['Int']>;
    getregtransByID?: Maybe<Regtrans>;
    testregtrans?: Maybe<Scalars['Int']>;
    gettrans?: Maybe<Array<Maybe<Trans>>>;
    gettransCount?: Maybe<Scalars['Int']>;
    gettransByID?: Maybe<Trans>;
    testtrans?: Maybe<Scalars['Int']>;
    gets_file_info?: Maybe<Array<Maybe<S_File_Info>>>;
    gets_file_infoCount?: Maybe<Scalars['Int']>;
    gets_file_infoByID?: Maybe<S_File_Info>;
    tests_file_info?: Maybe<Scalars['Int']>;
    getxportOrderFileInfo?: Maybe<Array<Maybe<XportOrderFileInfo>>>;
    getxportOrderFileInfoCount?: Maybe<Scalars['Int']>;
    getxportOrderFileInfoByID?: Maybe<XportOrderFileInfo>;
    getxportOrderPicInfo?: Maybe<Array<Maybe<XportOrderPicInfo>>>;
    getxportOrderPicInfoCount?: Maybe<Scalars['Int']>;
    getxportOrderPicInfoByID?: Maybe<XportOrderPicInfo>;
    testxportOrderPicInfo?: Maybe<Scalars['Int']>;
    getXportOrderMainByID?: Maybe<XportOrderMain>;
    searchOrderMain?: Maybe<ListOrderMain>;
    orderMainList?: Maybe<ListOrderMain>;
    getcustomscheck?: Maybe<Array<Maybe<Customscheck>>>;
    getcustomscheckCount?: Maybe<Scalars['Int']>;
    getcustomscheckByID?: Maybe<Customscheck>;
    testcustomscheck?: Maybe<Scalars['Int']>;
    getCheckStatData?: Maybe<Array<Maybe<CheckStatInfo>>>;
    getheavyeqp?: Maybe<Array<Maybe<Heavyeqp>>>;
    getheavyeqpCount?: Maybe<Scalars['Int']>;
    getheavyeqpByID?: Maybe<Heavyeqp>;
    testheavyeqp?: Maybe<Scalars['Int']>;
    getheavyeqpbyhs?: Maybe<Array<Maybe<Heavyeqp>>>;
    getinvoicesku?: Maybe<Array<Maybe<Invoicesku>>>;
    getinvoiceskuCount?: Maybe<Scalars['Int']>;
    getinvoiceskuByID?: Maybe<Invoicesku>;
    testinvoicesku?: Maybe<Scalars['Int']>;
    getkddpreorderByID?: Maybe<Kddpreorder>;
    testkddpreorder?: Maybe<Scalars['Int']>;
    getkddpreorderByOrderNo?: Maybe<Kddpreorder>;
    getuniqueSepseqno?: Maybe<Scalars['Boolean']>;
    kddPreOrderDecList?: Maybe<ListKddPreOrder>;
    searchPreOrderDec?: Maybe<ListKddPreOrder>;
    scanExSplit?: Maybe<Array<Maybe<Kddpreorder>>>;
    scanSepInfo?: Maybe<Kddpreorder>;
    getProductCompanyList?: Maybe<ListProductCompany>;
    searchProductCompany?: Maybe<ListProductCompany>;
    getxportProductCompanyByID?: Maybe<ProductCompany>;
    testxportProductCompany?: Maybe<Scalars['Int']>;
    getKddPreOrderTemplateList?: Maybe<ListKddPreOrderTemplate>;
    getKddPreOrderTemplateByID?: Maybe<KddPreOrderTemplate>;
    searchKddPreOrderTemplate?: Maybe<ListKddPreOrderTemplate>;
    getSingleWindowDataByID?: Maybe<Kddpreorder>;
    searchSingleWindowDec?: Maybe<ListKddPreOrder>;
    getSingleWindowDecList?: Maybe<ListKddPreOrder>;
    getsinglewindowcustom?: Maybe<Array<Maybe<Singlewindowcustom>>>;
    getsinglewindowcustomCount?: Maybe<Scalars['Int']>;
    getsinglewindowcustomByID?: Maybe<Singlewindowcustom>;
    getsinglewindowcustomByEntryId?: Maybe<Array<Maybe<Singlewindowcustom>>>;
    testsinglewindowcustom?: Maybe<Scalars['Int']>;
    getTitMailByID?: Maybe<TitMail>;
    getMailsbyRefNo?: Maybe<Array<Maybe<TitMail>>>;
    searchTitMail?: Maybe<ListTitMail>;
    titMailList?: Maybe<ListTitMail>;
    getDecStatusList?: Maybe<ListDecStatus>;
    getDecStatusByID?: Maybe<DecStatus>;
    searchDecStatus?: Maybe<ListDecStatus>;
    getDecStatusByOrderNo?: Maybe<Array<Maybe<DecStatus>>>;
    uploads?: Maybe<Array<Maybe<File>>>;
    getRpaJobInfoList?: Maybe<ListRpaJobInfo>;
    getRpaJobInfoByID?: Maybe<RpaJobInfo>;
    getRpaProcessDetailsByJobNo?: Maybe<ListRpaJobProcessDetail>;
    getRpaJobInfoByTaskCode?: Maybe<ClientPorcessJobData>;
    searchRpaJobInfo?: Maybe<ListRpaJobInfo>;
    getRpaTaskDefList?: Maybe<ListRpaTaskDef>;
    getRpaTaskDefByID?: Maybe<RpaTaskDef>;
    searchRpaTaskDef?: Maybe<ListRpaTaskDef>;
    getdataLogList?: Maybe<Array<Maybe<DataLog>>>;
    getkddLogByID?: Maybe<Kddpreorder>;
    trackExpressBySearchNo?: Maybe<TrackBillInfo>;
    searchExpressOrderById?: Maybe<ExpressOrder>;
    getOssFileInfoList?: Maybe<ListOssFileInfo>;
    getOssFileInfoByID?: Maybe<OssFileInfo>;
    getOssFileInfoListByTags?: Maybe<ListOssFileInfo>;
    searchOssFileInfo?: Maybe<ListOssFileInfo>;
    getRootPath?: Maybe<Array<Maybe<OssPath>>>;
    listOssPath?: Maybe<ListOssFileInfo>;
    getFileInfoTags?: Maybe<Array<Maybe<Scalars['String']>>>;
    getHsRegexList?: Maybe<ListHsRegex>;
    getHsRegexByID?: Maybe<HsRegex>;
    searchHsRegex?: Maybe<ListHsRegex>;
    testhsinforegex?: Maybe<Scalars['Int']>;
    getValidationruleList?: Maybe<ListValidationrule>;
    getValidationruleByID?: Maybe<Validationrule>;
    searchValidationrule?: Maybe<ListValidationrule>;
    testvalidationrule?: Maybe<Scalars['Int']>;
  };


  export type QueryGetAppUsersArgs = {
    appName?: Maybe<Scalars['String']>;
    companyName?: Maybe<Scalars['String']>;
    searchStr?: Maybe<Scalars['String']>;
  };


  export type QueryGetAppUserByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryGetCsActiveOrderArgs = {
    csUserId?: Maybe<Scalars['String']>;
    companyName?: Maybe<Scalars['String']>;
    searchNo?: Maybe<Scalars['String']>;
    offset?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    csOrderStatus?: Maybe<CsSearchType>;
  };


  export type QueryGetCsActiveCountArgs = {
    csUserId?: Maybe<Scalars['String']>;
  };


  export type QueryGetClientStatusArgs = {
    clientid?: Maybe<Scalars['String']>;
  };


  export type QuerySearchDecHeadArgs = {
    clientid?: Maybe<Scalars['String']>;
    ieType?: Maybe<Scalars['String']>;
    searchType?: Maybe<Scalars['String']>;
    offset?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    companyName?: Maybe<Scalars['String']>;
    userName?: Maybe<Scalars['String']>;
    portName?: Maybe<Scalars['String']>;
  };


  export type QueryGetClientCountStatusArgs = {
    clientid?: Maybe<Scalars['String']>;
    ieType?: Maybe<Scalars['String']>;
    companyName?: Maybe<Scalars['String']>;
    userName?: Maybe<Scalars['String']>;
    portName?: Maybe<Scalars['String']>;
  };


  export type QueryGetDecDetailbyIdArgs = {
    sign?: Maybe<Scalars['String']>;
    companyName?: Maybe<Scalars['String']>;
    cusCiqNo?: Maybe<Scalars['String']>;
  };


  export type QueryGetParaByTypeArgs = {
    paraType?: Maybe<Scalars['String']>;
    searchStr?: Maybe<Scalars['String']>;
  };


  export type QueryGetParasArgs = {
    paraType?: Maybe<Scalars['String']>;
    searchStr?: Maybe<Scalars['String']>;
  };


  export type QueryJudgeRoleUrlArgs = {
    url?: Maybe<Scalars['String']>;
  };


  export type QueryGetHscodeParaArgs = {
    searchStr?: Maybe<Scalars['String']>;
  };


  export type QueryGetAppAppNameArgs = {
    searchStr?: Maybe<Scalars['String']>;
    searchType?: Maybe<Scalars['String']>;
  };


  export type QueryGetAppAppNameCountArgs = {
    searchType?: Maybe<Scalars['String']>;
  };


  export type QueryGetAppAppNameByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryGetAppRolePolicyArgs = {
    AppID?: Maybe<Scalars['String']>;
    CompanyName?: Maybe<Scalars['String']>;
    RoleName?: Maybe<Scalars['String']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetAppRolePolicyCountArgs = {
    AppID?: Maybe<Scalars['String']>;
    CompanyName?: Maybe<Scalars['String']>;
    RoleName?: Maybe<Scalars['String']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetAppRolePolicyByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryTestAppRolePolicyArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type QueryGetAppCompanyArgs = {
    companyName?: Maybe<Scalars['String']>;
    customCode?: Maybe<Scalars['String']>;
    tradeCodeSCC?: Maybe<Scalars['String']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetAppCompanyCountArgs = {
    companyName?: Maybe<Scalars['String']>;
    customCode?: Maybe<Scalars['String']>;
    tradeCodeSCC?: Maybe<Scalars['String']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetAppCompanyByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryTestAppCompanyArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type QueryGetproductmanageByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QuerySearchProductManageArgs = {
    searchStr?: Maybe<Scalars['String']>;
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryProductManageListArgs = {
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryTestproductmanageArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type QueryGetprodhistoryArgs = {
    prodid?: Maybe<Scalars['String']>;
  };


  export type QuerySearchProductBySkuArgs = {
    searchStr?: Maybe<Scalars['String']>;
    tradeNameCn?: Maybe<Scalars['String']>;
  };


  export type QuerySearchProductBySkufitArgs = {
    searchStr?: Maybe<Scalars['String']>;
    tradeNameCn?: Maybe<Scalars['String']>;
  };


  export type QuerySearchProductByallSkuArgs = {
    searchStr?: Maybe<Scalars['String']>;
  };


  export type QueryAutofillProductArgs = {
    prodinfo?: Maybe<ProductmanageInput>;
  };


  export type QueryGetproductprotoArgs = {
    appID?: Maybe<Scalars['String']>;
    tradeNameCn?: Maybe<Scalars['String']>;
    materialPartNo?: Maybe<Scalars['String']>;
    nameCn?: Maybe<Scalars['String']>;
    hsCode?: Maybe<Scalars['String']>;
    tradeNameEn?: Maybe<Scalars['String']>;
    produceCoNameCn?: Maybe<Scalars['String']>;
    produceCoNameEn?: Maybe<Scalars['String']>;
    nameEn?: Maybe<Scalars['String']>;
    modelEn?: Maybe<Scalars['String']>;
    declareElementsStr?: Maybe<Scalars['String']>;
    gUnitCn?: Maybe<Scalars['String']>;
    gUnitEn?: Maybe<Scalars['String']>;
    keyWord?: Maybe<Scalars['String']>;
    sourceType?: Maybe<Scalars['String']>;
    productType?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    remark?: Maybe<Scalars['String']>;
    unit1?: Maybe<Scalars['String']>;
    unit2?: Maybe<Scalars['String']>;
    controlmark?: Maybe<Scalars['String']>;
    tarifflag?: Maybe<Scalars['String']>;
    createPerson?: Maybe<Scalars['String']>;
    appCompany?: Maybe<Scalars['String']>;
    heavyeqp?: Maybe<Scalars['String']>;
    subsystem?: Maybe<Scalars['String']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    mbdeclareElementsStr?: Maybe<Scalars['String']>;
    ciqCode?: Maybe<Scalars['String']>;
    ciqName?: Maybe<Scalars['String']>;
    nameDesc?: Maybe<Scalars['String']>;
    netWeight?: Maybe<Scalars['String']>;
    pageinfo?: Maybe<Paging>;
    protoName?: Maybe<Scalars['String']>;
  };


  export type QueryGetproductprotoCountArgs = {
    appID?: Maybe<Scalars['String']>;
    tradeNameCn?: Maybe<Scalars['String']>;
    materialPartNo?: Maybe<Scalars['String']>;
    nameCn?: Maybe<Scalars['String']>;
    hsCode?: Maybe<Scalars['String']>;
    tradeNameEn?: Maybe<Scalars['String']>;
    produceCoNameCn?: Maybe<Scalars['String']>;
    produceCoNameEn?: Maybe<Scalars['String']>;
    nameEn?: Maybe<Scalars['String']>;
    modelEn?: Maybe<Scalars['String']>;
    declareElementsStr?: Maybe<Scalars['String']>;
    gUnitCn?: Maybe<Scalars['String']>;
    gUnitEn?: Maybe<Scalars['String']>;
    keyWord?: Maybe<Scalars['String']>;
    sourceType?: Maybe<Scalars['String']>;
    productType?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    remark?: Maybe<Scalars['String']>;
    unit1?: Maybe<Scalars['String']>;
    unit2?: Maybe<Scalars['String']>;
    controlmark?: Maybe<Scalars['String']>;
    tarifflag?: Maybe<Scalars['String']>;
    createPerson?: Maybe<Scalars['String']>;
    appCompany?: Maybe<Scalars['String']>;
    heavyeqp?: Maybe<Scalars['String']>;
    subsystem?: Maybe<Scalars['String']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    mbdeclareElementsStr?: Maybe<Scalars['String']>;
    ciqCode?: Maybe<Scalars['String']>;
    ciqName?: Maybe<Scalars['String']>;
    nameDesc?: Maybe<Scalars['String']>;
    netWeight?: Maybe<Scalars['String']>;
    pageinfo?: Maybe<Paging>;
    protoName?: Maybe<Scalars['String']>;
  };


  export type QueryGetproductprotoByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryTestproductprotoArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type QueryGetregistrationArgs = {
    customs_status?: Maybe<Scalars['String']>;
    agent_name?: Maybe<Scalars['String']>;
    bill_no?: Maybe<Scalars['String']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetregistrationCountArgs = {
    customs_status?: Maybe<Scalars['String']>;
    agent_name?: Maybe<Scalars['String']>;
    bill_no?: Maybe<Scalars['String']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetregistrationByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryTestregistrationArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type QueryGetboxArgs = {
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetboxCountArgs = {
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetboxByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryTestboxArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type QueryGetregtransArgs = {
    truck_id?: Maybe<Scalars['String']>;
    driver_id?: Maybe<Scalars['String']>;
    transdt?: Maybe<Scalars['Date']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetregtransCountArgs = {
    truck_id?: Maybe<Scalars['String']>;
    driver_id?: Maybe<Scalars['String']>;
    transdt?: Maybe<Scalars['Date']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetregtransByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryTestregtransArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type QueryGettransArgs = {
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGettransCountArgs = {
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGettransByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryTesttransArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type QueryGets_File_InfoArgs = {
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGets_File_InfoCountArgs = {
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGets_File_InfoByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryTests_File_InfoArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type QueryGetxportOrderFileInfoArgs = {
    orderNo?: Maybe<Scalars['String']>;
    fileName?: Maybe<Scalars['String']>;
    OSSPath?: Maybe<Scalars['String']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetxportOrderFileInfoCountArgs = {
    orderNo?: Maybe<Scalars['String']>;
    fileName?: Maybe<Scalars['String']>;
    OSSPath?: Maybe<Scalars['String']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetxportOrderFileInfoByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryGetxportOrderPicInfoArgs = {
    GVFlag?: Maybe<Scalars['Int']>;
    orderNo?: Maybe<Scalars['String']>;
    OrginFileName?: Maybe<Scalars['String']>;
    fileName?: Maybe<Scalars['String']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetxportOrderPicInfoCountArgs = {
    GVFlag?: Maybe<Scalars['Int']>;
    orderNo?: Maybe<Scalars['String']>;
    OrginFileName?: Maybe<Scalars['String']>;
    fileName?: Maybe<Scalars['String']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetxportOrderPicInfoByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryTestxportOrderPicInfoArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type QueryGetXportOrderMainByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QuerySearchOrderMainArgs = {
    searchStr?: Maybe<Scalars['String']>;
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryOrderMainListArgs = {
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryGetcustomscheckArgs = {
    orderNo?: Maybe<Scalars['String']>;
    entryid?: Maybe<Scalars['String']>;
    checkmonth?: Maybe<Scalars['String']>;
    checkyear?: Maybe<Scalars['String']>;
    tradename?: Maybe<Scalars['String']>;
    ownername?: Maybe<Scalars['String']>;
    checkresultmemo?: Maybe<Scalars['String']>;
    consignorEname?: Maybe<Scalars['String']>;
    checkReason?: Maybe<Scalars['String']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetcustomscheckCountArgs = {
    orderNo?: Maybe<Scalars['String']>;
    entryid?: Maybe<Scalars['String']>;
    checkmonth?: Maybe<Scalars['String']>;
    checkyear?: Maybe<Scalars['String']>;
    tradename?: Maybe<Scalars['String']>;
    ownername?: Maybe<Scalars['String']>;
    checkresultmemo?: Maybe<Scalars['String']>;
    consignorEname?: Maybe<Scalars['String']>;
    checkReason?: Maybe<Scalars['String']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetcustomscheckByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryTestcustomscheckArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type QueryGetheavyeqpArgs = {
    hscode?: Maybe<Scalars['String']>;
    content?: Maybe<Scalars['String']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetheavyeqpCountArgs = {
    hscode?: Maybe<Scalars['String']>;
    content?: Maybe<Scalars['String']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetheavyeqpByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryTestheavyeqpArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type QueryGetheavyeqpbyhsArgs = {
    hscode?: Maybe<Scalars['String']>;
  };


  export type QueryGetinvoiceskuArgs = {
    invoiceno?: Maybe<Scalars['String']>;
    orderno?: Maybe<Scalars['String']>;
    Description?: Maybe<Scalars['String']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetinvoiceskuCountArgs = {
    invoiceno?: Maybe<Scalars['String']>;
    orderno?: Maybe<Scalars['String']>;
    Description?: Maybe<Scalars['String']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetinvoiceskuByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryTestinvoiceskuArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type QueryGetkddpreorderByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryTestkddpreorderArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type QueryGetkddpreorderByOrderNoArgs = {
    orderOutNo?: Maybe<Scalars['String']>;
  };


  export type QueryGetuniqueSepseqnoArgs = {
    sepSeqno?: Maybe<Scalars['String']>;
  };


  export type QueryKddPreOrderDecListArgs = {
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QuerySearchPreOrderDecArgs = {
    searchStr?: Maybe<Scalars['String']>;
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryScanExSplitArgs = {
    sepSeqno?: Maybe<Scalars['String']>;
  };


  export type QueryScanSepInfoArgs = {
    sepSeqno?: Maybe<Scalars['String']>;
  };


  export type QueryGetProductCompanyListArgs = {
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QuerySearchProductCompanyArgs = {
    searchStr?: Maybe<Scalars['String']>;
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryGetxportProductCompanyByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryTestxportProductCompanyArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type QueryGetKddPreOrderTemplateListArgs = {
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryGetKddPreOrderTemplateByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QuerySearchKddPreOrderTemplateArgs = {
    searchStr?: Maybe<Scalars['String']>;
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryGetSingleWindowDataByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QuerySearchSingleWindowDecArgs = {
    searchStr?: Maybe<Scalars['String']>;
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryGetSingleWindowDecListArgs = {
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryGetsinglewindowcustomArgs = {
    cusRetSeqNo?: Maybe<Scalars['String']>;
    spDecSeqNo?: Maybe<Scalars['String']>;
    entryId?: Maybe<Scalars['String']>;
    cusCiqNo?: Maybe<Scalars['String']>;
    noticeDate?: Maybe<Scalars['String']>;
    channel?: Maybe<Scalars['String']>;
    note?: Maybe<Scalars['String']>;
    procReason?: Maybe<Scalars['String']>;
    customMaster?: Maybe<Scalars['String']>;
    agentName?: Maybe<Scalars['String']>;
    dclrNo?: Maybe<Scalars['String']>;
    tradeCo?: Maybe<Scalars['String']>;
    customsField?: Maybe<Scalars['String']>;
    bonNo?: Maybe<Scalars['String']>;
    iEDate?: Maybe<Scalars['String']>;
    packNo?: Maybe<Scalars['String']>;
    billNo?: Maybe<Scalars['String']>;
    cusTrafMode?: Maybe<Scalars['String']>;
    trafName?: Maybe<Scalars['String']>;
    cusVoyageNo?: Maybe<Scalars['String']>;
    netWt?: Maybe<Scalars['String']>;
    grossWt?: Maybe<Scalars['String']>;
    dDate?: Maybe<Scalars['String']>;
    createPerson?: Maybe<Scalars['String']>;
    rcptDspsStuCode?: Maybe<Scalars['String']>;
    rcptDspsDtldDesc?: Maybe<Scalars['String']>;
    createUser?: Maybe<Scalars['String']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    indbTime?: Maybe<Scalars['String']>;
    updateUser?: Maybe<Scalars['String']>;
    updateTime?: Maybe<Scalars['String']>;
    isudMark?: Maybe<Scalars['String']>;
    rcptDspsStuCodeName?: Maybe<Scalars['String']>;
    channelName?: Maybe<Scalars['String']>;
    customMasterName?: Maybe<Scalars['String']>;
    cusRegNo?: Maybe<Scalars['String']>;
    cusRetSeqNos?: Maybe<Scalars['String']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetsinglewindowcustomCountArgs = {
    cusRetSeqNo?: Maybe<Scalars['String']>;
    spDecSeqNo?: Maybe<Scalars['String']>;
    entryId?: Maybe<Scalars['String']>;
    cusCiqNo?: Maybe<Scalars['String']>;
    noticeDate?: Maybe<Scalars['String']>;
    channel?: Maybe<Scalars['String']>;
    note?: Maybe<Scalars['String']>;
    procReason?: Maybe<Scalars['String']>;
    customMaster?: Maybe<Scalars['String']>;
    agentName?: Maybe<Scalars['String']>;
    dclrNo?: Maybe<Scalars['String']>;
    tradeCo?: Maybe<Scalars['String']>;
    customsField?: Maybe<Scalars['String']>;
    bonNo?: Maybe<Scalars['String']>;
    iEDate?: Maybe<Scalars['String']>;
    packNo?: Maybe<Scalars['String']>;
    billNo?: Maybe<Scalars['String']>;
    cusTrafMode?: Maybe<Scalars['String']>;
    trafName?: Maybe<Scalars['String']>;
    cusVoyageNo?: Maybe<Scalars['String']>;
    netWt?: Maybe<Scalars['String']>;
    grossWt?: Maybe<Scalars['String']>;
    dDate?: Maybe<Scalars['String']>;
    createPerson?: Maybe<Scalars['String']>;
    rcptDspsStuCode?: Maybe<Scalars['String']>;
    rcptDspsDtldDesc?: Maybe<Scalars['String']>;
    createUser?: Maybe<Scalars['String']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    indbTime?: Maybe<Scalars['String']>;
    updateUser?: Maybe<Scalars['String']>;
    updateTime?: Maybe<Scalars['String']>;
    isudMark?: Maybe<Scalars['String']>;
    rcptDspsStuCodeName?: Maybe<Scalars['String']>;
    channelName?: Maybe<Scalars['String']>;
    customMasterName?: Maybe<Scalars['String']>;
    cusRegNo?: Maybe<Scalars['String']>;
    cusRetSeqNos?: Maybe<Scalars['String']>;
    pageinfo?: Maybe<Paging>;
  };


  export type QueryGetsinglewindowcustomByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryGetsinglewindowcustomByEntryIdArgs = {
    entryId?: Maybe<Scalars['String']>;
  };


  export type QueryTestsinglewindowcustomArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type QueryGetTitMailByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryGetMailsbyRefNoArgs = {
    refNo?: Maybe<Scalars['String']>;
  };


  export type QuerySearchTitMailArgs = {
    searchStr?: Maybe<Scalars['String']>;
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryTitMailListArgs = {
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryGetDecStatusListArgs = {
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryGetDecStatusByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QuerySearchDecStatusArgs = {
    searchStr?: Maybe<Scalars['String']>;
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryGetDecStatusByOrderNoArgs = {
    orderNo?: Maybe<Scalars['String']>;
    refNo?: Maybe<Scalars['String']>;
  };


  export type QueryGetRpaJobInfoListArgs = {
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryGetRpaJobInfoByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryGetRpaProcessDetailsByJobNoArgs = {
    jobNo?: Maybe<Scalars['String']>;
    pageInfo?: Maybe<Paging>;
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  };


  export type QueryGetRpaJobInfoByTaskCodeArgs = {
    taskCode?: Maybe<Scalars['String']>;
    sortType?: Maybe<Scalars['Int']>;
  };


  export type QuerySearchRpaJobInfoArgs = {
    searchStr?: Maybe<Scalars['String']>;
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryGetRpaTaskDefListArgs = {
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryGetRpaTaskDefByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QuerySearchRpaTaskDefArgs = {
    searchStr?: Maybe<Scalars['String']>;
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryGetdataLogListArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryGetkddLogByIdArgs = {
    id?: Maybe<Scalars['String']>;
  };


  export type QueryTrackExpressBySearchNoArgs = {
    searchNo?: Maybe<Scalars['String']>;
    idNo?: Maybe<Scalars['String']>;
  };


  export type QuerySearchExpressOrderByIdArgs = {
    trackId?: Maybe<Scalars['String']>;
  };


  export type QueryGetOssFileInfoListArgs = {
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryGetOssFileInfoByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QueryGetOssFileInfoListByTagsArgs = {
    tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  };


  export type QuerySearchOssFileInfoArgs = {
    searchStr?: Maybe<Scalars['String']>;
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryListOssPathArgs = {
    path?: Maybe<Scalars['String']>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryGetFileInfoTagsArgs = {
    searchStr?: Maybe<Scalars['String']>;
  };


  export type QueryGetHsRegexListArgs = {
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryGetHsRegexByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QuerySearchHsRegexArgs = {
    searchStr?: Maybe<Scalars['String']>;
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryTesthsinforegexArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type QueryGetValidationruleListArgs = {
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryGetValidationruleByIdArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type QuerySearchValidationruleArgs = {
    searchStr?: Maybe<Scalars['String']>;
    conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
    pageInfo?: Maybe<Paging>;
  };


  export type QueryTestvalidationruleArgs = {
    test?: Maybe<Scalars['String']>;
  };

  export type AppUserInfo = {
    __typename?: 'AppUserInfo';
    id?: Maybe<Scalars['ID']>;
    mobileNo?: Maybe<Scalars['String']>;
    openID?: Maybe<Scalars['String']>;
    roles?: Maybe<Array<Maybe<Scalars['String']>>>;
    name?: Maybe<Scalars['String']>;
    memo?: Maybe<Scalars['String']>;
    companyName?: Maybe<Scalars['String']>;
    appID?: Maybe<Scalars['String']>;
    QRID?: Maybe<Scalars['String']>;
    activeDate?: Maybe<Scalars['Date']>;
    electronSign?: Maybe<Scalars['String']>;
    headImgUrl?: Maybe<Scalars['String']>;
    kuai00SocketID?: Maybe<Scalars['String']>;
    nickName?: Maybe<Scalars['String']>;
    validFlag?: Maybe<Scalars['Boolean']>;
    userName?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };


  export enum CsSearchType {
    Inprogress = 'INPROGRESS',
    Init = 'INIT',
    Csfinished = 'CSFINISHED',
    Aiprocessing = 'AIPROCESSING'
  }

  export type ActiveOrder = {
    __typename?: 'ActiveOrder';
    orderNo?: Maybe<Scalars['String']>;
    orderOutNo?: Maybe<Scalars['String']>;
    ieFlag?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    preEntryId?: Maybe<Scalars['String']>;
    entryId?: Maybe<Scalars['String']>;
    csUserInfo?: Maybe<KeyValue>;
    userMini?: Maybe<KeyValue>;
    coMini?: Maybe<KeyValue>;
    userOrderStatus?: Maybe<Scalars['Int']>;
    csOrderStatus?: Maybe<Scalars['Int']>;
    status?: Maybe<Scalars['Int']>;
  };

  export type KeyValue = {
    __typename?: 'KeyValue';
    name?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
  };

  export type CsActiverOrderCount = {
    __typename?: 'CSActiverOrderCount';
    inProcessCount?: Maybe<Scalars['Int']>;
    initCount?: Maybe<Scalars['Int']>;
    cSFinishedToday?: Maybe<Scalars['Int']>;
    cSFinishMonth?: Maybe<Scalars['Int']>;
    aIProcessingCount?: Maybe<Scalars['Int']>;
  };

  export type KdcSign = {
    __typename?: 'KdcSign';
    sign?: Maybe<Scalars['String']>;
    companyname?: Maybe<Scalars['String']>;
    watchpath?: Maybe<Scalars['String']>;
    sendpath?: Maybe<Scalars['String']>;
    CopCode?: Maybe<Scalars['String']>;
    mainflag?: Maybe<Scalars['Boolean']>;
    activeflag?: Maybe<Scalars['Boolean']>;
    workingflag?: Maybe<Scalars['Boolean']>;
    clienttype?: Maybe<Scalars['String']>;
    loginsites?: Maybe<SiteMap>;
    ICCode?: Maybe<Scalars['String']>;
    OperName?: Maybe<Scalars['String']>;
    UserName?: Maybe<Scalars['String']>;
  };

  export type SiteMap = {
    __typename?: 'SiteMap';
    singleWindow?: Maybe<SiteStatus>;
    hb56?: Maybe<SiteStatus>;
  };

  export type SiteStatus = {
    __typename?: 'SiteStatus';
    siteUrl?: Maybe<Scalars['String']>;
    siteStatus?: Maybe<Scalars['Boolean']>;
    lastUpdateDate?: Maybe<Scalars['String']>;
  };

  export type SingleWindowDecHead = {
    __typename?: 'SingleWindowDecHead';
    clientId?: Maybe<Scalars['String']>;
    cusCiqNo?: Maybe<Scalars['String']>;
    cusIEFlag?: Maybe<Scalars['String']>;
    cusDecStatus?: Maybe<Scalars['String']>;
    cusDecStatusName?: Maybe<Scalars['String']>;
    ciqDecStatus?: Maybe<Scalars['String']>;
    ciqDecStatusName?: Maybe<Scalars['String']>;
    spDecSeqNo?: Maybe<Scalars['String']>;
    entryId?: Maybe<Scalars['String']>;
    copNo?: Maybe<Scalars['String']>;
    entDeclNo?: Maybe<Scalars['String']>;
    rcvgdTradeCode?: Maybe<Scalars['String']>;
    consigneeCode?: Maybe<Scalars['String']>;
    consigneeCname?: Maybe<Scalars['String']>;
    cnsnTradeCode?: Maybe<Scalars['String']>;
    consignorCode?: Maybe<Scalars['String']>;
    consignorCname?: Maybe<Scalars['String']>;
    ownerCode?: Maybe<Scalars['String']>;
    ownerName?: Maybe<Scalars['String']>;
    billNo?: Maybe<Scalars['String']>;
    splitBillLadNo?: Maybe<Scalars['String']>;
    trafName?: Maybe<Scalars['String']>;
    cusVoyageNo?: Maybe<Scalars['String']>;
    ciqVoyageNo?: Maybe<Scalars['String']>;
    cusTradeNationCode?: Maybe<Scalars['String']>;
    ciqTradeCountryCode?: Maybe<Scalars['String']>;
    iEDate?: Maybe<Scalars['String']>;
    dDate?: Maybe<Scalars['String']>;
    declDate?: Maybe<Scalars['String']>;
    supvModeCdde?: Maybe<Scalars['String']>;
    tradeModeCode?: Maybe<Scalars['String']>;
    contrNo?: Maybe<Scalars['String']>;
    agentCode?: Maybe<Scalars['String']>;
    agentName?: Maybe<Scalars['String']>;
    customMaster?: Maybe<Scalars['String']>;
    inspOrgCode?: Maybe<Scalars['String']>;
    orgCode?: Maybe<Scalars['String']>;
    entUuid?: Maybe<Scalars['String']>;
    chktstFlag?: Maybe<Scalars['String']>;
    dataSrcCode?: Maybe<Scalars['String']>;
    dataSrcUnitCode?: Maybe<Scalars['String']>;
    dataSrcModeCode?: Maybe<Scalars['String']>;
    createUser?: Maybe<Scalars['String']>;
    indbTime?: Maybe<Scalars['String']>;
    updateUser?: Maybe<Scalars['String']>;
    updateTime?: Maybe<Scalars['String']>;
    ciqMessageId?: Maybe<Scalars['String']>;
    declRegNo?: Maybe<Scalars['String']>;
    declRegName?: Maybe<Scalars['String']>;
    transPreNo?: Maybe<Scalars['String']>;
    dclTrnRelFlag?: Maybe<Scalars['String']>;
    cusRemark?: Maybe<Scalars['String']>;
    inputEtpsCode?: Maybe<Scalars['String']>;
    extendField?: Maybe<Scalars['String']>;
    typistNo?: Maybe<Scalars['String']>;
    ciqBillNo?: Maybe<Scalars['String']>;
    indbTimeEnd?: Maybe<Scalars['String']>;
    updateTimeEnd?: Maybe<Scalars['String']>;
    dDateEnd?: Maybe<Scalars['String']>;
    declDateEnd?: Maybe<Scalars['String']>;
    cusTradeNationCodeName?: Maybe<Scalars['String']>;
    cusIEFlagName?: Maybe<Scalars['String']>;
    begin?: Maybe<Scalars['String']>;
    end?: Maybe<Scalars['String']>;
    stName?: Maybe<Scalars['String']>;
    stOrder?: Maybe<Scalars['String']>;
    supvModeCddeName?: Maybe<Scalars['String']>;
    shipperConsignee?: Maybe<Scalars['String']>;
    customMasterName?: Maybe<Scalars['String']>;
    goodsNum?: Maybe<Scalars['Int']>;
    queryPage?: Maybe<Scalars['String']>;
    orgCodeName?: Maybe<Scalars['String']>;
    statusList?: Maybe<Scalars['String']>;
    queryType?: Maybe<Scalars['String']>;
    dclrNo?: Maybe<Scalars['String']>;
    clBlNo?: Maybe<Scalars['String']>;
    contractNo?: Maybe<Scalars['String']>;
    ciqTradeCountryCodeName?: Maybe<Scalars['String']>;
    tradeModeCodeName?: Maybe<Scalars['String']>;
    inspPlaceCode?: Maybe<Scalars['String']>;
    despPortCodeName?: Maybe<Scalars['String']>;
    despPortCode?: Maybe<Scalars['String']>;
    contTel?: Maybe<Scalars['String']>;
    declPersonName?: Maybe<Scalars['String']>;
    ciqEntyPortCodeName?: Maybe<Scalars['String']>;
    ciqEntyPortCode?: Maybe<Scalars['String']>;
    grossWt?: Maybe<Scalars['String']>;
    packNo?: Maybe<Scalars['String']>;
    inputErName?: Maybe<Scalars['String']>;
    preEntryId?: Maybe<Scalars['String']>;
    noteS?: Maybe<Scalars['String']>;
    cusmPutrecNo?: Maybe<Scalars['String']>;
    inspPutrecNo?: Maybe<Scalars['String']>;
    declPersnCertNo?: Maybe<Scalars['String']>;
    operType?: Maybe<Scalars['String']>;
    certNo?: Maybe<Scalars['String']>;
    seqNoSignData?: Maybe<Scalars['String']>;
    iEPort?: Maybe<Scalars['String']>;
    iEPortName?: Maybe<Scalars['String']>;
    icCode?: Maybe<Scalars['String']>;
    indbUser?: Maybe<Scalars['String']>;
    etpsCategory?: Maybe<Scalars['String']>;
    etpsCategoryName?: Maybe<Scalars['String']>;
    consigneeEname?: Maybe<Scalars['String']>;
    consignorEname?: Maybe<Scalars['String']>;
    loginName?: Maybe<Scalars['String']>;
    relativeId?: Maybe<Scalars['String']>;
    tableFlag?: Maybe<Scalars['String']>;
    cusOrgCode?: Maybe<Scalars['String']>;
    ciqQueryFlag?: Maybe<Scalars['String']>;
    priUseGoodsType?: Maybe<Scalars['String']>;
    cnsnTradeScc?: Maybe<Scalars['String']>;
    rcvgdTradeScc?: Maybe<Scalars['String']>;
    ownerScc?: Maybe<Scalars['String']>;
    ownerCiqCode?: Maybe<Scalars['String']>;
    agentScc?: Maybe<Scalars['String']>;
    billType?: Maybe<Scalars['String']>;
    crawlbillinfo?: Maybe<CrawlBillInfo>;
    billCompareFlag?: Maybe<Scalars['Boolean']>;
    billCrawledFlag?: Maybe<Scalars['Boolean']>;
    validateFlag?: Maybe<Scalars['Boolean']>;
    userOpenID?: Maybe<Scalars['String']>;
  };

  export type CrawlBillInfo = {
    __typename?: 'CrawlBillInfo';
    billCrawlDate?: Maybe<Scalars['String']>;
    billPackNo?: Maybe<Scalars['String']>;
    billGrossWt?: Maybe<Scalars['String']>;
    billTrafName?: Maybe<Scalars['String']>;
    billCusVoyageNo?: Maybe<Scalars['String']>;
    billContainerList?: Maybe<Array<Maybe<CrawlBillContianer>>>;
  };

  export type CrawlBillContianer = {
    __typename?: 'CrawlBillContianer';
    containNo?: Maybe<Scalars['String']>;
    containtype?: Maybe<Scalars['String']>;
    lclFlag?: Maybe<Scalars['Boolean']>;
  };

  export type ClientStatCount = {
    __typename?: 'ClientStatCount';
    noCrawlCount?: Maybe<Scalars['Int']>;
    billCompareErrorCount?: Maybe<Scalars['Int']>;
    validateErrorCount?: Maybe<Scalars['Int']>;
    sendableCount?: Maybe<Scalars['Int']>;
    errorCount?: Maybe<Scalars['Int']>;
    markedCount?: Maybe<Scalars['Int']>;
    emergencyCout?: Maybe<Scalars['Int']>;
  };

  export type SingleWindowDecDetail = {
    __typename?: 'SingleWindowDecDetail';
    specDeclFlag?: Maybe<Scalars['String']>;
    inspOrgCodeName?: Maybe<Scalars['String']>;
    consigneeCname?: Maybe<Scalars['String']>;
    insurRate?: Maybe<Scalars['String']>;
    ciqDecStatusName?: Maybe<Scalars['String']>;
    ciqTradeCountryCode?: Maybe<Scalars['String']>;
    declCodeName?: Maybe<Scalars['String']>;
    orgCodeName?: Maybe<Scalars['String']>;
    customMasterName?: Maybe<Scalars['String']>;
    orgCode?: Maybe<Scalars['String']>;
    preDecCiqMarkLob?: Maybe<Scalars['String']>;
    contractNo?: Maybe<Scalars['String']>;
    cusCiqNo?: Maybe<Scalars['String']>;
    purpOrgCode?: Maybe<Scalars['String']>;
    preDecRequCertList?: Maybe<Scalars['String']>;
    rcvgdTradeCode?: Maybe<Scalars['String']>;
    despPortCode?: Maybe<Scalars['String']>;
    ownerCiqCode?: Maybe<Scalars['String']>;
    ownerCode?: Maybe<Scalars['String']>;
    preDecRoyaltyFee?: Maybe<Scalars['String']>;
    ciqDespCtryCode?: Maybe<Scalars['String']>;
    ownerName?: Maybe<Scalars['String']>;
    dataSrcModeCode?: Maybe<Scalars['String']>;
    decMergeListVo?: Maybe<Scalars['String']>;
    preDecUserList?: Maybe<Scalars['String']>;
    agentCode?: Maybe<Scalars['String']>;
    transMode?: Maybe<Scalars['String']>;
    cusTradeCountry?: Maybe<Scalars['String']>;
    insurMark?: Maybe<Scalars['String']>;
    vsaOrgCode?: Maybe<Scalars['String']>;
    dataSrcUnitCode?: Maybe<Scalars['String']>;
    ciqEntyPortCodeName?: Maybe<Scalars['String']>;
    isudMark?: Maybe<Scalars['String']>;
    tradeModeCode?: Maybe<Scalars['String']>;
    cusVoyageNo?: Maybe<Scalars['String']>;
    agentScc?: Maybe<Scalars['String']>;
    indbTime?: Maybe<Scalars['String']>;
    cusTradeCountryName?: Maybe<Scalars['String']>;
    extendField?: Maybe<Scalars['String']>;
    insurMarkName?: Maybe<Scalars['String']>;
    tradeModeCodeName?: Maybe<Scalars['String']>;
    cusTrafMode?: Maybe<Scalars['String']>;
    packNo?: Maybe<Scalars['String']>;
    preDecEntQualifListVo?: Maybe<Scalars['String']>;
    ciqIEFlag?: Maybe<Scalars['String']>;
    decOtherPacksVo?: Maybe<Scalars['String']>;
    contaCount?: Maybe<Scalars['String']>;
    declCode?: Maybe<Scalars['String']>;
    typistNo?: Maybe<Scalars['String']>;
    ciqTradeCountryCodeName?: Maybe<Scalars['String']>;
    isCopPromise?: Maybe<Scalars['String']>;
    entryTypeName?: Maybe<Scalars['String']>;
    ciqVoyageNo?: Maybe<Scalars['String']>;
    purpOrgCodeName?: Maybe<Scalars['String']>;
    vsaOrgCodeName?: Maybe<Scalars['String']>;
    gdsArvlDate?: Maybe<Scalars['String']>;
    goodsPlace?: Maybe<Scalars['String']>;
    iEDate?: Maybe<Scalars['String']>;
    agentName?: Maybe<Scalars['String']>;
    declRegNo?: Maybe<Scalars['String']>;
    supvModeCdde?: Maybe<Scalars['String']>;
    preDecContainerVo?: Maybe<Scalars['String']>;
    wrapType?: Maybe<Scalars['String']>;
    custmRegNo?: Maybe<Scalars['String']>;
    ownerScc?: Maybe<Scalars['String']>;
    tableFlag?: Maybe<Scalars['String']>;
    iEPort?: Maybe<Scalars['String']>;
    cusIEFlag?: Maybe<Scalars['String']>;
    attaDocuCdstr?: Maybe<Scalars['String']>;
    netWt?: Maybe<Scalars['String']>;
    billNo?: Maybe<Scalars['String']>;
    cusTradeNationCode?: Maybe<Scalars['String']>;
    ciqDespCtryCodeName?: Maybe<Scalars['String']>;
    despPortCodeName?: Maybe<Scalars['String']>;
    updateTime?: Maybe<Scalars['String']>;
    despDate?: Maybe<Scalars['String']>;
    preDecCiqDeclAttListVo?: Maybe<Scalars['String']>;
    dclTrnRelFlag?: Maybe<Scalars['String']>;
    inputEtpsCode?: Maybe<Scalars['String']>;
    inputErName?: Maybe<Scalars['String']>;
    createUser?: Maybe<Scalars['String']>;
    declRegName?: Maybe<Scalars['String']>;
    ciqEntyPortCode?: Maybe<Scalars['String']>;
    promiseItems?: Maybe<Scalars['String']>;
    dataSrcCode?: Maybe<Scalars['String']>;
    markNo?: Maybe<Scalars['String']>;
    portStopCode?: Maybe<Scalars['String']>;
    updateUser?: Maybe<Scalars['String']>;
    arrivPortCode?: Maybe<Scalars['String']>;
    transModeName?: Maybe<Scalars['String']>;
    ciqDestCode?: Maybe<Scalars['String']>;
    preDecDocVo?: Maybe<Scalars['String']>;
    cusDecStatus?: Maybe<Scalars['String']>;
    distinatePort?: Maybe<Scalars['String']>;
    cusTrafModeName?: Maybe<Scalars['String']>;
    portStopCodeName?: Maybe<Scalars['String']>;
    deliveryOrder?: Maybe<Scalars['String']>;
    cusDecStatusName?: Maybe<Scalars['String']>;
    ciqTrafMode?: Maybe<Scalars['String']>;
    cutModeName?: Maybe<Scalars['String']>;
    ciqTrafModeName?: Maybe<Scalars['String']>;
    wrapTypeName?: Maybe<Scalars['String']>;
    cutMode?: Maybe<Scalars['String']>;
    contrNo?: Maybe<Scalars['String']>;
    chktstFlag?: Maybe<Scalars['String']>;
    trafName?: Maybe<Scalars['String']>;
    inspOrgCode?: Maybe<Scalars['String']>;
    grossWt?: Maybe<Scalars['String']>;
    arrivPortCodeName?: Maybe<Scalars['String']>;
    consigneeCode?: Maybe<Scalars['String']>;
    rcvgdTradeScc?: Maybe<Scalars['String']>;
    customMaster?: Maybe<Scalars['String']>;
    cusRemark?: Maybe<Scalars['String']>;
    iEPortName?: Maybe<Scalars['String']>;
    cusLicenseListVo?: Maybe<Scalars['String']>;
    consignorEname?: Maybe<Scalars['String']>;
    districtCode?: Maybe<Scalars['String']>;
    entryType?: Maybe<Scalars['String']>;
    inputEtpsName?: Maybe<Scalars['String']>;
    supvModeCddeName?: Maybe<Scalars['String']>;
    cusTradeNationCodeName?: Maybe<Scalars['String']>;
    specPassFlag?: Maybe<Scalars['String']>;
    distinatePortName?: Maybe<Scalars['String']>;
    ciqDecStatus?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    orderNo?: Maybe<Scalars['String']>;
    preEntryId?: Maybe<Scalars['String']>;
  };

  export type ParaKeyValue = {
    __typename?: 'ParaKeyValue';
    key?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
    paraType?: Maybe<Scalars['String']>;
  };

  export type ParaValue = {
    __typename?: 'ParaValue';
    id?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['JSON']>;
    paraType?: Maybe<Scalars['String']>;
  };


  export type ClassifyInfo = {
    __typename?: 'ClassifyInfo';
    codeTs?: Maybe<Scalars['String']>;
    controlMark?: Maybe<Scalars['String']>;
    unitFlag?: Maybe<Scalars['String']>;
    ciqWtMeasUnit?: Maybe<Scalars['String']>;
    ciqWtMeasUnitName?: Maybe<Scalars['String']>;
    gname?: Maybe<Scalars['String']>;
    unit1?: Maybe<Scalars['String']>;
    dangerFlag?: Maybe<Scalars['String']>;
    unit1Name?: Maybe<Scalars['String']>;
    unit2?: Maybe<Scalars['String']>;
    outDutyTypeFlag?: Maybe<Scalars['String']>;
    inspMonitorCond?: Maybe<Scalars['String']>;
    ciqQtyMeasUnitName?: Maybe<Scalars['String']>;
    ciqQtyMeasUnit?: Maybe<Scalars['String']>;
    noteS?: Maybe<Scalars['String']>;
    unit2Name?: Maybe<Scalars['String']>;
    declareElements?: Maybe<Array<Maybe<DeclareElement>>>;
    ciqinfo?: Maybe<Array<Maybe<CiqInfo>>>;
    low_rate?: Maybe<Scalars['String']>;
    high_rate?: Maybe<Scalars['String']>;
    out_rate?: Maybe<Scalars['String']>;
    reg_rate?: Maybe<Scalars['String']>;
    tax_rate?: Maybe<Scalars['String']>;
    back_rate?: Maybe<Scalars['String']>;
    low_t_rate?: Maybe<Scalars['String']>;
    low_out_rate?: Maybe<Scalars['String']>;
    ret_rate?: Maybe<Scalars['String']>;
  };

  /** ## 申报要素 */
  export type DeclareElement = {
    __typename?: 'DeclareElement';
    requireCheck?: Maybe<Scalars['String']>;
    decFacCode?: Maybe<Scalars['String']>;
    codeTs?: Maybe<Scalars['String']>;
    decFacName?: Maybe<Scalars['String']>;
    gname?: Maybe<Scalars['String']>;
    decFacType?: Maybe<Scalars['String']>;
    ieFlag?: Maybe<Scalars['String']>;
    textName?: Maybe<Scalars['String']>;
    snum?: Maybe<Scalars['String']>;
    extendFiled?: Maybe<Scalars['String']>;
    decFacContent?: Maybe<Scalars['String']>;
  };

  /** ## 商检代码 */
  export type CiqInfo = {
    __typename?: 'CiqInfo';
    classifyCnnm?: Maybe<Scalars['String']>;
    statcode?: Maybe<Scalars['String']>;
    hscode?: Maybe<Scalars['String']>;
    hsname?: Maybe<Scalars['String']>;
    ciqname?: Maybe<Scalars['String']>;
  };

  export type AppAppName = {
    __typename?: 'AppAppName';
    id?: Maybe<Scalars['ID']>;
    appName?: Maybe<Scalars['String']>;
    appMemo?: Maybe<Scalars['String']>;
    appRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type SearchTypeReponse = {
    __typename?: 'SearchTypeReponse';
    coditionDisplayName?: Maybe<Scalars['String']>;
    coditionEnumName?: Maybe<Scalars['String']>;
    searchCount?: Maybe<Scalars['Int']>;
    conditionTooltip?: Maybe<Scalars['String']>;
  };

  export type Paging = {
    sortfield?: Maybe<Scalars['String']>;
    order?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    skip?: Maybe<Scalars['Int']>;
  };

  export type AppRolePolicy = {
    __typename?: 'AppRolePolicy';
    id?: Maybe<Scalars['ID']>;
    AppID?: Maybe<Scalars['String']>;
    CompanyName?: Maybe<Scalars['String']>;
    RoleName?: Maybe<Array<Maybe<Scalars['String']>>>;
    ActionName?: Maybe<Scalars['String']>;
    ActionType?: Maybe<Scalars['String']>;
    Memo?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type AppCompany = {
    __typename?: 'AppCompany';
    id?: Maybe<Scalars['ID']>;
    companyName?: Maybe<Scalars['String']>;
    customCode?: Maybe<Scalars['String']>;
    tradeCodeSCC?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type Productmanage = {
    __typename?: 'productmanage';
    id?: Maybe<Scalars['ID']>;
    appID?: Maybe<Scalars['String']>;
    tradeNameCn?: Maybe<Scalars['String']>;
    materialPartNo?: Maybe<Scalars['String']>;
    nameCn?: Maybe<Scalars['String']>;
    hsCode?: Maybe<Scalars['String']>;
    tradeNameEn?: Maybe<Scalars['String']>;
    produceCoNameCn?: Maybe<Scalars['String']>;
    produceCoNameEn?: Maybe<Scalars['String']>;
    nameEn?: Maybe<Scalars['String']>;
    modelEn?: Maybe<Scalars['String']>;
    declareElementsStr?: Maybe<Scalars['String']>;
    declareElements?: Maybe<Array<Maybe<DeclareEle>>>;
    gUnitCn?: Maybe<Scalars['String']>;
    gUnitEn?: Maybe<Scalars['String']>;
    keyWord?: Maybe<Scalars['String']>;
    sourceType?: Maybe<Scalars['String']>;
    productType?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    remark?: Maybe<Scalars['String']>;
    unit1?: Maybe<Scalars['String']>;
    unit1Name?: Maybe<Scalars['String']>;
    gUnitCnName?: Maybe<Scalars['String']>;
    unit2Name?: Maybe<Scalars['String']>;
    unit2?: Maybe<Scalars['String']>;
    controlmark?: Maybe<Scalars['String']>;
    tarifflag?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    appCompany?: Maybe<Scalars['String']>;
    heavyeqp?: Maybe<Scalars['String']>;
    subsystem?: Maybe<Scalars['String']>;
    mbdeclareElementsStr?: Maybe<Scalars['String']>;
    mbdeclareElements?: Maybe<Array<Maybe<DeclareEle>>>;
    ciqCode?: Maybe<Scalars['String']>;
    ciqName?: Maybe<Scalars['String']>;
    nameDesc?: Maybe<Scalars['String']>;
    netWeight?: Maybe<Scalars['Float']>;
    declPrice?: Maybe<Scalars['Float']>;
    productproto?: Maybe<Scalars['String']>;
    protoid?: Maybe<Scalars['String']>;
    operNames?: Maybe<Array<Maybe<Scalars['String']>>>;
    checkflag?: Maybe<Scalars['String']>;
    checkmemo?: Maybe<Scalars['String']>;
  };

  export type DeclareEle = {
    __typename?: 'DeclareEle';
    key?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
  };

  export type KeyValueInput = {
    id?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    values?: Maybe<Scalars['JSON']>;
  };

  export type ListProductManage = {
    __typename?: 'ListProductManage';
    totalCount?: Maybe<Scalars['Int']>;
    dataList?: Maybe<Array<Maybe<Productmanage>>>;
  };

  export type Producthistory = {
    __typename?: 'producthistory';
    id?: Maybe<Scalars['ID']>;
    prodid?: Maybe<Scalars['String']>;
    upmemo?: Maybe<Scalars['String']>;
    appID?: Maybe<Scalars['String']>;
    tradeNameCn?: Maybe<Scalars['String']>;
    materialPartNo?: Maybe<Scalars['String']>;
    nameCn?: Maybe<Scalars['String']>;
    hsCode?: Maybe<Scalars['String']>;
    tradeNameEn?: Maybe<Scalars['String']>;
    unit1Name?: Maybe<Scalars['String']>;
    gUnitCnName?: Maybe<Scalars['String']>;
    unit2Name?: Maybe<Scalars['String']>;
    produceCoNameCn?: Maybe<Scalars['String']>;
    produceCoNameEn?: Maybe<Scalars['String']>;
    nameEn?: Maybe<Scalars['String']>;
    modelEn?: Maybe<Scalars['String']>;
    declareElementsStr?: Maybe<Scalars['String']>;
    declareElements?: Maybe<Array<Maybe<DeclareEle>>>;
    gUnitCn?: Maybe<Scalars['String']>;
    gUnitEn?: Maybe<Scalars['String']>;
    keyWord?: Maybe<Scalars['String']>;
    sourceType?: Maybe<Scalars['String']>;
    productType?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    remark?: Maybe<Scalars['String']>;
    unit1?: Maybe<Scalars['String']>;
    unit2?: Maybe<Scalars['String']>;
    controlmark?: Maybe<Scalars['String']>;
    tarifflag?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    appCompany?: Maybe<Scalars['String']>;
    heavyeqp?: Maybe<Scalars['String']>;
    subsystem?: Maybe<Scalars['String']>;
    mbdeclareElementsStr?: Maybe<Scalars['String']>;
    mbdeclareElements?: Maybe<Array<Maybe<DeclareEle>>>;
    ciqCode?: Maybe<Scalars['String']>;
    ciqName?: Maybe<Scalars['String']>;
    nameDesc?: Maybe<Scalars['String']>;
    netWeight?: Maybe<Scalars['Float']>;
    declPrice?: Maybe<Scalars['Float']>;
    productproto?: Maybe<Scalars['String']>;
    protoid?: Maybe<Scalars['String']>;
    operNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  export type ProductmanageInput = {
    id?: Maybe<Scalars['ID']>;
    appID?: Maybe<Scalars['String']>;
    tradeNameCn?: Maybe<Scalars['String']>;
    materialPartNo?: Maybe<Scalars['String']>;
    nameCn?: Maybe<Scalars['String']>;
    hsCode?: Maybe<Scalars['String']>;
    tradeNameEn?: Maybe<Scalars['String']>;
    produceCoNameCn?: Maybe<Scalars['String']>;
    produceCoNameEn?: Maybe<Scalars['String']>;
    nameEn?: Maybe<Scalars['String']>;
    modelEn?: Maybe<Scalars['String']>;
    declareElementsStr?: Maybe<Scalars['String']>;
    declareElements?: Maybe<Array<Maybe<DeclareEleInput>>>;
    gUnitCn?: Maybe<Scalars['String']>;
    gUnitEn?: Maybe<Scalars['String']>;
    keyWord?: Maybe<Scalars['String']>;
    sourceType?: Maybe<Scalars['String']>;
    productType?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    remark?: Maybe<Scalars['String']>;
    unit1?: Maybe<Scalars['String']>;
    unit2?: Maybe<Scalars['String']>;
    unit1Name?: Maybe<Scalars['String']>;
    gUnitCnName?: Maybe<Scalars['String']>;
    unit2Name?: Maybe<Scalars['String']>;
    controlmark?: Maybe<Scalars['String']>;
    tarifflag?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    appCompany?: Maybe<Scalars['String']>;
    heavyeqp?: Maybe<Scalars['String']>;
    subsystem?: Maybe<Scalars['String']>;
    mbdeclareElementsStr?: Maybe<Scalars['String']>;
    mbdeclareElements?: Maybe<Array<Maybe<DeclareEleInput>>>;
    ciqCode?: Maybe<Scalars['String']>;
    ciqName?: Maybe<Scalars['String']>;
    nameDesc?: Maybe<Scalars['String']>;
    netWeight?: Maybe<Scalars['Float']>;
    declPrice?: Maybe<Scalars['Float']>;
    productproto?: Maybe<Scalars['String']>;
    protoid?: Maybe<Scalars['String']>;
    operNames?: Maybe<Array<Maybe<Scalars['String']>>>;
    upmemo?: Maybe<Scalars['String']>;
    checkflag?: Maybe<Scalars['String']>;
    checkmemo?: Maybe<Scalars['String']>;
  };

  export type DeclareEleInput = {
    key?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
  };

  export type Productproto = {
    __typename?: 'productproto';
    id?: Maybe<Scalars['ID']>;
    appID?: Maybe<Scalars['String']>;
    tradeNameCn?: Maybe<Scalars['String']>;
    protoName?: Maybe<Scalars['String']>;
    materialPartNo?: Maybe<Scalars['String']>;
    nameCn?: Maybe<Scalars['String']>;
    hsCode?: Maybe<Scalars['String']>;
    tradeNameEn?: Maybe<Scalars['String']>;
    produceCoNameCn?: Maybe<Scalars['String']>;
    produceCoNameEn?: Maybe<Scalars['String']>;
    nameEn?: Maybe<Scalars['String']>;
    modelEn?: Maybe<Scalars['String']>;
    declareElementsStr?: Maybe<Scalars['String']>;
    declareElements?: Maybe<Array<Maybe<DeclareEle>>>;
    gUnitCn?: Maybe<Scalars['String']>;
    gUnitEn?: Maybe<Scalars['String']>;
    keyWord?: Maybe<Scalars['String']>;
    sourceType?: Maybe<Scalars['String']>;
    productType?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    remark?: Maybe<Scalars['String']>;
    unit1?: Maybe<Scalars['String']>;
    unit2?: Maybe<Scalars['String']>;
    controlmark?: Maybe<Scalars['String']>;
    tarifflag?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    appCompany?: Maybe<Scalars['String']>;
    heavyeqp?: Maybe<Scalars['String']>;
    subsystem?: Maybe<Scalars['String']>;
    mbdeclareElementsStr?: Maybe<Scalars['String']>;
    mbdeclareElements?: Maybe<Array<Maybe<DeclareEle>>>;
    ciqCode?: Maybe<Scalars['String']>;
    ciqName?: Maybe<Scalars['String']>;
    nameDesc?: Maybe<Scalars['String']>;
    netWeight?: Maybe<Scalars['Float']>;
    declPrice?: Maybe<Scalars['Float']>;
    operNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  export type Registration = {
    __typename?: 'registration';
    regtransSchemas?: Maybe<Array<Maybe<Regtrans>>>;
    comm20?: Maybe<Scalars['String']>;
    s_file_infoSchemas?: Maybe<Array<Maybe<S_File_Info>>>;
    boxSchemas?: Maybe<Array<Maybe<Box>>>;
    transSchemas?: Maybe<Array<Maybe<Trans>>>;
    comm19?: Maybe<Scalars['String']>;
    comm18?: Maybe<Scalars['String']>;
    comm17?: Maybe<Scalars['String']>;
    comm16?: Maybe<Scalars['String']>;
    comm15?: Maybe<Scalars['String']>;
    comm14?: Maybe<Scalars['String']>;
    comm13?: Maybe<Scalars['String']>;
    comm12?: Maybe<Scalars['String']>;
    comm11?: Maybe<Scalars['String']>;
    comm10?: Maybe<Scalars['String']>;
    comm9?: Maybe<Scalars['String']>;
    comm8?: Maybe<Scalars['String']>;
    comm7?: Maybe<Scalars['String']>;
    comm6?: Maybe<Scalars['String']>;
    comm5?: Maybe<Scalars['String']>;
    comm4?: Maybe<Scalars['String']>;
    comm3?: Maybe<Scalars['String']>;
    comm2?: Maybe<Scalars['String']>;
    comm1?: Maybe<Scalars['String']>;
    status18_time?: Maybe<Scalars['Date']>;
    status18_user?: Maybe<Scalars['String']>;
    status17_time?: Maybe<Scalars['Date']>;
    status17_user?: Maybe<Scalars['String']>;
    status16_time?: Maybe<Scalars['Date']>;
    status16_user?: Maybe<Scalars['String']>;
    status15_time?: Maybe<Scalars['Date']>;
    status15_user?: Maybe<Scalars['String']>;
    status14_time?: Maybe<Scalars['Date']>;
    status14_user?: Maybe<Scalars['String']>;
    status13_time?: Maybe<Scalars['Date']>;
    status13_user?: Maybe<Scalars['String']>;
    status12_time?: Maybe<Scalars['Date']>;
    status12_user?: Maybe<Scalars['String']>;
    status11_time?: Maybe<Scalars['Date']>;
    status11_user?: Maybe<Scalars['String']>;
    status10_time?: Maybe<Scalars['Date']>;
    status10_user?: Maybe<Scalars['String']>;
    status9_time?: Maybe<Scalars['Date']>;
    status9_user?: Maybe<Scalars['String']>;
    status8_time?: Maybe<Scalars['Date']>;
    status8_user?: Maybe<Scalars['String']>;
    status7_time?: Maybe<Scalars['Date']>;
    status7_user?: Maybe<Scalars['String']>;
    status6_time?: Maybe<Scalars['Date']>;
    status6_user?: Maybe<Scalars['String']>;
    status5_time?: Maybe<Scalars['Date']>;
    status5_user?: Maybe<Scalars['String']>;
    status4_time?: Maybe<Scalars['Date']>;
    status4_user?: Maybe<Scalars['String']>;
    status3_time?: Maybe<Scalars['Date']>;
    status3_user?: Maybe<Scalars['String']>;
    status2_time?: Maybe<Scalars['Date']>;
    status2_user?: Maybe<Scalars['String']>;
    status1_time?: Maybe<Scalars['Date']>;
    status1_user?: Maybe<Scalars['String']>;
    audit2_time?: Maybe<Scalars['Date']>;
    audit2_user?: Maybe<Scalars['String']>;
    audit1_time?: Maybe<Scalars['Date']>;
    audit1_user?: Maybe<Scalars['String']>;
    audit_flag?: Maybe<Scalars['String']>;
    spare_nine?: Maybe<Scalars['String']>;
    spare_einght?: Maybe<Scalars['String']>;
    spare_seven?: Maybe<Scalars['String']>;
    spare_six?: Maybe<Scalars['String']>;
    spare_five?: Maybe<Scalars['String']>;
    spare_four?: Maybe<Scalars['String']>;
    spare_three?: Maybe<Scalars['String']>;
    spare_two?: Maybe<Scalars['String']>;
    spare_one?: Maybe<Scalars['String']>;
    spare?: Maybe<Scalars['String']>;
    fourty_foot_standard_foot?: Maybe<Scalars['String']>;
    twenty_foot_standard_foot?: Maybe<Scalars['String']>;
    data_color?: Maybe<Scalars['String']>;
    export_sn?: Maybe<Scalars['String']>;
    independent_tax?: Maybe<Scalars['String']>;
    security_verification?: Maybe<Scalars['String']>;
    self_reported?: Maybe<Scalars['String']>;
    documents_attached?: Maybe<Scalars['String']>;
    progress?: Maybe<Scalars['String']>;
    declaration_unit?: Maybe<Scalars['String']>;
    consumption_unit?: Maybe<Scalars['String']>;
    shipper_consignee?: Maybe<Scalars['String']>;
    busi_type?: Maybe<Scalars['String']>;
    pre_entry_sn?: Maybe<Scalars['String']>;
    import_sn?: Maybe<Scalars['String']>;
    business_audit?: Maybe<Scalars['String']>;
    business_approval?: Maybe<Scalars['String']>;
    second_number?: Maybe<Scalars['String']>;
    legal_number?: Maybe<Scalars['String']>;
    transactions_number?: Maybe<Scalars['String']>;
    total_price?: Maybe<Scalars['String']>;
    goods?: Maybe<Scalars['String']>;
    document_code?: Maybe<Scalars['String']>;
    container?: Maybe<Scalars['String']>;
    yard_code?: Maybe<Scalars['String']>;
    regulatory_place?: Maybe<Scalars['String']>;
    related_record?: Maybe<Scalars['String']>;
    related_declaration_form?: Maybe<Scalars['String']>;
    royalties?: Maybe<Scalars['String']>;
    price_impact?: Maybe<Scalars['String']>;
    special_relationship?: Maybe<Scalars['String']>;
    customs_declaration_type?: Maybe<Scalars['String']>;
    container_number?: Maybe<Scalars['String']>;
    domestic_destination?: Maybe<Scalars['String']>;
    loading_port?: Maybe<Scalars['String']>;
    origin_country?: Maybe<Scalars['String']>;
    license_key?: Maybe<Scalars['String']>;
    tax_unit?: Maybe<Scalars['String']>;
    tax_rate?: Maybe<Scalars['String']>;
    application_date?: Maybe<Scalars['Date']>;
    import_date?: Maybe<Scalars['Date']>;
    record_number?: Maybe<Scalars['String']>;
    customs_number?: Maybe<Scalars['String']>;
    uniform_number?: Maybe<Scalars['String']>;
    ass_number?: Maybe<Scalars['String']>;
    customs_status?: Maybe<Scalars['String']>;
    edoc_size?: Maybe<Scalars['String']>;
    sign_time?: Maybe<Scalars['Date']>;
    edoc_cop_id?: Maybe<Scalars['String']>;
    op_note?: Maybe<Scalars['String']>;
    edoc_fomat_type?: Maybe<Scalars['String']>;
    edoc_code?: Maybe<Scalars['String']>;
    edoc_id?: Maybe<Scalars['String']>;
    certificate?: Maybe<Scalars['String']>;
    host_id?: Maybe<Scalars['String']>;
    sign_date?: Maybe<Scalars['String']>;
    sign?: Maybe<Scalars['String']>;
    client_seq_no?: Maybe<Scalars['String']>;
    oper_name?: Maybe<Scalars['String']>;
    ic_code?: Maybe<Scalars['String']>;
    oper_type?: Maybe<Scalars['String']>;
    voy_no?: Maybe<Scalars['String']>;
    rel_man_no?: Maybe<Scalars['String']>;
    rel_id?: Maybe<Scalars['String']>;
    dec_no?: Maybe<Scalars['String']>;
    dec_bp_no?: Maybe<Scalars['String']>;
    cus_fie?: Maybe<Scalars['String']>;
    trade_area_code?: Maybe<Scalars['String']>;
    promise_itmes?: Maybe<Scalars['String']>;
    bill_type?: Maybe<Scalars['String']>;
    check_flow?: Maybe<Scalars['String']>;
    chk_surety?: Maybe<Scalars['String']>;
    wrap_type?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    trans_mode?: Maybe<Scalars['String']>;
    traf_name?: Maybe<Scalars['String']>;
    traf_mode?: Maybe<Scalars['String']>;
    trade_mode?: Maybe<Scalars['String']>;
    trade_country?: Maybe<Scalars['String']>;
    tgd_no?: Maybe<Scalars['String']>;
    seq_no?: Maybe<Scalars['String']>;
    risk?: Maybe<Scalars['String']>;
    pre_entryId?: Maybe<Scalars['String']>;
    p_date?: Maybe<Scalars['String']>;
    partener_id?: Maybe<Scalars['String']>;
    payment_mark?: Maybe<Scalars['String']>;
    pay_way?: Maybe<Scalars['String']>;
    other_rate?: Maybe<Scalars['String']>;
    other_mark?: Maybe<Scalars['String']>;
    other_curr?: Maybe<Scalars['String']>;
    note_s?: Maybe<Scalars['String']>;
    net_wt?: Maybe<Scalars['String']>;
    manual_no?: Maybe<Scalars['String']>;
    license_no?: Maybe<Scalars['String']>;
    insur_rate?: Maybe<Scalars['String']>;
    insur_mark?: Maybe<Scalars['String']>;
    insur_curr?: Maybe<Scalars['String']>;
    in_ratio?: Maybe<Scalars['String']>;
    ie_flag?: Maybe<Scalars['String']>;
    fee_rate?: Maybe<Scalars['String']>;
    fee_mark?: Maybe<Scalars['String']>;
    fee_curr?: Maybe<Scalars['String']>;
    entry_type?: Maybe<Scalars['String']>;
    entry_id?: Maybe<Scalars['String']>;
    edi_id?: Maybe<Scalars['String']>;
    district_code?: Maybe<Scalars['String']>;
    distinate_port?: Maybe<Scalars['String']>;
    decl_trn_rel?: Maybe<Scalars['String']>;
    data_source?: Maybe<Scalars['String']>;
    cut_code?: Maybe<Scalars['String']>;
    custom_master?: Maybe<Scalars['String']>;
    appr_no?: Maybe<Scalars['String']>;
    cop_code_scc?: Maybe<Scalars['String']>;
    cop_name?: Maybe<Scalars['String']>;
    cop_code?: Maybe<Scalars['String']>;
    trade_code_scc?: Maybe<Scalars['String']>;
    trade_name?: Maybe<Scalars['String']>;
    trade_code?: Maybe<Scalars['String']>;
    owner_code_scc?: Maybe<Scalars['String']>;
    owner_name?: Maybe<Scalars['String']>;
    owner_code?: Maybe<Scalars['String']>;
    agent_code_scc?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    agent_code?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    update_time?: Maybe<Scalars['Date']>;
    update_name?: Maybe<Scalars['String']>;
    update_id?: Maybe<Scalars['String']>;
    create_time?: Maybe<Scalars['Date']>;
    inputer_name?: Maybe<Scalars['String']>;
    typist_no?: Maybe<Scalars['String']>;
    agent_name?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    bill_no?: Maybe<Scalars['String']>;
    contr_no?: Maybe<Scalars['String']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    gross_wet?: Maybe<Scalars['String']>;
    ie_date?: Maybe<Scalars['String']>;
    ie_port?: Maybe<Scalars['String']>;
    pack_no?: Maybe<Scalars['String']>;
    bon_no?: Maybe<Scalars['String']>;
  };

  export type Regtrans = {
    __typename?: 'regtrans';
    regtrans_id?: Maybe<Scalars['String']>;
    truck_id?: Maybe<Scalars['String']>;
    driver_id?: Maybe<Scalars['String']>;
    transdt?: Maybe<Scalars['Date']>;
    transer?: Maybe<Scalars['String']>;
    senddt?: Maybe<Scalars['Date']>;
    check_no?: Maybe<Scalars['String']>;
    receive_no?: Maybe<Scalars['String']>;
    comm1?: Maybe<Scalars['String']>;
    comm2?: Maybe<Scalars['String']>;
    comm3?: Maybe<Scalars['String']>;
    comm4?: Maybe<Scalars['String']>;
    comm5?: Maybe<Scalars['String']>;
    comm6?: Maybe<Scalars['String']>;
    comm7?: Maybe<Scalars['String']>;
    comm8?: Maybe<Scalars['String']>;
    comm9?: Maybe<Scalars['String']>;
    comm10?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type S_File_Info = {
    __typename?: 's_file_info';
    ref_id?: Maybe<Scalars['String']>;
    file_kind?: Maybe<Scalars['String']>;
    file_address?: Maybe<Scalars['String']>;
    remark?: Maybe<Scalars['String']>;
    pic_config?: Maybe<Scalars['String']>;
    latitude?: Maybe<Scalars['String']>;
    longitude?: Maybe<Scalars['String']>;
    file_dir?: Maybe<Scalars['String']>;
    file_size?: Maybe<Scalars['Int']>;
    file_mime?: Maybe<Scalars['String']>;
    file_url?: Maybe<Scalars['String']>;
    file_type?: Maybe<Scalars['Int']>;
    file_sha?: Maybe<Scalars['String']>;
    file_suffix?: Maybe<Scalars['String']>;
    file_name?: Maybe<Scalars['String']>;
    update_time?: Maybe<Scalars['Date']>;
    update_id?: Maybe<Scalars['String']>;
    create_time?: Maybe<Scalars['Date']>;
    create_id?: Maybe<Scalars['String']>;
    file_id?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type Box = {
    __typename?: 'box';
    comm10?: Maybe<Scalars['String']>;
    comm9?: Maybe<Scalars['String']>;
    comm8?: Maybe<Scalars['String']>;
    comm7?: Maybe<Scalars['String']>;
    comm6?: Maybe<Scalars['String']>;
    comm5?: Maybe<Scalars['String']>;
    comm4?: Maybe<Scalars['String']>;
    comm3?: Maybe<Scalars['String']>;
    comm2?: Maybe<Scalars['String']>;
    comm1?: Maybe<Scalars['String']>;
    box_lev?: Maybe<Scalars['String']>;
    ref_box_id?: Maybe<Scalars['String']>;
    box_updater?: Maybe<Scalars['String']>;
    box_updatedt?: Maybe<Scalars['Date']>;
    box_creater?: Maybe<Scalars['String']>;
    box_createdt?: Maybe<Scalars['Date']>;
    box_pno?: Maybe<Scalars['String']>;
    box_no?: Maybe<Scalars['String']>;
    regid?: Maybe<Scalars['String']>;
    box_id?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type Trans = {
    __typename?: 'trans';
    comm12?: Maybe<Scalars['String']>;
    comm11?: Maybe<Scalars['String']>;
    comm10?: Maybe<Scalars['String']>;
    comm9?: Maybe<Scalars['String']>;
    comm8?: Maybe<Scalars['String']>;
    comm7?: Maybe<Scalars['String']>;
    comm6?: Maybe<Scalars['String']>;
    comm5?: Maybe<Scalars['String']>;
    comm4?: Maybe<Scalars['String']>;
    comm3?: Maybe<Scalars['String']>;
    comm2?: Maybe<Scalars['String']>;
    comm1?: Maybe<Scalars['String']>;
    ctime?: Maybe<Scalars['Date']>;
    comment?: Maybe<Scalars['String']>;
    no?: Maybe<Scalars['Int']>;
    type?: Maybe<Scalars['String']>;
    ref_id?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type XportOrderFileInfo = {
    __typename?: 'xportOrderFileInfo';
    orderNo?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    seqNo?: Maybe<Scalars['Int']>;
    fileName?: Maybe<Scalars['String']>;
    OSSPath?: Maybe<Scalars['String']>;
    fileTypeName?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type XportOrderPicInfo = {
    __typename?: 'xportOrderPicInfo';
    id?: Maybe<Scalars['ID']>;
    orderNo?: Maybe<Scalars['String']>;
    OrginFileName?: Maybe<Scalars['String']>;
    fileName?: Maybe<Scalars['String']>;
    OSSPath?: Maybe<Scalars['String']>;
    seqNo?: Maybe<Scalars['Int']>;
    totalAmount?: Maybe<Scalars['Float']>;
    FileType?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    width?: Maybe<Scalars['Int']>;
    height?: Maybe<Scalars['Int']>;
    GVFlag?: Maybe<Scalars['Int']>;
  };

  export type XportOrderMain = {
    __typename?: 'XportOrderMain';
    id?: Maybe<Scalars['ID']>;
    orderNo?: Maybe<Scalars['String']>;
    orderOutNo?: Maybe<Scalars['String']>;
    appID?: Maybe<Scalars['String']>;
    appCompanyMini?: Maybe<KeyValue>;
    coUserMini?: Maybe<KeyValue>;
    processRouteType?: Maybe<Scalars['String']>;
    processFiles?: Maybe<Array<Maybe<Scalars['ID']>>>;
    memo?: Maybe<Scalars['String']>;
    ieDate?: Maybe<Scalars['Date']>;
    statusCode?: Maybe<Scalars['Int']>;
    supplier?: Maybe<Scalars['String']>;
    billNo?: Maybe<Scalars['String']>;
    xportYWType?: Maybe<Scalars['String']>;
    zhidantype?: Maybe<Scalars['String']>;
    statusName?: Maybe<Scalars['String']>;
    supplierName?: Maybe<Scalars['String']>;
    preOrderTemplateName?: Maybe<Scalars['String']>;
    fileProcessPercent?: Maybe<Scalars['Int']>;
    departInfo?: Maybe<KeyValue>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    custRefNo?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    custCompanyMini?: Maybe<KeyValue>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    refNo?: Maybe<Scalars['String']>;
    recTime?: Maybe<Scalars['Date']>;
    estFinishCustomDate?: Maybe<Scalars['Date']>;
  };

  export type ListOrderMain = {
    __typename?: 'ListOrderMain';
    totalCount?: Maybe<Scalars['Int']>;
    dataList?: Maybe<Array<Maybe<XportOrderMain>>>;
  };

  export type Customscheck = {
    __typename?: 'customscheck';
    id?: Maybe<Scalars['ID']>;
    orderNo?: Maybe<Scalars['String']>;
    entryid?: Maybe<Scalars['String']>;
    goodsname?: Maybe<Array<Maybe<Scalars['String']>>>;
    checkdate?: Maybe<Scalars['Date']>;
    checkmonth?: Maybe<Scalars['String']>;
    checkyear?: Maybe<Scalars['String']>;
    tradename?: Maybe<Scalars['String']>;
    ownername?: Maybe<Scalars['String']>;
    checkresult?: Maybe<Scalars['Boolean']>;
    checkresultmemo?: Maybe<Scalars['String']>;
    consignorEname?: Maybe<Scalars['String']>;
    checkReason?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type CheckStatInfo = {
    __typename?: 'checkStatInfo';
    year?: Maybe<Scalars['String']>;
    totalCount?: Maybe<Array<Maybe<Scalars['Int']>>>;
    checkCount?: Maybe<Array<Maybe<Scalars['Int']>>>;
    checkErrorCount?: Maybe<Array<Maybe<Scalars['Int']>>>;
    checkRate?: Maybe<Array<Maybe<Scalars['Float']>>>;
    checkErrorRate?: Maybe<Array<Maybe<Scalars['Float']>>>;
    stateTotal?: Maybe<Scalars['Int']>;
    stateCheckRate?: Maybe<Scalars['Float']>;
    stateCheckErrorRate?: Maybe<Scalars['Float']>;
    stateCheckCount?: Maybe<Scalars['Int']>;
    stateCheckErrorCount?: Maybe<Scalars['Int']>;
  };

  export type Heavyeqp = {
    __typename?: 'heavyeqp';
    id?: Maybe<Scalars['ID']>;
    hscode?: Maybe<Scalars['String']>;
    eqpclass?: Maybe<Scalars['String']>;
    eqptype?: Maybe<Scalars['String']>;
    eqpcategory?: Maybe<Scalars['String']>;
    eqpname?: Maybe<Scalars['String']>;
    firstlevel?: Maybe<Scalars['String']>;
    secondlevel?: Maybe<Scalars['String']>;
    singleusage?: Maybe<Array<Maybe<Singleusage>>>;
    expiredyear?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type Singleusage = {
    __typename?: 'singleusage';
    machinename?: Maybe<Scalars['String']>;
    machinemodel?: Maybe<Scalars['String']>;
    amount?: Maybe<Scalars['String']>;
  };

  export type Invoicesku = {
    __typename?: 'invoicesku';
    id?: Maybe<Scalars['ID']>;
    invoiceno?: Maybe<Scalars['String']>;
    orderno?: Maybe<Scalars['String']>;
    Description?: Maybe<Scalars['String']>;
    sku?: Maybe<Scalars['String']>;
    qty?: Maybe<Scalars['String']>;
    unitprice?: Maybe<Scalars['String']>;
    totalprice?: Maybe<Scalars['String']>;
    atr?: Maybe<Scalars['String']>;
    goodsdesc?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type Kddpreorder = {
    __typename?: 'kddpreorder';
    id?: Maybe<Scalars['ID']>;
    appID?: Maybe<Scalars['String']>;
    uploadfile?: Maybe<Scalars['String']>;
    orderOutNo?: Maybe<Scalars['String']>;
    refNo?: Maybe<Scalars['String']>;
    templateName?: Maybe<Scalars['String']>;
    parentOrder?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    chkSurety?: Maybe<Scalars['String']>;
    orderNo?: Maybe<Scalars['String']>;
    orderNo1?: Maybe<Scalars['String']>;
    sepSeqno?: Maybe<Scalars['String']>;
    departCode?: Maybe<Scalars['String']>;
    departName?: Maybe<Scalars['String']>;
    drawtotalAmount?: Maybe<Scalars['Float']>;
    ordertype?: Maybe<Scalars['String']>;
    zhidantype?: Maybe<Scalars['String']>;
    splitLeft?: Maybe<Scalars['Float']>;
    mianbaoFlag?: Maybe<Scalars['String']>;
    stockFlag?: Maybe<Scalars['String']>;
    goodsCustname?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    cusIEFlagName?: Maybe<Scalars['String']>;
    preEntryId?: Maybe<Scalars['String']>;
    entryId?: Maybe<Scalars['String']>;
    noOtherPack?: Maybe<Scalars['String']>;
    entQualifTypeCodeS?: Maybe<Scalars['String']>;
    entQualifTypeCodeSName?: Maybe<Scalars['String']>;
    ciqBillNo?: Maybe<Scalars['String']>;
    correlationDeclNo?: Maybe<Scalars['String']>;
    correlationReasonFlagName?: Maybe<Scalars['String']>;
    specDeclFlagInput?: Maybe<Scalars['String']>;
    appCert?: Maybe<Scalars['String']>;
    procmessage?: Maybe<Scalars['String']>;
    declDate?: Maybe<Scalars['String']>;
    decOtherPacksVo?: Maybe<Array<Maybe<DecOtherPacksVo>>>;
    decMergeListVo?: Maybe<Array<Maybe<DecMergeListVo>>>;
    cmbdecMergeListVo?: Maybe<Array<Maybe<DecMergeListVo>>>;
    preDecEntQualifListVo?: Maybe<Array<Maybe<PreDecEntQualifListVo>>>;
    memoInstock?: Maybe<Scalars['String']>;
    operNames?: Maybe<Array<Maybe<Scalars['String']>>>;
    custNo?: Maybe<Scalars['String']>;
    cmbCutMode?: Maybe<Scalars['String']>;
    storeOutNo?: Maybe<Scalars['String']>;
    statusName?: Maybe<Scalars['String']>;
    statusCode?: Maybe<Scalars['Int']>;
    sfserial?: Maybe<Scalars['String']>;
    flighttype?: Maybe<Scalars['String']>;
    preDecRequCertList?: Maybe<Array<Maybe<PreDecRequCert>>>;
    /** add */
    spDecSeqNo?: Maybe<Scalars['String']>;
    cnsnTradeCode?: Maybe<Scalars['String']>;
    cnsnTradeScc?: Maybe<Scalars['String']>;
    coOwner?: Maybe<Scalars['String']>;
    ciqDeparPortCode?: Maybe<Scalars['String']>;
    paymentMark?: Maybe<Scalars['String']>;
    relmanNo?: Maybe<Scalars['String']>;
    advDclcusTrnDspsMark?: Maybe<Scalars['String']>;
    checkFlow?: Maybe<Scalars['String']>;
    inRatio?: Maybe<Scalars['String']>;
    apprNo?: Maybe<Scalars['String']>;
    guaranteePermit?: Maybe<Scalars['String']>;
    dclrNo?: Maybe<Scalars['String']>;
    dclrCntway?: Maybe<Scalars['String']>;
    ediId?: Maybe<Scalars['String']>;
    entDeclNo?: Maybe<Scalars['String']>;
    entUuid?: Maybe<Scalars['String']>;
    consigneeAddr?: Maybe<Scalars['String']>;
    counterClaim?: Maybe<Scalars['String']>;
    inspPlaceCode?: Maybe<Scalars['String']>;
    declPersnCertNo?: Maybe<Scalars['String']>;
    declPersonName?: Maybe<Scalars['String']>;
    contactperson?: Maybe<Scalars['String']>;
    contTel?: Maybe<Scalars['String']>;
    attaCollectName?: Maybe<Scalars['String']>;
    totalValUs?: Maybe<Scalars['String']>;
    totalValCn?: Maybe<Scalars['String']>;
    contCancelFlag?: Maybe<Scalars['String']>;
    /** correlationDeclNo :String #// , */
    correlationReasonFlag?: Maybe<Scalars['String']>;
    splitBillLadNo?: Maybe<Scalars['String']>;
    clBlNo?: Maybe<Scalars['String']>;
    retNote?: Maybe<Scalars['String']>;
    copNo?: Maybe<Scalars['String']>;
    cusRemark?: Maybe<Scalars['String']>;
    billType?: Maybe<Scalars['String']>;
    ciqIsudMark?: Maybe<Scalars['String']>;
    clientId?: Maybe<Scalars['String']>;
    transPreNo?: Maybe<Scalars['String']>;
    /** ciqBillNo :String #// null, */
    ciqMessageId?: Maybe<Scalars['String']>;
    declareFlag?: Maybe<Scalars['String']>;
    ciqEtpsRegNo?: Maybe<Scalars['String']>;
    verFlags?: Maybe<Scalars['String']>;
    tgdNo?: Maybe<Scalars['String']>;
    declareName?: Maybe<Scalars['String']>;
    risk?: Maybe<Scalars['String']>;
    ediRemark?: Maybe<Scalars['String']>;
    ediRemark2?: Maybe<Scalars['String']>;
    priUseGoodsType?: Maybe<Scalars['String']>;
    twoStepFlag?: Maybe<Scalars['String']>;
    confirmPerson?: Maybe<Scalars['String']>;
    confirmDate?: Maybe<Scalars['Date']>;
    preDecContainerVo?: Maybe<Array<Maybe<SwPreDecContainerVo>>>;
    cusLicenseListVo?: Maybe<Array<Maybe<SwCusLicenseListVo>>>;
    preDecDocVo?: Maybe<Array<Maybe<SwPreDecDocVo>>>;
    specDeclFlag?: Maybe<Scalars['String']>;
    inspOrgCodeName?: Maybe<Scalars['String']>;
    consigneeCname?: Maybe<Scalars['String']>;
    bonNo?: Maybe<Scalars['String']>;
    otherRate?: Maybe<Scalars['String']>;
    insurRate?: Maybe<Scalars['String']>;
    ciqDecStatusName?: Maybe<Scalars['String']>;
    ciqTradeCountryCode?: Maybe<Scalars['String']>;
    declCodeName?: Maybe<Scalars['String']>;
    orgCodeName?: Maybe<Scalars['String']>;
    orgCode?: Maybe<Scalars['String']>;
    customMasterName?: Maybe<Scalars['String']>;
    preDecCiqMarkLob?: Maybe<Scalars['String']>;
    contractNo?: Maybe<Scalars['String']>;
    purpOrgCode?: Maybe<Scalars['String']>;
    noteS?: Maybe<Scalars['String']>;
    mbnoteS?: Maybe<Scalars['String']>;
    showSepNo?: Maybe<Scalars['String']>;
    mProjectNo?: Maybe<Scalars['String']>;
    mbcontrNo?: Maybe<Scalars['String']>;
    finishdate?: Maybe<Scalars['Date']>;
    cusCiqNo?: Maybe<Scalars['String']>;
    rcvgdTradeCode?: Maybe<Scalars['String']>;
    ownerCiqCode?: Maybe<Scalars['String']>;
    despPortCode?: Maybe<Scalars['String']>;
    ownerCode?: Maybe<Scalars['String']>;
    origBoxFlag?: Maybe<Scalars['String']>;
    preDecRoyaltyFee?: Maybe<Scalars['String']>;
    ownerName?: Maybe<Scalars['String']>;
    ciqDespCtryCode?: Maybe<Scalars['String']>;
    dataSrcModeCode?: Maybe<Scalars['String']>;
    applCopyQuan?: Maybe<Scalars['String']>;
    preDecUserList?: Maybe<Scalars['String']>;
    agentCode?: Maybe<Scalars['String']>;
    relativeId?: Maybe<Scalars['String']>;
    transMode?: Maybe<Scalars['String']>;
    cusTradeCountry?: Maybe<Scalars['String']>;
    insurMark?: Maybe<Scalars['String']>;
    vsaOrgCode?: Maybe<Scalars['String']>;
    ciqEntyPortCodeName?: Maybe<Scalars['String']>;
    dataSrcUnitCode?: Maybe<Scalars['String']>;
    isudMark?: Maybe<Scalars['String']>;
    feeMarkName?: Maybe<Scalars['String']>;
    tradeModeCode?: Maybe<Scalars['String']>;
    cusVoyageNo?: Maybe<Scalars['String']>;
    customsField?: Maybe<Scalars['String']>;
    agentScc?: Maybe<Scalars['String']>;
    indbTime?: Maybe<Scalars['String']>;
    cusTradeCountryName?: Maybe<Scalars['String']>;
    extendField?: Maybe<Scalars['String']>;
    insurMarkName?: Maybe<Scalars['String']>;
    tradeModeCodeName?: Maybe<Scalars['String']>;
    otherMarkName?: Maybe<Scalars['String']>;
    origBoxFlagName?: Maybe<Scalars['String']>;
    cusTrafMode?: Maybe<Scalars['String']>;
    consigneeEname?: Maybe<Scalars['String']>;
    appCertCode?: Maybe<Scalars['String']>;
    insurCurr?: Maybe<Scalars['String']>;
    packNo?: Maybe<Scalars['String']>;
    licenseNo?: Maybe<Scalars['String']>;
    manualNo?: Maybe<Scalars['String']>;
    dDate?: Maybe<Scalars['String']>;
    consignorCode?: Maybe<Scalars['String']>;
    consignorEname?: Maybe<Scalars['String']>;
    ciqIEFlag?: Maybe<Scalars['String']>;
    contaCount?: Maybe<Scalars['String']>;
    declCode?: Maybe<Scalars['String']>;
    typistNo?: Maybe<Scalars['String']>;
    ciqTradeCountryCodeName?: Maybe<Scalars['String']>;
    feeCurr?: Maybe<Scalars['String']>;
    isCopPromise?: Maybe<Scalars['String']>;
    entryTypeName?: Maybe<Scalars['String']>;
    ciqVoyageNo?: Maybe<Scalars['String']>;
    purpOrgCodeName?: Maybe<Scalars['String']>;
    vsaOrgCodeName?: Maybe<Scalars['String']>;
    feeRate?: Maybe<Scalars['String']>;
    gdsArvlDate?: Maybe<Scalars['String']>;
    goodsPlace?: Maybe<Scalars['String']>;
    iEDate?: Maybe<Scalars['String']>;
    agentName?: Maybe<Scalars['String']>;
    otherCurr?: Maybe<Scalars['String']>;
    declRegNo?: Maybe<Scalars['String']>;
    supvModeCdde?: Maybe<Scalars['String']>;
    wrapType?: Maybe<Scalars['String']>;
    custmRegNo?: Maybe<Scalars['String']>;
    ownerScc?: Maybe<Scalars['String']>;
    tableFlag?: Maybe<Scalars['String']>;
    iEPort?: Maybe<Scalars['String']>;
    cusIEFlag?: Maybe<Scalars['String']>;
    netWt?: Maybe<Scalars['String']>;
    attaDocuCdstr?: Maybe<Scalars['String']>;
    customsFieldName?: Maybe<Scalars['String']>;
    speclInspQuraRe?: Maybe<Scalars['String']>;
    consignorAddr?: Maybe<Scalars['String']>;
    billNo?: Maybe<Scalars['String']>;
    cusTradeNationCode?: Maybe<Scalars['String']>;
    ciqDespCtryCodeName?: Maybe<Scalars['String']>;
    despPortCodeName?: Maybe<Scalars['String']>;
    feeMark?: Maybe<Scalars['String']>;
    updateTime?: Maybe<Scalars['String']>;
    despDate?: Maybe<Scalars['String']>;
    preDecCiqDeclAttListVo?: Maybe<Scalars['String']>;
    dclTrnRelFlag?: Maybe<Scalars['String']>;
    inputEtpsCode?: Maybe<Scalars['String']>;
    inputErName?: Maybe<Scalars['String']>;
    otherCurrName?: Maybe<Scalars['String']>;
    createUser?: Maybe<Scalars['String']>;
    declRegName?: Maybe<Scalars['String']>;
    ciqEntyPortCode?: Maybe<Scalars['String']>;
    promiseItems?: Maybe<Scalars['String']>;
    dataSrcCode?: Maybe<Scalars['String']>;
    portStopCode?: Maybe<Scalars['String']>;
    markNo?: Maybe<Scalars['String']>;
    updateUser?: Maybe<Scalars['String']>;
    arrivPortCode?: Maybe<Scalars['String']>;
    applOri?: Maybe<Scalars['String']>;
    transModeName?: Maybe<Scalars['String']>;
    ciqDestCode?: Maybe<Scalars['String']>;
    consignorCname?: Maybe<Scalars['String']>;
    cusDecStatus?: Maybe<Scalars['String']>;
    distinatePort?: Maybe<Scalars['String']>;
    portStopCodeName?: Maybe<Scalars['String']>;
    cusTrafModeName?: Maybe<Scalars['String']>;
    deliveryOrder?: Maybe<Scalars['String']>;
    cusDecStatusName?: Maybe<Scalars['String']>;
    ciqTrafMode?: Maybe<Scalars['String']>;
    ciqTrafModeName?: Maybe<Scalars['String']>;
    cutModeName?: Maybe<Scalars['String']>;
    wrapTypeName?: Maybe<Scalars['String']>;
    cutMode?: Maybe<Scalars['String']>;
    otherMark?: Maybe<Scalars['String']>;
    contrNo?: Maybe<Scalars['String']>;
    chktstFlag?: Maybe<Scalars['String']>;
    trafName?: Maybe<Scalars['String']>;
    inspOrgCode?: Maybe<Scalars['String']>;
    grossWt?: Maybe<Scalars['String']>;
    arrivPortCodeName?: Maybe<Scalars['String']>;
    consigneeCode?: Maybe<Scalars['String']>;
    rcvgdTradeScc?: Maybe<Scalars['String']>;
    customMaster?: Maybe<Scalars['String']>;
    feeCurrName?: Maybe<Scalars['String']>;
    cmplDschrgDt?: Maybe<Scalars['String']>;
    iEPortName?: Maybe<Scalars['String']>;
    appCertName?: Maybe<Scalars['String']>;
    districtCode?: Maybe<Scalars['String']>;
    entryType?: Maybe<Scalars['String']>;
    inputEtpsName?: Maybe<Scalars['String']>;
    supvModeCddeName?: Maybe<Scalars['String']>;
    cusTradeNationCodeName?: Maybe<Scalars['String']>;
    specPassFlag?: Maybe<Scalars['String']>;
    insurCurrName?: Maybe<Scalars['String']>;
    distinatePortName?: Maybe<Scalars['String']>;
    ciqDecStatus?: Maybe<Scalars['String']>;
  };

  export type DecOtherPacksVo = {
    __typename?: 'decOtherPacksVo';
    packType?: Maybe<Scalars['String']>;
    packTypeCode?: Maybe<Scalars['String']>;
    packQty?: Maybe<Scalars['Int']>;
  };

  export type DecMergeListVo = {
    __typename?: 'decMergeListVo';
    sku?: Maybe<Scalars['String']>;
    realdeclTotal?: Maybe<Scalars['String']>;
    contrNo?: Maybe<Scalars['String']>;
    mbgModel?: Maybe<Scalars['String']>;
    netWeight?: Maybe<Scalars['String']>;
    qty?: Maybe<Scalars['String']>;
    subsystem?: Maybe<Scalars['String']>;
    nameDesc?: Maybe<Scalars['String']>;
    eqpname?: Maybe<Scalars['String']>;
    firstlevel?: Maybe<Scalars['String']>;
    secondlevel?: Maybe<Scalars['String']>;
    decMergeOrder?: Maybe<Scalars['String']>;
    combinebf?: Maybe<Scalars['String']>;
    ControlMark?: Maybe<Scalars['String']>;
    TarifFlag?: Maybe<Scalars['String']>;
    productid?: Maybe<Scalars['String']>;
    productupdateDate?: Maybe<Scalars['Date']>;
    pageindex?: Maybe<Scalars['Int']>;
    pageurl?: Maybe<Scalars['String']>;
    instockSku?: Maybe<Scalars['String']>;
    confidence?: Maybe<Scalars['String']>;
    drawamount?: Maybe<Scalars['Float']>;
    freeFlag?: Maybe<Scalars['String']>;
    sourceProdInfo?: Maybe<Array<Maybe<SplitProdDetail>>>;
    targetProdInfo?: Maybe<Array<Maybe<SplitProdDetail>>>;
    loctioninfo?: Maybe<Array<Maybe<OcrLoction>>>;
    classifyInfo?: Maybe<ClassifyListInfo>;
    extendjson?: Maybe<Scalars['JSON']>;
    dnno?: Maybe<Scalars['String']>;
    dnpage?: Maybe<Scalars['String']>;
    pageinvoice?: Maybe<Scalars['String']>;
    hzsku?: Maybe<Scalars['String']>;
    pageNetweight?: Maybe<Scalars['String']>;
    realdeclPrice?: Maybe<Scalars['String']>;
    namememo?: Maybe<Scalars['String']>;
    modflag?: Maybe<Scalars['Int']>;
    packTypeName?: Maybe<Scalars['String']>;
    goodsSpec?: Maybe<Scalars['String']>;
    codeTs?: Maybe<Scalars['String']>;
    goodsAttrName?: Maybe<Scalars['String']>;
    origPlaceCodeName?: Maybe<Scalars['String']>;
    purposeName?: Maybe<Scalars['String']>;
    ciqCurr?: Maybe<Scalars['String']>;
    cusOriginCountry?: Maybe<Scalars['String']>;
    qty1?: Maybe<Scalars['String']>;
    cusCiqNo?: Maybe<Scalars['String']>;
    dutyModeName?: Maybe<Scalars['String']>;
    goodsTotalVal?: Maybe<Scalars['String']>;
    dutyMode?: Maybe<Scalars['String']>;
    updateTime?: Maybe<Scalars['String']>;
    goodsBrand?: Maybe<Scalars['String']>;
    unit1?: Maybe<Scalars['String']>;
    unCode?: Maybe<Scalars['String']>;
    prodBatchNo?: Maybe<Scalars['String']>;
    gUnit?: Maybe<Scalars['String']>;
    packSpec?: Maybe<Scalars['String']>;
    contrItem?: Maybe<Scalars['String']>;
    qty2?: Maybe<Scalars['String']>;
    unit2Name?: Maybe<Scalars['String']>;
    unit2?: Maybe<Scalars['String']>;
    createUser?: Maybe<Scalars['String']>;
    tradeCurr?: Maybe<Scalars['String']>;
    stuff?: Maybe<Scalars['String']>;
    origPlaceCode?: Maybe<Scalars['String']>;
    destinationCountry?: Maybe<Scalars['String']>;
    declTotal?: Maybe<Scalars['String']>;
    codeTsName?: Maybe<Scalars['String']>;
    goodsAttr?: Maybe<Scalars['String']>;
    goodsModel?: Maybe<Scalars['String']>;
    indbTime?: Maybe<Scalars['String']>;
    updateUser?: Maybe<Scalars['String']>;
    gNo?: Maybe<Scalars['Int']>;
    produceDate?: Maybe<Scalars['String']>;
    gModel?: Maybe<Scalars['String']>;
    ciqWtMeasUnit?: Maybe<Scalars['String']>;
    ciqDestCode?: Maybe<Scalars['String']>;
    ciqWtMeasUnitName?: Maybe<Scalars['String']>;
    gUnitName?: Maybe<Scalars['String']>;
    purpose?: Maybe<Scalars['String']>;
    tradeCurrName?: Maybe<Scalars['String']>;
    destinationCountryName?: Maybe<Scalars['String']>;
    gQty?: Maybe<Scalars['String']>;
    declPrice?: Maybe<Scalars['String']>;
    gName?: Maybe<Scalars['String']>;
    goodsLegalInspectionMark?: Maybe<Scalars['String']>;
    noDangFlag?: Maybe<Scalars['String']>;
    prodValidDt?: Maybe<Scalars['String']>;
    exgVersion?: Maybe<Scalars['String']>;
    hsCodeDesc?: Maybe<Scalars['String']>;
    districtCodeName?: Maybe<Scalars['String']>;
    dangName?: Maybe<Scalars['String']>;
    ciqOriginCountry?: Maybe<Scalars['String']>;
    preDecCiqGoodsCont?: Maybe<Scalars['String']>;
    stdWeightUnitCode?: Maybe<Scalars['String']>;
    packType?: Maybe<Scalars['String']>;
    engManEntCnm?: Maybe<Scalars['String']>;
    ciqCurrName?: Maybe<Scalars['String']>;
    unit1Name?: Maybe<Scalars['String']>;
    prodQgp?: Maybe<Scalars['String']>;
    cusOriginCountryName?: Maybe<Scalars['String']>;
    districtCode?: Maybe<Scalars['String']>;
    ciqWeight?: Maybe<Scalars['String']>;
    exgNo?: Maybe<Scalars['String']>;
    noDangFlagName?: Maybe<Scalars['String']>;
    ciqName?: Maybe<Scalars['String']>;
    ciqDestCodeName?: Maybe<Scalars['String']>;
    ciqCode?: Maybe<Scalars['String']>;
    /** supList: [], */
    preDecCiqGoodsLimit?: Maybe<Array<Maybe<SwPreDecCiqGoodsLimit>>>;
  };

  export type SplitProdDetail = {
    __typename?: 'SplitProdDetail';
    orderNo?: Maybe<Scalars['String']>;
    gNo?: Maybe<Scalars['Int']>;
    orderOutNo?: Maybe<Scalars['String']>;
    qty?: Maybe<Scalars['Float']>;
  };

  export type OcrLoction = {
    __typename?: 'OcrLoction';
    field?: Maybe<Scalars['String']>;
    orivalue?: Maybe<Scalars['String']>;
    conceptKey?: Maybe<Scalars['String']>;
    topLeftX?: Maybe<Scalars['Float']>;
    topLeftY?: Maybe<Scalars['Float']>;
    bottomRightX?: Maybe<Scalars['Float']>;
    bottomRightY?: Maybe<Scalars['Float']>;
    confidence?: Maybe<Scalars['Float']>;
  };

  /** 税率 */
  export type ClassifyListInfo = {
    __typename?: 'classifyListInfo';
    codeTs?: Maybe<Scalars['String']>;
    unit1?: Maybe<Scalars['String']>;
    unit1Name?: Maybe<Scalars['String']>;
    unit2?: Maybe<Scalars['String']>;
    unit2Name?: Maybe<Scalars['String']>;
    unitFlag?: Maybe<Scalars['String']>;
    low_rate?: Maybe<Scalars['String']>;
    high_rate?: Maybe<Scalars['String']>;
    out_rate?: Maybe<Scalars['String']>;
    reg_rate?: Maybe<Scalars['String']>;
    reg_amount?: Maybe<Scalars['Float']>;
    tax_rate?: Maybe<Scalars['String']>;
    tax_amount?: Maybe<Scalars['Float']>;
    back_rate?: Maybe<Scalars['String']>;
    low_t_rate?: Maybe<Scalars['String']>;
    low_out_rate?: Maybe<Scalars['String']>;
    ret_rate?: Maybe<Scalars['String']>;
    cif_total_amount?: Maybe<Scalars['Float']>;
    extrange_rate_cny?: Maybe<Scalars['Float']>;
    in_amount?: Maybe<Scalars['Float']>;
    out_amount?: Maybe<Scalars['Float']>;
    ret_amount?: Maybe<Scalars['Float']>;
    total_tax_amount?: Maybe<Scalars['Float']>;
    fob_trans_fee?: Maybe<Scalars['Float']>;
    cusOriginCountry?: Maybe<Scalars['String']>;
  };

  /** ## 商检证书 */
  export type SwPreDecCiqGoodsLimit = {
    __typename?: 'SWPreDecCiqGoodsLimit';
    gNo?: Maybe<Scalars['String']>;
    goodsLimitSeqNo?: Maybe<Scalars['String']>;
    updateTime?: Maybe<Scalars['String']>;
    licWrtofUnit?: Maybe<Scalars['String']>;
    licWrtofQty?: Maybe<Scalars['String']>;
    licWrtofUnitName?: Maybe<Scalars['String']>;
    licenceNo?: Maybe<Scalars['String']>;
    createUser?: Maybe<Scalars['String']>;
    licTypeName?: Maybe<Scalars['String']>;
    licTypeCodeName?: Maybe<Scalars['String']>;
    cusCiqNo?: Maybe<Scalars['String']>;
    licTypeCode?: Maybe<Scalars['String']>;
    indbTime?: Maybe<Scalars['String']>;
    updateUser?: Maybe<Scalars['String']>;
    licWrtofDetailNo?: Maybe<Scalars['String']>;
    preDecCiqGoodsLimitVinVo?: Maybe<Array<Maybe<SwPreDecCiqGoodsLimitVinVo>>>;
  };

  export type SwPreDecCiqGoodsLimitVinVo = {
    __typename?: 'SWPreDecCiqGoodsLimitVinVo';
    gNo?: Maybe<Scalars['String']>;
    invoiceNum?: Maybe<Scalars['String']>;
    goodsLimitSeqNo?: Maybe<Scalars['String']>;
    updateTime?: Maybe<Scalars['String']>;
    prodEnnm?: Maybe<Scalars['String']>;
    vinNo?: Maybe<Scalars['String']>;
    invoiceNo?: Maybe<Scalars['String']>;
    motorNo?: Maybe<Scalars['String']>;
    goodsLimitVinSeqNo?: Maybe<Scalars['String']>;
    prodCnnm?: Maybe<Scalars['String']>;
    pricePerUnit?: Maybe<Scalars['String']>;
    licenceNo?: Maybe<Scalars['String']>;
    createUser?: Maybe<Scalars['String']>;
    qualityQgp?: Maybe<Scalars['String']>;
    billLadDate?: Maybe<Scalars['String']>;
    vinCode?: Maybe<Scalars['String']>;
    licTypeCodeName?: Maybe<Scalars['String']>;
    modelEn?: Maybe<Scalars['String']>;
    chassisNo?: Maybe<Scalars['String']>;
    cusCiqNo?: Maybe<Scalars['String']>;
    licTypeCode?: Maybe<Scalars['String']>;
    indbTime?: Maybe<Scalars['String']>;
    updateUser?: Maybe<Scalars['String']>;
  };

  export type PreDecEntQualifListVo = {
    __typename?: 'preDecEntQualifListVo';
    entQualifTypeCode?: Maybe<Scalars['String']>;
    entQualifTypeCodeName?: Maybe<Scalars['String']>;
    entQualifSeqNo?: Maybe<Scalars['String']>;
    entQualifNo?: Maybe<Scalars['String']>;
  };

  export type PreDecRequCert = {
    __typename?: 'preDecRequCert';
    requCertSeqNo?: Maybe<Scalars['String']>;
    applOri?: Maybe<Scalars['String']>;
    createUser?: Maybe<Scalars['String']>;
    appCertName?: Maybe<Scalars['String']>;
    updateTime?: Maybe<Scalars['String']>;
    applCopyQuan?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
    appCertCode?: Maybe<Scalars['String']>;
    cusCiqNo?: Maybe<Scalars['String']>;
    indbTime?: Maybe<Scalars['String']>;
    updateUser?: Maybe<Scalars['String']>;
  };

  /** ## 集装箱 */
  export type SwPreDecContainerVo = {
    __typename?: 'SWPreDecContainerVo';
    cntnrModeCode?: Maybe<Scalars['String']>;
    contSeqNo?: Maybe<Scalars['String']>;
    updateTime?: Maybe<Scalars['String']>;
    cntnrModeCodeName?: Maybe<Scalars['String']>;
    lclFlag?: Maybe<Scalars['String']>;
    goodsNo?: Maybe<Scalars['String']>;
    containerMdCodeName?: Maybe<Scalars['String']>;
    containerNo?: Maybe<Scalars['String']>;
    createUser?: Maybe<Scalars['String']>;
    lclFlagName?: Maybe<Scalars['String']>;
    cusCiqNo?: Maybe<Scalars['String']>;
    containerMdCode?: Maybe<Scalars['String']>;
    indbTime?: Maybe<Scalars['String']>;
    updateUser?: Maybe<Scalars['String']>;
    addList?: Maybe<Scalars['String']>;
    containerWt?: Maybe<Scalars['String']>;
  };

  export type SwCusLicenseListVo = {
    __typename?: 'SWCusLicenseListVo';
    createUser?: Maybe<Scalars['String']>;
    updateTime?: Maybe<Scalars['String']>;
    preDecCusEcoRel?: Maybe<Scalars['String']>;
    acmpFormCodeName?: Maybe<Scalars['String']>;
    formSeqNo?: Maybe<Scalars['String']>;
    cusCiqNo?: Maybe<Scalars['String']>;
    acmpFormCode?: Maybe<Scalars['String']>;
    acmpFormName?: Maybe<Scalars['String']>;
    indbTime?: Maybe<Scalars['String']>;
    acmpFormNo?: Maybe<Scalars['String']>;
    updateUser?: Maybe<Scalars['String']>;
  };

  export type SwPreDecDocVo = {
    __typename?: 'SWPreDecDocVo';
    attEdocId?: Maybe<Scalars['String']>;
    attEdocNo?: Maybe<Scalars['String']>;
    attEdocPath?: Maybe<Scalars['String']>;
    attEdocSize?: Maybe<Scalars['String']>;
    attEdocStatus?: Maybe<Scalars['String']>;
    attFmtTypeCode?: Maybe<Scalars['String']>;
    attSeqNo?: Maybe<Scalars['String']>;
    attTypeCode?: Maybe<Scalars['String']>;
    attTypeCodeName?: Maybe<Scalars['String']>;
    belongWkunitCode?: Maybe<Scalars['String']>;
    belongWkunitName?: Maybe<Scalars['String']>;
    bizTypeCode?: Maybe<Scalars['String']>;
    createUser?: Maybe<Scalars['String']>;
    cusCiqNo?: Maybe<Scalars['String']>;
    delList?: Maybe<Scalars['String']>;
    entOrigFileName?: Maybe<Scalars['String']>;
    indbTime?: Maybe<Scalars['String']>;
    signDate?: Maybe<Scalars['String']>;
    signWkunitCode?: Maybe<Scalars['String']>;
    updateTime?: Maybe<Scalars['String']>;
    updateUser?: Maybe<Scalars['String']>;
    uploadOpTypeCode?: Maybe<Scalars['String']>;
  };

  export type ListKddPreOrder = {
    __typename?: 'ListKddPreOrder';
    totalCount?: Maybe<Scalars['Int']>;
    dataList?: Maybe<Array<Maybe<Kddpreorder>>>;
  };

  export type ListProductCompany = {
    __typename?: 'ListProductCompany';
    totalCount?: Maybe<Scalars['Int']>;
    dataList?: Maybe<Array<Maybe<ProductCompany>>>;
  };

  export type ProductCompany = {
    __typename?: 'ProductCompany';
    id?: Maybe<Scalars['ID']>;
    supplierName?: Maybe<Scalars['String']>;
    suppplierEnName?: Maybe<Scalars['String']>;
    appID?: Maybe<Scalars['String']>;
    appCompany?: Maybe<Scalars['String']>;
    memo?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type ListKddPreOrderTemplate = {
    __typename?: 'ListKddPreOrderTemplate';
    totalCount?: Maybe<Scalars['Int']>;
    dataList?: Maybe<Array<Maybe<KddPreOrderTemplate>>>;
  };

  export type KddPreOrderTemplate = {
    __typename?: 'KddPreOrderTemplate';
    id?: Maybe<Scalars['ID']>;
    appID?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    memo?: Maybe<Scalars['String']>;
    templateConfig?: Maybe<Scalars['JSON']>;
    tempalteValue?: Maybe<Kddpreorder>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type Singlewindowcustom = {
    __typename?: 'singlewindowcustom';
    cusRetSeqNo?: Maybe<Scalars['String']>;
    spDecSeqNo?: Maybe<Scalars['String']>;
    entryId?: Maybe<Scalars['String']>;
    cusCiqNo?: Maybe<Scalars['String']>;
    noticeDate?: Maybe<Scalars['String']>;
    channel?: Maybe<Scalars['String']>;
    note?: Maybe<Scalars['String']>;
    procReason?: Maybe<Scalars['String']>;
    customMaster?: Maybe<Scalars['String']>;
    agentName?: Maybe<Scalars['String']>;
    dclrNo?: Maybe<Scalars['String']>;
    tradeCo?: Maybe<Scalars['String']>;
    customsField?: Maybe<Scalars['String']>;
    bonNo?: Maybe<Scalars['String']>;
    iEDate?: Maybe<Scalars['String']>;
    packNo?: Maybe<Scalars['String']>;
    billNo?: Maybe<Scalars['String']>;
    cusTrafMode?: Maybe<Scalars['String']>;
    trafName?: Maybe<Scalars['String']>;
    cusVoyageNo?: Maybe<Scalars['String']>;
    netWt?: Maybe<Scalars['String']>;
    grossWt?: Maybe<Scalars['String']>;
    dDate?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    rcptDspsStuCode?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    rcptDspsDtldDesc?: Maybe<Scalars['String']>;
    createUser?: Maybe<Scalars['String']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    indbTime?: Maybe<Scalars['String']>;
    updateUser?: Maybe<Scalars['String']>;
    updateTime?: Maybe<Scalars['String']>;
    isudMark?: Maybe<Scalars['String']>;
    rcptDspsStuCodeName?: Maybe<Scalars['String']>;
    channelName?: Maybe<Scalars['String']>;
    customMasterName?: Maybe<Scalars['String']>;
    cusRegNo?: Maybe<Scalars['String']>;
    cusRetSeqNos?: Maybe<Scalars['String']>;
  };

  export type TitMail = {
    __typename?: 'TitMail';
    id?: Maybe<Scalars['ID']>;
    orderNo?: Maybe<Scalars['String']>;
    orderOutNo?: Maybe<Scalars['String']>;
    mailId?: Maybe<Scalars['String']>;
    appID?: Maybe<Scalars['String']>;
    appCompanyMini?: Maybe<KeyValue>;
    coUserMini?: Maybe<KeyValue>;
    headerLines?: Maybe<Array<Maybe<KeyValue>>>;
    html?: Maybe<Scalars['String']>;
    text?: Maybe<Scalars['String']>;
    textAsHtml?: Maybe<Scalars['String']>;
    subject?: Maybe<Scalars['String']>;
    references?: Maybe<Array<Maybe<Scalars['String']>>>;
    date?: Maybe<Scalars['Date']>;
    to?: Maybe<MailAddress>;
    from?: Maybe<MailAddress>;
    cc?: Maybe<MailAddress>;
    messageId?: Maybe<Scalars['String']>;
    inReplyTo?: Maybe<Scalars['String']>;
    replyTo?: Maybe<MailAddress>;
    memo?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    refNo?: Maybe<Scalars['String']>;
    mailStatus?: Maybe<Scalars['String']>;
    attachments?: Maybe<Array<Maybe<KeyValue>>>;
    relatedRefNos?: Maybe<Array<Maybe<KeyValue>>>;
  };

  export type MailAddress = {
    __typename?: 'MailAddress';
    value?: Maybe<Array<Maybe<MailAddressBase>>>;
    html?: Maybe<Scalars['String']>;
    text?: Maybe<Scalars['String']>;
  };

  export type MailAddressBase = {
    __typename?: 'MailAddressBase';
    address?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
  };

  export type ListTitMail = {
    __typename?: 'ListTitMail';
    totalCount?: Maybe<Scalars['Int']>;
    dataList?: Maybe<Array<Maybe<TitMail>>>;
  };

  export type ListDecStatus = {
    __typename?: 'ListDecStatus';
    totalCount?: Maybe<Scalars['Int']>;
    dataList?: Maybe<Array<Maybe<DecStatus>>>;
  };

  export type DecStatus = {
    __typename?: 'DecStatus';
    id?: Maybe<Scalars['ID']>;
    orderNo?: Maybe<Scalars['String']>;
    appID?: Maybe<Scalars['String']>;
    refNo?: Maybe<Scalars['String']>;
    note?: Maybe<Scalars['String']>;
    cusCiqNo?: Maybe<Scalars['String']>;
    channel?: Maybe<Scalars['String']>;
    statusCode?: Maybe<Scalars['Int']>;
    statusName?: Maybe<Scalars['String']>;
    entryId?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type File = {
    __typename?: 'File';
    filename: Scalars['String'];
    mimetype: Scalars['String'];
    encoding: Scalars['String'];
    url: Scalars['String'];
    ossKey: Scalars['String'];
    docSize?: Maybe<Scalars['Int']>;
  };

  export type ListRpaJobInfo = {
    __typename?: 'ListRpaJobInfo';
    totalCount?: Maybe<Scalars['Int']>;
    dataList?: Maybe<Array<Maybe<RpaJobInfo>>>;
  };

  export type RpaJobInfo = {
    __typename?: 'RpaJobInfo';
    id?: Maybe<Scalars['ID']>;
    taskCode?: Maybe<Scalars['String']>;
    appID?: Maybe<Scalars['String']>;
    taskName?: Maybe<Scalars['String']>;
    jobName?: Maybe<Scalars['String']>;
    memo?: Maybe<Scalars['String']>;
    jobNo?: Maybe<Scalars['String']>;
    inputFileName?: Maybe<Scalars['String']>;
    ossPath?: Maybe<Scalars['String']>;
    processDetails?: Maybe<Array<Maybe<RpaJobProcessDetail>>>;
    jobRecords?: Maybe<Array<Maybe<RpaJobRecord>>>;
    clientID?: Maybe<Scalars['String']>;
    statusName?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type RpaJobProcessDetail = {
    __typename?: 'RpaJobProcessDetail';
    id?: Maybe<Scalars['ID']>;
    jobNo?: Maybe<Scalars['String']>;
    seqNo?: Maybe<Scalars['Int']>;
    memo?: Maybe<Scalars['String']>;
    keyValue?: Maybe<Scalars['String']>;
    data?: Maybe<Scalars['JSON']>;
    statusName?: Maybe<Scalars['String']>;
    paraJson?: Maybe<Scalars['JSON']>;
    clientID?: Maybe<Scalars['String']>;
    callbackFun?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    processMessage?: Maybe<Scalars['String']>;
    callbackStatusName?: Maybe<Scalars['String']>;
    callbackMessage?: Maybe<Scalars['String']>;
    inputValue?: Maybe<Scalars['String']>;
    outputValue?: Maybe<Scalars['String']>;
    balance?: Maybe<Scalars['Float']>;
  };

  export type RpaJobRecord = {
    __typename?: 'RpaJobRecord';
    name?: Maybe<Scalars['String']>;
    main?: Maybe<Scalars['String']>;
    branch?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type ListRpaJobProcessDetail = {
    __typename?: 'ListRpaJobProcessDetail';
    totalCount?: Maybe<Scalars['Int']>;
    totalProcessCount?: Maybe<Scalars['Int']>;
    inputTotal?: Maybe<Scalars['Float']>;
    outputTotal?: Maybe<Scalars['Float']>;
    finishCount?: Maybe<Scalars['Int']>;
    dataList?: Maybe<Array<Maybe<RpaJobProcessDetail>>>;
  };

  export type ClientPorcessJobData = {
    __typename?: 'ClientPorcessJobData';
    jobInfo?: Maybe<RpaJobInfo>;
    processDetails?: Maybe<Array<Maybe<RpaJobProcessDetail>>>;
  };

  export type ListRpaTaskDef = {
    __typename?: 'ListRpaTaskDef';
    totalCount?: Maybe<Scalars['Int']>;
    dataList?: Maybe<Array<Maybe<RpaTaskDef>>>;
  };

  export type RpaTaskDef = {
    __typename?: 'RpaTaskDef';
    id?: Maybe<Scalars['ID']>;
    taskName?: Maybe<Scalars['String']>;
    appID?: Maybe<Scalars['String']>;
    taskType?: Maybe<Scalars['String']>;
    jobRecords?: Maybe<Array<Maybe<RpaJobRecord>>>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type DataLog = {
    __typename?: 'dataLog';
    id?: Maybe<Scalars['ID']>;
    appID?: Maybe<Scalars['String']>;
    upid?: Maybe<Scalars['String']>;
    upmemo?: Maybe<Scalars['String']>;
    uptype?: Maybe<Scalars['String']>;
    upperson?: Maybe<Scalars['String']>;
    uptime?: Maybe<Scalars['Date']>;
    detail?: Maybe<Scalars['JSON']>;
  };

  export type TrackBillInfo = {
    __typename?: 'TrackBillInfo';
    trackInfos?: Maybe<Array<Maybe<TrackDetail>>>;
    searchNo?: Maybe<Scalars['String']>;
    billNo?: Maybe<Scalars['String']>;
    voyageNo?: Maybe<Scalars['String']>;
    ieDate?: Maybe<Scalars['Date']>;
    recieveCity?: Maybe<Scalars['String']>;
    mAWBNo?: Maybe<Scalars['String']>;
    hAWBNo?: Maybe<Scalars['String']>;
  };

  export type TrackDetail = {
    __typename?: 'TrackDetail';
    status?: Maybe<Scalars['String']>;
    statusEn?: Maybe<Scalars['String']>;
    statusCode?: Maybe<Scalars['String']>;
    statusMemo?: Maybe<Scalars['String']>;
    statusMemoEn?: Maybe<Scalars['String']>;
    statusTime?: Maybe<Scalars['Date']>;
    flightNo?: Maybe<Scalars['String']>;
    loadAPT?: Maybe<Scalars['String']>;
    opeAPT?: Maybe<Scalars['String']>;
    opeAPTEn?: Maybe<Scalars['String']>;
    unloadAPT?: Maybe<Scalars['String']>;
    unloadATPEEn?: Maybe<Scalars['String']>;
    loadATPEn?: Maybe<Scalars['String']>;
  };

  /** 快件订单信息 */
  export type ExpressOrder = {
    __typename?: 'ExpressOrder';
    /** 翔运业务编号 */
    trackId?: Maybe<Scalars['String']>;
    /** 商品名称 */
    goodsDesp?: Maybe<Scalars['String']>;
    /** 英文品名 */
    goodsDspeEn?: Maybe<Scalars['String']>;
    /** 件数 */
    packNo?: Maybe<Scalars['Int']>;
    /** 数量 */
    qty?: Maybe<Scalars['Float']>;
    /** 毛重 */
    grossWeight?: Maybe<Scalars['Float']>;
    /** 金额 */
    totalAmont?: Maybe<Scalars['Float']>;
    /** 收货人 */
    consingee?: Maybe<Scalars['String']>;
    /** 收货地址 */
    consingeeAddress?: Maybe<Scalars['String']>;
    /** 发货人 */
    shipper?: Maybe<Scalars['String']>;
    /** 货主 */
    owner?: Maybe<Scalars['String']>;
    /** 总运单号 */
    mAWBNo?: Maybe<Scalars['String']>;
    /** 分运单号 */
    hAWBNo?: Maybe<Scalars['String']>;
    /** 运费 */
    transFeeAmount?: Maybe<Scalars['Float']>;
    curr?: Maybe<Scalars['String']>;
    transFeeCurr?: Maybe<Scalars['String']>;
  };

  export type ListOssFileInfo = {
    __typename?: 'ListOssFileInfo';
    totalCount?: Maybe<Scalars['Int']>;
    dataList?: Maybe<Array<Maybe<OssFileInfo>>>;
  };

  export type OssFileInfo = {
    __typename?: 'OssFileInfo';
    id?: Maybe<Scalars['ID']>;
    refNo?: Maybe<Scalars['String']>;
    pathTitle?: Maybe<Scalars['String']>;
    fileName?: Maybe<Scalars['String']>;
    bucketName?: Maybe<Scalars['String']>;
    ossPath?: Maybe<Scalars['String']>;
    isDic?: Maybe<Scalars['Boolean']>;
    memo?: Maybe<Scalars['String']>;
    sourceDesc?: Maybe<Scalars['String']>;
    tags?: Maybe<Array<Maybe<Scalars['String']>>>;
    md5str?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    appID?: Maybe<Scalars['String']>;
  };

  export type OssPath = {
    __typename?: 'OssPath';
    title?: Maybe<Scalars['String']>;
    path?: Maybe<Scalars['String']>;
  };

  export type ListHsRegex = {
    __typename?: 'ListHsRegex';
    totalCount?: Maybe<Scalars['Int']>;
    dataList?: Maybe<Array<Maybe<HsRegex>>>;
  };

  export type HsRegex = {
    __typename?: 'HsRegex';
    id?: Maybe<Scalars['ID']>;
    hsCode?: Maybe<Scalars['String']>;
    gName?: Maybe<Scalars['String']>;
    ciqName?: Maybe<Scalars['String']>;
    sugunit?: Maybe<Scalars['Date']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    declareElements?: Maybe<Scalars['JSON']>;
  };

  export type ListValidationrule = {
    __typename?: 'ListValidationrule';
    totalCount?: Maybe<Scalars['Int']>;
    dataList?: Maybe<Array<Maybe<Validationrule>>>;
  };

  export type Validationrule = {
    __typename?: 'Validationrule';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    aviltype?: Maybe<Scalars['String']>;
    cust?: Maybe<Scalars['String']>;
    processtype?: Maybe<Scalars['String']>;
    sorobj?: Maybe<Scalars['String']>;
    condition?: Maybe<Scalars['JSON']>;
    errorInfo?: Maybe<Scalars['String']>;
    rule?: Maybe<Scalars['JSON']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    appID?: Maybe<Scalars['String']>;
  };

  export type Mutation = {
    __typename?: 'Mutation';
    _empty?: Maybe<Scalars['String']>;
    updateAppUser: UpdateResponse;
    updateAppUserPassword: UpdateResponse;
    deleteAppUser: UpdateResponse;
    csPickOrder: MarkUpdateResponse;
    markDZ: MarkUpdateResponse;
    unMarkDZ: MarkUpdateResponse;
    updateAppAppName: UpdateResponse;
    deleteAppAppName: UpdateResponse;
    updateAppRolePolicy: UpdateResponse;
    deleteAppRolePolicy: UpdateResponse;
    testAppRolePolicyup?: Maybe<Scalars['Int']>;
    updateAppCompany: UpdateResponse;
    deleteAppCompany: UpdateResponse;
    testAppCompanyup?: Maybe<Scalars['Int']>;
    updateproductmanage: UpdateResponse;
    upsertproductmanage: UpdateResponse;
    upsertallproductmanage: UpdateResponse;
    deleteproductmanage: UpdateResponse;
    testproductmanageup?: Maybe<Scalars['Int']>;
    batchupdateprod: UpdateResponse;
    updateproductproto: UpdateResponse;
    deleteproductproto: UpdateResponse;
    testproductprotoup?: Maybe<Scalars['Int']>;
    updateregistration: UpdateResponse;
    deleteregistration: UpdateResponse;
    testregistrationup?: Maybe<Scalars['Int']>;
    passstate?: Maybe<UpdateResponse>;
    transPassState?: Maybe<UpdateResponse>;
    updatebox: UpdateResponse;
    deletebox: UpdateResponse;
    testboxup?: Maybe<Scalars['Int']>;
    updateboxStoreDate: UpdateResponse;
    updateregtrans: UpdateResponse;
    deleteregtrans: UpdateResponse;
    testregtransup?: Maybe<Scalars['Int']>;
    updatetrans: UpdateResponse;
    deletetrans: UpdateResponse;
    testtransup?: Maybe<Scalars['Int']>;
    updates_file_info: UpdateResponse;
    deletes_file_info: UpdateResponse;
    tests_file_infoup?: Maybe<Scalars['Int']>;
    UpdateOrderFileInfos: UpdateResponse;
    updatexportOrderFileInfo: UpdateResponse;
    deletexportOrderFileInfo: UpdateResponse;
    updatexportOrderPicInfo: UpdateResponse;
    deletexportOrderPicInfo: UpdateResponse;
    updatexportOrderPicInfos: UpdateResponse;
    testxportOrderPicInfoup?: Maybe<Scalars['Int']>;
    updateXportOrderMain: UpdateResponse;
    deleteXportOrderMain: UpdateResponse;
    testXportOrderMainup?: Maybe<Scalars['Int']>;
    newOrderFromMail: UpdateResponse;
    copyOrderFromOrigin: UpdateResponse;
    passmail: UpdateResponse;
    updatecustomscheck: UpdateResponse;
    deletecustomscheck: UpdateResponse;
    testcustomscheckup?: Maybe<Scalars['Int']>;
    updateheavyeqp: UpdateResponse;
    deleteheavyeqp: UpdateResponse;
    testheavyeqpup?: Maybe<Scalars['Int']>;
    updateinvoicesku: UpdateResponse;
    deleteinvoicesku: UpdateResponse;
    testinvoiceskuup?: Maybe<Scalars['Int']>;
    updateKddPreOrderDec: UpdateResponse;
    updateKddDecStatus: UpdateResponse;
    deletekddpreorder: UpdateResponse;
    testkddpreorderup?: Maybe<Scalars['Int']>;
    manualSplitPreOrder: UpdateResponse;
    genStockBySels: UpdateResponse;
    genStockBylist: UpdateResponse;
    genExmianbiao: UpdateResponse;
    genExstock: UpdateResponse;
    sendtoXVision: UpdateResponse;
    sendToSingleWindow: UpdateResponse;
    directZhidan: UpdateResponse;
    copyKdd: UpdateResponse;
    updatexportProductCompany: UpdateResponse;
    deletexportProductCompany: UpdateResponse;
    testxportProductCompanyup?: Maybe<Scalars['Int']>;
    updateKddPreOrderTemplate: UpdateResponse;
    deleteKddPreOrderTemplate: UpdateResponse;
    updateSingleWindowDec: UpdateResponse;
    deleteSingleWindowData: UpdateResponse;
    testSingleWindowDecDetailup?: Maybe<Scalars['Int']>;
    updatesinglewindowcustom: UpdateResponse;
    deletesinglewindowcustom: UpdateResponse;
    testsinglewindowcustomup?: Maybe<Scalars['Int']>;
    updateTitMail: UpdateResponse;
    deleteTitMail: UpdateResponse;
    reFetchMail: UpdateResponse;
    updateDecStatus: UpdateResponse;
    deleteDecStatus: UpdateResponse;
    testDecStatusup?: Maybe<Scalars['Int']>;
    singleUpload: File;
    updateRpaJobInfo: UpdateResponse;
    deleteRpaJobInfo: UpdateResponse;
    linkJobInfoByCode: UpdateResponse;
    reportJobInfoProcessDetail: UpdateResponse;
    callTmpApi: UpdateResponse;
    resetProcessDetail: UpdateResponse;
    resetAllProcessDetail: UpdateResponse;
    updateRpaTaskDef: UpdateResponse;
    deleteRpaTaskDef: UpdateResponse;
    newOrder: UpdateResponse;
    orderHandle: UpdateResponse;
    updateOssFileInfo: UpdateResponse;
    deleteOssFileInfo: UpdateResponse;
    newOssFileInfoDic: UpdateResponse;
    uploadFileInfo: UpdateResponse;
    updateHsRegex: UpdateResponse;
    deleteHsRegex: UpdateResponse;
    testhsinforegexup?: Maybe<Scalars['Int']>;
    updateValidationrule: UpdateResponse;
    deleteValidationrule: UpdateResponse;
    testvalidationruleup?: Maybe<Scalars['Int']>;
  };


  export type MutationUpdateAppUserArgs = {
    id?: Maybe<Scalars['ID']>;
    mobileNo?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    userName?: Maybe<Scalars['String']>;
    validFlag?: Maybe<Scalars['Boolean']>;
    roles?: Maybe<Array<Maybe<Scalars['String']>>>;
    companyName?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    appID?: Maybe<Scalars['String']>;
  };


  export type MutationUpdateAppUserPasswordArgs = {
    id?: Maybe<Scalars['ID']>;
    password?: Maybe<Scalars['String']>;
    oldPassword?: Maybe<Scalars['String']>;
    userName?: Maybe<Scalars['String']>;
    companyName?: Maybe<Scalars['String']>;
    appID?: Maybe<Scalars['String']>;
  };


  export type MutationDeleteAppUserArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationCsPickOrderArgs = {
    orderNo?: Maybe<Scalars['String']>;
    csUserId?: Maybe<Scalars['String']>;
    csUserName?: Maybe<Scalars['String']>;
    csOrderStatus?: Maybe<Scalars['Int']>;
  };


  export type MutationMarkDzArgs = {
    dzNo: Array<Maybe<Scalars['String']>>;
    userId?: Maybe<Scalars['String']>;
  };


  export type MutationUnMarkDzArgs = {
    dzNo: Array<Maybe<Scalars['String']>>;
    userId?: Maybe<Scalars['String']>;
  };


  export type MutationUpdateAppAppNameArgs = {
    id?: Maybe<Scalars['ID']>;
    appName?: Maybe<Scalars['String']>;
    appMemo?: Maybe<Scalars['String']>;
    appRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };


  export type MutationDeleteAppAppNameArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationUpdateAppRolePolicyArgs = {
    id?: Maybe<Scalars['ID']>;
    AppID?: Maybe<Scalars['String']>;
    CompanyName?: Maybe<Scalars['String']>;
    RoleName?: Maybe<Array<Maybe<Scalars['String']>>>;
    ActionName?: Maybe<Scalars['String']>;
    ActionType?: Maybe<Scalars['String']>;
    Memo?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };


  export type MutationDeleteAppRolePolicyArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationTestAppRolePolicyupArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type MutationUpdateAppCompanyArgs = {
    id?: Maybe<Scalars['ID']>;
    companyName?: Maybe<Scalars['String']>;
    customCode?: Maybe<Scalars['String']>;
    tradeCodeSCC?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };


  export type MutationDeleteAppCompanyArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationTestAppCompanyupArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type MutationUpdateproductmanageArgs = {
    updateInput?: Maybe<ProductmanageInput>;
  };


  export type MutationUpsertproductmanageArgs = {
    updateInput?: Maybe<ProductmanageInput>;
  };


  export type MutationUpsertallproductmanageArgs = {
    updateInput?: Maybe<Array<Maybe<ProductmanageInput>>>;
  };


  export type MutationDeleteproductmanageArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationTestproductmanageupArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type MutationBatchupdateprodArgs = {
    prodlist?: Maybe<Array<Maybe<ProductmanageInput>>>;
    upmemo?: Maybe<Scalars['String']>;
  };


  export type MutationUpdateproductprotoArgs = {
    id?: Maybe<Scalars['ID']>;
    tradeNameCn?: Maybe<Scalars['String']>;
    materialPartNo?: Maybe<Scalars['String']>;
    nameCn?: Maybe<Scalars['String']>;
    hsCode?: Maybe<Scalars['String']>;
    tradeNameEn?: Maybe<Scalars['String']>;
    produceCoNameCn?: Maybe<Scalars['String']>;
    produceCoNameEn?: Maybe<Scalars['String']>;
    nameEn?: Maybe<Scalars['String']>;
    modelEn?: Maybe<Scalars['String']>;
    declareElementsStr?: Maybe<Scalars['String']>;
    declareElements?: Maybe<Array<Maybe<DeclareEleInput>>>;
    gUnitCn?: Maybe<Scalars['String']>;
    gUnitEn?: Maybe<Scalars['String']>;
    keyWord?: Maybe<Scalars['String']>;
    sourceType?: Maybe<Scalars['String']>;
    productType?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    remark?: Maybe<Scalars['String']>;
    unit1?: Maybe<Scalars['String']>;
    unit2?: Maybe<Scalars['String']>;
    controlmark?: Maybe<Scalars['String']>;
    tarifflag?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    appCompany?: Maybe<Scalars['String']>;
    heavyeqp?: Maybe<Scalars['String']>;
    subsystem?: Maybe<Scalars['String']>;
    mbdeclareElementsStr?: Maybe<Scalars['String']>;
    mbdeclareElements?: Maybe<Array<Maybe<DeclareEleInput>>>;
    ciqCode?: Maybe<Scalars['String']>;
    ciqName?: Maybe<Scalars['String']>;
    nameDesc?: Maybe<Scalars['String']>;
    netWeight?: Maybe<Scalars['Float']>;
    declPrice?: Maybe<Scalars['Float']>;
    protoName?: Maybe<Scalars['String']>;
  };


  export type MutationDeleteproductprotoArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationTestproductprotoupArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type MutationUpdateregistrationArgs = {
    comm20?: Maybe<Scalars['String']>;
    comm19?: Maybe<Scalars['String']>;
    comm18?: Maybe<Scalars['String']>;
    comm17?: Maybe<Scalars['String']>;
    comm16?: Maybe<Scalars['String']>;
    comm15?: Maybe<Scalars['String']>;
    comm14?: Maybe<Scalars['String']>;
    comm13?: Maybe<Scalars['String']>;
    comm12?: Maybe<Scalars['String']>;
    comm11?: Maybe<Scalars['String']>;
    comm10?: Maybe<Scalars['String']>;
    comm9?: Maybe<Scalars['String']>;
    comm8?: Maybe<Scalars['String']>;
    comm7?: Maybe<Scalars['String']>;
    comm6?: Maybe<Scalars['String']>;
    comm5?: Maybe<Scalars['String']>;
    comm4?: Maybe<Scalars['String']>;
    comm3?: Maybe<Scalars['String']>;
    comm2?: Maybe<Scalars['String']>;
    comm1?: Maybe<Scalars['String']>;
    status18_time?: Maybe<Scalars['Date']>;
    status18_user?: Maybe<Scalars['String']>;
    status17_time?: Maybe<Scalars['Date']>;
    status17_user?: Maybe<Scalars['String']>;
    status16_time?: Maybe<Scalars['Date']>;
    status16_user?: Maybe<Scalars['String']>;
    status15_time?: Maybe<Scalars['Date']>;
    status15_user?: Maybe<Scalars['String']>;
    status14_time?: Maybe<Scalars['Date']>;
    status14_user?: Maybe<Scalars['String']>;
    status13_time?: Maybe<Scalars['Date']>;
    status13_user?: Maybe<Scalars['String']>;
    status12_time?: Maybe<Scalars['Date']>;
    status12_user?: Maybe<Scalars['String']>;
    status11_time?: Maybe<Scalars['Date']>;
    status11_user?: Maybe<Scalars['String']>;
    status10_time?: Maybe<Scalars['Date']>;
    status10_user?: Maybe<Scalars['String']>;
    status9_time?: Maybe<Scalars['Date']>;
    status9_user?: Maybe<Scalars['String']>;
    status8_time?: Maybe<Scalars['Date']>;
    status8_user?: Maybe<Scalars['String']>;
    status7_time?: Maybe<Scalars['Date']>;
    status7_user?: Maybe<Scalars['String']>;
    status6_time?: Maybe<Scalars['Date']>;
    status6_user?: Maybe<Scalars['String']>;
    status5_time?: Maybe<Scalars['Date']>;
    status5_user?: Maybe<Scalars['String']>;
    status4_time?: Maybe<Scalars['Date']>;
    status4_user?: Maybe<Scalars['String']>;
    status3_time?: Maybe<Scalars['Date']>;
    status3_user?: Maybe<Scalars['String']>;
    status2_time?: Maybe<Scalars['Date']>;
    status2_user?: Maybe<Scalars['String']>;
    status1_time?: Maybe<Scalars['Date']>;
    status1_user?: Maybe<Scalars['String']>;
    audit2_time?: Maybe<Scalars['Date']>;
    audit2_user?: Maybe<Scalars['String']>;
    audit1_time?: Maybe<Scalars['Date']>;
    audit1_user?: Maybe<Scalars['String']>;
    audit_flag?: Maybe<Scalars['String']>;
    spare_nine?: Maybe<Scalars['String']>;
    spare_einght?: Maybe<Scalars['String']>;
    spare_seven?: Maybe<Scalars['String']>;
    spare_six?: Maybe<Scalars['String']>;
    spare_five?: Maybe<Scalars['String']>;
    spare_four?: Maybe<Scalars['String']>;
    spare_three?: Maybe<Scalars['String']>;
    spare_two?: Maybe<Scalars['String']>;
    spare_one?: Maybe<Scalars['String']>;
    spare?: Maybe<Scalars['String']>;
    fourty_foot_standard_foot?: Maybe<Scalars['String']>;
    twenty_foot_standard_foot?: Maybe<Scalars['String']>;
    data_color?: Maybe<Scalars['String']>;
    export_sn?: Maybe<Scalars['String']>;
    independent_tax?: Maybe<Scalars['String']>;
    security_verification?: Maybe<Scalars['String']>;
    self_reported?: Maybe<Scalars['String']>;
    documents_attached?: Maybe<Scalars['String']>;
    progress?: Maybe<Scalars['String']>;
    declaration_unit?: Maybe<Scalars['String']>;
    consumption_unit?: Maybe<Scalars['String']>;
    shipper_consignee?: Maybe<Scalars['String']>;
    busi_type?: Maybe<Scalars['String']>;
    pre_entry_sn?: Maybe<Scalars['String']>;
    import_sn?: Maybe<Scalars['String']>;
    business_audit?: Maybe<Scalars['String']>;
    business_approval?: Maybe<Scalars['String']>;
    second_number?: Maybe<Scalars['String']>;
    legal_number?: Maybe<Scalars['String']>;
    transactions_number?: Maybe<Scalars['String']>;
    total_price?: Maybe<Scalars['String']>;
    goods?: Maybe<Scalars['String']>;
    document_code?: Maybe<Scalars['String']>;
    container?: Maybe<Scalars['String']>;
    yard_code?: Maybe<Scalars['String']>;
    regulatory_place?: Maybe<Scalars['String']>;
    related_record?: Maybe<Scalars['String']>;
    related_declaration_form?: Maybe<Scalars['String']>;
    royalties?: Maybe<Scalars['String']>;
    price_impact?: Maybe<Scalars['String']>;
    special_relationship?: Maybe<Scalars['String']>;
    customs_declaration_type?: Maybe<Scalars['String']>;
    container_number?: Maybe<Scalars['String']>;
    domestic_destination?: Maybe<Scalars['String']>;
    loading_port?: Maybe<Scalars['String']>;
    origin_country?: Maybe<Scalars['String']>;
    license_key?: Maybe<Scalars['String']>;
    tax_unit?: Maybe<Scalars['String']>;
    tax_rate?: Maybe<Scalars['String']>;
    application_date?: Maybe<Scalars['Date']>;
    import_date?: Maybe<Scalars['Date']>;
    record_number?: Maybe<Scalars['String']>;
    customs_number?: Maybe<Scalars['String']>;
    uniform_number?: Maybe<Scalars['String']>;
    ass_number?: Maybe<Scalars['String']>;
    customs_status?: Maybe<Scalars['String']>;
    edoc_size?: Maybe<Scalars['String']>;
    sign_time?: Maybe<Scalars['Date']>;
    edoc_cop_id?: Maybe<Scalars['String']>;
    op_note?: Maybe<Scalars['String']>;
    edoc_fomat_type?: Maybe<Scalars['String']>;
    edoc_code?: Maybe<Scalars['String']>;
    edoc_id?: Maybe<Scalars['String']>;
    certificate?: Maybe<Scalars['String']>;
    host_id?: Maybe<Scalars['String']>;
    sign_date?: Maybe<Scalars['String']>;
    sign?: Maybe<Scalars['String']>;
    client_seq_no?: Maybe<Scalars['String']>;
    oper_name?: Maybe<Scalars['String']>;
    ic_code?: Maybe<Scalars['String']>;
    oper_type?: Maybe<Scalars['String']>;
    voy_no?: Maybe<Scalars['String']>;
    rel_man_no?: Maybe<Scalars['String']>;
    rel_id?: Maybe<Scalars['String']>;
    dec_no?: Maybe<Scalars['String']>;
    dec_bp_no?: Maybe<Scalars['String']>;
    cus_fie?: Maybe<Scalars['String']>;
    trade_area_code?: Maybe<Scalars['String']>;
    promise_itmes?: Maybe<Scalars['String']>;
    bill_type?: Maybe<Scalars['String']>;
    check_flow?: Maybe<Scalars['String']>;
    chk_surety?: Maybe<Scalars['String']>;
    wrap_type?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    trans_mode?: Maybe<Scalars['String']>;
    traf_name?: Maybe<Scalars['String']>;
    traf_mode?: Maybe<Scalars['String']>;
    trade_mode?: Maybe<Scalars['String']>;
    trade_country?: Maybe<Scalars['String']>;
    tgd_no?: Maybe<Scalars['String']>;
    seq_no?: Maybe<Scalars['String']>;
    risk?: Maybe<Scalars['String']>;
    pre_entryId?: Maybe<Scalars['String']>;
    p_date?: Maybe<Scalars['String']>;
    partener_id?: Maybe<Scalars['String']>;
    payment_mark?: Maybe<Scalars['String']>;
    pay_way?: Maybe<Scalars['String']>;
    other_rate?: Maybe<Scalars['String']>;
    other_mark?: Maybe<Scalars['String']>;
    other_curr?: Maybe<Scalars['String']>;
    note_s?: Maybe<Scalars['String']>;
    net_wt?: Maybe<Scalars['String']>;
    manual_no?: Maybe<Scalars['String']>;
    license_no?: Maybe<Scalars['String']>;
    insur_rate?: Maybe<Scalars['String']>;
    insur_mark?: Maybe<Scalars['String']>;
    insur_curr?: Maybe<Scalars['String']>;
    in_ratio?: Maybe<Scalars['String']>;
    ie_flag?: Maybe<Scalars['String']>;
    fee_rate?: Maybe<Scalars['String']>;
    fee_mark?: Maybe<Scalars['String']>;
    fee_curr?: Maybe<Scalars['String']>;
    entry_type?: Maybe<Scalars['String']>;
    entry_id?: Maybe<Scalars['String']>;
    edi_id?: Maybe<Scalars['String']>;
    district_code?: Maybe<Scalars['String']>;
    distinate_port?: Maybe<Scalars['String']>;
    decl_trn_rel?: Maybe<Scalars['String']>;
    data_source?: Maybe<Scalars['String']>;
    cut_code?: Maybe<Scalars['String']>;
    custom_master?: Maybe<Scalars['String']>;
    appr_no?: Maybe<Scalars['String']>;
    cop_code_scc?: Maybe<Scalars['String']>;
    cop_name?: Maybe<Scalars['String']>;
    cop_code?: Maybe<Scalars['String']>;
    trade_code_scc?: Maybe<Scalars['String']>;
    trade_name?: Maybe<Scalars['String']>;
    trade_code?: Maybe<Scalars['String']>;
    owner_code_scc?: Maybe<Scalars['String']>;
    owner_name?: Maybe<Scalars['String']>;
    owner_code?: Maybe<Scalars['String']>;
    agent_code_scc?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    agent_code?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    update_time?: Maybe<Scalars['Date']>;
    update_name?: Maybe<Scalars['String']>;
    update_id?: Maybe<Scalars['String']>;
    create_time?: Maybe<Scalars['Date']>;
    inputer_name?: Maybe<Scalars['String']>;
    typist_no?: Maybe<Scalars['String']>;
    agent_name?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    bill_no?: Maybe<Scalars['String']>;
    contr_no?: Maybe<Scalars['String']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    gross_wet?: Maybe<Scalars['String']>;
    ie_date?: Maybe<Scalars['String']>;
    ie_port?: Maybe<Scalars['String']>;
    pack_no?: Maybe<Scalars['String']>;
    bon_no?: Maybe<Scalars['String']>;
  };


  export type MutationDeleteregistrationArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationTestregistrationupArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type MutationPassstateArgs = {
    bon_no?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    updateperson?: Maybe<Scalars['String']>;
    agentName?: Maybe<Scalars['String']>;
    prestatus?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    orderNo?: Maybe<Scalars['String']>;
  };


  export type MutationTransPassStateArgs = {
    picfile?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    updateperson?: Maybe<Scalars['String']>;
    agentName?: Maybe<Scalars['String']>;
    prestatus?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    orderNo?: Maybe<Scalars['String']>;
  };


  export type MutationUpdateboxArgs = {
    comm10?: Maybe<Scalars['String']>;
    comm9?: Maybe<Scalars['String']>;
    comm8?: Maybe<Scalars['String']>;
    comm7?: Maybe<Scalars['String']>;
    comm6?: Maybe<Scalars['String']>;
    comm5?: Maybe<Scalars['String']>;
    comm4?: Maybe<Scalars['String']>;
    comm3?: Maybe<Scalars['String']>;
    comm2?: Maybe<Scalars['String']>;
    comm1?: Maybe<Scalars['String']>;
    box_lev?: Maybe<Scalars['String']>;
    ref_box_id?: Maybe<Scalars['String']>;
    box_updater?: Maybe<Scalars['String']>;
    box_updatedt?: Maybe<Scalars['Date']>;
    box_creater?: Maybe<Scalars['String']>;
    box_createdt?: Maybe<Scalars['Date']>;
    box_pno?: Maybe<Scalars['String']>;
    box_no?: Maybe<Scalars['String']>;
    regid?: Maybe<Scalars['String']>;
    box_id?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };


  export type MutationDeleteboxArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationTestboxupArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type MutationUpdateboxStoreDateArgs = {
    comm3?: Maybe<Scalars['String']>;
    bon_no?: Maybe<Scalars['String']>;
    updateperson?: Maybe<Scalars['String']>;
    agentName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    box_id?: Maybe<Scalars['String']>;
  };


  export type MutationUpdateregtransArgs = {
    regtrans_id?: Maybe<Scalars['String']>;
    truck_id?: Maybe<Scalars['String']>;
    driver_id?: Maybe<Scalars['String']>;
    transdt?: Maybe<Scalars['Date']>;
    transer?: Maybe<Scalars['String']>;
    senddt?: Maybe<Scalars['Date']>;
    check_no?: Maybe<Scalars['String']>;
    receive_no?: Maybe<Scalars['String']>;
    comm1?: Maybe<Scalars['String']>;
    comm2?: Maybe<Scalars['String']>;
    comm3?: Maybe<Scalars['String']>;
    comm4?: Maybe<Scalars['String']>;
    comm5?: Maybe<Scalars['String']>;
    comm6?: Maybe<Scalars['String']>;
    comm7?: Maybe<Scalars['String']>;
    comm8?: Maybe<Scalars['String']>;
    comm9?: Maybe<Scalars['String']>;
    comm10?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };


  export type MutationDeleteregtransArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationTestregtransupArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type MutationUpdatetransArgs = {
    comm12?: Maybe<Scalars['String']>;
    comm11?: Maybe<Scalars['String']>;
    comm10?: Maybe<Scalars['String']>;
    comm9?: Maybe<Scalars['String']>;
    comm8?: Maybe<Scalars['String']>;
    comm7?: Maybe<Scalars['String']>;
    comm6?: Maybe<Scalars['String']>;
    comm5?: Maybe<Scalars['String']>;
    comm4?: Maybe<Scalars['String']>;
    comm3?: Maybe<Scalars['String']>;
    comm2?: Maybe<Scalars['String']>;
    comm1?: Maybe<Scalars['String']>;
    ctime?: Maybe<Scalars['Date']>;
    comment?: Maybe<Scalars['String']>;
    no?: Maybe<Scalars['Int']>;
    type?: Maybe<Scalars['String']>;
    ref_id?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };


  export type MutationDeletetransArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationTesttransupArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type MutationUpdates_File_InfoArgs = {
    ref_id?: Maybe<Scalars['String']>;
    file_kind?: Maybe<Scalars['String']>;
    file_address?: Maybe<Scalars['String']>;
    remark?: Maybe<Scalars['String']>;
    pic_config?: Maybe<Scalars['String']>;
    latitude?: Maybe<Scalars['String']>;
    longitude?: Maybe<Scalars['String']>;
    file_dir?: Maybe<Scalars['String']>;
    file_size?: Maybe<Scalars['Int']>;
    file_mime?: Maybe<Scalars['String']>;
    file_url?: Maybe<Scalars['String']>;
    file_type?: Maybe<Scalars['Int']>;
    file_sha?: Maybe<Scalars['String']>;
    file_suffix?: Maybe<Scalars['String']>;
    file_name?: Maybe<Scalars['String']>;
    update_time?: Maybe<Scalars['Date']>;
    update_id?: Maybe<Scalars['String']>;
    create_time?: Maybe<Scalars['Date']>;
    create_id?: Maybe<Scalars['String']>;
    file_id?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };


  export type MutationDeletes_File_InfoArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationTests_File_InfoupArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type MutationUpdateOrderFileInfosArgs = {
    id?: Maybe<Scalars['ID']>;
    inputs?: Maybe<Array<Maybe<OrderFileInfoInput>>>;
  };


  export type MutationUpdatexportOrderFileInfoArgs = {
    orderNo?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    seqNo?: Maybe<Scalars['Int']>;
    fileName?: Maybe<Scalars['String']>;
    OSSPath?: Maybe<Scalars['String']>;
    fileTypeName?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };


  export type MutationDeletexportOrderFileInfoArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationUpdatexportOrderPicInfoArgs = {
    id?: Maybe<Scalars['ID']>;
    orderNo?: Maybe<Scalars['String']>;
    OrginFileName?: Maybe<Scalars['String']>;
    fileName?: Maybe<Scalars['String']>;
    OSSPath?: Maybe<Scalars['String']>;
    seqNo?: Maybe<Scalars['Int']>;
    FileType?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };


  export type MutationDeletexportOrderPicInfoArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationUpdatexportOrderPicInfosArgs = {
    inputPics?: Maybe<Array<Maybe<XportOrderPicInfoInput>>>;
  };


  export type MutationTestxportOrderPicInfoupArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type MutationUpdateXportOrderMainArgs = {
    id?: Maybe<Scalars['ID']>;
    input?: Maybe<XportOrderMainInput>;
  };


  export type MutationDeleteXportOrderMainArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationTestXportOrderMainupArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type MutationNewOrderFromMailArgs = {
    orderInfo?: Maybe<OrderMainInput>;
  };


  export type MutationCopyOrderFromOriginArgs = {
    billNo?: Maybe<Scalars['String']>;
    orderNo?: Maybe<Scalars['String']>;
  };


  export type MutationPassmailArgs = {
    mailId?: Maybe<Scalars['String']>;
  };


  export type MutationUpdatecustomscheckArgs = {
    id?: Maybe<Scalars['ID']>;
    orderNo?: Maybe<Scalars['String']>;
    entryid?: Maybe<Scalars['String']>;
    goodsname?: Maybe<Array<Maybe<Scalars['String']>>>;
    checkdate?: Maybe<Scalars['Date']>;
    checkmonth?: Maybe<Scalars['String']>;
    checkyear?: Maybe<Scalars['String']>;
    tradename?: Maybe<Scalars['String']>;
    ownername?: Maybe<Scalars['String']>;
    checkresult?: Maybe<Scalars['Boolean']>;
    checkresultmemo?: Maybe<Scalars['String']>;
    consignorEname?: Maybe<Scalars['String']>;
    checkReason?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };


  export type MutationDeletecustomscheckArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationTestcustomscheckupArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type MutationUpdateheavyeqpArgs = {
    id?: Maybe<Scalars['ID']>;
    hscode?: Maybe<Scalars['String']>;
    eqpclass?: Maybe<Scalars['String']>;
    eqptype?: Maybe<Scalars['String']>;
    eqpcategory?: Maybe<Scalars['String']>;
    eqpname?: Maybe<Scalars['String']>;
    firstlevel?: Maybe<Scalars['String']>;
    secondlevel?: Maybe<Scalars['String']>;
    singleusage?: Maybe<Scalars['String']>;
    expiredyear?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };


  export type MutationDeleteheavyeqpArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationTestheavyeqpupArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type MutationUpdateinvoiceskuArgs = {
    id?: Maybe<Scalars['ID']>;
    invoiceno?: Maybe<Scalars['String']>;
    orderno?: Maybe<Scalars['String']>;
    Description?: Maybe<Scalars['String']>;
    sku?: Maybe<Scalars['String']>;
    qty?: Maybe<Scalars['String']>;
    unitprice?: Maybe<Scalars['String']>;
    totalprice?: Maybe<Scalars['String']>;
    atr?: Maybe<Scalars['String']>;
    goodsdesc?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };


  export type MutationDeleteinvoiceskuArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationTestinvoiceskuupArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type MutationUpdateKddPreOrderDecArgs = {
    id?: Maybe<Scalars['ID']>;
    input?: Maybe<KddPreOrderInput>;
  };


  export type MutationUpdateKddDecStatusArgs = {
    id: Scalars['ID'];
    currStatusCode?: Maybe<Scalars['Int']>;
    nextStatusName: Scalars['String'];
  };


  export type MutationDeletekddpreorderArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationTestkddpreorderupArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type MutationManualSplitPreOrderArgs = {
    orderNo?: Maybe<Scalars['String']>;
    sepSeqno?: Maybe<Scalars['String']>;
    selGNos?: Maybe<Array<Maybe<Scalars['Int']>>>;
    mianbaoFlag?: Maybe<Scalars['String']>;
    templateName?: Maybe<Scalars['String']>;
  };


  export type MutationGenStockBySelsArgs = {
    orderNo?: Maybe<Scalars['String']>;
    sepSeqno?: Maybe<Scalars['String']>;
    selGNos?: Maybe<Array<Maybe<Scalars['Int']>>>;
    mianbaoFlag?: Maybe<Scalars['String']>;
    templateName?: Maybe<Scalars['String']>;
  };


  export type MutationGenStockBylistArgs = {
    sepSeqno?: Maybe<Scalars['String']>;
    selGNos?: Maybe<Array<Maybe<Scalars['String']>>>;
    mianbaoFlag?: Maybe<Scalars['String']>;
    templateName?: Maybe<Scalars['String']>;
  };


  export type MutationGenExmianbiaoArgs = {
    exid?: Maybe<Scalars['String']>;
    orderNo?: Maybe<Scalars['String']>;
    selGNos?: Maybe<Array<Maybe<Scalars['Int']>>>;
  };


  export type MutationGenExstockArgs = {
    exid?: Maybe<Scalars['String']>;
    orderNo?: Maybe<Scalars['String']>;
    selGNos?: Maybe<Array<Maybe<Scalars['Int']>>>;
  };


  export type MutationSendtoXVisionArgs = {
    orderNo?: Maybe<Scalars['String']>;
  };


  export type MutationSendToSingleWindowArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    orderNo?: Maybe<Scalars['String']>;
  };


  export type MutationDirectZhidanArgs = {
    showSepNo?: Maybe<Scalars['String']>;
    supplierName?: Maybe<Scalars['String']>;
    mianbaoFlag?: Maybe<Scalars['String']>;
    billNo?: Maybe<Scalars['String']>;
  };


  export type MutationCopyKddArgs = {
    showSepNo?: Maybe<Scalars['String']>;
    billNo?: Maybe<Scalars['String']>;
    copyid?: Maybe<Scalars['String']>;
  };


  export type MutationUpdatexportProductCompanyArgs = {
    id?: Maybe<Scalars['ID']>;
    input?: Maybe<ProductCompanyInput>;
  };


  export type MutationDeletexportProductCompanyArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationTestxportProductCompanyupArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type MutationUpdateKddPreOrderTemplateArgs = {
    id?: Maybe<Scalars['ID']>;
    input?: Maybe<KddPreOrderTemplateInput>;
  };


  export type MutationDeleteKddPreOrderTemplateArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationUpdateSingleWindowDecArgs = {
    id?: Maybe<Scalars['ID']>;
    input?: Maybe<KddPreOrderInput>;
  };


  export type MutationDeleteSingleWindowDataArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationTestSingleWindowDecDetailupArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type MutationUpdatesinglewindowcustomArgs = {
    cusRetSeqNo?: Maybe<Scalars['String']>;
    spDecSeqNo?: Maybe<Scalars['String']>;
    entryId?: Maybe<Scalars['String']>;
    cusCiqNo?: Maybe<Scalars['String']>;
    noticeDate?: Maybe<Scalars['String']>;
    channel?: Maybe<Scalars['String']>;
    note?: Maybe<Scalars['String']>;
    procReason?: Maybe<Scalars['String']>;
    customMaster?: Maybe<Scalars['String']>;
    agentName?: Maybe<Scalars['String']>;
    dclrNo?: Maybe<Scalars['String']>;
    tradeCo?: Maybe<Scalars['String']>;
    customsField?: Maybe<Scalars['String']>;
    bonNo?: Maybe<Scalars['String']>;
    iEDate?: Maybe<Scalars['String']>;
    packNo?: Maybe<Scalars['String']>;
    billNo?: Maybe<Scalars['String']>;
    cusTrafMode?: Maybe<Scalars['String']>;
    trafName?: Maybe<Scalars['String']>;
    cusVoyageNo?: Maybe<Scalars['String']>;
    netWt?: Maybe<Scalars['String']>;
    grossWt?: Maybe<Scalars['String']>;
    dDate?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    rcptDspsStuCode?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    rcptDspsDtldDesc?: Maybe<Scalars['String']>;
    createUser?: Maybe<Scalars['String']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    indbTime?: Maybe<Scalars['String']>;
    updateUser?: Maybe<Scalars['String']>;
    updateTime?: Maybe<Scalars['String']>;
    isudMark?: Maybe<Scalars['String']>;
    rcptDspsStuCodeName?: Maybe<Scalars['String']>;
    channelName?: Maybe<Scalars['String']>;
    customMasterName?: Maybe<Scalars['String']>;
    cusRegNo?: Maybe<Scalars['String']>;
    cusRetSeqNos?: Maybe<Scalars['String']>;
  };


  export type MutationDeletesinglewindowcustomArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationTestsinglewindowcustomupArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type MutationUpdateTitMailArgs = {
    id?: Maybe<Scalars['ID']>;
    orderNo?: Maybe<Scalars['String']>;
    orderOutNo?: Maybe<Scalars['String']>;
    mailId?: Maybe<Scalars['String']>;
    appID?: Maybe<Scalars['String']>;
    appCompanyMini?: Maybe<KeyValueInput>;
    coUserMini?: Maybe<KeyValueInput>;
    headerLines?: Maybe<Array<Maybe<KeyValueInput>>>;
    messageId?: Maybe<Scalars['String']>;
    memo?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    refNo?: Maybe<Scalars['String']>;
    mailStatus?: Maybe<Scalars['String']>;
  };


  export type MutationDeleteTitMailArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationUpdateDecStatusArgs = {
    id?: Maybe<Scalars['ID']>;
    input?: Maybe<DecStatusInput>;
  };


  export type MutationDeleteDecStatusArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationTestDecStatusupArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type MutationSingleUploadArgs = {
    file: Scalars['Upload'];
  };


  export type MutationUpdateRpaJobInfoArgs = {
    id?: Maybe<Scalars['ID']>;
    input?: Maybe<RpaJobInfoInput>;
  };


  export type MutationDeleteRpaJobInfoArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationLinkJobInfoByCodeArgs = {
    jobCode?: Maybe<Scalars['String']>;
    recordName?: Maybe<Scalars['String']>;
    clientID?: Maybe<Scalars['String']>;
  };


  export type MutationReportJobInfoProcessDetailArgs = {
    input?: Maybe<RpaJobProcessDetailInput>;
  };


  export type MutationCallTmpApiArgs = {
    apiUrl?: Maybe<Scalars['String']>;
    paraData?: Maybe<Scalars['JSON']>;
  };


  export type MutationResetProcessDetailArgs = {
    id?: Maybe<Scalars['ID']>;
  };


  export type MutationResetAllProcessDetailArgs = {
    jobNo?: Maybe<Scalars['String']>;
  };


  export type MutationUpdateRpaTaskDefArgs = {
    id?: Maybe<Scalars['ID']>;
    input?: Maybe<RpaTaskDefInput>;
  };


  export type MutationDeleteRpaTaskDefArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationNewOrderArgs = {
    id?: Maybe<Scalars['ID']>;
    input?: Maybe<ExpressOrderInput>;
  };


  export type MutationOrderHandleArgs = {
    id?: Maybe<Scalars['ID']>;
    statusName?: Maybe<Scalars['String']>;
    person?: Maybe<KeyValueInput>;
  };


  export type MutationUpdateOssFileInfoArgs = {
    id?: Maybe<Scalars['ID']>;
    input?: Maybe<OssFileInfoInput>;
  };


  export type MutationDeleteOssFileInfoArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationNewOssFileInfoDicArgs = {
    parentPath?: Maybe<Scalars['String']>;
    path?: Maybe<Scalars['String']>;
    memo?: Maybe<Scalars['String']>;
  };


  export type MutationUploadFileInfoArgs = {
    file: Scalars['Upload'];
    parentPath?: Maybe<Scalars['String']>;
  };


  export type MutationUpdateHsRegexArgs = {
    id?: Maybe<Scalars['ID']>;
    input?: Maybe<HsRegexInput>;
  };


  export type MutationDeleteHsRegexArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationTesthsinforegexupArgs = {
    test?: Maybe<Scalars['String']>;
  };


  export type MutationUpdateValidationruleArgs = {
    id?: Maybe<Scalars['ID']>;
    input?: Maybe<ValidationruleInput>;
  };


  export type MutationDeleteValidationruleArgs = {
    id?: Maybe<Scalars['ID']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
  };


  export type MutationTestvalidationruleupArgs = {
    test?: Maybe<Scalars['String']>;
  };

  export type UpdateResponse = {
    __typename?: 'UpdateResponse';
    status?: Maybe<Scalars['String']>;
    statusCode?: Maybe<Scalars['Int']>;
    statusMessage?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    data?: Maybe<Scalars['String']>;
  };

  export type MarkUpdateResponse = {
    __typename?: 'MarkUpdateResponse';
    status?: Maybe<Scalars['Boolean']>;
    statusCode?: Maybe<Scalars['Int']>;
    statusMessage?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
  };

  export type OrderFileInfoInput = {
    orderNo?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    seqNo?: Maybe<Scalars['Int']>;
    fileName?: Maybe<Scalars['String']>;
    OSSPath?: Maybe<Scalars['String']>;
    fileTypeName?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type XportOrderPicInfoInput = {
    id?: Maybe<Scalars['ID']>;
    orderNo?: Maybe<Scalars['String']>;
    OrginFileName?: Maybe<Scalars['String']>;
    fileName?: Maybe<Scalars['String']>;
    OSSPath?: Maybe<Scalars['String']>;
    seqNo?: Maybe<Scalars['Int']>;
    width?: Maybe<Scalars['Int']>;
    height?: Maybe<Scalars['Int']>;
    FileType?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    totalAmount?: Maybe<Scalars['Float']>;
    GVFlag?: Maybe<Scalars['Int']>;
  };

  export type XportOrderMainInput = {
    id?: Maybe<Scalars['ID']>;
    orderNo?: Maybe<Scalars['String']>;
    orderOutNo?: Maybe<Scalars['String']>;
    appID?: Maybe<Scalars['String']>;
    appCompanyMini?: Maybe<KeyValueInput>;
    coUserMini?: Maybe<KeyValueInput>;
    processRouteType?: Maybe<Scalars['String']>;
    processFiles?: Maybe<Array<Maybe<Scalars['ID']>>>;
    memo?: Maybe<Scalars['String']>;
    ieDate?: Maybe<Scalars['Date']>;
    statusCode?: Maybe<Scalars['Int']>;
    supplier?: Maybe<Scalars['String']>;
    billNo?: Maybe<Scalars['String']>;
    xportYWType?: Maybe<Scalars['String']>;
    zhidantype?: Maybe<Scalars['String']>;
    statusName?: Maybe<Scalars['String']>;
    supplierName?: Maybe<Scalars['String']>;
    preOrderTemplateName?: Maybe<Scalars['String']>;
    fileProcessPercent?: Maybe<Scalars['Int']>;
    departInfo?: Maybe<KeyValueInput>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    custRefNo?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    custCompanyMini?: Maybe<KeyValueInput>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    refNo?: Maybe<Scalars['String']>;
    recTime?: Maybe<Scalars['Date']>;
    estFinishCustomDate?: Maybe<Scalars['Date']>;
  };

  export type OrderMainInput = {
    id?: Maybe<Scalars['ID']>;
    orderNo?: Maybe<Scalars['String']>;
    orderOutNo?: Maybe<Scalars['String']>;
    appID?: Maybe<Scalars['String']>;
    appCompanyMini?: Maybe<KeyValueInput>;
    coUserMini?: Maybe<KeyValueInput>;
    processRouteType?: Maybe<Scalars['String']>;
    processFiles?: Maybe<Array<Maybe<Scalars['ID']>>>;
    memo?: Maybe<Scalars['String']>;
    ieDate?: Maybe<Scalars['Date']>;
    statusCode?: Maybe<Scalars['Int']>;
    supplier?: Maybe<Scalars['String']>;
    billNo?: Maybe<Scalars['String']>;
    xportYWType?: Maybe<Scalars['String']>;
    zhidantype?: Maybe<Scalars['String']>;
    statusName?: Maybe<Scalars['String']>;
    supplierName?: Maybe<Scalars['String']>;
    preOrderTemplateName?: Maybe<Scalars['String']>;
    fileProcessPercent?: Maybe<Scalars['Int']>;
    departInfo?: Maybe<KeyValueInput>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    custRefNo?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    custCompanyMini?: Maybe<KeyValueInput>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    refNo?: Maybe<Scalars['String']>;
    recTime?: Maybe<Scalars['Date']>;
    estFinishCustomDate?: Maybe<Scalars['Date']>;
    /** mail unique id */
    messageId?: Maybe<Scalars['String']>;
    attachFiles?: Maybe<Array<Maybe<KeyValueInput>>>;
    /** mail._id */
    mailId?: Maybe<Scalars['String']>;
  };

  export type KddPreOrderInput = {
    id?: Maybe<Scalars['ID']>;
    templateName?: Maybe<Scalars['String']>;
    appID?: Maybe<Scalars['String']>;
    uploadfile?: Maybe<Scalars['String']>;
    orderOutNo?: Maybe<Scalars['String']>;
    parentOrder?: Maybe<Scalars['String']>;
    refNo?: Maybe<Scalars['String']>;
    orderNo?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    chkSurety?: Maybe<Scalars['String']>;
    /** 单一窗口发送 clientSeqNo */
    orderNo1?: Maybe<Scalars['String']>;
    sepSeqno?: Maybe<Scalars['String']>;
    departCode?: Maybe<Scalars['String']>;
    departName?: Maybe<Scalars['String']>;
    drawtotalAmount?: Maybe<Scalars['Float']>;
    ordertype?: Maybe<Scalars['String']>;
    zhidantype?: Maybe<Scalars['String']>;
    splitLeft?: Maybe<Scalars['Float']>;
    mianbaoFlag?: Maybe<Scalars['String']>;
    stockFlag?: Maybe<Scalars['String']>;
    goodsCustname?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate: Scalars['Date'];
    lastUpdatePerson?: Maybe<Scalars['String']>;
    cusIEFlagName?: Maybe<Scalars['String']>;
    preEntryId?: Maybe<Scalars['String']>;
    entryId?: Maybe<Scalars['String']>;
    noOtherPack?: Maybe<Scalars['String']>;
    entQualifTypeCodeS?: Maybe<Scalars['String']>;
    entQualifTypeCodeSName?: Maybe<Scalars['String']>;
    ciqBillNo?: Maybe<Scalars['String']>;
    correlationDeclNo?: Maybe<Scalars['String']>;
    correlationReasonFlagName?: Maybe<Scalars['String']>;
    specDeclFlagInput?: Maybe<Scalars['String']>;
    appCert?: Maybe<Scalars['String']>;
    procmessage?: Maybe<Scalars['String']>;
    declDate?: Maybe<Scalars['String']>;
    preDecRequCertList?: Maybe<Array<Maybe<PreDecRequCertInput>>>;
    decOtherPacksVo?: Maybe<Array<Maybe<DecOtherPacksVoInput>>>;
    decMergeListVo?: Maybe<Array<Maybe<DecMergeListVoInput>>>;
    cmbdecMergeListVo?: Maybe<Array<Maybe<DecMergeListVoInput>>>;
    preDecEntQualifListVo?: Maybe<Array<Maybe<PreDecEntQualifListVoInput>>>;
    memoInstock?: Maybe<Scalars['String']>;
    operNames?: Maybe<Array<Maybe<Scalars['String']>>>;
    custNo?: Maybe<Scalars['String']>;
    cmbCutMode?: Maybe<Scalars['String']>;
    storeOutNo?: Maybe<Scalars['String']>;
    statusName?: Maybe<Scalars['String']>;
    statusCode?: Maybe<Scalars['Int']>;
    cusIEFlag?: Maybe<Scalars['String']>;
    customMasterName?: Maybe<Scalars['String']>;
    customMaster?: Maybe<Scalars['String']>;
    cusDecStatusName?: Maybe<Scalars['String']>;
    cusDecStatus?: Maybe<Scalars['String']>;
    cusCiqNo?: Maybe<Scalars['String']>;
    iEPortName?: Maybe<Scalars['String']>;
    iEPort?: Maybe<Scalars['String']>;
    manualNo?: Maybe<Scalars['String']>;
    contrNo?: Maybe<Scalars['String']>;
    iEDate?: Maybe<Scalars['String']>;
    dDate?: Maybe<Scalars['String']>;
    rcvgdTradeScc?: Maybe<Scalars['String']>;
    rcvgdTradeCode?: Maybe<Scalars['String']>;
    consigneeCode?: Maybe<Scalars['String']>;
    consigneeCname?: Maybe<Scalars['String']>;
    consignorCode?: Maybe<Scalars['String']>;
    consignorEname?: Maybe<Scalars['String']>;
    ownerScc?: Maybe<Scalars['String']>;
    ownerCode?: Maybe<Scalars['String']>;
    ownerCiqCode?: Maybe<Scalars['String']>;
    ownerName?: Maybe<Scalars['String']>;
    agentScc?: Maybe<Scalars['String']>;
    agentCode?: Maybe<Scalars['String']>;
    declRegNo?: Maybe<Scalars['String']>;
    agentName?: Maybe<Scalars['String']>;
    cusTrafModeName?: Maybe<Scalars['String']>;
    cusTrafMode?: Maybe<Scalars['String']>;
    trafName?: Maybe<Scalars['String']>;
    cusVoyageNo?: Maybe<Scalars['String']>;
    billNo?: Maybe<Scalars['String']>;
    tradeModeCode?: Maybe<Scalars['String']>;
    tradeModeCodeName?: Maybe<Scalars['String']>;
    cutModeName?: Maybe<Scalars['String']>;
    cutMode?: Maybe<Scalars['String']>;
    licenseNo?: Maybe<Scalars['String']>;
    cusTradeCountryName?: Maybe<Scalars['String']>;
    cusTradeCountry?: Maybe<Scalars['String']>;
    distinatePortName?: Maybe<Scalars['String']>;
    distinatePort?: Maybe<Scalars['String']>;
    transModeName?: Maybe<Scalars['String']>;
    transMode?: Maybe<Scalars['String']>;
    feeMarkName?: Maybe<Scalars['String']>;
    feeMark?: Maybe<Scalars['String']>;
    feeRate?: Maybe<Scalars['String']>;
    feeCurrName?: Maybe<Scalars['String']>;
    feeCurr?: Maybe<Scalars['String']>;
    insurMarkName?: Maybe<Scalars['String']>;
    insurMark?: Maybe<Scalars['String']>;
    insurRate?: Maybe<Scalars['String']>;
    insurCurrName?: Maybe<Scalars['String']>;
    insurCurr?: Maybe<Scalars['String']>;
    otherMarkName?: Maybe<Scalars['String']>;
    otherMark?: Maybe<Scalars['String']>;
    otherRate?: Maybe<Scalars['String']>;
    otherCurrName?: Maybe<Scalars['String']>;
    otherCurr?: Maybe<Scalars['String']>;
    packNo?: Maybe<Scalars['String']>;
    wrapTypeName?: Maybe<Scalars['String']>;
    wrapType?: Maybe<Scalars['String']>;
    grossWt?: Maybe<Scalars['String']>;
    netWt?: Maybe<Scalars['String']>;
    cusTradeNationCodeName?: Maybe<Scalars['String']>;
    cusTradeNationCode?: Maybe<Scalars['String']>;
    contaCount?: Maybe<Scalars['String']>;
    attaDocuCdstr?: Maybe<Scalars['String']>;
    ciqEntyPortCodeName?: Maybe<Scalars['String']>;
    ciqEntyPortCode?: Maybe<Scalars['String']>;
    goodsPlace?: Maybe<Scalars['String']>;
    despPortCodeName?: Maybe<Scalars['String']>;
    despPortCode?: Maybe<Scalars['String']>;
    entryTypeName?: Maybe<Scalars['String']>;
    entryType?: Maybe<Scalars['String']>;
    noteS?: Maybe<Scalars['String']>;
    mbnoteS?: Maybe<Scalars['String']>;
    flightName?: Maybe<Scalars['String']>;
    heavyflightType?: Maybe<Scalars['String']>;
    showSepNo?: Maybe<Scalars['String']>;
    mProjectNo?: Maybe<Scalars['String']>;
    mbcontrNo?: Maybe<Scalars['String']>;
    finishdate?: Maybe<Scalars['Date']>;
    markNo?: Maybe<Scalars['String']>;
    orgCodeName?: Maybe<Scalars['String']>;
    orgCode?: Maybe<Scalars['String']>;
    vsaOrgCodeName?: Maybe<Scalars['String']>;
    vsaOrgCode?: Maybe<Scalars['String']>;
    inspOrgCodeName?: Maybe<Scalars['String']>;
    inspOrgCode?: Maybe<Scalars['String']>;
    despDate?: Maybe<Scalars['String']>;
    purpOrgCodeName?: Maybe<Scalars['String']>;
    purpOrgCode?: Maybe<Scalars['String']>;
    origBoxFlagName?: Maybe<Scalars['String']>;
    origBoxFlag?: Maybe<Scalars['String']>;
    specDeclFlag?: Maybe<Scalars['String']>;
    appCertName?: Maybe<Scalars['String']>;
    promiseItems?: Maybe<Scalars['String']>;
    preDecContainerVo?: Maybe<Array<Maybe<SwPreDecContainerVoInput>>>;
    cusLicenseListVo?: Maybe<Array<Maybe<SwCusLicenseListVoInput>>>;
    preDecDocVo?: Maybe<Array<Maybe<SwPreDecDocVoInput>>>;
    sfserial?: Maybe<Scalars['String']>;
    flighttype?: Maybe<Scalars['String']>;
  };

  export type PreDecRequCertInput = {
    requCertSeqNo?: Maybe<Scalars['String']>;
    applOri?: Maybe<Scalars['String']>;
    createUser?: Maybe<Scalars['String']>;
    appCertName?: Maybe<Scalars['String']>;
    updateTime?: Maybe<Scalars['String']>;
    applCopyQuan?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
    appCertCode?: Maybe<Scalars['String']>;
    cusCiqNo?: Maybe<Scalars['String']>;
    indbTime?: Maybe<Scalars['String']>;
    updateUser?: Maybe<Scalars['String']>;
  };

  export type DecOtherPacksVoInput = {
    packType?: Maybe<Scalars['String']>;
    packTypeCode?: Maybe<Scalars['String']>;
    packQty?: Maybe<Scalars['Int']>;
  };

  export type DecMergeListVoInput = {
    gNo?: Maybe<Scalars['Int']>;
    contrItem?: Maybe<Scalars['String']>;
    codeTs?: Maybe<Scalars['String']>;
    ciqName?: Maybe<Scalars['String']>;
    ciqCode?: Maybe<Scalars['String']>;
    gName?: Maybe<Scalars['String']>;
    gModel?: Maybe<Scalars['String']>;
    gQty?: Maybe<Scalars['String']>;
    gUnitName?: Maybe<Scalars['String']>;
    gUnit?: Maybe<Scalars['String']>;
    declPrice?: Maybe<Scalars['String']>;
    declTotal?: Maybe<Scalars['String']>;
    tradeCurrName?: Maybe<Scalars['String']>;
    tradeCurr?: Maybe<Scalars['String']>;
    qty1?: Maybe<Scalars['String']>;
    unit1Name?: Maybe<Scalars['String']>;
    unit1?: Maybe<Scalars['String']>;
    exgVersion?: Maybe<Scalars['String']>;
    exgNo?: Maybe<Scalars['String']>;
    destinationCountryName?: Maybe<Scalars['String']>;
    destinationCountry?: Maybe<Scalars['String']>;
    qty2?: Maybe<Scalars['String']>;
    unit2Name?: Maybe<Scalars['String']>;
    unit2?: Maybe<Scalars['String']>;
    cusOriginCountryName?: Maybe<Scalars['String']>;
    cusOriginCountry?: Maybe<Scalars['String']>;
    origPlaceCodeName?: Maybe<Scalars['String']>;
    origPlaceCode?: Maybe<Scalars['String']>;
    districtCodeName?: Maybe<Scalars['String']>;
    districtCode?: Maybe<Scalars['String']>;
    ciqDestCodeName?: Maybe<Scalars['String']>;
    ciqDestCode?: Maybe<Scalars['String']>;
    dutyModeName?: Maybe<Scalars['String']>;
    dutyMode?: Maybe<Scalars['String']>;
    goodsAttrName?: Maybe<Scalars['String']>;
    sku?: Maybe<Scalars['String']>;
    realdeclTotal?: Maybe<Scalars['String']>;
    dnno?: Maybe<Scalars['String']>;
    dnpage?: Maybe<Scalars['String']>;
    pageinvoice?: Maybe<Scalars['String']>;
    hzsku?: Maybe<Scalars['String']>;
    pageNetweight?: Maybe<Scalars['String']>;
    realdeclPrice?: Maybe<Scalars['String']>;
    contrNo?: Maybe<Scalars['String']>;
    mbgModel?: Maybe<Scalars['String']>;
    netWeight?: Maybe<Scalars['String']>;
    qty?: Maybe<Scalars['String']>;
    subsystem?: Maybe<Scalars['String']>;
    nameDesc?: Maybe<Scalars['String']>;
    eqpname?: Maybe<Scalars['String']>;
    firstlevel?: Maybe<Scalars['String']>;
    secondlevel?: Maybe<Scalars['String']>;
    decMergeOrder?: Maybe<Scalars['String']>;
    combinebf?: Maybe<Scalars['String']>;
    ControlMark?: Maybe<Scalars['String']>;
    TarifFlag?: Maybe<Scalars['String']>;
    productid?: Maybe<Scalars['String']>;
    productupdateDate?: Maybe<Scalars['Date']>;
    pageindex?: Maybe<Scalars['Int']>;
    pageurl?: Maybe<Scalars['String']>;
    instockSku?: Maybe<Scalars['String']>;
    confidence?: Maybe<Scalars['String']>;
    drawamount?: Maybe<Scalars['Float']>;
    sourceProdInfo?: Maybe<Array<Maybe<SplitProdDetailInput>>>;
    targetProdInfo?: Maybe<Array<Maybe<SplitProdDetailInput>>>;
    classifyInfo?: Maybe<ClassinfoInfoInput>;
    purpose?: Maybe<Scalars['String']>;
    purposeName?: Maybe<Scalars['String']>;
    goodsAttr?: Maybe<Scalars['String']>;
    freeFlag?: Maybe<Scalars['String']>;
    loctioninfo?: Maybe<Array<Maybe<OcrLoctionInput>>>;
    prodQgp?: Maybe<Scalars['String']>;
    stuff?: Maybe<Scalars['String']>;
    prodValidDt?: Maybe<Scalars['String']>;
    engManEntCnm?: Maybe<Scalars['String']>;
    goodsSpec?: Maybe<Scalars['String']>;
    goodsBrand?: Maybe<Scalars['String']>;
    goodsModel?: Maybe<Scalars['String']>;
    produceDate?: Maybe<Scalars['String']>;
    prodBatchNo?: Maybe<Scalars['String']>;
    noDangFlagName?: Maybe<Scalars['String']>;
    noDangFlag?: Maybe<Scalars['String']>;
    unCode?: Maybe<Scalars['String']>;
    dangName?: Maybe<Scalars['String']>;
    packTypeName?: Maybe<Scalars['String']>;
    packType?: Maybe<Scalars['String']>;
    packSpec?: Maybe<Scalars['String']>;
    preDecCiqGoodsLimit?: Maybe<Array<Maybe<PreDecCiqGoodsLimitInput>>>;
    extendjson?: Maybe<Scalars['JSON']>;
    namememo?: Maybe<Scalars['String']>;
    modflag?: Maybe<Scalars['Int']>;
    multiflag?: Maybe<Scalars['Int']>;
    singleweight?: Maybe<Scalars['Float']>;
  };

  export type SplitProdDetailInput = {
    orderNo?: Maybe<Scalars['String']>;
    gNo?: Maybe<Scalars['Int']>;
    orderOutNo?: Maybe<Scalars['String']>;
    qty?: Maybe<Scalars['Float']>;
  };

  export type ClassinfoInfoInput = {
    codeTs?: Maybe<Scalars['String']>;
    unit1?: Maybe<Scalars['String']>;
    unit1Name?: Maybe<Scalars['String']>;
    unit2?: Maybe<Scalars['String']>;
    unit2Name?: Maybe<Scalars['String']>;
    unitFlag?: Maybe<Scalars['String']>;
    low_rate?: Maybe<Scalars['String']>;
    high_rate?: Maybe<Scalars['String']>;
    out_rate?: Maybe<Scalars['String']>;
    reg_rate?: Maybe<Scalars['String']>;
    reg_amount?: Maybe<Scalars['Float']>;
    tax_rate?: Maybe<Scalars['String']>;
    tax_amount?: Maybe<Scalars['Float']>;
    back_rate?: Maybe<Scalars['String']>;
    low_t_rate?: Maybe<Scalars['String']>;
    low_out_rate?: Maybe<Scalars['String']>;
    ret_rate?: Maybe<Scalars['String']>;
    cif_total_amount?: Maybe<Scalars['Float']>;
    extrange_rate_cny?: Maybe<Scalars['Float']>;
    in_amount?: Maybe<Scalars['Float']>;
    out_amount?: Maybe<Scalars['Float']>;
    ret_amount?: Maybe<Scalars['Float']>;
    total_tax_amount?: Maybe<Scalars['Float']>;
    fob_trans_fee?: Maybe<Scalars['Float']>;
    cusOriginCountry?: Maybe<Scalars['String']>;
  };

  export type OcrLoctionInput = {
    field?: Maybe<Scalars['String']>;
    orivalue?: Maybe<Scalars['String']>;
    conceptKey?: Maybe<Scalars['String']>;
    topLeftX?: Maybe<Scalars['Float']>;
    topLeftY?: Maybe<Scalars['Float']>;
    bottomRightX?: Maybe<Scalars['Float']>;
    bottomRightY?: Maybe<Scalars['Float']>;
    confidence?: Maybe<Scalars['Float']>;
  };

  export type PreDecCiqGoodsLimitInput = {
    gNo?: Maybe<Scalars['String']>;
    goodsLimitSeqNo?: Maybe<Scalars['String']>;
    licWrtofUnit?: Maybe<Scalars['String']>;
    licWrtofQty?: Maybe<Scalars['String']>;
    licWrtofUnitName?: Maybe<Scalars['String']>;
    licWrtofDetailNo?: Maybe<Scalars['String']>;
    licenceNo?: Maybe<Scalars['String']>;
    licTypeName?: Maybe<Scalars['String']>;
    licTypeCodeName?: Maybe<Scalars['String']>;
    licTypeCode?: Maybe<Scalars['String']>;
    preDecCiqGoodsLimitVinVo?: Maybe<Array<Maybe<PreDecCiqGoodsLimitVinVoInput>>>;
  };

  export type PreDecCiqGoodsLimitVinVoInput = {
    gNo?: Maybe<Scalars['String']>;
    invoiceNum?: Maybe<Scalars['String']>;
    goodsLimitSeqNo?: Maybe<Scalars['String']>;
    prodEnnm?: Maybe<Scalars['String']>;
    vinNo?: Maybe<Scalars['String']>;
    invoiceNo?: Maybe<Scalars['String']>;
    motorNo?: Maybe<Scalars['String']>;
    goodsLimitVinSeqNo?: Maybe<Scalars['String']>;
    prodCnnm?: Maybe<Scalars['String']>;
    pricePerUnit?: Maybe<Scalars['String']>;
    licenceNo?: Maybe<Scalars['String']>;
    qualityQgp?: Maybe<Scalars['String']>;
    billLadDate?: Maybe<Scalars['String']>;
    vinCode?: Maybe<Scalars['String']>;
    licTypeCodeName?: Maybe<Scalars['String']>;
    modelEn?: Maybe<Scalars['String']>;
    chassisNo?: Maybe<Scalars['String']>;
    licTypeCode?: Maybe<Scalars['String']>;
  };

  export type PreDecEntQualifListVoInput = {
    entQualifTypeCode?: Maybe<Scalars['String']>;
    entQualifTypeCodeName?: Maybe<Scalars['String']>;
    entQualifSeqNo?: Maybe<Scalars['String']>;
    entQualifNo?: Maybe<Scalars['String']>;
  };

  export type SwPreDecContainerVoInput = {
    cntnrModeCode?: Maybe<Scalars['String']>;
    contSeqNo?: Maybe<Scalars['String']>;
    updateTime?: Maybe<Scalars['String']>;
    cntnrModeCodeName?: Maybe<Scalars['String']>;
    lclFlag?: Maybe<Scalars['String']>;
    goodsNo?: Maybe<Scalars['String']>;
    containerMdCodeName?: Maybe<Scalars['String']>;
    containerNo?: Maybe<Scalars['String']>;
    createUser?: Maybe<Scalars['String']>;
    lclFlagName?: Maybe<Scalars['String']>;
    cusCiqNo?: Maybe<Scalars['String']>;
    containerMdCode?: Maybe<Scalars['String']>;
    indbTime?: Maybe<Scalars['String']>;
    updateUser?: Maybe<Scalars['String']>;
    addList?: Maybe<Scalars['String']>;
    containerWt?: Maybe<Scalars['String']>;
  };

  export type SwCusLicenseListVoInput = {
    createUser?: Maybe<Scalars['String']>;
    updateTime?: Maybe<Scalars['String']>;
    preDecCusEcoRel?: Maybe<Scalars['String']>;
    acmpFormCodeName?: Maybe<Scalars['String']>;
    formSeqNo?: Maybe<Scalars['String']>;
    cusCiqNo?: Maybe<Scalars['String']>;
    acmpFormCode?: Maybe<Scalars['String']>;
    acmpFormName?: Maybe<Scalars['String']>;
    indbTime?: Maybe<Scalars['String']>;
    acmpFormNo?: Maybe<Scalars['String']>;
    updateUser?: Maybe<Scalars['String']>;
  };

  export type SwPreDecDocVoInput = {
    attEdocId?: Maybe<Scalars['String']>;
    attEdocNo?: Maybe<Scalars['String']>;
    attEdocPath?: Maybe<Scalars['String']>;
    attEdocSize?: Maybe<Scalars['String']>;
    attEdocStatus?: Maybe<Scalars['String']>;
    attFmtTypeCode?: Maybe<Scalars['String']>;
    attSeqNo?: Maybe<Scalars['String']>;
    attTypeCode?: Maybe<Scalars['String']>;
    attTypeCodeName?: Maybe<Scalars['String']>;
    belongWkunitCode?: Maybe<Scalars['String']>;
    belongWkunitName?: Maybe<Scalars['String']>;
    bizTypeCode?: Maybe<Scalars['String']>;
    createUser?: Maybe<Scalars['String']>;
    cusCiqNo?: Maybe<Scalars['String']>;
    delList?: Maybe<Scalars['String']>;
    entOrigFileName?: Maybe<Scalars['String']>;
    indbTime?: Maybe<Scalars['String']>;
    signDate?: Maybe<Scalars['String']>;
    signWkunitCode?: Maybe<Scalars['String']>;
    updateTime?: Maybe<Scalars['String']>;
    updateUser?: Maybe<Scalars['String']>;
    uploadOpTypeCode?: Maybe<Scalars['String']>;
  };

  export type ProductCompanyInput = {
    id?: Maybe<Scalars['ID']>;
    supplierName?: Maybe<Scalars['String']>;
    suppplierEnName?: Maybe<Scalars['String']>;
    appID?: Maybe<Scalars['String']>;
    appCompany?: Maybe<Scalars['String']>;
    memo?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type KddPreOrderTemplateInput = {
    id?: Maybe<Scalars['ID']>;
    appID?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    memo?: Maybe<Scalars['String']>;
    templateConfig?: Maybe<Scalars['JSON']>;
    tempalteValue?: Maybe<KddPreOrderInput>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type DecStatusInput = {
    id?: Maybe<Scalars['ID']>;
    orderNo?: Maybe<Scalars['String']>;
    appID?: Maybe<Scalars['String']>;
    refNo?: Maybe<Scalars['String']>;
    note?: Maybe<Scalars['String']>;
    cusCiqNo?: Maybe<Scalars['String']>;
    channel?: Maybe<Scalars['String']>;
    statusCode?: Maybe<Scalars['Int']>;
    statusName?: Maybe<Scalars['String']>;
    entryId?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };


  export type RpaJobInfoInput = {
    id?: Maybe<Scalars['ID']>;
    taskCode?: Maybe<Scalars['String']>;
    appID?: Maybe<Scalars['String']>;
    taskName?: Maybe<Scalars['String']>;
    jobName?: Maybe<Scalars['String']>;
    memo?: Maybe<Scalars['String']>;
    inputFileName?: Maybe<Scalars['String']>;
    ossPath?: Maybe<Scalars['String']>;
    jobRecords?: Maybe<Array<Maybe<RpaJobRecordInput>>>;
    /** processDetails: Object */
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type RpaJobRecordInput = {
    name?: Maybe<Scalars['String']>;
    main?: Maybe<Scalars['String']>;
    branch?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  export type RpaJobProcessDetailInput = {
    id?: Maybe<Scalars['ID']>;
    jobNo?: Maybe<Scalars['String']>;
    seqNo?: Maybe<Scalars['Int']>;
    keyValue?: Maybe<Scalars['String']>;
    data?: Maybe<Scalars['JSON']>;
    statusName?: Maybe<Scalars['String']>;
    paraJson?: Maybe<Scalars['JSON']>;
    clientID?: Maybe<Scalars['String']>;
    processMessage?: Maybe<Scalars['String']>;
  };

  export type RpaTaskDefInput = {
    id?: Maybe<Scalars['ID']>;
    taskName?: Maybe<Scalars['String']>;
    appID?: Maybe<Scalars['String']>;
    taskType?: Maybe<Scalars['String']>;
    jobRecords?: Maybe<Array<Maybe<RpaJobRecordInput>>>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
  };

  /** 快件订单信息 */
  export type ExpressOrderInput = {
    /** 翔运业务编号 */
    trackId?: Maybe<Scalars['String']>;
    /** 商品名称 */
    goodsDesp?: Maybe<Scalars['String']>;
    /** 英文品名 */
    goodsDspeEn?: Maybe<Scalars['String']>;
    /** 件数 */
    packNo?: Maybe<Scalars['Int']>;
    /** 数量 */
    qty?: Maybe<Scalars['Float']>;
    /** 毛重 */
    grossWeight?: Maybe<Scalars['Float']>;
    /** 金额 */
    totalAmont?: Maybe<Scalars['Float']>;
    /** 收货人 */
    consingee?: Maybe<Scalars['String']>;
    /** 收货地址 */
    consingeeAddress?: Maybe<Scalars['String']>;
    /** 发货人 */
    shipper?: Maybe<Scalars['String']>;
    /** 货主 */
    owner?: Maybe<Scalars['String']>;
    /** 总运单号 */
    mAWBNo?: Maybe<Scalars['String']>;
    /** 分运单号 */
    hAWBNo?: Maybe<Scalars['String']>;
    /** 运费 */
    transFeeAmount?: Maybe<Scalars['Float']>;
    curr?: Maybe<Scalars['String']>;
    transFeeCurr?: Maybe<Scalars['String']>;
  };

  export type OssFileInfoInput = {
    id?: Maybe<Scalars['ID']>;
    refNo?: Maybe<Scalars['String']>;
    pathTitle?: Maybe<Scalars['String']>;
    isDic?: Maybe<Scalars['Boolean']>;
    fileName?: Maybe<Scalars['String']>;
    bucketName?: Maybe<Scalars['String']>;
    ossPath?: Maybe<Scalars['String']>;
    memo?: Maybe<Scalars['String']>;
    sourceDesc?: Maybe<Scalars['String']>;
    tags?: Maybe<Array<Maybe<Scalars['String']>>>;
    md5str?: Maybe<Scalars['String']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    appID?: Maybe<Scalars['String']>;
  };

  export type HsRegexInput = {
    id?: Maybe<Scalars['ID']>;
    hsCode?: Maybe<Scalars['String']>;
    gName?: Maybe<Scalars['String']>;
    ciqName?: Maybe<Scalars['String']>;
    sugunit?: Maybe<Scalars['Date']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    declareElements?: Maybe<Scalars['JSON']>;
  };

  export type ValidationruleInput = {
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    aviltype?: Maybe<Scalars['String']>;
    cust?: Maybe<Scalars['String']>;
    processtype?: Maybe<Scalars['String']>;
    sorobj?: Maybe<Scalars['String']>;
    condition?: Maybe<Scalars['JSON']>;
    errorInfo?: Maybe<Scalars['String']>;
    rule?: Maybe<Scalars['JSON']>;
    createTime?: Maybe<Scalars['Date']>;
    createPerson?: Maybe<Scalars['String']>;
    lastUpdateDate?: Maybe<Scalars['Date']>;
    lastUpdatePerson?: Maybe<Scalars['String']>;
    appID?: Maybe<Scalars['String']>;
  };

  export type Filter = {
    ieFlag?: Maybe<Scalars['String']>;
  };

  export enum IeType {
    Import = 'IMPORT',
    Export = 'EXPORT',
    Inbound = 'INBOUND',
    Outbound = 'OUTBOUND'
  }

  export type MailAddressBaseInput = {
    address?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
  };

  export type MailAddressInput = {
    value?: Maybe<Array<Maybe<MailAddressBaseInput>>>;
    html?: Maybe<Scalars['String']>;
    text?: Maybe<Scalars['String']>;
  };

  export type SingleusageInput = {
    machinename?: Maybe<Scalars['String']>;
    machinemodel?: Maybe<Scalars['String']>;
    amount?: Maybe<Scalars['String']>;
  };

  export type DataLogInput = {
    id?: Maybe<Scalars['ID']>;
    appID?: Maybe<Scalars['String']>;
    upid?: Maybe<Scalars['String']>;
    upmemo?: Maybe<Scalars['String']>;
    uptype?: Maybe<Scalars['String']>;
    upperson?: Maybe<Scalars['String']>;
    uptime?: Maybe<Scalars['Date']>;
    detail?: Maybe<Scalars['JSON']>;
  };
}