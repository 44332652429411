import { NgModule } from '@angular/core';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';

import {
  MenuFoldOutline,
  MenuUnfoldOutline,
  FormOutline,
  DashboardOutline,
  EditTwoTone,
  DeleteTwoTone,
  LogoutOutline,
  LockOutline,
  UserOutline,
  MailOutline,
  SettingOutline,
  SaveOutline,
  RightCircleTwoTone,
  RollbackOutline,
  DeleteOutline,
  FolderViewOutline,
  InboxOutline,
  RedoOutline,
} from '@ant-design/icons-angular/icons';

const icons = [MenuFoldOutline, MenuUnfoldOutline, DashboardOutline, 
  FormOutline,EditTwoTone,DeleteTwoTone,LockOutline,UserOutline,MailOutline,
  SettingOutline,SaveOutline,RightCircleTwoTone,RollbackOutline,DeleteOutline,
  FolderViewOutline,LogoutOutline,InboxOutline,RedoOutline
];

@NgModule({
  imports: [NzIconModule],
  exports: [NzIconModule],
  providers: [
    { provide: NZ_ICONS, useValue: icons }
  ]
})
export class IconsProviderModule {
}
