export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


/** 重装目录 */
export type AeoHeavyeqp = IDataInfo & {
  __typename?: 'AeoHeavyeqp';
  id?: Maybe<Scalars['ID']>;
  hscode?: Maybe<Scalars['String']>;
  eqpclass?: Maybe<Scalars['String']>;
  eqptype?: Maybe<Scalars['String']>;
  eqpcategory?: Maybe<Scalars['String']>;
  eqpname?: Maybe<Scalars['String']>;
  firstlevel?: Maybe<Scalars['String']>;
  secondlevel?: Maybe<Scalars['String']>;
  singleusage?: Maybe<Scalars['JSON']>;
  expiredyear?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 重装目录 */
export type AeoHeavyeqpInput = {
  id?: Maybe<Scalars['ID']>;
  hscode?: Maybe<Scalars['String']>;
  eqpclass?: Maybe<Scalars['String']>;
  eqptype?: Maybe<Scalars['String']>;
  eqpcategory?: Maybe<Scalars['String']>;
  eqpname?: Maybe<Scalars['String']>;
  firstlevel?: Maybe<Scalars['String']>;
  secondlevel?: Maybe<Scalars['String']>;
  singleusage?: Maybe<Scalars['JSON']>;
  expiredyear?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type AeoValidateResult = {
  __typename?: 'AeoValidateResult';
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Array<Maybe<Scalars['String']>>>;
  errorMessage?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<ValidateErrorDesc>>>;
  ruleName?: Maybe<Scalars['String']>;
  ruleId?: Maybe<Scalars['Int']>;
  relatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** 一体化 卡口验放凭证 */
export type AeoValidateRule = IDataInfo & {
  __typename?: 'AeoValidateRule';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type AeoValidateRuleInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** map POI 信息 */
export type AmapPoi = {
  __typename?: 'AmapPOI';
  /** POI 名称 */
  id?: Maybe<Scalars['String']>;
  /** POI 名称 */
  name?: Maybe<Scalars['String']>;
  /** POI 类型 */
  type?: Maybe<Scalars['String']>;
  /** POI 类型编码 */
  typecode?: Maybe<Scalars['String']>;
  /** POI 坐标 */
  location?: Maybe<Scalars['String']>;
  /** POI 地址信息 */
  address?: Maybe<Scalars['String']>;
  /** POI 所在省份名称 */
  pname?: Maybe<Scalars['String']>;
  /** POI 所在城市名称 */
  cityname?: Maybe<Scalars['String']>;
  /** POI 所在区名称 */
  adname?: Maybe<Scalars['String']>;
  /** POI 所在区域编码 */
  adcode?: Maybe<Scalars['String']>;
};

/** application def */
export type AppAppName = IDataInfo & {
  __typename?: 'AppAppName';
  id?: Maybe<Scalars['ID']>;
  appName?: Maybe<Scalars['String']>;
  appMemo?: Maybe<Scalars['String']>;
  appRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type AppAppNameInput = {
  id?: Maybe<Scalars['ID']>;
  appName?: Maybe<Scalars['String']>;
  appMemo?: Maybe<Scalars['String']>;
  appRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 系统使用公司 参数，海关编码 参数信息 */
export type AppCompany = IDataInfo & {
  __typename?: 'AppCompany';
  id?: Maybe<Scalars['ID']>;
  companyName?: Maybe<Scalars['String']>;
  /** 海关10位数编码 */
  customCode?: Maybe<Scalars['String']>;
  /** 企业信用编号 */
  tradeCodeSCC?: Maybe<Scalars['String']>;
  /** 企业商检编码 */
  customCiqCode?: Maybe<Scalars['String']>;
  consignors?: Maybe<Array<Maybe<RelatedCompanyConfig>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  /** 章信息 */
  stampsPic?: Maybe<Array<Maybe<KeyValue>>>;
};

export type AppCompanyInput = {
  id?: Maybe<Scalars['ID']>;
  companyName?: Maybe<Scalars['String']>;
  customCode?: Maybe<Scalars['String']>;
  tradeCodeSCC?: Maybe<Scalars['String']>;
  customCiqCode?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  stampsPic?: Maybe<Array<Maybe<KeyValueInput>>>;
};

export type AppComponentConfig = {
  __typename?: 'AppComponentConfig';
  id?: Maybe<Scalars['ID']>;
  config?: Maybe<Scalars['JSON']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};

export type AppComponentConfigInput = {
  id?: Maybe<Scalars['ID']>;
  config?: Maybe<Scalars['JSON']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};

/** appd对应部门部门 */
export type AppDepartment = {
  __typename?: 'AppDepartment';
  id?: Maybe<Scalars['ID']>;
  appName?: Maybe<Scalars['String']>;
  /** 钉钉中departID String是不是更好？一个appName里不能重复 name也是 */
  departId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parentid?: Maybe<Scalars['Int']>;
  departEmail?: Maybe<Scalars['String']>;
  departEmailPassword?: Maybe<Scalars['String']>;
  serverAddress?: Maybe<Scalars['String']>;
  /** 数据来源，如果是钉钉来，id和name不能修改 */
  source?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type AppDepartmentInput = {
  id?: Maybe<Scalars['ID']>;
  appName?: Maybe<Scalars['String']>;
  /** 钉钉中departID String是不是更好？一个appName里不能重复 name也是 */
  departId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parentid?: Maybe<Scalars['Int']>;
  departEmail?: Maybe<Scalars['String']>;
  departEmailPassword?: Maybe<Scalars['String']>;
  serverAddress?: Maybe<Scalars['String']>;
  /** 数据来源，如果是钉钉来，id和name不能修改 */
  source?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** AppRolePolicy */
export type AppRolePolicy = IDataInfo & {
  __typename?: 'AppRolePolicy';
  id?: Maybe<Scalars['ID']>;
  AppID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Array<Maybe<Scalars['String']>>>;
  ActionName?: Maybe<Scalars['String']>;
  ActionType?: Maybe<Scalars['String']>;
  Memo?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type AppRolePolicyInput = {
  id?: Maybe<Scalars['ID']>;
  AppID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Array<Maybe<Scalars['String']>>>;
  ActionName?: Maybe<Scalars['String']>;
  ActionType?: Maybe<Scalars['String']>;
  Memo?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type AppUserInfo = IDataInfo & {
  __typename?: 'AppUserInfo';
  id?: Maybe<Scalars['ID']>;
  mobileNo?: Maybe<Scalars['String']>;
  openID?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
  QRID?: Maybe<Scalars['String']>;
  activeDate?: Maybe<Scalars['Date']>;
  electronSign?: Maybe<Scalars['String']>;
  headImgUrl?: Maybe<Scalars['String']>;
  socketID?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  validFlag?: Maybe<Scalars['Boolean']>;
  userName?: Maybe<Scalars['String']>;
  departments?: Maybe<Array<Maybe<KeyValue>>>;
  /** 默认部门 */
  defaultDepart?: Maybe<KeyValue>;
  /** work email */
  email?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  /** 钉钉企业id 对应companyName */
  dingAppCorpID?: Maybe<Scalars['String']>;
  /** 钉钉 or 微信 */
  dingWechat?: Maybe<Scalars['String']>;
};

export type AppUserInfoInput = {
  id?: Maybe<Scalars['ID']>;
  mobileNo?: Maybe<Scalars['String']>;
  openID?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
  QRID?: Maybe<Scalars['String']>;
  activeDate?: Maybe<Scalars['Date']>;
  departments?: Maybe<Array<Maybe<KeyValueInput>>>;
  /** work email */
  email?: Maybe<Scalars['String']>;
  electronSign?: Maybe<Scalars['String']>;
  headImgUrl?: Maybe<Scalars['String']>;
  socketID?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  validFlag?: Maybe<Scalars['Boolean']>;
  userName?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  /** 钉钉企业id 对应companyName */
  dingAppCorpID?: Maybe<Scalars['String']>;
  /** 钉钉 or 微信 */
  dingWechat?: Maybe<Scalars['String']>;
};

export type Approvers = {
  __typename?: 'Approvers';
  user_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  task_action_type?: Maybe<Scalars['String']>;
};

/** 对账业务基础信息 */
export type BillOrderData = {
  __typename?: 'BillOrderData';
  /** 业务单号 */
  orderNo?: Maybe<Scalars['String']>;
  /** 业务类型 */
  orderType?: Maybe<Scalars['String']>;
  /** 业务部门 */
  orderDept?: Maybe<Scalars['String']>;
  /** 业务员 */
  orderPerson?: Maybe<Scalars['String']>;
  /** 业务日期 */
  orderDate?: Maybe<Scalars['Date']>;
  /** 业务描述 */
  orderDesc?: Maybe<Scalars['String']>;
  applyid?: Maybe<Scalars['ID']>;
  projectClass?: Maybe<Scalars['String']>;
  tmsApplyNo?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  transStatus?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  wbsNo?: Maybe<Scalars['String']>;
  transType?: Maybe<Scalars['String']>;
  customsType?: Maybe<Scalars['String']>;
  planTransDate?: Maybe<Scalars['Date']>;
  TotalAmount?: Maybe<Scalars['Float']>;
  CostAmount?: Maybe<Scalars['Float']>;
  Profit?: Maybe<Scalars['Float']>;
};

export type BillOrderDataInput = {
  /** 业务单号 */
  orderNo?: Maybe<Scalars['String']>;
  /** 业务类型 */
  orderType?: Maybe<Scalars['String']>;
  /** 业务部门 */
  orderDept?: Maybe<Scalars['String']>;
  /** 业务员 */
  orderPerson?: Maybe<Scalars['String']>;
  /** 业务日期 */
  orderDate?: Maybe<Scalars['Date']>;
  /** 业务描述 */
  orderDesc?: Maybe<Scalars['String']>;
  applyid?: Maybe<Scalars['ID']>;
  projectClass?: Maybe<Scalars['String']>;
  tmsApplyNo?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  transStatus?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  wbsNo?: Maybe<Scalars['String']>;
  transType?: Maybe<Scalars['String']>;
  customsType?: Maybe<Scalars['String']>;
  planTransDate?: Maybe<Scalars['Date']>;
  TotalAmount?: Maybe<Scalars['Float']>;
  CostAmount?: Maybe<Scalars['Float']>;
  Profit?: Maybe<Scalars['Float']>;
};

/** 改单申请列表 */
export type BizCompanyInfo = IDataInfo & {
  __typename?: 'BizCompanyInfo';
  id?: Maybe<Scalars['ID']>;
  /** 工商信息 */
  name?: Maybe<Scalars['String']>;
  SCC?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  companyType?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  estDate?: Maybe<Scalars['Date']>;
  keyCode?: Maybe<Scalars['String']>;
  roleList?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupCode?: Maybe<Scalars['String']>;
  /** 税务信息 */
  InvoiceTaxNo?: Maybe<Scalars['String']>;
  InvoiceBankName?: Maybe<Scalars['String']>;
  InvoiceAccount?: Maybe<Scalars['String']>;
  /** 钉钉企业ID dingb2985c3caba0d75c24f2f5cc6abecb85 */
  dingCorpId?: Maybe<Scalars['String']>;
  /** 银行信息 */
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  /** 运输车队接单调度列表 */
  tmsSupplierDiaodu?: Maybe<Array<Maybe<KeyValue>>>;
  /** 车队公司邮箱 用于接受委托邮件 */
  supplierEmail?: Maybe<Scalars['String']>;
};

export type BizCompanyInfoInput = {
  id?: Maybe<Scalars['ID']>;
  /** 工商信息 */
  name?: Maybe<Scalars['String']>;
  SCC?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  companyType?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  estDate?: Maybe<Scalars['Date']>;
  keyCode?: Maybe<Scalars['String']>;
  roleList?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupCode?: Maybe<Scalars['String']>;
  /** 税务信息 */
  InvoiceTaxNo?: Maybe<Scalars['String']>;
  InvoiceBankName?: Maybe<Scalars['String']>;
  InvoiceAccount?: Maybe<Scalars['String']>;
  /** 钉钉企业ID dingb2985c3caba0d75c24f2f5cc6abecb85 */
  dingCorpId?: Maybe<Scalars['String']>;
  tmsSupplierDiaodu?: Maybe<Array<Maybe<KeyValueInput>>>;
  /** 银行信息 */
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  /** 车队公司邮箱 用于接受委托邮件 */
  supplierEmail?: Maybe<Scalars['String']>;
};

/** 开票申请单 */
export type BizInvoiceApply = IDataInfo & {
  __typename?: 'BizInvoiceApply';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 申请单号 */
  invoiceApplyNo?: Maybe<Scalars['String']>;
  payApplyRemark?: Maybe<Scalars['String']>;
  /** 申请日期 */
  applyDate?: Maybe<Scalars['String']>;
  /** 申请人 */
  applyUser?: Maybe<Scalars['String']>;
  /** 申请人部门 */
  applyDept?: Maybe<Scalars['String']>;
  /** 发票类型 */
  invoiceType?: Maybe<Scalars['String']>;
  /** 发票抬头 */
  invoiceTitle?: Maybe<Scalars['String']>;
  /** 纳税人识别号 */
  taxNo?: Maybe<Scalars['String']>;
  /** 开户行 */
  bank?: Maybe<Scalars['String']>;
  /** 银行账号 */
  bankAccount?: Maybe<Scalars['String']>;
  /** 电话 */
  phone?: Maybe<Scalars['String']>;
  /** 地址 */
  address?: Maybe<Scalars['String']>;
  /** 申请金额 */
  applyAmount?: Maybe<Scalars['Float']>;
  /** 申请金额大写 */
  applyAmountUpper?: Maybe<Scalars['String']>;
  /** 申请备注 */
  applyRemark?: Maybe<Scalars['String']>;
  /** 对账客户 */
  custID?: Maybe<Scalars['String']>;
  /** 对账客户名称 */
  custName?: Maybe<Scalars['String']>;
  /** 开票明细 */
  invoiceDetail?: Maybe<Array<Maybe<BillOrderData>>>;
  /** 开票收入明细 总金额应该和申请金额一致 */
  invoiceIncomeDetail?: Maybe<Array<Maybe<ServiceBillDetailInfo>>>;
  /** 开票成本明细 对应业务对应的成本汇总,理论上应该有个毛利率,应该是总金额-总成本 */
  invoiceCostDetail?: Maybe<Array<Maybe<ServicePurchaseDetailInfo>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type BizInvoiceApplyInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  invoiceApplyNo?: Maybe<Scalars['String']>;
  payApplyRemark?: Maybe<Scalars['String']>;
  applyDate?: Maybe<Scalars['String']>;
  applyUser?: Maybe<Scalars['String']>;
  applyDept?: Maybe<Scalars['String']>;
  invoiceType?: Maybe<Scalars['String']>;
  invoiceTitle?: Maybe<Scalars['String']>;
  taxNo?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  applyAmount?: Maybe<Scalars['Float']>;
  applyAmountUpper?: Maybe<Scalars['String']>;
  applyRemark?: Maybe<Scalars['String']>;
  /** 对账客户 */
  custID?: Maybe<Scalars['String']>;
  /** 对账客户名称 */
  custName?: Maybe<Scalars['String']>;
  invoiceDetail?: Maybe<Array<Maybe<BillOrderDataInput>>>;
  invoiceIncomeDetail?: Maybe<Array<Maybe<ServiceBillDetailInfoInput>>>;
  invoiceCostDetail?: Maybe<Array<Maybe<ServicePurchaseDetailInfoInput>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 付款申请表 */
export type BizPaySupplierApply = IDataInfo & {
  __typename?: 'BizPaySupplierApply';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 申请单号 生成规则：年月日+序号 + 供应商名称 简称 不能修改 */
  payApplyNo?: Maybe<Scalars['String']>;
  /** 备注 */
  payApplyRemark?: Maybe<Scalars['String']>;
  /** 发票号码 */
  invoiceNo?: Maybe<Scalars['String']>;
  /** 发票金额 */
  invoiceAmount?: Maybe<Scalars['Float']>;
  /** 发票税率 */
  taxRate?: Maybe<Scalars['Float']>;
  /** 发票税额 */
  taxAmount?: Maybe<Scalars['Float']>;
  /** 付款金额 */
  payAmount?: Maybe<Scalars['Float']>;
  /** 付款成本明细 */
  costDetails?: Maybe<Array<Maybe<ServicePurchaseDetailInfo>>>;
  /** 申请日期 */
  applyDate?: Maybe<Scalars['String']>;
  /** 申请人 */
  applyUser?: Maybe<Scalars['String']>;
  /** 申请人部门 */
  applyDept?: Maybe<Scalars['String']>;
  /** 供应商 */
  supplier?: Maybe<Scalars['String']>;
  /** 供应商名称 */
  supplierName?: Maybe<Scalars['String']>;
  /** 付款合同 */
  payContractNo?: Maybe<Scalars['String']>;
  /** 付款合同名称 */
  payContractName?: Maybe<Scalars['String']>;
  /** 付款合同限额 */
  payContractLimit?: Maybe<Scalars['Float']>;
  /** 付款合同已付金额 */
  payContractPaid?: Maybe<Scalars['Float']>;
  /** 关联订单 */
  relatedOrder?: Maybe<Array<Maybe<RoadTransForwardingOrder>>>;
  /** 供应商付款申请单状态 */
  applyStatus?: Maybe<Scalars['String']>;
  /** 创建时间 */
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type BizPaySupplierApplyInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  /** 申请单号 */
  payApplyNo?: Maybe<Scalars['String']>;
  /** 备注 */
  payApplyRemark?: Maybe<Scalars['String']>;
  /** 发票号码 */
  invoiceNo?: Maybe<Scalars['String']>;
  /** 发票金额 */
  invoiceAmount?: Maybe<Scalars['Float']>;
  /** 发票税率 */
  taxRate?: Maybe<Scalars['Float']>;
  /** 发票税额 */
  taxAmount?: Maybe<Scalars['Float']>;
  /** 付款金额 */
  payAmount?: Maybe<Scalars['Float']>;
  /** 付款成本明细 */
  costDetails?: Maybe<Array<Maybe<ServicePurchaseDetailInfoInput>>>;
  /** 申请日期 */
  applyDate?: Maybe<Scalars['String']>;
  /** 申请人 */
  applyUser?: Maybe<Scalars['String']>;
  /** 申请人部门 */
  applyDept?: Maybe<Scalars['String']>;
  /** 供应商 */
  supplier?: Maybe<Scalars['String']>;
  /** 供应商名称 */
  supplierName?: Maybe<Scalars['String']>;
  /** 付款合同 */
  payContractNo?: Maybe<Scalars['String']>;
  /** 付款合同名称 */
  payContractName?: Maybe<Scalars['String']>;
  /** 付款合同限额 */
  payContractLimit?: Maybe<Scalars['Float']>;
  /** 付款合同已付金额 */
  payContractPaid?: Maybe<Scalars['Float']>;
  /** 供应商付款申请单状态 */
  applyStatus?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 一体化 卡口验放凭证 */
export type BizSalesContractDetail = IDataInfo & {
  __typename?: 'BizSalesContractDetail';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 客户代码 */
  customerCode?: Maybe<Scalars['String']>;
  /** 客户名称 */
  customerName?: Maybe<Scalars['String']>;
  /** 合同号 */
  contractNo?: Maybe<Scalars['String']>;
  /** 合同名称 */
  contractName?: Maybe<Scalars['String']>;
  /** 合同类型 */
  contractType?: Maybe<Scalars['String']>;
  /** 合同状态 */
  contractStatus?: Maybe<Scalars['String']>;
  /** 合同金额 */
  contractAmount?: Maybe<Scalars['Float']>;
  /** 合同开始日期 */
  contractStartDate?: Maybe<Scalars['String']>;
  /** 合同结束日期 */
  contractEndDate?: Maybe<Scalars['String']>;
  /** 合同签订日期 */
  contractSignDate?: Maybe<Scalars['String']>;
  /** 服务项目清单 */
  serviceItemList?: Maybe<Array<Maybe<BizSalesContractServiceItem>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type BizSalesContractDetailInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 一体化 卡口验放凭证 */
export type BizSalesContractService = IDataInfo & {
  __typename?: 'BizSalesContractService';
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 开票项目 */
  invoiceItem?: Maybe<Scalars['String']>;
  /** 开票税率 */
  taxRate?: Maybe<Scalars['Float']>;
  /** 服务项目 */
  serviceItem?: Maybe<Scalars['String']>;
  /** 服务项目名称 */
  serviceItemName?: Maybe<Scalars['String']>;
  /** 服务项目类型 */
  serviceItemType?: Maybe<Scalars['String']>;
  /** 计价类型 */
  priceType?: Maybe<Scalars['String']>;
  /** 计价单位 */
  serviceItemUnit?: Maybe<Scalars['String']>;
  /** 服务项目状态 */
  serviceItemStatus?: Maybe<Scalars['String']>;
  /** 服务项目单价 */
  serviceItemPrice?: Maybe<Scalars['Float']>;
  /** 服务项目编号 */
  serviceItemNo?: Maybe<Scalars['String']>;
  /** 备注 */
  remark?: Maybe<Scalars['String']>;
  contractNo?: Maybe<Scalars['String']>;
  contractName?: Maybe<Scalars['String']>;
  transTypeCode?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerCode?: Maybe<Scalars['String']>;
  vehicleTypeNo?: Maybe<Scalars['String']>;
  /** 代垫代付标志 */
  dfflag?: Maybe<Scalars['Boolean']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type BizSalesContractServiceInput = {
  id?: Maybe<Scalars['ID']>;
  /** 开票项目 */
  invoiceItem?: Maybe<Scalars['String']>;
  /** 开票税率 */
  taxRate?: Maybe<Scalars['Float']>;
  /** 服务项目 */
  serviceItem?: Maybe<Scalars['String']>;
  /** 服务项目名称 */
  serviceItemName?: Maybe<Scalars['String']>;
  /** 服务项目类型 */
  serviceItemType?: Maybe<Scalars['String']>;
  /** 计价类型 */
  priceType?: Maybe<Scalars['String']>;
  /** 计价单位 */
  serviceItemUnit?: Maybe<Scalars['String']>;
  /** 服务项目状态 */
  serviceItemStatus?: Maybe<Scalars['String']>;
  /** 服务项目单价 */
  serviceItemPrice?: Maybe<Scalars['Float']>;
  /** 服务项目编号 */
  serviceItemNo?: Maybe<Scalars['String']>;
  /** 备注 */
  remark?: Maybe<Scalars['String']>;
  contractNo?: Maybe<Scalars['String']>;
  contractName?: Maybe<Scalars['String']>;
  transTypeCode?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerCode?: Maybe<Scalars['String']>;
  vehicleTypeNo?: Maybe<Scalars['String']>;
  dfflag?: Maybe<Scalars['Boolean']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type BizSalesContractServiceItem = {
  __typename?: 'BizSalesContractServiceItem';
  /** 开票项目 */
  invoiceItem?: Maybe<Scalars['String']>;
  /** 开票税率 */
  taxRate?: Maybe<Scalars['Float']>;
  /** 服务项目 */
  serviceItem?: Maybe<Scalars['String']>;
  /** 服务项目名称 */
  serviceItemName?: Maybe<Scalars['String']>;
  /** 服务项目类型 */
  serviceItemType?: Maybe<Scalars['String']>;
  /** 计价类型 */
  priceType?: Maybe<Scalars['String']>;
  /** 计价单位 */
  serviceItemUnit?: Maybe<Scalars['String']>;
  /** 服务项目状态 */
  serviceItemStatus?: Maybe<Scalars['String']>;
  /** 服务项目单价 */
  serviceItemPrice?: Maybe<Scalars['Float']>;
  /** 服务项目编号 */
  serviceItemNo?: Maybe<Scalars['String']>;
  /** 备注 */
  remark?: Maybe<Scalars['String']>;
};

/** 一体化 卡口验放凭证 */
export type BizSupplierContractDetail = IDataInfo & {
  __typename?: 'BizSupplierContractDetail';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type BizSupplierContractDetailInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export enum CsSearchType {
  Inprogress = 'INPROGRESS',
  Init = 'INIT',
  Csfinished = 'CSFINISHED',
  Aiprocessing = 'AIPROCESSING'
}

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

/** 扣分记录 */
export type Chacuo = IDataInfo & {
  __typename?: 'Chacuo';
  id?: Maybe<Scalars['ID']>;
  scoreProcEr?: Maybe<Scalars['String']>;
  formType?: Maybe<Scalars['String']>;
  statusName?: Maybe<Scalars['String']>;
  errorScore?: Maybe<Scalars['Int']>;
  scoreDcSeqNo?: Maybe<Scalars['String']>;
  returnDate?: Maybe<Scalars['String']>;
  scoreStep?: Maybe<Scalars['String']>;
  scoreDesc?: Maybe<Scalars['String']>;
  returnProcEr?: Maybe<Scalars['String']>;
  tradeCode?: Maybe<Scalars['String']>;
  scoreTime?: Maybe<Scalars['String']>;
  socreCustomsCodeName?: Maybe<Scalars['String']>;
  clearFlag?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
  applyFlag?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Float']>;
  scoreReason?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  socreCustomsCode?: Maybe<Scalars['String']>;
  saicSysNo?: Maybe<Scalars['String']>;
  errorType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  returnCustomsCodeName?: Maybe<Scalars['String']>;
  agentCode?: Maybe<Scalars['String']>;
  errorTypeHead?: Maybe<Scalars['String']>;
  scoreSeqNo?: Maybe<Scalars['String']>;
  returnCustomsCode?: Maybe<Scalars['String']>;
  returnFlag?: Maybe<Scalars['String']>;
  returnReason?: Maybe<Scalars['String']>;
  declCard?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  /** 责任类型 */
  dutyType?: Maybe<Scalars['String']>;
  /** 责任人 */
  dutyPerson?: Maybe<Scalars['String']>;
  /** 责任审核状态 */
  dutyStatusName?: Maybe<Scalars['String']>;
  /** 备注 */
  dutyMemo?: Maybe<Scalars['String']>;
  /** 部门 */
  departName?: Maybe<Scalars['String']>;
  dutyClassify?: Maybe<Scalars['String']>;
  chacuoDetails?: Maybe<Array<Maybe<ChacuoDetailInfo>>>;
};

export type ChacuoDetailInfo = {
  __typename?: 'ChacuoDetailInfo';
  id?: Maybe<Scalars['ID']>;
  scoreProcEr?: Maybe<Scalars['String']>;
  formType?: Maybe<Scalars['String']>;
  statusName?: Maybe<Scalars['String']>;
  errorScore?: Maybe<Scalars['Int']>;
  scoreDcSeqNo?: Maybe<Scalars['String']>;
  returnDate?: Maybe<Scalars['String']>;
  scoreStep?: Maybe<Scalars['String']>;
  scoreDesc?: Maybe<Scalars['String']>;
  returnProcEr?: Maybe<Scalars['String']>;
  tradeCode?: Maybe<Scalars['String']>;
  scoreTime?: Maybe<Scalars['String']>;
  socreCustomsCodeName?: Maybe<Scalars['String']>;
  clearFlag?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
  applyFlag?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Float']>;
  scoreReason?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  socreCustomsCode?: Maybe<Scalars['String']>;
  saicSysNo?: Maybe<Scalars['String']>;
  errorType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  returnCustomsCodeName?: Maybe<Scalars['String']>;
  agentCode?: Maybe<Scalars['String']>;
  errorTypeHead?: Maybe<Scalars['String']>;
  scoreSeqNo?: Maybe<Scalars['String']>;
  returnCustomsCode?: Maybe<Scalars['String']>;
  returnFlag?: Maybe<Scalars['String']>;
  returnReason?: Maybe<Scalars['String']>;
  declCard?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type ChacuoInput = {
  id?: Maybe<Scalars['ID']>;
  scoreProcEr?: Maybe<Scalars['String']>;
  formType?: Maybe<Scalars['String']>;
  statusName?: Maybe<Scalars['String']>;
  errorScore?: Maybe<Scalars['Int']>;
  scoreDcSeqNo?: Maybe<Scalars['String']>;
  returnDate?: Maybe<Scalars['String']>;
  scoreStep?: Maybe<Scalars['String']>;
  scoreDesc?: Maybe<Scalars['String']>;
  returnProcEr?: Maybe<Scalars['String']>;
  tradeCode?: Maybe<Scalars['String']>;
  scoreTime?: Maybe<Scalars['String']>;
  socreCustomsCodeName?: Maybe<Scalars['String']>;
  clearFlag?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
  applyFlag?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Float']>;
  scoreReason?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  socreCustomsCode?: Maybe<Scalars['String']>;
  saicSysNo?: Maybe<Scalars['String']>;
  errorType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  returnCustomsCodeName?: Maybe<Scalars['String']>;
  agentCode?: Maybe<Scalars['String']>;
  errorTypeHead?: Maybe<Scalars['String']>;
  scoreSeqNo?: Maybe<Scalars['String']>;
  returnCustomsCode?: Maybe<Scalars['String']>;
  returnFlag?: Maybe<Scalars['String']>;
  returnReason?: Maybe<Scalars['String']>;
  declCard?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  /** 责任类型 */
  dutyType?: Maybe<Scalars['String']>;
  /** 责任人 */
  dutyPerson?: Maybe<Scalars['String']>;
  /** 责任审核状态 */
  dutyStatusName?: Maybe<Scalars['String']>;
  /** 备注 */
  dutyMemo?: Maybe<Scalars['String']>;
  /** 部门 */
  departName?: Maybe<Scalars['String']>;
  dutyClassify?: Maybe<Scalars['String']>;
};

export type ChauoStats = {
  __typename?: 'ChauoStats';
  year?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['String']>;
  totalCount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  duty0?: Maybe<Scalars['Int']>;
  duty1?: Maybe<Scalars['Int']>;
  duty2?: Maybe<Scalars['Int']>;
  duty3?: Maybe<Scalars['Int']>;
  duty3up?: Maybe<Scalars['Int']>;
};

export type CiqInfo = {
  __typename?: 'CiqInfo';
  classifyCnnm?: Maybe<Scalars['String']>;
  statcode?: Maybe<Scalars['String']>;
  hscode?: Maybe<Scalars['String']>;
  hsname?: Maybe<Scalars['String']>;
  ciqname?: Maybe<Scalars['String']>;
};

export type ClassifyInfo = {
  __typename?: 'ClassifyInfo';
  codeTs?: Maybe<Scalars['String']>;
  controlMark?: Maybe<Scalars['String']>;
  unitFlag?: Maybe<Scalars['String']>;
  ciqWtMeasUnit?: Maybe<Scalars['String']>;
  ciqWtMeasUnitName?: Maybe<Scalars['String']>;
  gname?: Maybe<Scalars['String']>;
  unit1?: Maybe<Scalars['String']>;
  dangerFlag?: Maybe<Scalars['String']>;
  unit1Name?: Maybe<Scalars['String']>;
  unit2?: Maybe<Scalars['String']>;
  outDutyTypeFlag?: Maybe<Scalars['String']>;
  inspMonitorCond?: Maybe<Scalars['String']>;
  ciqQtyMeasUnitName?: Maybe<Scalars['String']>;
  ciqQtyMeasUnit?: Maybe<Scalars['String']>;
  noteS?: Maybe<Scalars['String']>;
  unit2Name?: Maybe<Scalars['String']>;
  declareElements?: Maybe<Array<Maybe<DeclareElement>>>;
  ciqinfo?: Maybe<Array<Maybe<CiqInfo>>>;
  low_rate?: Maybe<Scalars['String']>;
  high_rate?: Maybe<Scalars['String']>;
  out_rate?: Maybe<Scalars['String']>;
  reg_rate?: Maybe<Scalars['String']>;
  tax_rate?: Maybe<Scalars['String']>;
  back_rate?: Maybe<Scalars['String']>;
  low_t_rate?: Maybe<Scalars['String']>;
  low_out_rate?: Maybe<Scalars['String']>;
  ret_rate?: Maybe<Scalars['String']>;
};

export type ClientPorcessJobData = {
  __typename?: 'ClientPorcessJobData';
  jobInfo?: Maybe<RpaJobInfo>;
  processDetails?: Maybe<Array<Maybe<RpaJobProcessDetail>>>;
};

export type Contacter = {
  __typename?: 'Contacter';
  contactPerson?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
};

export type ContacterInput = {
  contactPerson?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
};

/** 客户付款清单 用来跟客户对账 */
export type CustBillInfo = IDataInfo & {
  __typename?: 'CustBillInfo';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 对账单号 唯一 */
  billNo?: Maybe<Scalars['String']>;
  /** 对账月份 */
  billMonth?: Maybe<Scalars['String']>;
  /** 对账客户 */
  custID?: Maybe<Scalars['String']>;
  /** 对账客户名称 */
  custName?: Maybe<Scalars['String']>;
  /** 依据合同号 会有多个合同号? */
  contractNo?: Maybe<Scalars['String']>;
  /** 总金额 */
  totalAmount?: Maybe<Scalars['Float']>;
  /** 币制 */
  currency?: Maybe<Scalars['String']>;
  /** 成本总金额 */
  costTotalAmount?: Maybe<Scalars['Float']>;
  /** 开票金额 */
  invoiceAmount?: Maybe<Scalars['Float']>;
  /** 收入明细 从收入里来 */
  incomeDetail?: Maybe<Array<Maybe<ServiceBillDetailInfo>>>;
  /** 成本明细 */
  costDetail?: Maybe<Array<Maybe<ServicePurchaseDetailInfo>>>;
  /** 对账业务清单 */
  billOrderInfos?: Maybe<Array<Maybe<BillOrderData>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 收入明细  一个客户对账单有多个收入明细 和serviceBillDetailInfo关联 关系是怎样的? */
export type CustBillInfoIncomeDetail = {
  __typename?: 'CustBillInfoIncomeDetail';
  /** id */
  id?: Maybe<Scalars['ID']>;
  /** 对账单号 */
  billNo?: Maybe<Scalars['String']>;
  /** 业务单号 */
  orderNo?: Maybe<Scalars['String']>;
  /** 收费项目 */
  chargeItem?: Maybe<Scalars['String']>;
  /** 收费金额 */
  chargeAmount?: Maybe<Scalars['Float']>;
  /** 收费币制 */
  chargeCurrency?: Maybe<Scalars['String']>;
  /** 关联收费ID */
  serviceBillDetailId?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type CustBillInfoInput = {
  appID?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  billNo?: Maybe<Scalars['String']>;
  billMonth?: Maybe<Scalars['String']>;
  custID?: Maybe<Scalars['String']>;
  custName?: Maybe<Scalars['String']>;
  contractNo?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  costTotalAmount?: Maybe<Scalars['Float']>;
  invoiceAmount?: Maybe<Scalars['Float']>;
  incomeDetail?: Maybe<Array<Maybe<ServiceBillDetailInfoInput>>>;
  costDetail?: Maybe<Array<Maybe<ServicePurchaseDetailInfoInput>>>;
  billOrderInfos?: Maybe<Array<Maybe<BillOrderDataInput>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 委托清单明细 */
export type CustomApplyBussinessInfo = IDataInfo & {
  __typename?: 'CustomApplyBussinessInfo';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** tms委托编号 */
  parentTmsApplyNo?: Maybe<Scalars['String']>;
  /** 业务清单编号 导入业务清单号码 */
  businessNo?: Maybe<Scalars['String']>;
  /** 物料凭证号 */
  materialFONo?: Maybe<Scalars['String']>;
  /** 容器尺寸 */
  containerSize?: Maybe<Scalars['String']>;
  /** 毛重 */
  grossWeight?: Maybe<Scalars['Float']>;
  goodsCount?: Maybe<Scalars['Float']>;
  /** 件数 */
  packNo?: Maybe<Scalars['Int']>;
  /** 备注 */
  remark?: Maybe<Scalars['String']>;
  boxNo?: Maybe<Scalars['String']>;
  kgRelateNo?: Maybe<Scalars['String']>;
  materialArea?: Maybe<Scalars['String']>;
  option1?: Maybe<Scalars['String']>;
  iePack?: Maybe<Scalars['String']>;
  companyPlaceType?: Maybe<Scalars['String']>;
  declPrice?: Maybe<Scalars['Float']>;
  /** 关联 发运申请信息 */
  tmsCustTransApply?: Maybe<TmsCustTransApply>;
  /**
   * 状态
   * 对应的状态Dict tmsBusinessNoDic
   */
  status?: Maybe<Scalars['String']>;
  errmsg?: Maybe<Scalars['String']>;
  totalNet?: Maybe<Scalars['String']>;
  codeInfo?: Maybe<Scalars['String']>;
  setIndex?: Maybe<Scalars['Int']>;
  /** 状态Code */
  statusCode?: Maybe<Scalars['Int']>;
  /** 第一个过渡任务编号 */
  guanMaoTaskNo1?: Maybe<Scalars['String']>;
  /** 任务编号 */
  guanMaoTaskNo?: Maybe<Scalars['String']>;
  /** 对应做完的关贸云业务清单编号 */
  guanMaoBusinessTransitNos?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 卡口验放凭证编号 */
  spAreaPortReleaseNos?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 装车单凭证号码 */
  spAreaPackageListNos?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 提发货凭证编号 */
  spAreaWarehouseNos?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 合并发运 */
  batchNo?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type CustomApplyBussinessInfoInput = {
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** tms委托编号 */
  parentTmsApplyNo?: Maybe<Scalars['String']>;
  /** 业务清单编号 导入业务清单号码 */
  businessNo?: Maybe<Scalars['String']>;
  /** 物料凭证号 */
  materialFONo?: Maybe<Scalars['String']>;
  /** 容器尺寸 */
  containerSize?: Maybe<Scalars['String']>;
  /** 毛重 */
  grossWeight?: Maybe<Scalars['Float']>;
  /** 商品数量 */
  goodsCount?: Maybe<Scalars['Float']>;
  /** 件数 */
  packNo?: Maybe<Scalars['Int']>;
  /** 备注 */
  remark?: Maybe<Scalars['String']>;
  /**
   * 状态
   * 对应的状态Dict tmsBusinessNoDic
   */
  status?: Maybe<Scalars['String']>;
  errmsg?: Maybe<Scalars['String']>;
  totalNet?: Maybe<Scalars['String']>;
  codeInfo?: Maybe<Scalars['String']>;
  setIndex?: Maybe<Scalars['Int']>;
  /** 状态Code */
  statusCode?: Maybe<Scalars['Int']>;
  /** 合并发运 */
  batchNo?: Maybe<Scalars['String']>;
  boxNo?: Maybe<Scalars['String']>;
  kgRelateNo?: Maybe<Scalars['String']>;
  materialArea?: Maybe<Scalars['String']>;
  option1?: Maybe<Scalars['String']>;
  iePack?: Maybe<Scalars['String']>;
  companyPlaceType?: Maybe<Scalars['String']>;
  declPrice?: Maybe<Scalars['Float']>;
  tmsApplyNo?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** SAMC 报关单基础信息 */
export type CustomDeclareInfo = {
  __typename?: 'CustomDeclareInfo';
  id?: Maybe<Scalars['String']>;
  entryId?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
  trafName?: Maybe<Scalars['String']>;
  trafMode?: Maybe<Scalars['String']>;
  voyageNo?: Maybe<Scalars['String']>;
  iEPort?: Maybe<Scalars['String']>;
  declDate?: Maybe<Scalars['String']>;
  iEPortName?: Maybe<Scalars['String']>;
  packNo?: Maybe<Scalars['String']>;
  grossWeight?: Maybe<Scalars['String']>;
  portGoodsArea?: Maybe<Scalars['String']>;
  portGoodsPlace?: Maybe<Scalars['String']>;
};

export type CustomDeclareInfoInput = {
  id?: Maybe<Scalars['String']>;
  entryId?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
  trafName?: Maybe<Scalars['String']>;
  trafMode?: Maybe<Scalars['String']>;
  voyageNo?: Maybe<Scalars['String']>;
  iEPort?: Maybe<Scalars['String']>;
  declDate?: Maybe<Scalars['String']>;
  iEPortName?: Maybe<Scalars['String']>;
  packNo?: Maybe<Scalars['String']>;
  grossWeight?: Maybe<Scalars['String']>;
  portGoodsArea?: Maybe<Scalars['String']>;
  portGoodsPlace?: Maybe<Scalars['String']>;
};

/** 对象修改备注，日志 */
export type DataChangeLogInfo = IDataInfo & {
  __typename?: 'DataChangeLogInfo';
  id?: Maybe<Scalars['ID']>;
  kddid?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  /** 更新 */
  changes?: Maybe<Array<Maybe<ObjectChangeDesc>>>;
  changeMemo?: Maybe<Scalars['String']>;
  shenheType?: Maybe<Scalars['String']>;
  shenhePerson?: Maybe<Scalars['String']>;
  showSepNo?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  statusName?: Maybe<Scalars['String']>;
  process_instance_id?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type DataChangeLogInfoInput = {
  id?: Maybe<Scalars['ID']>;
  kddid?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  /** 更新 */
  changes?: Maybe<Array<Maybe<ObjectChangeDescInput>>>;
  changeMemo?: Maybe<Scalars['String']>;
  shenheType?: Maybe<Scalars['String']>;
  shenhePerson?: Maybe<Scalars['String']>;
  showSepNo?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  statusName?: Maybe<Scalars['String']>;
  process_instance_id?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};


export type DecCheckHeadStats = {
  __typename?: 'DecCheckHeadStats';
  year?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['String']>;
  totalCount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  dutyKouan?: Maybe<Scalars['Int']>;
  dutyBaogd?: Maybe<Scalars['Int']>;
  dutyMudd?: Maybe<Scalars['Int']>;
  dutyZhuijia?: Maybe<Scalars['Int']>;
  dutyZuofei?: Maybe<Scalars['Int']>;
  dutyOther?: Maybe<Scalars['Int']>;
  dutyNotConfirm?: Maybe<Scalars['Int']>;
};

/** 改单申请列表 */
export type DecModHeadInfo = IDataInfo & {
  __typename?: 'DecModHeadInfo';
  id?: Maybe<Scalars['ID']>;
  decModSeqNo?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  entryId?: Maybe<Scalars['String']>;
  customMaster?: Maybe<Scalars['String']>;
  cusIEFlag?: Maybe<Scalars['String']>;
  agentCode?: Maybe<Scalars['String']>;
  agentName?: Maybe<Scalars['String']>;
  agentScc?: Maybe<Scalars['String']>;
  rcvgdTradeCode?: Maybe<Scalars['String']>;
  consigneeCname?: Maybe<Scalars['String']>;
  rcvgdTradeScc?: Maybe<Scalars['String']>;
  ownerCode?: Maybe<Scalars['String']>;
  ownerName?: Maybe<Scalars['String']>;
  ownerScc?: Maybe<Scalars['String']>;
  decModBizType?: Maybe<Scalars['String']>;
  decModType?: Maybe<Scalars['String']>;
  decModNote?: Maybe<Scalars['String']>;
  inputEtpsCode?: Maybe<Scalars['String']>;
  inputEtpsName?: Maybe<Scalars['String']>;
  inputErName?: Maybe<Scalars['String']>;
  inputTime?: Maybe<Scalars['String']>;
  applIcCode?: Maybe<Scalars['String']>;
  applErName?: Maybe<Scalars['String']>;
  applTime?: Maybe<Scalars['String']>;
  decModStatus?: Maybe<Scalars['String']>;
  entOpName?: Maybe<Scalars['String']>;
  indbTime?: Maybe<Scalars['String']>;
  dDate?: Maybe<Scalars['String']>;
  decModStatusName?: Maybe<Scalars['String']>;
  decModTypeName?: Maybe<Scalars['String']>;
  customMasterName?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  decModList?: Maybe<Array<Maybe<DecModListInfo>>>;
  /** 责任类型 */
  dutyType?: Maybe<Scalars['String']>;
  /** 责任人 */
  dutyPerson?: Maybe<Scalars['String']>;
  /** 责任审核状态 */
  dutyStatusName?: Maybe<Scalars['String']>;
  dutyClassify?: Maybe<Scalars['String']>;
  /** 钉钉审批编号 */
  process_instance_id?: Maybe<Scalars['String']>;
};

export type DecModHeadInfoInput = {
  id?: Maybe<Scalars['ID']>;
  decModSeqNo?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  entryId?: Maybe<Scalars['String']>;
  customMaster?: Maybe<Scalars['String']>;
  cusIEFlag?: Maybe<Scalars['String']>;
  agentCode?: Maybe<Scalars['String']>;
  agentName?: Maybe<Scalars['String']>;
  agentScc?: Maybe<Scalars['String']>;
  rcvgdTradeCode?: Maybe<Scalars['String']>;
  consigneeCname?: Maybe<Scalars['String']>;
  rcvgdTradeScc?: Maybe<Scalars['String']>;
  ownerCode?: Maybe<Scalars['String']>;
  ownerName?: Maybe<Scalars['String']>;
  ownerScc?: Maybe<Scalars['String']>;
  decModBizType?: Maybe<Scalars['String']>;
  decModType?: Maybe<Scalars['String']>;
  decModNote?: Maybe<Scalars['String']>;
  inputEtpsCode?: Maybe<Scalars['String']>;
  inputEtpsName?: Maybe<Scalars['String']>;
  inputErName?: Maybe<Scalars['String']>;
  inputTime?: Maybe<Scalars['String']>;
  applIcCode?: Maybe<Scalars['String']>;
  applErName?: Maybe<Scalars['String']>;
  applTime?: Maybe<Scalars['String']>;
  decModStatus?: Maybe<Scalars['String']>;
  entOpName?: Maybe<Scalars['String']>;
  indbTime?: Maybe<Scalars['String']>;
  dDate?: Maybe<Scalars['String']>;
  decModStatusName?: Maybe<Scalars['String']>;
  decModTypeName?: Maybe<Scalars['String']>;
  customMasterName?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  decModList?: Maybe<Array<Maybe<DecModListInfoInput>>>;
  /** 责任类型 */
  dutyType?: Maybe<Scalars['String']>;
  /** 责任人 */
  dutyPerson?: Maybe<Scalars['String']>;
  /** 责任审核状态 */
  dutyStatusName?: Maybe<Scalars['String']>;
  dutyClassify?: Maybe<Scalars['String']>;
  /** 钉钉审批编号 */
  process_instance_id?: Maybe<Scalars['String']>;
};

export type DecModHeadStats = {
  __typename?: 'DecModHeadStats';
  year?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  modify?: Maybe<Scalars['Int']>;
  delete?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
  dutyCompany?: Maybe<Scalars['Int']>;
  dutyCustomer?: Maybe<Scalars['Int']>;
  dutyOther?: Maybe<Scalars['Int']>;
  dutyNotConfirm?: Maybe<Scalars['Int']>;
};

/** 改单内容明细 */
export type DecModListInfo = {
  __typename?: 'DecModListInfo';
  decFieldName?: Maybe<Scalars['String']>;
  fieldOrigValue?: Maybe<Scalars['String']>;
  fieldModValue?: Maybe<Scalars['String']>;
  fieldOrigName?: Maybe<Scalars['String']>;
  fieldModName?: Maybe<Scalars['String']>;
  decListNoName?: Maybe<Scalars['String']>;
  decListTypeName?: Maybe<Scalars['String']>;
};

export type DecModListInfoInput = {
  decFieldName?: Maybe<Scalars['String']>;
  fieldOrigValue?: Maybe<Scalars['String']>;
  fieldModValue?: Maybe<Scalars['String']>;
  fieldOrigName?: Maybe<Scalars['String']>;
  fieldModName?: Maybe<Scalars['String']>;
  decListNoName?: Maybe<Scalars['String']>;
  decListTypeName?: Maybe<Scalars['String']>;
};

export type DecRejectHeadStats = {
  __typename?: 'DecRejectHeadStats';
  year?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['String']>;
  totalCount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  dutyCangdan?: Maybe<Scalars['Int']>;
  dutyJiagong?: Maybe<Scalars['Int']>;
  dutyShuju?: Maybe<Scalars['Int']>;
  dutyShuliang?: Maybe<Scalars['Int']>;
  dutyZshui?: Maybe<Scalars['Int']>;
  dutyShenbao?: Maybe<Scalars['Int']>;
  dutyLuoji?: Maybe<Scalars['Int']>;
  dutyGeshi?: Maybe<Scalars['Int']>;
  dutyOther?: Maybe<Scalars['Int']>;
  dutyNotConfirm?: Maybe<Scalars['Int']>;
};

export type DecStatus = {
  __typename?: 'DecStatus';
  id?: Maybe<Scalars['ID']>;
  orderNo?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  changeid?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  statusName?: Maybe<Scalars['String']>;
  entryId?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  processInstanceId?: Maybe<Scalars['String']>;
};

export type DecStatusInput = {
  id?: Maybe<Scalars['ID']>;
  orderNo?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  changeid?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  statusName?: Maybe<Scalars['String']>;
  entryId?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 申报跟踪表 报关单放行状态 跟踪 */
export type DecTrackInfo = IDataInfo & {
  __typename?: 'DecTrackInfo';
  id?: Maybe<Scalars['ID']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
  ciqDecStatusName?: Maybe<Scalars['String']>;
  consigneeCname?: Maybe<Scalars['String']>;
  copNo?: Maybe<Scalars['String']>;
  cusDecStatusName?: Maybe<Scalars['String']>;
  cusIEFlagName?: Maybe<Scalars['String']>;
  cusVoyageNo?: Maybe<Scalars['String']>;
  customMasterName?: Maybe<Scalars['String']>;
  dDate?: Maybe<Scalars['String']>;
  declDate?: Maybe<Scalars['String']>;
  entryId?: Maybe<Scalars['String']>;
  iEDate?: Maybe<Scalars['String']>;
  iEPortName?: Maybe<Scalars['String']>;
  indbTime?: Maybe<Scalars['String']>;
  ownerName?: Maybe<Scalars['String']>;
  supvModeCddeName?: Maybe<Scalars['String']>;
  updateTime?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  trafName?: Maybe<Scalars['String']>;
  /** 启运港 */
  despPortCodeName?: Maybe<Scalars['String']>;
  /** 商检标志 */
  shangjianMark?: Maybe<Scalars['String']>;
  /** 放行时间 P状态时间 */
  trackChannelPassDate?: Maybe<Scalars['Date']>;
  /** R海关已结关  结关状态时间 */
  trackChannelRDate?: Maybe<Scalars['Date']>;
  /** C海关无纸验放查验通知书（放行） 查验时间 */
  trackChannelCheckDate?: Maybe<Scalars['Date']>;
  trackChannelCheckName?: Maybe<Scalars['String']>;
  trackChannelCheckNote?: Maybe<Scalars['String']>;
  /** 商检标志 */
  trackShangjianFlag?: Maybe<Scalars['String']>;
  /** 商检编号+机关 */
  trackCiqInfo?: Maybe<Scalars['String']>;
  trackShangjianChannelDate?: Maybe<Scalars['String']>;
  trackShangjianChannelNote?: Maybe<Scalars['String']>;
  /** 商检查验  检疫 关区 。目的地检疫 */
  trackChannelCheckShangjian?: Maybe<Scalars['String']>;
  /** J审结 B担保验放，K通关无纸化担保放行 可以提货，但还没到P */
  trackChannelKPassDate?: Maybe<Scalars['Date']>;
  /** 当前状态 */
  trackChannelLast?: Maybe<Scalars['String']>;
  /** 当前状态时间 */
  trackChannelLastDate?: Maybe<Scalars['Date']>;
  trackChannelLastNote?: Maybe<Scalars['String']>;
  /** L海关入库成功 7 和L一样么？ */
  trackChannelLDate?: Maybe<Scalars['Date']>;
  /** 跟踪完成标志 */
  trackFinish?: Maybe<Scalars['Boolean']>;
  /** 改单标志 */
  trackDecModifyChannel?: Maybe<Scalars['String']>;
  /** 改单日志 */
  trackDecModifyNote?: Maybe<Scalars['String']>;
  /** 改单日期 */
  trackDecModifyDate?: Maybe<Scalars['String']>;
  /** 删单标志 */
  trackDecDeleteChannel?: Maybe<Scalars['String']>;
  /** 删单日志 */
  trackDecDeleteNote?: Maybe<Scalars['String']>;
  /** 删单完成日期 */
  trackDecDeleteDate?: Maybe<Scalars['String']>;
  /** 退单记录 */
  trackExceptionChannel?: Maybe<Scalars['String']>;
  trackExceptionNote?: Maybe<Scalars['String']>;
  trackExceptionDate?: Maybe<Scalars['Date']>;
  /** 到港时间 */
  arriveDate?: Maybe<Scalars['Date']>;
  /** 通关时效小时数 */
  processPassHours?: Maybe<Scalars['Int']>;
  /**
   * 海关Processhours:
   * 入库时间到方向时间
   */
  customProcessPassHours?: Maybe<Scalars['Int']>;
  /** 进出区提发货编号 */
  whRecPreentNo?: Maybe<Scalars['String']>;
  /** 收到confirm邮件指令时间 */
  mailConfirmTime?: Maybe<Scalars['Date']>;
  /** 接单时间 单证中心 */
  zhidanProcessStartTime?: Maybe<Scalars['Date']>;
  /** 单证中心制单完成发邮件时间 */
  zhidanProcessEndTime?: Maybe<Scalars['Date']>;
  /** 报关部门开始审核事情 */
  baoguanProcessStartTime?: Maybe<Scalars['Date']>;
  /** 人民币总额 */
  totalRMBAmount?: Maybe<Scalars['Float']>;
  /** 发票总价 */
  totalAmount?: Maybe<Scalars['Float']>;
  /** 发票币值 */
  tradeCurr?: Maybe<Scalars['String']>;
};

export type DecTrackStats = {
  __typename?: 'DecTrackStats';
  iEPortName?: Maybe<Scalars['String']>;
  ieFlag?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['String']>;
  totalCount?: Maybe<Scalars['Int']>;
  avgProcessHours?: Maybe<Scalars['Int']>;
  in12Hours?: Maybe<Scalars['Int']>;
  in24Hours?: Maybe<Scalars['Int']>;
  in48Hours?: Maybe<Scalars['Int']>;
  over48Hours?: Maybe<Scalars['Int']>;
  over48HoursCheck?: Maybe<Scalars['Int']>;
  over48Other?: Maybe<Scalars['Int']>;
};

export type DeclareEle = {
  __typename?: 'DeclareEle';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type DeclareEleInput = {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type DeclareElement = {
  __typename?: 'DeclareElement';
  requireCheck?: Maybe<Scalars['String']>;
  decFacCode?: Maybe<Scalars['String']>;
  codeTs?: Maybe<Scalars['String']>;
  decFacName?: Maybe<Scalars['String']>;
  gname?: Maybe<Scalars['String']>;
  decFacType?: Maybe<Scalars['String']>;
  ieFlag?: Maybe<Scalars['String']>;
  textName?: Maybe<Scalars['String']>;
  snum?: Maybe<Scalars['String']>;
  extendFiled?: Maybe<Scalars['String']>;
  decFacContent?: Maybe<Scalars['String']>;
};

/** 钉钉审批状态 */
export type DingEventInput = {
  processInstanceId?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  finishTime?: Maybe<Scalars['Date']>;
  corpId?: Maybe<Scalars['String']>;
  EventType?: Maybe<Scalars['String']>;
  businessId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  processCode?: Maybe<Scalars['String']>;
  bizCategoryId?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['String']>;
  businessType?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['Float']>;
};

export type DingProcessApplyPara = {
  __typename?: 'DingProcessApplyPara';
  originator_user_id?: Maybe<Scalars['String']>;
  dept_id?: Maybe<Scalars['Int']>;
  process_code?: Maybe<Scalars['String']>;
  approvers_v2?: Maybe<Array<Maybe<Approvers>>>;
};

export type DingProcessFormInput = {
  component_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  ext_value?: Maybe<Scalars['String']>;
};

export type DingProcessInstanceInput = {
  approver_userids?: Maybe<Array<Maybe<Scalars['String']>>>;
  attached_process_instance_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  biz_action?: Maybe<Scalars['String']>;
  business_id?: Maybe<Scalars['String']>;
  create_time?: Maybe<Scalars['String']>;
  finish_time?: Maybe<Scalars['String']>;
  form_component_values?: Maybe<Array<Maybe<DingProcessFormInput>>>;
  operation_records?: Maybe<Array<Maybe<DingProcessOperationRecordInput>>>;
  cc_userids?: Maybe<Array<Maybe<Scalars['String']>>>;
  originator_dept_id?: Maybe<Scalars['String']>;
  originator_dept_name?: Maybe<Scalars['String']>;
  originator_userid?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tasks?: Maybe<Array<Maybe<DingProcessTaskInput>>>;
  title?: Maybe<Scalars['String']>;
};

export type DingProcessOperationRecordInput = {
  date?: Maybe<Scalars['String']>;
  operation_result?: Maybe<Scalars['String']>;
  operation_type?: Maybe<Scalars['String']>;
  userid?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
};

export type DingProcessTaskInput = {
  activity_id?: Maybe<Scalars['String']>;
  create_time?: Maybe<Scalars['String']>;
  task_result?: Maybe<Scalars['String']>;
  task_status?: Maybe<Scalars['String']>;
  taskid?: Maybe<Scalars['String']>;
  finish_time?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  userid?: Maybe<Scalars['String']>;
};

export type DingUser = {
  __typename?: 'DingUser';
  name?: Maybe<Scalars['String']>;
  userid?: Maybe<Scalars['String']>;
  unionid?: Maybe<Scalars['String']>;
  openId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  /** 部门 */
  department?: Maybe<Array<Maybe<Scalars['Int']>>>;
  roles?: Maybe<Array<Maybe<KeyValue>>>;
  avatar?: Maybe<Scalars['String']>;
};

export type DingUserInput = {
  userid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  openId?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

/** 单证状态列表 */
export type ExpressOrder = {
  __typename?: 'ExpressOrder';
  /** 翔运业务编号 */
  trackId?: Maybe<Scalars['String']>;
  /** 商品名称 */
  goodsDesp?: Maybe<Scalars['String']>;
  /** 英文品名 */
  goodsDspeEn?: Maybe<Scalars['String']>;
  /** 件数 */
  packNo?: Maybe<Scalars['Int']>;
  /** 数量 */
  qty?: Maybe<Scalars['Float']>;
  /** 毛重 */
  grossWeight?: Maybe<Scalars['Float']>;
  /** 金额 */
  totalAmont?: Maybe<Scalars['Float']>;
  /** 收货人 */
  consingee?: Maybe<Scalars['String']>;
  /** 收货地址 */
  consingeeAddress?: Maybe<Scalars['String']>;
  /** 发货人 */
  shipper?: Maybe<Scalars['String']>;
  /** 货主 */
  owner?: Maybe<Scalars['String']>;
  /** 总运单号 */
  mAWBNo?: Maybe<Scalars['String']>;
  /** 分运单号 */
  hAWBNo?: Maybe<Scalars['String']>;
  /** 运费 */
  transFeeAmount?: Maybe<Scalars['Float']>;
  curr?: Maybe<Scalars['String']>;
  transFeeCurr?: Maybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
  url: Scalars['String'];
  ossKey: Scalars['String'];
  docSize?: Maybe<Scalars['Int']>;
};

export type Filter = {
  ieFlag?: Maybe<Scalars['String']>;
};

export type FunJobInfo = IDataInfo & {
  __typename?: 'FunJobInfo';
  id?: Maybe<Scalars['ID']>;
  jobName?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['String']>;
  input?: Maybe<Scalars['JSON']>;
  jodTaskDef?: Maybe<Scalars['JSON']>;
  inputId?: Maybe<Scalars['String']>;
  taskDetails?: Maybe<Array<Maybe<FunTaskDetailInfo>>>;
  appId?: Maybe<Scalars['String']>;
  jobMemo?: Maybe<Scalars['String']>;
  /** job执行状态 */
  jobStatus?: Maybe<Scalars['String']>;
  progressMessage?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type FunJobInfoInput = {
  id?: Maybe<Scalars['ID']>;
  jobName?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['String']>;
  input?: Maybe<Scalars['JSON']>;
  jodTaskDef?: Maybe<Scalars['JSON']>;
  inputId?: Maybe<Scalars['String']>;
  appId?: Maybe<Scalars['String']>;
  jobMemo?: Maybe<Scalars['String']>;
  jobStatus?: Maybe<Scalars['String']>;
  progressMessage?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type FunTaskDetailInfo = IDataInfo & {
  __typename?: 'FunTaskDetailInfo';
  id?: Maybe<Scalars['ID']>;
  taskId?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['String']>;
  jobName?: Maybe<Scalars['String']>;
  stepId?: Maybe<Scalars['Int']>;
  taskSeqID?: Maybe<Scalars['Int']>;
  fun?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  input?: Maybe<Scalars['JSON']>;
  output?: Maybe<Scalars['JSON']>;
  taskName?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type FunTaskDetailInfoInput = {
  id?: Maybe<Scalars['ID']>;
  taskId?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['String']>;
  jobName?: Maybe<Scalars['String']>;
  stepId?: Maybe<Scalars['Int']>;
  taskSeqID?: Maybe<Scalars['Int']>;
  fun?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  input?: Maybe<Scalars['JSON']>;
  output?: Maybe<Scalars['JSON']>;
  taskName?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type GroupItemDetail = {
  __typename?: 'GroupItemDetail';
  unit?: Maybe<Scalars['String']>;
  hsCode?: Maybe<Scalars['String']>;
  itemDesp?: Maybe<Scalars['String']>;
  itemName?: Maybe<Scalars['String']>;
  inStock?: Maybe<Scalars['Float']>;
};

/** 业务清单 */
export type GuanMaoBusinessTransit = {
  __typename?: 'GuanMaoBusinessTransit';
  id?: Maybe<Scalars['String']>;
  /** 业务清单号 */
  businessNo?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
  transMode?: Maybe<Scalars['String']>;
  shipper?: Maybe<Scalars['String']>;
  shipperCode?: Maybe<Scalars['String']>;
  grossWtTotal?: Maybe<Scalars['String']>;
  netWt?: Maybe<Scalars['String']>;
  bizNo?: Maybe<Scalars['String']>;
  serialNo?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  businessType?: Maybe<Scalars['String']>;
  ieType?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  orderCompletedStatus?: Maybe<Scalars['String']>;
  /** 商品明细 */
  prodls?: Maybe<Array<Maybe<GuanMaoBusinessTransitProduct>>>;
  detail?: Maybe<Array<Maybe<QdBusinessTransitProduct>>>;
  /** 车辆信息 */
  carls?: Maybe<Array<Maybe<GuanMaoCarInfo>>>;
  /** 在制品明细 */
  prealeasels?: Maybe<Array<Maybe<GuanMaoInProductDetail>>>;
  /** 对应业务清单号吗 */
  relBusinessNo?: Maybe<Scalars['String']>;
};

/** 业务清单 */
export type GuanMaoBusinessTransitInput = {
  id?: Maybe<Scalars['String']>;
  /** 业务清单号 */
  businessNo?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
  transMode?: Maybe<Scalars['String']>;
  shipper?: Maybe<Scalars['String']>;
  shipperCode?: Maybe<Scalars['String']>;
  grossWtTotal?: Maybe<Scalars['String']>;
  netWt?: Maybe<Scalars['String']>;
  bizNo?: Maybe<Scalars['String']>;
  serialNo?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  businessType?: Maybe<Scalars['String']>;
  ieType?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  orderCompletedStatus?: Maybe<Scalars['String']>;
  /** 对应业务清单号吗 */
  relBusinessNo?: Maybe<Scalars['String']>;
};

/** 业务清单商品信息 */
export type GuanMaoBusinessTransitProduct = {
  __typename?: 'GuanMaoBusinessTransitProduct';
  gNo?: Maybe<Scalars['String']>;
  /** 料件成品标志:String #料件, */
  endOrDeed?: Maybe<Scalars['String']>;
  /** 批次号:String #W001181954, */
  batchNo?: Maybe<Scalars['String']>;
  /** 商品料号:String #110000001880, */
  partNumber?: Maybe<Scalars['String']>;
  gdsNm?: Maybe<Scalars['String']>;
  /** 商品编码 */
  gdecd?: Maybe<Scalars['String']>;
  /** 规格型号 */
  gdsSpcfModelDesc?: Maybe<Scalars['String']>;
  dclUprcAmt?: Maybe<Scalars['String']>;
  dclTotalAmt?: Maybe<Scalars['String']>;
  dclCurrcd?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['String']>;
  unitOne?: Maybe<Scalars['String']>;
  dclQty?: Maybe<Scalars['String']>;
  dclUnitcd?: Maybe<Scalars['String']>;
  natcd?: Maybe<Scalars['String']>;
  netWt?: Maybe<Scalars['String']>;
  warehouseNo?: Maybe<Scalars['String']>;
  dutyFreeFlag?: Maybe<Scalars['String']>;
  mtpckEndprdTypecd?: Maybe<Scalars['String']>;
};

export type GuanMaoCarInfo = {
  __typename?: 'GuanMaoCarInfo';
  vehicleNo?: Maybe<Scalars['String']>;
  vehicleWt?: Maybe<Scalars['String']>;
  vehicleFrameWt?: Maybe<Scalars['String']>;
};

/** 在制品明细 */
export type GuanMaoInProductDetail = {
  __typename?: 'GuanMaoInProductDetail';
  id?: Maybe<Scalars['String']>;
  operate?: Maybe<Scalars['String']>;
  foNo?: Maybe<Scalars['String']>;
  gdsNm?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['String']>;
  unitOne?: Maybe<Scalars['String']>;
  dclQty?: Maybe<Scalars['String']>;
  dclUnitcd?: Maybe<Scalars['String']>;
};

/** 扣分记录 */
export type GuanmaoProduct = IDataInfo & {
  __typename?: 'GuanmaoProduct';
  id?: Maybe<Scalars['ID']>;
  /** SAP 料号 */
  partNumber?: Maybe<Scalars['String']>;
  /** 商品属性 0 非保税 1= 保税 */
  attribute?: Maybe<Scalars['String']>;
  /** 件号 */
  pieceNo?: Maybe<Scalars['String']>;
  /** 项目类型： */
  projectType?: Maybe<Scalars['String']>;
  senNo?: Maybe<Scalars['String']>;
  wareHouseNo?: Maybe<Scalars['String']>;
  classNum?: Maybe<Scalars['String']>;
  /** 商品状态： 0-停用 1-启用 */
  isUsing?: Maybe<Scalars['String']>;
  /** 商品名称(英文)： */
  declareName?: Maybe<Scalars['String']>;
  goodsDesc?: Maybe<Scalars['String']>;
  customMfr?: Maybe<Scalars['String']>;
  orginCountryValue?: Maybe<Scalars['String']>;
  orginCountry?: Maybe<Scalars['String']>;
  proManufacture?: Maybe<Scalars['String']>;
  domPurchase?: Maybe<Scalars['String']>;
  /** 归类状态：40=已归类 */
  isClassify?: Maybe<Scalars['String']>;
  /** 预归类书编号 */
  preNum?: Maybe<Scalars['String']>;
  classBasis?: Maybe<Scalars['String']>;
  goodsRemark?: Maybe<Scalars['String']>;
  proLine?: Maybe<Scalars['String']>;
  firstQuantity?: Maybe<Scalars['String']>;
  unitFirst?: Maybe<Scalars['String']>;
  secondQuantity?: Maybe<Scalars['String']>;
  unitSecond?: Maybe<Scalars['String']>;
  littleFirQuantity?: Maybe<Scalars['String']>;
  littleFirUnit?: Maybe<Scalars['String']>;
  littleSecQuantity?: Maybe<Scalars['String']>;
  littleSecUnit?: Maybe<Scalars['String']>;
  bulkFirQuantity?: Maybe<Scalars['String']>;
  bulkFirUnit?: Maybe<Scalars['String']>;
  bulkSecQuantity?: Maybe<Scalars['String']>;
  bulkSecUnit?: Maybe<Scalars['String']>;
  declareUnit?: Maybe<Scalars['String']>;
  netFirQuantity?: Maybe<Scalars['String']>;
  netFirst?: Maybe<Scalars['String']>;
  netSecQuantity?: Maybe<Scalars['String']>;
  netSecUnit?: Maybe<Scalars['String']>;
  grossFirQuantity?: Maybe<Scalars['String']>;
  grossFirst?: Maybe<Scalars['String']>;
  grossSecQuantity?: Maybe<Scalars['String']>;
  grossSecUnit?: Maybe<Scalars['String']>;
  volumeFirQuantity?: Maybe<Scalars['String']>;
  volumeUnit?: Maybe<Scalars['String']>;
  volumeSecQuantity?: Maybe<Scalars['String']>;
  volumeSecUnit?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['String']>;
  currencyValue?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  unitOne?: Maybe<Scalars['String']>;
  littlePrice?: Maybe<Scalars['String']>;
  littleCurrencyValue?: Maybe<Scalars['String']>;
  littleCurrency?: Maybe<Scalars['String']>;
  littleUnit?: Maybe<Scalars['String']>;
  bulkPrice?: Maybe<Scalars['String']>;
  bulkCurrencyValue?: Maybe<Scalars['String']>;
  bulkCurrency?: Maybe<Scalars['String']>;
  bulkUnit?: Maybe<Scalars['String']>;
  textfield?: Maybe<Scalars['String']>;
  fileField?: Maybe<Scalars['String']>;
  hsCode?: Maybe<Scalars['String']>;
  sjName?: Maybe<Scalars['String']>;
  sjCode?: Maybe<Scalars['String']>;
  /** 申报品名： */
  goodsName?: Maybe<Scalars['String']>;
  /**
   * "
   * 料件/成品 01= 料件
   */
  endOrDeed?: Maybe<Scalars['String']>;
  manualNum?: Maybe<Scalars['String']>;
  itemNumber?: Maybe<Scalars['String']>;
  versionNum?: Maybe<Scalars['String']>;
  procotolNum?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  hsType?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  spModel?: Maybe<Scalars['String']>;
  /** 申报要素：values */
  declareElementValue?: Maybe<Scalars['String']>;
  /** 申报要素：keys */
  declareElement?: Maybe<Scalars['String']>;
  lowRate?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['String']>;
  outRate?: Maybe<Scalars['String']>;
  rebateRate?: Maybe<Scalars['String']>;
  highRate?: Maybe<Scalars['String']>;
  priceTaxRate?: Maybe<Scalars['String']>;
  relConfirm?: Maybe<Scalars['String']>;
  priceConfirm?: Maybe<Scalars['String']>;
  royalConfirm?: Maybe<Scalars['String']>;
  scaleFactorOne?: Maybe<Scalars['String']>;
  scaleFactorTwo?: Maybe<Scalars['String']>;
  taxElementValue?: Maybe<Scalars['String']>;
  taxElement?: Maybe<Scalars['String']>;
  catalogNo?: Maybe<Scalars['String']>;
  brandType?: Maybe<Scalars['String']>;
  taxFunction?: Maybe<Scalars['String']>;
  part?: Maybe<Scalars['String']>;
  workPrin?: Maybe<Scalars['String']>;
  orgUse?: Maybe<Scalars['String']>;
  taxPlace?: Maybe<Scalars['String']>;
  packageWrapName?: Maybe<Scalars['String']>;
  packageWrap?: Maybe<Scalars['String']>;
  baleQuantity?: Maybe<Scalars['String']>;
  baleFirst?: Maybe<Scalars['String']>;
  balePieces?: Maybe<Scalars['String']>;
  baleSecond?: Maybe<Scalars['String']>;
  wrapLong?: Maybe<Scalars['String']>;
  wrapWidth?: Maybe<Scalars['String']>;
  wrapHeight?: Maybe<Scalars['String']>;
  wrapWt?: Maybe<Scalars['String']>;
  smallLong?: Maybe<Scalars['String']>;
  smallWidth?: Maybe<Scalars['String']>;
  smallHeight?: Maybe<Scalars['String']>;
  infoId?: Maybe<Scalars['String']>;
  typeFlag?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  batchNo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  dataSource?: Maybe<Scalars['String']>;
  dangerousGoodsCode?: Maybe<Scalars['String']>;
  dangerousGoods?: Maybe<Scalars['String']>;
};

export type GuanmaoProductInput = {
  id?: Maybe<Scalars['ID']>;
  /** SAP 料号 */
  partNumber?: Maybe<Scalars['String']>;
  /** 商品属性 0 非保税 1= 保税 */
  attribute?: Maybe<Scalars['String']>;
  /** 件号 */
  pieceNo?: Maybe<Scalars['String']>;
  /** 项目类型： */
  projectType?: Maybe<Scalars['String']>;
  senNo?: Maybe<Scalars['String']>;
  wareHouseNo?: Maybe<Scalars['String']>;
  classNum?: Maybe<Scalars['String']>;
  /** 商品状态： 0-停用 1-启用 */
  isUsing?: Maybe<Scalars['String']>;
  /** 商品名称(英文)： */
  declareName?: Maybe<Scalars['String']>;
  goodsDesc?: Maybe<Scalars['String']>;
  customMfr?: Maybe<Scalars['String']>;
  orginCountryValue?: Maybe<Scalars['String']>;
  orginCountry?: Maybe<Scalars['String']>;
  proManufacture?: Maybe<Scalars['String']>;
  domPurchase?: Maybe<Scalars['String']>;
  /** 归类状态：40=已归类 */
  isClassify?: Maybe<Scalars['String']>;
  /** 预归类书编号 */
  preNum?: Maybe<Scalars['String']>;
  classBasis?: Maybe<Scalars['String']>;
  goodsRemark?: Maybe<Scalars['String']>;
  proLine?: Maybe<Scalars['String']>;
  firstQuantity?: Maybe<Scalars['String']>;
  unitFirst?: Maybe<Scalars['String']>;
  secondQuantity?: Maybe<Scalars['String']>;
  unitSecond?: Maybe<Scalars['String']>;
  littleFirQuantity?: Maybe<Scalars['String']>;
  littleFirUnit?: Maybe<Scalars['String']>;
  littleSecQuantity?: Maybe<Scalars['String']>;
  littleSecUnit?: Maybe<Scalars['String']>;
  bulkFirQuantity?: Maybe<Scalars['String']>;
  bulkFirUnit?: Maybe<Scalars['String']>;
  bulkSecQuantity?: Maybe<Scalars['String']>;
  bulkSecUnit?: Maybe<Scalars['String']>;
  declareUnit?: Maybe<Scalars['String']>;
  netFirQuantity?: Maybe<Scalars['String']>;
  netFirst?: Maybe<Scalars['String']>;
  netSecQuantity?: Maybe<Scalars['String']>;
  netSecUnit?: Maybe<Scalars['String']>;
  grossFirQuantity?: Maybe<Scalars['String']>;
  grossFirst?: Maybe<Scalars['String']>;
  grossSecQuantity?: Maybe<Scalars['String']>;
  grossSecUnit?: Maybe<Scalars['String']>;
  volumeFirQuantity?: Maybe<Scalars['String']>;
  volumeUnit?: Maybe<Scalars['String']>;
  volumeSecQuantity?: Maybe<Scalars['String']>;
  volumeSecUnit?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['String']>;
  currencyValue?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  unitOne?: Maybe<Scalars['String']>;
  littlePrice?: Maybe<Scalars['String']>;
  littleCurrencyValue?: Maybe<Scalars['String']>;
  littleCurrency?: Maybe<Scalars['String']>;
  littleUnit?: Maybe<Scalars['String']>;
  bulkPrice?: Maybe<Scalars['String']>;
  bulkCurrencyValue?: Maybe<Scalars['String']>;
  bulkCurrency?: Maybe<Scalars['String']>;
  bulkUnit?: Maybe<Scalars['String']>;
  textfield?: Maybe<Scalars['String']>;
  fileField?: Maybe<Scalars['String']>;
  hsCode?: Maybe<Scalars['String']>;
  sjName?: Maybe<Scalars['String']>;
  sjCode?: Maybe<Scalars['String']>;
  /** 申报品名： */
  goodsName?: Maybe<Scalars['String']>;
  /**
   * "
   * 料件/成品 01= 料件
   */
  endOrDeed?: Maybe<Scalars['String']>;
  manualNum?: Maybe<Scalars['String']>;
  itemNumber?: Maybe<Scalars['String']>;
  versionNum?: Maybe<Scalars['String']>;
  procotolNum?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  hsType?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  spModel?: Maybe<Scalars['String']>;
  /** 申报要素：values */
  declareElementValue?: Maybe<Scalars['String']>;
  /** 申报要素：keys */
  declareElement?: Maybe<Scalars['String']>;
  lowRate?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['String']>;
  outRate?: Maybe<Scalars['String']>;
  rebateRate?: Maybe<Scalars['String']>;
  highRate?: Maybe<Scalars['String']>;
  priceTaxRate?: Maybe<Scalars['String']>;
  relConfirm?: Maybe<Scalars['String']>;
  priceConfirm?: Maybe<Scalars['String']>;
  royalConfirm?: Maybe<Scalars['String']>;
  scaleFactorOne?: Maybe<Scalars['String']>;
  scaleFactorTwo?: Maybe<Scalars['String']>;
  taxElementValue?: Maybe<Scalars['String']>;
  taxElement?: Maybe<Scalars['String']>;
  catalogNo?: Maybe<Scalars['String']>;
  brandType?: Maybe<Scalars['String']>;
  taxFunction?: Maybe<Scalars['String']>;
  part?: Maybe<Scalars['String']>;
  workPrin?: Maybe<Scalars['String']>;
  orgUse?: Maybe<Scalars['String']>;
  taxPlace?: Maybe<Scalars['String']>;
  packageWrapName?: Maybe<Scalars['String']>;
  packageWrap?: Maybe<Scalars['String']>;
  baleQuantity?: Maybe<Scalars['String']>;
  baleFirst?: Maybe<Scalars['String']>;
  balePieces?: Maybe<Scalars['String']>;
  baleSecond?: Maybe<Scalars['String']>;
  wrapLong?: Maybe<Scalars['String']>;
  wrapWidth?: Maybe<Scalars['String']>;
  wrapHeight?: Maybe<Scalars['String']>;
  wrapWt?: Maybe<Scalars['String']>;
  smallLong?: Maybe<Scalars['String']>;
  smallWidth?: Maybe<Scalars['String']>;
  smallHeight?: Maybe<Scalars['String']>;
  infoId?: Maybe<Scalars['String']>;
  typeFlag?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  batchNo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  dataSource?: Maybe<Scalars['String']>;
  dangerousGoodsCode?: Maybe<Scalars['String']>;
  dangerousGoods?: Maybe<Scalars['String']>;
};

/** 一体化 卡口验放凭证 */
export type HsCustomsRegulation = IDataInfo & {
  __typename?: 'HsCustomsRegulation';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  regType?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['String']>;
  gdsName?: Maybe<Scalars['String']>;
  nameDesc?: Maybe<Scalars['String']>;
  desc?: Maybe<Scalars['String']>;
  hsCode?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type HsCustomsRegulationInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  regType?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['String']>;
  gdsName?: Maybe<Scalars['String']>;
  nameDesc?: Maybe<Scalars['String']>;
  desc?: Maybe<Scalars['String']>;
  hsCode?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type IDataInfo = {
  id?: Maybe<Scalars['ID']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export enum IeType {
  Import = 'IMPORT',
  Export = 'EXPORT',
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND'
}


export type KddPreOrderInput = {
  id?: Maybe<Scalars['ID']>;
  templateName?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
  uploadfile?: Maybe<Scalars['String']>;
  orderOutNo?: Maybe<Scalars['String']>;
  parentOrder?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  chkSurety?: Maybe<Scalars['String']>;
  /** 单一窗口发送 clientSeqNo */
  orderNo1?: Maybe<Scalars['String']>;
  sepSeqno?: Maybe<Scalars['String']>;
  departCode?: Maybe<Scalars['String']>;
  departName?: Maybe<Scalars['String']>;
  drawtotalAmount?: Maybe<Scalars['Float']>;
  ordertype?: Maybe<Scalars['String']>;
  zhidantype?: Maybe<Scalars['String']>;
  splitLeft?: Maybe<Scalars['Float']>;
  mianbaoFlag?: Maybe<Scalars['String']>;
  stockFlag?: Maybe<Scalars['String']>;
  goodsCustname?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate: Scalars['Date'];
  lastUpdatePerson?: Maybe<Scalars['String']>;
  cusIEFlagName?: Maybe<Scalars['String']>;
  preEntryId?: Maybe<Scalars['String']>;
  entryId?: Maybe<Scalars['String']>;
  noOtherPack?: Maybe<Scalars['String']>;
  entQualifTypeCodeS?: Maybe<Scalars['String']>;
  entQualifTypeCodeSName?: Maybe<Scalars['String']>;
  ciqBillNo?: Maybe<Scalars['String']>;
  correlationDeclNo?: Maybe<Scalars['String']>;
  correlationReasonFlagName?: Maybe<Scalars['String']>;
  specDeclFlagInput?: Maybe<Scalars['String']>;
  appCert?: Maybe<Scalars['String']>;
  procmessage?: Maybe<Scalars['String']>;
  declDate?: Maybe<Scalars['String']>;
  preDecRequCertList?: Maybe<Array<Maybe<PreDecRequCertInput>>>;
  decOtherPacksVo?: Maybe<Array<Maybe<DecOtherPacksVoInput>>>;
  decMergeListVo?: Maybe<Array<Maybe<DecMergeListVoInput>>>;
  cmbdecMergeListVo?: Maybe<Array<Maybe<DecMergeListVoInput>>>;
  preDecEntQualifListVo?: Maybe<Array<Maybe<PreDecEntQualifListVoInput>>>;
  memoInstock?: Maybe<Scalars['String']>;
  operNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  custNo?: Maybe<Scalars['String']>;
  cmbCutMode?: Maybe<Scalars['String']>;
  patchobj?: Maybe<Scalars['JSON']>;
  storeOutNo?: Maybe<Scalars['String']>;
  statusName?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  cusIEFlag?: Maybe<Scalars['String']>;
  customMasterName?: Maybe<Scalars['String']>;
  customMaster?: Maybe<Scalars['String']>;
  cusDecStatusName?: Maybe<Scalars['String']>;
  cusDecStatus?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  iEPortName?: Maybe<Scalars['String']>;
  iEPort?: Maybe<Scalars['String']>;
  manualNo?: Maybe<Scalars['String']>;
  contrNo?: Maybe<Scalars['String']>;
  iEDate?: Maybe<Scalars['String']>;
  dDate?: Maybe<Scalars['String']>;
  cnsnTradeScc?: Maybe<Scalars['String']>;
  cnsnTradeCode?: Maybe<Scalars['String']>;
  consignorCname?: Maybe<Scalars['String']>;
  consigneeEname?: Maybe<Scalars['String']>;
  rcvgdTradeScc?: Maybe<Scalars['String']>;
  rcvgdTradeCode?: Maybe<Scalars['String']>;
  consigneeCode?: Maybe<Scalars['String']>;
  consigneeCname?: Maybe<Scalars['String']>;
  consignorCode?: Maybe<Scalars['String']>;
  consignorEname?: Maybe<Scalars['String']>;
  ownerScc?: Maybe<Scalars['String']>;
  ownerCode?: Maybe<Scalars['String']>;
  ownerCiqCode?: Maybe<Scalars['String']>;
  ownerName?: Maybe<Scalars['String']>;
  agentScc?: Maybe<Scalars['String']>;
  agentCode?: Maybe<Scalars['String']>;
  declRegNo?: Maybe<Scalars['String']>;
  agentName?: Maybe<Scalars['String']>;
  cusTrafModeName?: Maybe<Scalars['String']>;
  cusTrafMode?: Maybe<Scalars['String']>;
  trafName?: Maybe<Scalars['String']>;
  cusVoyageNo?: Maybe<Scalars['String']>;
  relativeId?: Maybe<Scalars['String']>;
  relmanNo?: Maybe<Scalars['String']>;
  bonNo?: Maybe<Scalars['String']>;
  customsField?: Maybe<Scalars['String']>;
  customsFieldName?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
  tradeModeCode?: Maybe<Scalars['String']>;
  tradeModeCodeName?: Maybe<Scalars['String']>;
  cutModeName?: Maybe<Scalars['String']>;
  cutMode?: Maybe<Scalars['String']>;
  licenseNo?: Maybe<Scalars['String']>;
  cusTradeCountryName?: Maybe<Scalars['String']>;
  cusTradeCountry?: Maybe<Scalars['String']>;
  distinatePortName?: Maybe<Scalars['String']>;
  distinatePort?: Maybe<Scalars['String']>;
  transModeName?: Maybe<Scalars['String']>;
  transMode?: Maybe<Scalars['String']>;
  feeMarkName?: Maybe<Scalars['String']>;
  feeMark?: Maybe<Scalars['String']>;
  feeRate?: Maybe<Scalars['String']>;
  feeCurrName?: Maybe<Scalars['String']>;
  feeCurr?: Maybe<Scalars['String']>;
  insurMarkName?: Maybe<Scalars['String']>;
  insurMark?: Maybe<Scalars['String']>;
  insurRate?: Maybe<Scalars['String']>;
  insurCurrName?: Maybe<Scalars['String']>;
  insurCurr?: Maybe<Scalars['String']>;
  otherMarkName?: Maybe<Scalars['String']>;
  otherMark?: Maybe<Scalars['String']>;
  otherRate?: Maybe<Scalars['String']>;
  otherCurrName?: Maybe<Scalars['String']>;
  otherCurr?: Maybe<Scalars['String']>;
  packNo?: Maybe<Scalars['String']>;
  wrapTypeName?: Maybe<Scalars['String']>;
  wrapType?: Maybe<Scalars['String']>;
  grossWt?: Maybe<Scalars['String']>;
  netWt?: Maybe<Scalars['String']>;
  cusTradeNationCodeName?: Maybe<Scalars['String']>;
  cusTradeNationCode?: Maybe<Scalars['String']>;
  contaCount?: Maybe<Scalars['String']>;
  attaDocuCdstr?: Maybe<Scalars['String']>;
  ciqEntyPortCodeName?: Maybe<Scalars['String']>;
  ciqEntyPortCode?: Maybe<Scalars['String']>;
  goodsPlace?: Maybe<Scalars['String']>;
  despPortCodeName?: Maybe<Scalars['String']>;
  despPortCode?: Maybe<Scalars['String']>;
  entryTypeName?: Maybe<Scalars['String']>;
  entryType?: Maybe<Scalars['String']>;
  noteS?: Maybe<Scalars['String']>;
  mbnoteS?: Maybe<Scalars['String']>;
  flightName?: Maybe<Scalars['String']>;
  heavyflightType?: Maybe<Scalars['String']>;
  showSepNo?: Maybe<Scalars['String']>;
  mProjectNo?: Maybe<Scalars['String']>;
  mbcontrNo?: Maybe<Scalars['String']>;
  finishdate?: Maybe<Scalars['Date']>;
  markNo?: Maybe<Scalars['String']>;
  orgCodeName?: Maybe<Scalars['String']>;
  orgCode?: Maybe<Scalars['String']>;
  vsaOrgCodeName?: Maybe<Scalars['String']>;
  vsaOrgCode?: Maybe<Scalars['String']>;
  inspOrgCodeName?: Maybe<Scalars['String']>;
  inspOrgCode?: Maybe<Scalars['String']>;
  despDate?: Maybe<Scalars['String']>;
  purpOrgCodeName?: Maybe<Scalars['String']>;
  purpOrgCode?: Maybe<Scalars['String']>;
  origBoxFlagName?: Maybe<Scalars['String']>;
  origBoxFlag?: Maybe<Scalars['String']>;
  specDeclFlag?: Maybe<Scalars['String']>;
  appCertName?: Maybe<Scalars['String']>;
  promiseItems?: Maybe<Scalars['String']>;
  preDecContainerVo?: Maybe<Array<Maybe<SwPreDecContainerVoInput>>>;
  cusLicenseListVo?: Maybe<Array<Maybe<SwCusLicenseListVoInput>>>;
  preDecDocVo?: Maybe<Array<Maybe<SwPreDecDocVoInput>>>;
  sfserial?: Maybe<Scalars['String']>;
  flighttype?: Maybe<Scalars['String']>;
  billType?: Maybe<Scalars['String']>;
};

export type KddPreOrderTemplate = {
  __typename?: 'KddPreOrderTemplate';
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  templateConfig?: Maybe<Scalars['JSON']>;
  tempalteValue?: Maybe<Kddpreorder>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type KddPreOrderTemplateInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  templateConfig?: Maybe<Scalars['JSON']>;
  tempalteValue?: Maybe<KddPreOrderInput>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type KeyValue = {
  __typename?: 'KeyValue';
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  values?: Maybe<Scalars['JSON']>;
};

export type KeyValueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  values?: Maybe<Scalars['JSON']>;
};

/** 料件流转信息 */
export type LiaoJianInfo = {
  __typename?: 'LiaoJianInfo';
  id?: Maybe<Scalars['String']>;
  createdDatetime?: Maybe<Scalars['String']>;
  createdUser?: Maybe<Scalars['String']>;
  updatedDatetime?: Maybe<Scalars['String']>;
  updatedUser?: Maybe<Scalars['String']>;
  plateNo?: Maybe<Scalars['String']>;
  plateWeight?: Maybe<Scalars['String']>;
  frameWeight?: Maybe<Scalars['String']>;
  totalGrossWeight?: Maybe<Scalars['String']>;
  packageSize?: Maybe<Scalars['String']>;
  packageCount?: Maybe<Scalars['String']>;
  corporateName?: Maybe<Scalars['String']>;
  corporateCode?: Maybe<Scalars['String']>;
  corporateInsideCode?: Maybe<Scalars['String']>;
  dealType?: Maybe<Scalars['String']>;
  contractNo?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
  orderType?: Maybe<Scalars['String']>;
  bizCode?: Maybe<Scalars['String']>;
  sortieNo?: Maybe<Scalars['String']>;
  taskNo?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  taskCategory?: Maybe<Scalars['String']>;
  processType?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  soldInside?: Maybe<Scalars['String']>;
  fragments?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  showSoldInside?: Maybe<Scalars['String']>;
  btns?: Maybe<Scalars['String']>;
  checked?: Maybe<Scalars['String']>;
  originEmsOrgName?: Maybe<Scalars['String']>;
  destinaEmsOrgName?: Maybe<Scalars['String']>;
  originWarehouse?: Maybe<Scalars['String']>;
  destinaWarehouse?: Maybe<Scalars['String']>;
  partCode?: Maybe<Scalars['String']>;
  rowId?: Maybe<Scalars['String']>;
  ids?: Maybe<Scalars['String']>;
  businessType?: Maybe<Scalars['String']>;
};

/** 重装目录列表 */
export type ListAeoHeavyeqp = {
  __typename?: 'ListAeoHeavyeqp';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<AeoHeavyeqp>>>;
};

export type ListAeoValidateRule = {
  __typename?: 'ListAeoValidateRule';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<AeoValidateRule>>>;
};

export type ListAppAppName = {
  __typename?: 'ListAppAppName';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<AppAppName>>>;
};

export type ListAppCompany = {
  __typename?: 'ListAppCompany';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<AppCompany>>>;
};

export type ListAppComponentConfig = {
  __typename?: 'ListAppComponentConfig';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<AppComponentConfig>>>;
};

export type ListAppRolePolicy = {
  __typename?: 'ListAppRolePolicy';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<AppRolePolicy>>>;
};

export type ListAppUserInfo = {
  __typename?: 'ListAppUserInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<AppUserInfo>>>;
};

/** 客户查询结果 */
export type ListBizCompanyInfo = {
  __typename?: 'ListBizCompanyInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<BizCompanyInfo>>>;
};

export type ListBizInvoiceApply = {
  __typename?: 'ListBizInvoiceApply';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<BizInvoiceApply>>>;
};

export type ListBizPaySupplierApply = {
  __typename?: 'ListBizPaySupplierApply';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<BizPaySupplierApply>>>;
};

export type ListBizSalesContractDetail = {
  __typename?: 'ListBizSalesContractDetail';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<BizSalesContractDetail>>>;
};

export type ListBizSalesContractService = {
  __typename?: 'ListBizSalesContractService';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<BizSalesContractService>>>;
};

export type ListBizSupplierContractDetail = {
  __typename?: 'ListBizSupplierContractDetail';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<BizSupplierContractDetail>>>;
};

export type ListCustBillInfo = {
  __typename?: 'ListCustBillInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<CustBillInfo>>>;
};

export type ListCustomApplyBussinessInfo = {
  __typename?: 'ListCustomApplyBussinessInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<CustomApplyBussinessInfo>>>;
};

/** 客户查询结果 */
export type ListDataChangeLogInfo = {
  __typename?: 'ListDataChangeLogInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<DataChangeLogInfo>>>;
};

/** 改单申请查询结果 */
export type ListDecModHeadInfo = {
  __typename?: 'ListDecModHeadInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<DecModHeadInfo>>>;
};

export type ListDecStatus = {
  __typename?: 'ListDecStatus';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<DecStatus>>>;
};

/** 改单申请查询结果 */
export type ListDecTrackInfo = {
  __typename?: 'ListDecTrackInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<DecTrackInfo>>>;
};

export type ListFunJobInfo = {
  __typename?: 'ListFunJobInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<FunJobInfo>>>;
};

export type ListFunTaskDetailInfo = {
  __typename?: 'ListFunTaskDetailInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<FunTaskDetailInfo>>>;
};

/** 美华商品库雷彪 */
export type ListGuanmaoProduct = {
  __typename?: 'ListGuanmaoProduct';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<GuanmaoProduct>>>;
};

export type ListHsCustomsRegulation = {
  __typename?: 'ListHsCustomsRegulation';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<HsCustomsRegulation>>>;
};

export type ListKddPreOrder = {
  __typename?: 'ListKddPreOrder';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<Kddpreorder>>>;
};

export type ListKddPreOrderTemplate = {
  __typename?: 'ListKddPreOrderTemplate';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<KddPreOrderTemplate>>>;
};

export type ListMissionCheckRecord = {
  __typename?: 'ListMissionCheckRecord';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<MissionCheckRecord>>>;
};

/** 美华商品库雷彪 */
export type ListNetTransitCar = {
  __typename?: 'ListNetTransitCar';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<NetTransitCar>>>;
};

export type ListOrderFileInfo = {
  __typename?: 'ListOrderFileInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<OrderFileInfo>>>;
};

export type ListOrderMain = {
  __typename?: 'ListOrderMain';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<XportOrderMain>>>;
};

export type ListOrderPicInfo = {
  __typename?: 'ListOrderPicInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<OrderPicInfo>>>;
};

export type ListOssFileInfo = {
  __typename?: 'ListOssFileInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<OssFileInfo>>>;
};

/** 合同查询 */
export type ListOwnerManage = {
  __typename?: 'ListOwnerManage';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<OwnerManage>>>;
};

export type ListPartNumberSapInventoryPrice = {
  __typename?: 'ListPartNumberSAPInventoryPrice';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<PartNumberSapInventoryPrice>>>;
};

export type ListProductCompany = {
  __typename?: 'ListProductCompany';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<ProductCompany>>>;
};

export type ListProductManage = {
  __typename?: 'ListProductManage';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<Productmanage>>>;
};

/** 合同查询 */
export type ListPurchaseContract = {
  __typename?: 'ListPurchaseContract';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<PurchaseContract>>>;
};

export type ListRoadTransForwardingOrder = {
  __typename?: 'ListRoadTransForwardingOrder';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<RoadTransForwardingOrder>>>;
};

export type ListRpaJobInfo = {
  __typename?: 'ListRpaJobInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<RpaJobInfo>>>;
};

export type ListRpaJobProcessDetail = {
  __typename?: 'ListRpaJobProcessDetail';
  totalCount?: Maybe<Scalars['Int']>;
  totalProcessCount?: Maybe<Scalars['Int']>;
  inputTotal?: Maybe<Scalars['Float']>;
  outputTotal?: Maybe<Scalars['Float']>;
  finishCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<RpaJobProcessDetail>>>;
};

export type ListRpaTaskDef = {
  __typename?: 'ListRpaTaskDef';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<RpaTaskDef>>>;
};

export type ListScmSamcTrackInfo = {
  __typename?: 'ListScmSamcTrackInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<ScmSamcTrackInfo>>>;
};

export type ListServiceBillDetailInfo = {
  __typename?: 'ListServiceBillDetailInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<ServiceBillDetailInfo>>>;
};

export type ListServicePurchaseDetailInfo = {
  __typename?: 'ListServicePurchaseDetailInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<ServicePurchaseDetailInfo>>>;
};

export type ListSpAreaInventory = {
  __typename?: 'ListSpAreaInventory';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<SpAreaInventory>>>;
};

export type ListSpAreaInventoryGroupView = {
  __typename?: 'ListSpAreaInventoryGroupView';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<SpAreaInventoryGroupView>>>;
};

export type ListSpAreaPortRelease = {
  __typename?: 'ListSpAreaPortRelease';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<SpAreaPortRelease>>>;
};

export type ListSpAreaStat = {
  __typename?: 'ListSpAreaStat';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<SpAreaStat>>>;
};

export type ListSpPassport = {
  __typename?: 'ListSpPassport';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<SpPassport>>>;
};

export type ListSpecialTypeOrderTrackInfo = {
  __typename?: 'ListSpecialTypeOrderTrackInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<SpecialTypeOrderTrackInfo>>>;
};

export type ListTensorSpAreaPackageList = {
  __typename?: 'ListTensorSpAreaPackageList';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<TensorSpAreaPackageList>>>;
};

export type ListTitMail = {
  __typename?: 'ListTitMail';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<TitMail>>>;
};

export type ListTmsCustApplyLog = {
  __typename?: 'ListTmsCustApplyLog';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<TmsCustApplyLog>>>;
};

export type ListTmsCustTransApply = {
  __typename?: 'ListTmsCustTransApply';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<TmsCustTransApply>>>;
};

export type ListTmsOrderInfo = {
  __typename?: 'ListTmsOrderInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<TmsOrderInfo>>>;
};

export type ListTmsPassPortOrder = {
  __typename?: 'ListTmsPassPortOrder';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<TmsPassPortOrder>>>;
};

export type ListTmsProduceWarehouse = {
  __typename?: 'ListTmsProduceWarehouse';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<TmsProduceWarehouse>>>;
};

export type ListTmsSupplierDriverInfo = {
  __typename?: 'ListTmsSupplierDriverInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<TmsSupplierDriverInfo>>>;
};

export type ListTmsSupplierVehicleInfo = {
  __typename?: 'ListTmsSupplierVehicleInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<TmsSupplierVehicleInfo>>>;
};

export type ListTmsTransApply = {
  __typename?: 'ListTmsTransApply';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<TmsTransApply>>>;
};

export type ListTmsTransMissionInfo = {
  __typename?: 'ListTmsTransMissionInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<TmsTransMissionInfo>>>;
};

export type ListTmsUserAddressInfo = {
  __typename?: 'ListTmsUserAddressInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<TmsUserAddressInfo>>>;
};

export type ListTrainDataInfo = {
  __typename?: 'ListTrainDataInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<TrainDataInfo>>>;
};

export type ListTransTaskOrder = {
  __typename?: 'ListTransTaskOrder';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<TransTaskOrder>>>;
};

export type ListTransVehicleInfo = {
  __typename?: 'ListTransVehicleInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<TransVehicleInfo>>>;
};

export type ListWmsInwardPlan = {
  __typename?: 'ListWmsInwardPlan';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<WmsInwardPlan>>>;
};

export type ListYitihuaPackageList = {
  __typename?: 'ListYitihuaPackageList';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<YitihuaPackageList>>>;
};

/** 改单申请查询结果 */
export type Listchacuo = {
  __typename?: 'Listchacuo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<Chacuo>>>;
};

/** 合同查询 */
export type Listcontract = {
  __typename?: 'Listcontract';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<Contract>>>;
};

/** 合同查询 */
export type Listdzstateinfo = {
  __typename?: 'Listdzstateinfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<Dzstateinfo>>>;
};

/** 改单申请查询结果 */
export type Listhgcheck = {
  __typename?: 'Listhgcheck';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<Hgcheck>>>;
};

/** 改单申请查询结果 */
export type Listhgreject = {
  __typename?: 'Listhgreject';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<Hgreject>>>;
};

/** 改单申请查询结果 */
export type Listhsregex = {
  __typename?: 'Listhsregex';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<Hsregex>>>;
};

/** 合同查询 */
export type ListtaxInfo = {
  __typename?: 'ListtaxInfo';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<TaxInfo>>>;
};

/** 改单申请查询结果 */
export type Listvalidationrule = {
  __typename?: 'Listvalidationrule';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<Validationrule>>>;
};

export type ListwareHouse = {
  __typename?: 'ListwareHouse';
  totalCount?: Maybe<Scalars['Int']>;
  dataList?: Maybe<Array<Maybe<WareHouse>>>;
};

export type MailAddress = {
  __typename?: 'MailAddress';
  value?: Maybe<Array<Maybe<MailAddressBase>>>;
  html?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type MailAddressBase = {
  __typename?: 'MailAddressBase';
  address?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MailAddressBaseInput = {
  address?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MailAddressInput = {
  value?: Maybe<Array<Maybe<MailAddressBaseInput>>>;
  html?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type MarkUpdateResponse = {
  __typename?: 'MarkUpdateResponse';
  status?: Maybe<Scalars['Boolean']>;
  statusCode?: Maybe<Scalars['Int']>;
  statusMessage?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

/** 一体化 卡口验放凭证 */
export type MissionCheckRecord = IDataInfo & {
  __typename?: 'MissionCheckRecord';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 任务序列号 */
  missionId?: Maybe<Scalars['String']>;
  /** 运输任务编号 */
  transTaskNo?: Maybe<Scalars['String']>;
  /** 运输任务单号 */
  tmsApplyNo?: Maybe<Scalars['String']>;
  /** 打卡人 */
  checkPerson?: Maybe<Scalars['String']>;
  /** 打卡时间 */
  checkTime?: Maybe<Scalars['Date']>;
  /** 打卡地点 */
  checkPlace?: Maybe<Scalars['String']>;
  /** 打卡图片 */
  checkPic?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 打卡描述 */
  checkDesc?: Maybe<Scalars['String']>;
  /** 打卡状态 */
  checkStatus?: Maybe<Scalars['String']>;
  /** 打卡GPS位置 */
  checkLocation?: Maybe<Scalars['String']>;
  /** 用户id */
  userId?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type MissionCheckRecordInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  /** 任务序列号 */
  missionId?: Maybe<Scalars['String']>;
  /** 运输任务编号 */
  transTaskNo?: Maybe<Scalars['String']>;
  /** 运输任务单号 */
  tmsApplyNo?: Maybe<Scalars['String']>;
  /** 打卡人 */
  checkPerson?: Maybe<Scalars['String']>;
  /** 打卡时间 */
  checkTime?: Maybe<Scalars['Date']>;
  /** 打卡地点 */
  checkPlace?: Maybe<Scalars['String']>;
  /** 打卡图片 */
  checkPic?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 打卡描述 */
  checkDesc?: Maybe<Scalars['String']>;
  /** 打卡状态 */
  checkStatus?: Maybe<Scalars['String']>;
  /** 打卡GPS位置 */
  checkLocation?: Maybe<Scalars['String']>;
  /** 用户id */
  userId?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  UpdateAppCompany: MutationResponse;
  DeleteAppCompany: MutationResponse;
  UpdateAppAppName: MutationResponse;
  DeleteAppAppName: MutationResponse;
  UpdateAppRolePolicy: MutationResponse;
  DeleteAppRolePolicy: MutationResponse;
  /** 更新系统角色，从钉钉 */
  UpdateAppRoles?: Maybe<MutationResponse>;
  /** 更新系统部门，从外部，钉钉 */
  updateAppDepartmentsFromDing?: Maybe<MutationResponse>;
  /** 本地界面修改部门信息内容 */
  updateAppDepartment?: Maybe<MutationResponse>;
  UpdateAppUserInfo: MutationResponse;
  /** 更新个人资料 姓名 */
  updateAppUserInfoProfile: MutationResponse;
  DeleteAppUserInfo: MutationResponse;
  /** 扫码登录 */
  scanQrLogin: MutationResponse;
  /**
   * 钉钉小程序网页用code得到userInfo后login
   * 微信是否能统一?
   */
  dingDingLogin: MutationResponse;
  verifySmsCode: MutationResponse;
  autoChatLogin?: Maybe<MutationResponse>;
  calChatBill?: Maybe<MutationResponse>;
  verifyChatSmsCode?: Maybe<MutationResponse>;
  logoutMobileByOpenId: MutationResponse;
  UpdateAppComponentConfig: MutationResponse;
  DeleteAppComponentConfig: MutationResponse;
  UpdateFunJobInfo: MutationResponse;
  DeleteFunJobInfo: MutationResponse;
  UpdateFunTaskDetailInfo: MutationResponse;
  DeleteFunTaskDetailInfo: MutationResponse;
  /** 更新job 状态和进度 */
  UpdateJobStatus: MutationResponse;
  /** 执行单独步骤 */
  ExecSingleTask: MutationResponse;
  /** 重新执行JobErrorTasks */
  ExecJobErrorTasks: MutationResponse;
  UpdateOrderFileInfo: MutationResponse;
  DeleteOrderFileInfo: MutationResponse;
  UpdateOrderPicInfo: MutationResponse;
  DeleteOrderPicInfo: MutationResponse;
  updateAttachFileTag?: Maybe<MutationResponse>;
  batchupdateAttachFileTag?: Maybe<MutationResponse>;
  /** 生成随附单证 */
  generateAttachPDF?: Maybe<MutationResponse>;
  drawpic?: Maybe<MutationResponse>;
  drawpicfromprint?: Maybe<MutationResponse>;
  drawpicfromallprint?: Maybe<MutationResponse>;
  stockpic?: Maybe<MutationResponse>;
  /** 复审状态修改 */
  auditWithAttFile?: Maybe<MutationResponse>;
  /** 上传随附单证 附件PDF */
  uploadOrderFile?: Maybe<MutationResponse>;
  uploadBgdz?: Maybe<MutationResponse>;
  updatexportOrderPicInfos?: Maybe<MutationResponse>;
  updatexportOrderFileInfo?: Maybe<MutationResponse>;
  deletexportOrderFileInfo?: Maybe<MutationResponse>;
  singleUpload: File;
  UpdateLabels: MutationResponse;
  UpdateDataType: MutationResponse;
  update: MutationResponse;
  /** 将整页加入到OCR Sample中 */
  AddPageToOCRSample: MutationResponse;
  UpdateDecModHeadDuty?: Maybe<MutationResponse>;
  UpdateDecModHeadInfo?: Maybe<MutationResponse>;
  updateBizCompanyMemo?: Maybe<MutationResponse>;
  updateBizCompany?: Maybe<MutationResponse>;
  deleteBizCompany?: Maybe<MutationResponse>;
  updatevalidationrule: MutationResponse;
  updatehgcheck: MutationResponse;
  updatechacuo?: Maybe<MutationResponse>;
  callTmpApi: MutationResponse;
  callouterApi: MutationResponse;
  updateKddPreOrderDec: MutationResponse;
  /** 更新发送单一窗口状态 */
  updateOrderStatus: MutationResponse;
  updateKddDecStatus: MutationResponse;
  autoShenheDecStatus: MutationResponse;
  deletekddpreorder: MutationResponse;
  removekddpreorder: MutationResponse;
  manualSplitPreOrder: MutationResponse;
  remanualSplitPreOrder: MutationResponse;
  weiwsplit: MutationResponse;
  genStockBySels: MutationResponse;
  genStockBylist: MutationResponse;
  genExmianbiao: MutationResponse;
  genExstock: MutationResponse;
  sendtoXVision: MutationResponse;
  sendToSingleWindow: MutationResponse;
  directZhidan: MutationResponse;
  directZhidanfromOrigin: MutationResponse;
  copyKdd: MutationResponse;
  updateKddPreOrderTemplate: UpdateResponse;
  deleteKddPreOrderTemplate: UpdateResponse;
  updateproductmanage: MutationResponse;
  deleteproductmanage: MutationResponse;
  batchupdateprod: MutationResponse;
  updateDingProcessEvent?: Maybe<MutationResponse>;
  updateTitMail: UpdateResponse;
  deleteTitMail: UpdateResponse;
  reFetchDepartMail: UpdateResponse;
  reFetchMail: UpdateResponse;
  fetchAllMail: UpdateResponse;
  /** 根据邮件内容更新dec-dic */
  updateDecStatusFromMail: MutationResponse;
  updatexportProductCompany: MutationResponse;
  deletexportProductCompany: MutationResponse;
  updateXportOrderMain: MutationResponse;
  deleteXportOrderMain: MutationResponse;
  newOrderFromMail: MutationResponse;
  copyOrderFromOrigin: MutationResponse;
  passmail: MutationResponse;
  updateDataChangeLogInfo?: Maybe<MutationResponse>;
  watermarkPic?: Maybe<MutationResponse>;
  updatePurchaseContract?: Maybe<MutationResponse>;
  deletePurchaseContrac: MutationResponse;
  updateAeoHeavyeqp?: Maybe<MutationResponse>;
  updatetaxInfo?: Maybe<MutationResponse>;
  deletetaxInfo: MutationResponse;
  testSingleWindowDecDetailup?: Maybe<Scalars['Int']>;
  updateOwnerManage?: Maybe<MutationResponse>;
  deleteOwnerManage: MutationResponse;
  updateRpaJobInfo: UpdateResponse;
  deleteRpaJobInfo: UpdateResponse;
  linkJobInfoByCode: UpdateResponse;
  reportJobInfoProcessDetail: UpdateResponse;
  resetProcessDetail: UpdateResponse;
  updateProcessDetail: UpdateResponse;
  resetAllProcessDetail: UpdateResponse;
  updatewareHouse: MutationResponse;
  deletewareHouse: MutationResponse;
  updateGuanmaoProduct?: Maybe<MutationResponse>;
  importSapprod?: Maybe<MutationResponse>;
  sycroSapprod?: Maybe<MutationResponse>;
  /** 根据输入业务清单号,新建业务 */
  addNetTransitCar?: Maybe<MutationResponse>;
  updateNetTransitCar?: Maybe<MutationResponse>;
  updateSpAreaPortRelease?: Maybe<MutationResponse>;
  deleteSpAreaPortRelease?: Maybe<MutationResponse>;
  updateSpAreaInventory?: Maybe<MutationResponse>;
  freshGroupInventory?: Maybe<MutationResponse>;
  deleteSpAreaInventory?: Maybe<MutationResponse>;
  updateOssFileInfo: UpdateResponse;
  deleteOssFileInfo: UpdateResponse;
  newOssFileInfoDic: UpdateResponse;
  uploadFileInfo: UpdateResponse;
  updateAeoValidateRule?: Maybe<MutationResponse>;
  deleteAeoValidateRule?: Maybe<MutationResponse>;
  updateSpAreaStat?: Maybe<MutationResponse>;
  deleteSpAreaStat?: Maybe<MutationResponse>;
  updateTmsTransApply?: Maybe<MutationResponse>;
  deleteTmsTransApply?: Maybe<MutationResponse>;
  genTmsApplyPdf?: Maybe<MutationResponse>;
  genTmsBarInfo?: Maybe<MutationResponse>;
  /** 推送钉钉消息 */
  pushDingMssage?: Maybe<MutationResponse>;
  updateWmsInwardPlan?: Maybe<MutationResponse>;
  deleteWmsInwardPlan?: Maybe<MutationResponse>;
  updateScmSamcTrackInfo?: Maybe<MutationResponse>;
  deleteScmSamcTrackInfo?: Maybe<MutationResponse>;
  updateDeliveryFlag?: Maybe<MutationResponse>;
  updatePremFlag?: Maybe<MutationResponse>;
  updateTmsUserAddressInfo?: Maybe<MutationResponse>;
  updateTmsUserAddressInfobysession?: Maybe<MutationResponse>;
  updateTmsUserAddressInfoDefault?: Maybe<MutationResponse>;
  deleteTmsUserAddressInfo?: Maybe<MutationResponse>;
  updateTmsCustTransApply?: Maybe<MutationResponse>;
  applyCustModify?: Maybe<MutationResponse>;
  tmpupdateTmsCustTransApply?: Maybe<MutationResponse>;
  nocarupdateTmsCustTransApply?: Maybe<MutationResponse>;
  calCustTransApplyPrice?: Maybe<MutationResponse>;
  afterupdateTmsCustTransApply?: Maybe<MutationResponse>;
  wbsupdateTmsCustTransApply?: Maybe<MutationResponse>;
  updateTmsCustTransApplyStockStatus?: Maybe<MutationResponse>;
  updateTmsCustTransApplyStatus?: Maybe<MutationResponse>;
  finishxyTmsCustTransApply?: Maybe<MutationResponse>;
  xystartTmsCustTransApply?: Maybe<MutationResponse>;
  NewfreshBillAndPurchase?: Maybe<MutationResponse>;
  /**
   * 提交审批
   * 有条件
   */
  submitTmsCustTransApply?: Maybe<MutationResponse>;
  submitTmsCustTransApplyWithFR?: Maybe<MutationResponse>;
  submitTmsCustTransApplyWithDateFR?: Maybe<MutationResponse>;
  testAddTmsCustTransApplyWithFR?: Maybe<MutationResponse>;
  batchsubmitTmsCustTransApply?: Maybe<MutationResponse>;
  deleteTmsCustTransApply?: Maybe<MutationResponse>;
  submitdeleteTmsCustTransApply?: Maybe<MutationResponse>;
  submitProcCustTransApply?: Maybe<MutationResponse>;
  submitBatchProcCustTransApply?: Maybe<MutationResponse>;
  fillTmsCustTransApplyfromclip?: Maybe<MutationResponse>;
  fillTmsCustTransApplyfromRobot?: Maybe<MutationResponse>;
  fillTmsCustTransApplyfromPic?: Maybe<MutationResponse>;
  regTmsCustTransApplyfromclip?: Maybe<MutationResponse>;
  freeCar?: Maybe<MutationResponse>;
  confirmDeleteTmsApplyInfo?: Maybe<MutationResponse>;
  denyDeleteTmsApplyInfo?: Maybe<MutationResponse>;
  revertTmsCustTransApply?: Maybe<MutationResponse>;
  savetransMemo?: Maybe<MutationResponse>;
  updateCustomApplyTransGoods?: Maybe<MutationResponse>;
  updateVehicleNo?: Maybe<MutationResponse>;
  updateCustomApplyBussinessDetail?: Maybe<MutationResponse>;
  /** 刷新当前清单的状态 */
  updateCustomBizDetail?: Maybe<MutationResponse>;
  /** scan 保存到清单列表中 */
  updatesingleCustomBizDetail?: Maybe<MutationResponse>;
  freshbillandPurchase?: Maybe<MutationResponse>;
  updateTransVehicleInfo?: Maybe<MutationResponse>;
  deleteTransVehicleInfo?: Maybe<MutationResponse>;
  updateRoadTransForwardingOrder?: Maybe<MutationResponse>;
  startRoadTransForwardingOrder?: Maybe<MutationResponse>;
  genRoadTransForwardingOrder?: Maybe<MutationResponse>;
  finishRoadTransForwardingOrder?: Maybe<MutationResponse>;
  updateForwardingOrderStatusByfOrderNo?: Maybe<MutationResponse>;
  deleteRoadTransForwardingOrder?: Maybe<MutationResponse>;
  deleteRoadTransForwardingOrderWithMessage?: Maybe<MutationResponse>;
  submitForderPurchase?: Maybe<MutationResponse>;
  passForderPurchase?: Maybe<MutationResponse>;
  removeForderPurchase?: Maybe<MutationResponse>;
  rejectForderPurchase?: Maybe<MutationResponse>;
  cancleForderPurchase?: Maybe<MutationResponse>;
  getCarReleselocation?: Maybe<MutationResponse>;
  judgePackageInfo?: Maybe<MutationResponse>;
  getShowPackageInfo?: Maybe<MutationResponse>;
  freshPackageInfo?: Maybe<MutationResponse>;
  updateTransTaskOrder?: Maybe<MutationResponse>;
  finishTransTaskOrder?: Maybe<MutationResponse>;
  updateTransTaskOrderlist?: Maybe<MutationResponse>;
  updateTransServiceOrderlist?: Maybe<MutationResponse>;
  updateTransTaskForklist?: Maybe<MutationResponse>;
  deleteTransTaskOrder?: Maybe<MutationResponse>;
  /** 司机接单,生成业务执行单 */
  excuteTransTaskOrder?: Maybe<MutationResponse>;
  /** 司机签到打卡 */
  driverTaskCheckIn?: Maybe<MutationResponse>;
  miodriverTaskCheckIn?: Maybe<MutationResponse>;
  miodriverExceptionCheckIn?: Maybe<MutationResponse>;
  driverTaskFetch?: Maybe<MutationResponse>;
  /** 空车单制作 */
  repairPackageInfo?: Maybe<MutationResponse>;
  forcerepairPackageInfo?: Maybe<MutationResponse>;
  abolishPackageInfo?: Maybe<MutationResponse>;
  testTaskInfo?: Maybe<MutationResponse>;
  testDistance?: Maybe<MutationResponse>;
  fetchPackageInfo?: Maybe<MutationResponse>;
  /** 一体化空车单自动制作 */
  createEmptyYithuaPackageList?: Maybe<MutationResponse>;
  updateTmsSupplierVehicleInfo?: Maybe<MutationResponse>;
  deleteTmsSupplierVehicleInfo?: Maybe<MutationResponse>;
  updateTmsSupplierDriverInfo?: Maybe<MutationResponse>;
  deleteTmsSupplierDriverInfo?: Maybe<MutationResponse>;
  updateTmsTransMissionInfo?: Maybe<MutationResponse>;
  deleteTmsTransMissionInfoByPlace?: Maybe<MutationResponse>;
  deleteTmsTransMissionInfo?: Maybe<MutationResponse>;
  checkTmsTransMissionInfo?: Maybe<MutationResponse>;
  updateMissionCheckRecord?: Maybe<MutationResponse>;
  deleteMissionCheckRecord?: Maybe<MutationResponse>;
  updateServiceBillDetailInfo?: Maybe<MutationResponse>;
  deleteServiceBillDetailInfo?: Maybe<MutationResponse>;
  applyBillDetailInfo?: Maybe<MutationResponse>;
  updateServicePurchaseDetailInfo?: Maybe<MutationResponse>;
  deleteServicePurchaseDetailInfo?: Maybe<MutationResponse>;
  applyPurchaseDetailInfo?: Maybe<MutationResponse>;
  modifyTmsCost?: Maybe<MutationResponse>;
  addBillFromPurchase?: Maybe<MutationResponse>;
  updateBizSalesContractDetail?: Maybe<MutationResponse>;
  deleteBizSalesContractDetail?: Maybe<MutationResponse>;
  updateBizSalesContractService?: Maybe<MutationResponse>;
  deleteBizSalesContractService?: Maybe<MutationResponse>;
  updateBizSupplierContractDetail?: Maybe<MutationResponse>;
  deleteBizSupplierContractDetail?: Maybe<MutationResponse>;
  updateCustBillInfo?: Maybe<MutationResponse>;
  deleteCustBillInfo?: Maybe<MutationResponse>;
  addApplytoBill?: Maybe<MutationResponse>;
  deleteorderBill?: Maybe<MutationResponse>;
  deleteIncome?: Maybe<MutationResponse>;
  deleteCost?: Maybe<MutationResponse>;
  updateBizInvoiceApply?: Maybe<MutationResponse>;
  addBizInvoiceApply?: Maybe<MutationResponse>;
  deleteBizInvoiceApply?: Maybe<MutationResponse>;
  addbilltoInvoice?: Maybe<MutationResponse>;
  deleteInvorderBill?: Maybe<MutationResponse>;
  deleteInvIncome?: Maybe<MutationResponse>;
  deleteInvCost?: Maybe<MutationResponse>;
  updateBizPaySupplierApply?: Maybe<MutationResponse>;
  updateBizPayApplyNo?: Maybe<MutationResponse>;
  closePaysupplierApply?: Maybe<MutationResponse>;
  addBizPaySupplierApply?: Maybe<MutationResponse>;
  deleteBizPaySupplierApply?: Maybe<MutationResponse>;
  addApplytoPayapply?: Maybe<MutationResponse>;
  deletePaycost?: Maybe<MutationResponse>;
  updateCustomApplyBussinessInfo?: Maybe<MutationResponse>;
  abolishCustomApplyBussinessInfo?: Maybe<MutationResponse>;
  updateCustomApplyBussinessInfoList?: Maybe<MutationResponse>;
  deleteCustomApplyBussinessInfo?: Maybe<MutationResponse>;
  /** 删除当前所有的清单 */
  deleteAllCustomApplyBussiness?: Maybe<MutationResponse>;
  /** 更新当前物料凭证号的后续状态 */
  freshMaterialFOData?: Maybe<MutationResponse>;
  CombineFO?: Maybe<MutationResponse>;
  testConfig?: Maybe<MutationResponse>;
  updateMaterialFOData?: Maybe<MutationResponse>;
  preProcessMaterialFOData?: Maybe<MutationResponse>;
  genYsPass?: Maybe<MutationResponse>;
  genYsPackage?: Maybe<MutationResponse>;
  /** 取消流转指令下发 */
  cancelMaterialFOData?: Maybe<MutationResponse>;
  updateYitihuaPackageList?: Maybe<MutationResponse>;
  deleteYitihuaPackageList?: Maybe<MutationResponse>;
  updateTensorSpAreaPackageList?: Maybe<MutationResponse>;
  deleteTensorSpAreaPackageList?: Maybe<MutationResponse>;
  updatePartNumberSAPInventoryPrice?: Maybe<MutationResponse>;
  deletePartNumberSAPInventoryPrice?: Maybe<MutationResponse>;
  updateTmsCustApplyLog?: Maybe<MutationResponse>;
  deleteTmsCustApplyLog?: Maybe<MutationResponse>;
  updateTmsPassPortOrder?: Maybe<MutationResponse>;
  updateTmsPassPortOrderPic?: Maybe<MutationResponse>;
  importPassPortOrder?: Maybe<MutationResponse>;
  genOppositePass?: Maybe<MutationResponse>;
  copyPassport?: Maybe<MutationResponse>;
  submitTmsPassPortOrder?: Maybe<MutationResponse>;
  passPortRevert?: Maybe<MutationResponse>;
  passPortDelete?: Maybe<MutationResponse>;
  deleteTmsPassPortOrder?: Maybe<MutationResponse>;
  emptyCommand?: Maybe<MutationResponse>;
  gwImportywInfoByPassport?: Maybe<MutationResponse>;
  gwUserImportywInfoByPassport?: Maybe<MutationResponse>;
  gwImportreleaseByPassport?: Maybe<MutationResponse>;
  gwUserImportreleaseByPassport?: Maybe<MutationResponse>;
  genpatchPackage?: Maybe<MutationResponse>;
  genUserpatchPackage?: Maybe<MutationResponse>;
  getPassportStatus?: Maybe<MutationResponse>;
  getPackageStatus?: Maybe<MutationResponse>;
  getCurrPackageStatus?: Maybe<MutationResponse>;
  freshallPackageStatus?: Maybe<MutationResponse>;
  getAllPackageStatus?: Maybe<MutationResponse>;
  revertPassPort?: Maybe<MutationResponse>;
  applyPassPortCust?: Maybe<MutationResponse>;
  statisTodayPassport?: Maybe<MutationResponse>;
  updateSpPassport?: Maybe<MutationResponse>;
  deleteSpPassport?: Maybe<MutationResponse>;
  getSpPassporStock?: Maybe<MutationResponse>;
  updateHsCustomsRegulation?: Maybe<MutationResponse>;
  deleteHsCustomsRegulation?: Maybe<MutationResponse>;
  updateTmsProduceWarehouse?: Maybe<MutationResponse>;
  deleteTmsProduceWarehouse?: Maybe<MutationResponse>;
  updateProduceWarehouse?: Maybe<MutationResponse>;
  uploadProduce?: Maybe<MutationResponse>;
  uploadsubProduce?: Maybe<MutationResponse>;
  updateTmsOrderInfo?: Maybe<MutationResponse>;
  deleteTmsOrderInfo?: Maybe<MutationResponse>;
  updateTmsorderDepart?: Maybe<MutationResponse>;
};


export type MutationUpdateAppCompanyArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<AppCompanyInput>;
};


export type MutationDeleteAppCompanyArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateAppAppNameArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<AppAppNameInput>;
};


export type MutationDeleteAppAppNameArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateAppRolePolicyArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<AppRolePolicyInput>;
};


export type MutationDeleteAppRolePolicyArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateAppRolesArgs = {
  appName?: Maybe<Scalars['String']>;
  appRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationUpdateAppDepartmentsFromDingArgs = {
  appName?: Maybe<Scalars['String']>;
  appDeparts?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};


export type MutationUpdateAppDepartmentArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<AppDepartmentInput>;
};


export type MutationUpdateAppUserInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<AppUserInfoInput>;
};


export type MutationUpdateAppUserInfoProfileArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};


export type MutationDeleteAppUserInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationScanQrLoginArgs = {
  openID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  QRID?: Maybe<Scalars['String']>;
  electronSign?: Maybe<Scalars['String']>;
  socketID?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  headImgUrl?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  mobileNo?: Maybe<Scalars['String']>;
  departments?: Maybe<Array<Maybe<KeyValueInput>>>;
};


export type MutationDingDingLoginArgs = {
  userinfo?: Maybe<AppUserInfoInput>;
  dingUnionEmpExt?: Maybe<Scalars['JSON']>;
};


export type MutationVerifySmsCodeArgs = {
  code?: Maybe<Scalars['String']>;
  mobileNo?: Maybe<Scalars['String']>;
  openId?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
};


export type MutationAutoChatLoginArgs = {
  openId?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
};


export type MutationCalChatBillArgs = {
  openId?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
};


export type MutationVerifyChatSmsCodeArgs = {
  code?: Maybe<Scalars['String']>;
  mobileNo?: Maybe<Scalars['String']>;
  openId?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
};


export type MutationLogoutMobileByOpenIdArgs = {
  openId?: Maybe<Scalars['String']>;
  mobileNo?: Maybe<Scalars['String']>;
};


export type MutationUpdateAppComponentConfigArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<AppComponentConfigInput>;
};


export type MutationDeleteAppComponentConfigArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateFunJobInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<FunJobInfoInput>;
};


export type MutationDeleteFunJobInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateFunTaskDetailInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<FunTaskDetailInfoInput>;
};


export type MutationDeleteFunTaskDetailInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateJobStatusArgs = {
  jobId?: Maybe<Scalars['ID']>;
};


export type MutationExecSingleTaskArgs = {
  taskId?: Maybe<Scalars['ID']>;
};


export type MutationExecJobErrorTasksArgs = {
  jobId?: Maybe<Scalars['String']>;
  jobName?: Maybe<Scalars['String']>;
};


export type MutationUpdateOrderFileInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<OrderFileInfoInput>;
};


export type MutationDeleteOrderFileInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateOrderPicInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<OrderPicInfoInput>;
};


export type MutationDeleteOrderPicInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateAttachFileTagArgs = {
  id?: Maybe<Scalars['ID']>;
  pageNo?: Maybe<Scalars['Int']>;
  attFileTag?: Maybe<KeyValueInput>;
};


export type MutationBatchupdateAttachFileTagArgs = {
  input?: Maybe<Array<Maybe<OrderPicInfoInput>>>;
};


export type MutationGenerateAttachPdfArgs = {
  input?: Maybe<Array<Maybe<KeyValueInput>>>;
  orderNo?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
};


export type MutationDrawpicArgs = {
  input?: Maybe<OrderFileInfoInput>;
};


export type MutationDrawpicfromprintArgs = {
  input?: Maybe<PrintFileInfoInput>;
};


export type MutationDrawpicfromallprintArgs = {
  orderNo?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  CCCinvoiceNo?: Maybe<Scalars['String']>;
  energyinvoiceNo?: Maybe<Scalars['String']>;
};


export type MutationStockpicArgs = {
  input?: Maybe<OrderFileInfoInput>;
};


export type MutationAuditWithAttFileArgs = {
  input?: Maybe<OrderFileInfoInput>;
  auditPerson?: Maybe<Scalars['String']>;
  passResult?: Maybe<Scalars['Boolean']>;
  openID?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
};


export type MutationUploadOrderFileArgs = {
  files: Array<Maybe<Scalars['Upload']>>;
  attDocType?: Maybe<KeyValueInput>;
  refNo?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type MutationUploadBgdzArgs = {
  refNo?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
};


export type MutationUpdatexportOrderPicInfosArgs = {
  inputPics?: Maybe<Array<Maybe<OrderPicInfoInput>>>;
};


export type MutationUpdatexportOrderFileInfoArgs = {
  orderNo?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  seqNo?: Maybe<Scalars['Int']>;
  fileName?: Maybe<Scalars['String']>;
  OSSPath?: Maybe<Scalars['String']>;
  fileTypeName?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};


export type MutationDeletexportOrderFileInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationSingleUploadArgs = {
  file: Scalars['Upload'];
};


export type MutationUpdateLabelsArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TrainDataInfoInput>;
};


export type MutationUpdateDataTypeArgs = {
  sType?: Maybe<Scalars['String']>;
};


export type MutationUpdateArgs = {
  condition?: Maybe<Scalars['JSON']>;
  updata?: Maybe<Scalars['JSON']>;
};


export type MutationAddPageToOcrSampleArgs = {
  ossPath?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
  block?: Maybe<Scalars['JSON']>;
};


export type MutationUpdateDecModHeadDutyArgs = {
  dutyType?: Maybe<Scalars['String']>;
  dutyPerson?: Maybe<Scalars['String']>;
  decModSeqNo?: Maybe<Scalars['String']>;
  processStatus?: Maybe<Scalars['String']>;
  process_instance_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};


export type MutationUpdateDecModHeadInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<DecModHeadInfoInput>;
};


export type MutationUpdateBizCompanyMemoArgs = {
  memeo?: Maybe<Scalars['String']>;
};


export type MutationUpdateBizCompanyArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<BizCompanyInfoInput>;
};


export type MutationDeleteBizCompanyArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdatevalidationruleArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<ValidationruleInput>;
};


export type MutationUpdatehgcheckArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<HgcheckInput>;
};


export type MutationUpdatechacuoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<ChacuoInput>;
};


export type MutationCallTmpApiArgs = {
  apiUrl?: Maybe<Scalars['String']>;
  paraData?: Maybe<Scalars['JSON']>;
};


export type MutationCallouterApiArgs = {
  apiUrl?: Maybe<Scalars['String']>;
  paraData?: Maybe<Scalars['JSON']>;
};


export type MutationUpdateKddPreOrderDecArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<KddPreOrderInput>;
};


export type MutationUpdateOrderStatusArgs = {
  id?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
  statusName?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  data?: Maybe<Scalars['JSON']>;
  message?: Maybe<Scalars['String']>;
};


export type MutationUpdateKddDecStatusArgs = {
  id: Scalars['ID'];
  currStatusCode?: Maybe<Scalars['Int']>;
  nextStatusName: Scalars['String'];
  changeid?: Maybe<Scalars['String']>;
  changememo?: Maybe<Scalars['String']>;
};


export type MutationAutoShenheDecStatusArgs = {
  id: Scalars['ID'];
  currStatusCode?: Maybe<Scalars['Int']>;
  nextStatusName: Scalars['String'];
  changeid?: Maybe<Scalars['String']>;
  changememo?: Maybe<Scalars['String']>;
};


export type MutationDeletekddpreorderArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationRemovekddpreorderArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationManualSplitPreOrderArgs = {
  orderNo?: Maybe<Scalars['String']>;
  sepSeqno?: Maybe<Scalars['String']>;
  selGNos?: Maybe<Array<Maybe<Scalars['Int']>>>;
  mianbaoFlag?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
};


export type MutationRemanualSplitPreOrderArgs = {
  orderNo?: Maybe<Scalars['String']>;
  sepSeqno?: Maybe<Scalars['String']>;
  selGNos?: Maybe<Array<Maybe<Scalars['Int']>>>;
  mianbaoFlag?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
};


export type MutationWeiwsplitArgs = {
  orderNo?: Maybe<Scalars['String']>;
  sepSeqno?: Maybe<Scalars['String']>;
  selGNos?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type MutationGenStockBySelsArgs = {
  orderNo?: Maybe<Scalars['String']>;
  sepSeqno?: Maybe<Scalars['String']>;
  selGNos?: Maybe<Array<Maybe<Scalars['Int']>>>;
  mianbaoFlag?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
};


export type MutationGenStockBylistArgs = {
  sepSeqno?: Maybe<Scalars['String']>;
  selGNos?: Maybe<Array<Maybe<Scalars['String']>>>;
  mianbaoFlag?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
};


export type MutationGenExmianbiaoArgs = {
  exid?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
  selGNos?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type MutationGenExstockArgs = {
  exid?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
  selGNos?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type MutationSendtoXVisionArgs = {
  orderNo?: Maybe<Scalars['String']>;
};


export type MutationSendToSingleWindowArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  orderNo?: Maybe<Scalars['String']>;
};


export type MutationDirectZhidanArgs = {
  showSepNo?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  mianbaoFlag?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
};


export type MutationDirectZhidanfromOriginArgs = {
  showSepNo?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


export type MutationCopyKddArgs = {
  showSepNo?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
  copyid?: Maybe<Scalars['String']>;
};


export type MutationUpdateKddPreOrderTemplateArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<KddPreOrderTemplateInput>;
};


export type MutationDeleteKddPreOrderTemplateArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateproductmanageArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<ProductmanageInput>;
};


export type MutationDeleteproductmanageArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationBatchupdateprodArgs = {
  prodlist?: Maybe<Array<Maybe<ProductmanageInput>>>;
  upmemo?: Maybe<Scalars['String']>;
};


export type MutationUpdateDingProcessEventArgs = {
  event?: Maybe<DingEventInput>;
  processInstance?: Maybe<DingProcessInstanceInput>;
};


export type MutationUpdateTitMailArgs = {
  id?: Maybe<Scalars['ID']>;
  orderNo?: Maybe<Scalars['String']>;
  orderOutNo?: Maybe<Scalars['String']>;
  mailId?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
  appCompanyMini?: Maybe<KeyValueInput>;
  coUserMini?: Maybe<KeyValueInput>;
  headerLines?: Maybe<Array<Maybe<KeyValueInput>>>;
  messageId?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  mailStatus?: Maybe<Scalars['String']>;
};


export type MutationDeleteTitMailArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationReFetchDepartMailArgs = {
  user?: Maybe<Scalars['String']>;
};


export type MutationUpdateDecStatusFromMailArgs = {
  messageId?: Maybe<Scalars['String']>;
};


export type MutationUpdatexportProductCompanyArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<ProductCompanyInput>;
};


export type MutationDeletexportProductCompanyArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateXportOrderMainArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<OrderMainInput>;
};


export type MutationDeleteXportOrderMainArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationNewOrderFromMailArgs = {
  orderInfo?: Maybe<OrderMainInput>;
};


export type MutationCopyOrderFromOriginArgs = {
  billNo?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
};


export type MutationPassmailArgs = {
  mailId?: Maybe<Scalars['String']>;
};


export type MutationUpdateDataChangeLogInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<DataChangeLogInfoInput>;
};


export type MutationWatermarkPicArgs = {
  input?: Maybe<Array<Maybe<StampPicsInput>>>;
};


export type MutationUpdatePurchaseContractArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<PurchaseContractInput>;
};


export type MutationDeletePurchaseContracArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateAeoHeavyeqpArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<AeoHeavyeqpInput>;
};


export type MutationUpdatetaxInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TaxInfoInput>;
};


export type MutationDeletetaxInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationTestSingleWindowDecDetailupArgs = {
  test?: Maybe<Scalars['String']>;
};


export type MutationUpdateOwnerManageArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<OwnerManageInput>;
};


export type MutationDeleteOwnerManageArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateRpaJobInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<RpaJobInfoInput>;
};


export type MutationDeleteRpaJobInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationLinkJobInfoByCodeArgs = {
  jobCode?: Maybe<Scalars['String']>;
  recordName?: Maybe<Scalars['String']>;
  clientID?: Maybe<Scalars['String']>;
};


export type MutationReportJobInfoProcessDetailArgs = {
  input?: Maybe<RpaJobProcessDetailInput>;
};


export type MutationResetProcessDetailArgs = {
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
};


export type MutationUpdateProcessDetailArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<RpaJobProcessDetailInput>;
};


export type MutationResetAllProcessDetailArgs = {
  jobNo?: Maybe<Scalars['String']>;
};


export type MutationUpdatewareHouseArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<ProductmanageInput>;
};


export type MutationDeletewareHouseArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateGuanmaoProductArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<GuanmaoProductInput>;
};


export type MutationImportSapprodArgs = {
  ossPath?: Maybe<Scalars['String']>;
};


export type MutationSycroSapprodArgs = {
  prodls?: Maybe<Array<Maybe<GuanmaoProductInput>>>;
  shenheid?: Maybe<Scalars['String']>;
  specsign?: Maybe<Scalars['String']>;
};


export type MutationAddNetTransitCarArgs = {
  businessNos?: Maybe<Scalars['String']>;
};


export type MutationUpdateNetTransitCarArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<NetTransitCarInput>;
};


export type MutationUpdateSpAreaPortReleaseArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<SpAreaPortReleaseInput>;
};


export type MutationDeleteSpAreaPortReleaseArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateSpAreaInventoryArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<SpAreaInventoryInput>;
};


export type MutationDeleteSpAreaInventoryArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateOssFileInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<OssFileInfoInput>;
};


export type MutationDeleteOssFileInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationNewOssFileInfoDicArgs = {
  parentPath?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
};


export type MutationUploadFileInfoArgs = {
  file: Scalars['Upload'];
  parentPath?: Maybe<Scalars['String']>;
};


export type MutationUpdateAeoValidateRuleArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<AeoValidateRuleInput>;
};


export type MutationDeleteAeoValidateRuleArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateSpAreaStatArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<SpAreaStatInput>;
};


export type MutationDeleteSpAreaStatArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateTmsTransApplyArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsTransApplyInput>;
};


export type MutationDeleteTmsTransApplyArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationGenTmsApplyPdfArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationGenTmsBarInfoArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationPushDingMssageArgs = {
  openids?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};


export type MutationUpdateWmsInwardPlanArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<WmsInwardPlanInput>;
};


export type MutationDeleteWmsInwardPlanArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateScmSamcTrackInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<ScmSamcTrackInfoInput>;
};


export type MutationDeleteScmSamcTrackInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateDeliveryFlagArgs = {
  uplist?: Maybe<Array<Maybe<ScmSamcTrackInfoInput>>>;
};


export type MutationUpdatePremFlagArgs = {
  uplist?: Maybe<Array<Maybe<ScmSamcTrackInfoInput>>>;
};


export type MutationUpdateTmsUserAddressInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsUserAddressInfoInput>;
};


export type MutationUpdateTmsUserAddressInfobysessionArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsUserAddressInfoInput>;
  placeId?: Maybe<Scalars['String']>;
  session?: Maybe<Scalars['String']>;
};


export type MutationUpdateTmsUserAddressInfoDefaultArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsUserAddressInfoInput>;
};


export type MutationDeleteTmsUserAddressInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateTmsCustTransApplyArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsCustTransApplyInput>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationApplyCustModifyArgs = {
  id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['String']>;
  departId?: Maybe<Scalars['Int']>;
};


export type MutationTmpupdateTmsCustTransApplyArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsCustTransApplyInput>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationNocarupdateTmsCustTransApplyArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsCustTransApplyInput>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationCalCustTransApplyPriceArgs = {
  input?: Maybe<TmsCustTransApplyInput>;
};


export type MutationAfterupdateTmsCustTransApplyArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsCustTransApplyInput>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationWbsupdateTmsCustTransApplyArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsCustTransApplyInput>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationUpdateTmsCustTransApplyStockStatusArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsCustTransApplyInput>;
};


export type MutationUpdateTmsCustTransApplyStatusArgs = {
  tmsApplyNo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  upperson?: Maybe<Scalars['String']>;
  uppersonId?: Maybe<Scalars['String']>;
};


export type MutationFinishxyTmsCustTransApplyArgs = {
  tmsApplyNo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  upperson?: Maybe<Scalars['String']>;
  uppersonId?: Maybe<Scalars['String']>;
};


export type MutationXystartTmsCustTransApplyArgs = {
  tmsApplyNo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  upperson?: Maybe<Scalars['String']>;
  uppersonId?: Maybe<Scalars['String']>;
};


export type MutationNewfreshBillAndPurchaseArgs = {
  tmsApplyNo?: Maybe<Scalars['String']>;
};


export type MutationSubmitTmsCustTransApplyArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsCustTransApplyInput>;
  userId?: Maybe<Scalars['String']>;
  departId?: Maybe<Scalars['Int']>;
};


export type MutationSubmitTmsCustTransApplyWithFrArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsCustTransApplyInput>;
  userId?: Maybe<Scalars['String']>;
  departId?: Maybe<Scalars['Int']>;
  BGflag?: Maybe<Scalars['Boolean']>;
  FRflag?: Maybe<Scalars['Boolean']>;
};


export type MutationSubmitTmsCustTransApplyWithDateFrArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsCustTransApplyInput>;
  userId?: Maybe<Scalars['String']>;
  departId?: Maybe<Scalars['Int']>;
  BGflag?: Maybe<Scalars['Boolean']>;
  FRflag?: Maybe<Scalars['Boolean']>;
  datelist?: Maybe<Array<Maybe<Scalars['Date']>>>;
};


export type MutationTestAddTmsCustTransApplyWithFrArgs = {
  did?: Maybe<Scalars['String']>;
};


export type MutationBatchsubmitTmsCustTransApplyArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsCustTransApplyInput>;
  userId?: Maybe<Scalars['String']>;
  departId?: Maybe<Scalars['Int']>;
  datelist?: Maybe<Array<Maybe<Scalars['Date']>>>;
  trip?: Maybe<Scalars['String']>;
};


export type MutationDeleteTmsCustTransApplyArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationSubmitdeleteTmsCustTransApplyArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  departId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationSubmitProcCustTransApplyArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  departId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
  procType?: Maybe<Scalars['String']>;
};


export type MutationSubmitBatchProcCustTransApplyArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  departId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
  procType?: Maybe<Scalars['String']>;
};


export type MutationFillTmsCustTransApplyfromclipArgs = {
  cliptext?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationFillTmsCustTransApplyfromRobotArgs = {
  chattext?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationFillTmsCustTransApplyfromPicArgs = {
  ossUrl?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationRegTmsCustTransApplyfromclipArgs = {
  cliptext?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationFreeCarArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationConfirmDeleteTmsApplyInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationDenyDeleteTmsApplyInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationRevertTmsCustTransApplyArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationSavetransMemoArgs = {
  id?: Maybe<Scalars['ID']>;
  otherRequire?: Maybe<Scalars['String']>;
};


export type MutationUpdateCustomApplyTransGoodsArgs = {
  id?: Maybe<Scalars['ID']>;
  prodls?: Maybe<Array<Maybe<TmsGoodsDetailInput>>>;
};


export type MutationUpdateVehicleNoArgs = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  licenesNo?: Maybe<Scalars['String']>;
  carWt?: Maybe<Scalars['String']>;
};


export type MutationUpdateCustomApplyBussinessDetailArgs = {
  tmsApplyNo?: Maybe<Scalars['String']>;
  businessNo?: Maybe<Scalars['String']>;
};


export type MutationUpdateCustomBizDetailArgs = {
  tmsApplyNo?: Maybe<Scalars['String']>;
  orderstr?: Maybe<Scalars['String']>;
};


export type MutationUpdatesingleCustomBizDetailArgs = {
  boxNo?: Maybe<Scalars['String']>;
  tmsApplyNo?: Maybe<Scalars['String']>;
  materialFONo?: Maybe<Scalars['String']>;
  grossWeight?: Maybe<Scalars['Float']>;
  containerSize?: Maybe<Scalars['String']>;
  packNo?: Maybe<Scalars['Float']>;
  codeInfo?: Maybe<Scalars['String']>;
};


export type MutationFreshbillandPurchaseArgs = {
  tmsApplyNo?: Maybe<Scalars['String']>;
};


export type MutationUpdateTransVehicleInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TransVehicleInfoInput>;
};


export type MutationDeleteTransVehicleInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateRoadTransForwardingOrderArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<RoadTransForwardingOrderInput>;
};


export type MutationStartRoadTransForwardingOrderArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<RoadTransForwardingOrderInput>;
};


export type MutationGenRoadTransForwardingOrderArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<RoadTransForwardingOrderInput>;
};


export type MutationFinishRoadTransForwardingOrderArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<RoadTransForwardingOrderInput>;
};


export type MutationUpdateForwardingOrderStatusByfOrderNoArgs = {
  fOrderNo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};


export type MutationDeleteRoadTransForwardingOrderArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationDeleteRoadTransForwardingOrderWithMessageArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationSubmitForderPurchaseArgs = {
  fOrderNo?: Maybe<Scalars['String']>;
  payApplyNo?: Maybe<Scalars['String']>;
  payApplyId?: Maybe<Scalars['String']>;
  payApplyPerson?: Maybe<Scalars['String']>;
};


export type MutationPassForderPurchaseArgs = {
  fOrderNo?: Maybe<Scalars['String']>;
  invoiceApplyId?: Maybe<Scalars['String']>;
};


export type MutationRemoveForderPurchaseArgs = {
  tmsApplyNo?: Maybe<Scalars['String']>;
  invoiceApplyId?: Maybe<Scalars['String']>;
};


export type MutationRejectForderPurchaseArgs = {
  fOrderNo?: Maybe<Scalars['String']>;
  rejMemo?: Maybe<Scalars['String']>;
};


export type MutationCancleForderPurchaseArgs = {
  fOrderNo?: Maybe<Scalars['String']>;
};


export type MutationGetCarReleselocationArgs = {
  carNo?: Maybe<Scalars['String']>;
};


export type MutationJudgePackageInfoArgs = {
  checkLocation?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  iePack?: Maybe<Scalars['String']>;
  workPlace?: Maybe<Scalars['String']>;
  ieType?: Maybe<Scalars['String']>;
};


export type MutationGetShowPackageInfoArgs = {
  checkLocation?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  iePack?: Maybe<Scalars['String']>;
  workPlace?: Maybe<Scalars['String']>;
  ieType?: Maybe<Scalars['String']>;
};


export type MutationFreshPackageInfoArgs = {
  packageListNo?: Maybe<Scalars['String']>;
  taskNo?: Maybe<Scalars['String']>;
  missionName?: Maybe<Scalars['String']>;
  workPlace?: Maybe<Scalars['String']>;
  missionPlace?: Maybe<Scalars['String']>;
};


export type MutationUpdateTransTaskOrderArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TransTaskOrderInput>;
};


export type MutationFinishTransTaskOrderArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TransTaskOrderInput>;
};


export type MutationUpdateTransTaskOrderlistArgs = {
  id?: Maybe<Scalars['ID']>;
  tasklist?: Maybe<Array<Maybe<TransTaskOrderInput>>>;
};


export type MutationUpdateTransServiceOrderlistArgs = {
  id?: Maybe<Scalars['ID']>;
  taskInfo?: Maybe<TransTaskOrderInput>;
};


export type MutationUpdateTransTaskForklistArgs = {
  id?: Maybe<Scalars['ID']>;
  tasklist?: Maybe<Array<Maybe<TransTaskOrderInput>>>;
};


export type MutationDeleteTransTaskOrderArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationExcuteTransTaskOrderArgs = {
  taskNo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  checkLocation?: Maybe<Scalars['String']>;
};


export type MutationDriverTaskCheckInArgs = {
  missionName?: Maybe<Scalars['String']>;
  missionPlace?: Maybe<Scalars['String']>;
  carNo?: Maybe<Scalars['String']>;
  checkLocation?: Maybe<Scalars['String']>;
  applyNo?: Maybe<Scalars['String']>;
  gpsLocation?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['ID']>;
  inOutFlag?: Maybe<Scalars['String']>;
  checkPerson?: Maybe<Scalars['String']>;
  checkPersonTel?: Maybe<Scalars['String']>;
  missionPic?: Maybe<Array<Maybe<Scalars['String']>>>;
  checkAddress?: Maybe<Scalars['String']>;
};


export type MutationMiodriverTaskCheckInArgs = {
  missionName?: Maybe<Scalars['String']>;
  missionPlace?: Maybe<Scalars['String']>;
  carNo?: Maybe<Scalars['String']>;
  checkLocation?: Maybe<Scalars['String']>;
  applyNo?: Maybe<Scalars['String']>;
  gpsLocation?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['ID']>;
  inOutFlag?: Maybe<Scalars['String']>;
  checkPerson?: Maybe<Scalars['String']>;
  checkPersonTel?: Maybe<Scalars['String']>;
  missionPic?: Maybe<Array<Maybe<Scalars['String']>>>;
  checkAddress?: Maybe<Scalars['String']>;
  packageListNo?: Maybe<Scalars['String']>;
};


export type MutationMiodriverExceptionCheckInArgs = {
  missionName?: Maybe<Scalars['String']>;
  missionPlace?: Maybe<Scalars['String']>;
  carNo?: Maybe<Scalars['String']>;
  checkLocation?: Maybe<Scalars['String']>;
  applyNo?: Maybe<Scalars['String']>;
  gpsLocation?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['ID']>;
  inOutFlag?: Maybe<Scalars['String']>;
  checkPerson?: Maybe<Scalars['String']>;
  checkPersonTel?: Maybe<Scalars['String']>;
  missionPic?: Maybe<Array<Maybe<Scalars['String']>>>;
  checkAddress?: Maybe<Scalars['String']>;
  Pmreason?: Maybe<Scalars['String']>;
  Pmarea?: Maybe<Scalars['String']>;
  PmcargoInfo?: Maybe<Scalars['String']>;
  Pmdirection?: Maybe<Scalars['String']>;
};


export type MutationDriverTaskFetchArgs = {
  missionName?: Maybe<Scalars['String']>;
  missionPlace?: Maybe<Scalars['String']>;
  carNo?: Maybe<Scalars['String']>;
  checkLocation?: Maybe<Scalars['String']>;
  applyNo?: Maybe<Scalars['String']>;
  gpsLocation?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['ID']>;
  inOutFlag?: Maybe<Scalars['String']>;
  checkPerson?: Maybe<Scalars['String']>;
  checkPersonTel?: Maybe<Scalars['String']>;
  missionPic?: Maybe<Array<Maybe<Scalars['String']>>>;
  checkAddress?: Maybe<Scalars['String']>;
  packageListNo?: Maybe<Scalars['String']>;
};


export type MutationRepairPackageInfoArgs = {
  missionName?: Maybe<Scalars['String']>;
  missionPlace?: Maybe<Scalars['String']>;
  carNo?: Maybe<Scalars['String']>;
  checkLocation?: Maybe<Scalars['String']>;
  applyNo?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['ID']>;
  inOutFlag?: Maybe<Scalars['String']>;
  checkPerson?: Maybe<Scalars['String']>;
  checkPersonTel?: Maybe<Scalars['String']>;
};


export type MutationForcerepairPackageInfoArgs = {
  checkArea?: Maybe<Scalars['String']>;
  taskNo?: Maybe<Scalars['String']>;
  inOutFlag?: Maybe<Scalars['String']>;
};


export type MutationAbolishPackageInfoArgs = {
  packageListNo?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
};


export type MutationTestTaskInfoArgs = {
  taskId?: Maybe<Scalars['String']>;
};


export type MutationTestDistanceArgs = {
  gpsLocation?: Maybe<Scalars['String']>;
  checkLocation?: Maybe<Scalars['String']>;
};


export type MutationFetchPackageInfoArgs = {
  missionid?: Maybe<Scalars['String']>;
  vehicleCardNo?: Maybe<Scalars['String']>;
};


export type MutationCreateEmptyYithuaPackageListArgs = {
  area?: Maybe<Scalars['String']>;
  vehicleNo?: Maybe<Scalars['String']>;
  vehicleWt?: Maybe<Scalars['Float']>;
  vehicleFrameWt?: Maybe<Scalars['Float']>;
  tmsApplyNo?: Maybe<Scalars['String']>;
  iePack?: Maybe<Scalars['String']>;
};


export type MutationUpdateTmsSupplierVehicleInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsSupplierVehicleInfoInput>;
};


export type MutationDeleteTmsSupplierVehicleInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateTmsSupplierDriverInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsSupplierDriverInfoInput>;
};


export type MutationDeleteTmsSupplierDriverInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateTmsTransMissionInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsTransMissionInfoInput>;
};


export type MutationDeleteTmsTransMissionInfoByPlaceArgs = {
  taskNo?: Maybe<Scalars['String']>;
  missionName?: Maybe<Scalars['String']>;
  missionPlace?: Maybe<Scalars['String']>;
};


export type MutationDeleteTmsTransMissionInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationCheckTmsTransMissionInfoArgs = {
  checkLocation?: Maybe<Scalars['String']>;
  checkDesc?: Maybe<Scalars['String']>;
  checkPic?: Maybe<Array<Maybe<Scalars['String']>>>;
  openId?: Maybe<Scalars['String']>;
  input?: Maybe<TmsTransMissionInfoInput>;
};


export type MutationUpdateMissionCheckRecordArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<MissionCheckRecordInput>;
};


export type MutationDeleteMissionCheckRecordArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateServiceBillDetailInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<ServiceBillDetailInfoInput>;
};


export type MutationDeleteServiceBillDetailInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationApplyBillDetailInfoArgs = {
  billDetailId?: Maybe<Scalars['ID']>;
  chargeItemCode?: Maybe<Scalars['String']>;
  chargeName?: Maybe<Scalars['String']>;
  chargeType?: Maybe<Scalars['String']>;
  chargeUnit?: Maybe<Scalars['String']>;
  chargePrice?: Maybe<Scalars['String']>;
  chargeQty?: Maybe<Scalars['String']>;
  fOrderNo?: Maybe<Scalars['String']>;
};


export type MutationUpdateServicePurchaseDetailInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<ServicePurchaseDetailInfoInput>;
};


export type MutationDeleteServicePurchaseDetailInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationApplyPurchaseDetailInfoArgs = {
  orderInfo?: Maybe<RoadTransForwardingOrderInput>;
  chargeItemCode?: Maybe<Scalars['String']>;
  chargeName?: Maybe<Scalars['String']>;
  chargeType?: Maybe<Scalars['String']>;
  chargeUnit?: Maybe<Scalars['String']>;
  chargePrice?: Maybe<Scalars['String']>;
  chargeQty?: Maybe<Scalars['String']>;
  vehicleCardNo?: Maybe<Scalars['String']>;
  applyuserId?: Maybe<Scalars['String']>;
  createuserId?: Maybe<Scalars['String']>;
  purchaseItemId?: Maybe<Scalars['ID']>;
  dfflag?: Maybe<Scalars['Boolean']>;
};


export type MutationModifyTmsCostArgs = {
  elementPrice?: Maybe<Scalars['Float']>;
  elementQty?: Maybe<Scalars['Float']>;
  elementId?: Maybe<Scalars['String']>;
  costPrice?: Maybe<Scalars['Float']>;
  costQty?: Maybe<Scalars['Float']>;
  costId?: Maybe<Scalars['String']>;
  servicePrice?: Maybe<Scalars['Float']>;
  serviceQty?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['String']>;
};


export type MutationAddBillFromPurchaseArgs = {
  fOrderNo?: Maybe<Scalars['String']>;
};


export type MutationUpdateBizSalesContractDetailArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<BizSalesContractDetailInput>;
};


export type MutationDeleteBizSalesContractDetailArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateBizSalesContractServiceArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<BizSalesContractServiceInput>;
};


export type MutationDeleteBizSalesContractServiceArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateBizSupplierContractDetailArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<BizSupplierContractDetailInput>;
};


export type MutationDeleteBizSupplierContractDetailArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateCustBillInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<CustBillInfoInput>;
};


export type MutationDeleteCustBillInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationAddApplytoBillArgs = {
  id?: Maybe<Scalars['ID']>;
  applyls?: Maybe<Array<Maybe<TmsCustTransApplyInput>>>;
};


export type MutationDeleteorderBillArgs = {
  id?: Maybe<Scalars['ID']>;
  orderls?: Maybe<Array<Maybe<BillOrderDataInput>>>;
};


export type MutationDeleteIncomeArgs = {
  id?: Maybe<Scalars['ID']>;
  orderls?: Maybe<Array<Maybe<ServiceBillDetailInfoInput>>>;
};


export type MutationDeleteCostArgs = {
  id?: Maybe<Scalars['ID']>;
  orderls?: Maybe<Array<Maybe<ServicePurchaseDetailInfoInput>>>;
};


export type MutationUpdateBizInvoiceApplyArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<BizInvoiceApplyInput>;
};


export type MutationAddBizInvoiceApplyArgs = {
  input?: Maybe<BizInvoiceApplyInput>;
};


export type MutationDeleteBizInvoiceApplyArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationAddbilltoInvoiceArgs = {
  id?: Maybe<Scalars['ID']>;
  billNo?: Maybe<Scalars['String']>;
  feels?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationDeleteInvorderBillArgs = {
  id?: Maybe<Scalars['ID']>;
  orderls?: Maybe<Array<Maybe<BillOrderDataInput>>>;
};


export type MutationDeleteInvIncomeArgs = {
  id?: Maybe<Scalars['ID']>;
  orderls?: Maybe<Array<Maybe<ServiceBillDetailInfoInput>>>;
};


export type MutationDeleteInvCostArgs = {
  id?: Maybe<Scalars['ID']>;
  orderls?: Maybe<Array<Maybe<ServicePurchaseDetailInfoInput>>>;
};


export type MutationUpdateBizPaySupplierApplyArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<BizPaySupplierApplyInput>;
};


export type MutationUpdateBizPayApplyNoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<BizPaySupplierApplyInput>;
};


export type MutationClosePaysupplierApplyArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationAddBizPaySupplierApplyArgs = {
  input?: Maybe<BizPaySupplierApplyInput>;
};


export type MutationDeleteBizPaySupplierApplyArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationAddApplytoPayapplyArgs = {
  id?: Maybe<Scalars['ID']>;
  applyls?: Maybe<Array<Maybe<TmsCustTransApplyInput>>>;
};


export type MutationDeletePaycostArgs = {
  id?: Maybe<Scalars['ID']>;
  orderls?: Maybe<Array<Maybe<ServicePurchaseDetailInfoInput>>>;
};


export type MutationUpdateCustomApplyBussinessInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<CustomApplyBussinessInfoInput>;
};


export type MutationAbolishCustomApplyBussinessInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<CustomApplyBussinessInfoInput>;
};


export type MutationUpdateCustomApplyBussinessInfoListArgs = {
  prodls?: Maybe<Array<Maybe<CustomApplyBussinessInfoInput>>>;
};


export type MutationDeleteCustomApplyBussinessInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationDeleteAllCustomApplyBussinessArgs = {
  tmsApplyNo?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};


export type MutationFreshMaterialFoDataArgs = {
  materialFONo?: Maybe<Scalars['String']>;
  tmsApplyNo?: Maybe<Scalars['String']>;
  limitDay?: Maybe<Scalars['Float']>;
  specsign?: Maybe<Scalars['String']>;
};


export type MutationCombineFoArgs = {
  materialFONos?: Maybe<Array<Maybe<Scalars['String']>>>;
  tmsApplyNo?: Maybe<Scalars['String']>;
  specsign?: Maybe<Scalars['String']>;
};


export type MutationTestConfigArgs = {
  aa?: Maybe<Scalars['String']>;
};


export type MutationUpdateMaterialFoDataArgs = {
  materialFONo?: Maybe<Scalars['String']>;
  tmsApplyNo?: Maybe<Scalars['String']>;
  specsign?: Maybe<Scalars['String']>;
};


export type MutationPreProcessMaterialFoDataArgs = {
  materialFONo?: Maybe<Scalars['String']>;
  tmsApplyNo?: Maybe<Scalars['String']>;
  specsign?: Maybe<Scalars['String']>;
};


export type MutationGenYsPassArgs = {
  materialFONo?: Maybe<Scalars['String']>;
  tmsApplyNo?: Maybe<Scalars['String']>;
  specsign?: Maybe<Scalars['String']>;
};


export type MutationGenYsPackageArgs = {
  materialFONos?: Maybe<Array<Maybe<Scalars['String']>>>;
  tmsApplyNo?: Maybe<Scalars['String']>;
  specsign?: Maybe<Scalars['String']>;
};


export type MutationCancelMaterialFoDataArgs = {
  materialFONo?: Maybe<Scalars['String']>;
  tmsApplyNo?: Maybe<Scalars['String']>;
};


export type MutationUpdateYitihuaPackageListArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<YitihuaPackageListInput>;
};


export type MutationDeleteYitihuaPackageListArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateTensorSpAreaPackageListArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TensorSpAreaPackageListInput>;
};


export type MutationDeleteTensorSpAreaPackageListArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdatePartNumberSapInventoryPriceArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<PartNumberSapInventoryPriceInput>;
};


export type MutationDeletePartNumberSapInventoryPriceArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateTmsCustApplyLogArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsCustApplyLogInput>;
};


export type MutationDeleteTmsCustApplyLogArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateTmsPassPortOrderArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsPassPortOrderInput>;
};


export type MutationUpdateTmsPassPortOrderPicArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsPassPortOrderInput>;
};


export type MutationImportPassPortOrderArgs = {
  excelPath?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  departName?: Maybe<Scalars['String']>;
  departId?: Maybe<Scalars['String']>;
};


export type MutationGenOppositePassArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsPassPortOrderInput>;
  genType?: Maybe<Scalars['String']>;
};


export type MutationCopyPassportArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsPassPortOrderInput>;
};


export type MutationSubmitTmsPassPortOrderArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsPassPortOrderInput>;
};


export type MutationPassPortRevertArgs = {
  id?: Maybe<Scalars['ID']>;
  memo?: Maybe<Scalars['String']>;
};


export type MutationPassPortDeleteArgs = {
  id?: Maybe<Scalars['ID']>;
  memo?: Maybe<Scalars['String']>;
};


export type MutationDeleteTmsPassPortOrderArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationEmptyCommandArgs = {
  orderNo?: Maybe<Scalars['String']>;
};


export type MutationGwImportywInfoByPassportArgs = {
  orderNo?: Maybe<Scalars['String']>;
  specsign?: Maybe<Scalars['String']>;
};


export type MutationGwUserImportywInfoByPassportArgs = {
  orderNo?: Maybe<Scalars['String']>;
  specsign?: Maybe<Scalars['String']>;
  proUser?: Maybe<Scalars['String']>;
  proUserId?: Maybe<Scalars['String']>;
};


export type MutationGwImportreleaseByPassportArgs = {
  orderNo?: Maybe<Scalars['String']>;
  specsign?: Maybe<Scalars['String']>;
};


export type MutationGwUserImportreleaseByPassportArgs = {
  orderNo?: Maybe<Scalars['String']>;
  specsign?: Maybe<Scalars['String']>;
  proUser?: Maybe<Scalars['String']>;
  proUserId?: Maybe<Scalars['String']>;
};


export type MutationGenpatchPackageArgs = {
  orderNo?: Maybe<Scalars['String']>;
  specsign?: Maybe<Scalars['String']>;
};


export type MutationGenUserpatchPackageArgs = {
  orderNo?: Maybe<Scalars['String']>;
  specsign?: Maybe<Scalars['String']>;
  proUser?: Maybe<Scalars['String']>;
  proUserId?: Maybe<Scalars['String']>;
};


export type MutationGetPassportStatusArgs = {
  orderNo?: Maybe<Scalars['String']>;
  specsign?: Maybe<Scalars['String']>;
};


export type MutationGetPackageStatusArgs = {
  orderNo?: Maybe<Scalars['String']>;
  specsign?: Maybe<Scalars['String']>;
};


export type MutationGetCurrPackageStatusArgs = {
  orderNo?: Maybe<Scalars['String']>;
  specsign?: Maybe<Scalars['String']>;
};


export type MutationGetAllPackageStatusArgs = {
  orderNo?: Maybe<Scalars['String']>;
  specsign?: Maybe<Scalars['String']>;
};


export type MutationRevertPassPortArgs = {
  orderNo?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
};


export type MutationApplyPassPortCustArgs = {
  orderNols?: Maybe<Array<Maybe<Scalars['String']>>>;
  proCust?: Maybe<Scalars['String']>;
  managerUser?: Maybe<Scalars['String']>;
  managerUserId?: Maybe<Scalars['String']>;
};


export type MutationStatisTodayPassportArgs = {
  managerUser?: Maybe<Scalars['String']>;
};


export type MutationUpdateSpPassportArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<SpPassportInput>;
};


export type MutationDeleteSpPassportArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationGetSpPassporStockArgs = {
  passPortDetail?: Maybe<TmsPassPortOrderInput>;
};


export type MutationUpdateHsCustomsRegulationArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<HsCustomsRegulationInput>;
};


export type MutationDeleteHsCustomsRegulationArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateTmsProduceWarehouseArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsProduceWarehouseInput>;
};


export type MutationDeleteTmsProduceWarehouseArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateProduceWarehouseArgs = {
  serialNo?: Maybe<Scalars['String']>;
  parentRefNo?: Maybe<Scalars['String']>;
  specsign?: Maybe<Scalars['String']>;
};


export type MutationUploadProduceArgs = {
  produceList?: Maybe<Array<Maybe<TmsProduceWarehouseInput>>>;
};


export type MutationUploadsubProduceArgs = {
  produceList?: Maybe<Array<Maybe<TmsProduceWarehouseInput>>>;
};


export type MutationUpdateTmsOrderInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<TmsOrderInfoInput>;
};


export type MutationDeleteTmsOrderInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
};


export type MutationUpdateTmsorderDepartArgs = {
  refNo?: Maybe<Scalars['String']>;
  departName?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
};

export type MutationResponse = {
  __typename?: 'MutationResponse';
  code?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  statusMessage?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

/** 区内流转业务 */
export type NetTransitCar = IDataInfo & {
  __typename?: 'NetTransitCar';
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  /** 车牌号 */
  carNo?: Maybe<Scalars['String']>;
  /** 提货仓库 */
  outWareHouseNo?: Maybe<Scalars['String']>;
  /** 目的仓库 */
  inWareHouseNo?: Maybe<Scalars['String']>;
  /** 毛重 */
  grossWt?: Maybe<Scalars['String']>;
  /** 输入清单号 */
  inputString?: Maybe<Scalars['String']>;
  /** 出仓业务编号 */
  outWhBussinessNos?: Maybe<Array<Maybe<GuanMaoBusinessTransit>>>;
  /** 进仓业务编号 */
  inWhBussinessNos?: Maybe<Array<Maybe<GuanMaoBusinessTransit>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type NetTransitCarInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  /** 车牌号 */
  carNo?: Maybe<Scalars['String']>;
  /** 提货仓库 */
  outWareHouseNo?: Maybe<Scalars['String']>;
  /** 目的仓库 */
  inWareHouseNo?: Maybe<Scalars['String']>;
  /** 毛重 */
  grossWt?: Maybe<Scalars['String']>;
  /** 出仓业务编号 */
  outWhBussinessNos?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  /** 出仓业务编号 */
  inWhBussinessNos?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type OcrSamplePage = {
  __typename?: 'OCRSamplePage';
  ossPath?: Maybe<Scalars['String']>;
};

export type ObjectChangeDesc = {
  __typename?: 'ObjectChangeDesc';
  /**
   * 存储对应的字段层级名字
   * ["FieldName"][1]["subFiled2"]
   */
  fieldLocation?: Maybe<Scalars['String']>;
  /**
   * 存储对应的字段层级名字
   * ["商品列表"]["第一项"][申报数量”]
   */
  fieldsDesc?: Maybe<Scalars['String']>;
  /** 原始值，可以是JSON件 originType */
  originValue?: Maybe<Scalars['String']>;
  /** 字段值的类型（JSON，String，Number，Bool） */
  originType?: Maybe<Scalars['String']>;
  /** 字段修改备注 */
  fieldChangeMemo?: Maybe<Scalars['String']>;
};

export type ObjectChangeDescInput = {
  /**
   * 存储对应的字段层级名字
   * ["FieldName"][1]["subFiled2"]
   */
  fieldLocation?: Maybe<Scalars['String']>;
  /**
   * 存储对应的字段层级名字
   * ["商品列表"]["第一项"][申报数量”]
   */
  fieldsDesc?: Maybe<Scalars['String']>;
  /** 原始值，可以是JSON件 originType */
  originValue?: Maybe<Scalars['String']>;
  /** 字段值的类型（JSON，String，Number，Bool） */
  originType?: Maybe<Scalars['String']>;
  /** 字段修改备注 */
  fieldChangeMemo?: Maybe<Scalars['String']>;
};

export type OcrLoction = {
  __typename?: 'OcrLoction';
  field?: Maybe<Scalars['String']>;
  orivalue?: Maybe<Scalars['String']>;
  conceptKey?: Maybe<Scalars['String']>;
  topLeftX?: Maybe<Scalars['Float']>;
  topLeftY?: Maybe<Scalars['Float']>;
  bottomRightX?: Maybe<Scalars['Float']>;
  bottomRightY?: Maybe<Scalars['Float']>;
  confidence?: Maybe<Scalars['Float']>;
};

export type OcrLoctionInput = {
  field?: Maybe<Scalars['String']>;
  orivalue?: Maybe<Scalars['String']>;
  conceptKey?: Maybe<Scalars['String']>;
  topLeftX?: Maybe<Scalars['Float']>;
  topLeftY?: Maybe<Scalars['Float']>;
  bottomRightX?: Maybe<Scalars['Float']>;
  bottomRightY?: Maybe<Scalars['Float']>;
  confidence?: Maybe<Scalars['Float']>;
};

/** Ocr 调用参数 */
export type OcrParaInput = {
  oem?: Maybe<Scalars['Int']>;
  lang?: Maybe<Array<Maybe<Scalars['String']>>>;
  border?: Maybe<Scalars['Int']>;
  border_y?: Maybe<Scalars['Int']>;
  psm?: Maybe<Scalars['Int']>;
};

export type OcrSampleBlock = {
  __typename?: 'OcrSampleBlock';
  /** img path */
  ossPath?: Maybe<Scalars['String']>;
  /** block:[[22,33],[33,33]] ti的contous */
  block?: Maybe<Scalars['JSON']>;
  /** 正确的值 */
  baseText?: Maybe<Scalars['String']>;
  /** 所属客户名称 */
  custName?: Maybe<Scalars['String']>;
  /** 字体高度 */
  fontHeight?: Maybe<Scalars['Int']>;
  /** 字体宽度 */
  fontWidth?: Maybe<Scalars['Int']>;
};

export type OrderFileInfo = IDataInfo & {
  __typename?: 'OrderFileInfo';
  appID?: Maybe<Scalars['String']>;
  /** 中间类型对应的原始文件 */
  originFileName?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  seqNo?: Maybe<Scalars['Int']>;
  fileName?: Maybe<Scalars['String']>;
  OSSPath?: Maybe<Scalars['String']>;
  fileTypeName?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  bucketName?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  pics?: Maybe<Array<Maybe<OrderPicInfo>>>;
};

export type OrderFileInfoInput = {
  appID?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  seqNo?: Maybe<Scalars['Int']>;
  fileName?: Maybe<Scalars['String']>;
  OSSPath?: Maybe<Scalars['String']>;
  fileTypeName?: Maybe<Scalars['String']>;
  originFileName?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  bucketName?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type OrderMainInput = {
  id?: Maybe<Scalars['ID']>;
  orderNo?: Maybe<Scalars['String']>;
  /** 业务类型 */
  orderType?: Maybe<KeyValueInput>;
  orderOutNo?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
  appCompanyMini?: Maybe<KeyValueInput>;
  coUserMini?: Maybe<KeyValueInput>;
  processRouteType?: Maybe<Scalars['String']>;
  processFiles?: Maybe<Array<Maybe<Scalars['ID']>>>;
  memo?: Maybe<Scalars['String']>;
  ieDate?: Maybe<Scalars['Date']>;
  statusCode?: Maybe<Scalars['Int']>;
  supplier?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
  xportYWType?: Maybe<Scalars['String']>;
  zhidantype?: Maybe<Scalars['String']>;
  statusName?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  preOrderTemplateName?: Maybe<Scalars['String']>;
  fileProcessPercent?: Maybe<Scalars['Int']>;
  departInfo?: Maybe<KeyValueInput>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  custRefNo?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  custCompanyMini?: Maybe<KeyValueInput>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  recTime?: Maybe<Scalars['Date']>;
  estFinishCustomDate?: Maybe<Scalars['Date']>;
  /** mail unique id */
  messageId?: Maybe<Scalars['String']>;
  attachFiles?: Maybe<Array<Maybe<KeyValueInput>>>;
  /** mail._id */
  mailId?: Maybe<Scalars['String']>;
  departName?: Maybe<Scalars['String']>;
  jobInfo?: Maybe<FunJobInfoInput>;
  processTaskList?: Maybe<Array<Maybe<ProcessTaskInfoInput>>>;
};

export type OrderPicInfo = IDataInfo & {
  __typename?: 'OrderPicInfo';
  id?: Maybe<Scalars['ID']>;
  orderNo?: Maybe<Scalars['String']>;
  OrginFileName?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  OSSPath?: Maybe<Scalars['String']>;
  compressedOSSPath?: Maybe<Scalars['String']>;
  seqNo?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  /** 压缩类型为compressd用于压缩， */
  FileType?: Maybe<Scalars['String']>;
  /** 随附单证 类型 */
  attFileTag?: Maybe<KeyValue>;
  /** 随附单证 pageNo */
  pageNo?: Maybe<Scalars['Int']>;
  totalAmount?: Maybe<Scalars['Float']>;
  GVFlag?: Maybe<Scalars['Int']>;
  errMark?: Maybe<Scalars['Int']>;
  filetypelabel?: Maybe<Scalars['Int']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  rotatedOSSPath?: Maybe<Scalars['String']>;
  rotatedDegree?: Maybe<Scalars['Float']>;
  contourJsonPath?: Maybe<Scalars['String']>;
  xvJSONPath?: Maybe<Scalars['String']>;
  rotatedXvJSONPath?: Maybe<Scalars['String']>;
};

export type OrderPicInfoInput = {
  id?: Maybe<Scalars['ID']>;
  orderNo?: Maybe<Scalars['String']>;
  /** 对应文件的唯一ossPath */
  OrginFileName?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  OSSPath?: Maybe<Scalars['String']>;
  compressedOSSPath?: Maybe<Scalars['String']>;
  /** 随附单证 类型 */
  attFileTag?: Maybe<KeyValueInput>;
  /** 随附单证 pageNo */
  pageNo?: Maybe<Scalars['Int']>;
  seqNo?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  FileType?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  GVFlag?: Maybe<Scalars['Int']>;
  errMark?: Maybe<Scalars['Int']>;
  filetypelabel?: Maybe<Scalars['Int']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  rotatedOSSPath?: Maybe<Scalars['String']>;
  rotatedDegree?: Maybe<Scalars['Float']>;
  contourJsonPath?: Maybe<Scalars['String']>;
  xvJSONPath?: Maybe<Scalars['String']>;
  rotatedXvJSONPath?: Maybe<Scalars['String']>;
};

export type OssFileInfo = {
  __typename?: 'OssFileInfo';
  id?: Maybe<Scalars['ID']>;
  refNo?: Maybe<Scalars['String']>;
  pathTitle?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  bucketName?: Maybe<Scalars['String']>;
  ossPath?: Maybe<Scalars['String']>;
  isDic?: Maybe<Scalars['Boolean']>;
  memo?: Maybe<Scalars['String']>;
  sourceDesc?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  md5str?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
};

export type OssFileInfoInput = {
  id?: Maybe<Scalars['ID']>;
  refNo?: Maybe<Scalars['String']>;
  pathTitle?: Maybe<Scalars['String']>;
  isDic?: Maybe<Scalars['Boolean']>;
  fileName?: Maybe<Scalars['String']>;
  bucketName?: Maybe<Scalars['String']>;
  ossPath?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  sourceDesc?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  md5str?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
};

export type OssPath = {
  __typename?: 'OssPath';
  title?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

/** 采购订单合同 供应商有缓存1？ */
export type OwnerManage = IDataInfo & {
  __typename?: 'OwnerManage';
  id?: Maybe<Scalars['ID']>;
  ownerName?: Maybe<Scalars['String']>;
  ownerCiqCode?: Maybe<Scalars['String']>;
  ownerCode?: Maybe<Scalars['String']>;
  promiseItems0?: Maybe<Scalars['String']>;
  promiseItems1?: Maybe<Scalars['String']>;
  promiseItems2?: Maybe<Scalars['String']>;
  promiseItems3?: Maybe<Scalars['String']>;
  promiseItems4?: Maybe<Scalars['String']>;
  consignorEname?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 采购订单合同 */
export type OwnerManageInput = {
  id?: Maybe<Scalars['ID']>;
  ownerName?: Maybe<Scalars['String']>;
  ownerCiqCode?: Maybe<Scalars['String']>;
  ownerCode?: Maybe<Scalars['String']>;
  promiseItems0?: Maybe<Scalars['String']>;
  promiseItems1?: Maybe<Scalars['String']>;
  promiseItems2?: Maybe<Scalars['String']>;
  promiseItems3?: Maybe<Scalars['String']>;
  promiseItems4?: Maybe<Scalars['String']>;
  consignorEname?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type PackageSasPassport = {
  __typename?: 'PackageSasPassport';
  /** 后台反填 */
  id?: Maybe<Scalars['ID']>;
  /** 后台反填 */
  pid?: Maybe<Scalars['Int']>;
  /** 卡口验放凭证编号 */
  sasPassportPreentNo?: Maybe<Scalars['String']>;
  /** 后台反填，见操作状态表 */
  sasOptStatus?: Maybe<Scalars['String']>;
  /** 卡口验放凭证出入库类型 */
  passportTypecd?: Maybe<Scalars['String']>;
  /** 进出标志代码 */
  ioTypecd?: Maybe<Scalars['String']>;
  /** 关区代码 */
  customCode?: Maybe<Scalars['String']>;
  /** 备注（存放卡口验放凭证id） */
  rmk?: Maybe<Scalars['String']>;
  /** 查验状态（1-需要查验，0-不需查验） */
  isInspection?: Maybe<Scalars['String']>;
  /** 卸货状态（0-未卸货，1-已卸货） */
  isDischarge?: Maybe<Scalars['String']>;
};

export type PackageSasPassportInput = {
  /** 后台反填 */
  id?: Maybe<Scalars['Int']>;
  /** 后台反填 */
  pid?: Maybe<Scalars['Int']>;
  /** 卡口验放凭证编号 */
  sasPassportPreentNo?: Maybe<Scalars['String']>;
  /** 后台反填，见操作状态表 */
  sasOptStatus?: Maybe<Scalars['String']>;
  /** 卡口验放凭证出入库类型 */
  passportTypecd?: Maybe<Scalars['String']>;
  /** 进出标志代码 */
  ioTypecd?: Maybe<Scalars['String']>;
  /** 关区代码 */
  customCode?: Maybe<Scalars['String']>;
  /** 备注（存放卡口验放凭证id） */
  rmk?: Maybe<Scalars['String']>;
  /** 查验状态（1-需要查验，0-不需查验） */
  isInspection?: Maybe<Scalars['String']>;
  /** 卸货状态（0-未卸货，1-已卸货） */
  isDischarge?: Maybe<Scalars['String']>;
};

export type ParaIdNameValue = {
  __typename?: 'ParaIdNameValue';
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  values?: Maybe<Scalars['JSON']>;
  paraType?: Maybe<Scalars['String']>;
};

export type ParaValue = {
  __typename?: 'ParaValue';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paraType?: Maybe<Scalars['String']>;
};

/** 一体化 卡口验放凭证 */
export type PartNumberSapInventoryPrice = IDataInfo & {
  __typename?: 'PartNumberSAPInventoryPrice';
  appID?: Maybe<Scalars['String']>;
  /** 料件编号 */
  partNumberSAP?: Maybe<Scalars['String']>;
  /** 价格币值 */
  currency?: Maybe<Scalars['String']>;
  /** 价格类型 */
  priceType?: Maybe<Scalars['String']>;
  /** 价格备注 */
  priceMemo?: Maybe<Scalars['String']>;
  /** 标准价格 */
  standardPrice?: Maybe<Scalars['Float']>;
  /** 周期价格 */
  cyclePrice?: Maybe<Scalars['Float']>;
  /** 项目 */
  project?: Maybe<Scalars['String']>;
  /** 物料类型 */
  materialType?: Maybe<Scalars['String']>;
  /** ValCl */
  valCl?: Maybe<Scalars['String']>;
  /** WBSElement */
  wbsElement?: Maybe<Scalars['String']>;
  /** 价格单位 */
  priceUnit?: Maybe<Scalars['String']>;
  /** 实际价格 */
  actualPrice?: Maybe<Scalars['Float']>;
  /** 周期单位价格 */
  cycleUnitPrice?: Maybe<Scalars['Float']>;
  /** 价格日期版本号 */
  priceDateVersion?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type PartNumberSapInventoryPriceInput = {
  /** 料件编号 */
  partNumberSAP?: Maybe<Scalars['String']>;
  /** 价格币值 */
  currency?: Maybe<Scalars['String']>;
  /** 价格类型 */
  priceType?: Maybe<Scalars['String']>;
  /** 价格备注 */
  priceMemo?: Maybe<Scalars['String']>;
  /** 标准价格 */
  standardPrice?: Maybe<Scalars['Float']>;
  /** 周期价格 */
  cyclePrice?: Maybe<Scalars['Float']>;
  /** 项目 */
  project?: Maybe<Scalars['String']>;
  /** 物料类型 */
  materialType?: Maybe<Scalars['String']>;
  /** ValCl */
  valCl?: Maybe<Scalars['String']>;
  /** WBSElement */
  wbsElement?: Maybe<Scalars['String']>;
  /** 价格单位 */
  priceUnit?: Maybe<Scalars['String']>;
  /** 实际价格 */
  actualPrice?: Maybe<Scalars['Float']>;
  /** 周期单位价格 */
  cycleUnitPrice?: Maybe<Scalars['Float']>;
  /** 价格日期版本号 */
  priceDateVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type ProcessTaskInfo = {
  __typename?: 'ProcessTaskInfo';
  /**
   * 单证中心
   * 快件
   * 空运普货
   * 海运
   * 仓库
   * 运输
   */
  ProcessTaskName?: Maybe<Scalars['String']>;
  /**
   * 单证
   * 报关
   * 仓储
   * 运输
   */
  ProcessType?: Maybe<Scalars['String']>;
};

export type ProcessTaskInfoInput = {
  /**
   * 单证中心
   * 快件
   * 空运普货
   * 海运
   * 仓库
   * 运输
   */
  ProcessTaskName?: Maybe<Scalars['String']>;
  /**
   * 单证
   * 报关
   * 仓储
   * 运输
   */
  ProcessType?: Maybe<Scalars['String']>;
};

export type ProductCompany = {
  __typename?: 'ProductCompany';
  id?: Maybe<Scalars['ID']>;
  supplierName?: Maybe<Scalars['String']>;
  suppplierEnName?: Maybe<Scalars['String']>;
  /** 报关单上发货人英文名称 */
  consignorEname?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
  appCompany?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  batchflag?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type ProductCompanyInput = {
  id?: Maybe<Scalars['ID']>;
  supplierName?: Maybe<Scalars['String']>;
  suppplierEnName?: Maybe<Scalars['String']>;
  /** 报关单上发货人英文名称 */
  consignorEname?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
  appCompany?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  batchflag?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 采购订单合同 */
export type PurchaseContract = IDataInfo & {
  __typename?: 'PurchaseContract';
  id?: Maybe<Scalars['ID']>;
  buyer?: Maybe<Scalars['String']>;
  seller?: Maybe<Scalars['String']>;
  /** 项目 ARJ21 C919 */
  programm?: Maybe<Scalars['String']>;
  /** 任务单号，采购申请等号码，可能是多个分割的 */
  sapNo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 系统内供应商名称和AEO统一 */
  supplierName?: Maybe<Scalars['String']>;
  /** 合同总价 */
  totalContractPrice?: Maybe<Scalars['Float']>;
  /** 交付条款  EX Works */
  deliveryTerm?: Maybe<Scalars['String']>;
  /** 交付地点  Welwyn Garden City,UK */
  deliveryPlace?: Maybe<Scalars['String']>;
  /** 交付时间 */
  deliveryTime?: Maybe<Scalars['String']>;
  /** 采购订单号，如果是合同就是合同号，这个家签署日期和revNo为唯一 */
  purchaseOrderNo?: Maybe<Scalars['String']>;
  /** 合同号 */
  contractNo?: Maybe<Scalars['String']>;
  /** 修订版本号 */
  revNo?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['Date']>;
  /** DAP 付款方式，成交方式 */
  paymentTerm?: Maybe<Scalars['String']>;
  /** 备注 */
  remark?: Maybe<Scalars['String']>;
  /** 一次完成 */
  singlePurchase?: Maybe<Scalars['Boolean']>;
  bucketName?: Maybe<Scalars['String']>;
  OSSPath?: Maybe<Scalars['String']>;
  filesize?: Maybe<Scalars['Float']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 采购订单合同 */
export type PurchaseContractInput = {
  id?: Maybe<Scalars['ID']>;
  buyer?: Maybe<Scalars['String']>;
  seller?: Maybe<Scalars['String']>;
  /** 项目 ARJ21 C919 */
  programm?: Maybe<Scalars['String']>;
  /** 任务单号，采购申请等号码，可能是多个分割的 */
  sapNo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 系统内供应商名称和AEO统一 */
  supplierName?: Maybe<Scalars['String']>;
  /** 合同总价 */
  totalContractPrice?: Maybe<Scalars['Float']>;
  /** 交付条款  EX Works */
  deliveryTerm?: Maybe<Scalars['String']>;
  /** 交付地点  Welwyn Garden City,UK */
  deliveryPlace?: Maybe<Scalars['String']>;
  /** 交付时间 */
  deliveryTime?: Maybe<Scalars['String']>;
  purchaseOrderNo?: Maybe<Scalars['String']>;
  /** 合同号 */
  contractNo?: Maybe<Scalars['String']>;
  /** 修订版本号 */
  revNo?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['Date']>;
  /** DAP 付款方式，成交方式 */
  paymentTerm?: Maybe<Scalars['String']>;
  /** 备注 */
  remark?: Maybe<Scalars['String']>;
  singlePurchase?: Maybe<Scalars['String']>;
  bucketName?: Maybe<Scalars['String']>;
  OSSPath?: Maybe<Scalars['String']>;
  filesize?: Maybe<Scalars['Float']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  me?: Maybe<Scalars['JSON']>;
  AppCompanyList?: Maybe<ListAppCompany>;
  GetAppCompanyByID?: Maybe<AppCompany>;
  SearchAppCompany?: Maybe<ListAppCompany>;
  /** 得到查询公司的可用的章 */
  GetAppCompanyWatermarks?: Maybe<Array<Maybe<KeyValue>>>;
  AppAppNameList?: Maybe<ListAppAppName>;
  GetAppAppNameByID?: Maybe<AppAppName>;
  GetAppAppNameByName?: Maybe<AppAppName>;
  SearchAppAppName?: Maybe<ListAppAppName>;
  AppRolePolicyList?: Maybe<ListAppRolePolicy>;
  GetAppRolePolicyByID?: Maybe<AppRolePolicy>;
  SearchAppRolePolicy?: Maybe<ListAppRolePolicy>;
  /** 判断权限 */
  judgeRoleUrl?: Maybe<Scalars['Boolean']>;
  /** 得到app对应的部门 */
  judgeRoleMenu?: Maybe<Scalars['JSON']>;
  getAppDeparts?: Maybe<Array<Maybe<AppDepartment>>>;
  AppUserInfoList?: Maybe<ListAppUserInfo>;
  GetAppUserInfoByID?: Maybe<AppUserInfo>;
  SearchAppUserInfo?: Maybe<ListAppUserInfo>;
  /** 得到当前钉钉下的登录的企业用户 */
  getDingCorpUserList?: Maybe<ListAppUserInfo>;
  AppComponentConfigList?: Maybe<ListAppComponentConfig>;
  SearchAppComponentConfig?: Maybe<ListAppComponentConfig>;
  GetAppComponentConfigByID?: Maybe<AppComponentConfig>;
  FunJobInfoList?: Maybe<ListFunJobInfo>;
  GetFunJobInfoByID?: Maybe<FunJobInfo>;
  SearchFunJobInfo?: Maybe<ListFunJobInfo>;
  FunTaskDetailInfoList?: Maybe<ListFunTaskDetailInfo>;
  GetFunTaskDetailInfoByID?: Maybe<FunTaskDetailInfo>;
  SearchFunTaskDetailInfo?: Maybe<ListFunTaskDetailInfo>;
  GetOrderFileByNo?: Maybe<Array<Maybe<OrderFileInfo>>>;
  OrderFileInfoList?: Maybe<ListOrderFileInfo>;
  GetOrderFileInfoByID?: Maybe<OrderFileInfo>;
  SearchOrderFileInfo?: Maybe<ListOrderFileInfo>;
  OrderPicInfoList?: Maybe<ListOrderPicInfo>;
  GetOrderPicInfoByID?: Maybe<OrderPicInfo>;
  SearchOrderPicInfo?: Maybe<ListOrderPicInfo>;
  overAmount?: Maybe<MutationResponse>;
  getxportOrderFileInfo?: Maybe<Array<Maybe<OrderFileInfo>>>;
  getxportOrderPicInfo?: Maybe<Array<Maybe<OrderPicInfo>>>;
  getxportOrderPicInfoCount?: Maybe<Scalars['Int']>;
  getxportOrderPicInfoByID?: Maybe<OrderPicInfo>;
  geterrLabels?: Maybe<Array<Maybe<TrainDataInfo>>>;
  getSpecLabels?: Maybe<Array<Maybe<TrainDataInfo>>>;
  getnewlabels?: Maybe<Array<Maybe<TrainDataInfo>>>;
  SearchLabels?: Maybe<ListTrainDataInfo>;
  /** 得到指定block的ocr结果 */
  GetOssBlocksOcrResult?: Maybe<Array<Maybe<TiOcrBlockResult>>>;
  /** list avali ocr langs */
  ListAvailableLangs?: Maybe<Array<Maybe<Scalars['String']>>>;
  DecModHeadInfoList?: Maybe<ListDecModHeadInfo>;
  GetDecModHeadInfoByID?: Maybe<DecModHeadInfo>;
  SearchDecModHeadInfo?: Maybe<ListDecModHeadInfo>;
  DecModStats?: Maybe<Array<Maybe<DecModHeadStats>>>;
  BizCompanyInfoList?: Maybe<ListBizCompanyInfo>;
  GetBizCompanyInfoByID?: Maybe<BizCompanyInfo>;
  SearchBizCompanyInfo?: Maybe<ListBizCompanyInfo>;
  validationruleList?: Maybe<Listvalidationrule>;
  GetvalidationruleByID?: Maybe<Validationrule>;
  Searchvalidationrule?: Maybe<Listvalidationrule>;
  hsregexList?: Maybe<Listhsregex>;
  GethsregexByID?: Maybe<Hsregex>;
  Searchhsregex?: Maybe<Listhsregex>;
  hgcheckList?: Maybe<Listhgcheck>;
  GethgcheckByID?: Maybe<Hgcheck>;
  Searchhgcheck?: Maybe<Listhgcheck>;
  HgcheckStat?: Maybe<Array<Maybe<DecCheckHeadStats>>>;
  hgrejectList?: Maybe<Listhgreject>;
  GethgrejectByID?: Maybe<Hgreject>;
  Searchhgreject?: Maybe<Listhgreject>;
  HgrejectStat?: Maybe<Array<Maybe<DecRejectHeadStats>>>;
  contractList?: Maybe<Listcontract>;
  GetcontractByID?: Maybe<Contract>;
  Searchcontract?: Maybe<Listcontract>;
  chacuoList?: Maybe<Listchacuo>;
  GetchacuoByID?: Maybe<Chacuo>;
  Searchchacuo?: Maybe<Listchacuo>;
  ChacuoStat?: Maybe<Array<Maybe<ChauoStats>>>;
  dzstateinfoList?: Maybe<Listdzstateinfo>;
  GetdzstateinfoByID?: Maybe<Dzstateinfo>;
  Searchdzstateinfo?: Maybe<Listdzstateinfo>;
  getParas?: Maybe<Array<Maybe<ParaIdNameValue>>>;
  getHscodePara?: Maybe<Array<Maybe<ClassifyInfo>>>;
  getAppUsers?: Maybe<Array<Maybe<AppUserInfo>>>;
  getkddpreorderByID?: Maybe<Kddpreorder>;
  searchPreOrderDec?: Maybe<ListKddPreOrder>;
  searchPreOrderSku?: Maybe<ListKddPreOrder>;
  searchshendanPreOrderDec?: Maybe<ListKddPreOrder>;
  shendankddPreOrderDecList?: Maybe<ListKddPreOrder>;
  getkddpreorderByOrderNo?: Maybe<Kddpreorder>;
  getuniqueSepseqno?: Maybe<Scalars['Boolean']>;
  kddPreOrderDecList?: Maybe<ListKddPreOrder>;
  scanExSplit?: Maybe<Array<Maybe<Kddpreorder>>>;
  scanSepInfo?: Maybe<Kddpreorder>;
  searchDecStatus?: Maybe<ListDecStatus>;
  SearchDecStatusDev?: Maybe<ListDecStatus>;
  getdataLogList?: Maybe<Array<Maybe<DataLog>>>;
  getKddPreOrderTemplateList?: Maybe<ListKddPreOrderTemplate>;
  getKddPreOrderTemplateByID?: Maybe<KddPreOrderTemplate>;
  searchKddPreOrderTemplate?: Maybe<ListKddPreOrderTemplate>;
  getproductmanageByID?: Maybe<Productmanage>;
  searchProductManage?: Maybe<ListProductManage>;
  productManageList?: Maybe<ListProductManage>;
  getprodhistory: Array<Maybe<Producthistory>>;
  searchProductBySku?: Maybe<Array<Maybe<Productmanage>>>;
  searchProductBySkufit?: Maybe<Array<Maybe<Productmanage>>>;
  searchProductByallSku?: Maybe<Array<Maybe<Productmanage>>>;
  autofillProduct?: Maybe<Array<Maybe<Productmanage>>>;
  /** 委托审单 */
  getAttorneyShendanProcessPara?: Maybe<DingProcessApplyPara>;
  pushcustfushendingding?: Maybe<MutationResponse>;
  dingdingexcute?: Maybe<MutationResponse>;
  /** 发送前审单流程人员参数 */
  getShendanProcessPara?: Maybe<DingProcessApplyPara>;
  /** 改删单责任认定流程人员参数 */
  getModifyApplyProcessPara?: Maybe<DingProcessApplyPara>;
  /** 得到可用审单人 */
  getShendanPerson?: Maybe<Array<Maybe<KeyValue>>>;
  applyCreate?: Maybe<ApplyResult>;
  DecTrackInfoList?: Maybe<ListDecTrackInfo>;
  SearchDecTrackInfo?: Maybe<ListDecTrackInfo>;
  GetDecTrackInfoByID?: Maybe<DecTrackInfo>;
  SpecialTypeOrderTrackInfoList?: Maybe<ListSpecialTypeOrderTrackInfo>;
  SearchSpecialTypeOrderTrackInfo?: Maybe<ListSpecialTypeOrderTrackInfo>;
  ShangjianInfoList?: Maybe<ListDecTrackInfo>;
  SearchShangjianInfo?: Maybe<ListDecTrackInfo>;
  DecTrackStats?: Maybe<Array<Maybe<DecTrackStats>>>;
  getTitMailByID?: Maybe<TitMail>;
  getMailsbyRefNo?: Maybe<Array<Maybe<TitMail>>>;
  searchTitMail?: Maybe<ListTitMail>;
  /** 显示自己委托邮件列表 */
  searchAttorneyTitMail?: Maybe<ListTitMail>;
  searchAttorneyTitMailtotal?: Maybe<ListTitMail>;
  /** 显示自己委托邮件列表 */
  titAttorneyMailList?: Maybe<ListTitMail>;
  titMailList?: Maybe<ListTitMail>;
  getProductCompanyList?: Maybe<ListProductCompany>;
  searchProductCompany?: Maybe<ListProductCompany>;
  getxportProductCompanyByID?: Maybe<ProductCompany>;
  /** 根据输入查询历史境外收发货人 */
  searchConsignorEname?: Maybe<Array<Maybe<KeyValue>>>;
  getXportOrderMainByID?: Maybe<XportOrderMain>;
  searchOrderMain?: Maybe<ListOrderMain>;
  orderMainList?: Maybe<ListOrderMain>;
  DataChangeLogInfoList?: Maybe<ListDataChangeLogInfo>;
  GetDataChangeLogInfoByID?: Maybe<DataChangeLogInfo>;
  SearchDataChangeLogInfo?: Maybe<ListDataChangeLogInfo>;
  purchaseContractList?: Maybe<ListPurchaseContract>;
  getPurchaseContractByID?: Maybe<PurchaseContract>;
  searchPurchaseContract?: Maybe<ListPurchaseContract>;
  aeoHeavyeqpList?: Maybe<ListAeoHeavyeqp>;
  getAeoHeavyeqpByID?: Maybe<AeoHeavyeqp>;
  searchAeoHeavyeqp?: Maybe<ListAeoHeavyeqp>;
  taxInfoList?: Maybe<ListtaxInfo>;
  gettaxInfoByID?: Maybe<TaxInfo>;
  searchtaxInfo?: Maybe<ListtaxInfo>;
  searchSingleWindowDec?: Maybe<ListKddPreOrder>;
  getSingleWindowDecList?: Maybe<ListKddPreOrder>;
  getSingleWindowDataByID?: Maybe<Kddpreorder>;
  getsinglewindowcustomByEntryId?: Maybe<Array<Maybe<Singlewindowcustom>>>;
  getkddLogByID?: Maybe<Kddpreorder>;
  ownerManageList?: Maybe<ListOwnerManage>;
  getOwnerManageByID?: Maybe<OwnerManage>;
  searchOwnerManage?: Maybe<ListOwnerManage>;
  getRpaJobInfoList?: Maybe<ListRpaJobInfo>;
  getRpaJobInfoByID?: Maybe<RpaJobInfo>;
  getRpaProcessDetailsByJobNo?: Maybe<ListRpaJobProcessDetail>;
  getRpaJobInfoByTaskCode?: Maybe<ClientPorcessJobData>;
  searchRpaJobInfo?: Maybe<ListRpaJobInfo>;
  getRpaTaskDefList?: Maybe<ListRpaTaskDef>;
  getwareHouseByID?: Maybe<WareHouse>;
  searchwareHouse?: Maybe<ListwareHouse>;
  wareHouseList?: Maybe<ListwareHouse>;
  guanmaoProductList?: Maybe<ListGuanmaoProduct>;
  getGuanmaoProductByID?: Maybe<GuanmaoProduct>;
  searchGuanmaoProduct?: Maybe<ListGuanmaoProduct>;
  guanmaoProductStat?: Maybe<Array<Maybe<ChauoStats>>>;
  NetTransitCarList?: Maybe<ListNetTransitCar>;
  GetNetTransitCarByID?: Maybe<NetTransitCar>;
  SearchNetTransitCar?: Maybe<ListNetTransitCar>;
  NetTransitCarStat?: Maybe<Array<Maybe<ChauoStats>>>;
  spAreaPortReleaseList?: Maybe<ListSpAreaPortRelease>;
  getSpAreaPortReleaseByID?: Maybe<SpAreaPortRelease>;
  searchSpAreaPortRelease?: Maybe<ListSpAreaPortRelease>;
  /** 根据 料号+ 仓库 进行汇总 */
  spAreaInventoryGroup?: Maybe<ListSpAreaInventoryGroupView>;
  spAreaInventoryList?: Maybe<ListSpAreaInventory>;
  getSpAreaInventoryByID?: Maybe<SpAreaInventory>;
  searchSpAreaInventory?: Maybe<ListSpAreaInventory>;
  getOssFileInfoList?: Maybe<ListOssFileInfo>;
  getOssFileInfoByID?: Maybe<OssFileInfo>;
  getOssFileInfoListByTags?: Maybe<ListOssFileInfo>;
  searchOssFileInfo?: Maybe<ListOssFileInfo>;
  getRootPath?: Maybe<Array<Maybe<OssPath>>>;
  listOssPath?: Maybe<ListOssFileInfo>;
  getFileInfoTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  aeoValidateRuleList?: Maybe<ListAeoValidateRule>;
  getAeoValidateRuleByID?: Maybe<AeoValidateRule>;
  searchAeoValidateRule?: Maybe<ListAeoValidateRule>;
  /** 校验报关单 */
  doAeoValidate?: Maybe<Array<Maybe<AeoValidateResult>>>;
  spAreaStatList?: Maybe<ListSpAreaStat>;
  getSpAreaStatByID?: Maybe<SpAreaStat>;
  searchSpAreaStat?: Maybe<ListSpAreaStat>;
  /**
   * 根据类型查询异常明细
   * 前台分页
   */
  getSpAreaExcptionDetail?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  searchExpressOrderById?: Maybe<ExpressOrder>;
  trackExpressBySearchNo?: Maybe<TrackBillInfo>;
  tmsTransApplyList?: Maybe<ListTmsTransApply>;
  getTmsTransApplyByID?: Maybe<TmsTransApply>;
  getTmsTransApplyByapplySeqNo?: Maybe<TmsTransApply>;
  searchTmsTransApply?: Maybe<ListTmsTransApply>;
  wmsInwardPlanList?: Maybe<ListWmsInwardPlan>;
  getWmsInwardPlanByID?: Maybe<WmsInwardPlan>;
  searchWmsInwardPlan?: Maybe<ListWmsInwardPlan>;
  scmSamcTrackInfoList?: Maybe<ListScmSamcTrackInfo>;
  getScmSamcTrackInfoByID?: Maybe<ScmSamcTrackInfo>;
  searchScmSamcTrackInfo?: Maybe<ListScmSamcTrackInfo>;
  /** 得到可送货业务清单 */
  searchAvailTracInfolist?: Maybe<ListScmSamcTrackInfo>;
  tmsUserAddressInfoList?: Maybe<ListTmsUserAddressInfo>;
  getTmsUserAddressInfoByID?: Maybe<TmsUserAddressInfo>;
  searchTmsUserAddressInfo?: Maybe<ListTmsUserAddressInfo>;
  getNearAddress?: Maybe<TmsUserAddressInfo>;
  tmsCustTransApplyList?: Maybe<ListTmsCustTransApply>;
  getTmsCustTransApplyByID?: Maybe<TmsCustTransApply>;
  searchTmsCustTransApply?: Maybe<ListTmsCustTransApply>;
  getTmsCustTransApplyCount?: Maybe<MutationResponse>;
  transportsearchTmsCustTransApply?: Maybe<ListTmsCustTransApply>;
  /** 从业务申请单得到商品明细 */
  getGuanMaoBusinessTransitFromNos?: Maybe<Array<Maybe<GuanMaoBusinessTransit>>>;
  tmsdingdingexcute?: Maybe<MutationResponse>;
  /** 根据关键字搜索POI */
  searchAmapPOI?: Maybe<Array<Maybe<AmapPoi>>>;
  searchAmapPOIBysession?: Maybe<Array<Maybe<AmapPoi>>>;
  /** 得到gps位置附近的POI */
  getAroundPOI?: Maybe<Array<Maybe<AmapPoi>>>;
  /** 得到起点终点距离 */
  getDriveDistance?: Maybe<Scalars['Int']>;
  getDetailPOI?: Maybe<Scalars['String']>;
  transVehicleInfoList?: Maybe<ListTransVehicleInfo>;
  getTransVehicleInfoByID?: Maybe<TransVehicleInfo>;
  searchTransVehicleInfo?: Maybe<ListTransVehicleInfo>;
  roadTransForwardingOrderList?: Maybe<ListRoadTransForwardingOrder>;
  getRoadTransForwardingOrderBytmsApplyNo?: Maybe<Array<Maybe<RoadTransForwardingOrder>>>;
  getRoadTransForwardingOrderByID?: Maybe<RoadTransForwardingOrder>;
  getRoadTransForwardingOrderByCop?: Maybe<RoadTransForwardingOrder>;
  searchRoadTransForwardingOrder?: Maybe<ListRoadTransForwardingOrder>;
  searchCarNoRoadTransForwardingOrder?: Maybe<ListRoadTransForwardingOrder>;
  /** 供应商查询自己的单证 */
  searchRoadTranForwardSupplier?: Maybe<ListRoadTransForwardingOrder>;
  searchRoadTranForwardPending?: Maybe<ListRoadTransForwardingOrder>;
  getRoadTransForwardingOrderCount?: Maybe<MutationResponse>;
  transTaskOrderList?: Maybe<ListTransTaskOrder>;
  /** 的到打卡明细列表 */
  getTransTaskOrderByID?: Maybe<TransTaskOrder>;
  /** 更具业务编号车辆任务列表 */
  getTranTaskOrderByfOrderNo?: Maybe<Array<Maybe<TransTaskOrder>>>;
  searchTransTaskOrder?: Maybe<ListTransTaskOrder>;
  searchTodayTransTaskOrder?: Maybe<ListTransTaskOrder>;
  searchTodayErrorTaskOrder?: Maybe<ListTransTaskOrder>;
  weChatsearchTransTaskOrder?: Maybe<ListTransTaskOrder>;
  tmsSupplierVehicleInfoList?: Maybe<ListTmsSupplierVehicleInfo>;
  getTmsSupplierVehicleInfoByID?: Maybe<TmsSupplierVehicleInfo>;
  searchTmsSupplierVehicleInfo?: Maybe<ListTmsSupplierVehicleInfo>;
  tmsSupplierDriverInfoList?: Maybe<ListTmsSupplierDriverInfo>;
  getTmsSupplierDriverInfoByID?: Maybe<TmsSupplierDriverInfo>;
  searchTmsSupplierDriverInfo?: Maybe<ListTmsSupplierDriverInfo>;
  tmsTransMissionInfoList?: Maybe<ListTmsTransMissionInfo>;
  getTmsTransMissionInfoByID?: Maybe<TmsTransMissionInfo>;
  /** 根据司机任务号获取操作任务列表 */
  getTaskMissionsByOrderNo?: Maybe<Array<Maybe<TmsTransMissionInfo>>>;
  searchTmsTransMissionInfo?: Maybe<ListTmsTransMissionInfo>;
  missionCheckRecordList?: Maybe<ListMissionCheckRecord>;
  getMissionCheckRecordByID?: Maybe<MissionCheckRecord>;
  searchMissionCheckRecord?: Maybe<ListMissionCheckRecord>;
  serviceBillDetailInfoList?: Maybe<ListServiceBillDetailInfo>;
  getServiceBillDetailInfoByID?: Maybe<ServiceBillDetailInfo>;
  getServiceBillDetailInfoByOrder?: Maybe<Array<Maybe<ServiceBillDetailInfo>>>;
  searchServiceBillDetailInfo?: Maybe<ListServiceBillDetailInfo>;
  getNewBill?: Maybe<Array<Maybe<ServiceBillDetailInfo>>>;
  getPurchaseDetailInfoList?: Maybe<ListServicePurchaseDetailInfo>;
  getServicePurchaseDetailInfoByID?: Maybe<ServicePurchaseDetailInfo>;
  getServicePurchaseDetailInfoByForder?: Maybe<Array<Maybe<ServicePurchaseDetailInfo>>>;
  getXYPurchaseDetailInfoByForder?: Maybe<Array<Maybe<ServicePurchaseDetailInfo>>>;
  getXYPurchaseDetailInfoByCust?: Maybe<Array<Maybe<ServicePurchaseDetailInfo>>>;
  searchServicePurchaseDetailInfo?: Maybe<ListServicePurchaseDetailInfo>;
  bizSalesContractDetailList?: Maybe<ListBizSalesContractDetail>;
  getBizSalesContractDetailByID?: Maybe<BizSalesContractDetail>;
  searchBizSalesContractDetail?: Maybe<ListBizSalesContractDetail>;
  bizSalesContractServiceList?: Maybe<ListBizSalesContractService>;
  getBizSalesContractServiceByID?: Maybe<BizSalesContractService>;
  getBizSalesContractDetailByCompany?: Maybe<Array<Maybe<BizSalesContractService>>>;
  searchBizSalesContractService?: Maybe<ListBizSalesContractService>;
  bizSupplierContractDetailList?: Maybe<ListBizSupplierContractDetail>;
  getBizSupplierContractDetailByID?: Maybe<BizSupplierContractDetail>;
  searchBizSupplierContractDetail?: Maybe<ListBizSupplierContractDetail>;
  custBillInfoList?: Maybe<ListCustBillInfo>;
  getCustBillInfoByID?: Maybe<CustBillInfo>;
  searchCustBillInfo?: Maybe<ListCustBillInfo>;
  bizInvoiceApplyList?: Maybe<ListBizInvoiceApply>;
  getBizInvoiceApplyByID?: Maybe<BizInvoiceApply>;
  getBizInvoiceDetailByID?: Maybe<Array<Maybe<MainBillOrderData>>>;
  searchBizInvoiceApply?: Maybe<ListBizInvoiceApply>;
  bizPaySupplierApplyList?: Maybe<ListBizPaySupplierApply>;
  /** 的到供应商费用申请单明细 费用明细用 getBizPaySupplierApplyCostGroupByFOrder 来汇总和展示 */
  getBizPaySupplierApplyByID?: Maybe<BizPaySupplierApply>;
  searchBizPaySupplierApply?: Maybe<ListBizPaySupplierApply>;
  searchChooseBizPaySupplierApply?: Maybe<ListBizPaySupplierApply>;
  /** 供应商查询付款申请单 */
  searchBizPayApplyBySupplier?: Maybe<ListBizPaySupplierApply>;
  customApplyBussinessInfoList?: Maybe<ListCustomApplyBussinessInfo>;
  getCustomApplyBussinessInfoByID?: Maybe<CustomApplyBussinessInfo>;
  searchCustomApplyBussinessInfo?: Maybe<ListCustomApplyBussinessInfo>;
  yitihuaPackageListList?: Maybe<ListYitihuaPackageList>;
  getYitihuaPackageListByID?: Maybe<YitihuaPackageList>;
  searchYitihuaPackageList?: Maybe<ListYitihuaPackageList>;
  tensorSpAreaPackageListList?: Maybe<ListTensorSpAreaPackageList>;
  getTensorSpAreaPackageListByID?: Maybe<TensorSpAreaPackageList>;
  searchTensorSpAreaPackageList?: Maybe<ListTensorSpAreaPackageList>;
  partNumberSAPInventoryPriceList?: Maybe<ListPartNumberSapInventoryPrice>;
  getPartNumberSAPInventoryPriceByID?: Maybe<PartNumberSapInventoryPrice>;
  searchPartNumberSAPInventoryPrice?: Maybe<ListPartNumberSapInventoryPrice>;
  tmsCustApplyLogList?: Maybe<ListTmsCustApplyLog>;
  getTmsCustApplyLogByID?: Maybe<TmsCustApplyLog>;
  searchTmsCustApplyLog?: Maybe<ListTmsCustApplyLog>;
  tmsPassPortOrderList?: Maybe<ListTmsPassPortOrder>;
  getTmsPassPortOrderByID?: Maybe<TmsPassPortOrder>;
  getTmsPassPortProUser?: Maybe<Array<Maybe<AppUserInfo>>>;
  searchTmsPassPortOrder?: Maybe<ListTmsPassPortOrder>;
  spPassportList?: Maybe<ListSpPassport>;
  getSpPassportByID?: Maybe<SpPassport>;
  getSpPassportBygdsNm?: Maybe<Array<Maybe<PassPortProd>>>;
  searchSpPassport?: Maybe<ListSpPassport>;
  hsCustomsRegulationList?: Maybe<ListHsCustomsRegulation>;
  getHsCustomsRegulationByID?: Maybe<HsCustomsRegulation>;
  searchHsCustomsRegulation?: Maybe<ListHsCustomsRegulation>;
  tmsProduceWarehouseList?: Maybe<ListTmsProduceWarehouse>;
  getTmsProduceWarehouseByID?: Maybe<TmsProduceWarehouse>;
  searchTmsProduceWarehouse?: Maybe<ListTmsProduceWarehouse>;
  tmsOrderInfoList?: Maybe<ListTmsOrderInfo>;
  getTmsOrderInfoByID?: Maybe<TmsOrderInfo>;
  searchTmsOrderInfo?: Maybe<ListTmsOrderInfo>;
};


export type QueryAppCompanyListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetAppCompanyByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchAppCompanyArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetAppCompanyWatermarksArgs = {
  searchStrs?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryAppAppNameListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetAppAppNameByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetAppAppNameByNameArgs = {
  appName?: Maybe<Scalars['String']>;
};


export type QuerySearchAppAppNameArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryAppRolePolicyListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetAppRolePolicyByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchAppRolePolicyArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryJudgeRoleUrlArgs = {
  url?: Maybe<Scalars['String']>;
};


export type QueryJudgeRoleMenuArgs = {
  menuDef?: Maybe<Scalars['JSON']>;
};


export type QueryGetAppDepartsArgs = {
  appName?: Maybe<Scalars['String']>;
};


export type QueryAppUserInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetAppUserInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchAppUserInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetDingCorpUserListArgs = {
  dingAppCorpID?: Maybe<Scalars['String']>;
};


export type QueryAppComponentConfigListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QuerySearchAppComponentConfigArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetAppComponentConfigByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryFunJobInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetFunJobInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchFunJobInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryFunTaskDetailInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetFunTaskDetailInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchFunTaskDetailInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetOrderFileByNoArgs = {
  searchStr?: Maybe<Scalars['String']>;
};


export type QueryOrderFileInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetOrderFileInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchOrderFileInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryOrderPicInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetOrderPicInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchOrderPicInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryOverAmountArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetxportOrderFileInfoArgs = {
  orderNo?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  OSSPath?: Maybe<Scalars['String']>;
  pageinfo?: Maybe<Paging>;
};


export type QueryGetxportOrderPicInfoArgs = {
  GVFlag?: Maybe<Scalars['Int']>;
  orderNo?: Maybe<Scalars['String']>;
  OrginFileName?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  pageinfo?: Maybe<Paging>;
};


export type QueryGetxportOrderPicInfoCountArgs = {
  GVFlag?: Maybe<Scalars['Int']>;
  orderNo?: Maybe<Scalars['String']>;
  OrginFileName?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  pageinfo?: Maybe<Paging>;
};


export type QueryGetxportOrderPicInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGeterrLabelsArgs = {
  searchStr?: Maybe<Scalars['String']>;
};


export type QueryGetSpecLabelsArgs = {
  searchStr?: Maybe<Scalars['String']>;
};


export type QueryGetnewlabelsArgs = {
  pageInfo?: Maybe<Paging>;
};


export type QuerySearchLabelsArgs = {
  condition?: Maybe<Scalars['JSON']>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetOssBlocksOcrResultArgs = {
  ossKey?: Maybe<Scalars['String']>;
  ocrPara?: Maybe<OcrParaInput>;
  blocks?: Maybe<Array<Maybe<Array<Maybe<Array<Maybe<Scalars['Int']>>>>>>>;
};


export type QueryDecModHeadInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetDecModHeadInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchDecModHeadInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryBizCompanyInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetBizCompanyInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchBizCompanyInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryValidationruleListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetvalidationruleByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchvalidationruleArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryHsregexListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGethsregexByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchhsregexArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryHgcheckListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGethgcheckByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchhgcheckArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryHgrejectListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGethgrejectByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchhgrejectArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryContractListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetcontractByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchcontractArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryChacuoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetchacuoByIdArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QuerySearchchacuoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryDzstateinfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetdzstateinfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchdzstateinfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetParasArgs = {
  paraType?: Maybe<Scalars['String']>;
  searchStr?: Maybe<Scalars['String']>;
};


export type QueryGetHscodeParaArgs = {
  searchStr?: Maybe<Scalars['String']>;
};


export type QueryGetAppUsersArgs = {
  appName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  searchStr?: Maybe<Scalars['String']>;
};


export type QueryGetkddpreorderByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchPreOrderDecArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QuerySearchPreOrderSkuArgs = {
  searchStr?: Maybe<Scalars['String']>;
  ordertype?: Maybe<Scalars['String']>;
  pageInfo?: Maybe<Paging>;
};


export type QuerySearchshendanPreOrderDecArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryShendankddPreOrderDecListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetkddpreorderByOrderNoArgs = {
  orderOutNo?: Maybe<Scalars['String']>;
};


export type QueryGetuniqueSepseqnoArgs = {
  sepSeqno?: Maybe<Scalars['String']>;
};


export type QueryKddPreOrderDecListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryScanExSplitArgs = {
  sepSeqno?: Maybe<Scalars['String']>;
};


export type QueryScanSepInfoArgs = {
  sepSeqno?: Maybe<Scalars['String']>;
};


export type QuerySearchDecStatusArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QuerySearchDecStatusDevArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetdataLogListArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetKddPreOrderTemplateListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetKddPreOrderTemplateByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchKddPreOrderTemplateArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetproductmanageByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchProductManageArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryProductManageListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetprodhistoryArgs = {
  prodid?: Maybe<Scalars['String']>;
};


export type QuerySearchProductBySkuArgs = {
  searchStr?: Maybe<Scalars['String']>;
  tradeNameCn?: Maybe<Scalars['String']>;
};


export type QuerySearchProductBySkufitArgs = {
  searchStr?: Maybe<Scalars['String']>;
  tradeNameCn?: Maybe<Scalars['String']>;
};


export type QuerySearchProductByallSkuArgs = {
  searchStr?: Maybe<Scalars['String']>;
};


export type QueryAutofillProductArgs = {
  prodinfo?: Maybe<ProductmanageInput>;
};


export type QueryGetAttorneyShendanProcessParaArgs = {
  user_id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};


export type QueryPushcustfushendingdingArgs = {
  user_id?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
};


export type QueryDingdingexcuteArgs = {
  processInstanceId?: Maybe<Scalars['String']>;
  statusName?: Maybe<Scalars['String']>;
  act?: Maybe<Scalars['String']>;
};


export type QueryGetShendanProcessParaArgs = {
  total_amount?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['String']>;
  depart_id?: Maybe<Scalars['Int']>;
  shendan_id?: Maybe<Scalars['String']>;
};


export type QueryGetModifyApplyProcessParaArgs = {
  userName?: Maybe<Scalars['String']>;
};


export type QueryGetShendanPersonArgs = {
  user_id?: Maybe<Scalars['String']>;
  depart_id?: Maybe<Scalars['Int']>;
};


export type QueryApplyCreateArgs = {
  originator_user_id?: Maybe<Scalars['String']>;
  dept_id?: Maybe<Scalars['Int']>;
  process_code?: Maybe<Scalars['String']>;
  approvers_v2?: Maybe<Scalars['JSON']>;
  form_component_values?: Maybe<Scalars['JSON']>;
};


export type QueryDecTrackInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QuerySearchDecTrackInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetDecTrackInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySpecialTypeOrderTrackInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QuerySearchSpecialTypeOrderTrackInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryShangjianInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QuerySearchShangjianInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetTitMailByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetMailsbyRefNoArgs = {
  refNo?: Maybe<Scalars['String']>;
};


export type QuerySearchTitMailArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QuerySearchAttorneyTitMailArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QuerySearchAttorneyTitMailtotalArgs = {
  searchStr?: Maybe<Scalars['String']>;
};


export type QueryTitAttorneyMailListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryTitMailListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetProductCompanyListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QuerySearchProductCompanyArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetxportProductCompanyByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchConsignorEnameArgs = {
  searchStr?: Maybe<Scalars['String']>;
};


export type QueryGetXportOrderMainByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchOrderMainArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryOrderMainListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryDataChangeLogInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetDataChangeLogInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchDataChangeLogInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryPurchaseContractListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetPurchaseContractByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchPurchaseContractArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryAeoHeavyeqpListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetAeoHeavyeqpByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchAeoHeavyeqpArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryTaxInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGettaxInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchtaxInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QuerySearchSingleWindowDecArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetSingleWindowDecListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetSingleWindowDataByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetsinglewindowcustomByEntryIdArgs = {
  entryId?: Maybe<Scalars['String']>;
};


export type QueryGetkddLogByIdArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryOwnerManageListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetOwnerManageByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchOwnerManageArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetRpaJobInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetRpaJobInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetRpaProcessDetailsByJobNoArgs = {
  jobNo?: Maybe<Scalars['String']>;
  pageInfo?: Maybe<Paging>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
};


export type QueryGetRpaJobInfoByTaskCodeArgs = {
  taskCode?: Maybe<Scalars['String']>;
  sortType?: Maybe<Scalars['Int']>;
};


export type QuerySearchRpaJobInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetRpaTaskDefListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetwareHouseByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchwareHouseArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryWareHouseListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGuanmaoProductListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetGuanmaoProductByIdArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QuerySearchGuanmaoProductArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryNetTransitCarListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetNetTransitCarByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchNetTransitCarArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QuerySpAreaPortReleaseListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetSpAreaPortReleaseByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchSpAreaPortReleaseArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QuerySpAreaInventoryGroupArgs = {
  sku?: Maybe<Scalars['String']>;
  warehouseName?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
  exceptionFlag?: Maybe<Scalars['Boolean']>;
};


export type QuerySpAreaInventoryListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetSpAreaInventoryByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchSpAreaInventoryArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetOssFileInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetOssFileInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetOssFileInfoListByTagsArgs = {
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QuerySearchOssFileInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryListOssPathArgs = {
  path?: Maybe<Scalars['String']>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetFileInfoTagsArgs = {
  searchStr?: Maybe<Scalars['String']>;
};


export type QueryAeoValidateRuleListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetAeoValidateRuleByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchAeoValidateRuleArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryDoAeoValidateArgs = {
  input?: Maybe<KddPreOrderInput>;
};


export type QuerySpAreaStatListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetSpAreaStatByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchSpAreaStatArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetSpAreaExcptionDetailArgs = {
  type?: Maybe<Scalars['String']>;
};


export type QuerySearchExpressOrderByIdArgs = {
  trackId?: Maybe<Scalars['String']>;
};


export type QueryTrackExpressBySearchNoArgs = {
  searchNo?: Maybe<Scalars['String']>;
  idNo?: Maybe<Scalars['String']>;
};


export type QueryTmsTransApplyListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetTmsTransApplyByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetTmsTransApplyByapplySeqNoArgs = {
  applySeqNo?: Maybe<Scalars['String']>;
};


export type QuerySearchTmsTransApplyArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryWmsInwardPlanListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetWmsInwardPlanByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchWmsInwardPlanArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryScmSamcTrackInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetScmSamcTrackInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchScmSamcTrackInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QuerySearchAvailTracInfolistArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryTmsUserAddressInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetTmsUserAddressInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchTmsUserAddressInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetNearAddressArgs = {
  longtitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
};


export type QueryTmsCustTransApplyListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetTmsCustTransApplyByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchTmsCustTransApplyArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetTmsCustTransApplyCountArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
};


export type QueryTransportsearchTmsCustTransApplyArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetGuanMaoBusinessTransitFromNosArgs = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryTmsdingdingexcuteArgs = {
  processInstanceId?: Maybe<Scalars['String']>;
  statusName?: Maybe<Scalars['String']>;
  act?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};


export type QuerySearchAmapPoiArgs = {
  searchStr?: Maybe<Scalars['String']>;
};


export type QuerySearchAmapPoiBysessionArgs = {
  searchStr?: Maybe<Scalars['String']>;
  session?: Maybe<Scalars['String']>;
};


export type QueryGetAroundPoiArgs = {
  loc?: Maybe<Scalars['String']>;
};


export type QueryGetDriveDistanceArgs = {
  origin?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
};


export type QueryGetDetailPoiArgs = {
  placeId?: Maybe<Scalars['String']>;
  session?: Maybe<Scalars['String']>;
};


export type QueryTransVehicleInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetTransVehicleInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchTransVehicleInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryRoadTransForwardingOrderListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetRoadTransForwardingOrderBytmsApplyNoArgs = {
  tmsApplyNo?: Maybe<Scalars['String']>;
};


export type QueryGetRoadTransForwardingOrderByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetRoadTransForwardingOrderByCopArgs = {
  id?: Maybe<Scalars['ID']>;
  supplierId?: Maybe<Scalars['String']>;
};


export type QuerySearchRoadTransForwardingOrderArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QuerySearchCarNoRoadTransForwardingOrderArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QuerySearchRoadTranForwardSupplierArgs = {
  wbsNo?: Maybe<Scalars['String']>;
  tmsApplyNo?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  checkStatus?: Maybe<Scalars['String']>;
  pageInfo?: Maybe<Paging>;
};


export type QuerySearchRoadTranForwardPendingArgs = {
  searchStr?: Maybe<Scalars['String']>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetRoadTransForwardingOrderCountArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
};


export type QueryTransTaskOrderListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetTransTaskOrderByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetTranTaskOrderByfOrderNoArgs = {
  fOrderNo?: Maybe<Scalars['String']>;
};


export type QuerySearchTransTaskOrderArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QuerySearchTodayTransTaskOrderArgs = {
  searchStr?: Maybe<Scalars['String']>;
};


export type QuerySearchTodayErrorTaskOrderArgs = {
  searchStr?: Maybe<Scalars['String']>;
};


export type QueryWeChatsearchTransTaskOrderArgs = {
  tel?: Maybe<Scalars['String']>;
  searchStr?: Maybe<Scalars['String']>;
  statusls?: Maybe<Array<Maybe<Scalars['String']>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryTmsSupplierVehicleInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetTmsSupplierVehicleInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchTmsSupplierVehicleInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryTmsSupplierDriverInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetTmsSupplierDriverInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchTmsSupplierDriverInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryTmsTransMissionInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetTmsTransMissionInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetTaskMissionsByOrderNoArgs = {
  transTaskOrderNo?: Maybe<Scalars['String']>;
};


export type QuerySearchTmsTransMissionInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryMissionCheckRecordListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetMissionCheckRecordByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchMissionCheckRecordArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryServiceBillDetailInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetServiceBillDetailInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetServiceBillDetailInfoByOrderArgs = {
  tmsApplyNo?: Maybe<Scalars['String']>;
};


export type QuerySearchServiceBillDetailInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetNewBillArgs = {
  tmsApplyNo?: Maybe<Scalars['String']>;
};


export type QueryGetPurchaseDetailInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetServicePurchaseDetailInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetServicePurchaseDetailInfoByForderArgs = {
  forderNo?: Maybe<Scalars['String']>;
};


export type QueryGetXyPurchaseDetailInfoByForderArgs = {
  forderNo?: Maybe<Scalars['String']>;
};


export type QueryGetXyPurchaseDetailInfoByCustArgs = {
  tmsApplyNo?: Maybe<Scalars['String']>;
};


export type QuerySearchServicePurchaseDetailInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryBizSalesContractDetailListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetBizSalesContractDetailByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchBizSalesContractDetailArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryBizSalesContractServiceListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetBizSalesContractServiceByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetBizSalesContractDetailByCompanyArgs = {
  supplierName?: Maybe<Scalars['String']>;
};


export type QuerySearchBizSalesContractServiceArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryBizSupplierContractDetailListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetBizSupplierContractDetailByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchBizSupplierContractDetailArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryCustBillInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetCustBillInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchCustBillInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryBizInvoiceApplyListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetBizInvoiceApplyByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetBizInvoiceDetailByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchBizInvoiceApplyArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryBizPaySupplierApplyListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetBizPaySupplierApplyByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchBizPaySupplierApplyArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QuerySearchChooseBizPaySupplierApplyArgs = {
  searchStr?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QuerySearchBizPayApplyBySupplierArgs = {
  supplierName?: Maybe<Scalars['String']>;
  payApplyNo?: Maybe<Scalars['String']>;
  invoiceNo?: Maybe<Scalars['String']>;
  pageInfo?: Maybe<Paging>;
};


export type QueryCustomApplyBussinessInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetCustomApplyBussinessInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchCustomApplyBussinessInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryYitihuaPackageListListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetYitihuaPackageListByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchYitihuaPackageListArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryTensorSpAreaPackageListListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetTensorSpAreaPackageListByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchTensorSpAreaPackageListArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryPartNumberSapInventoryPriceListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetPartNumberSapInventoryPriceByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchPartNumberSapInventoryPriceArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryTmsCustApplyLogListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetTmsCustApplyLogByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchTmsCustApplyLogArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryTmsPassPortOrderListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetTmsPassPortOrderByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetTmsPassPortProUserArgs = {
  role?: Maybe<Scalars['String']>;
};


export type QuerySearchTmsPassPortOrderArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QuerySpPassportListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetSpPassportByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetSpPassportBygdsNmArgs = {
  gdsNm?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  checkPlace?: Maybe<Scalars['String']>;
  pieceNo?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  passportNo?: Maybe<Scalars['String']>;
};


export type QuerySearchSpPassportArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryHsCustomsRegulationListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetHsCustomsRegulationByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchHsCustomsRegulationArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryTmsProduceWarehouseListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetTmsProduceWarehouseByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchTmsProduceWarehouseArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryTmsOrderInfoListArgs = {
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};


export type QueryGetTmsOrderInfoByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchTmsOrderInfoArgs = {
  searchStr?: Maybe<Scalars['String']>;
  conditionMap?: Maybe<Array<Maybe<KeyValueInput>>>;
  pageInfo?: Maybe<Paging>;
};

/** 关联公司资料及配置文件 */
export type RelatedCompanyConfig = {
  __typename?: 'RelatedCompanyConfig';
  name?: Maybe<Scalars['String']>;
  defualtParams?: Maybe<Array<Maybe<KeyValue>>>;
};

export type RelatedCompanyConfigInput = {
  name?: Maybe<Scalars['String']>;
  defualtParams?: Maybe<Array<Maybe<KeyValueInput>>>;
};

/** 运输委托书 */
export type RoadTransForwardingOrder = IDataInfo & {
  __typename?: 'RoadTransForwardingOrder';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 供应商名称 */
  supplierName?: Maybe<Scalars['String']>;
  /** 供应商编码 */
  supplierCode?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  wbsNo?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['String']>;
  /** 计划提货日期 */
  planTransDate?: Maybe<Scalars['Date']>;
  /** 提货地址 */
  pickupPlaces?: Maybe<Array<Maybe<TmsAddressInfo>>>;
  /** 送货地址 */
  targetPlaces?: Maybe<Array<Maybe<TmsAddressInfo>>>;
  attFiles?: Maybe<Array<Maybe<OrderFileInfo>>>;
  /** 委托编号 */
  fOrderNo?: Maybe<Scalars['String']>;
  transType?: Maybe<Scalars['String']>;
  transTypeCode?: Maybe<Scalars['String']>;
  normalDistance?: Maybe<Scalars['Float']>;
  DanDistance?: Maybe<Scalars['Float']>;
  /** 件数 */
  packNo?: Maybe<Scalars['Int']>;
  /** 毛重 */
  grossWeight?: Maybe<Scalars['Float']>;
  /** 其他要求 */
  otherRequire?: Maybe<Scalars['String']>;
  rejMemo?: Maybe<Scalars['String']>;
  taskContactPerson?: Maybe<Scalars['String']>;
  taskContactPersonId?: Maybe<Scalars['String']>;
  taskContactTel?: Maybe<Scalars['String']>;
  supplierPersonId?: Maybe<Scalars['String']>;
  supplierPersonName?: Maybe<Scalars['String']>;
  /** 车型要求 */
  applyVehicles?: Maybe<Array<Maybe<TmsVehicleInfo>>>;
  /** 附加服务 */
  extraServiceItems?: Maybe<Array<Maybe<TmsExtraServiceItem>>>;
  /** 对应的运输申请单 */
  tmsApplyNo?: Maybe<Scalars['String']>;
  /** 通过流程实例ID获取运输任务单 */
  transTaskOrders?: Maybe<Array<Maybe<TransTaskOrder>>>;
  applyInfo?: Maybe<TmsCustTransApply>;
  /** 运输状态 */
  transStatus?: Maybe<Scalars['String']>;
  /** 状态 */
  status?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  /** 账单编号 */
  payApplyNo?: Maybe<Scalars['String']>;
  /** 账单id */
  payApplyId?: Maybe<Scalars['String']>;
  /** 审核状态 待审核 审核通过 审核拒绝 */
  checkStatus?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  payApplyPerson?: Maybe<Scalars['String']>;
  payApplyDate?: Maybe<Scalars['Date']>;
  /** 供应商待办id */
  todoId?: Maybe<Scalars['String']>;
  batchNo?: Maybe<Scalars['String']>;
  batchInfo?: Maybe<Scalars['String']>;
  batchForderInfos?: Maybe<Array<Maybe<RoadTransForwardingOrder>>>;
  /** 总成本 供应商结算价 */
  totalCost?: Maybe<Scalars['Float']>;
  totalIncome?: Maybe<Scalars['Float']>;
  /** 总成本 */
  totoalCostAmount?: Maybe<Scalars['Float']>;
  /** 运费成本 */
  transportCostAmount?: Maybe<Scalars['Float']>;
  /** 装卸成本 */
  loadCostAmount?: Maybe<Scalars['Float']>;
  /** 其他成本 */
  otherCostAmount?: Maybe<Scalars['Float']>;
  /** 异常成本 */
  exceptionCostAmount?: Maybe<Scalars['Float']>;
  /** 保险成本 */
  insuranceCostAmount?: Maybe<Scalars['Float']>;
};

export type RoadTransForwardingOrderInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  supplierCode?: Maybe<Scalars['String']>;
  wbsNo?: Maybe<Scalars['String']>;
  todoId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['String']>;
  /** 计划提货日期 */
  planTransDate?: Maybe<Scalars['Date']>;
  /** 提货地址 */
  pickupPlaces?: Maybe<Array<Maybe<TmsAddressInfoInput>>>;
  /** 送货地址 */
  targetPlaces?: Maybe<Array<Maybe<TmsAddressInfoInput>>>;
  attFiles?: Maybe<Array<Maybe<OrderFileInfoInput>>>;
  /** 委托编号 */
  fOrderNo?: Maybe<Scalars['String']>;
  transType?: Maybe<Scalars['String']>;
  transTypeCode?: Maybe<Scalars['String']>;
  normalDistance?: Maybe<Scalars['Float']>;
  DanDistance?: Maybe<Scalars['Float']>;
  /** 件数 */
  packNo?: Maybe<Scalars['Int']>;
  /** 毛重 */
  grossWeight?: Maybe<Scalars['Float']>;
  /** 其他要求 */
  otherRequire?: Maybe<Scalars['String']>;
  rejMemo?: Maybe<Scalars['String']>;
  taskContactPerson?: Maybe<Scalars['String']>;
  taskContactPersonId?: Maybe<Scalars['String']>;
  taskContactTel?: Maybe<Scalars['String']>;
  supplierPersonId?: Maybe<Scalars['String']>;
  supplierPersonName?: Maybe<Scalars['String']>;
  /** 车型要求 */
  applyVehicles?: Maybe<Array<Maybe<TmsVehicleInfoInput>>>;
  /** 附加服务 */
  extraServiceItems?: Maybe<Array<Maybe<TmsExtraServiceItemInput>>>;
  /** 对应的运输申请单 */
  tmsApplyNo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  transStatus?: Maybe<Scalars['String']>;
  payApplyNo?: Maybe<Scalars['String']>;
  payApplyId?: Maybe<Scalars['String']>;
  checkStatus?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  payApplyPerson?: Maybe<Scalars['String']>;
  payApplyDate?: Maybe<Scalars['Date']>;
  batchNo?: Maybe<Scalars['String']>;
  batchInfo?: Maybe<Scalars['String']>;
};

export type RpaJobInfo = {
  __typename?: 'RpaJobInfo';
  id?: Maybe<Scalars['ID']>;
  taskCode?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
  taskName?: Maybe<Scalars['String']>;
  jobName?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  jobNo?: Maybe<Scalars['String']>;
  inputFileName?: Maybe<Scalars['String']>;
  ossPath?: Maybe<Scalars['String']>;
  processDetails?: Maybe<Array<Maybe<RpaJobProcessDetail>>>;
  jobRecords?: Maybe<Array<Maybe<RpaJobRecord>>>;
  clientID?: Maybe<Scalars['String']>;
  statusName?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type RpaJobInfoInput = {
  id?: Maybe<Scalars['ID']>;
  taskCode?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
  taskName?: Maybe<Scalars['String']>;
  jobName?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  inputFileName?: Maybe<Scalars['String']>;
  ossPath?: Maybe<Scalars['String']>;
  jobRecords?: Maybe<Array<Maybe<RpaJobRecordInput>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type RpaJobProcessDetail = {
  __typename?: 'RpaJobProcessDetail';
  id?: Maybe<Scalars['ID']>;
  jobNo?: Maybe<Scalars['String']>;
  seqNo?: Maybe<Scalars['Int']>;
  memo?: Maybe<Scalars['String']>;
  keyValue?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  statusName?: Maybe<Scalars['String']>;
  paraJson?: Maybe<Scalars['JSON']>;
  clientID?: Maybe<Scalars['String']>;
  callbackFun?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  processMessage?: Maybe<Scalars['String']>;
  callbackStatusName?: Maybe<Scalars['String']>;
  callbackMessage?: Maybe<Scalars['String']>;
  inputValue?: Maybe<Scalars['String']>;
  outputValue?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Float']>;
};

export type RpaJobProcessDetailInput = {
  id?: Maybe<Scalars['ID']>;
  jobNo?: Maybe<Scalars['String']>;
  seqNo?: Maybe<Scalars['Int']>;
  keyValue?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  statusName?: Maybe<Scalars['String']>;
  paraJson?: Maybe<Scalars['JSON']>;
  clientID?: Maybe<Scalars['String']>;
  processMessage?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  inputValue?: Maybe<Scalars['String']>;
  outputValue?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Float']>;
};

export type RpaJobRecord = {
  __typename?: 'RpaJobRecord';
  name?: Maybe<Scalars['String']>;
  main?: Maybe<Scalars['JSON']>;
  branch?: Maybe<Scalars['JSON']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type RpaJobRecordInput = {
  name?: Maybe<Scalars['String']>;
  main?: Maybe<Scalars['JSON']>;
  branch?: Maybe<Scalars['JSON']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type RpaTaskDef = {
  __typename?: 'RpaTaskDef';
  id?: Maybe<Scalars['ID']>;
  taskName?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  jobRecords?: Maybe<Array<Maybe<RpaJobRecord>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type RpaTaskDefInput = {
  id?: Maybe<Scalars['ID']>;
  taskName?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  jobRecords?: Maybe<Array<Maybe<RpaJobRecordInput>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type SwCusLicenseListVo = {
  __typename?: 'SWCusLicenseListVo';
  createUser?: Maybe<Scalars['String']>;
  updateTime?: Maybe<Scalars['String']>;
  preDecCusEcoRel?: Maybe<Scalars['String']>;
  acmpFormCodeName?: Maybe<Scalars['String']>;
  formSeqNo?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  acmpFormCode?: Maybe<Scalars['String']>;
  acmpFormName?: Maybe<Scalars['String']>;
  indbTime?: Maybe<Scalars['String']>;
  acmpFormNo?: Maybe<Scalars['String']>;
  updateUser?: Maybe<Scalars['String']>;
};

export type SwCusLicenseListVoInput = {
  createUser?: Maybe<Scalars['String']>;
  updateTime?: Maybe<Scalars['String']>;
  preDecCusEcoRel?: Maybe<Scalars['String']>;
  acmpFormCodeName?: Maybe<Scalars['String']>;
  formSeqNo?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  acmpFormCode?: Maybe<Scalars['String']>;
  acmpFormName?: Maybe<Scalars['String']>;
  indbTime?: Maybe<Scalars['String']>;
  acmpFormNo?: Maybe<Scalars['String']>;
  updateUser?: Maybe<Scalars['String']>;
};

export type SwPreDecCiqGoodsLimit = {
  __typename?: 'SWPreDecCiqGoodsLimit';
  gNo?: Maybe<Scalars['String']>;
  goodsLimitSeqNo?: Maybe<Scalars['String']>;
  updateTime?: Maybe<Scalars['String']>;
  licWrtofUnit?: Maybe<Scalars['String']>;
  licWrtofQty?: Maybe<Scalars['String']>;
  licWrtofUnitName?: Maybe<Scalars['String']>;
  licenceNo?: Maybe<Scalars['String']>;
  createUser?: Maybe<Scalars['String']>;
  licTypeName?: Maybe<Scalars['String']>;
  licTypeCodeName?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  licTypeCode?: Maybe<Scalars['String']>;
  indbTime?: Maybe<Scalars['String']>;
  updateUser?: Maybe<Scalars['String']>;
  licWrtofDetailNo?: Maybe<Scalars['String']>;
  preDecCiqGoodsLimitVinVo?: Maybe<Array<Maybe<SwPreDecCiqGoodsLimitVinVo>>>;
};

export type SwPreDecCiqGoodsLimitVinVo = {
  __typename?: 'SWPreDecCiqGoodsLimitVinVo';
  gNo?: Maybe<Scalars['String']>;
  invoiceNum?: Maybe<Scalars['String']>;
  goodsLimitSeqNo?: Maybe<Scalars['String']>;
  updateTime?: Maybe<Scalars['String']>;
  prodEnnm?: Maybe<Scalars['String']>;
  vinNo?: Maybe<Scalars['String']>;
  invoiceNo?: Maybe<Scalars['String']>;
  motorNo?: Maybe<Scalars['String']>;
  goodsLimitVinSeqNo?: Maybe<Scalars['String']>;
  prodCnnm?: Maybe<Scalars['String']>;
  pricePerUnit?: Maybe<Scalars['String']>;
  licenceNo?: Maybe<Scalars['String']>;
  createUser?: Maybe<Scalars['String']>;
  qualityQgp?: Maybe<Scalars['String']>;
  billLadDate?: Maybe<Scalars['String']>;
  vinCode?: Maybe<Scalars['String']>;
  licTypeCodeName?: Maybe<Scalars['String']>;
  modelEn?: Maybe<Scalars['String']>;
  chassisNo?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  licTypeCode?: Maybe<Scalars['String']>;
  indbTime?: Maybe<Scalars['String']>;
  updateUser?: Maybe<Scalars['String']>;
};

export type SwPreDecContainerVo = {
  __typename?: 'SWPreDecContainerVo';
  cntnrModeCode?: Maybe<Scalars['String']>;
  contSeqNo?: Maybe<Scalars['String']>;
  updateTime?: Maybe<Scalars['String']>;
  cntnrModeCodeName?: Maybe<Scalars['String']>;
  lclFlag?: Maybe<Scalars['String']>;
  goodsNo?: Maybe<Scalars['String']>;
  containerMdCodeName?: Maybe<Scalars['String']>;
  containerNo?: Maybe<Scalars['String']>;
  createUser?: Maybe<Scalars['String']>;
  lclFlagName?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  containerMdCode?: Maybe<Scalars['String']>;
  indbTime?: Maybe<Scalars['String']>;
  updateUser?: Maybe<Scalars['String']>;
  addList?: Maybe<Scalars['String']>;
  containerWt?: Maybe<Scalars['String']>;
};

export type SwPreDecContainerVoInput = {
  cntnrModeCode?: Maybe<Scalars['String']>;
  contSeqNo?: Maybe<Scalars['String']>;
  updateTime?: Maybe<Scalars['String']>;
  cntnrModeCodeName?: Maybe<Scalars['String']>;
  lclFlag?: Maybe<Scalars['String']>;
  goodsNo?: Maybe<Scalars['String']>;
  containerMdCodeName?: Maybe<Scalars['String']>;
  containerNo?: Maybe<Scalars['String']>;
  createUser?: Maybe<Scalars['String']>;
  lclFlagName?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  containerMdCode?: Maybe<Scalars['String']>;
  indbTime?: Maybe<Scalars['String']>;
  updateUser?: Maybe<Scalars['String']>;
  addList?: Maybe<Scalars['String']>;
  containerWt?: Maybe<Scalars['String']>;
};

export type SwPreDecDocVo = {
  __typename?: 'SWPreDecDocVo';
  attEdocId?: Maybe<Scalars['String']>;
  attEdocNo?: Maybe<Scalars['String']>;
  attEdocPath?: Maybe<Scalars['String']>;
  attEdocSize?: Maybe<Scalars['String']>;
  attEdocStatus?: Maybe<Scalars['String']>;
  attFmtTypeCode?: Maybe<Scalars['String']>;
  attSeqNo?: Maybe<Scalars['String']>;
  attTypeCode?: Maybe<Scalars['String']>;
  attTypeCodeName?: Maybe<Scalars['String']>;
  belongWkunitCode?: Maybe<Scalars['String']>;
  belongWkunitName?: Maybe<Scalars['String']>;
  bizTypeCode?: Maybe<Scalars['String']>;
  createUser?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  delList?: Maybe<Scalars['String']>;
  entOrigFileName?: Maybe<Scalars['String']>;
  indbTime?: Maybe<Scalars['String']>;
  signDate?: Maybe<Scalars['String']>;
  signWkunitCode?: Maybe<Scalars['String']>;
  updateTime?: Maybe<Scalars['String']>;
  updateUser?: Maybe<Scalars['String']>;
  uploadOpTypeCode?: Maybe<Scalars['String']>;
};

export type SwPreDecDocVoInput = {
  attEdocId?: Maybe<Scalars['String']>;
  attEdocNo?: Maybe<Scalars['String']>;
  attEdocPath?: Maybe<Scalars['String']>;
  attEdocSize?: Maybe<Scalars['String']>;
  attEdocStatus?: Maybe<Scalars['String']>;
  attFmtTypeCode?: Maybe<Scalars['String']>;
  attSeqNo?: Maybe<Scalars['String']>;
  attTypeCode?: Maybe<Scalars['String']>;
  attTypeCodeName?: Maybe<Scalars['String']>;
  belongWkunitCode?: Maybe<Scalars['String']>;
  belongWkunitName?: Maybe<Scalars['String']>;
  bizTypeCode?: Maybe<Scalars['String']>;
  createUser?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  delList?: Maybe<Scalars['String']>;
  entOrigFileName?: Maybe<Scalars['String']>;
  indbTime?: Maybe<Scalars['String']>;
  signDate?: Maybe<Scalars['String']>;
  signWkunitCode?: Maybe<Scalars['String']>;
  updateTime?: Maybe<Scalars['String']>;
  updateUser?: Maybe<Scalars['String']>;
  uploadOpTypeCode?: Maybe<Scalars['String']>;
};

/** SAMC 业务跟踪 一线二线关务相关 */
export type ScmSamcTrackInfo = IDataInfo & {
  __typename?: 'ScmSamcTrackInfo';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 业务编号 翔运 */
  refNo?: Maybe<Scalars['String']>;
  /** 提运单号 空运 总运单号-分运单号 */
  billNo?: Maybe<Scalars['String']>;
  /** 方便查询的运单号 分运单号或海运提单号 */
  billNo1?: Maybe<Scalars['String']>;
  transMode?: Maybe<Scalars['String']>;
  /** 客户跟踪号 */
  custRefNo?: Maybe<Scalars['String']>;
  /**
   * 上飞操作类型
   *
   * "orderType": {
   *   "id": "一线进祝桥",
   *   "name": "一线进祝桥",
   *   "values": {
   *     "type": "上飞全流程",
   *     "desc": ""
   *   }
   * }
   */
  orderType?: Maybe<KeyValue>;
  /**
   * 上飞操作类型 中转标志
   * 中转标志：0-非中转；1-中转
   * 中转海贸或其他仓库
   */
  transFlag?: Maybe<Scalars['String']>;
  /** 委托人 */
  attorneyName?: Maybe<Scalars['String']>;
  /** 委托人联系方式 */
  attorneyTel?: Maybe<Scalars['String']>;
  /** 委托人联系地址 */
  attorneyEmail?: Maybe<Scalars['String']>;
  /** 收货人 采购 */
  consigneeName?: Maybe<Scalars['String']>;
  /** 收货人联系方式 */
  consigneeTel?: Maybe<Scalars['String']>;
  /** 收货人联系地址 */
  consigneeEmail?: Maybe<Scalars['String']>;
  /** 收货仓库 */
  destinationPlace?: Maybe<Scalars['String']>;
  /**
   * 其他附加参数
   * 机型 planeType
   * 子系统 subSystem
   */
  params?: Maybe<Scalars['JSON']>;
  /** 委托时间 */
  attorneyTime?: Maybe<Scalars['Date']>;
  /** 计划到港时间 */
  planArriveTime?: Maybe<Scalars['Date']>;
  /** 到港时间 */
  arriveTime?: Maybe<Scalars['Date']>;
  /** 邮件收到时间 */
  mailArriveTime?: Maybe<Scalars['Date']>;
  /** 接单时间 */
  orderTime?: Maybe<Scalars['Date']>;
  /**
   * 港口清关时间
   * 进口港口清关时间
   */
  portClearTime?: Maybe<Scalars['Date']>;
  /** 相关报关单基础信息 */
  relCustomDeclareInfos?: Maybe<Array<Maybe<CustomDeclareInfo>>>;
  /** 港口清关报关单号 */
  portClearEntryId?: Maybe<Scalars['String']>;
  /**
   * 港口货物存放地点
   * goodsPlace
   */
  portGoodsPlace?: Maybe<Scalars['String']>;
  /**
   * 港口货物存放地点 监管区,物流区
   * goodsPlace
   */
  portGoodsArea?: Maybe<Scalars['String']>;
  /** 商品名称 */
  goodsName?: Maybe<Scalars['String']>;
  /** 合同号 */
  contractNo?: Maybe<Scalars['String']>;
  /** 尺寸 */
  size?: Maybe<Scalars['String']>;
  /** 件数 */
  packNo?: Maybe<Scalars['Int']>;
  /** 毛重 */
  grossWeight?: Maybe<Scalars['Float']>;
  /**
   * 仓库名称
   * 当前所在仓库名称
   * 关区,鼎鑫,同维仓库
   */
  warehouseName?: Maybe<Scalars['String']>;
  /** 进仓编号 */
  inWarehouseNo?: Maybe<Scalars['String']>;
  /** 进仓时间 */
  inWarehouseTime?: Maybe<Scalars['String']>;
  /** 进仓类型 */
  inWarehouseType?: Maybe<Scalars['String']>;
  /** 加急标志 */
  premFlag?: Maybe<Scalars['Boolean']>;
  /** 送货标志 */
  deliveryFlag?: Maybe<Scalars['String']>;
  iEPort?: Maybe<Scalars['String']>;
  iEPortName?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type ScmSamcTrackInfoInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  /** 提运单号 空运 总运单号-分运单号 */
  billNo?: Maybe<Scalars['String']>;
  /** 方便查询的运单号 分运单号或海运提单号 */
  billNo1?: Maybe<Scalars['String']>;
  transMode?: Maybe<Scalars['String']>;
  /** 客户跟踪号 */
  custRefNo?: Maybe<Scalars['String']>;
  /**
   * 上飞操作类型
   *
   * "orderType": {
   *   "id": "一线进祝桥",
   *   "name": "一线进祝桥",
   *   "values": {
   *     "type": "上飞全流程",
   *     "desc": ""
   *   }
   * }
   */
  orderType?: Maybe<KeyValueInput>;
  /**
   * 上飞操作类型 中转标志
   * 中转标志：0-非中转；1-中转
   * 中转海贸或其他仓库
   */
  transFlag?: Maybe<Scalars['String']>;
  /** 委托人 */
  attorneyName?: Maybe<Scalars['String']>;
  /** 委托人联系方式 */
  attorneyTel?: Maybe<Scalars['String']>;
  /** 委托人联系地址 */
  attorneyEmail?: Maybe<Scalars['String']>;
  /** 收货人 采购 */
  consigneeName?: Maybe<Scalars['String']>;
  /** 收货人联系方式 */
  consigneeTel?: Maybe<Scalars['String']>;
  /** 收货人联系地址 */
  consigneeEmail?: Maybe<Scalars['String']>;
  /** 委托时间 */
  attorneyTime?: Maybe<Scalars['Date']>;
  /** 计划到港时间 */
  planArriveTime?: Maybe<Scalars['Date']>;
  /** 到港时间 */
  arriveTime?: Maybe<Scalars['Date']>;
  /** 邮件收到时间 */
  mailArriveTime?: Maybe<Scalars['Date']>;
  /** 接单时间 */
  orderTime?: Maybe<Scalars['Date']>;
  /**
   * 港口清关时间
   * 进口港口清关时间 结关时间
   */
  portClearTime?: Maybe<Scalars['Date']>;
  /** 进出港口 */
  iEPortName?: Maybe<Scalars['String']>;
  params?: Maybe<Scalars['JSON']>;
  iEPort?: Maybe<Scalars['String']>;
  /** 相关报关单基础信息 */
  relCustomDeclareInfos?: Maybe<Array<Maybe<CustomDeclareInfoInput>>>;
  /** 港口清关报关单号 */
  portClearEntryId?: Maybe<Scalars['String']>;
  /** 商品名称 */
  goodsName?: Maybe<Scalars['String']>;
  /** 合同号 */
  contractNo?: Maybe<Scalars['String']>;
  /** 尺寸 */
  size?: Maybe<Scalars['String']>;
  /** 件数 */
  packNo?: Maybe<Scalars['Int']>;
  /** 毛重 */
  grossWeight?: Maybe<Scalars['Float']>;
  /**
   * 仓库名称
   * 当前所在仓库名称
   */
  warehouseName?: Maybe<Scalars['String']>;
  /** 进仓编号 */
  inWarehouseNo?: Maybe<Scalars['String']>;
  /** 进仓时间 */
  inWarehouseTime?: Maybe<Scalars['String']>;
  /** 进仓类型 */
  inWarehouseType?: Maybe<Scalars['String']>;
  /** 加急标志 */
  premFlag?: Maybe<Scalars['Boolean']>;
  /** 送货标志 */
  deliveryFlag?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type SearchTypeReponse = {
  __typename?: 'SearchTypeReponse';
  coditionDisplayName?: Maybe<Scalars['String']>;
  coditionEnumName?: Maybe<Scalars['String']>;
  searchCount?: Maybe<Scalars['Int']>;
  conditionTooltip?: Maybe<Scalars['String']>;
};

/** 发送单一窗口状态信息 */
export type SendProgressStatus = {
  __typename?: 'SendProgressStatus';
  id?: Maybe<Scalars['ID']>;
  orderNo?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** 服务对账明细 */
export type ServiceBillDetailInfo = IDataInfo & {
  __typename?: 'ServiceBillDetailInfo';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 批次号 服务对账明细 */
  batchNo?: Maybe<Scalars['String']>;
  /** 关联业务单据号 可以是仓储,运输等不同的collection */
  refNo?: Maybe<Scalars['String']>;
  /** 总业务编号 全部业务统一编号 */
  billOrderNo?: Maybe<Scalars['String']>;
  /**
   * 业务类型
   * tms:运输业务 仓储业务
   * 报关业务 有独立接单的业务
   */
  refType?: Maybe<Scalars['String']>;
  /** 账单月份 */
  billMonth?: Maybe<Scalars['String']>;
  /** 币制 */
  currency?: Maybe<Scalars['String']>;
  /** 计费类型 */
  chargeType?: Maybe<Scalars['String']>;
  /** 费用种类 运费 装卸费用 其他 */
  serviceItemType?: Maybe<Scalars['String']>;
  /** 计费单位 */
  chargeUnit?: Maybe<Scalars['String']>;
  /** 计费数量 */
  chargeQty?: Maybe<Scalars['Float']>;
  /** 计费单价 */
  chargePrice?: Maybe<Scalars['Float']>;
  /** 计费金额 */
  chargeAmount?: Maybe<Scalars['Float']>;
  /** 计费合同号 */
  chargeContractNo?: Maybe<Scalars['String']>;
  /** 收费客户代码 */
  chargeCustomerCode?: Maybe<Scalars['String']>;
  /** 收费客户名称 */
  chargeCustomerName?: Maybe<Scalars['String']>;
  /** 收费名称 */
  chargeName?: Maybe<Scalars['String']>;
  /** 收费项目代码 对应合同的收费项目代码 */
  chargeItemCode?: Maybe<Scalars['String']>;
  /** 收费项目备注 */
  chargeItemRemark?: Maybe<Scalars['String']>;
  /** 折扣率 */
  discountRate?: Maybe<Scalars['Float']>;
  /** 折扣金额 */
  discountAmount?: Maybe<Scalars['Float']>;
  /** 客户编号 */
  customerNo?: Maybe<Scalars['String']>;
  /** 客户名称 */
  customerName?: Maybe<Scalars['String']>;
  /** 合同编号 */
  contractNo?: Maybe<Scalars['String']>;
  /** 开票项目 */
  invoiceItem?: Maybe<Scalars['String']>;
  /** 费用确认状态  未确认,已确认,待修改,已锁定 */
  chargeConfirmStatus?: Maybe<Scalars['String']>;
  /** 开票项目名称 */
  invoiceItemName?: Maybe<Scalars['String']>;
  /** 开票金额 */
  invoiceAmount?: Maybe<Scalars['Float']>;
  /** 开票税率 */
  invoiceTaxRate?: Maybe<Scalars['Float']>;
  /** 发票类型 普通发票,专用发票 */
  invoiceType?: Maybe<Scalars['String']>;
  /** 收入部门 */
  incomeDept?: Maybe<Scalars['String']>;
  /** 收入部门名称 */
  incomeDeptName?: Maybe<Scalars['String']>;
  /** 对账单编号 */
  custBillNo?: Maybe<Scalars['String']>;
  custBillid?: Maybe<Scalars['String']>;
  invoiceApplyid?: Maybe<Scalars['String']>;
  payApplyid?: Maybe<Scalars['String']>;
  /** 发票号 */
  invoiceNo?: Maybe<Scalars['String']>;
  invoiceApplyNo?: Maybe<Scalars['String']>;
  payApplyNo?: Maybe<Scalars['String']>;
  payApplyRemark?: Maybe<Scalars['String']>;
  /** 账单状态 */
  billStatus?: Maybe<Scalars['String']>;
  /** 发票日期 */
  invoiceDate?: Maybe<Scalars['String']>;
  applyStatus?: Maybe<Scalars['String']>;
  genStatus?: Maybe<Scalars['String']>;
  genNo?: Maybe<Scalars['String']>;
  dfflag?: Maybe<Scalars['Boolean']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type ServiceBillDetailInfoInput = {
  appID?: Maybe<Scalars['String']>;
  /** 批次号 服务对账明细 */
  id?: Maybe<Scalars['ID']>;
  /** 批次号 服务对账明细 */
  batchNo?: Maybe<Scalars['String']>;
  /** 关联业务单据号 可以是仓储,运输等不同的collection */
  refNo?: Maybe<Scalars['String']>;
  /** 总业务编号 全部业务统一编号 */
  billOrderNo?: Maybe<Scalars['String']>;
  /**
   * 业务类型
   * tms:运输业务 仓储业务
   * 报关业务 有独立接单的业务
   */
  refType?: Maybe<Scalars['String']>;
  /** 账单月份 */
  billMonth?: Maybe<Scalars['String']>;
  /** 币制 */
  currency?: Maybe<Scalars['String']>;
  /** 计费类型 */
  chargeType?: Maybe<Scalars['String']>;
  serviceItemType?: Maybe<Scalars['String']>;
  /** 计费单位 */
  chargeUnit?: Maybe<Scalars['String']>;
  /** 计费数量 */
  chargeQty?: Maybe<Scalars['Float']>;
  /** 计费单价 */
  chargePrice?: Maybe<Scalars['Float']>;
  /** 计费金额 */
  chargeAmount?: Maybe<Scalars['Float']>;
  /** 计费合同号 */
  chargeContractNo?: Maybe<Scalars['String']>;
  /** 收费客户代码 */
  chargeCustomerCode?: Maybe<Scalars['String']>;
  /** 收费客户名称 */
  chargeCustomerName?: Maybe<Scalars['String']>;
  /** 收费名称 */
  chargeName?: Maybe<Scalars['String']>;
  /** 收费项目代码 对应合同的收费项目代码 */
  chargeItemCode?: Maybe<Scalars['String']>;
  /** 收费项目备注 */
  chargeItemRemark?: Maybe<Scalars['String']>;
  /** 折扣率 */
  discountRate?: Maybe<Scalars['Float']>;
  /** 折扣金额 */
  discountAmount?: Maybe<Scalars['Float']>;
  /** 客户编号 */
  customerNo?: Maybe<Scalars['String']>;
  /** 费用确认状态  未确认,已确认,待修改,已锁定 */
  chargeConfirmStatus?: Maybe<Scalars['String']>;
  /** 客户名称 */
  customerName?: Maybe<Scalars['String']>;
  /** 合同编号 */
  contractNo?: Maybe<Scalars['String']>;
  /** 开票项目 */
  invoiceItem?: Maybe<Scalars['String']>;
  /** 开票项目名称 */
  invoiceItemName?: Maybe<Scalars['String']>;
  /** 开票金额 */
  invoiceAmount?: Maybe<Scalars['Float']>;
  /** 开票税率 */
  invoiceTaxRate?: Maybe<Scalars['Float']>;
  /** 发票类型 普通发票,专用发票 */
  invoiceType?: Maybe<Scalars['String']>;
  /** 收入部门 */
  incomeDept?: Maybe<Scalars['String']>;
  /** 收入部门名称 */
  incomeDeptName?: Maybe<Scalars['String']>;
  /** 对账单编号 */
  custBillNo?: Maybe<Scalars['String']>;
  /** 发票号 */
  invoiceNo?: Maybe<Scalars['String']>;
  invoiceApplyNo?: Maybe<Scalars['String']>;
  payApplyNo?: Maybe<Scalars['String']>;
  payApplyRemark?: Maybe<Scalars['String']>;
  applyStatus?: Maybe<Scalars['String']>;
  genStatus?: Maybe<Scalars['String']>;
  genNo?: Maybe<Scalars['String']>;
  dfflag?: Maybe<Scalars['Boolean']>;
  billStatus?: Maybe<Scalars['String']>;
  custBillid?: Maybe<Scalars['String']>;
  invoiceApplyid?: Maybe<Scalars['String']>;
  payApplyid?: Maybe<Scalars['String']>;
  /** 发票日期 */
  invoiceDate?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 成本 */
export type ServicePurchaseDetailInfo = IDataInfo & {
  __typename?: 'ServicePurchaseDetailInfo';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 批次号 */
  batchNo?: Maybe<Scalars['String']>;
  /** 关联业务单据号 */
  refNo?: Maybe<Scalars['String']>;
  /** 总业务编号 全部业务统一编号 */
  billOrderNo?: Maybe<Scalars['String']>;
  /** 外部委托单号 */
  forwardOrderNo?: Maybe<Scalars['String']>;
  /**
   * 业务类型
   * tms:运输业务 仓储业务
   * 报关业务 有独立接单的业务
   */
  refType?: Maybe<Scalars['String']>;
  /** 计费类型 */
  chargeType?: Maybe<Scalars['String']>;
  /** 计费单位 */
  chargeUnit?: Maybe<Scalars['String']>;
  /** 计费数量 */
  chargeQty?: Maybe<Scalars['Float']>;
  /** 计费单价 */
  chargePrice?: Maybe<Scalars['Float']>;
  /** 计费金额 */
  chargeAmount?: Maybe<Scalars['Float']>;
  /** 计费合同号 */
  chargeContractNo?: Maybe<Scalars['String']>;
  /** 收费客户代码 */
  chargeCustomerCode?: Maybe<Scalars['String']>;
  /** 收费客户名称 */
  chargeCustomerName?: Maybe<Scalars['String']>;
  /** 收费名称 */
  chargeName?: Maybe<Scalars['String']>;
  serviceItemType?: Maybe<Scalars['String']>;
  /** 收费项目代码 */
  chargeItemCode?: Maybe<Scalars['String']>;
  /** 收费项目备注 */
  chargeItemRemark?: Maybe<Scalars['String']>;
  /** 折扣率 */
  discountRate?: Maybe<Scalars['Float']>;
  /** 折扣金额 */
  discountAmount?: Maybe<Scalars['Float']>;
  /** 成本发票号码 */
  costInvoiceNo?: Maybe<Scalars['String']>;
  /** 收入总价 */
  incomeAmount?: Maybe<Scalars['Float']>;
  /** 服务费总价 */
  serviceAmount?: Maybe<Scalars['Float']>;
  /** 状态 */
  process_instance_id?: Maybe<Scalars['String']>;
  /** 审核状态 */
  applyStatus?: Maybe<Scalars['String']>;
  /** 这个是什么状态? */
  orderStatus?: Maybe<Scalars['String']>;
  /** 是生成的还是手动添加的 */
  genStatus?: Maybe<Scalars['String']>;
  custBillNo?: Maybe<Scalars['String']>;
  invoiceItem?: Maybe<Scalars['String']>;
  invoiceNo?: Maybe<Scalars['String']>;
  invoiceApplyNo?: Maybe<Scalars['String']>;
  payApplyNo?: Maybe<Scalars['String']>;
  billStatus?: Maybe<Scalars['String']>;
  /** 费用唯一标志  billOrderNo+类型（chargeItemCode）+编号 和成本对照 */
  genNo?: Maybe<Scalars['String']>;
  custBillid?: Maybe<Scalars['String']>;
  invoiceApplyid?: Maybe<Scalars['String']>;
  payApplyid?: Maybe<Scalars['String']>;
  dfflag?: Maybe<Scalars['Boolean']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 业务成本明细输入Input */
export type ServicePurchaseDetailInfoInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  /** 批次号 一体化 卡口验放凭证 */
  batchNo?: Maybe<Scalars['String']>;
  billOrderNo?: Maybe<Scalars['String']>;
  /** 外部委托单号 */
  forwardOrderNo?: Maybe<Scalars['String']>;
  chargeContractNo?: Maybe<Scalars['String']>;
  costInvoiceNo?: Maybe<Scalars['String']>;
  chargeCustomerCode?: Maybe<Scalars['String']>;
  /** 收费客户名称 */
  chargeCustomerName?: Maybe<Scalars['String']>;
  /** 关联业务单据号 */
  refNo?: Maybe<Scalars['String']>;
  /**
   * 业务类型
   * tms:运输业务 仓储业务
   * 报关业务 有独立接单的业务
   */
  refType?: Maybe<Scalars['String']>;
  /** 计费类型 */
  chargeType?: Maybe<Scalars['String']>;
  /** 计费单位 */
  chargeUnit?: Maybe<Scalars['String']>;
  /** 计费数量 */
  chargeQty?: Maybe<Scalars['Float']>;
  /** 计费单价 */
  chargePrice?: Maybe<Scalars['Float']>;
  /** 计费金额 */
  chargeAmount?: Maybe<Scalars['Float']>;
  /** 计费合同号 */
  purchaseContractNo?: Maybe<Scalars['String']>;
  /** 收费客户代码 */
  purchaseCustomerCode?: Maybe<Scalars['String']>;
  /** 收费客户名称 */
  purchaseeCustomerName?: Maybe<Scalars['String']>;
  /** 收费名称 */
  chargeName?: Maybe<Scalars['String']>;
  serviceItemType?: Maybe<Scalars['String']>;
  /** 收费项目代码 */
  chargeItemCode?: Maybe<Scalars['String']>;
  /** 收费项目备注 */
  chargeItemRemark?: Maybe<Scalars['String']>;
  /** 折扣率 */
  discountRate?: Maybe<Scalars['Float']>;
  /** 折扣金额 */
  discountAmount?: Maybe<Scalars['Float']>;
  /** 币制 */
  currency?: Maybe<Scalars['String']>;
  process_instance_id?: Maybe<Scalars['String']>;
  /** 这个是什么状态? */
  orderStatus?: Maybe<Scalars['String']>;
  /** 审核状态是什么状态 */
  applyStatus?: Maybe<Scalars['String']>;
  /** 是生成的还是手动添加的 */
  genStatus?: Maybe<Scalars['String']>;
  custBillNo?: Maybe<Scalars['String']>;
  invoiceItem?: Maybe<Scalars['String']>;
  invoiceNo?: Maybe<Scalars['String']>;
  invoiceApplyNo?: Maybe<Scalars['String']>;
  payApplyNo?: Maybe<Scalars['String']>;
  billStatus?: Maybe<Scalars['String']>;
  genNo?: Maybe<Scalars['String']>;
  custBillid?: Maybe<Scalars['String']>;
  invoiceApplyid?: Maybe<Scalars['String']>;
  payApplyid?: Maybe<Scalars['String']>;
  dfflag?: Maybe<Scalars['Boolean']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 商品库存进出明细 */
export type SpAreaInventory = IDataInfo & {
  __typename?: 'SpAreaInventory';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /**
   * 仓库编号
   * S001
   */
  warehouse?: Maybe<Scalars['String']>;
  /**
   * 仓库名称
   * S001
   */
  warehouseName?: Maybe<Scalars['String']>;
  /**
   *     库存类型
   *
   *
   *     原材料
   *     半成品
   *     成品
   *     退换货 Maintenance, repair, and operations (MRO) goods
   *     次品
   *     丢失 都可以用进,出的形式进行调整 这个Transctio Type是不是没用了?
   *
   *
   *
   * are items used to support and facilitate the production of finished
   */
  inventoryType?: Maybe<Scalars['String']>;
  /**
   * 入库数量
   * 这个数量和单位现在是根据海关的数量和单位来,以后可能会转换为仓库使用的数量
   * inwardQty
   */
  inwardQty?: Maybe<Scalars['Float']>;
  /** 出库数量 */
  outwardQty?: Maybe<Scalars['Float']>;
  /** 计量单位 */
  unit?: Maybe<Scalars['String']>;
  /** netWeight */
  netWeight?: Maybe<Scalars['Float']>;
  netWeightUnit?: Maybe<Scalars['String']>;
  /** 毛重 */
  grossWeight?: Maybe<Scalars['Float']>;
  /** 毛重单位 */
  grossWeightUnit?: Maybe<Scalars['String']>;
  /**
   * 体积
   * 计价体积
   */
  stockVolumn?: Maybe<Scalars['Float']>;
  /**
   * 体积
   * 计价体积 单位
   */
  stockVolumnUnit?: Maybe<Scalars['String']>;
  /** 供应商 */
  vender?: Maybe<Scalars['String']>;
  /** 原产国 */
  countryOfOrigin?: Maybe<Scalars['String']>;
  /** 海关编码 */
  hsCode?: Maybe<Scalars['String']>;
  /** 海关申报单位 */
  customsUnit?: Maybe<Scalars['String']>;
  /** 海关数量 */
  customsQty?: Maybe<Scalars['String']>;
  /**
   * SKU 商品唯一编码,
   * 这里是SAP partNo
   */
  sku?: Maybe<Scalars['String']>;
  /** 品名 */
  itemName?: Maybe<Scalars['String']>;
  /** 商品描述 型号等 */
  itemDesp?: Maybe<Scalars['String']>;
  /** 总成本金额 */
  costTotal?: Maybe<Scalars['Float']>;
  /** 单件成本 */
  priceCost?: Maybe<Scalars['Float']>;
  /** 币制 */
  curr?: Maybe<Scalars['String']>;
  /** 汇率 */
  currRate?: Maybe<Scalars['Float']>;
  /** 原始单证编号 */
  originDocNo?: Maybe<Scalars['String']>;
  /**
   * 原始单证类型
   * 提发货凭证
   * 卡口验放凭证
   */
  originDocType?: Maybe<Scalars['String']>;
  /**
   *     0：空车进出区
   *     1：非径予放行货物
   *     2：先报后送
   *     3：分送集报
   *     4：区内流转
   *     5：区间流转
   *     6：进出境径予放行
   *     7：二线进出
   *     8：便捷进出区
   *     9：一单多车B
   * 怎么对应两个对应的?
   *
   *     1 、一线进出
   *     2、先报后送
   *     3、分送集报
   *     4、区内流转
   *     5、区间流转
   *     7、二线进出
   *     8、调整类
   *     9、一单多车B
   */
  originTransactionType?: Maybe<Scalars['String']>;
  /** 其他备注信息 */
  originDocMemo?: Maybe<Scalars['String']>;
  /** 操作时间 */
  transctionDate?: Maybe<Scalars['Date']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 综保区 商品库存 */
export type SpAreaInventoryGroupView = {
  __typename?: 'SpAreaInventoryGroupView';
  warehouseName?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  hsCode?: Maybe<Scalars['String']>;
  unitQtySet?: Maybe<Array<Maybe<GroupItemDetail>>>;
  itemDesp?: Maybe<Scalars['String']>;
  itemName?: Maybe<Scalars['String']>;
  sumInQty?: Maybe<Scalars['Float']>;
  hsCodeSize?: Maybe<Scalars['Int']>;
  unitSize?: Maybe<Scalars['Int']>;
  hsCodeSet?: Maybe<Array<Maybe<Scalars['String']>>>;
  sumOutQty?: Maybe<Scalars['Float']>;
  inStockQty?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  unitCount?: Maybe<Scalars['Int']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type SpAreaInventoryInput = {
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /**
   * 仓库编号
   * S001
   */
  warehouse?: Maybe<Scalars['String']>;
  /**
   * 仓库名称
   * S001
   */
  warehouseName?: Maybe<Scalars['String']>;
  /**
   *     库存类型
   *
   *
   *     原材料
   *     半成品
   *     成品
   *     退换货 Maintenance, repair, and operations (MRO) goods
   *     次品
   *     丢失 都可以用进,出的形式进行调整 这个Transctio Type是不是没用了?
   *
   *
   *
   * are items used to support and facilitate the production of finished
   */
  inventoryType?: Maybe<Scalars['String']>;
  /**
   * 入库数量
   * inwardQty
   */
  inwardQty?: Maybe<Scalars['Float']>;
  /** 出库数量 */
  outwardQty?: Maybe<Scalars['Float']>;
  /** 计量单位 */
  unit?: Maybe<Scalars['String']>;
  /** netWeight */
  netWeight?: Maybe<Scalars['Float']>;
  netWeightUnit?: Maybe<Scalars['String']>;
  /** 毛重 */
  grossWeight?: Maybe<Scalars['Float']>;
  /** 毛重单位 */
  grossWeightUnit?: Maybe<Scalars['String']>;
  /**
   * 体积
   * 计价体积
   */
  stockVolumn?: Maybe<Scalars['Float']>;
  /**
   * 体积
   * 计价体积 单位
   */
  stockVolumnUnit?: Maybe<Scalars['String']>;
  /** 供应商 */
  vender?: Maybe<Scalars['String']>;
  /** 原产国 */
  countryOfOrigin?: Maybe<Scalars['String']>;
  /** 海关编码 */
  hsCode?: Maybe<Scalars['String']>;
  /** 海关申报单位 */
  customsUnit?: Maybe<Scalars['String']>;
  /** 海关数量 */
  customsQty?: Maybe<Scalars['String']>;
  /**
   * SKU 商品唯一编码,
   * 这里是SAP partNo
   */
  sku?: Maybe<Scalars['String']>;
  /** 品名 */
  itemName?: Maybe<Scalars['String']>;
  /** 商品描述 型号等 */
  itemDesp?: Maybe<Scalars['String']>;
  /** 总成本金额 */
  costTotal?: Maybe<Scalars['Float']>;
  /** 单件成本 */
  priceCost?: Maybe<Scalars['Float']>;
  /** 币制 */
  curr?: Maybe<Scalars['String']>;
  /** 汇率 */
  currRate?: Maybe<Scalars['Float']>;
  /** 原始单证编号 */
  originDocNo?: Maybe<Scalars['String']>;
  /**
   * 原始单证类型
   * 提发货凭证
   * 卡口验放凭证
   */
  originDocType?: Maybe<Scalars['String']>;
  /** 其他备注信息 */
  originDocMemo?: Maybe<Scalars['String']>;
  /** 操作时间 */
  transctionDate?: Maybe<Scalars['Date']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 一体化 卡口验放凭证 */
export type SpAreaPortRelease = IDataInfo & {
  __typename?: 'SpAreaPortRelease';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /**
   * 组织id
   *
   * 后台生成
   */
  orgId?: Maybe<Scalars['String']>;
  /** 卡口验放原始ID */
  releaseid?: Maybe<Scalars['String']>;
  /** 提发货区域  浦东  大场 */
  tfhtype?: Maybe<Scalars['String']>;
  /**
   * 账号信息
   * 祝桥/大场
   * tfhtype 冗余
   * J/D sf001/sf002
   */
  area?: Maybe<Scalars['String']>;
  /**
   * 凭证编号
   *
   * 后台生成
   */
  preentNo?: Maybe<Scalars['String']>;
  /**
   *     出入库类型
   *
   * 0：空车进出区
   * 1：非径予放行货物
   * 2：先报后送
   * 3：分送集报
   * 4：区内流转
   * 5：区间流转
   * 6：进出境径予放行
   * 7：二线进出
   * 8：便捷进出区
   * 9：一单多车B
   */
  inExpType?: Maybe<Scalars['String']>;
  /**
   * 经营企业编号
   *
   * nan
   */
  bizopEtpsNo?: Maybe<Scalars['String']>;
  /**
   * 经营单位名称
   *
   * nan
   */
  bizopEtpsNm?: Maybe<Scalars['String']>;
  /**
   * 经营单位社会信用代码
   *
   * nan
   */
  bizopEtpsSccd?: Maybe<Scalars['String']>;
  /**
   * 进出标志
   *
   * I-进区；E-出区
   */
  ieTypecd?: Maybe<Scalars['String']>;
  /**
   * 料件、成品标志
   *
   * I-料件,E-成品, S-设备,L-边角料,D-残次品,F-待维修件,G-已维修件,H-维修边角料,J-维修坏件（空车进出区不填写）
   */
  mtpckEndprdTypecd?: Maybe<Scalars['String']>;
  /**
   * 进出境关别
   *
   * 关区代码
   */
  impexpPortcd?: Maybe<Scalars['String']>;
  /**
   * 主管海关
   *
   * yyyy-MM-dd HH:mm:ss
   */
  masterCuscd?: Maybe<Scalars['String']>;
  /**
   * 申报日期
   *
   * 后台反填
   */
  dclTime?: Maybe<Scalars['String']>;
  /**
   * 进出卡口状态
   *
   * 后台反填
   */
  portIochkptStucd?: Maybe<Scalars['String']>;
  /**
   * 是否记入企业账户标记
   *
   * 0-不计入；1-计入
   */
  recAccountFlag?: Maybe<Scalars['String']>;
  /**
   * 境内收发货企业名称
   *
   * nan
   */
  rcvgdEtpsNm?: Maybe<Scalars['String']>;
  /**
   * 境内收发货企业编码
   *
   * nan
   */
  rcvgdEtpsNo?: Maybe<Scalars['String']>;
  /**
   * 境内收发货企业社会信用代码
   *
   * nan
   */
  rcvgdEtpsSccd?: Maybe<Scalars['String']>;
  /**
   * 承运车车牌号
   *
   * nan
   */
  vehicleNo?: Maybe<Scalars['String']>;
  /**
   * IC卡号(电子车牌）
   *
   * nan
   */
  vehicleIcNo?: Maybe<Scalars['String']>;
  /**
   * 集装箱号
   *
   * nan
   */
  containerNo?: Maybe<Scalars['String']>;
  /**
   * 车自重
   *
   * nan
   */
  vehicleWt?: Maybe<Scalars['String']>;
  /**
   * 车架号
   *
   * nan
   */
  vehicleFrameNo?: Maybe<Scalars['String']>;
  /**
   * 车架重
   *
   * nan
   */
  vehicleFrameWt?: Maybe<Scalars['String']>;
  /**
   * 集装箱箱型
   *
   * nan
   */
  containerType?: Maybe<Scalars['String']>;
  /**
   * 集装箱重
   *
   * nan
   */
  containerWt?: Maybe<Scalars['String']>;
  /**
   * 总重量
   *
   * nan
   */
  totalWt?: Maybe<Scalars['String']>;
  /**
   * 卡口地磅采集重量
   *
   * nan
   */
  passCollectWt?: Maybe<Scalars['String']>;
  /**
   * 重量比对误差
   *
   * nan
   */
  wtError?: Maybe<Scalars['String']>;
  /**
   * 货物总毛重
   *
   * nan
   */
  totalGrossWt?: Maybe<Scalars['String']>;
  /**
   * 货物总净重
   *
   * nan
   */
  totalNetWt?: Maybe<Scalars['String']>;
  /**
   * 过卡时间1
   *
   * 后台生成
   */
  passTime?: Maybe<Scalars['String']>;
  /**
   * 过卡时间2
   *
   * 后台生成
   */
  secdPassTime?: Maybe<Scalars['String']>;
  /**
   * 绑定类型
   *
   * 出入库类型为分送集报及空车进出不填
   */
  bindTypecd?: Maybe<Scalars['String']>;
  /**
   * 委托标识
   *
   * 0-未委托；1-委托
   */
  entrustSign?: Maybe<Scalars['String']>;
  /**
   * 备注
   *
   * nan
   */
  rmk?: Maybe<Scalars['String']>;
  /**
   * 操作状态
   *
   * 见操作状态表
   */
  optStatus?: Maybe<Scalars['String']>;
  /**
   * 申报类型
   *
   * 1：备案
   */
  dclTypecd?: Maybe<Scalars['String']>;
  /**
   * 关联单证编号
   *
   * 必填的情况：
   */
  rltPortRecNo?: Maybe<Scalars['String']>;
  /**
   * 是否回区
   *
   * 后台反填
   */
  isReturnArea?: Maybe<Scalars['String']>;
  /**
   * 是否绑定
   *
   * 后台反填
   */
  isBandPlane?: Maybe<Scalars['String']>;
  /**
   * 业务类型
   *
   * 出入库类型为7-二线进出的时候必填
   */
  businessTypecd?: Maybe<Scalars['String']>;
  /**
   * 总担保编号
   *
   * 出入库类型为分送集报出区且非免担保时必填
   */
  grtNo?: Maybe<Scalars['String']>;
  /**
   * 回区期限
   *
   * 加工业务回区时间期限
   */
  backTime?: Maybe<Scalars['String']>;
  /**
   * 成交方式
   *
   * 参见成交方式码表
   */
  transMode?: Maybe<Scalars['String']>;
  /**
   * 企业内部编号
   *
   * 企业内部编号
   */
  enterNumber?: Maybe<Scalars['String']>;
  /**
   * 特殊业务类型
   *
   * 1-退单，2-海运拼箱
   */
  specialBusiness?: Maybe<Scalars['String']>;
  /**
   * 是否免担保
   *
   * 1-免担保，0-担保
   */
  isGrt?: Maybe<Scalars['String']>;
  /**
   * 预记入企业账户时间
   *
   * yyyy-MM-dd HH:mm:ss
   */
  preRecAccountTime?: Maybe<Scalars['String']>;
  /**
   * 正式记入企业账户时间
   *
   * yyyy-MM-dd HH:mm:ss
   */
  preAccountTime?: Maybe<Scalars['String']>;
  /**
   * 变更次数
   *
   * 后台生成
   */
  chgTmsCnt?: Maybe<Scalars['String']>;
  /** 商品明细 */
  portReleaseDt?: Maybe<Array<Maybe<SpAreadPortGoods>>>;
  /** 状态 */
  portReleaseStatus?: Maybe<Array<Maybe<SpAreaStauts>>>;
  /** 提发货凭证关联单证信息 */
  portReleaseRlt?: Maybe<Array<Maybe<SpAreaPortReleaseRtl>>>;
  /** 卡口验放凭证出入库关联表信息 */
  portReleaseInexpRlt?: Maybe<Array<Maybe<SpAreadPortInExpRlt>>>;
  /**
   * portReleaseDetail:String # [],
   * portReleaseInexpRlt:String # [],
   * portReleaseRlt:String # [
   *      {
   *          "id" : "291505",
   *          "pid" : "152133",
   *          "orgId" : "104622",
   *          "portNo" : "104622ZV220305152133",
   *          "rltTbTypecd" : "8",
   *          "rltNo" : "YS224922030506214"
   *      }
   *  ],
   *
   *  "tradeGrade" : null
   */
  recAccountTime?: Maybe<Scalars['String']>;
  bindUsedTypecd?: Maybe<Scalars['String']>;
  amountOfTax?: Maybe<Scalars['String']>;
  inOrgId?: Maybe<Scalars['String']>;
  inExpTime?: Maybe<Scalars['String']>;
  inexpRltTypecd?: Maybe<Scalars['String']>;
  isRltInexp?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type SpAreaPortReleaseInput = {
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /**
   * 组织id
   *
   * 后台生成
   */
  orgId?: Maybe<Scalars['String']>;
  /**
   * 凭证编号
   *
   * 后台生成
   */
  preentNo?: Maybe<Scalars['String']>;
  /**
   * 出入库类型
   *
   * 0：空车进出区
   */
  inExpType?: Maybe<Scalars['String']>;
  /**
   * 经营企业编号
   *
   * nan
   */
  bizopEtpsNo?: Maybe<Scalars['String']>;
  /**
   * 经营单位名称
   *
   * nan
   */
  bizopEtpsNm?: Maybe<Scalars['String']>;
  /**
   * 经营单位社会信用代码
   *
   * nan
   */
  bizopEtpsSccd?: Maybe<Scalars['String']>;
  /**
   * 进出标志
   *
   * I-进区；E-出区
   */
  ieTypecd?: Maybe<Scalars['String']>;
  /**
   * 料件、成品标志
   *
   * I-料件,E-成品, S-设备,L-边角料,D-残次品,F-待维修件,G-已维修件,H-维修边角料,J-维修坏件（空车进出区不填写）
   */
  mtpckEndprdTypecd?: Maybe<Scalars['String']>;
  /**
   * 进出境关别
   *
   * 关区代码
   */
  impexpPortcd?: Maybe<Scalars['String']>;
  /**
   * 主管海关
   *
   * yyyy-MM-dd HH:mm:ss
   */
  masterCuscd?: Maybe<Scalars['String']>;
  /**
   * 申报日期
   *
   * 后台反填
   */
  dclTime?: Maybe<Scalars['String']>;
  /**
   * 进出卡口状态
   *
   * 后台反填
   */
  portIochkptStucd?: Maybe<Scalars['String']>;
  /**
   * 是否记入企业账户标记
   *
   * 0-不计入；1-计入
   */
  recAccountFlag?: Maybe<Scalars['String']>;
  /**
   * 境内收发货企业名称
   *
   * nan
   */
  rcvgdEtpsNm?: Maybe<Scalars['String']>;
  /**
   * 境内收发货企业编码
   *
   * nan
   */
  rcvgdEtpsNo?: Maybe<Scalars['String']>;
  /**
   * 境内收发货企业社会信用代码
   *
   * nan
   */
  rcvgdEtpsSccd?: Maybe<Scalars['String']>;
  /**
   * 承运车车牌号
   *
   * nan
   */
  vehicleNo?: Maybe<Scalars['String']>;
  /**
   * IC卡号(电子车牌）
   *
   * nan
   */
  vehicleIcNo?: Maybe<Scalars['String']>;
  /**
   * 集装箱号
   *
   * nan
   */
  containerNo?: Maybe<Scalars['String']>;
  /**
   * 车自重
   *
   * nan
   */
  vehicleWt?: Maybe<Scalars['String']>;
  /**
   * 车架号
   *
   * nan
   */
  vehicleFrameNo?: Maybe<Scalars['String']>;
  /**
   * 车架重
   *
   * nan
   */
  vehicleFrameWt?: Maybe<Scalars['String']>;
  /**
   * 集装箱箱型
   *
   * nan
   */
  containerType?: Maybe<Scalars['String']>;
  /**
   * 集装箱重
   *
   * nan
   */
  containerWt?: Maybe<Scalars['String']>;
  /**
   * 总重量
   *
   * nan
   */
  totalWt?: Maybe<Scalars['String']>;
  /**
   * 卡口地磅采集重量
   *
   * nan
   */
  passCollectWt?: Maybe<Scalars['String']>;
  /**
   * 重量比对误差
   *
   * nan
   */
  wtError?: Maybe<Scalars['String']>;
  /**
   * 货物总毛重
   *
   * nan
   */
  totalGrossWt?: Maybe<Scalars['String']>;
  /**
   * 货物总净重
   *
   * nan
   */
  totalNetWt?: Maybe<Scalars['String']>;
  /**
   * 过卡时间1
   *
   * 后台生成
   */
  passTime?: Maybe<Scalars['String']>;
  /**
   * 过卡时间2
   *
   * 后台生成
   */
  secdPassTime?: Maybe<Scalars['String']>;
  /**
   * 绑定类型
   *
   * 出入库类型为分送集报及空车进出不填
   * 1-一车多票；2-一车一票；3-一票多车
   */
  bindTypecd?: Maybe<Scalars['String']>;
  /**
   * 委托标识
   *
   * 0-未委托；1-委托
   */
  entrustSign?: Maybe<Scalars['String']>;
  /**
   * 备注
   *
   * nan
   */
  rmk?: Maybe<Scalars['String']>;
  /**
   * 操作状态
   *
   * 见操作状态表
   */
  optStatus?: Maybe<Scalars['String']>;
  /**
   * 申报类型
   *
   * 1：备案
   */
  dclTypecd?: Maybe<Scalars['String']>;
  /**
   * 关联单证编号
   *
   * 必填的情况：
   */
  rltPortRecNo?: Maybe<Scalars['String']>;
  /**
   * 是否回区
   *
   * 后台反填
   */
  isReturnArea?: Maybe<Scalars['String']>;
  /**
   * 是否绑定
   *
   * 后台反填
   */
  isBandPlane?: Maybe<Scalars['String']>;
  /**
   * 业务类型
   *
   * 出入库类型为7-二线进出的时候必填
   */
  businessTypecd?: Maybe<Scalars['String']>;
  /**
   * 总担保编号
   *
   * 出入库类型为分送集报出区且非免担保时必填
   */
  grtNo?: Maybe<Scalars['String']>;
  /**
   * 回区期限
   *
   * 加工业务回区时间期限
   */
  backTime?: Maybe<Scalars['String']>;
  /**
   * 成交方式
   *
   * 参见成交方式码表
   */
  transMode?: Maybe<Scalars['String']>;
  /**
   * 企业内部编号
   *
   * 企业内部编号
   */
  enterNumber?: Maybe<Scalars['String']>;
  /**
   * 特殊业务类型
   *
   * 1-退单，2-海运拼箱
   */
  specialBusiness?: Maybe<Scalars['String']>;
  /**
   * 是否免担保
   *
   * 1-免担保，0-担保
   */
  isGrt?: Maybe<Scalars['String']>;
  /**
   * 预记入企业账户时间
   *
   * yyyy-MM-dd HH:mm:ss
   */
  preRecAccountTime?: Maybe<Scalars['String']>;
  /**
   * 正式记入企业账户时间
   *
   * yyyy-MM-dd HH:mm:ss
   */
  preAccountTime?: Maybe<Scalars['String']>;
  /**
   * 变更次数
   *
   * 后台生成
   */
  chgTmsCnt?: Maybe<Scalars['String']>;
  /** 商品明细 */
  portReleaseDt?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  /** 状态 */
  portReleaseStatus?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  /** 提发货凭证关联单证信息 */
  portReleaseRlt?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  /** 卡口验放凭证出入库关联表信息 */
  portReleaseInexpRlt?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  /**
   * portReleaseDetail:String # [],
   * portReleaseInexpRlt:String # [],
   * portReleaseRlt:String # [
   *      {
   *          "id" : "291505",
   *          "pid" : "152133",
   *          "orgId" : "104622",
   *          "portNo" : "104622ZV220305152133",
   *          "rltTbTypecd" : "8",
   *          "rltNo" : "YS224922030506214"
   *      }
   *  ],
   *
   *  "tradeGrade" : null
   */
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 卡口验放凭证关联单证 */
export type SpAreaPortReleaseRtl = {
  __typename?: 'SpAreaPortReleaseRtl';
  /**
   * id
   *
   * 第一次暂存后台生成
   */
  id?: Maybe<Scalars['String']>;
  /**
   * 表头id
   *
   * 第一次暂存后台生成
   */
  pid?: Maybe<Scalars['String']>;
  /**
   * 组织id
   *
   * 第一次暂存后台生成
   */
  orgId?: Maybe<Scalars['String']>;
  /**
   * 卡口验放凭证编号
   *
   * 第一次暂存后台生成
   */
  portNo?: Maybe<Scalars['String']>;
  /**
   * 关联单证类型
   *
   * 1-进出区提发货；2-进境径予；3-出境径予；
   */
  rltTbTypecd?: Maybe<Scalars['String']>;
  /**
   * 关联单证编号
   *
   * 进出区提发货编号/径予单证编号/个人申报清单编号
   */
  rltNo?: Maybe<Scalars['String']>;
};

/** 一体化 卡口验放凭证 */
export type SpAreaStat = IDataInfo & {
  __typename?: 'SpAreaStat';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type SpAreaStatInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 特综一体化平台状态 */
export type SpAreaStauts = {
  __typename?: 'SpAreaStauts';
  /**
   * id
   *
   * 后台反填
   */
  id?: Maybe<Scalars['String']>;
  /**
   * 提发货的id
   *
   * 后台反填
   */
  pid?: Maybe<Scalars['String']>;
  /**
   * 创建时间
   *
   * 后台反填
   */
  createTime?: Maybe<Scalars['String']>;
  /**
   * 操作状态
   *
   * 后台反填
   */
  optStatus?: Maybe<Scalars['String']>;
  /**
   * 操作人名称
   *
   * 后台反填
   */
  optUserName?: Maybe<Scalars['String']>;
  /**
   * 操作时间
   *
   * 后台反填
   */
  optTime?: Maybe<Scalars['String']>;
  /**
   * 备注
   *
   * 后台反填
   */
  remark?: Maybe<Scalars['String']>;
};

/** 提发货凭证商品明细 */
export type SpAreadPortGoods = {
  __typename?: 'SpAreadPortGoods';
  /**
   * id
   *
   * 第一次暂存后台生成
   */
  id?: Maybe<Scalars['String']>;
  /**
   * 表头id
   *
   * 第一次暂存后台生成
   */
  pid?: Maybe<Scalars['String']>;
  /**
   * 组织id
   *
   * 第一次暂存后台生成
   */
  orgId?: Maybe<Scalars['String']>;
  /**
   * 凭证编号
   *
   * 第一次暂存后台生成
   */
  portNo?: Maybe<Scalars['String']>;
  /**
   * 关联单证编号
   *
   * 出入库类型为：1、2、4、5、9类型必填
   */
  rltNo?: Maybe<Scalars['String']>;
  /**
   * 关联表体序号
   *
   * 1.出入库类型为：1、2、4、5、9类型，绑定类型为3-一票多车时要填写关联明细序号；
   */
  rltSeqno?: Maybe<Scalars['String']>;
  /**
   * 明细序号
   *
   * 第一次暂存后台生成
   */
  gdsSeqno?: Maybe<Scalars['String']>;
  /**
   * 商品料号
   *
   * nan
   */
  gdsMtno?: Maybe<Scalars['String']>;
  /**
   * 商品编码
   *
   * nan
   */
  gdecd?: Maybe<Scalars['String']>;
  /**
   * 商品名称
   *
   * nan
   */
  gdsNm?: Maybe<Scalars['String']>;
  /**
   * 规格型号
   *
   * nan
   */
  gdsSpcfModelDesc?: Maybe<Scalars['String']>;
  /**
   * 币制
   *
   * nan
   */
  dclCurrcd?: Maybe<Scalars['String']>;
  /**
   * 申报数量
   *
   * nan
   */
  dclQty?: Maybe<Scalars['String']>;
  /**
   * 申报计量单位
   *
   * nan
   */
  dclUnitcd?: Maybe<Scalars['String']>;
  /**
   * 原产国（地区）
   *
   * 参见原产国码表
   */
  natcd?: Maybe<Scalars['String']>;
  /**
   * 净重
   *
   * nan
   */
  netWt?: Maybe<Scalars['String']>;
  /**
   * 总价
   *
   * nan
   */
  dclTotalAmt?: Maybe<Scalars['String']>;
  /**
   * 修改标志代码
   *
   * 申报类型为变更的时候需要填写
   */
  modfMarkcd?: Maybe<Scalars['String']>;
  /**
   * 法定数量
   *
   * nan
   */
  qty1?: Maybe<Scalars['String']>;
  /**
   * 法定单位
   *
   * nan
   */
  unit1?: Maybe<Scalars['String']>;
};

/** 卡口验放凭证出入库关联表信息 */
export type SpAreadPortInExpRlt = {
  __typename?: 'SpAreadPortInExpRlt';
  /**
   * id
   *
   * 后台反填
   */
  Id?: Maybe<Scalars['String']>;
  /**
   * 表头id
   *
   * 后台反填
   */
  pid?: Maybe<Scalars['String']>;
  /**
   * 组织id
   *
   * 后台反填
   */
  orgId?: Maybe<Scalars['String']>;
  /**
   * 核放凭证预录入编号
   *
   * 后台反填
   */
  preentNo?: Maybe<Scalars['String']>;
  /**
   * 出入库单编号
   *
   * nan
   */
  inExpWhNo?: Maybe<Scalars['String']>;
  /**
   * 出入库时间
   *
   * nan
   */
  inExpTime?: Maybe<Scalars['String']>;
};

export type SpAreadPortReleaseConditionInput = {
  ieTypecd?: Maybe<Scalars['String']>;
  inExpType?: Maybe<Scalars['String']>;
  optStatus?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  preentNo?: Maybe<Scalars['String']>;
  rltNo?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  vehicleNo?: Maybe<Scalars['String']>;
};

/** 一体化 卡口验放凭证 */
export type SpPassport = {
  __typename?: 'SpPassport';
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  tfhtype?: Maybe<Scalars['String']>;
  dataid?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  passportNo?: Maybe<Scalars['String']>;
  chgTmsCnt?: Maybe<Scalars['String']>;
  dclTypecd?: Maybe<Scalars['String']>;
  ioTypecd?: Maybe<Scalars['String']>;
  masterCuscd?: Maybe<Scalars['String']>;
  areainEtpsno?: Maybe<Scalars['String']>;
  areainEtpsNm?: Maybe<Scalars['String']>;
  areainEtpsSccd?: Maybe<Scalars['String']>;
  gdsTypecd?: Maybe<Scalars['String']>;
  dclTime?: Maybe<Scalars['String']>;
  putrecEmapvTime?: Maybe<Scalars['String']>;
  faliureTime?: Maybe<Scalars['String']>;
  vehicleNo?: Maybe<Scalars['String']>;
  passTime?: Maybe<Scalars['String']>;
  secdPassTime?: Maybe<Scalars['String']>;
  stucd?: Maybe<Scalars['String']>;
  emapvMarkcd?: Maybe<Scalars['String']>;
  ctrlNo?: Maybe<Scalars['String']>;
  optResult?: Maybe<Scalars['String']>;
  rmk?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  optStatus?: Maybe<Scalars['String']>;
  customName?: Maybe<Scalars['String']>;
  tradeGrade?: Maybe<Scalars['String']>;
  passportUsedTypecd?: Maybe<Scalars['String']>;
  associatedIntoPassportNo?: Maybe<Scalars['String']>;
  completelyAssociatedStatus?: Maybe<Scalars['String']>;
  /** 便捷商品明细 */
  easyPassportDt?: Maybe<Array<Maybe<PassPortProd>>>;
  easyPassportStatus?: Maybe<Array<Maybe<PassPortStatus>>>;
};

export type SpPassportInput = {
  id?: Maybe<Scalars['ID']>;
  tfhtype?: Maybe<Scalars['String']>;
  dataid?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  passportNo?: Maybe<Scalars['String']>;
  chgTmsCnt?: Maybe<Scalars['String']>;
  dclTypecd?: Maybe<Scalars['String']>;
  ioTypecd?: Maybe<Scalars['String']>;
  masterCuscd?: Maybe<Scalars['String']>;
  areainEtpsno?: Maybe<Scalars['String']>;
  areainEtpsNm?: Maybe<Scalars['String']>;
  areainEtpsSccd?: Maybe<Scalars['String']>;
  gdsTypecd?: Maybe<Scalars['String']>;
  dclTime?: Maybe<Scalars['String']>;
  putrecEmapvTime?: Maybe<Scalars['String']>;
  faliureTime?: Maybe<Scalars['String']>;
  vehicleNo?: Maybe<Scalars['String']>;
  passTime?: Maybe<Scalars['String']>;
  secdPassTime?: Maybe<Scalars['String']>;
  stucd?: Maybe<Scalars['String']>;
  emapvMarkcd?: Maybe<Scalars['String']>;
  ctrlNo?: Maybe<Scalars['String']>;
  optResult?: Maybe<Scalars['String']>;
  rmk?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  optStatus?: Maybe<Scalars['String']>;
  customName?: Maybe<Scalars['String']>;
  tradeGrade?: Maybe<Scalars['String']>;
  passportUsedTypecd?: Maybe<Scalars['String']>;
  associatedIntoPassportNo?: Maybe<Scalars['String']>;
  completelyAssociatedStatus?: Maybe<Scalars['String']>;
  easyPassportDt?: Maybe<Array<Maybe<PassPortProdInput>>>;
  easyPassportStatus?: Maybe<Array<Maybe<PassPortStatusInput>>>;
};

/** 围网业务类型定义 */
export type SpecialTypeClass = {
  __typename?: 'SpecialTypeClass';
  /** 类型 一线二线 区分内容 */
  lineType?: Maybe<KeyValue>;
  /**
   * 最终 货物目的地
   * 祝桥 围网仓库
   * 大场 围网仓库
   */
  destTargetStock?: Maybe<KeyValue>;
  /** 模版名称描述SpecialTypeClassTemplate 用来生成报关单 */
  templateDefName?: Maybe<Scalars['String']>;
  /**
   * 中转仓库
   * codeName value 记录一些值,如 关区,海关编号等 模版信息
   */
  transitWarehouse?: Maybe<KeyValue>;
  /**
   *     报关单类型
   *     自贸区特有的类型：
   * 1：普通备案清单
   * 2：先进区后报关
   * 3：分送集报备案清单
   * 4；分送集报报关单
   * 注：当选择自贸区类型时，以下字段不可填写
   * 表头：合同号、批准文件、内销比率
   * 表体：用途、工缴费、成品货号
   * 自由文本：监管仓号、报关员联系方式
   */
  billType?: Maybe<Scalars['String']>;
  /**
   * 报关单进出类型
   * I E
   */
  cusIEFlag?: Maybe<Scalars['String']>;
  /**
   * 报关单监管方式
   * I E
   */
  transMode?: Maybe<Scalars['String']>;
  transModeName?: Maybe<Scalars['String']>;
  /**
   *    出入库类型
   *    、一线进出
   * 2、先报后送
   * 3、分送集报
   * 4、区内流转
   * 5、区间流转
   * 7、二线进出
   * 8、调整类
   * 9、一单多车B
   */
  inExpType?: Maybe<Scalars['String']>;
  /**
   * 业务类型
   * 1自用设备、2简单加工、3保税加工、4保税仓储、5转口贸易、6跨境电商、7汽车平行进口、8融资租赁、9期货保税交割、10保税维修、11进口汽车保税存储、12保税研发、13委托加工、14大宗商品现货保税交易、15临时进出区、16设备出区、17模具外发、18外发加工、19境内维修、20境外维修、21飞机试飞、22保税展示
   */
  businessTypecd?: Maybe<Scalars['String']>;
  /**
   * 料件、成品标志
   * I-料件,E-成品, S-设备,L-边角料,D-残次品,F-待维修件,G-已维修件,H-维修边角料,J-维修坏件
   */
  mtpckEndprdTypecd?: Maybe<Scalars['String']>;
  /**
   * 进出标志
   * I-进区，E-出区
   */
  ieTypecd?: Maybe<Scalars['String']>;
  /**
   * 监管方式
   * 料件进出区
   */
  supvModecd?: Maybe<Scalars['String']>;
  /**
   * 进出境关别
   * 洋山特综
   */
  impexpPortcd?: Maybe<Scalars['String']>;
  /**
   *     是否报关
   *     1-否，2-是
   * 除出入库类型为区内流转、调整类外其余均是2-是
   */
  dclcusFlag?: Maybe<Scalars['String']>;
  /**
   * 报关类型
   * 1-关联报关；2-对应报关
   */
  dclcusTypecd?: Maybe<Scalars['String']>;
  /**
   * 境内目的地、境内货源地
   * 洋山特殊综合保税区 3122S
   */
  districtCode?: Maybe<Scalars['String']>;
  /** 最终目的国 */
  destinationNatcd?: Maybe<Scalars['String']>;
  /** 经营企业 */
  bizopEtpsNm?: Maybe<Scalars['String']>;
  /** 经营企业编码 */
  bizopEtpsNo?: Maybe<Scalars['String']>;
  /** 经营企业社会信用代码 */
  bizopEtpsSccd?: Maybe<Scalars['String']>;
  /**
   * 确认类型
   * 1-备案 2-变更 3-作废
   */
  dclTypecd?: Maybe<Scalars['String']>;
  /**
   *     特殊业务
   *     4-特殊类
   * 5-涉证商品
   * 6-海运拼箱
   */
  specialBusiness?: Maybe<Scalars['String']>;
  /**
   *     调拨模式
   *     出入库类型为4-区内流转时必填	1-围网内
   * 2-跨围网
   */
  allocationMode?: Maybe<Scalars['String']>;
};

export type SpecialTypeClassInput = {
  /** 类型 一线二线 区分内容 */
  lineType?: Maybe<KeyValueInput>;
  /**
   * 最终 货物目的地
   * 祝桥 围网仓库
   * 大场 围网仓库
   */
  destTargetStock?: Maybe<KeyValueInput>;
  /**
   * 中转仓库
   * codeName value 记录一些值,如 关区,海关编号等 模版信息
   */
  transitWarehouse?: Maybe<KeyValueInput>;
  /**
   *    出入库类型
   *    、一线进出
   * 2、先报后送
   * 3、分送集报
   * 4、区内流转
   * 5、区间流转
   * 7、二线进出
   * 8、调整类
   * 9、一单多车B
   */
  inExpType?: Maybe<Scalars['String']>;
  /**
   * 业务类型
   * 1自用设备、2简单加工、3保税加工、4保税仓储、5转口贸易、6跨境电商、7汽车平行进口、8融资租赁、9期货保税交割、10保税维修、11进口汽车保税存储、12保税研发、13委托加工、14大宗商品现货保税交易、15临时进出区、16设备出区、17模具外发、18外发加工、19境内维修、20境外维修、21飞机试飞、22保税展示
   */
  businessTypecd?: Maybe<Scalars['String']>;
  /**
   * 料件、成品标志
   * I-料件,E-成品, S-设备,L-边角料,D-残次品,F-待维修件,G-已维修件,H-维修边角料,J-维修坏件
   */
  mtpckEndprdTypecd?: Maybe<Scalars['String']>;
  /**
   * 进出标志
   * I-进区，E-出区
   */
  ieTypecd?: Maybe<Scalars['String']>;
  /**
   * 监管方式
   * 料件进出区
   */
  supvModecd?: Maybe<Scalars['String']>;
  /**
   *     是否报关
   *     1-否，2-是
   * 除出入库类型为区内流转、调整类外其余均是2-是
   */
  dclcusFlag?: Maybe<Scalars['String']>;
  /**
   * 报关类型
   * 1-关联报关；2-对应报关
   */
  dclcusTypecd?: Maybe<Scalars['String']>;
  /**
   * 境内目的地、境内货源地
   * 洋山特殊综合保税区 3122S
   */
  districtCode?: Maybe<Scalars['String']>;
  /** 最终目的国 */
  destinationNatcd?: Maybe<Scalars['String']>;
  /** 经营企业 */
  bizopEtpsNm?: Maybe<Scalars['String']>;
  /** 经营企业编码 */
  bizopEtpsNo?: Maybe<Scalars['String']>;
  /** 经营企业社会信用代码 */
  bizopEtpsSccd?: Maybe<Scalars['String']>;
};

/** 综保业务跟踪表 以委托为单位 */
export type SpecialTypeOrderTrackInfo = {
  __typename?: 'SpecialTypeOrderTrackInfo';
  id?: Maybe<Scalars['ID']>;
  /** 业务类型 一体化其他等 加一个参数 */
  orderType?: Maybe<KeyValue>;
  appCompanyMini?: Maybe<KeyValue>;
  /** 供应商 */
  supplier?: Maybe<Scalars['String']>;
  /** 供应商 */
  supplierName?: Maybe<Scalars['String']>;
  /**
   * 客户业务编号
   * //上飞taskNO
   */
  custRefNo?: Maybe<Scalars['String']>;
  /** 业务编号 */
  refNo?: Maybe<Scalars['String']>;
  /** 提运单号 */
  billNo?: Maybe<Scalars['String']>;
  /**
   * 预计进口日期
   * 到港日期
   */
  ieDate?: Maybe<Scalars['Date']>;
  /** 口岸报关状态 */
  portBaoguanStatus?: Maybe<Scalars['String']>;
  /** 口岸清关时间 */
  portBaoguanStatusDate?: Maybe<Scalars['Date']>;
  /** 保税库清关状态 2207-2249 */
  dutyFreeWHBaoguanStatus?: Maybe<Scalars['String']>;
  /** 保税库清关状态 2207-2249 核注核放 等状态比报关状态多 */
  dutyFreeWHBaoguanStatusDate?: Maybe<Scalars['String']>;
  /**
   * 一体化状态
   * 核注清单状态
   * optStatus
   * 暂存,已确认,入库成功,失败待审核,审核通过,审核拒绝
   */
  specialAreaWHStatus?: Maybe<Scalars['Date']>;
  /**
   * 一体化状态
   * 核注清单状态时间
   */
  specialAreaWHStatusDate?: Maybe<Scalars['Date']>;
  /**
   *     一体化状态
   *     进出卡口状态
   *     后台反填
   * 1-未过卡 2-部分过卡 3-完全过卡
   */
  invtIochkptStucd?: Maybe<Scalars['String']>;
};

/** 特殊监管区域模版 报关对应 */
export type SpecialTypeTemplate = {
  __typename?: 'SpecialTypeTemplate';
  /** 报关单模版 */
  baoguanTemplate?: Maybe<Kddpreorder>;
  /** 提发货凭证模版 */
  warehouseTemplate?: Maybe<WareHouse>;
  /** 模版名称 */
  templateName?: Maybe<Scalars['String']>;
};

export type SplitProdDetail = {
  __typename?: 'SplitProdDetail';
  orderNo?: Maybe<Scalars['String']>;
  gNo?: Maybe<Scalars['Int']>;
  orderOutNo?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Float']>;
};

export type SplitProdDetailInput = {
  orderNo?: Maybe<Scalars['String']>;
  gNo?: Maybe<Scalars['Int']>;
  orderOutNo?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Float']>;
};

/** 盖章明细 */
export type StampPicsInput = {
  stampKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  picOSSKey?: Maybe<Scalars['String']>;
};

export type SubWareHouse = {
  __typename?: 'SubWareHouse';
  name?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export type SubWareHouseInput = {
  name?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  taskStatusChanged?: Maybe<FunJobInfo>;
  /** 发送单一窗口状态更新Sub */
  sendSingleWindowStatusChanged?: Maybe<Kddpreorder>;
};


export type SubscriptionTaskStatusChangedArgs = {
  jobId?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type SubscriptionSendSingleWindowStatusChangedArgs = {
  orderNos?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** 一体化 装车单 */
export type TensorSpAreaPackageList = IDataInfo & {
  __typename?: 'TensorSpAreaPackageList';
  appID?: Maybe<Scalars['String']>;
  /** 装车单信息基本表ID */
  id?: Maybe<Scalars['ID']>;
  /** 装车单车队组织id */
  orgId?: Maybe<Scalars['String']>;
  /** 装车单单证号 */
  packageListNo?: Maybe<Scalars['String']>;
  /** 车牌号 */
  vehicleNo?: Maybe<Scalars['String']>;
  /** 关区编码(2249) */
  customCode?: Maybe<Scalars['String']>;
  /** 进出区标志(I：进区，E：出区) */
  iePack?: Maybe<Scalars['String']>;
  /** 装车单操作状态 */
  optStatus?: Maybe<Scalars['String']>;
  /** 申报类型代码 */
  dclTypecd?: Maybe<Scalars['String']>;
  /** 企业中文名称 */
  tradeName?: Maybe<Scalars['String']>;
  /** 总重量 */
  weight?: Maybe<Scalars['Int']>;
  /** 是否已装车 */
  isConfirm?: Maybe<Scalars['String']>;
  /** 关联的通关单据 */
  packageSaspassport?: Maybe<Array<Maybe<PackageSasPassport>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type TensorSpAreaPackageListConditionInput = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  tradeName?: Maybe<Scalars['String']>;
  sasPassportPreentNo?: Maybe<Scalars['String']>;
  optStatus?: Maybe<Scalars['String']>;
  iePack?: Maybe<Scalars['String']>;
  vehicleNo?: Maybe<Scalars['String']>;
  packageListNo?: Maybe<Scalars['String']>;
};

export type TensorSpAreaPackageListInput = {
  appID?: Maybe<Scalars['String']>;
  /** 装车单信息基本表ID */
  id?: Maybe<Scalars['ID']>;
  /** 装车单车队组织id */
  orgId?: Maybe<Scalars['String']>;
  /** 装车单单证号 */
  packageListNo?: Maybe<Scalars['String']>;
  /** 车牌号 */
  vehicleNo?: Maybe<Scalars['String']>;
  /** 关区编码(2249) */
  customCode?: Maybe<Scalars['String']>;
  /** 进出区标志(I：进区，E：出区) */
  iePack?: Maybe<Scalars['String']>;
  /** 装车单操作状态 */
  optStatus?: Maybe<Scalars['String']>;
  /** 申报类型代码 */
  dclTypecd?: Maybe<Scalars['String']>;
  /** 企业中文名称 */
  tradeName?: Maybe<Scalars['String']>;
  /** 总重量 */
  weight?: Maybe<Scalars['Int']>;
  /** 是否已装车 */
  isConfirm?: Maybe<Scalars['String']>;
  /** 关联的通关单据 */
  packageSaspassport?: Maybe<Array<Maybe<PackageSasPassportInput>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type TiOcrBlockResult = {
  __typename?: 'TiOcrBlockResult';
  text?: Maybe<Scalars['String']>;
  confidence?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type TitMail = {
  __typename?: 'TitMail';
  id?: Maybe<Scalars['ID']>;
  orderNo?: Maybe<Scalars['String']>;
  orderOutNo?: Maybe<Scalars['String']>;
  attFiles?: Maybe<Array<Maybe<OrderFileInfo>>>;
  mailId?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
  appCompanyMini?: Maybe<KeyValue>;
  coUserMini?: Maybe<KeyValue>;
  headerLines?: Maybe<Array<Maybe<KeyValue>>>;
  html?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  textAsHtml?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  references?: Maybe<Array<Maybe<Scalars['String']>>>;
  date?: Maybe<Scalars['Date']>;
  to?: Maybe<MailAddress>;
  from?: Maybe<MailAddress>;
  cc?: Maybe<MailAddress>;
  messageId?: Maybe<Scalars['String']>;
  inReplyTo?: Maybe<Scalars['String']>;
  replyTo?: Maybe<MailAddress>;
  memo?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  mailStatus?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<KeyValue>>>;
  relatedRefNos?: Maybe<Array<Maybe<KeyValue>>>;
  jobInfo?: Maybe<FunJobInfo>;
  releatedMails?: Maybe<Array<Maybe<TitMail>>>;
};

export type TmsAddressInfo = {
  __typename?: 'TmsAddressInfo';
  addressBrief?: Maybe<Scalars['String']>;
  /** 地址全称 */
  addressDetail?: Maybe<Scalars['String']>;
  /** 省 */
  province?: Maybe<Scalars['String']>;
  /** 市 */
  city?: Maybe<Scalars['String']>;
  /** 联系人 */
  contactPerson?: Maybe<Scalars['String']>;
  /** 联系电话 */
  contactPhone?: Maybe<Scalars['String']>;
  /** GPS location */
  location?: Maybe<Scalars['String']>;
  /** 公司名称 */
  companyName?: Maybe<Scalars['String']>;
  addressTag?: Maybe<Scalars['String']>;
  wareHouseName?: Maybe<Scalars['String']>;
  wareHouseLocation?: Maybe<Scalars['String']>;
  extraContacter?: Maybe<Array<Maybe<Contacter>>>;
};

export type TmsAddressInfoInput = {
  addressBrief?: Maybe<Scalars['String']>;
  /** 地址全称 */
  addressDetail?: Maybe<Scalars['String']>;
  /** 省 */
  province?: Maybe<Scalars['String']>;
  /** 市 */
  city?: Maybe<Scalars['String']>;
  /** 联系人 */
  contactPerson?: Maybe<Scalars['String']>;
  /** 联系电话 */
  contactPhone?: Maybe<Scalars['String']>;
  /** GPS location */
  location?: Maybe<Scalars['String']>;
  /** 公司名称 */
  companyName?: Maybe<Scalars['String']>;
  addressTag?: Maybe<Scalars['String']>;
  wareHouseName?: Maybe<Scalars['String']>;
  wareHouseLocation?: Maybe<Scalars['String']>;
  extraContacter?: Maybe<Array<Maybe<ContacterInput>>>;
};

/** TmsCustApply 日志记录 */
export type TmsCustApplyLog = IDataInfo & {
  __typename?: 'TmsCustApplyLog';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 关联的申请单号 */
  custApplyNo?: Maybe<Scalars['String']>;
  /** 更新对象 例如:申请单,派车单,运输单 等 */
  updateObject?: Maybe<Scalars['String']>;
  /** 更新对象ID */
  updateObjectID?: Maybe<Scalars['String']>;
  /** 更新唯一编号 FO task No */
  updateObjectNo?: Maybe<Scalars['String']>;
  /** 操作类型 更新状态 */
  operationType?: Maybe<Scalars['String']>;
  /** 更新简述 例如:更新状态为已审核 */
  operationDesc?: Maybe<Scalars['String']>;
  /** 更新前的数据 */
  beforeData?: Maybe<Scalars['JSON']>;
  /** 更新后的数据 */
  afterData?: Maybe<Scalars['JSON']>;
  /** 操作人 */
  operator?: Maybe<Scalars['String']>;
  /** 操作时间 */
  operationTime?: Maybe<Scalars['Date']>;
  /** 备注 */
  remark?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type TmsCustApplyLogInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  /** 关联的申请单号 */
  custApplyNo?: Maybe<Scalars['String']>;
  /** 更新对象 例如:申请单,派车单,运输单 等 */
  updateObject?: Maybe<Scalars['String']>;
  /** 更新对象ID */
  updateObjectID?: Maybe<Scalars['String']>;
  /** 更新唯一编号 FO task No */
  updateObjectNo?: Maybe<Scalars['String']>;
  /** 操作类型 更新状态 */
  operationType?: Maybe<Scalars['String']>;
  /** 更新简述 例如:更新状态为已审核 */
  operationDesc?: Maybe<Scalars['String']>;
  /** 更新前的数据 */
  beforeData?: Maybe<Scalars['JSON']>;
  /** 更新后的数据 */
  afterData?: Maybe<Scalars['JSON']>;
  /** 操作人 */
  operator?: Maybe<Scalars['String']>;
  /** 操作时间 */
  operationTime?: Maybe<Scalars['Date']>;
  /** 备注 */
  remark?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 一体化 卡口验放凭证 */
export type TmsCustTransApply = IDataInfo & {
  __typename?: 'TmsCustTransApply';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 项目 ARJ C919 */
  projectClass?: Maybe<Scalars['String']>;
  /** 委托业务编号 */
  tmsApplyNo?: Maybe<Scalars['String']>;
  process_instance_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  curMessage?: Maybe<Scalars['String']>;
  /** 装货完成 */
  stockStatus?: Maybe<Scalars['String']>;
  stockTime?: Maybe<Scalars['Date']>;
  /** 通过流程实例ID获取运输申请单 */
  roadForwadingOrders?: Maybe<Array<Maybe<RoadTransForwardingOrder>>>;
  billList?: Maybe<Array<Maybe<ServiceBillDetailInfo>>>;
  /** 通过流程实例ID获取运输任务单 */
  transTaskOrders?: Maybe<Array<Maybe<TransTaskOrder>>>;
  batchCustInfos?: Maybe<Array<Maybe<TmsCustTransApply>>>;
  /** 部门 */
  statusCode?: Maybe<Scalars['Int']>;
  transStatus?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  /** WBS号码 */
  wbsNo?: Maybe<Scalars['String']>;
  /**
   * 运输类型
   * 跨省散货
   * 跨省包车
   * 空运
   * 顺丰
   * 市内运输
   * 短驳
   */
  transType?: Maybe<Scalars['String']>;
  transTypeCode?: Maybe<Scalars['String']>;
  normalDistance?: Maybe<Scalars['Float']>;
  DanDistance?: Maybe<Scalars['Float']>;
  wbsInfo?: Maybe<Scalars['String']>;
  /**
   * 清关类型
   * 两地流转
   * 外发加工
   * 试飞出区
   * 便捷
   * 无
   */
  customsType?: Maybe<Scalars['String']>;
  /** 提货地址 */
  pickupPlaces?: Maybe<Array<Maybe<TmsAddressInfo>>>;
  /** 送货地址 */
  targetPlaces?: Maybe<Array<Maybe<TmsAddressInfo>>>;
  /** 计划提货日期 */
  planTransDate?: Maybe<Scalars['Date']>;
  /** 发运商品明细 */
  transGoodsDetails?: Maybe<Array<Maybe<TmsGoodsDetail>>>;
  /** 主要品名 */
  goodsName?: Maybe<Scalars['String']>;
  /** 件数 */
  packNo?: Maybe<Scalars['Int']>;
  /** 保价金额 */
  insuredAmount?: Maybe<Scalars['Float']>;
  /** 毛重 */
  grossWeight?: Maybe<Scalars['Float']>;
  /** 危险品标志 */
  dangerousMark?: Maybe<Scalars['String']>;
  dangerousType?: Maybe<Scalars['String']>;
  /** 冷藏要求 */
  highTempture?: Maybe<Scalars['Float']>;
  lowTempture?: Maybe<Scalars['Float']>;
  /** 车型要求 */
  applyVehicles?: Maybe<Array<Maybe<TmsVehicleInfo>>>;
  /** 附加服务 */
  extraServiceItems?: Maybe<Array<Maybe<TmsExtraServiceItem>>>;
  /** 数量 */
  carNum?: Maybe<Scalars['Int']>;
  /** 其他要求 */
  otherRequire?: Maybe<Scalars['String']>;
  /** 申请人ID */
  userId?: Maybe<Scalars['String']>;
  /** 翔运接单人id */
  applyuserId?: Maybe<Scalars['String']>;
  applyuserName?: Maybe<Scalars['String']>;
  /** 代办人id和name */
  fuserId?: Maybe<Scalars['String']>;
  fuserName?: Maybe<Scalars['String']>;
  /** 供应商接单id */
  customOperaterId?: Maybe<Scalars['String']>;
  customOperaterName?: Maybe<Scalars['String']>;
  /** 预估运费 */
  estimateFreight?: Maybe<Scalars['Float']>;
  /** 处理状态 */
  processStatus?: Maybe<Scalars['String']>;
  /** 随附单证 MSDS 以及货物图片等 */
  attFiles?: Maybe<Array<Maybe<OrderFileInfo>>>;
  /** 申请人部门 */
  applyDepartName?: Maybe<Scalars['String']>;
  applyDepartId?: Maybe<Scalars['String']>;
  /** 委托清单类型 1.外发加工 2. 料件流转 */
  customsBusinessType?: Maybe<Scalars['String']>;
  /** 清单号码LJ2019000001 可能没用了 */
  businessInfos?: Maybe<Array<Maybe<GuanMaoBusinessTransit>>>;
  applyBussinessDetail?: Maybe<Array<Maybe<CustomApplyBussinessInfo>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  /** 翔运待办ID */
  todoId?: Maybe<Scalars['String']>;
  billStatus?: Maybe<Scalars['String']>;
  batchNo?: Maybe<Scalars['String']>;
  parentTmsApplyNo?: Maybe<Scalars['String']>;
  /** 批量标志 */
  batchInfo?: Maybe<Scalars['String']>;
};

export type TmsCustTransApplyInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  tmsApplyNo?: Maybe<Scalars['String']>;
  process_instance_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  curMessage?: Maybe<Scalars['String']>;
  /** 装货完成 */
  stockStatus?: Maybe<Scalars['String']>;
  stockTime?: Maybe<Scalars['Date']>;
  processStatus?: Maybe<Scalars['String']>;
  transStatus?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  /** 项目 ARJ C919 */
  projectClass?: Maybe<Scalars['String']>;
  /** WBS号码 */
  wbsNo?: Maybe<Scalars['String']>;
  /**
   * 运输类型
   * 跨省散货
   * 跨省包车
   * 空运
   * 顺丰
   * 市内运输
   * 短驳
   */
  transType?: Maybe<Scalars['String']>;
  /**
   * 市内 - CIT (City)
   * 跨省500千米内 - W5I (Within 500km In-province)
   * 跨省500千米外 - O5O (Over 500km Out-province)
   * 跨散 - DIS (Disperse)
   * 顺丰 - SFN (ShunFeng)
   * 空运 - AIR (Air transport)
   * 其他 - OTH (Other)
   */
  transTypeCode?: Maybe<Scalars['String']>;
  normalDistance?: Maybe<Scalars['Float']>;
  DanDistance?: Maybe<Scalars['Float']>;
  wbsInfo?: Maybe<Scalars['String']>;
  /**
   * 清关类型
   * 两地流转
   * 外发加工
   * 试飞出区
   * 料件流转
   * 便捷
   * 无
   */
  customsType?: Maybe<Scalars['String']>;
  /** 提货地址 */
  pickupPlaces?: Maybe<Array<Maybe<TmsAddressInfoInput>>>;
  /** 送货地址 */
  targetPlaces?: Maybe<Array<Maybe<TmsAddressInfoInput>>>;
  /** 计划提货日期 */
  planTransDate?: Maybe<Scalars['Date']>;
  /** 运输商品信息 */
  transGoodsDetails?: Maybe<Array<Maybe<TmsGoodsDetailInput>>>;
  /** 附加服务 */
  extraServiceItems?: Maybe<Array<Maybe<TmsExtraServiceItemInput>>>;
  /** 商品名称 */
  goodsName?: Maybe<Scalars['String']>;
  /** 件数 */
  packNo?: Maybe<Scalars['Int']>;
  /** 保价金额 */
  insuredAmount?: Maybe<Scalars['Float']>;
  /** 毛重 */
  grossWeight?: Maybe<Scalars['Float']>;
  /** 危险品标志 */
  dangerousMark?: Maybe<Scalars['String']>;
  dangerousType?: Maybe<Scalars['String']>;
  /**
   * 冷藏要求
   * 无
   */
  frozenRequire?: Maybe<Scalars['String']>;
  /** 冷藏要求 */
  highTempture?: Maybe<Scalars['Float']>;
  lowTempture?: Maybe<Scalars['Float']>;
  /** 车型要求 */
  applyVehicles?: Maybe<Array<Maybe<TmsVehicleInfoInput>>>;
  /** 数量 */
  carNum?: Maybe<Scalars['Int']>;
  /** 其他要求 */
  otherRequire?: Maybe<Scalars['String']>;
  /** 申请人ID */
  userId?: Maybe<Scalars['String']>;
  applyuserId?: Maybe<Scalars['String']>;
  applyuserName?: Maybe<Scalars['String']>;
  /** 代办人id和name */
  fuserId?: Maybe<Scalars['String']>;
  fuserName?: Maybe<Scalars['String']>;
  /** 供应商接单id */
  customOperaterId?: Maybe<Scalars['String']>;
  customOperaterName?: Maybe<Scalars['String']>;
  /** 预估运费 */
  estimateFreight?: Maybe<Scalars['Float']>;
  /** 随附单证 */
  attFiles?: Maybe<Array<Maybe<OrderFileInfoInput>>>;
  /** 业务申请单列表 */
  businessInfos?: Maybe<Array<Maybe<GuanMaoBusinessTransitInput>>>;
  /** 申请人部门 */
  applyDepartName?: Maybe<Scalars['String']>;
  billStatus?: Maybe<Scalars['String']>;
  batchNo?: Maybe<Scalars['String']>;
  batchInfo?: Maybe<Scalars['String']>;
  parentTmsApplyNo?: Maybe<Scalars['String']>;
  applyDepartId?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** TMS 人工服务商品描述 */
export type TmsExtraServiceItem = {
  __typename?: 'TmsExtraServiceItem';
  /** 服务类型 工人 */
  serviceItem?: Maybe<Scalars['String']>;
  /** 服务项目编号 */
  serviceItemNo?: Maybe<Scalars['String']>;
  /** 备注 */
  remark?: Maybe<Scalars['String']>;
  /** 服务数量 9 人 台班 */
  chargeQty?: Maybe<Scalars['Float']>;
  /** 计量 */
  chargeUnit?: Maybe<Scalars['String']>;
  /** 描述 */
  chargeDescription?: Maybe<Scalars['String']>;
  /** 服务要求备注 */
  serviceMemo?: Maybe<Scalars['String']>;
  relateAddress?: Maybe<TmsAddressInfo>;
};

export type TmsExtraServiceItemInput = {
  /** 服务类型 工人 */
  serviceItem?: Maybe<Scalars['String']>;
  /** 服务项目编号 */
  serviceItemNo?: Maybe<Scalars['String']>;
  /** 备注 */
  remark?: Maybe<Scalars['String']>;
  /** 服务数量 9 人 台班 */
  chargeQty?: Maybe<Scalars['Float']>;
  /** 计量 */
  chargeUnit?: Maybe<Scalars['String']>;
  /** 描述 */
  chargeDescription?: Maybe<Scalars['String']>;
  /** 服务要求备注 */
  serviceMemo?: Maybe<Scalars['String']>;
  relateAddress?: Maybe<TmsAddressInfoInput>;
};

/** 运输货物明细,可以输入也可以从商品库中来 */
export type TmsGoodsDetail = {
  __typename?: 'TmsGoodsDetail';
  /** 序号 */
  id?: Maybe<Scalars['Int']>;
  /** 商品名称 */
  goodsName?: Maybe<Scalars['String']>;
  /** partNumberSAP */
  partNumberSAP?: Maybe<Scalars['String']>;
  /** 件号 */
  packNo?: Maybe<Scalars['String']>;
  /** 包装种类? */
  packType?: Maybe<Scalars['String']>;
  /** 物品类型 */
  goodsType?: Maybe<Scalars['String']>;
  /** 数量 */
  quantity?: Maybe<Scalars['Float']>;
  /** 单位 */
  unit?: Maybe<Scalars['String']>;
  /** 币制 */
  curr?: Maybe<Scalars['String']>;
  /** 重量 */
  weight?: Maybe<Scalars['Float']>;
  /** 体积 */
  volume?: Maybe<Scalars['Float']>;
  /** 长 */
  length?: Maybe<Scalars['Float']>;
  /** 宽 */
  width?: Maybe<Scalars['Float']>;
  /** 高 */
  height?: Maybe<Scalars['Float']>;
  /** 总价 */
  totalPrice?: Maybe<Scalars['Float']>;
  /**
   * 危险品分类
   *
   * 1类 爆炸品、2类 气体、3类 易燃液体、4类 易燃固体；易于自燃的物资；遇水释放易燃气体的物质、5类 氧化剂和有机过氧化物、6类 毒性物质和感染性物质、7类 放射性物质、8类 腐蚀性物质、9类杂项危险品
   */
  dangerousType?: Maybe<Scalars['String']>;
  /** 温度要求 */
  highTempture?: Maybe<Scalars['Float']>;
  lowTempture?: Maybe<Scalars['Float']>;
  /** 温度要求 有这个需要输入温度 */
  temptureRequire?: Maybe<Scalars['String']>;
};

/** 运输货物明细,可以输入也可以从商品库中来 */
export type TmsGoodsDetailInput = {
  /** 序号 */
  id?: Maybe<Scalars['Int']>;
  /** 商品名称 */
  goodsName?: Maybe<Scalars['String']>;
  /** partNumberSAP */
  partNumberSAP?: Maybe<Scalars['String']>;
  /** 件号 */
  packNo?: Maybe<Scalars['String']>;
  packType?: Maybe<Scalars['String']>;
  /** 物品类型 */
  goodsType?: Maybe<Scalars['String']>;
  /** 数量 */
  quantity?: Maybe<Scalars['Float']>;
  /** 单位 */
  unit?: Maybe<Scalars['String']>;
  /** 币制 */
  curr?: Maybe<Scalars['String']>;
  /** 重量 */
  weight?: Maybe<Scalars['Float']>;
  /** 体积 */
  volume?: Maybe<Scalars['Float']>;
  /** 长 */
  length?: Maybe<Scalars['Float']>;
  /** 宽 */
  width?: Maybe<Scalars['Float']>;
  /** 高 */
  height?: Maybe<Scalars['Float']>;
  /** 总价 */
  totalPrice?: Maybe<Scalars['Float']>;
  /**
   * 危险品分类
   *
   * 1类 爆炸品、2类 气体、3类 易燃液体、4类 易燃固体；易于自燃的物资；遇水释放易燃气体的物质、5类 氧化剂和有机过氧化物、6类 毒性物质和感染性物质、7类 放射性物质、8类 腐蚀性物质、9类杂项危险品
   */
  dangerousType?: Maybe<Scalars['String']>;
  /** 温度要求 */
  highTempture?: Maybe<Scalars['Float']>;
  lowTempture?: Maybe<Scalars['Float']>;
  /** 温度要求 有这个需要输入温度 */
  temptureRequire?: Maybe<Scalars['String']>;
};

/** tms表头 */
export type TmsOrderInfo = IDataInfo & {
  __typename?: 'TmsOrderInfo';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  orderType?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  mainOrderNo?: Maybe<Scalars['String']>;
  batchNo?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  processStr?: Maybe<Scalars['String']>;
  departId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  departName?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type TmsOrderInfoInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  orderType?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  mainOrderNo?: Maybe<Scalars['String']>;
  batchNo?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  processStr?: Maybe<Scalars['String']>;
  departId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  departName?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 便捷委托订单 */
export type TmsPassPortOrder = IDataInfo & {
  __typename?: 'TmsPassPortOrder';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 客户编码 */
  custID?: Maybe<Scalars['String']>;
  /** 客户名称 */
  custName?: Maybe<Scalars['String']>;
  /** 计划提送货时间 */
  planTransDate?: Maybe<Scalars['Date']>;
  /** 进出地点 大场 浦东 */
  workPlace?: Maybe<Scalars['String']>;
  upfile?: Maybe<Scalars['String']>;
  saveurl?: Maybe<Scalars['String']>;
  /** 操作供应商 */
  proCust?: Maybe<Scalars['String']>;
  proUser?: Maybe<Scalars['String']>;
  proUserId?: Maybe<Scalars['String']>;
  attFiles?: Maybe<Array<Maybe<OrderFileInfo>>>;
  /** 分派人   */
  managerUser?: Maybe<Scalars['String']>;
  managerUserId?: Maybe<Scalars['String']>;
  /** 进出方向 进口 出口 */
  workDirection?: Maybe<Scalars['String']>;
  /** 车辆类型 */
  vehicleType?: Maybe<Scalars['String']>;
  /** 车牌号 */
  carNo?: Maybe<Scalars['String']>;
  /** 车辆整备重量 */
  carWeight?: Maybe<Scalars['Float']>;
  /** 司机 */
  driver?: Maybe<Scalars['String']>;
  /** 司机电话 */
  driverPhone?: Maybe<Scalars['String']>;
  /** 挂车车牌 */
  guaVehicleCardNo?: Maybe<Scalars['String']>;
  /** 挂车车架重 */
  guaVehicleFrameWeight?: Maybe<Scalars['Float']>;
  /** 货物总毛重 */
  totalGrossWeight?: Maybe<Scalars['Float']>;
  /** 货物信息 */
  passGoodsList?: Maybe<Array<Maybe<TmsPassPortOrderGoods>>>;
  orderNo?: Maybe<Scalars['String']>;
  tmsApplyNo?: Maybe<Scalars['String']>;
  /** 订单状态 订单执行状态 */
  orderStatus?: Maybe<Scalars['String']>;
  /** 历史状态 */
  processStatus?: Maybe<Scalars['String']>;
  /** 申请人部门 */
  applyDepartName?: Maybe<Scalars['String']>;
  /** 申请人部门ID */
  applyDepartId?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  transTaskOrders?: Maybe<Array<Maybe<TransTaskOrder>>>;
  gmInfolist?: Maybe<Array<Maybe<GmInfo>>>;
  emptygmInfolist?: Maybe<Array<Maybe<GmInfo>>>;
  emptyorderStatus?: Maybe<Scalars['String']>;
  portStatus?: Maybe<Scalars['String']>;
  processStr?: Maybe<Scalars['String']>;
  checkInfo?: Maybe<Scalars['String']>;
  process_instance_id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type TmsPassPortOrderGoods = {
  __typename?: 'TmsPassPortOrderGoods';
  /** 货物名称 */
  gdsNm?: Maybe<Scalars['String']>;
  /** 商品序号 */
  gdsSeqno?: Maybe<Scalars['String']>;
  /** 件号 */
  gdsSpcfModelDesc?: Maybe<Scalars['String']>;
  /** 数量 */
  dclQty?: Maybe<Scalars['Float']>;
  /** 单位 */
  dclUnitcd?: Maybe<Scalars['String']>;
  /** 单位名称 */
  unitName?: Maybe<Scalars['String']>;
  /** 备注 */
  rmk?: Maybe<Scalars['String']>;
  /** 毛重 */
  grossWeight?: Maybe<Scalars['Float']>;
  /** 关联进仓商品ID 唯一 */
  associatedIntoId?: Maybe<Scalars['String']>;
  gdsTypecd?: Maybe<Scalars['String']>;
  /** 关联进仓商品列表 */
  associatedInGoodsList?: Maybe<Array<Maybe<PassPortProd>>>;
};

export type TmsPassPortOrderGoodsInput = {
  /** 货物名称 */
  gdsNm?: Maybe<Scalars['String']>;
  /** 商品序号 */
  gdsSeqno?: Maybe<Scalars['String']>;
  /** 件号 */
  gdsSpcfModelDesc?: Maybe<Scalars['String']>;
  /** 数量 */
  dclQty?: Maybe<Scalars['Float']>;
  /** 单位 */
  dclUnitcd?: Maybe<Scalars['String']>;
  /** 单位名称 */
  unitName?: Maybe<Scalars['String']>;
  /** 备注 */
  rmk?: Maybe<Scalars['String']>;
  /** 关联进仓商品ID 唯一 */
  associatedIntoId?: Maybe<Scalars['String']>;
  tmsApplyNo?: Maybe<Scalars['String']>;
  /** 关联进仓商品列表 */
  associatedInGoodsList?: Maybe<Array<Maybe<PassPortProdInput>>>;
  gdsTypecd?: Maybe<Scalars['String']>;
  /** 关联进仓商品序号 */
  associatedIntoGdsSeqno?: Maybe<Scalars['String']>;
  /** 关联进仓商品便捷进出区编号 */
  associatedIntoPassportNo?: Maybe<Scalars['String']>;
  grossWeight?: Maybe<Scalars['Float']>;
};

export type TmsPassPortOrderInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  /** 客户编码 */
  custID?: Maybe<Scalars['String']>;
  /** 客户名称 */
  custName?: Maybe<Scalars['String']>;
  vehicleType?: Maybe<Scalars['String']>;
  /** 计划提送货时间 */
  planTransDate?: Maybe<Scalars['Date']>;
  /** 进出地点 大场 浦东 */
  workPlace?: Maybe<Scalars['String']>;
  upfile?: Maybe<Scalars['String']>;
  saveurl?: Maybe<Scalars['String']>;
  /** 操作供应商 */
  proCust?: Maybe<Scalars['String']>;
  proUser?: Maybe<Scalars['String']>;
  proUserId?: Maybe<Scalars['String']>;
  attFiles?: Maybe<Array<Maybe<OrderFileInfoInput>>>;
  /** 分派人   */
  managerUser?: Maybe<Scalars['String']>;
  managerUserId?: Maybe<Scalars['String']>;
  /** 进出方向 进口 出口 */
  workDirection?: Maybe<Scalars['String']>;
  /** 车牌号 */
  carNo?: Maybe<Scalars['String']>;
  /** 车辆整备重量 */
  carWeight?: Maybe<Scalars['Float']>;
  /** 司机 */
  driver?: Maybe<Scalars['String']>;
  /** 司机电话 */
  driverPhone?: Maybe<Scalars['String']>;
  /** 挂车车牌 */
  guaVehicleCardNo?: Maybe<Scalars['String']>;
  /** 挂车车架重 */
  guaVehicleFrameWeight?: Maybe<Scalars['Float']>;
  /** 货物总毛重 */
  totalGrossWeight?: Maybe<Scalars['Float']>;
  /** 申请人部门 */
  applyDepartName?: Maybe<Scalars['String']>;
  /** 申请人部门ID */
  applyDepartId?: Maybe<Scalars['String']>;
  /** 货物信息 */
  passGoodsList?: Maybe<Array<Maybe<TmsPassPortOrderGoodsInput>>>;
  orderNo?: Maybe<Scalars['String']>;
  tmsApplyNo?: Maybe<Scalars['String']>;
  orderStatus?: Maybe<Scalars['String']>;
  /** 历史状态 */
  processStatus?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  gmInfolist?: Maybe<Array<Maybe<GmInfoInput>>>;
  emptygmInfolist?: Maybe<Array<Maybe<GmInfoInput>>>;
  emptyorderStatus?: Maybe<Scalars['String']>;
  portStatus?: Maybe<Scalars['String']>;
  processStr?: Maybe<Scalars['String']>;
  checkInfo?: Maybe<Scalars['String']>;
  process_instance_id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 一体化 卡口验放凭证 */
export type TmsProduceWarehouse = IDataInfo & {
  __typename?: 'TmsProduceWarehouse';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 入库日期 */
  inStockDate?: Maybe<Scalars['String']>;
  /** 系列号 */
  serialNo?: Maybe<Scalars['String']>;
  /** 零件号 */
  partNo?: Maybe<Scalars['String']>;
  /** 工单号 */
  orderNo?: Maybe<Scalars['String']>;
  businessId?: Maybe<Scalars['String']>;
  /** 业务清单编号 */
  businessNo?: Maybe<Scalars['String']>;
  gdsNm?: Maybe<Scalars['String']>;
  prodid?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['String']>;
  /** 任务编号 */
  taskNo?: Maybe<Scalars['String']>;
  /** 问题反馈 */
  errInfo?: Maybe<Scalars['String']>;
  /** 状态 */
  status?: Maybe<Scalars['String']>;
  /** 提交日期 */
  ddate?: Maybe<Scalars['String']>;
  /** 部门 */
  departName?: Maybe<Scalars['String']>;
  /** 品名 */
  goodsName?: Maybe<Scalars['String']>;
  /** 批次号 */
  batchNo?: Maybe<Scalars['String']>;
  departId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  parentRefNo?: Maybe<Scalars['String']>;
  createdUser?: Maybe<Scalars['String']>;
  updatedUser?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type TmsProduceWarehouseInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  /** 入库日期 */
  inStockDate?: Maybe<Scalars['String']>;
  /** 系列号 */
  serialNo?: Maybe<Scalars['String']>;
  /** 零件号 */
  partNo?: Maybe<Scalars['String']>;
  /** 工单号 */
  orderNo?: Maybe<Scalars['String']>;
  businessId?: Maybe<Scalars['String']>;
  /** 业务清单编号 */
  businessNo?: Maybe<Scalars['String']>;
  gdsNm?: Maybe<Scalars['String']>;
  prodid?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['String']>;
  /** 任务编号 */
  taskNo?: Maybe<Scalars['String']>;
  /** 问题反馈 */
  errInfo?: Maybe<Scalars['String']>;
  /** 状态 */
  status?: Maybe<Scalars['String']>;
  /** 提交日期 */
  ddate?: Maybe<Scalars['String']>;
  /** 部门 */
  departName?: Maybe<Scalars['String']>;
  /** 品名 */
  goodsName?: Maybe<Scalars['String']>;
  /** 批次号 */
  batchNo?: Maybe<Scalars['String']>;
  departId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  parentRefNo?: Maybe<Scalars['String']>;
  createdUser?: Maybe<Scalars['String']>;
  updatedUser?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 车队司机信息 */
export type TmsSupplierDriverInfo = IDataInfo & {
  __typename?: 'TmsSupplierDriverInfo';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 姓名 */
  name?: Maybe<Scalars['String']>;
  /** 手机号 */
  tel?: Maybe<Scalars['String']>;
  /** 身份证号 */
  idCard?: Maybe<Scalars['String']>;
  /** 驾驶证类型 A1 A2 B1 B2 C1 C2 */
  driverType?: Maybe<Scalars['String']>;
  /** 联系人 */
  contactPerson?: Maybe<Scalars['String']>;
  /** 联系人电话 */
  contactTel?: Maybe<Scalars['String']>;
  /** 在职标志 1 在职 0 离职  2 临时 */
  status?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  /** 所属公司名称 用来区分是否是 */
  supplierId?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type TmsSupplierDriverInfoInput = {
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 姓名 */
  name?: Maybe<Scalars['String']>;
  /** 手机号 */
  tel?: Maybe<Scalars['String']>;
  /** 身份证号 */
  idCard?: Maybe<Scalars['String']>;
  /** 驾驶证类型 A1 A2 B1 B2 C1 C2 */
  driverType?: Maybe<Scalars['String']>;
  /** 联系人 */
  contactPerson?: Maybe<Scalars['String']>;
  /** 联系人电话 */
  contactTel?: Maybe<Scalars['String']>;
  /** 在职标志 1 在职 0 离职  2 临时 */
  status?: Maybe<Scalars['String']>;
  /** 备注 */
  memo?: Maybe<Scalars['String']>;
  /** 所属公司名称 用来区分是否是 */
  supplierId?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 一体化 卡口验放凭证 */
export type TmsSupplierVehicleInfo = IDataInfo & {
  __typename?: 'TmsSupplierVehicleInfo';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 车牌号 */
  vehicleNo?: Maybe<Scalars['String']>;
  /** 车牌颜色 */
  vehicleColor?: Maybe<Scalars['String']>;
  /** 所属供应商 */
  supplierId?: Maybe<Scalars['ID']>;
  /** 所属供应商编号 */
  supplierNo?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  /** 车辆类型编号 */
  vehicleTypeNo?: Maybe<Scalars['String']>;
  /** 车辆类型名称 */
  vehicleType?: Maybe<Scalars['String']>;
  /** 车型大类,运输/叉车/吊车/危险品/冷藏/危险品冷藏  叉车 吊车 单独不用填写地址 用于区分 */
  cargoType?: Maybe<Scalars['String']>;
  /** 危险品类别 */
  danFlag?: Maybe<Scalars['String']>;
  /** 车辆自重 */
  vehicleWeight?: Maybe<Scalars['Float']>;
  frameWeight?: Maybe<Scalars['Float']>;
  vehicleLength?: Maybe<Scalars['Float']>;
  /** 最大载重 */
  cargoWeight?: Maybe<Scalars['Float']>;
  /** 体积高 */
  cargoHeight?: Maybe<Scalars['Float']>;
  /** 体积长 */
  cargoLength?: Maybe<Scalars['Float']>;
  /** 体积宽 */
  cargoWidth?: Maybe<Scalars['Float']>;
  /** 备注 */
  description?: Maybe<Scalars['String']>;
  /** 温度要求 */
  lowTempture?: Maybe<Scalars['Float']>;
  /** 温度要求 */
  highTempture?: Maybe<Scalars['Float']>;
  /** 司机 */
  driverName?: Maybe<Scalars['String']>;
  /** 司机 */
  driverTel?: Maybe<Scalars['String']>;
  /** 司机身份证号 */
  driverId?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type TmsSupplierVehicleInfoInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  /** 车牌号 */
  vehicleNo?: Maybe<Scalars['String']>;
  /** 车牌颜色 */
  vehicleColor?: Maybe<Scalars['String']>;
  /** 所属供应商 */
  supplierId?: Maybe<Scalars['ID']>;
  /** 所属供应商编号 */
  supplierNo?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  /** 车辆类型编号 */
  vehicleTypeNo?: Maybe<Scalars['String']>;
  /** 车辆类型名称 */
  vehicleType?: Maybe<Scalars['String']>;
  /** 车型大类,运输/叉车/吊车/危险品/冷藏/危险品冷藏  叉车 吊车 单独不用填写地址 用于区分 */
  cargoType?: Maybe<Scalars['String']>;
  /** 危险品类别 */
  danFlag?: Maybe<Scalars['String']>;
  /** 车辆自重 */
  vehicleWeight?: Maybe<Scalars['Float']>;
  frameWeight?: Maybe<Scalars['Float']>;
  vehicleLength?: Maybe<Scalars['Float']>;
  /** 最大载重 */
  cargoWeight?: Maybe<Scalars['Float']>;
  /** 体积高 */
  cargoHeight?: Maybe<Scalars['Float']>;
  /** 体积长 */
  cargoLength?: Maybe<Scalars['Float']>;
  /** 体积宽 */
  cargoWidth?: Maybe<Scalars['Float']>;
  /** 备注 */
  description?: Maybe<Scalars['String']>;
  /** 温度要求 */
  lowTempture?: Maybe<Scalars['Float']>;
  /** 温度要求 */
  highTempture?: Maybe<Scalars['Float']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** TMS 运输派车申请单 */
export type TmsTransApply = IDataInfo & {
  __typename?: 'TmsTransApply';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 计划提货时间 */
  planTransDate?: Maybe<Scalars['Date']>;
  /** 提货单序列号 */
  applySeqNo?: Maybe<Scalars['String']>;
  /** 目的地 */
  destinationPlace?: Maybe<Scalars['String']>;
  /** 目的地编码 */
  destinationPlaceCode?: Maybe<Scalars['String']>;
  /** 目的地名称 鼎新仓库 */
  destinationPlaceName?: Maybe<Scalars['String']>;
  destinationContact?: Maybe<Scalars['String']>;
  destinationContactPhone?: Maybe<Scalars['String']>;
  curMessage?: Maybe<Scalars['String']>;
  /** 装货完成 */
  stockStatus?: Maybe<Scalars['String']>;
  stockTime?: Maybe<Scalars['Date']>;
  /** 总票数 */
  count?: Maybe<Scalars['Int']>;
  /** 总件数 */
  totalPack?: Maybe<Scalars['Int']>;
  /** 总重量 */
  totalGrossWeight?: Maybe<Scalars['Float']>;
  /** 提货地点 */
  pickupPlace?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 备注 */
  transMemo?: Maybe<Scalars['String']>;
  /** 申请人部门 */
  applyDepartName?: Maybe<Scalars['String']>;
  applyDepartId?: Maybe<Scalars['String']>;
  /** 运输明细 */
  transDetails?: Maybe<Array<Maybe<TmsTransApplyDetail>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type TmsTransApplyDetail = {
  __typename?: 'TmsTransApplyDetail';
  /** 报关单号 */
  customsDeclarationNo?: Maybe<Scalars['String']>;
  custRefNo?: Maybe<Scalars['String']>;
  /** 总运单号 */
  waybillNo?: Maybe<Scalars['String']>;
  /** 分运单号 */
  subWaybillNo?: Maybe<Scalars['String']>;
  /** 合同号 */
  contractNo?: Maybe<Scalars['String']>;
  /** 尺寸 */
  size?: Maybe<Scalars['String']>;
  /** 件数 */
  packNo?: Maybe<Scalars['Int']>;
  /** 毛重 */
  grossWeight?: Maybe<Scalars['Float']>;
  /** 货物存放地点 */
  goodsStoragePlace?: Maybe<Scalars['String']>;
  /** 货物提货对应仓库地址,监管/物流,区分提货区域 */
  goodsPlaceMemo?: Maybe<Scalars['String']>;
  /** 品名 */
  goodsName?: Maybe<Scalars['String']>;
  /** 备注 */
  memo?: Maybe<Scalars['String']>;
  portGoodsArea?: Maybe<Scalars['String']>;
  portGoodsPlace?: Maybe<Scalars['String']>;
  iEPortName?: Maybe<Scalars['String']>;
};

export type TmsTransApplyDetailInput = {
  customsDeclarationNo?: Maybe<Scalars['String']>;
  waybillNo?: Maybe<Scalars['String']>;
  subWaybillNo?: Maybe<Scalars['String']>;
  contractNo?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  packNo?: Maybe<Scalars['Int']>;
  grossWeight?: Maybe<Scalars['Float']>;
  goodsName?: Maybe<Scalars['String']>;
  custRefNo?: Maybe<Scalars['String']>;
  goodsStoragePlace?: Maybe<Scalars['String']>;
  goodsPlaceMemo?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  portGoodsArea?: Maybe<Scalars['String']>;
  portGoodsPlace?: Maybe<Scalars['String']>;
  iEPortName?: Maybe<Scalars['String']>;
};

export type TmsTransApplyInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  /** 计划提货时间 */
  planTransDate?: Maybe<Scalars['Date']>;
  /** 提货单序列号 */
  applySeqNo?: Maybe<Scalars['String']>;
  /** 目的地 */
  destinationPlace?: Maybe<Scalars['String']>;
  /** 目的地编码 */
  destinationPlaceCode?: Maybe<Scalars['String']>;
  /** 目的地名称 鼎新仓库 */
  destinationPlaceName?: Maybe<Scalars['String']>;
  destinationContact?: Maybe<Scalars['String']>;
  destinationContactPhone?: Maybe<Scalars['String']>;
  curMessage?: Maybe<Scalars['String']>;
  /** 装货完成 */
  stockStatus?: Maybe<Scalars['String']>;
  stockTime?: Maybe<Scalars['Date']>;
  /** 总票数 */
  count?: Maybe<Scalars['Int']>;
  /** 总件数 */
  totalPack?: Maybe<Scalars['Int']>;
  /** 总重量 */
  totalGrossWeight?: Maybe<Scalars['Float']>;
  /** 提货地点 */
  pickupPlace?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 备注 */
  transMemo?: Maybe<Scalars['String']>;
  /** 申请人部门 */
  applyDepartName?: Maybe<Scalars['String']>;
  applyDepartId?: Maybe<Scalars['String']>;
  /** 运输明细 */
  transDetails?: Maybe<Array<Maybe<TmsTransApplyDetailInput>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 司机任务清单 司机需要执行的内容,其中有些需要执行后台的任务的 */
export type TmsTransMissionInfo = IDataInfo & {
  __typename?: 'TmsTransMissionInfo';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 任务序列号 */
  seqNo?: Maybe<Scalars['Int']>;
  /** 任务名称 */
  missionName?: Maybe<Scalars['String']>;
  /** 任务类型 */
  missionType?: Maybe<Scalars['String']>;
  /** 任务地点 */
  missionPlace?: Maybe<Scalars['String']>;
  /** 打卡位置 location 经纬度 checkAddress 地名 */
  location?: Maybe<Scalars['String']>;
  checkAddress?: Maybe<Scalars['String']>;
  tmsApplyNo?: Maybe<Scalars['String']>;
  /** 任务图片 */
  missionPic?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 任务时间 */
  missionTime?: Maybe<Scalars['Date']>;
  /** 任务状态 */
  missionStatus?: Maybe<Scalars['String']>;
  /** 任务描述 */
  missionDesc?: Maybe<Scalars['String']>;
  /** 关联人物单号 */
  transTaskOrderNo?: Maybe<Scalars['String']>;
  /** 任务联系人 */
  missionContact?: Maybe<Scalars['String']>;
  /** 任务联系人电话 */
  missionContactPhone?: Maybe<Scalars['String']>;
  yitihuaPackage?: Maybe<YitihuaPackageInfo>;
  exceptionInfo?: Maybe<YthexcepInfo>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  record?: Maybe<MissionCheckRecord>;
};

export type TmsTransMissionInfoInput = {
  id?: Maybe<Scalars['ID']>;
  /** 任务名称 */
  missionName?: Maybe<Scalars['String']>;
  /** 任务序列号 */
  seqNo?: Maybe<Scalars['Int']>;
  /** 任务类型 */
  missionType?: Maybe<Scalars['String']>;
  /** 任务地点 */
  missionPlace?: Maybe<Scalars['String']>;
  /** 打卡位置  location 经纬度 checkAddress 地名 */
  location?: Maybe<Scalars['String']>;
  checkAddress?: Maybe<Scalars['String']>;
  tmsApplyNo?: Maybe<Scalars['String']>;
  /** 任务图片 */
  missionPic?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 任务时间 */
  missionTime?: Maybe<Scalars['Date']>;
  /** 任务状态 */
  missionStatus?: Maybe<Scalars['String']>;
  /** 任务描述 */
  missionDesc?: Maybe<Scalars['String']>;
  /** 关联人物单号 */
  transTaskOrderNo?: Maybe<Scalars['String']>;
  /** 任务联系人 */
  missionContact?: Maybe<Scalars['String']>;
  /** 任务联系人电话 */
  missionContactPhone?: Maybe<Scalars['String']>;
  yitihuaPackage?: Maybe<YitihuaPackageInfoInput>;
  exceptionInfo?: Maybe<YthexcepInfoInput>;
  appID?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** TMS 客户自己维护的地址部 */
export type TmsUserAddressInfo = IDataInfo & {
  __typename?: 'TmsUserAddressInfo';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 用户名称 地址所属用户 */
  userId?: Maybe<Scalars['String']>;
  addressTag?: Maybe<Scalars['String']>;
  wareHouseName?: Maybe<Scalars['String']>;
  wareHouseLocation?: Maybe<Scalars['String']>;
  subWareHouseList?: Maybe<Array<Maybe<SubWareHouse>>>;
  /** 地址简称 */
  addressBrief?: Maybe<Scalars['String']>;
  /** 地址全称 */
  addressDetail?: Maybe<Scalars['String']>;
  /** 省 */
  province?: Maybe<Scalars['String']>;
  /** 市 */
  city?: Maybe<Scalars['String']>;
  /** 联系人 */
  contactPerson?: Maybe<Scalars['String']>;
  /** 联系电话 */
  contactPhone?: Maybe<Scalars['String']>;
  extraContacter?: Maybe<Array<Maybe<Contacter>>>;
  defaultflag?: Maybe<Scalars['Boolean']>;
  /**
   * 公司名称
   * poi name
   */
  companyName?: Maybe<Scalars['String']>;
  /** "121.382566,31.185671", GPS 坐标 */
  location?: Maybe<Scalars['String']>;
  mongolocation?: Maybe<Scalars['JSON']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type TmsUserAddressInfoInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  /** 用户名称 地址所属用户 */
  userId?: Maybe<Scalars['String']>;
  addressTag?: Maybe<Scalars['String']>;
  wareHouseName?: Maybe<Scalars['String']>;
  wareHouseLocation?: Maybe<Scalars['String']>;
  subWareHouseList?: Maybe<Array<Maybe<SubWareHouseInput>>>;
  /** 地址简称 */
  addressBrief?: Maybe<Scalars['String']>;
  /** 地址全称 */
  addressDetail?: Maybe<Scalars['String']>;
  /** 省 */
  province?: Maybe<Scalars['String']>;
  /** 市 */
  city?: Maybe<Scalars['String']>;
  /** 联系人 */
  contactPerson?: Maybe<Scalars['String']>;
  /** 联系电话 */
  contactPhone?: Maybe<Scalars['String']>;
  extraContacter?: Maybe<Array<Maybe<ContacterInput>>>;
  defaultflag?: Maybe<Scalars['Boolean']>;
  /**
   * 公司名称
   * poi name
   */
  companyName?: Maybe<Scalars['String']>;
  /** "121.382566,31.185671", GPS 坐标 */
  location?: Maybe<Scalars['String']>;
  mongolocation?: Maybe<Scalars['JSON']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 运输申请单车辆信息和数量 */
export type TmsVehicleInfo = {
  __typename?: 'TmsVehicleInfo';
  /** 序号 */
  id?: Maybe<Scalars['Int']>;
  /** 车辆类型编号 */
  vehicleTypeNo?: Maybe<Scalars['String']>;
  tranTypeCode?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  vehicleType?: Maybe<Scalars['String']>;
  /** 车型大类,运输/叉车/吊车  叉车 吊车 单独不用填写地址 用于区分 */
  cargoType?: Maybe<Scalars['String']>;
  /** 危险品类别 */
  danFlag?: Maybe<Scalars['String']>;
  /** 车辆自重 */
  vehicleWeight?: Maybe<Scalars['Int']>;
  vehicleLength?: Maybe<Scalars['Int']>;
  /** 最大载重 */
  cargoWeight?: Maybe<Scalars['Int']>;
  /** 体积高 */
  cargoHeight?: Maybe<Scalars['Int']>;
  /** 体积长 */
  cargoLength?: Maybe<Scalars['Int']>;
  /** 体积宽 */
  cargoWidth?: Maybe<Scalars['Int']>;
  /** 温度要求 */
  lowTempture?: Maybe<Scalars['Float']>;
  highTempture?: Maybe<Scalars['Float']>;
  /** 车辆要求 */
  vehicleRequire?: Maybe<Scalars['String']>;
  /** 车辆数量 */
  vehicleNum?: Maybe<Scalars['Int']>;
  relateAddress?: Maybe<TmsAddressInfo>;
};

/** 运输申请单车辆信息和数量 */
export type TmsVehicleInfoInput = {
  /** 序号 */
  id?: Maybe<Scalars['Int']>;
  /** 车辆类型编号 */
  vehicleTypeNo?: Maybe<Scalars['String']>;
  tranTypeCode?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  /** 车辆名称 */
  vehicleType?: Maybe<Scalars['String']>;
  /** 车型大类,运输/叉车/吊车  叉车 吊车 单独不用填写地址 用于区分 */
  cargoType?: Maybe<Scalars['String']>;
  /** 危险品类别 */
  danFlag?: Maybe<Scalars['String']>;
  /** 车辆自重 */
  vehicleWeight?: Maybe<Scalars['Int']>;
  vehicleLength?: Maybe<Scalars['Int']>;
  /** 最大载重 */
  cargoWeight?: Maybe<Scalars['Int']>;
  /** 体积高 */
  cargoHeight?: Maybe<Scalars['Int']>;
  /** 体积长 */
  cargoLength?: Maybe<Scalars['Int']>;
  /** 体积宽 */
  cargoWidth?: Maybe<Scalars['Int']>;
  /** 温度要求 */
  lowTempture?: Maybe<Scalars['Float']>;
  highTempture?: Maybe<Scalars['Float']>;
  /** 车辆要求 */
  vehicleRequire?: Maybe<Scalars['String']>;
  /** 车辆数量 */
  vehicleNum?: Maybe<Scalars['Int']>;
  relateAddress?: Maybe<TmsAddressInfoInput>;
};

export type TrackBillInfo = {
  __typename?: 'TrackBillInfo';
  trackInfos?: Maybe<Array<Maybe<TrackDetail>>>;
  searchNo?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
  voyageNo?: Maybe<Scalars['String']>;
  ieDate?: Maybe<Scalars['Date']>;
  recieveCity?: Maybe<Scalars['String']>;
  mAWBNo?: Maybe<Scalars['String']>;
  hAWBNo?: Maybe<Scalars['String']>;
};

export type TrackDetail = {
  __typename?: 'TrackDetail';
  status?: Maybe<Scalars['String']>;
  statusEn?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['String']>;
  statusMemo?: Maybe<Scalars['String']>;
  statusMemoEn?: Maybe<Scalars['String']>;
  statusTime?: Maybe<Scalars['Date']>;
  flightNo?: Maybe<Scalars['String']>;
  loadAPT?: Maybe<Scalars['String']>;
  opeAPT?: Maybe<Scalars['String']>;
  opeAPTEn?: Maybe<Scalars['String']>;
  unloadAPT?: Maybe<Scalars['String']>;
  unloadATPEEn?: Maybe<Scalars['String']>;
  loadATPEn?: Maybe<Scalars['String']>;
};

export type TrainDataInfo = IDataInfo & {
  __typename?: 'TrainDataInfo';
  id?: Maybe<Scalars['ID']>;
  orderNo?: Maybe<Scalars['String']>;
  OrginFileName?: Maybe<Scalars['String']>;
  OSSPath?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  FileType?: Maybe<Scalars['String']>;
  dataType?: Maybe<Scalars['String']>;
  classinfo?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  confirmflag?: Maybe<Scalars['Boolean']>;
  errMark?: Maybe<Scalars['Int']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type TrainDataInfoInput = {
  id?: Maybe<Scalars['ID']>;
  orderNo?: Maybe<Scalars['String']>;
  OrginFileName?: Maybe<Scalars['String']>;
  OSSPath?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  FileType?: Maybe<Scalars['String']>;
  dataType?: Maybe<Scalars['String']>;
  classinfo?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  confirmflag?: Maybe<Scalars['Boolean']>;
  errMark?: Maybe<Scalars['Int']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 车辆安排任务单 司机根据任务单计算工作量 */
export type TransTaskOrder = IDataInfo & {
  __typename?: 'TransTaskOrder';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 任务单号 */
  taskNo?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  supplierPersonId?: Maybe<Scalars['String']>;
  roadForwadingOrder?: Maybe<RoadTransForwardingOrder>;
  applyInfo?: Maybe<TmsCustTransApply>;
  missions?: Maybe<Array<Maybe<TmsTransMissionInfo>>>;
  /**
   * "
   * 委托联系人
   */
  taskContactPerson?: Maybe<Scalars['String']>;
  taskContactTel?: Maybe<Scalars['String']>;
  vehicleType?: Maybe<Scalars['String']>;
  cargoType?: Maybe<Scalars['String']>;
  driver?: Maybe<Scalars['String']>;
  driverTel?: Maybe<Scalars['String']>;
  vehicleCardNo?: Maybe<Scalars['String']>;
  guavehicleCardNo?: Maybe<Scalars['String']>;
  escortDriver?: Maybe<Scalars['String']>;
  escortTel?: Maybe<Scalars['String']>;
  /** 押运员身份证 */
  escortCardNo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  /** 计划提货日期 */
  planTransDate?: Maybe<Scalars['Date']>;
  /** 委托编号 */
  fOrderNo?: Maybe<Scalars['String']>;
  /** 对应的运输申请单 */
  tmsApplyNo?: Maybe<Scalars['String']>;
  /** 提货地址 */
  pickupPlaces?: Maybe<Array<Maybe<TmsAddressInfo>>>;
  /** 送货地址 */
  targetPlaces?: Maybe<Array<Maybe<TmsAddressInfo>>>;
  /** 随附单证 */
  attFiles?: Maybe<Array<Maybe<OrderFileInfo>>>;
  /** 关联装车单清单 */
  yitihuaPackageList?: Maybe<Array<Maybe<YitihuaPackageInfo>>>;
  /** 出区装车单 */
  outyitihuaPackageList?: Maybe<Array<Maybe<YitihuaPackageInfo>>>;
  /** 件数 */
  packNo?: Maybe<Scalars['Int']>;
  /** 毛重 */
  grossWeight?: Maybe<Scalars['Float']>;
  /** 车辆自重 */
  vehicleWeight?: Maybe<Scalars['Float']>;
  frameWeight?: Maybe<Scalars['Float']>;
  /** 其他要求 */
  otherRequire?: Maybe<Scalars['String']>;
  /** 操作指引 */
  operateGuide?: Maybe<Scalars['String']>;
  transType?: Maybe<Scalars['String']>;
  /**
   * 海关操作类型,两地流转,出卡口,进k口 无需报关
   * 用来区分
   */
  customsType?: Maybe<Scalars['String']>;
  processStatus?: Maybe<Scalars['String']>;
  /** 签到异常 */
  checkStatus?: Maybe<Scalars['String']>;
  checkError?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type TransTaskOrderInput = {
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 任务单号 */
  taskNo?: Maybe<Scalars['String']>;
  /**
   * "
   * 委托联系人
   */
  taskContactPerson?: Maybe<Scalars['String']>;
  taskContactTel?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  supplierPersonId?: Maybe<Scalars['String']>;
  supplierPersonName?: Maybe<Scalars['String']>;
  vehicleType?: Maybe<Scalars['String']>;
  cargoType?: Maybe<Scalars['String']>;
  driver?: Maybe<Scalars['String']>;
  driverTel?: Maybe<Scalars['String']>;
  vehicleCardNo?: Maybe<Scalars['String']>;
  guavehicleCardNo?: Maybe<Scalars['String']>;
  escortDriver?: Maybe<Scalars['String']>;
  escortTel?: Maybe<Scalars['String']>;
  /** 押运员身份证 */
  escortCardNo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  /** 计划提货日期 */
  planTransDate?: Maybe<Scalars['Date']>;
  /** 委托编号 */
  fOrderNo?: Maybe<Scalars['String']>;
  /** 对应的运输申请单 */
  tmsApplyNo?: Maybe<Scalars['String']>;
  /** 提货地址 */
  pickupPlaces?: Maybe<Array<Maybe<TmsAddressInfoInput>>>;
  /** 送货地址 */
  targetPlaces?: Maybe<Array<Maybe<TmsAddressInfoInput>>>;
  attFiles?: Maybe<Array<Maybe<OrderFileInfoInput>>>;
  /** 件数 */
  packNo?: Maybe<Scalars['Int']>;
  /** 毛重 */
  grossWeight?: Maybe<Scalars['Float']>;
  /** 自重 */
  vehicleWeight?: Maybe<Scalars['Float']>;
  /** 车架重 */
  frameWeight?: Maybe<Scalars['Float']>;
  /** 其他要求 */
  otherRequire?: Maybe<Scalars['String']>;
  /** 操作指引 */
  operateGuide?: Maybe<Scalars['String']>;
  processStatus?: Maybe<Scalars['String']>;
  transType?: Maybe<Scalars['String']>;
  /**
   * 海关操作类型,两地流转,出卡口,进k口 无需报关
   * 用来区分
   */
  customsType?: Maybe<Scalars['String']>;
  checkStatus?: Maybe<Scalars['String']>;
  checkError?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 运输车辆信息 */
export type TransVehicleInfo = IDataInfo & {
  __typename?: 'TransVehicleInfo';
  id?: Maybe<Scalars['ID']>;
  /** 车辆类型编号 */
  vehicleTypeNo?: Maybe<Scalars['String']>;
  /** 车辆类型名称 */
  vehicleType?: Maybe<Scalars['String']>;
  /** 车型大类,运输/叉车/吊车  叉车 吊车 单独不用填写地址 用于区分 */
  cargoType?: Maybe<Scalars['String']>;
  /** 危险品类别 */
  danFlag?: Maybe<Scalars['String']>;
  /** 车辆自重 */
  vehicleWeight?: Maybe<Scalars['Float']>;
  vehicleLength?: Maybe<Scalars['Float']>;
  /** 最大载重 */
  cargoWeight?: Maybe<Scalars['Float']>;
  /** 体积高 */
  cargoHeight?: Maybe<Scalars['Float']>;
  /** 体积长 */
  cargoLength?: Maybe<Scalars['Float']>;
  /** 体积宽 */
  cargoWidth?: Maybe<Scalars['Float']>;
  /** 车架重 */
  frameWeight?: Maybe<Scalars['Float']>;
  /** 备注 */
  description?: Maybe<Scalars['String']>;
  /** 温度要求 */
  lowTempture?: Maybe<Scalars['Float']>;
  /** 温度要求 */
  highTempture?: Maybe<Scalars['Float']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 运输车辆信息 */
export type TransVehicleInfoInput = {
  id?: Maybe<Scalars['ID']>;
  /** 车辆类型编号 */
  vehicleTypeNo?: Maybe<Scalars['String']>;
  /** 车辆类型名称 */
  vehicleType?: Maybe<Scalars['String']>;
  /** 车型大类,运输/叉车/吊车/危险品/冷藏/危险品冷藏  叉车 吊车 单独不用填写地址 用于区分 */
  cargoType?: Maybe<Scalars['String']>;
  /** 危险品类别 */
  danFlag?: Maybe<Scalars['String']>;
  /** 车辆自重 */
  vehicleWeight?: Maybe<Scalars['Float']>;
  vehicleLength?: Maybe<Scalars['Float']>;
  /** 最大载重 */
  cargoWeight?: Maybe<Scalars['Float']>;
  /** 体积高 */
  cargoHeight?: Maybe<Scalars['Float']>;
  /** 体积长 */
  cargoLength?: Maybe<Scalars['Float']>;
  /** 体积宽 */
  cargoWidth?: Maybe<Scalars['Float']>;
  /** 车架重 */
  frameWeight?: Maybe<Scalars['Float']>;
  /** 备注 */
  description?: Maybe<Scalars['String']>;
  /** 温度要求 */
  lowTempture?: Maybe<Scalars['Float']>;
  /** 温度要求 */
  highTempture?: Maybe<Scalars['Float']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type UpdateResponse = {
  __typename?: 'UpdateResponse';
  status?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  statusMessage?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
};


export type ValidateErrorDesc = {
  __typename?: 'ValidateErrorDesc';
  /** 如果是list 发生问的的行数 */
  lineNo?: Maybe<Scalars['String']>;
  /** 相关字段 */
  relatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 校验等级 */
  ruleLevel?: Maybe<Scalars['String']>;
  /** 校验错误描述 */
  errorMessage?: Maybe<Scalars['String']>;
};

export type WmsGoodsDetail = {
  __typename?: 'WmsGoodsDetail';
  goodsCode?: Maybe<Scalars['String']>;
  goodsName?: Maybe<Scalars['String']>;
  goodsUnit?: Maybe<Scalars['String']>;
  goodsCount?: Maybe<Scalars['Int']>;
  goodsWeight?: Maybe<Scalars['Float']>;
  goodsVolume?: Maybe<Scalars['Float']>;
  goodsMemo?: Maybe<Scalars['String']>;
  /** 进仓序列号 */
  inwardNo?: Maybe<Scalars['String']>;
  /** 商品描述信息 json，现在是和运输过来的一致，后面可能会有变化 */
  goodsDesp?: Maybe<TmsTransApplyDetail>;
};

export type WmsGoodsDetailInput = {
  goodsCode?: Maybe<Scalars['String']>;
  goodsName?: Maybe<Scalars['String']>;
  goodsUnit?: Maybe<Scalars['String']>;
  goodsCount?: Maybe<Scalars['Int']>;
  goodsWeight?: Maybe<Scalars['Float']>;
  goodsVolume?: Maybe<Scalars['Float']>;
  goodsMemo?: Maybe<Scalars['String']>;
  /** 进仓序列号 */
  inwardNo?: Maybe<Scalars['String']>;
  /** 商品描述信息 json，现在是和运输过来的一致，后面可能会有变化 */
  goodsDesp?: Maybe<TmsTransApplyDetailInput>;
};

/** 仓库入库计划 */
export type WmsInwardPlan = IDataInfo & {
  __typename?: 'WmsInwardPlan';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  /** 仓库名称 */
  warehouseName?: Maybe<Scalars['String']>;
  /** 仓库编码 */
  warehouseCode?: Maybe<Scalars['String']>;
  /** 计划入库时间 */
  planInwardDate?: Maybe<Scalars['Date']>;
  /** 提货单号 */
  planApplySeqNo?: Maybe<Scalars['String']>;
  /** 计划入库票数 */
  count?: Maybe<Scalars['Int']>;
  /** 总件数 */
  totalPack?: Maybe<Scalars['Int']>;
  /** 总重量 */
  totalGrossWeight?: Maybe<Scalars['Float']>;
  /** 入库明细 */
  inwardGoods?: Maybe<Array<Maybe<WmsGoodsDetail>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type WmsInwardPlanInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type XportOrderMain = {
  __typename?: 'XportOrderMain';
  id?: Maybe<Scalars['ID']>;
  /** 业务类型 一体化其他等 加一个参数 */
  orderType?: Maybe<KeyValue>;
  orderNo?: Maybe<Scalars['String']>;
  orderOutNo?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
  appCompanyMini?: Maybe<KeyValue>;
  coUserMini?: Maybe<KeyValue>;
  processRouteType?: Maybe<Scalars['String']>;
  processFiles?: Maybe<Array<Maybe<Scalars['ID']>>>;
  memo?: Maybe<Scalars['String']>;
  ieDate?: Maybe<Scalars['Date']>;
  statusCode?: Maybe<Scalars['Int']>;
  supplier?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
  xportYWType?: Maybe<Scalars['String']>;
  zhidantype?: Maybe<Scalars['String']>;
  statusName?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  preOrderTemplateName?: Maybe<Scalars['String']>;
  fileProcessPercent?: Maybe<Scalars['Int']>;
  departInfo?: Maybe<KeyValue>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  /** 上飞业务编号或货运业务编号 订单号 */
  custRefNo?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  custCompanyMini?: Maybe<KeyValue>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  recTime?: Maybe<Scalars['Date']>;
  estFinishCustomDate?: Maybe<Scalars['Date']>;
  departName?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  attachFiles?: Maybe<Array<Maybe<KeyValue>>>;
  processTaskList?: Maybe<Array<Maybe<ProcessTaskInfo>>>;
  /** mail._id */
  mailId?: Maybe<Scalars['String']>;
  jobInfo?: Maybe<FunJobInfo>;
};

/** 一体化相关装车单信息 */
export type YitihuaPackageInfo = {
  __typename?: 'YitihuaPackageInfo';
  /** 装车单id 用来get装车单信息 状态 */
  id?: Maybe<Scalars['Int']>;
  releaseid?: Maybe<Scalars['Int']>;
  /** 装车单号 */
  packageListNo?: Maybe<Scalars['String']>;
  /** 进出标志 */
  iePack?: Maybe<Scalars['String']>;
  /** 场地 代码 大场,浦东 */
  area?: Maybe<Scalars['String']>;
  /** 总重量 */
  weight?: Maybe<Scalars['Int']>;
  /** 装车单关联单证编号(卡口验放单号) 第一个 空车单只有一个, */
  sasPassportPreentNo?: Maybe<Scalars['String']>;
  /** 卡口验放凭证出入库类型 */
  passportTypecd?: Maybe<Scalars['String']>;
  /** 当前状态 */
  optStatus?: Maybe<Scalars['String']>;
  /** 卡口状态 */
  releaseOpStatus?: Maybe<Scalars['String']>;
  rconfirm?: Maybe<Scalars['Boolean']>;
  pconfirm?: Maybe<Scalars['Boolean']>;
};

export type YitihuaPackageInfoInput = {
  /** 装车单id 用来get装车单信息 状态 */
  id?: Maybe<Scalars['Int']>;
  /** 装车单号 */
  packageListNo?: Maybe<Scalars['String']>;
  /** 进出标志 */
  iePack?: Maybe<Scalars['String']>;
  /** 场地 代码 大场,浦东 */
  area?: Maybe<Scalars['String']>;
  /** 总重量 */
  weight?: Maybe<Scalars['Int']>;
  /** 装车单关联单证编号(卡口验放单号) 第一个 空车单只有一个, */
  sasPassportPreentNo?: Maybe<Scalars['String']>;
  /** 卡口验放凭证出入库类型 */
  passportTypecd?: Maybe<Scalars['String']>;
  /** 当前状态 */
  optStatus?: Maybe<Scalars['String']>;
};

/** 一体化 卡口验放凭证 */
export type YitihuaPackageList = IDataInfo & {
  __typename?: 'YitihuaPackageList';
  appID?: Maybe<Scalars['String']>;
  /**
   * id
   *
   * 后台生成
   */
  id?: Maybe<Scalars['ID']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type YitihuaPackageListInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type ApplyResult = {
  __typename?: 'applyResult';
  errcode?: Maybe<Scalars['Int']>;
  errmsg?: Maybe<Scalars['String']>;
  process_instance_id?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['String']>;
};

export type ClassifyListInfo = {
  __typename?: 'classifyListInfo';
  codeTs?: Maybe<Scalars['String']>;
  unit1?: Maybe<Scalars['String']>;
  unit1Name?: Maybe<Scalars['String']>;
  unit2?: Maybe<Scalars['String']>;
  unit2Name?: Maybe<Scalars['String']>;
  unitFlag?: Maybe<Scalars['String']>;
  low_rate?: Maybe<Scalars['String']>;
  high_rate?: Maybe<Scalars['String']>;
  out_rate?: Maybe<Scalars['String']>;
  reg_rate?: Maybe<Scalars['String']>;
  reg_amount?: Maybe<Scalars['Float']>;
  tax_rate?: Maybe<Scalars['String']>;
  tax_amount?: Maybe<Scalars['Float']>;
  back_rate?: Maybe<Scalars['String']>;
  low_t_rate?: Maybe<Scalars['String']>;
  low_out_rate?: Maybe<Scalars['String']>;
  ret_rate?: Maybe<Scalars['String']>;
  cif_total_amount?: Maybe<Scalars['Float']>;
  extrange_rate_cny?: Maybe<Scalars['Float']>;
  in_amount?: Maybe<Scalars['Float']>;
  out_amount?: Maybe<Scalars['Float']>;
  ret_amount?: Maybe<Scalars['Float']>;
  total_tax_amount?: Maybe<Scalars['Float']>;
  fob_trans_fee?: Maybe<Scalars['Float']>;
  cusOriginCountry?: Maybe<Scalars['String']>;
};

export type ClassinfoInfoInput = {
  codeTs?: Maybe<Scalars['String']>;
  unit1?: Maybe<Scalars['String']>;
  unit1Name?: Maybe<Scalars['String']>;
  unit2?: Maybe<Scalars['String']>;
  unit2Name?: Maybe<Scalars['String']>;
  unitFlag?: Maybe<Scalars['String']>;
  low_rate?: Maybe<Scalars['String']>;
  high_rate?: Maybe<Scalars['String']>;
  out_rate?: Maybe<Scalars['String']>;
  reg_rate?: Maybe<Scalars['String']>;
  reg_amount?: Maybe<Scalars['Float']>;
  tax_rate?: Maybe<Scalars['String']>;
  tax_amount?: Maybe<Scalars['Float']>;
  back_rate?: Maybe<Scalars['String']>;
  low_t_rate?: Maybe<Scalars['String']>;
  low_out_rate?: Maybe<Scalars['String']>;
  ret_rate?: Maybe<Scalars['String']>;
  cif_total_amount?: Maybe<Scalars['Float']>;
  extrange_rate_cny?: Maybe<Scalars['Float']>;
  in_amount?: Maybe<Scalars['Float']>;
  out_amount?: Maybe<Scalars['Float']>;
  ret_amount?: Maybe<Scalars['Float']>;
  total_tax_amount?: Maybe<Scalars['Float']>;
  fob_trans_fee?: Maybe<Scalars['Float']>;
  cusOriginCountry?: Maybe<Scalars['String']>;
};

/** 税则校验列表 */
export type Contract = IDataInfo & {
  __typename?: 'contract';
  id?: Maybe<Scalars['ID']>;
  contractId?: Maybe<Scalars['String']>;
  contractNo?: Maybe<Scalars['String']>;
  contractName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  signBy?: Maybe<Scalars['String']>;
  signDate?: Maybe<Scalars['Date']>;
  companyCode?: Maybe<Scalars['String']>;
  companyType?: Maybe<Scalars['String']>;
  accountsCname?: Maybe<Scalars['String']>;
  contractAmount?: Maybe<Scalars['String']>;
  businessAmount?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  singlePurchase?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type DataLog = {
  __typename?: 'dataLog';
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  upid?: Maybe<Scalars['String']>;
  upmemo?: Maybe<Scalars['String']>;
  uptype?: Maybe<Scalars['String']>;
  upperson?: Maybe<Scalars['String']>;
  uptime?: Maybe<Scalars['Date']>;
  detail?: Maybe<Scalars['JSON']>;
};

export type DataLogInput = {
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  upid?: Maybe<Scalars['String']>;
  upmemo?: Maybe<Scalars['String']>;
  uptype?: Maybe<Scalars['String']>;
  upperson?: Maybe<Scalars['String']>;
  uptime?: Maybe<Scalars['Date']>;
  detail?: Maybe<Scalars['JSON']>;
};

export type DecMergeListVo = {
  __typename?: 'decMergeListVo';
  sku?: Maybe<Scalars['String']>;
  realdeclTotal?: Maybe<Scalars['String']>;
  contrNo?: Maybe<Scalars['String']>;
  mbgModel?: Maybe<Scalars['String']>;
  netWeight?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['String']>;
  subsystem?: Maybe<Scalars['String']>;
  nameDesc?: Maybe<Scalars['String']>;
  eqpname?: Maybe<Scalars['String']>;
  firstlevel?: Maybe<Scalars['String']>;
  secondlevel?: Maybe<Scalars['String']>;
  decMergeOrder?: Maybe<Scalars['String']>;
  combinebf?: Maybe<Scalars['String']>;
  ControlMark?: Maybe<Scalars['String']>;
  TarifFlag?: Maybe<Scalars['String']>;
  productid?: Maybe<Scalars['String']>;
  productupdateDate?: Maybe<Scalars['Date']>;
  pageindex?: Maybe<Scalars['Int']>;
  pageurl?: Maybe<Scalars['String']>;
  instockSku?: Maybe<Scalars['String']>;
  confidence?: Maybe<Scalars['String']>;
  drawamount?: Maybe<Scalars['Float']>;
  freeFlag?: Maybe<Scalars['String']>;
  sourceProdInfo?: Maybe<Array<Maybe<SplitProdDetail>>>;
  targetProdInfo?: Maybe<Array<Maybe<SplitProdDetail>>>;
  loctioninfo?: Maybe<Array<Maybe<OcrLoction>>>;
  classifyInfo?: Maybe<ClassifyListInfo>;
  extendjson?: Maybe<Scalars['JSON']>;
  dnno?: Maybe<Scalars['String']>;
  dnpage?: Maybe<Scalars['String']>;
  pageinvoice?: Maybe<Scalars['String']>;
  hzsku?: Maybe<Scalars['String']>;
  pageNetweight?: Maybe<Scalars['String']>;
  realdeclPrice?: Maybe<Scalars['String']>;
  namememo?: Maybe<Scalars['String']>;
  modflag?: Maybe<Scalars['Int']>;
  multiflag?: Maybe<Scalars['Int']>;
  singleweight?: Maybe<Scalars['Float']>;
  preDecCiqXiangHui?: Maybe<PreDecCiqXiangHui>;
  packTypeName?: Maybe<Scalars['String']>;
  goodsSpec?: Maybe<Scalars['String']>;
  codeTs?: Maybe<Scalars['String']>;
  goodsAttrName?: Maybe<Scalars['String']>;
  origPlaceCodeName?: Maybe<Scalars['String']>;
  purposeName?: Maybe<Scalars['String']>;
  ciqCurr?: Maybe<Scalars['String']>;
  cusOriginCountry?: Maybe<Scalars['String']>;
  qty1?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  dutyModeName?: Maybe<Scalars['String']>;
  goodsTotalVal?: Maybe<Scalars['String']>;
  dutyMode?: Maybe<Scalars['String']>;
  updateTime?: Maybe<Scalars['String']>;
  goodsBrand?: Maybe<Scalars['String']>;
  unit1?: Maybe<Scalars['String']>;
  unCode?: Maybe<Scalars['String']>;
  prodBatchNo?: Maybe<Scalars['String']>;
  gUnit?: Maybe<Scalars['String']>;
  packSpec?: Maybe<Scalars['String']>;
  contrItem?: Maybe<Scalars['String']>;
  qty2?: Maybe<Scalars['String']>;
  unit2Name?: Maybe<Scalars['String']>;
  unit2?: Maybe<Scalars['String']>;
  createUser?: Maybe<Scalars['String']>;
  tradeCurr?: Maybe<Scalars['String']>;
  stuff?: Maybe<Scalars['String']>;
  origPlaceCode?: Maybe<Scalars['String']>;
  destinationCountry?: Maybe<Scalars['String']>;
  declTotal?: Maybe<Scalars['String']>;
  codeTsName?: Maybe<Scalars['String']>;
  goodsAttr?: Maybe<Scalars['String']>;
  goodsModel?: Maybe<Scalars['String']>;
  indbTime?: Maybe<Scalars['String']>;
  updateUser?: Maybe<Scalars['String']>;
  gNo?: Maybe<Scalars['Int']>;
  produceDate?: Maybe<Scalars['String']>;
  gModel?: Maybe<Scalars['String']>;
  ciqWtMeasUnit?: Maybe<Scalars['String']>;
  ciqDestCode?: Maybe<Scalars['String']>;
  ciqWtMeasUnitName?: Maybe<Scalars['String']>;
  gUnitName?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  tradeCurrName?: Maybe<Scalars['String']>;
  destinationCountryName?: Maybe<Scalars['String']>;
  gQty?: Maybe<Scalars['String']>;
  declPrice?: Maybe<Scalars['String']>;
  gName?: Maybe<Scalars['String']>;
  goodsLegalInspectionMark?: Maybe<Scalars['String']>;
  noDangFlag?: Maybe<Scalars['String']>;
  prodValidDt?: Maybe<Scalars['String']>;
  exgVersion?: Maybe<Scalars['String']>;
  hsCodeDesc?: Maybe<Scalars['String']>;
  districtCodeName?: Maybe<Scalars['String']>;
  dangName?: Maybe<Scalars['String']>;
  ciqOriginCountry?: Maybe<Scalars['String']>;
  preDecCiqGoodsCont?: Maybe<Scalars['String']>;
  stdWeightUnitCode?: Maybe<Scalars['String']>;
  packType?: Maybe<Scalars['String']>;
  engManEntCnm?: Maybe<Scalars['String']>;
  ciqCurrName?: Maybe<Scalars['String']>;
  unit1Name?: Maybe<Scalars['String']>;
  prodQgp?: Maybe<Scalars['String']>;
  cusOriginCountryName?: Maybe<Scalars['String']>;
  districtCode?: Maybe<Scalars['String']>;
  ciqWeight?: Maybe<Scalars['String']>;
  exgNo?: Maybe<Scalars['String']>;
  noDangFlagName?: Maybe<Scalars['String']>;
  ciqName?: Maybe<Scalars['String']>;
  ciqDestCodeName?: Maybe<Scalars['String']>;
  ciqCode?: Maybe<Scalars['String']>;
  preDecCiqGoodsLimit?: Maybe<Array<Maybe<SwPreDecCiqGoodsLimit>>>;
};

export type DecMergeListVoInput = {
  gNo?: Maybe<Scalars['Int']>;
  contrItem?: Maybe<Scalars['String']>;
  codeTs?: Maybe<Scalars['String']>;
  ciqName?: Maybe<Scalars['String']>;
  ciqCode?: Maybe<Scalars['String']>;
  gName?: Maybe<Scalars['String']>;
  gModel?: Maybe<Scalars['String']>;
  gQty?: Maybe<Scalars['String']>;
  gUnitName?: Maybe<Scalars['String']>;
  gUnit?: Maybe<Scalars['String']>;
  declPrice?: Maybe<Scalars['String']>;
  declTotal?: Maybe<Scalars['String']>;
  tradeCurrName?: Maybe<Scalars['String']>;
  tradeCurr?: Maybe<Scalars['String']>;
  qty1?: Maybe<Scalars['String']>;
  unit1Name?: Maybe<Scalars['String']>;
  unit1?: Maybe<Scalars['String']>;
  exgVersion?: Maybe<Scalars['String']>;
  exgNo?: Maybe<Scalars['String']>;
  destinationCountryName?: Maybe<Scalars['String']>;
  destinationCountry?: Maybe<Scalars['String']>;
  qty2?: Maybe<Scalars['String']>;
  unit2Name?: Maybe<Scalars['String']>;
  unit2?: Maybe<Scalars['String']>;
  cusOriginCountryName?: Maybe<Scalars['String']>;
  cusOriginCountry?: Maybe<Scalars['String']>;
  origPlaceCodeName?: Maybe<Scalars['String']>;
  origPlaceCode?: Maybe<Scalars['String']>;
  districtCodeName?: Maybe<Scalars['String']>;
  districtCode?: Maybe<Scalars['String']>;
  ciqDestCodeName?: Maybe<Scalars['String']>;
  ciqDestCode?: Maybe<Scalars['String']>;
  dutyModeName?: Maybe<Scalars['String']>;
  dutyMode?: Maybe<Scalars['String']>;
  goodsAttrName?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  realdeclTotal?: Maybe<Scalars['String']>;
  dnno?: Maybe<Scalars['String']>;
  dnpage?: Maybe<Scalars['String']>;
  pageinvoice?: Maybe<Scalars['String']>;
  hzsku?: Maybe<Scalars['String']>;
  pageNetweight?: Maybe<Scalars['String']>;
  realdeclPrice?: Maybe<Scalars['String']>;
  contrNo?: Maybe<Scalars['String']>;
  mbgModel?: Maybe<Scalars['String']>;
  netWeight?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['String']>;
  subsystem?: Maybe<Scalars['String']>;
  nameDesc?: Maybe<Scalars['String']>;
  eqpname?: Maybe<Scalars['String']>;
  firstlevel?: Maybe<Scalars['String']>;
  secondlevel?: Maybe<Scalars['String']>;
  decMergeOrder?: Maybe<Scalars['String']>;
  combinebf?: Maybe<Scalars['String']>;
  ControlMark?: Maybe<Scalars['String']>;
  TarifFlag?: Maybe<Scalars['String']>;
  productid?: Maybe<Scalars['String']>;
  productupdateDate?: Maybe<Scalars['Date']>;
  pageindex?: Maybe<Scalars['Int']>;
  pageurl?: Maybe<Scalars['String']>;
  instockSku?: Maybe<Scalars['String']>;
  confidence?: Maybe<Scalars['String']>;
  drawamount?: Maybe<Scalars['Float']>;
  sourceProdInfo?: Maybe<Array<Maybe<SplitProdDetailInput>>>;
  targetProdInfo?: Maybe<Array<Maybe<SplitProdDetailInput>>>;
  classifyInfo?: Maybe<ClassinfoInfoInput>;
  purpose?: Maybe<Scalars['String']>;
  purposeName?: Maybe<Scalars['String']>;
  goodsAttr?: Maybe<Scalars['String']>;
  freeFlag?: Maybe<Scalars['String']>;
  loctioninfo?: Maybe<Array<Maybe<OcrLoctionInput>>>;
  prodQgp?: Maybe<Scalars['String']>;
  stuff?: Maybe<Scalars['String']>;
  prodValidDt?: Maybe<Scalars['String']>;
  engManEntCnm?: Maybe<Scalars['String']>;
  goodsSpec?: Maybe<Scalars['String']>;
  goodsBrand?: Maybe<Scalars['String']>;
  goodsModel?: Maybe<Scalars['String']>;
  produceDate?: Maybe<Scalars['String']>;
  prodBatchNo?: Maybe<Scalars['String']>;
  noDangFlagName?: Maybe<Scalars['String']>;
  noDangFlag?: Maybe<Scalars['String']>;
  unCode?: Maybe<Scalars['String']>;
  dangName?: Maybe<Scalars['String']>;
  packTypeName?: Maybe<Scalars['String']>;
  packType?: Maybe<Scalars['String']>;
  packSpec?: Maybe<Scalars['String']>;
  preDecCiqGoodsLimit?: Maybe<Array<Maybe<PreDecCiqGoodsLimitInput>>>;
  extendjson?: Maybe<Scalars['JSON']>;
  namememo?: Maybe<Scalars['String']>;
  modflag?: Maybe<Scalars['Int']>;
  multiflag?: Maybe<Scalars['Int']>;
  singleweight?: Maybe<Scalars['Float']>;
  preDecCiqXiangHui?: Maybe<PreDecCiqXiangHuiInput>;
};

export type DecOtherPacksVo = {
  __typename?: 'decOtherPacksVo';
  packType?: Maybe<Scalars['String']>;
  packTypeCode?: Maybe<Scalars['String']>;
  packQty?: Maybe<Scalars['Int']>;
};

export type DecOtherPacksVoInput = {
  packType?: Maybe<Scalars['String']>;
  packTypeCode?: Maybe<Scalars['String']>;
  packQty?: Maybe<Scalars['Int']>;
};

/** 单证状态列表 */
export type Dzstateinfo = IDataInfo & {
  __typename?: 'dzstateinfo';
  id?: Maybe<Scalars['ID']>;
  entryId?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
  customMaster?: Maybe<Scalars['String']>;
  agentName?: Maybe<Scalars['String']>;
  tradeCo?: Maybe<Scalars['String']>;
  packNo?: Maybe<Scalars['String']>;
  cusTrafMode?: Maybe<Scalars['String']>;
  trafName?: Maybe<Scalars['String']>;
  cusVoyageNo?: Maybe<Scalars['String']>;
  netWt?: Maybe<Scalars['String']>;
  grossWt?: Maybe<Scalars['String']>;
  iEDate?: Maybe<Scalars['String']>;
  dDate?: Maybe<Scalars['String']>;
  rkDate?: Maybe<Scalars['String']>;
  jgDate?: Maybe<Scalars['String']>;
  fxDate?: Maybe<Scalars['String']>;
  dgDate?: Maybe<Scalars['String']>;
  checkDate?: Maybe<Scalars['String']>;
  checkMemo?: Maybe<Scalars['String']>;
  revokeDate?: Maybe<Scalars['String']>;
  revokeMemo?: Maybe<Scalars['String']>;
  tdDate?: Maybe<Scalars['String']>;
  tdMemo?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type GmInfo = {
  __typename?: 'gmInfo';
  businessNo?: Maybe<Scalars['String']>;
  businessStatus?: Maybe<Scalars['String']>;
  passportNo?: Maybe<Scalars['String']>;
  passportStatus?: Maybe<Scalars['String']>;
  releaseNo?: Maybe<Scalars['String']>;
  releaseStatus?: Maybe<Scalars['String']>;
  packageNo?: Maybe<Scalars['String']>;
  packageStatus?: Maybe<Scalars['String']>;
};

export type GmInfoInput = {
  businessNo?: Maybe<Scalars['String']>;
  businessStatus?: Maybe<Scalars['String']>;
  passportNo?: Maybe<Scalars['String']>;
  passportStatus?: Maybe<Scalars['String']>;
  releaseNo?: Maybe<Scalars['String']>;
  releaseStatus?: Maybe<Scalars['String']>;
  packageNo?: Maybe<Scalars['String']>;
  packageStatus?: Maybe<Scalars['String']>;
};

/** 税则校验列表 */
export type Hgcheck = IDataInfo & {
  __typename?: 'hgcheck';
  id?: Maybe<Scalars['ID']>;
  checkNo?: Maybe<Scalars['String']>;
  noticeDate?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  entryId?: Maybe<Scalars['String']>;
  ownerName?: Maybe<Scalars['String']>;
  zhidanperson?: Maybe<Scalars['String']>;
  rcptDspsDtldDesc?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  channelName?: Maybe<Scalars['String']>;
  dutyType?: Maybe<Scalars['String']>;
  dutyPerson?: Maybe<Scalars['String']>;
  dutyClassify?: Maybe<Scalars['String']>;
  dutyStatusName?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  checkPlace?: Maybe<Scalars['String']>;
  checkTime?: Maybe<Scalars['Date']>;
  checkPerson?: Maybe<Scalars['String']>;
  checkResult?: Maybe<Scalars['String']>;
  process_instance_id?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type HgcheckInput = {
  id?: Maybe<Scalars['ID']>;
  checkNo?: Maybe<Scalars['String']>;
  noticeDate?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  entryId?: Maybe<Scalars['String']>;
  ownerName?: Maybe<Scalars['String']>;
  zhidanperson?: Maybe<Scalars['String']>;
  rcptDspsDtldDesc?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  channelName?: Maybe<Scalars['String']>;
  dutyType?: Maybe<Scalars['String']>;
  dutyPerson?: Maybe<Scalars['String']>;
  dutyClassify?: Maybe<Scalars['String']>;
  dutyStatusName?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  checkPlace?: Maybe<Scalars['String']>;
  checkTime?: Maybe<Scalars['Date']>;
  checkPerson?: Maybe<Scalars['String']>;
  checkResult?: Maybe<Scalars['String']>;
  process_instance_id?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 税则校验列表 */
export type Hgreject = IDataInfo & {
  __typename?: 'hgreject';
  id?: Maybe<Scalars['ID']>;
  checkNo?: Maybe<Scalars['String']>;
  noticeDate?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  entryId?: Maybe<Scalars['String']>;
  ownerName?: Maybe<Scalars['String']>;
  zhidanperson?: Maybe<Scalars['String']>;
  rcptDspsDtldDesc?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  channelName?: Maybe<Scalars['String']>;
  dutyType?: Maybe<Scalars['String']>;
  dutyPerson?: Maybe<Scalars['String']>;
  dutyClassify?: Maybe<Scalars['String']>;
  dutyStatusName?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  process_instance_id?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 税则校验列表 */
export type Hsregex = IDataInfo & {
  __typename?: 'hsregex';
  id?: Maybe<Scalars['ID']>;
  hsCode?: Maybe<Scalars['String']>;
  gName?: Maybe<Scalars['String']>;
  ciqName?: Maybe<Scalars['String']>;
  sugunit?: Maybe<Scalars['Date']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  declareElements?: Maybe<Scalars['JSON']>;
};

export type Kddpreorder = {
  __typename?: 'kddpreorder';
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  uploadfile?: Maybe<Scalars['String']>;
  orderOutNo?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
  parentOrder?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  chkSurety?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
  orderNo1?: Maybe<Scalars['String']>;
  sepSeqno?: Maybe<Scalars['String']>;
  departCode?: Maybe<Scalars['String']>;
  departName?: Maybe<Scalars['String']>;
  drawtotalAmount?: Maybe<Scalars['Float']>;
  ordertype?: Maybe<Scalars['String']>;
  zhidantype?: Maybe<Scalars['String']>;
  splitLeft?: Maybe<Scalars['Float']>;
  mianbaoFlag?: Maybe<Scalars['String']>;
  stockFlag?: Maybe<Scalars['String']>;
  goodsCustname?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  cusIEFlagName?: Maybe<Scalars['String']>;
  preEntryId?: Maybe<Scalars['String']>;
  entryId?: Maybe<Scalars['String']>;
  noOtherPack?: Maybe<Scalars['String']>;
  entQualifTypeCodeS?: Maybe<Scalars['String']>;
  entQualifTypeCodeSName?: Maybe<Scalars['String']>;
  ciqBillNo?: Maybe<Scalars['String']>;
  correlationDeclNo?: Maybe<Scalars['String']>;
  correlationReasonFlagName?: Maybe<Scalars['String']>;
  specDeclFlagInput?: Maybe<Scalars['String']>;
  appCert?: Maybe<Scalars['String']>;
  procmessage?: Maybe<Scalars['String']>;
  declDate?: Maybe<Scalars['String']>;
  decOtherPacksVo?: Maybe<Array<Maybe<DecOtherPacksVo>>>;
  decMergeListVo?: Maybe<Array<Maybe<DecMergeListVo>>>;
  cmbdecMergeListVo?: Maybe<Array<Maybe<DecMergeListVo>>>;
  preDecEntQualifListVo?: Maybe<Array<Maybe<PreDecEntQualifListVo>>>;
  memoInstock?: Maybe<Scalars['String']>;
  operNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  custNo?: Maybe<Scalars['String']>;
  cmbCutMode?: Maybe<Scalars['String']>;
  patchobj?: Maybe<Scalars['JSON']>;
  storeOutNo?: Maybe<Scalars['String']>;
  statusName?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  sfserial?: Maybe<Scalars['String']>;
  flighttype?: Maybe<Scalars['String']>;
  preDecRequCertList?: Maybe<Array<Maybe<PreDecRequCert>>>;
  spDecSeqNo?: Maybe<Scalars['String']>;
  cnsnTradeCode?: Maybe<Scalars['String']>;
  cnsnTradeScc?: Maybe<Scalars['String']>;
  coOwner?: Maybe<Scalars['String']>;
  ciqDeparPortCode?: Maybe<Scalars['String']>;
  paymentMark?: Maybe<Scalars['String']>;
  advDclcusTrnDspsMark?: Maybe<Scalars['String']>;
  checkFlow?: Maybe<Scalars['String']>;
  inRatio?: Maybe<Scalars['String']>;
  apprNo?: Maybe<Scalars['String']>;
  guaranteePermit?: Maybe<Scalars['String']>;
  dclrNo?: Maybe<Scalars['String']>;
  dclrCntway?: Maybe<Scalars['String']>;
  ediId?: Maybe<Scalars['String']>;
  entDeclNo?: Maybe<Scalars['String']>;
  entUuid?: Maybe<Scalars['String']>;
  consigneeAddr?: Maybe<Scalars['String']>;
  counterClaim?: Maybe<Scalars['String']>;
  inspPlaceCode?: Maybe<Scalars['String']>;
  declPersnCertNo?: Maybe<Scalars['String']>;
  declPersonName?: Maybe<Scalars['String']>;
  contactperson?: Maybe<Scalars['String']>;
  contTel?: Maybe<Scalars['String']>;
  attaCollectName?: Maybe<Scalars['String']>;
  totalValUs?: Maybe<Scalars['String']>;
  totalValCn?: Maybe<Scalars['String']>;
  contCancelFlag?: Maybe<Scalars['String']>;
  correlationReasonFlag?: Maybe<Scalars['String']>;
  splitBillLadNo?: Maybe<Scalars['String']>;
  clBlNo?: Maybe<Scalars['String']>;
  retNote?: Maybe<Scalars['String']>;
  copNo?: Maybe<Scalars['String']>;
  cusRemark?: Maybe<Scalars['String']>;
  billType?: Maybe<Scalars['String']>;
  ciqIsudMark?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  transPreNo?: Maybe<Scalars['String']>;
  ciqMessageId?: Maybe<Scalars['String']>;
  declareFlag?: Maybe<Scalars['String']>;
  ciqEtpsRegNo?: Maybe<Scalars['String']>;
  verFlags?: Maybe<Scalars['String']>;
  tgdNo?: Maybe<Scalars['String']>;
  declareName?: Maybe<Scalars['String']>;
  risk?: Maybe<Scalars['String']>;
  ediRemark?: Maybe<Scalars['String']>;
  ediRemark2?: Maybe<Scalars['String']>;
  priUseGoodsType?: Maybe<Scalars['String']>;
  twoStepFlag?: Maybe<Scalars['String']>;
  confirmPerson?: Maybe<Scalars['String']>;
  confirmDate?: Maybe<Scalars['Date']>;
  preDecContainerVo?: Maybe<Array<Maybe<SwPreDecContainerVo>>>;
  cusLicenseListVo?: Maybe<Array<Maybe<SwCusLicenseListVo>>>;
  preDecDocVo?: Maybe<Array<Maybe<SwPreDecDocVo>>>;
  specDeclFlag?: Maybe<Scalars['String']>;
  inspOrgCodeName?: Maybe<Scalars['String']>;
  consigneeCname?: Maybe<Scalars['String']>;
  otherRate?: Maybe<Scalars['String']>;
  insurRate?: Maybe<Scalars['String']>;
  ciqDecStatusName?: Maybe<Scalars['String']>;
  ciqTradeCountryCode?: Maybe<Scalars['String']>;
  declCodeName?: Maybe<Scalars['String']>;
  orgCodeName?: Maybe<Scalars['String']>;
  orgCode?: Maybe<Scalars['String']>;
  customMasterName?: Maybe<Scalars['String']>;
  preDecCiqMarkLob?: Maybe<Scalars['String']>;
  contractNo?: Maybe<Scalars['String']>;
  purpOrgCode?: Maybe<Scalars['String']>;
  noteS?: Maybe<Scalars['String']>;
  mbnoteS?: Maybe<Scalars['String']>;
  flightName?: Maybe<Scalars['String']>;
  heavyflightType?: Maybe<Scalars['String']>;
  showSepNo?: Maybe<Scalars['String']>;
  mProjectNo?: Maybe<Scalars['String']>;
  mbcontrNo?: Maybe<Scalars['String']>;
  finishdate?: Maybe<Scalars['Date']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  rcvgdTradeCode?: Maybe<Scalars['String']>;
  ownerCiqCode?: Maybe<Scalars['String']>;
  despPortCode?: Maybe<Scalars['String']>;
  ownerCode?: Maybe<Scalars['String']>;
  origBoxFlag?: Maybe<Scalars['String']>;
  preDecRoyaltyFee?: Maybe<Scalars['String']>;
  ownerName?: Maybe<Scalars['String']>;
  ciqDespCtryCode?: Maybe<Scalars['String']>;
  dataSrcModeCode?: Maybe<Scalars['String']>;
  applCopyQuan?: Maybe<Scalars['String']>;
  preDecUserList?: Maybe<Scalars['String']>;
  agentCode?: Maybe<Scalars['String']>;
  transMode?: Maybe<Scalars['String']>;
  cusTradeCountry?: Maybe<Scalars['String']>;
  insurMark?: Maybe<Scalars['String']>;
  vsaOrgCode?: Maybe<Scalars['String']>;
  ciqEntyPortCodeName?: Maybe<Scalars['String']>;
  dataSrcUnitCode?: Maybe<Scalars['String']>;
  isudMark?: Maybe<Scalars['String']>;
  feeMarkName?: Maybe<Scalars['String']>;
  tradeModeCode?: Maybe<Scalars['String']>;
  cusVoyageNo?: Maybe<Scalars['String']>;
  relativeId?: Maybe<Scalars['String']>;
  relmanNo?: Maybe<Scalars['String']>;
  bonNo?: Maybe<Scalars['String']>;
  customsField?: Maybe<Scalars['String']>;
  customsFieldName?: Maybe<Scalars['String']>;
  agentScc?: Maybe<Scalars['String']>;
  indbTime?: Maybe<Scalars['String']>;
  cusTradeCountryName?: Maybe<Scalars['String']>;
  extendField?: Maybe<Scalars['String']>;
  insurMarkName?: Maybe<Scalars['String']>;
  tradeModeCodeName?: Maybe<Scalars['String']>;
  otherMarkName?: Maybe<Scalars['String']>;
  origBoxFlagName?: Maybe<Scalars['String']>;
  cusTrafMode?: Maybe<Scalars['String']>;
  consigneeEname?: Maybe<Scalars['String']>;
  appCertCode?: Maybe<Scalars['String']>;
  insurCurr?: Maybe<Scalars['String']>;
  packNo?: Maybe<Scalars['String']>;
  licenseNo?: Maybe<Scalars['String']>;
  manualNo?: Maybe<Scalars['String']>;
  dDate?: Maybe<Scalars['String']>;
  consignorCode?: Maybe<Scalars['String']>;
  consignorEname?: Maybe<Scalars['String']>;
  ciqIEFlag?: Maybe<Scalars['String']>;
  contaCount?: Maybe<Scalars['String']>;
  declCode?: Maybe<Scalars['String']>;
  typistNo?: Maybe<Scalars['String']>;
  ciqTradeCountryCodeName?: Maybe<Scalars['String']>;
  feeCurr?: Maybe<Scalars['String']>;
  isCopPromise?: Maybe<Scalars['String']>;
  entryTypeName?: Maybe<Scalars['String']>;
  ciqVoyageNo?: Maybe<Scalars['String']>;
  purpOrgCodeName?: Maybe<Scalars['String']>;
  vsaOrgCodeName?: Maybe<Scalars['String']>;
  feeRate?: Maybe<Scalars['String']>;
  gdsArvlDate?: Maybe<Scalars['String']>;
  goodsPlace?: Maybe<Scalars['String']>;
  iEDate?: Maybe<Scalars['String']>;
  agentName?: Maybe<Scalars['String']>;
  otherCurr?: Maybe<Scalars['String']>;
  declRegNo?: Maybe<Scalars['String']>;
  supvModeCdde?: Maybe<Scalars['String']>;
  wrapType?: Maybe<Scalars['String']>;
  custmRegNo?: Maybe<Scalars['String']>;
  ownerScc?: Maybe<Scalars['String']>;
  tableFlag?: Maybe<Scalars['String']>;
  iEPort?: Maybe<Scalars['String']>;
  cusIEFlag?: Maybe<Scalars['String']>;
  netWt?: Maybe<Scalars['String']>;
  attaDocuCdstr?: Maybe<Scalars['String']>;
  speclInspQuraRe?: Maybe<Scalars['String']>;
  consignorAddr?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
  cusTradeNationCode?: Maybe<Scalars['String']>;
  ciqDespCtryCodeName?: Maybe<Scalars['String']>;
  despPortCodeName?: Maybe<Scalars['String']>;
  feeMark?: Maybe<Scalars['String']>;
  updateTime?: Maybe<Scalars['String']>;
  despDate?: Maybe<Scalars['String']>;
  preDecCiqDeclAttListVo?: Maybe<Scalars['String']>;
  dclTrnRelFlag?: Maybe<Scalars['String']>;
  inputEtpsCode?: Maybe<Scalars['String']>;
  inputErName?: Maybe<Scalars['String']>;
  otherCurrName?: Maybe<Scalars['String']>;
  createUser?: Maybe<Scalars['String']>;
  declRegName?: Maybe<Scalars['String']>;
  ciqEntyPortCode?: Maybe<Scalars['String']>;
  promiseItems?: Maybe<Scalars['String']>;
  dataSrcCode?: Maybe<Scalars['String']>;
  portStopCode?: Maybe<Scalars['String']>;
  markNo?: Maybe<Scalars['String']>;
  updateUser?: Maybe<Scalars['String']>;
  arrivPortCode?: Maybe<Scalars['String']>;
  applOri?: Maybe<Scalars['String']>;
  transModeName?: Maybe<Scalars['String']>;
  ciqDestCode?: Maybe<Scalars['String']>;
  consignorCname?: Maybe<Scalars['String']>;
  cusDecStatus?: Maybe<Scalars['String']>;
  distinatePort?: Maybe<Scalars['String']>;
  portStopCodeName?: Maybe<Scalars['String']>;
  cusTrafModeName?: Maybe<Scalars['String']>;
  deliveryOrder?: Maybe<Scalars['String']>;
  cusDecStatusName?: Maybe<Scalars['String']>;
  ciqTrafMode?: Maybe<Scalars['String']>;
  ciqTrafModeName?: Maybe<Scalars['String']>;
  cutModeName?: Maybe<Scalars['String']>;
  wrapTypeName?: Maybe<Scalars['String']>;
  cutMode?: Maybe<Scalars['String']>;
  otherMark?: Maybe<Scalars['String']>;
  contrNo?: Maybe<Scalars['String']>;
  chktstFlag?: Maybe<Scalars['String']>;
  trafName?: Maybe<Scalars['String']>;
  inspOrgCode?: Maybe<Scalars['String']>;
  grossWt?: Maybe<Scalars['String']>;
  arrivPortCodeName?: Maybe<Scalars['String']>;
  consigneeCode?: Maybe<Scalars['String']>;
  rcvgdTradeScc?: Maybe<Scalars['String']>;
  customMaster?: Maybe<Scalars['String']>;
  feeCurrName?: Maybe<Scalars['String']>;
  cmplDschrgDt?: Maybe<Scalars['String']>;
  iEPortName?: Maybe<Scalars['String']>;
  appCertName?: Maybe<Scalars['String']>;
  districtCode?: Maybe<Scalars['String']>;
  entryType?: Maybe<Scalars['String']>;
  inputEtpsName?: Maybe<Scalars['String']>;
  supvModeCddeName?: Maybe<Scalars['String']>;
  cusTradeNationCodeName?: Maybe<Scalars['String']>;
  specPassFlag?: Maybe<Scalars['String']>;
  insurCurrName?: Maybe<Scalars['String']>;
  distinatePortName?: Maybe<Scalars['String']>;
  ciqDecStatus?: Maybe<Scalars['String']>;
};

export type MainBillOrderData = {
  __typename?: 'mainBillOrderData';
  refNo?: Maybe<Scalars['String']>;
  showfeelist?: Maybe<Array<Maybe<Showfeelist>>>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  grossMargin?: Maybe<Scalars['Float']>;
  invoiceIncomeDetail?: Maybe<Array<Maybe<ServiceBillDetailInfo>>>;
  invoiceCostDetail?: Maybe<Array<Maybe<ServicePurchaseDetailInfo>>>;
  projectClass?: Maybe<Scalars['String']>;
  tmsApplyNo?: Maybe<Scalars['String']>;
  process_instance_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  curMessage?: Maybe<Scalars['String']>;
  stockStatus?: Maybe<Scalars['String']>;
  stockTime?: Maybe<Scalars['Date']>;
  billList?: Maybe<Array<Maybe<ServiceBillDetailInfo>>>;
  batchCustInfos?: Maybe<Array<Maybe<TmsCustTransApply>>>;
  statusCode?: Maybe<Scalars['Int']>;
  transStatus?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  wbsNo?: Maybe<Scalars['String']>;
  transType?: Maybe<Scalars['String']>;
  transTypeCode?: Maybe<Scalars['String']>;
  normalDistance?: Maybe<Scalars['Float']>;
  DanDistance?: Maybe<Scalars['Float']>;
  wbsInfo?: Maybe<Scalars['String']>;
  customsType?: Maybe<Scalars['String']>;
  pickupPlaces?: Maybe<Array<Maybe<TmsAddressInfo>>>;
  targetPlaces?: Maybe<Array<Maybe<TmsAddressInfo>>>;
  planTransDate?: Maybe<Scalars['Date']>;
  transGoodsDetails?: Maybe<Array<Maybe<TmsGoodsDetail>>>;
  goodsName?: Maybe<Scalars['String']>;
  packNo?: Maybe<Scalars['Int']>;
  insuredAmount?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  dangerousMark?: Maybe<Scalars['String']>;
  dangerousType?: Maybe<Scalars['String']>;
  highTempture?: Maybe<Scalars['Float']>;
  lowTempture?: Maybe<Scalars['Float']>;
  applyVehicles?: Maybe<Array<Maybe<TmsVehicleInfo>>>;
  extraServiceItems?: Maybe<Array<Maybe<TmsExtraServiceItem>>>;
  carNum?: Maybe<Scalars['Int']>;
  otherRequire?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  applyuserId?: Maybe<Scalars['String']>;
  applyuserName?: Maybe<Scalars['String']>;
  fuserId?: Maybe<Scalars['String']>;
  fuserName?: Maybe<Scalars['String']>;
  customOperaterId?: Maybe<Scalars['String']>;
  customOperaterName?: Maybe<Scalars['String']>;
  estimateFreight?: Maybe<Scalars['Float']>;
  processStatus?: Maybe<Scalars['String']>;
  attFiles?: Maybe<Array<Maybe<OrderFileInfo>>>;
  applyDepartName?: Maybe<Scalars['String']>;
  applyDepartId?: Maybe<Scalars['String']>;
  customsBusinessType?: Maybe<Scalars['String']>;
  businessInfos?: Maybe<Array<Maybe<GuanMaoBusinessTransit>>>;
  applyBussinessDetail?: Maybe<Array<Maybe<CustomApplyBussinessInfo>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  todoId?: Maybe<Scalars['String']>;
  billStatus?: Maybe<Scalars['String']>;
  batchNo?: Maybe<Scalars['String']>;
  parentTmsApplyNo?: Maybe<Scalars['String']>;
  batchInfo?: Maybe<Scalars['String']>;
};

export type OperateInfo = {
  __typename?: 'operateInfo';
  namefit?: Maybe<Scalars['Boolean']>;
  TopWinname?: Maybe<Scalars['String']>;
  Location?: Maybe<Scalars['String']>;
  Operate?: Maybe<Scalars['String']>;
  ifloop?: Maybe<Scalars['Boolean']>;
  InputField?: Maybe<Scalars['String']>;
  InputContent?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  ocrjudge?: Maybe<Scalars['String']>;
  dx?: Maybe<Scalars['Int']>;
  dy?: Maybe<Scalars['Int']>;
  proindex?: Maybe<Scalars['Int']>;
  roothookname?: Maybe<Scalars['String']>;
  tfinfo?: Maybe<Scalars['String']>;
};

export type OperateInfoInput = {
  namefit?: Maybe<Scalars['Boolean']>;
  TopWinname?: Maybe<Scalars['String']>;
  Location?: Maybe<Scalars['String']>;
  Operate?: Maybe<Scalars['String']>;
  ifloop?: Maybe<Scalars['Boolean']>;
  InputField?: Maybe<Scalars['String']>;
  InputContent?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  ocrjudge?: Maybe<Scalars['String']>;
  dx?: Maybe<Scalars['Int']>;
  dy?: Maybe<Scalars['Int']>;
  proindex?: Maybe<Scalars['Int']>;
  roothookname?: Maybe<Scalars['String']>;
  tfinfo?: Maybe<Scalars['String']>;
};

export type Paging = {
  sortfield?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type PassPortProd = {
  __typename?: 'passPortProd';
  id?: Maybe<Scalars['String']>;
  pid?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  passportNo?: Maybe<Scalars['String']>;
  chgTmsCnt?: Maybe<Scalars['String']>;
  gdsSeqno?: Maybe<Scalars['String']>;
  gdsNm?: Maybe<Scalars['String']>;
  dclQty?: Maybe<Scalars['String']>;
  dclUnitcd?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  gdsTypecd?: Maybe<Scalars['String']>;
  gdsSpcfModelDesc?: Maybe<Scalars['String']>;
  rmk?: Maybe<Scalars['String']>;
  associatedIntoGdsSeqno?: Maybe<Scalars['String']>;
  /** 这次用掉的数量 例子用掉是0 ,可能是用来审核通过但是没有过卡口,还没有核检的数量 */
  passportUsedQty?: Maybe<Scalars['String']>;
  actlPassQty?: Maybe<Scalars['String']>;
  remainQty?: Maybe<Scalars['String']>;
  associatedIntoId?: Maybe<Scalars['String']>;
};

export type PassPortProdInput = {
  id?: Maybe<Scalars['String']>;
  pid?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  passportNo?: Maybe<Scalars['String']>;
  chgTmsCnt?: Maybe<Scalars['String']>;
  gdsSeqno?: Maybe<Scalars['String']>;
  gdsNm?: Maybe<Scalars['String']>;
  dclQty?: Maybe<Scalars['String']>;
  dclUnitcd?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  gdsTypecd?: Maybe<Scalars['String']>;
  gdsSpcfModelDesc?: Maybe<Scalars['String']>;
  rmk?: Maybe<Scalars['String']>;
  associatedIntoGdsSeqno?: Maybe<Scalars['String']>;
  passportUsedQty?: Maybe<Scalars['String']>;
  actlPassQty?: Maybe<Scalars['String']>;
  remainQty?: Maybe<Scalars['String']>;
  associatedIntoId?: Maybe<Scalars['String']>;
};

export type PassPortStatus = {
  __typename?: 'passPortStatus';
  id?: Maybe<Scalars['String']>;
  pid?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['String']>;
  optStatus?: Maybe<Scalars['String']>;
  optUserId?: Maybe<Scalars['String']>;
  optUserName?: Maybe<Scalars['String']>;
  optTime?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export type PassPortStatusInput = {
  id?: Maybe<Scalars['String']>;
  pid?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['String']>;
  optStatus?: Maybe<Scalars['String']>;
  optUserId?: Maybe<Scalars['String']>;
  optUserName?: Maybe<Scalars['String']>;
  optTime?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export type PreDecCiqGoodsLimitInput = {
  gNo?: Maybe<Scalars['String']>;
  goodsLimitSeqNo?: Maybe<Scalars['String']>;
  licWrtofUnit?: Maybe<Scalars['String']>;
  licWrtofQty?: Maybe<Scalars['String']>;
  licWrtofUnitName?: Maybe<Scalars['String']>;
  licWrtofDetailNo?: Maybe<Scalars['String']>;
  licenceNo?: Maybe<Scalars['String']>;
  licTypeName?: Maybe<Scalars['String']>;
  licTypeCodeName?: Maybe<Scalars['String']>;
  licTypeCode?: Maybe<Scalars['String']>;
  preDecCiqGoodsLimitVinVo?: Maybe<Array<Maybe<PreDecCiqGoodsLimitVinVoInput>>>;
};

export type PreDecCiqGoodsLimitVinVoInput = {
  gNo?: Maybe<Scalars['String']>;
  invoiceNum?: Maybe<Scalars['String']>;
  goodsLimitSeqNo?: Maybe<Scalars['String']>;
  prodEnnm?: Maybe<Scalars['String']>;
  vinNo?: Maybe<Scalars['String']>;
  invoiceNo?: Maybe<Scalars['String']>;
  motorNo?: Maybe<Scalars['String']>;
  goodsLimitVinSeqNo?: Maybe<Scalars['String']>;
  prodCnnm?: Maybe<Scalars['String']>;
  pricePerUnit?: Maybe<Scalars['String']>;
  licenceNo?: Maybe<Scalars['String']>;
  qualityQgp?: Maybe<Scalars['String']>;
  billLadDate?: Maybe<Scalars['String']>;
  vinCode?: Maybe<Scalars['String']>;
  licTypeCodeName?: Maybe<Scalars['String']>;
  modelEn?: Maybe<Scalars['String']>;
  chassisNo?: Maybe<Scalars['String']>;
  licTypeCode?: Maybe<Scalars['String']>;
};

export type PreDecCiqXiangHui = {
  __typename?: 'preDecCiqXiangHui';
  preTradeAgreeCodeName?: Maybe<Scalars['String']>;
  preTradeAgreeCode?: Maybe<Scalars['String']>;
  oriCertType?: Maybe<Scalars['String']>;
  certOriCode?: Maybe<Scalars['String']>;
  certOriModItemNum?: Maybe<Scalars['String']>;
  rcepOrigPlaceDocCode?: Maybe<Scalars['String']>;
  rcepOrigPlaceDocCodeName?: Maybe<Scalars['String']>;
};

export type PreDecCiqXiangHuiInput = {
  preTradeAgreeCodeName?: Maybe<Scalars['String']>;
  preTradeAgreeCode?: Maybe<Scalars['String']>;
  oriCertType?: Maybe<Scalars['String']>;
  certOriCode?: Maybe<Scalars['String']>;
  certOriModItemNum?: Maybe<Scalars['String']>;
  rcepOrigPlaceDocCode?: Maybe<Scalars['String']>;
  rcepOrigPlaceDocCodeName?: Maybe<Scalars['String']>;
};

export type PreDecEntQualifListVo = {
  __typename?: 'preDecEntQualifListVo';
  entQualifTypeCode?: Maybe<Scalars['String']>;
  entQualifTypeCodeName?: Maybe<Scalars['String']>;
  entQualifSeqNo?: Maybe<Scalars['String']>;
  entQualifNo?: Maybe<Scalars['String']>;
};

export type PreDecEntQualifListVoInput = {
  entQualifTypeCode?: Maybe<Scalars['String']>;
  entQualifTypeCodeName?: Maybe<Scalars['String']>;
  entQualifSeqNo?: Maybe<Scalars['String']>;
  entQualifNo?: Maybe<Scalars['String']>;
};

export type PreDecRequCert = {
  __typename?: 'preDecRequCert';
  requCertSeqNo?: Maybe<Scalars['String']>;
  applOri?: Maybe<Scalars['String']>;
  createUser?: Maybe<Scalars['String']>;
  appCertName?: Maybe<Scalars['String']>;
  updateTime?: Maybe<Scalars['String']>;
  applCopyQuan?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  appCertCode?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  indbTime?: Maybe<Scalars['String']>;
  updateUser?: Maybe<Scalars['String']>;
};

export type PreDecRequCertInput = {
  requCertSeqNo?: Maybe<Scalars['String']>;
  applOri?: Maybe<Scalars['String']>;
  createUser?: Maybe<Scalars['String']>;
  appCertName?: Maybe<Scalars['String']>;
  updateTime?: Maybe<Scalars['String']>;
  applCopyQuan?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  appCertCode?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  indbTime?: Maybe<Scalars['String']>;
  updateUser?: Maybe<Scalars['String']>;
};

export type PrintFileInfoInput = {
  orderNo?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  printpath?: Maybe<Scalars['String']>;
  invNo?: Maybe<Scalars['String']>;
};

export type Producthistory = {
  __typename?: 'producthistory';
  id?: Maybe<Scalars['ID']>;
  /** SAP料号 */
  partNumberSAP?: Maybe<Scalars['String']>;
  exportDec?: Maybe<Scalars['String']>;
  prodid?: Maybe<Scalars['String']>;
  upmemo?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
  tradeNameCn?: Maybe<Scalars['String']>;
  materialPartNo?: Maybe<Scalars['String']>;
  nameCn?: Maybe<Scalars['String']>;
  hsCode?: Maybe<Scalars['String']>;
  tradeNameEn?: Maybe<Scalars['String']>;
  unit1Name?: Maybe<Scalars['String']>;
  gUnitCnName?: Maybe<Scalars['String']>;
  unit2Name?: Maybe<Scalars['String']>;
  produceCoNameCn?: Maybe<Scalars['String']>;
  produceCoNameEn?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  modelEn?: Maybe<Scalars['String']>;
  declareElementsStr?: Maybe<Scalars['String']>;
  declareElements?: Maybe<Array<Maybe<DeclareEle>>>;
  gUnitCn?: Maybe<Scalars['String']>;
  gUnitEn?: Maybe<Scalars['String']>;
  keyWord?: Maybe<Scalars['String']>;
  sourceType?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  unit1?: Maybe<Scalars['String']>;
  unit2?: Maybe<Scalars['String']>;
  controlmark?: Maybe<Scalars['String']>;
  tarifflag?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  appCompany?: Maybe<Scalars['String']>;
  heavyeqp?: Maybe<Scalars['String']>;
  subsystem?: Maybe<Scalars['String']>;
  mbdeclareElementsStr?: Maybe<Scalars['String']>;
  mbdeclareElements?: Maybe<Array<Maybe<DeclareEle>>>;
  ciqCode?: Maybe<Scalars['String']>;
  ciqName?: Maybe<Scalars['String']>;
  nameDesc?: Maybe<Scalars['String']>;
  netWeight?: Maybe<Scalars['Float']>;
  declPrice?: Maybe<Scalars['Float']>;
  productproto?: Maybe<Scalars['String']>;
  protoid?: Maybe<Scalars['String']>;
  operNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Productmanage = {
  __typename?: 'productmanage';
  id?: Maybe<Scalars['ID']>;
  appID?: Maybe<Scalars['String']>;
  tradeNameCn?: Maybe<Scalars['String']>;
  materialPartNo?: Maybe<Scalars['String']>;
  nameCn?: Maybe<Scalars['String']>;
  hsCode?: Maybe<Scalars['String']>;
  tradeNameEn?: Maybe<Scalars['String']>;
  produceCoNameCn?: Maybe<Scalars['String']>;
  produceCoNameEn?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  modelEn?: Maybe<Scalars['String']>;
  declareElementsStr?: Maybe<Scalars['String']>;
  declareElements?: Maybe<Array<Maybe<DeclareEle>>>;
  gUnitCn?: Maybe<Scalars['String']>;
  gUnitEn?: Maybe<Scalars['String']>;
  keyWord?: Maybe<Scalars['String']>;
  sourceType?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  unit1?: Maybe<Scalars['String']>;
  unit1Name?: Maybe<Scalars['String']>;
  gUnitCnName?: Maybe<Scalars['String']>;
  unit2Name?: Maybe<Scalars['String']>;
  /** SAP料号 */
  partNumberSAP?: Maybe<Scalars['String']>;
  exportDec?: Maybe<Scalars['String']>;
  unit2?: Maybe<Scalars['String']>;
  controlmark?: Maybe<Scalars['String']>;
  tarifflag?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  appCompany?: Maybe<Scalars['String']>;
  heavyeqp?: Maybe<Scalars['String']>;
  subsystem?: Maybe<Scalars['String']>;
  mbdeclareElementsStr?: Maybe<Scalars['String']>;
  mbdeclareElements?: Maybe<Array<Maybe<DeclareEle>>>;
  ciqCode?: Maybe<Scalars['String']>;
  ciqName?: Maybe<Scalars['String']>;
  nameDesc?: Maybe<Scalars['String']>;
  netWeight?: Maybe<Scalars['Float']>;
  declPrice?: Maybe<Scalars['Float']>;
  productproto?: Maybe<Scalars['String']>;
  protoid?: Maybe<Scalars['String']>;
  operNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  checkflag?: Maybe<Scalars['String']>;
  checkmemo?: Maybe<Scalars['String']>;
  singleusage?: Maybe<Array<Maybe<Singleusage>>>;
  realWeight?: Maybe<Scalars['String']>;
};

export type ProductmanageInput = {
  id?: Maybe<Scalars['ID']>;
  /** SAP料号 */
  partNumberSAP?: Maybe<Scalars['String']>;
  exportDec?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
  tradeNameCn?: Maybe<Scalars['String']>;
  materialPartNo?: Maybe<Scalars['String']>;
  nameCn?: Maybe<Scalars['String']>;
  hsCode?: Maybe<Scalars['String']>;
  tradeNameEn?: Maybe<Scalars['String']>;
  produceCoNameCn?: Maybe<Scalars['String']>;
  produceCoNameEn?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  modelEn?: Maybe<Scalars['String']>;
  declareElementsStr?: Maybe<Scalars['String']>;
  declareElements?: Maybe<Array<Maybe<DeclareEleInput>>>;
  gUnitCn?: Maybe<Scalars['String']>;
  gUnitEn?: Maybe<Scalars['String']>;
  keyWord?: Maybe<Scalars['String']>;
  sourceType?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  unit1?: Maybe<Scalars['String']>;
  unit2?: Maybe<Scalars['String']>;
  unit1Name?: Maybe<Scalars['String']>;
  gUnitCnName?: Maybe<Scalars['String']>;
  unit2Name?: Maybe<Scalars['String']>;
  controlmark?: Maybe<Scalars['String']>;
  tarifflag?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  appCompany?: Maybe<Scalars['String']>;
  heavyeqp?: Maybe<Scalars['String']>;
  subsystem?: Maybe<Scalars['String']>;
  mbdeclareElementsStr?: Maybe<Scalars['String']>;
  mbdeclareElements?: Maybe<Array<Maybe<DeclareEleInput>>>;
  ciqCode?: Maybe<Scalars['String']>;
  ciqName?: Maybe<Scalars['String']>;
  nameDesc?: Maybe<Scalars['String']>;
  netWeight?: Maybe<Scalars['Float']>;
  declPrice?: Maybe<Scalars['Float']>;
  productproto?: Maybe<Scalars['String']>;
  protoid?: Maybe<Scalars['String']>;
  operNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  upmemo?: Maybe<Scalars['String']>;
  checkflag?: Maybe<Scalars['String']>;
  checkmemo?: Maybe<Scalars['String']>;
  singleusage?: Maybe<Array<Maybe<SingleusageInput>>>;
  realWeight?: Maybe<Scalars['String']>;
};

export type QdBusinessTransitProduct = {
  __typename?: 'qdBusinessTransitProduct';
  accountType?: Maybe<Scalars['String']>;
  batchNo?: Maybe<Scalars['String']>;
  bizCode?: Maybe<Scalars['String']>;
  classifyType?: Maybe<Scalars['String']>;
  classifyTypeValue?: Maybe<Scalars['String']>;
  createdDatetime?: Maybe<Scalars['String']>;
  createdUser?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  declareElement?: Maybe<Scalars['String']>;
  detailSerialNo?: Maybe<Scalars['String']>;
  firstQuantity?: Maybe<Scalars['String']>;
  firstUnit?: Maybe<Scalars['String']>;
  goodsModel?: Maybe<Scalars['String']>;
  goodsName?: Maybe<Scalars['String']>;
  goodsOwner?: Maybe<Scalars['String']>;
  goodsOwnership?: Maybe<Scalars['String']>;
  goodsType?: Maybe<Scalars['String']>;
  goodsTypeValue?: Maybe<Scalars['String']>;
  hsCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  materialDocument?: Maybe<Scalars['String']>;
  netWt?: Maybe<Scalars['String']>;
  orgPieces?: Maybe<Scalars['String']>;
  orgUnit?: Maybe<Scalars['String']>;
  originCountry?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  partNo?: Maybe<Scalars['String']>;
  pieceNumber?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  secondQuantity?: Maybe<Scalars['String']>;
  secondUnit?: Maybe<Scalars['String']>;
  sortiesNo?: Maybe<Scalars['String']>;
  sourceDetailId?: Maybe<Scalars['String']>;
  sourcePartNo?: Maybe<Scalars['String']>;
  srialNo?: Maybe<Scalars['String']>;
  storePlace?: Maybe<Scalars['String']>;
  storeType?: Maybe<Scalars['String']>;
  storeTypeValue?: Maybe<Scalars['String']>;
  tradeTotal?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  updatedDatetime?: Maybe<Scalars['String']>;
  updatedUser?: Maybe<Scalars['String']>;
};

export type Showfeelist = {
  __typename?: 'showfeelist';
  billOrderNo?: Maybe<Scalars['String']>;
  billStatus?: Maybe<Scalars['String']>;
  chargeType?: Maybe<Scalars['String']>;
  chargeName?: Maybe<Scalars['String']>;
  chargeItemCode?: Maybe<Scalars['String']>;
  chargeUnit?: Maybe<Scalars['String']>;
  elementId?: Maybe<Scalars['String']>;
  elementPrice?: Maybe<Scalars['Float']>;
  elementQty?: Maybe<Scalars['Float']>;
  elementAmount?: Maybe<Scalars['Float']>;
  costId?: Maybe<Scalars['String']>;
  costPrice?: Maybe<Scalars['Float']>;
  costQty?: Maybe<Scalars['Float']>;
  costAmount?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['String']>;
  servicePrice?: Maybe<Scalars['Float']>;
  serviceQty?: Maybe<Scalars['Float']>;
  serviceAmount?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  grossMargin?: Maybe<Scalars['Float']>;
};

export type Singleusage = {
  __typename?: 'singleusage';
  machineinfo?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
};

export type SingleusageInput = {
  machineinfo?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
};

export type Singlewindowcustom = {
  __typename?: 'singlewindowcustom';
  cusRetSeqNo?: Maybe<Scalars['String']>;
  spDecSeqNo?: Maybe<Scalars['String']>;
  entryId?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  noticeDate?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  procReason?: Maybe<Scalars['String']>;
  customMaster?: Maybe<Scalars['String']>;
  agentName?: Maybe<Scalars['String']>;
  dclrNo?: Maybe<Scalars['String']>;
  tradeCo?: Maybe<Scalars['String']>;
  customsField?: Maybe<Scalars['String']>;
  bonNo?: Maybe<Scalars['String']>;
  iEDate?: Maybe<Scalars['String']>;
  packNo?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
  cusTrafMode?: Maybe<Scalars['String']>;
  trafName?: Maybe<Scalars['String']>;
  cusVoyageNo?: Maybe<Scalars['String']>;
  netWt?: Maybe<Scalars['String']>;
  grossWt?: Maybe<Scalars['String']>;
  dDate?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  rcptDspsStuCode?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  rcptDspsDtldDesc?: Maybe<Scalars['String']>;
  createUser?: Maybe<Scalars['String']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  indbTime?: Maybe<Scalars['String']>;
  updateUser?: Maybe<Scalars['String']>;
  updateTime?: Maybe<Scalars['String']>;
  isudMark?: Maybe<Scalars['String']>;
  rcptDspsStuCodeName?: Maybe<Scalars['String']>;
  channelName?: Maybe<Scalars['String']>;
  customMasterName?: Maybe<Scalars['String']>;
  cusRegNo?: Maybe<Scalars['String']>;
  cusRetSeqNos?: Maybe<Scalars['String']>;
};

/** 采购订单合同 */
export type TaxInfo = IDataInfo & {
  __typename?: 'taxInfo';
  id?: Maybe<Scalars['ID']>;
  entryId?: Maybe<Scalars['String']>;
  fileurl?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  taxHeadSeqNo?: Maybe<Scalars['String']>;
  taxvouNo?: Maybe<Scalars['String']>;
  traAmt?: Maybe<Scalars['Float']>;
  genDateStr?: Maybe<Scalars['String']>;
  taxType?: Maybe<Scalars['String']>;
  taxTypeName?: Maybe<Scalars['String']>;
  taxDetail?: Maybe<Scalars['JSON']>;
  timestamp?: Maybe<Scalars['Date']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 采购订单合同 */
export type TaxInfoInput = {
  id?: Maybe<Scalars['ID']>;
  entryId?: Maybe<Scalars['String']>;
  fileurl?: Maybe<Scalars['String']>;
  cusCiqNo?: Maybe<Scalars['String']>;
  taxHeadSeqNo?: Maybe<Scalars['String']>;
  taxvouNo?: Maybe<Scalars['String']>;
  traAmt?: Maybe<Scalars['Float']>;
  genDateStr?: Maybe<Scalars['String']>;
  taxType?: Maybe<Scalars['String']>;
  taxTypeName?: Maybe<Scalars['String']>;
  taxDetail?: Maybe<Scalars['JSON']>;
  timestamp?: Maybe<Scalars['Date']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

/** 改单申请列表 */
export type Validationrule = IDataInfo & {
  __typename?: 'validationrule';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  aviltype?: Maybe<Scalars['String']>;
  cust?: Maybe<Scalars['String']>;
  processtype?: Maybe<Scalars['String']>;
  sorobj?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['JSON']>;
  errorInfo?: Maybe<Scalars['String']>;
  rule?: Maybe<Scalars['JSON']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
};

export type ValidationruleInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  aviltype?: Maybe<Scalars['String']>;
  cust?: Maybe<Scalars['String']>;
  processtype?: Maybe<Scalars['String']>;
  sorobj?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['JSON']>;
  errorInfo?: Maybe<Scalars['String']>;
  rule?: Maybe<Scalars['JSON']>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
  appID?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
};

export type WareHouse = {
  __typename?: 'wareHouse';
  id?: Maybe<Scalars['ID']>;
  wareid?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  whRecPreentNo?: Maybe<Scalars['String']>;
  inExpWhNo?: Maybe<Scalars['String']>;
  inExpType?: Maybe<Scalars['String']>;
  bizopEtpsno?: Maybe<Scalars['String']>;
  bizopEtpsNm?: Maybe<Scalars['String']>;
  bizopEtpsSccd?: Maybe<Scalars['String']>;
  businessTypecd?: Maybe<Scalars['String']>;
  inExpTime?: Maybe<Scalars['String']>;
  mtpckEndprdTypecd?: Maybe<Scalars['String']>;
  supvModecd?: Maybe<Scalars['String']>;
  trspModecd?: Maybe<Scalars['String']>;
  impexpPortcd?: Maybe<Scalars['String']>;
  masterCuscd?: Maybe<Scalars['String']>;
  rltEntryNo?: Maybe<Scalars['String']>;
  rltWhRecNo?: Maybe<Scalars['String']>;
  whRecNo?: Maybe<Scalars['String']>;
  dclTime?: Maybe<Scalars['String']>;
  relDclcusFlag?: Maybe<Scalars['String']>;
  dclcusFlag?: Maybe<Scalars['String']>;
  dclcusTypecd?: Maybe<Scalars['String']>;
  preRecAccountTime?: Maybe<Scalars['String']>;
  recAccountTime?: Maybe<Scalars['String']>;
  invtIochkptStucd?: Maybe<Scalars['String']>;
  vrfdedMarkcd?: Maybe<Scalars['String']>;
  rmk?: Maybe<Scalars['String']>;
  entryStucd?: Maybe<Scalars['String']>;
  optStatus?: Maybe<Scalars['String']>;
  destinationNatcd?: Maybe<Scalars['String']>;
  tradeCountry?: Maybe<Scalars['String']>;
  tradingRegion?: Maybe<Scalars['String']>;
  districtCode?: Maybe<Scalars['String']>;
  grossWt?: Maybe<Scalars['String']>;
  transMode?: Maybe<Scalars['String']>;
  /**
   * 进出标志
   * I-进区，E-出区
   */
  ieTypecd?: Maybe<Scalars['String']>;
  grtNo?: Maybe<Scalars['String']>;
  grtNumber?: Maybe<Scalars['String']>;
  dclTypecd?: Maybe<Scalars['String']>;
  chgTmsCnt?: Maybe<Scalars['String']>;
  isDrag?: Maybe<Scalars['String']>;
  passportUsedTypecd?: Maybe<Scalars['String']>;
  inexpRltType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  enterNumber?: Maybe<Scalars['String']>;
  allocationMode?: Maybe<Scalars['String']>;
  specialBusiness?: Maybe<Scalars['String']>;
  releasePassDate?: Maybe<Scalars['String']>;
  whpassportUsedTypecd?: Maybe<Scalars['String']>;
  declareCustomeName?: Maybe<Scalars['String']>;
  declareTradeCode?: Maybe<Scalars['String']>;
  declareNumber?: Maybe<Scalars['String']>;
  declareStatus?: Maybe<Scalars['String']>;
  declareFlag?: Maybe<Scalars['String']>;
  declareEdiNo?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  warehouseReceiptDt?: Maybe<Array<Maybe<WarehouseReceiptDt>>>;
  warehousePort?: Maybe<Array<Maybe<WarehousePort>>>;
  warehouseStatus?: Maybe<Array<Maybe<WarehouseStatus>>>;
  warehouseInExp?: Maybe<Array<Maybe<WarehouseInExp>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type WareHouseInput = {
  id?: Maybe<Scalars['ID']>;
  wareid?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  whRecPreentNo?: Maybe<Scalars['String']>;
  inExpWhNo?: Maybe<Scalars['String']>;
  /**
   *    出入库类型
   *  1、一线进出
   * 2、先报后送
   * 3、分送集报
   * 4、区内流转
   * 5、区间流转
   * 7、二线进出
   * 8、调整类
   * 9、一单多车B
   */
  inExpType?: Maybe<Scalars['String']>;
  /** 经营企业编码 */
  bizopEtpsno?: Maybe<Scalars['String']>;
  bizopEtpsNm?: Maybe<Scalars['String']>;
  bizopEtpsSccd?: Maybe<Scalars['String']>;
  /**
   * 业务类型
   * 1自用设备、2简单加工、3保税加工、4保税仓储、5转口贸易、6跨境电商、7汽车平行进口、8融资租赁、9期货保税交割、10保税维修、11进口汽车保税存储、12保税研发、13委托加工、14大宗商品现货保税交易、15临时进出区、16设备出区、17模具外发、18外发加工、19境内维修、20境外维修、21飞机试飞、22保税展示
   */
  businessTypecd?: Maybe<Scalars['String']>;
  inExpTime?: Maybe<Scalars['String']>;
  /**
   * 料件、成品标志
   * I-料件,E-成品, S-设备,L-边角料,D-残次品,F-待维修件,G-已维修件,H-维修边角料,J-维修坏件
   */
  mtpckEndprdTypecd?: Maybe<Scalars['String']>;
  supvModecd?: Maybe<Scalars['String']>;
  trspModecd?: Maybe<Scalars['String']>;
  impexpPortcd?: Maybe<Scalars['String']>;
  masterCuscd?: Maybe<Scalars['String']>;
  rltEntryNo?: Maybe<Scalars['String']>;
  /**
   *     关联编号 区内流转提发货凭证和对应的进的只有一个对应,所以一个进可以有多个出,不能多个并到一个
   *     1.出入库类型为二线进出且业务类型为飞机试飞、外发加工时填写对应的业务申报编号
   * 2.出入库类型为区内流转且进出方向为出区时须关联对应出入库类型为区内流转且进出方向为入区的提发货编号
   * 3.设备子账户下出区提发货单证关联该子账户下入区提发货单证编号
   */
  rltWhRecNo?: Maybe<Scalars['String']>;
  whRecNo?: Maybe<Scalars['String']>;
  dclTime?: Maybe<Scalars['String']>;
  relDclcusFlag?: Maybe<Scalars['String']>;
  dclcusFlag?: Maybe<Scalars['String']>;
  dclcusTypecd?: Maybe<Scalars['String']>;
  preRecAccountTime?: Maybe<Scalars['String']>;
  recAccountTime?: Maybe<Scalars['String']>;
  invtIochkptStucd?: Maybe<Scalars['String']>;
  vrfdedMarkcd?: Maybe<Scalars['String']>;
  rmk?: Maybe<Scalars['String']>;
  entryStucd?: Maybe<Scalars['String']>;
  optStatus?: Maybe<Scalars['String']>;
  destinationNatcd?: Maybe<Scalars['String']>;
  tradeCountry?: Maybe<Scalars['String']>;
  tradingRegion?: Maybe<Scalars['String']>;
  districtCode?: Maybe<Scalars['String']>;
  grossWt?: Maybe<Scalars['String']>;
  transMode?: Maybe<Scalars['String']>;
  /**
   * 进出标志
   * I-进区，E-出区
   */
  ieTypecd?: Maybe<Scalars['String']>;
  grtNo?: Maybe<Scalars['String']>;
  grtNumber?: Maybe<Scalars['String']>;
  dclTypecd?: Maybe<Scalars['String']>;
  chgTmsCnt?: Maybe<Scalars['String']>;
  isDrag?: Maybe<Scalars['String']>;
  passportUsedTypecd?: Maybe<Scalars['String']>;
  inexpRltType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  /** 企业内部编号 */
  enterNumber?: Maybe<Scalars['String']>;
  allocationMode?: Maybe<Scalars['String']>;
  specialBusiness?: Maybe<Scalars['String']>;
  releasePassDate?: Maybe<Scalars['String']>;
  whpassportUsedTypecd?: Maybe<Scalars['String']>;
  declareCustomeName?: Maybe<Scalars['String']>;
  declareTradeCode?: Maybe<Scalars['String']>;
  declareNumber?: Maybe<Scalars['String']>;
  declareStatus?: Maybe<Scalars['String']>;
  declareFlag?: Maybe<Scalars['String']>;
  declareEdiNo?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  warehouseReceiptDt?: Maybe<Array<Maybe<WarehouseReceiptDtInput>>>;
  warehousePort?: Maybe<Array<Maybe<WarehousePortInput>>>;
  warehouseInExp?: Maybe<Array<Maybe<WarehouseInExpInput>>>;
  createTime?: Maybe<Scalars['Date']>;
  createPerson?: Maybe<Scalars['String']>;
  lastUpdateDate?: Maybe<Scalars['Date']>;
  lastUpdatePerson?: Maybe<Scalars['String']>;
};

export type WarehouseInExp = {
  __typename?: 'warehouseInExp';
  id?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  pid?: Maybe<Scalars['String']>;
  inExpWhNo?: Maybe<Scalars['String']>;
  inExpTime?: Maybe<Scalars['String']>;
  whRecPreentNo?: Maybe<Scalars['String']>;
  rmk?: Maybe<Scalars['String']>;
};

export type WarehouseInExpInput = {
  id?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  pid?: Maybe<Scalars['String']>;
  inExpWhNo?: Maybe<Scalars['String']>;
  inExpTime?: Maybe<Scalars['String']>;
  whRecPreentNo?: Maybe<Scalars['String']>;
  rmk?: Maybe<Scalars['String']>;
};

export type WarehousePort = {
  __typename?: 'warehousePort';
  id?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  pid?: Maybe<Scalars['String']>;
  portNo?: Maybe<Scalars['String']>;
  rtlTypecd?: Maybe<Scalars['String']>;
  modfMarkcd?: Maybe<Scalars['String']>;
};

export type WarehousePortInput = {
  id?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  pid?: Maybe<Scalars['String']>;
  portNo?: Maybe<Scalars['String']>;
  rtlTypecd?: Maybe<Scalars['String']>;
  modfMarkcd?: Maybe<Scalars['String']>;
};

export type WarehouseReceiptDt = {
  __typename?: 'warehouseReceiptDt';
  id?: Maybe<Scalars['String']>;
  pid?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  gdsSeqno?: Maybe<Scalars['String']>;
  portNo?: Maybe<Scalars['String']>;
  rltPortSeqno?: Maybe<Scalars['String']>;
  gdsMtno?: Maybe<Scalars['String']>;
  entryGdsSeqno?: Maybe<Scalars['String']>;
  gdecd?: Maybe<Scalars['String']>;
  gdsNm?: Maybe<Scalars['String']>;
  gdsSpcfModelDesc?: Maybe<Scalars['String']>;
  dclCurrcd?: Maybe<Scalars['String']>;
  dclQty?: Maybe<Scalars['String']>;
  dclUnitcd?: Maybe<Scalars['String']>;
  dclUprcAmt?: Maybe<Scalars['String']>;
  dclTotalAmt?: Maybe<Scalars['String']>;
  /** 原产国 */
  natcd?: Maybe<Scalars['String']>;
  netWt?: Maybe<Scalars['String']>;
  modfMarkcd?: Maybe<Scalars['String']>;
  leadId?: Maybe<Scalars['String']>;
};

export type WarehouseReceiptDtInput = {
  id?: Maybe<Scalars['String']>;
  pid?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  gdsSeqno?: Maybe<Scalars['String']>;
  portNo?: Maybe<Scalars['String']>;
  rltPortSeqno?: Maybe<Scalars['String']>;
  gdsMtno?: Maybe<Scalars['String']>;
  entryGdsSeqno?: Maybe<Scalars['String']>;
  gdecd?: Maybe<Scalars['String']>;
  gdsNm?: Maybe<Scalars['String']>;
  gdsSpcfModelDesc?: Maybe<Scalars['String']>;
  dclCurrcd?: Maybe<Scalars['String']>;
  dclQty?: Maybe<Scalars['String']>;
  dclUnitcd?: Maybe<Scalars['String']>;
  dclUprcAmt?: Maybe<Scalars['String']>;
  dclTotalAmt?: Maybe<Scalars['String']>;
  natcd?: Maybe<Scalars['String']>;
  netWt?: Maybe<Scalars['String']>;
  modfMarkcd?: Maybe<Scalars['String']>;
  leadId?: Maybe<Scalars['String']>;
};

export type WarehouseStatus = {
  __typename?: 'warehouseStatus';
  id?: Maybe<Scalars['String']>;
  pid?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['String']>;
  optStatus?: Maybe<Scalars['String']>;
  optUserName?: Maybe<Scalars['String']>;
  optTime?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

/** 过卡错误信息 */
export type YthexcepInfo = {
  __typename?: 'ythexcepInfo';
  /** 做空车单失败的原因 */
  reason?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  cargoInfo?: Maybe<Scalars['String']>;
  /** 是否进卡 */
  direction?: Maybe<Scalars['String']>;
};

export type YthexcepInfoInput = {
  /** 做空车单失败的原因 */
  reason?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  cargoInfo?: Maybe<Scalars['String']>;
  /** 是否进卡 */
  direction?: Maybe<Scalars['String']>;
};
