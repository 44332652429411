import {
  ViewChild,
  OnInit,
  OnDestroy,
  ElementRef,
  Input,
  OnChanges,
  Component,
  Type,
} from '@angular/core';

/**
 * table with selected delete
 */
@Component({
  template: '',
})
export abstract class CommonTableComponent<T> implements OnInit, OnChanges {
  @ViewChild('firstInput', { static: false })
  firstInput: ElementRef;

  @Input('displayList')
  displayList: T[];

  isAllDisplayDataChecked = false;
  isOperating = false;
  isIndeterminate = false;

  mapOfCheckedId: { [key: string]: boolean } = {};

  selData: T = null;
  keyName: string;

  afterSelRowChange() { }

  public selectedRow(data) {
    this.selData = data;
    this.afterSelRowChange();
  }
  constructor() {
    this.selData = this.newItem();
  }
  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    if (changes.hasOwnProperty('displayList')) {
      // this.displayList = changes.containerList.currentValue;
      if (this.displayList&&this.displayList.length > 0) {
        this.selData = this.displayList[0];
      }
      // console.log("containList", this.displayList);
    }
  }
  ngOnInit(): void {
    console.log(this.displayList.length);
    this.selData = this.newItem();
  }
  abstract newItem();
  abstract validateInput(data: T): boolean;
  //#region  check

  public refreshStatus(): void {
    this.isAllDisplayDataChecked = this.displayList.every(
      (item) => this.mapOfCheckedId[item[this.keyName]]
    );
    this.isIndeterminate =
      this.displayList.some(
        (item) => this.mapOfCheckedId[item[this.keyName]]
      ) && !this.isAllDisplayDataChecked;
  }

  public checkAll(value: boolean): void {
    this.displayList.forEach(
      (item) => (this.mapOfCheckedId[item[this.keyName]] = value)
    );
    this.refreshStatus();
  }

  //#endregion

  public deleteSelected() {
    // console.log("remove", this.mapOfCheckedId, this.keyName);

    // this.displayList = this.displayList.filter(
    //   ele => !!!this.mapOfCheckedId[ele[this.keyName]]
    // );
    for (const key in this.mapOfCheckedId) {
      if (this.mapOfCheckedId.hasOwnProperty(key) && this.mapOfCheckedId[key]) {
        const element = this.mapOfCheckedId[key];
        const index = this.displayList.findIndex(
          (ele) => ele[this.keyName] == key
        );
        this.displayList.splice(index, 1);
      }
    }

    this.mapOfCheckedId = {};
    this.refreshStatus();
  }
  public rowInputOk(event) {
    if (!this.validateInput(this.selData) || (this.keyName == 'gNo' && this['templateEdit'])) {
      return;
    }
    console.log('rowInputOk', this.selData);
    if (this.selData && this.selData[this.keyName]) {
      if(!!!this.displayList){
        this.displayList=[]
      }
      if (
        !this.displayList.some(
          (ele) => ele[this.keyName] == this.selData[this.keyName]
        )
      ) {
        this.displayList.push(this.selData);
      }
      this.addNew();
    }
  }
  public addNew() {
    this.selData = this.newItem();

    this.firstInput.nativeElement.focus();
  }
}
