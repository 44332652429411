
// export interface IWeichatUserModel {


//     'openID': string; // id
//     'appID': string; // 公众号ID
//     'QRID': string; // 当前登录SessionQRID
//     'kuai00SocketID': string; // 当前socketID
//     'electronSign': string; // 快点查客户端ID
//     'activeDate'?: Date;
//     'createDate'?: Date;
//     'nickName'?: string;
//     'headImgUrl'?: string;
//     'companyName'?: string;
//     'name'?: string;
//     'UserName'?: string;
//     'allUserFlag': boolean;

// }


export interface IRectangle {
  topX: number;
  topY: number;
  bottomX: number;
  bottomY: number;
}

export interface IRectConcept extends IRectangle{
  value:string

}

export  interface IInvoiceDrawInfo {
  /**
   * invoice 唯一序号
   */
  gNo: number;
  /**
   * 页数
   */
  pageIndex: number;
  /**
   * 当前页行数
   */
  lineIndex: number;

  rectangle: IRectangle;
  lineBackOpacity:number,
  concepts: IRectConcept[],
  decmerge:any,
  skuloc:any,
  declloc:any,
  odd:boolean
}



  export interface paraKeyValue {
    id: string;
    name: string;
    values?:any
  }

export interface ISocketMessage {
    'messageType': string;
    'messageBody': any;
}

export interface IMenuRouterDef {
    'iconType': string;
    'route'?: string;
    'label': string;
    'memo': string;
    'submenuFlag'?: boolean,
    'submenus'?: Array<any>
}



export interface IApiResponse {


    status: string;

    statusCode: number;
    message?: string

    statusMessage?: string;

    errorMessage?: string;

    data: string;
};

export interface ITemplateConfig {
    overwriteType: boolean;
    fieldCaption: string;
    required: boolean;
  }
  
 export  interface IFieldTemplateConfig {
    [key: string]: ITemplateConfig;
  }


  export interface IEventProductTabOpen {
    sku: string
    newflag: boolean,
    productid:string,
    supplierName:string

}

export interface IEventPreOrderChanged{
    orderNo:string,
    orderOutNo:string,
    id:string
}