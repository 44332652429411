// import { Injectable } from '@angular/core';
import { ComponentRef } from '@angular/core';
import {
  RouteReuseStrategy,
  DetachedRouteHandle,
  ActivatedRouteSnapshot,
} from '@angular/router';
// import { ! } from "util";
import { Subject } from 'rxjs';

// @Injectable({
//   providedIn: 'root'
// })

export interface IRouteCacheInfo {
  title: string;
  routeKey: string;
  handler: DetachedRouteHandle;
  routerSnapshot: ActivatedRouteSnapshot;
  data: any;
  closeflag: boolean;
}

export class RouterReuseService implements RouteReuseStrategy {
  public static handlers: { [key: string]: any } = {};
  public static cacheRouteSet: Array<IRouteCacheInfo> = [];

  public static cacheChanged = new Subject();
  noReuse = ['home'];

  judgeSameRouter(
    routeA: ActivatedRouteSnapshot,
    routeB: ActivatedRouteSnapshot
  ): boolean {
    //return Object.is(routeA,routeB);

    return (
      this.geturl(routeA) == this.geturl(routeB) &&
      JSON.stringify(routeA.queryParams) == JSON.stringify(routeB.queryParams)
    );
  }

  //   deactivateAllHandles() {
  //     // tslint:disable-next-line forin
  //     for (const key in this.handlers) {
  //         this.deactivateOutlet(this.handlers[key])
  //     }
  //     this.handlers = {}
  // }
  private deactivateOutlet(handle: DetachedRouteHandle): void {
    console.debug('deactvia reuse', handle);
    if (handle) {
      const componentRef: ComponentRef<any> = handle['componentRef'];
      if (componentRef) {
        componentRef.destroy();
        console.debug('deactvia destroy', handle);
      }
    } else {
      console.debug('empty detached');
    }
  }

  geturl(route: ActivatedRouteSnapshot): string {
    // const parastr=JSON.stringify(route.queryParams);
    //  console.log(parastr);
    //return `${route['_urlSegment']['segments'].join('/')}`;
    const url =
      route['_routerState']['url'] ||
      route['_urlSegment']['segments'].join('/');
    return url;
  }
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    console.log(
      'shouldDetach',
      this.geturl(route),
      route.routeConfig ? route.routeConfig.path : 'null'
    );

    if (this.noReuse.some((ele) => ele == this.geturl(route))) {
      return false;
    }
    // console.log(
    //   'shouldDetach',
    //   this.geturl(route),
    //   route.routeConfig ? route.routeConfig.path : 'null'
    // );

    const zz = RouterReuseService.cacheRouteSet.find(
      (ele) => ele.closeflag && this.judgeSameRouter(ele.routerSnapshot, route)
    ); //ele.routeKey==this.geturl(route));
    if (zz) {
      const closeindex = RouterReuseService.cacheRouteSet.indexOf(zz);
      console.debug('shouldDetach zz', zz);
      // RouterReuseService11.cacheRouteSet[closeindex].closeflag=true;
      this.deactivateOutlet(zz.handler);

      RouterReuseService.cacheRouteSet.splice(closeindex, 1);

      return true;

    } else {
      console.debug('shouldDetach zz', this.geturl(route),true);

      return true;
    }
  }
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    console.log(
      'store',
      this.geturl(route),
      route.routeConfig ? route.routeConfig.path : 'null',
      handle
    );

    //     if(handle){
    // RouterReuseService.handlers[route.routeConfig.path]=handle;
    //     }
    const existsRoute = RouterReuseService.cacheRouteSet.find((ele) =>
      this.judgeSameRouter(ele.routerSnapshot, route)
    );
    if (existsRoute && !!handle) {
      console.debug('exsit store handle',handle)
      existsRoute.handler = handle;
    } else {
      console.debug('store not ', handle);
      if (!existsRoute && !!handle) {
        console.log('handle ', handle);
        this.deactivateOutlet(handle);

        if (
          !existsRoute &&
          this.geturl(route) &&
          this.geturl(route) != 'login'
        ) {
          // RouterReuseService11.cacheChanged.next(
          //   RouterReuseService11.cacheRouteSet.push({
          //     routeKey: this.geturl(route),
          //     routerSnapshot: route,
          //     handler: handle,
          //     title:
          //       route.fragment ||
          //       route.data['title'] ||
          //       route.data['breadcrumb'],
          //     data: route.data,
          //     closeflag: false,
          //   })
          // );
        }

        // RouterReuseService.cacheChanged.next( RouterReuseService11.cacheRouteSet.push({
        //   routeKey: this.geturl(route),
        //   routerSnapshot: route,
        //   handler: handle,
        //   title:route.fragment|| route.data["breadcrumb"],

        //   data: route.data,
        //   closeflag:false,
        // }));
      }
    }
  }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    //   return !!RouterReuseService.handlers[route.routeConfig.path];
    //if(route.routeConfig.path)

    console.log(
      'shouldAttach',
      this.geturl(route),
      route.routeConfig ? route.routeConfig.path : 'null',
      !!RouterReuseService.cacheRouteSet.some(
        (ele) =>
          this.judgeSameRouter(ele.routerSnapshot, route) && !!ele.handler
      ) &&
        route.routeConfig &&
        !!route.routeConfig.path
    );
    // return false;

    return (
      !!RouterReuseService.cacheRouteSet.some(
        (ele) =>
          this.judgeSameRouter(ele.routerSnapshot, route) && !!ele.handler
      ) &&
      route.routeConfig &&
      !!route.routeConfig.path
    );
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    console.log(
      'retrieve',
      this.geturl(route),
      route.routeConfig ? route.routeConfig.path : 'null'
    );
    // if(route.routeConfig.loadChildren)
    // {
    //   return null;
    // }

    // if(RouterReuseService.handlers[route.routeConfig.path]){
    //   return RouterReuseService.handlers[route.routeConfig.path];
    // }
    // return null;
    const existsRoute = RouterReuseService.cacheRouteSet.find((ele) =>
      this.judgeSameRouter(ele.routerSnapshot, route)
    );
    if (existsRoute && !!existsRoute.handler) {
      RouterReuseService.cacheChanged.next(
        RouterReuseService.cacheRouteSet.indexOf(existsRoute)
      );
      console.log('handler:', existsRoute, route);
      return existsRoute.handler;
    } else {
      if (
        !existsRoute &&
        this.geturl(route) &&
        this.geturl(route) != '/login'
      ) {
        // RouterReuseService11.cacheChanged.next(
        //   RouterReuseService11.cacheRouteSet.push({
        //     routeKey: this.geturl(route),
        //     routerSnapshot: route,
        //     handler: null,
        //     title:
        //       route.fragment || route.data['title'] || route.data['breadcrumb'],
        //     data: route.data,
        //     closeflag: false,
        //   })
        // );
        // console.log(`after push routeConfig:${route.routeConfig.path}`,RouterReuseService11.cacheRouteSet);
      } else {
        console.log(`not push`);
      }

      return null;
    }
  }
  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    console.log(
      'shouldReuseRoute',
      this.geturl(future),
      this.geturl(curr),
      future.routeConfig,
      curr.routeConfig
    );

    const reuseFlag = this.judgeSameRouter(future, curr); //(this.geturl(future) === this.geturl(curr))&&(JSON.stringify(future.params)===JSON.stringify(curr.params))
    if (!reuseFlag) {
      this.addToTab(future);
    }
    return reuseFlag;
  }

  constructor() {}

  private addToTab(route: ActivatedRouteSnapshot) {
    console.debug('add to tab', this.geturl(route), route);

    const existsRoute = RouterReuseService.cacheRouteSet.find((ele) =>
      this.judgeSameRouter(ele.routerSnapshot, route)
    );
    if (!!!existsRoute && !this.geturl(route).startsWith('/login'))
      RouterReuseService.cacheChanged.next(
        RouterReuseService.cacheRouteSet.push({
          routeKey: this.geturl(route),
          routerSnapshot: route,
          handler: null,
          title:
            route.fragment || route.data['title'] || route.data['breadcrumb'],
          data: route.data,
          closeflag: false,
        })
      );
  }
}
