import { DecMergeListVo } from '@ternsorinfo/data';


export const newDecMergeListVo= function(gNo:number=1): DecMergeListVo {
    return {
      gNo: gNo,
      contrItem: "",
      codeTs: "",
      ciqName: "",
      ciqCode: "",
      gName: "",
      gModel: "",
      gQty: "",
      gUnitName: "",
      gUnit: "",
      declPrice: "",
      declTotal: "",
      tradeCurrName: "",
      tradeCurr: "",
      qty1: "",
      unit1Name: "",
      unit1: "",
      exgVersion: "",
      exgNo: "",
      destinationCountryName: "",
      destinationCountry: "",
      qty2: "",
      unit2Name: "",
      unit2: "",
      cusOriginCountryName: "",
      cusOriginCountry: "",
      origPlaceCodeName: "",
      origPlaceCode: "",
      districtCodeName: "",
      districtCode: "",
      ciqDestCodeName: "",
      ciqDestCode: "",
      dutyModeName: "",
      dutyMode: "",
      goodsAttrName: "",
      sku: "",
      realdeclTotal: "",
      dnno:"",
      dnpage:"",
      pageinvoice:"",
      contrNo: "",
      mbgModel: "",
      netWeight: "",
      qty: "",
      subsystem: "",
      nameDesc: "",
      eqpname: "",
      firstlevel: "",
      secondlevel: "",
      decMergeOrder: "",
      combinebf: "",
      ControlMark: "",
      TarifFlag: "",
      productid: "",
      productupdateDate: new Date(),
      pageindex: -1,
      pageurl: "",
      instockSku: null,
      confidence: "",
      drawamount: 0,
      sourceProdInfo: [],
      targetProdInfo: [],
      purpose: "",
      purposeName: "",
      goodsAttr: "",
      goodsSpec: "",
      freeFlag: "免表",
      loctioninfo: [],

      prodQgp: "",
      stuff: "",
      prodValidDt: "",
      engManEntCnm: "",
      goodsBrand: "",
      goodsModel: "",
      produceDate: "",
      prodBatchNo: "",

      noDangFlagName: "",
      noDangFlag: "",
      unCode: "",
      dangName: "",
      packTypeName: "",
      packType: "",
      packSpec: "",
      preDecCiqGoodsLimit:[],
      extendjson:{elecont:"",volume:""},
      namememo:"",
      modflag: 0,
      classifyInfo: {
        codeTs: "",
        unit1: "",
        unit1Name: "",
        unit2: "",
        unit2Name: "",
        unitFlag: "",
        low_rate: "",
        high_rate: "",
        out_rate: "",
        reg_rate: "", //消费税率
        reg_amount: 0, // 消费税金额
        tax_rate: "", // #增值税率
        tax_amount: 0,
        back_rate: "", // #出口退税率
        low_t_rate: "", // #- #暂定进口税率
        low_out_rate: "", // # -#暂定出口税率
        ret_rate: "", // retaliate 报复税率
        cif_total_amount: 0, // cif 总价 人民币,
        extrange_rate_cny: 0, //  #人民币汇率
        fob_trans_fee: 0, // fob split trans fee amount
        in_amount: 0,
        out_amount: 0,
        ret_amount: 0,
        total_tax_amount: 0,
        cusOriginCountry: ""
      }
    };
  }