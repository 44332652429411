import { AppUserInfo } from "./data";

export  interface IContext{
    dataSources:any,
    auth:{
      appID:string,
      QRID:string,
      production:boolean
      sessionUserInfo:AppUserInfo
    }
  }


 
export enum EnumTaskStatus {
  INIT = '初始',
  READY = '准备',
  RUNNING = '调用中',
  SUCCESSED = '调用成功',
  ERROR = '调用失败',
}

/**
 * 菜单 leaf 定义
 */
 export interface IMenuItem {
  iconType?: string;
  label?: string;
  memo?: string;
  route: string;
  fragment?: string;
}
/**
 * 菜单定义 前台后台共用 判断角色菜单
 *
 */
export interface IMenu {
  submenus: IMenuItem[];
  iconType?: string;
  label: string;
  memo?: string;

  submenuFlag: boolean;
}
