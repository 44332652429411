const paraconf = {
  iEPort: {
    optioninfo: { label: "name", value: "code" },
    paratype: "customs",
    paraname: "关区",
    seloutput: {},
    bindkey: "iEPort",
    bindname: "iEPortName"
  },
  cutMode: {
    optioninfo: { label: "name", value: "code" },
    paratype: "levytype",
    paraname: "监管方式",
    seloutput: {},
    bindkey: "cutMode",
    bindname: "cutModeName"
  },
  dutyMode: {
    optioninfo: { label: "name", value: "code" },
    paratype: "levymode",
    paraname: "请输入",
    seloutput: {},
    bindkey: "dutyMode",
    bindname: "dutyModeName"
  },
  transMode: {
    optioninfo: { label: "name", value: "code" },
    paratype: "transac",
    paraname: "贸易方式",
    seloutput: {},
    bindkey: "transMode",
    bindname: "transModeName"
  },

  ciqEntyPortCodeName: {
    optioninfo: { label: "name", value: "code" },
    paratype: "depport",
    paraname: "请输入",
    seloutput: {},
    bindkey: "ciqEntyPortCode",
    bindname: "ciqEntyPortCodeName"
  },
  cusTradeNationCode: {
    optioninfo: { label: "name", value: "code" },
    paratype: "country",
    paraname: "请输入",
    seloutput: {},
    bindkey: "cusTradeNationCode",
    bindname: "cusTradeNationCodeName"
  },

  despPortCode: {
    optioninfo: { label: "name", value: "code" },
    paratype: "port",
    paraname: "请输入",
    seloutput: {},
    bindkey: "despPortCode",
    bindname: "despPortCodeName"
  },
  cusOriginCountry: {
    optioninfo: { label: "name", value: "code" },
    paratype: "country",
    paraname: "原产国",
    seloutput: {},
    bindkey: "cusOriginCountry",
    bindname: "cusOriginCountryName"
  },
  origPlaceCode: {
    optioninfo: { label: "name", value: "code" },
    paratype: "worldfirstdistrict",
    paraname: "原产地区",
    seloutput: {},
    bindkey: "origPlaceCode",
    bindname: "origPlaceCodeName"
  },
  destinationCountry: {
    optioninfo: { label: "name", value: "code" },
    paratype: "country",
    paraname: "请输入",
    seloutput: {},
    bindkey: "destinationCountry",
    bindname: "destinationCountryName"
  },

  cusTradeCountry: {
    optioninfo: { label: "name", value: "code" },
    paratype: "country",
    paraname: "请输入",
    seloutput: {},
    bindkey: "cusTradeCountry",
    bindname: "cusTradeCountryName"
  },

  distinatePort: {
    optioninfo: { label: "name", value: "code" },
    paratype: "port",
    paraname: "请输入",
    seloutput: {},
    bindkey: "distinatePort",
    bindname: "distinatePortName"
  },

  tradeCurr: {
    optioninfo: { label: "name", value: "code" },
    paratype: "curr",
    paraname: "币制",
    seloutput: {},
    bindkey: "tradeCurr",
    bindname: "tradeCurrName"
  },
  sku: {
    optioninfo: { label: "materialPartNo", value: "materialPartNo" },
    paratype: "sku",
    paraname: "请输入",
    seloutput: {},
    bindkey: "sku"
  },
  tradeMode: {
    optioninfo: { label: "name", value: "code" },
    paratype: "trade",
    paraname: "请输入",
    seloutput: {},
    bindkey: "tradeModeCode",
    bindname: "tradeModeCodeName"
  },
  cusTrafMode: {
    optioninfo: { label: "name", value: "code" },
    paratype: "transf",
    paraname: "请输入",
    seloutput: {},
    bindkey: "cusTrafMode",
    bindname: "cusTrafModeName"
  },

  customMaster: {
    optioninfo: { label: "name", value: "code" },
    paratype: "customs",
    paraname: "请输入",
    seloutput: {},
    bindkey: "customMaster",
    bindname: "customMasterName"
  },
  hsCode: {
    optioninfo: { label: "hscode", value: "hscode" },
    paratype: "hscode",
    paraname: "税号",
    seloutput: { tempjson: "" },
    bindkey: "hsCode"
  },
  gUnitCn: {
    optioninfo: { label: "name", value: "code" },
    paratype: "unit",
    paraname: "单位",
    seloutput: {},
    bindkey: "gUnitCn",
    bindname: "gUnitCnName"
  },
  gUnit: {
    optioninfo: { label: "name", value: "code" },
    paratype: "unit",
    paraname: "单位",
    seloutput: {},
    bindkey: "gUnit",
    bindname: "gUnitName"
  },
  unit1: {
    optioninfo: { label: "name", value: "code" },
    paratype: "unit",
    paraname: "单位",
    seloutput: {},
    bindkey: "unit1",
    readonly: true,
    bindname: "unit1Name"
  },
  unit2: {
    optioninfo: { label: "name", value: "code" },
    paratype: "unit",
    paraname: "单位",
    seloutput: {},
    bindkey: "unit2",
    readonly: true,
    bindname: "unit2Name"
  },
  heavyeqp: {
    optioninfo: { label: "name", value: "code" },
    paratype: "heavyeqp",
    paraname: "重装目录",
    seloutput: {},
    bindkey: "heavyeqp"
  },
  customMasterName: {
    optioninfo: { label: "name", value: "code" },
    paratype: "customs",
    seloutput: {},
    bindkey: "customMasterName",
    bindname: "customMasterName"
  },
  tradeModeCode: {
    optioninfo: { label: "name", value: "code" },
    paratype: "levymode",
    seloutput: {},
    bindkey: "tradeModeCode",
    bindname: "tradeModeCodeName"
  },
  feeMark: {
    optioninfo: { label: "name", value: "code" },
    paratype: "fee_mark",
    seloutput: {},
    bindkey: "feeMark",
    bindname: "feeMarkName"
  },
  insurMark: {
    optioninfo: { label: "name", value: "code" },
    paratype: "insur_mark",
    seloutput: {},
    bindkey: "insurMark",
    bindname: "insurMarkName"
  },
  otherMark: {
    optioninfo: { label: "name", value: "code" },
    paratype: "other_mark",
    seloutput: {},
    bindkey: "otherMark",
    bindname: "otherMarkkName"
  },
  supvModeCdde: {
    optioninfo: { label: "name", value: "code" },
    paratype: "transac",
    seloutput: {},
    bindkey: "supvModeCdde",
    bindname: "supvModeCddeName"
  },
  destinationCountryName: {
    optioninfo: { label: "name", value: "code" },
    paratype: "country",
    seloutput: {},
    bindkey: "destinationCountry",
    bindname: "destinationCountryName"
  },

  ciqEntyPortCode: {
    optioninfo: { label: "name", value: "code" },
    paratype: "depport",
    seloutput: {},
    bindkey: "ciqEntyPortCode",
    bindname: "ciqEntyPortCodeName"
  },

  cusTrafModeName: {
    optioninfo: { label: "name", value: "code" },
    paratype: "customs",
    seloutput: {},
    bindkey: "cusTrafModeName",
    bindname: "cusTrafModeName"
  },
  wrapType: {
    optioninfo: { label: "name", value: "code" },
    paratype: "wrap_type",
    seloutput: {},
    bindkey: "wrapType",
    bindname: "wrapTypeName"
  },
  orgCode: {
    optioninfo: { label: "name", value: "code" },
    paratype: "ciqorg",
    seloutput: {},
    bindkey: "orgCode",
    bindname: "orgCodeName"
  },
  vsaOrgCode: {
    optioninfo: { label: "name", value: "code" },
    paratype: "ciqorg",
    seloutput: {},
    bindkey: "vsaOrgCode",
    bindname: "vsaOrgCodeName"
  },
  inspOrgCode: {
    optioninfo: { label: "name", value: "code" },
    paratype: "ciqorg",
    seloutput: {},
    bindkey: "inspOrgCode",
    bindname: "inspOrgCodeName"
  },
  purpOrgCode: {
    optioninfo: { label: "name", value: "code" },
    paratype: "ciqorg",
    seloutput: {},
    bindkey: "purpOrgCode",
    bindname: "purpOrgCodeName"
  },
  origBoxFlag: {
    optioninfo: { label: "name", value: "code" },
    paratype: "ciqorg",
    seloutput: {},
    bindkey: "origBoxFlag",
    bindname: "origBoxFlagName"
  },

  districtCode: {
    optioninfo: { label: "name", value: "code" },
    paratype: "district",
    seloutput: {},
    bindkey: "districtCode",
    bindname: "districtCodeName"
  },
  ciqDestCode: {
    optioninfo: { label: "name", value: "code" },
    paratype: "chinaregionalism",
    seloutput: {},
    bindkey: "ciqDestCode",
    bindname: "ciqDestCodeName"
  },
  purpose: {
    optioninfo: { label: "name", value: "code" },
    paratype: "purpose",
    seloutput: {},
    bindkey: "purpose",
    bindname: "purposeName"
  },
  goodsAttr: {
    optioninfo: { label: "name", value: "code" },
    paratype: "goodsattr",
    seloutput: {},
    bindkey: "goodsAttr",
    bindname: "goodsAttrName"
  },
  entQualifTypeCode: {
    optioninfo: { label: "name", value: "code" },
    paratype: "ProductPermissionType",
    seloutput: {},
    bindkey: "entQualifTypeCode",
    bindname: "entQualifTypeCodeName"
  }
};

export function getParaOpitons(paraType: string): ICustomParaDef[] {
  return <ICustomParaDef[]>JSON.parse(sessionStorage.getItem(paraType));
}

export interface ICustomParaDef {
  code: string;
  name: string;
  type: string;
  remark1: string;
  remark2: string;
  remark3: string;
}

export function getparaconf() {
  return paraconf;
}

// export const kddStatusArray = [
//   { statusName: "待制单", statusCode: 20, statusDesc: "待制单" },
//   {
//     statusName: "制单中",
//     statusCode: 25,
//     statusDesc: "制单中"
//   },
//   {
//     statusName: "合并后",
//     statusCode: 26,
//     statusDesc: "合并后"
//   },
//   {
//     statusName: "分拆后",
//     statusCode: 28,
//     statusDesc: "分拆后"
//   },

//   {
//     statusName: "待预录",
//     statusCode: 30,
//     statusDesc: "待预录"
//   },
//   {
//     statusName: "预录中",
//     statusCode: 33,
//     statusDesc: "预录中"
//   },

//   {
//     statusName: "退回",
//     statusCode: 35,
//     statusDesc: "退回"
//   },
//   {
//     statusName: "初审提交",
//     statusCode: 40,
//     statusDesc: "初审提交"
//   },
//   {
//     statusname: "初审结束",
//     statusCode: 41,
//     statusDesc: "初审结束",
//   },
//   {
//     statusname: "客户初审结束",
//     statusCode: 41.5,
//     statusDesc: "客户初审结束",
//   },
//   {
//     statusName: "复审",
//     statusCode: 45,
//     statusDesc: "复审"
//   },
//   {
//     statusName: "复审结束",
//     statusCode: 46,
//     statusDesc: "复审结束"
//   },
//   {
//     statusname: "客户复审结束",
//     statusCode: 46.5,
//     statusDesc: "客户复审结束",
//   },
//   {
//     statusname: "大金额审核提交",
//     statusCode: 47,
//     statusDesc: "大金额审核提交",
//   },
//   {
//     statusName: "审结",
//     statusCode: 50,
//     statusDesc: "审结"
//   },
//   {
//     statusName: "发送中",
//     statusCode: 53,
//     statusDesc: "发送中"
//   },
//   {
//     statusName: "发送失败",
//     statusCode: 55,
//     statusDesc: "发送中"
//   },
//   {
//     statusName: "发送成功",
//     statusCode: 58,
//     statusDesc: "发送中"
//   },
//   {
//     statusName: "已入库",
//     statusCode: 60,
//     statusDesc: "已入库"
//   }
// ];

// export const statusDic: any = {
//   上传中: {
//     statusname: "上传中",
//     statusCode: 3,
//     statusDesc: "文件上传等待处理"
//   },
//   已上传: {
//     statusname: "已上传",
//     statusCode: 5,
//     statusDesc: "已上传"
//   },
//   AI处理中: {
//     statusname: "AI处理中",
//     statusCode: 10,
//     statusDesc: "AI处理中"
//   },



//   待制单: {
//     statusname: "待制单",
//     statusCode: 20,
//     statusDesc: "待制单"
//   },
//   制单中: {
//     statusname: "制单中",
//     statusCode: 25,
//     statusDesc: "制单中"
//   },
//   "合并后":
//   {
//     statusName: "合并后",
//     statusCode: 26,
//     statusDesc: "合并后"
//   },
//   "分拆后":{
//     statusName: "分拆后",
//     statusCode: 28,
//     statusDesc: "分拆后"
//   },

//   待预录: {
//     statusname: "待预录",
//     statusCode: 30,
//     statusDesc: "制单中"
//   },
//   预录中: {
//     statusname: "预录中",
//     statusCode: 33,
//     statusDesc: "制单中"
//   },

//   退回: {
//     statusname: "退回",
//     statusCode: 35,
//     statusDesc: "退回"
//   },
//   初审: {
//     statusname: "初审",
//     statusCode: 40,
//     statusDesc: "初审"
//   },

//   复审: {
//     statusname: "复审",
//     statusCode: 45,
//     statusDesc: "复审"
//   },
//   审结: {
//     statusname: "审结",
//     statusCode: 50,
//     statusDesc: "审结"
//   },
//   发送中: {
//     statusname: "发送中",
//     statusCode: 53,
//     statusDesc: "发送中"
//   },
//   发送失败: {
//     statusname: "发送失败",
//     statusCode: 55,
//     statusDesc: "发送中"
//   },
//   发送成功: {
//     statusname: "发送成功",
//     statusCode: 58,
//     statusDesc: "发送中"
//   },
//   已入库: {
//     statusname: "已入库",
//     statusCode: 60,
//     statusDesc: "已入库"
//   }
// };
