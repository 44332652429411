import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppUserInfo } from '@ternsorinfo/data';
import { IRouteCacheInfo, RouterReuseService } from '@ternsorinfo/shared/backbase';
import { TiSocketService } from '@ternsorinfo/shared/helper';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { environment } from '../environments/environment';
import { VERSION } from '../environments/version';
import { menuDef } from './data/menu-def';


@Component({
  selector: 'ternsorinfo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
 export class AppComponent implements OnInit ,AfterViewInit{
  /**
   * sidebar menu collapsed flag
   */
  isCollapsed = false;
  /**
   *
   */
  activeTabIndex = 0;

  sideMenuList = menuDef;

  appVserion = VERSION.version;

  /**
   * dingUser
   */
  currentUser: AppUserInfo;

  /**
   *
   */
  
  public get prodFlag() : boolean {
    return sessionStorage.getItem('prod')=='prod';
  }
  public set prodFlag(v : boolean) {

    sessionStorage.setItem('prod',v?'prod':'dev')
    console.log('set prod',sessionStorage.getItem('prod'))
  }
  

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isCollapsed = true;

      // this.isCollapsed=true
    });
    //throw new Error('Method not implemented.');
  }
  /**
   * all opened tabs
   */
  componentTabs: Array<IRouteCacheInfo> = [];

  constructor(
    private router: Router,
    private socket: TiSocketService,
    private notificationService: NzNotificationService,
    private route: ActivatedRoute
  ) {
    this.componentTabs = RouterReuseService.cacheRouteSet;

    RouterReuseService.cacheChanged.subscribe((value: number) => {
      this.activeTabIndex = value;
    });

    this.socket.getSession(environment.appID).subscribe((_) => {
      this.socket.getLoginMessage().subscribe((message: string) => {
        console.log('login', message);
      });
      this.socket.onDisconnect().subscribe((data) => {
        this.notificationService.error('检查网络', 'socket disconnect');
      });
      this.socket.onCommonMessage().subscribe((message) => {
        this.notificationService.info('', message);
      });
    });
    this.socket.currentUserSubject.subscribe((v) => {
      this.currentUser = v;
      console.log('userChanged', v);
      this.afterLogin(this.currentUser);
      // if (this.currentUser && !this.currentUser) {
      //   this.currentUser;
      // }
    });
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe((para) => {
      console.log('proeeeed', para);
    });
  }

  closeTab(tabinfo: IRouteCacheInfo) {
    console.log('close', tabinfo.routeKey);
    const closeindex = RouterReuseService.cacheRouteSet.indexOf(tabinfo);
    if (closeindex != this.activeTabIndex) {
      RouterReuseService.cacheRouteSet.splice(closeindex, 1);
    } else {
      RouterReuseService.cacheRouteSet[closeindex].closeflag = true;
      if (closeindex >= 1) {
        this.selectedTabMenu(RouterReuseService.cacheRouteSet[closeindex - 1]);
      } else {
        this.router.navigate(['/']);
      }
    }
  }
  /**
   * select tab re open
   * @param tabinfo
   */
  selectedTabMenu(tabinfo: IRouteCacheInfo) {
    if (!tabinfo.closeflag) {
      this.openRoute(tabinfo);
    }
  }
  /**
   * 跳转到指定router
   * @param route
   */

  openRoute(route: IRouteCacheInfo) {
    this.router.navigateByUrl(route.routeKey)
    .then(flag=>{
      console.debug('openRoute',flag)

    })
    // this.router
    //   .navigate([route.routeKey], {
    //     queryParams: route.routerSnapshot.queryParams,
    //   })
    //   .then((flag) => {});
  }
  /**
   * menu click
   * @param subitem
   */

  menuNaviTo(subitem) {
    // this.router.navigateByUrl()
    this.router.navigate([subitem.route], {
      queryParams: { ...subitem.queryParams, prod: this.prodFlag },
      fragment: subitem.fragment,
    });
  }

  /**
   * 当用户变化时调用
   * @param userinfo
   */
  afterLogin(userinfo: AppUserInfo) {
    if (userinfo && userinfo.QRID) {
      // sessionStorage.setItem('currentUser', JSON.stringify(userinfo));
      // sessionStorage.setItem('currentUser', JSON.stringify(userinfo));

      console.log('after login');
      // if (!!!userinfo.validFlag) {
      //   this.notificationService.error('请登陆', '没有管理工作人员角色！');
      //   this.router.navigate(['login']);
      // }

      // this.grapqhlSerice
      //   .getAvailBranchs(userinfo.unionid)
      //   .subscribe((value) => {
      //     this.cpcBranchList = value;
      //   });
    } else {
      this.notificationService.error('请登陆', '未登录！请用钉钉扫码登录！');
    }
  }
}
