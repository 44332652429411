<nz-layout class="app-layout">
  <nz-header *ngIf="currentUser">
    <div class="app-header">
      <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
        <i
          class="trigger"
          nz-icon
          [nzType]="tcol ? 'menu-unfold' : 'menu-fold'"
        >
        </i>
      </span>
      <span class="sidebar-logo">
        <!-- <img src="/assets/img/sideComac.png" class="logoImg" alt="logo" /> -->
        <img src="/assets/img/comac.png" class="logoImg" alt="logo" />
      </span>

      <button
        nz-button
        nzType="primary"
        nzShape="circle"
        class="new"
        (click)="newOrderMain()"
      >
        <i nz-icon class="upload" nzType="cloud-upload" nzTheme="outline"></i>
      </button>

      <!--<span class="header-search mailSearch" style="margin-top:8px">
          <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
            <input type="text" nz-input placeholder="主题/收件人/正文/订单编号" />
          </nz-input-group>
  
          <ng-template #suffixIconButton>
            <button nz-button nzType="primary" style="padding:0" nzSearch>
              <i nz-icon style="margin: 0 8px" nzType="search"></i>
            </button>
          </ng-template>
        </span>-->

      <button
        nz-button
        id="betaprodBtn"
        name="betaprodBtn"
        nzSize="small"
        (click)="betaprod()"
        class="version"
      >
        {{ betaFlag ? '正式版' : '测试版' }}
      </button>
      <!-- <i
        nz-icon
        nzType="question-circle"
        nzTheme="outline"
        class="question"
        (click)="betaprod()"
      >
      </i> -->

      <nz-avatar
        nz-dropdown
        nzTrigger="click"
        [nzDropdownMenu]="menu"
        class="header-search aaa"
        [nzSize]="32"
        [nzSrc]="currentUser?.headImgUrl"
      ></nz-avatar>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item>修改密码</li>
          <li nz-menu-divider></li>
          <li nz-menu-item>
            <a [routerLink]="['/login']"
              ><i nz-icon nzType="logout" nzTheme="outline"></i
              ><span class="iconP">退出登陆</span></a
            >
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </nz-header>

  <nz-layout>
    <nz-sider
      class="menu-sidebar"
      nzCollapsible
      nzWidth="220px"
      nzCollapsedWidth="60"
      nzBreakpoint="md"
      [(nzCollapsed)]="isCollapsed"
      [nzCollapsible]="true"
      [nzTrigger]="null"
      *ngIf="currentUser"
    >
      <ul [nzTheme]="'dark'" nz-menu nzMode="inline">
        <ng-container *ngFor="let item of sideMenuList">
          <ng-container *ngIf="item.submenuFlag == false">
            <li nz-submenu>
              <span title
                ><i nz-icon nzType="form" nzTheme="outline"></i
                ><span>{{ item.label }}</span></span
              >
              <a routerLinkActive="active">
                <i nz-icon [type]="item.iconType"></i>{{ item.label }}
              </a>
            </li>
          </ng-container>
          <ng-container>
            <li nz-submenu *ngIf="item.submenuFlag == true">
              <span title
                ><i nz-icon [nzType]="item.iconType"></i>
                <span>{{ item.label }}</span></span
              >
              <ul>
                <li nz-menu-item *ngFor="let subitem of item.submenus">
                  <a (click)="menuNaviTo(subitem)">{{ subitem.label }}</a>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </nz-sider>
    <nz-layout>
      <nz-tabset
        class="header-tabs"
        [(nzSelectedIndex)]="activeTabIndex"
        *ngIf="currentUser"
      >
        <nz-tab
          *ngFor="let tabinfo of componentTabs; index as i"
          [nzTitle]="titleTemplate"
          (nzClick)="selectedTabMenu(tabinfo)"
        >
          <ng-template #titleTemplate>
            <span>
              <!--<i nz-icon nzType="bank"> </i>  -->
              {{ tabinfo.title
              }}<i
                nz-icon
                nzType="close"
                style="font-size: 12px; margin-left: 8px; margin-right: -1px;"
                class="ant-tabs-close-x"
                (click)="closeTab(tabinfo)"
                *ngIf="tabinfo.title != 'home'"
              ></i>
            </span>
          </ng-template>
        </nz-tab>
      </nz-tabset>

      <nz-content>
        <div class="inner-content">
          <router-outlet></router-outlet>
        </div>
      </nz-content>
      <!-- <nz-footer *ngIf="currentUser"
        >Ti Design ©2020 Implement By Ti Ver.{{ appVserion }}</nz-footer
      > -->
    </nz-layout>
  </nz-layout>
</nz-layout>
