import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { NetworkStatus } from '@apollo/client/core';
import { relayStylePagination } from '@apollo/client/utilities';
import { Observable } from 'rxjs';

import { map, switchMap } from 'rxjs/operators';
import { JudgeRoleGQLService } from './judge-role-service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  component: Object;
  route: ActivatedRouteSnapshot;

  constructor(
    private router: Router,
    private roleService: JudgeRoleGQLService
  ) {
    // this.router.routeReuseStrategy.shouldReuseRoute = function () {
    //   return false;
    // };
    // this.router.onSameUrlNavigation = 'reload';
  }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url = state.url;
    // console.debug('AuthGuard#canActivate called', url);

    //return true;
    const result = this.checkRoles(url);

    const tt = result.valueOf();
    if (tt == false) {
      console.log('router to login' )
      return this.router.parseUrl('/login');
    } else {
      console.log('router to ' ,tt)

      return result;
    }

    // this.router.navigate('/myroute', { queryParams: { index: 1 } });
    // if (result === false) {
    //   console.log('result false navigate to login');
    //   return this.router.navigateByUrl('/login');
    // } else {
    //   result.subscribe((v) => {
    //     if (v == false) {
    //       console.log('subcribe false navigate to login');

    //       return this.router.navigateByUrl('/login', {
    //         queryParams: { tt: 22 },
    //         skipLocationChange: true,
    //       });
    //     } else {
    //       return true;
    //     }
    //   });
    //   // return result
    // }
  }
  regUrl(url: string) {
    const regex = /\/\w{24,32}\??.*|\/0$|\?.*|#.*$/gm;

    const subst = ``;

    // The substituted value will be contained in the result variable
    return url.replace(regex, subst);
  }
  checkRoles(url: string) {
    console.log('check role in', url);

    // this.logger.debug("checkrole")
    // this.logger.debug(this.authenticationService.currentUser,this.authenticationService.currentUserValue);
    let userStorage;

    if (!!sessionStorage.getItem('currentUser')) {
      userStorage = JSON.parse(sessionStorage.getItem('currentUser'));
    }
    if (!!!userStorage) {
      this.router.navigate(['/login'],{ queryParams: { returnUrl: url }});
      return false;
    } else {
      // console.log('check role', this.regUrl(url));
      // return true;

      // check role from graphql
        // return true

      // return true;
       return this.roleService.judgeUrlRole(this.regUrl(url))
       .pipe(map((val)=>{
         if(val==false){

          return false;//this.router.parseUrl('/login')
         }
         else{
           return val
         }

       }))
       
    }

    // }
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const url = state.url;
    // this.logger.debug('AuthGuard#canActivate called', url);

    return this.checkRoles(url);
  }

  // canLoad(
  //   route: Route,
  //   segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
  //   return true;
  // }
}
