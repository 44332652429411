import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppUserInfo, DingUser } from '@ternsorinfo/data';
import { map, tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TiSocketService {
  public currentUserSubject: BehaviorSubject<AppUserInfo>;
  public currentUser: Observable<AppUserInfo>;

  constructor(private socket: Socket, private httpClient: HttpClient) {
    let userStorage;
    if (!!sessionStorage.getItem('currentUser')) {
      userStorage = JSON.parse(sessionStorage.getItem('currentUser'));
    }

    this.currentUserSubject = new BehaviorSubject<AppUserInfo>(
      <AppUserInfo>userStorage
    );
    this.currentUser = this.currentUserSubject.asObservable();
    // console.log('init userStorage',userStorage);
  }

  public get currentUserValue(): AppUserInfo {
    return this.currentUserSubject.value;
  }
  /**
   * 客户端获取seesion先为socket session QRCodeID做准备。
   */
  getSession(appID: string, DingURL = 'dingd') {
    return this.httpClient
      .get<any>(DingURL, {
        headers: new HttpHeaders({ appid: appID }),
      })
      .pipe(
        // map((res) => {
        //   return res.url;
        // }),
        tap((_) => console.log(`getSession`))
        // catchError((e)=>
        //   console.error("ee",[])
        // )
      );
  }

  logout(appID: string, DingURL = 'dingd') {
    sessionStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return this.httpClient.get(`${DingURL}/logout`, {
      headers: new HttpHeaders({ appid: appID }),
    });
  }

  dingJsConfig(url: string, appID: string, DingURL = 'dingd') {
    // const REDIRECT_URI = 'http://cpadmin.tiansole.com/dingd/scan';
    return this.httpClient
      .get<any>(`${DingURL}/getddconfig?url=${url}`, {
        headers: new HttpHeaders({ appid: appID }),
      })
      .pipe(
        // map((res) => {
        //   return res.url;
        // }),
        tap((_) => console.log(`getddconfig`))
      );
  }
  /**
   * dingding 客户端登陆
   * @param authCode auth tmp code
   */

  dingLogin(authCode: string, appID: string, DingURL = 'dingd') {
    return this.httpClient
      .get<any>(`${DingURL}/getUserByAuthCode?authCode=${authCode}`, {
        headers: new HttpHeaders({ appid: appID }),
      })
      .pipe(
        // map((res) => {
        //   return res.url;
        // }),
        tap((_) => console.log(`getddconfig`))
      );
  }

  /**
   *
   * @param tmpcode 扫码得到的tmpcode 用来获得重定向，获得openid 进而获得userid 再获得用户信息
   *
   */

  dingSNSauth(tmpcode, appID: string, DingURL = 'dingd') {
    // const REDIRECT_URI = 'http://cpadmin.tiansole.com/dingd/scan';
    return this.httpClient
      .get<any>(`${DingURL}/sns?tmp_auth_code=${tmpcode}`, {
        headers: new HttpHeaders({ appid: appID }),
      })
      .pipe(
        // map((res) => {
        //   return res.url;
        // }),
        tap((_) => console.log(`getSession`)),
        map((v) => {
          if (v && v.userInfo) {
            sessionStorage.setItem('currentUser', JSON.stringify(v.userInfo));
            sessionStorage.setItem('qrid', (<AppUserInfo>v.userInfo).QRID);

            this.currentUserSubject.next(v.userInfo);
            console.log('snsauth', v);

            return this.currentUserValue;
          } else {
            this.currentUserSubject.next(null);
            sessionStorage.removeItem('currentUser');
            return null;
          }
        })
        // catchError((e)=>
        //   console.error("ee",[])
        // )
      );
  }

  /**
   * 登录成功返回值
   */
  getLoginMessage() {
    return this.socket
      .fromEvent('loginmessage')
      
      .pipe(
        tap((_)=>console.debug(_)),
        map((data: any) => data));
  }

  onSocketStatusChange() {
    return this.socket.fromEvent('SocketPubMessage').pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  onCommonMessage() {
    return this.socket.fromEvent('message').pipe(
      map((data: any) => {
        console.log(`common message ${data}:${this.socket.ioSocket.id}`);
        return data;
      })
    );
  }

  onDisconnect() {
    return this.socket.fromEvent('disconnect');
  }
}
