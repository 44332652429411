
import {
  Productmanage,
  DecMergeListVo,
  KddPreOrderTemplate,
  Kddpreorder,
  KeyValue
} from '@ternsorinfo/data';
import { ITemplateConfig } from './commonTypes';
// import { templateJitUrl } from "@angular/compiler";
import { qtychange, mulwithcombef, getQtybyUnit, getunitinfo } from './helper';
import { formatDate } from '@angular/common';
export function formCondition(condition: any) {
  Object.keys(condition).map((key) => {
    if (condition[key] === '' || condition[key] == undefined) {
      delete condition[key];
    }
  });
}

// like condition from filed array

export interface ICondtionMongoRegex {
  $regex: string;
}
export interface IConditionMongoOr {
  $or: any[];
}
export interface IConditionMongoAnd {
  $and: any[];
}

export function formOrConditonFromFields(
  fields: string[],
  searchStr: string
): IConditionMongoOr {
  const condition: IConditionMongoOr = { $or: [] };

  for (const condi of fields) {
    let keycondition: any = {};
    keycondition[condi] = { $regex: searchStr, $options: 'i' };
    condition.$or.push(keycondition);
  }
  return condition;
}
export function cpxformAndConditionFromKeyValue(
  keyValues: KeyValue[]
): IConditionMongoAnd {
  const condition: IConditionMongoAnd = { $and: [] };
  for (const keyvalue of keyValues) {
    let keycondition: any = {};
    try {
      if (isNaN(keyvalue.name as any)) {
        keycondition[keyvalue.id] = JSON.parse(keyvalue.name);
        if (keycondition[keyvalue.id]['$regex']) {
          keycondition[keyvalue.id]['$regex'] = keycondition[keyvalue.id][
            '$regex'
          ]
            .replace('(', '\\(')
            .replace(')', '\\)');
        }
      }
      else{
        keycondition[keyvalue.id] = keyvalue.name;
      }
      // }
    } catch (error) {
      if (keyvalue.values) {

        var datereg=/^\d{4}-\d{2}-\d{2}/;
        for(var key in keyvalue.values){
          var cvalue=keyvalue.values[key];
          if(datereg.test(cvalue)){
            keyvalue.values[key]=new Date(keyvalue.values[key]);
          }
        }
        keycondition[keyvalue.id] = keyvalue.values;     
      } else {
        keycondition[keyvalue.id] = keyvalue.name;
      }
    }
    condition.$and.push(keycondition);
  }
  return condition;
}

export function formAndConditionFromKeyValue(
  keyValues: KeyValue[]
): IConditionMongoAnd {
  const condition: IConditionMongoAnd = { $and: [] };
  for (const keyvalue of keyValues) {
    let keycondition: any = {};

    try {
      keycondition[keyvalue.id] = JSON.parse(keyvalue.name);
      if (keycondition[keyvalue.id]['$regex']) {
        keycondition[keyvalue.id]['$regex'] = keycondition[keyvalue.id][
          '$regex'
        ]
          .replace('(', '\\(')
          .replace(')', '\\)');
      }
    } catch (error) {
      keycondition[keyvalue.id] = keyvalue.name
        .replace('(', '\\(')
        .replace(')', '\\)');
    }

    condition.$and.push(keycondition);
  }
  return condition;
}

// like conditon from condtion key

export function formOrConditon(__: any): any {
  const conditions: Array<any> = [];
  let searchCondition: any = {};

  for (const key in __) {
    if (!__[key]) {
      delete __[key];
    } else {
      if (key != 'pageinfo') {
        let condition: any = {};
        condition[key] = { $regex: __[key] };

        conditions.push(condition);
      }
    }
  }
  if (conditions.length > 0) {
    searchCondition = { $or: conditions };
  }
  return searchCondition;
}

export function parseHead(
  headStr: string
): { appId: string; openID: string; userName: string; empName: string } {
  if (!headStr) {
    throw new Error('非法');
  }

  const body = headStr.split(':');
  if (body.length == 2) {
    const result = {
      appId: body[0],
      openID: body[1],
      userName: '',
      empName: '无',
    };

    return result;
  } else {
    return { appId: '', openID: '', userName: '', empName: '' };
  }
}

export const parseHeadUpdate = async function (headStr: string) {
  const result = parseHead(headStr);
  if (result.openID) {
    // const user= await getCurrUser(result.openID,result.appId);
    const user = null;
    if (user) {
      result.empName = user.name;
      result.userName = user.nickName;
    }
  }
  return result;
};
export function PrefixZero(num: number, n: number) {
  if (num.toString().length < n) {
    return (Array(n).join('0') + num).slice(-n);
  } else {
    return num.toString();
  }
}
// yyyy-MM-dd HH:mm:ss
export function transformatdate(date: Date, format: string) {
  var _this = this;
  var formatreg = [];
  var y = date.getFullYear();
  var tmpyearreg: any = {};
  tmpyearreg.regex = /y+/gm;
  tmpyearreg.date = y;
  formatreg.push(tmpyearreg);

  let m: any = date.getMonth() + 1;
  m = m < 10 ? '0' + m : m;

  var tmpmreg: any = {};
  tmpmreg.regex = /M+/gm;
  tmpmreg.date = m;
  formatreg.push(tmpmreg);

  var d: any = date.getDate();
  d = d < 10 ? '0' + d : d;

  var tmpdreg: any = {};
  tmpdreg.regex = /d+/gm;
  tmpdreg.date = d;
  formatreg.push(tmpdreg);

  var h: any = date.getHours();
  var minute: any = date.getMinutes();
  var second: any = date.getSeconds();
  h = h < 10 ? '0' + h : h;
  var tmphreg: any = {};
  tmphreg.regex = /H+/gm;
  tmphreg.date = h;
  formatreg.push(tmphreg);

  minute = minute < 10 ? '0' + minute : minute;
  var tmpmireg: any = {};
  tmpmireg.regex = /m+/gm;
  tmpmireg.date = minute;
  formatreg.push(tmpmireg);

  second = second < 10 ? '0' + second : second;

  var tmpsereg: any = {};
  tmpsereg.regex = /s+/gm;
  tmpsereg.date = second;
  formatreg.push(tmpsereg);
  for (var i = 0; i < formatreg.length; i++) {
    var fregdetail = formatreg[i];
    var regex = fregdetail.regex;
    var datestr = getRegexresult(format, regex);
    if (datestr) {
      fregdetail.date = fregdetail.date.toString();
      fregdetail.date = fregdetail.date.substr(
        fregdetail.date.length - datestr.length
      );
      format = format.replace(datestr, fregdetail.date);
    }
  }
  return format;
}

function getRegexresult(txt: String, regex: any) {
  // const regex = /_(\d+).pdf$/gm;
  let m;

  while ((m = regex.exec(txt)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    let result = '';
    m.forEach((match: any, groupIndex: any) => {
      result = match;
    });
    return result;
  }
}

export function sort(sortField: string, sortValue: string = 'ascend') {
  return (a: any, b: any): number => {
    return sortValue === 'ascend'
      ? a[sortField!] > b[sortField!]
        ? 1
        : -1
      : b[sortField!] > a[sortField!]
        ? 1
        : -1;
  };
}

export const ResponseMessage = {
  ok: function (message: string, data: any) {
    return {
      success: true,
      message: message,
      data: data,
      status: 'OK',
    };
  },
  error: function (message: string, data = '') {
    return {
      success: false,
      message: message,
      data: data,
      status: 'ERROR',
    };
  },
};




export enum OrderFileType {
  ORIGIN = '原始上传',
  PREPROCESSED = '解压中间类型',
}

/**
 * hash password with companyName
 */
// export const hashString = function (companyName: string, password: string) {

//   const hash = crypto.createHmac('sha256', companyName)
//       .update(password)
//       .digest('hex');
//   return hash;
// }

export const ToCDB = function (str: string) {
  var tmp = '';
  if (str) {
    for (var i = 0; i < str.length; i++) {
      if (str.charCodeAt(i) == 12288) {
        tmp += String.fromCharCode(str.charCodeAt(i) - 12256);
        continue;
      }
      if (str.charCodeAt(i) > 65280 && str.charCodeAt(i) < 65375) {
        tmp += String.fromCharCode(str.charCodeAt(i) - 65248);
      } else {
        tmp += String.fromCharCode(str.charCodeAt(i));
      }
    }
  }
  return tmp;
};

export function getISOTimeSeqNO() {
  return new Date().toISOString().replace(/[-T-Z/./:]/gi, '');
}

export function deepCopyJson(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

// export const getRandomID = (length: number): string => {
//   return crypto.randomBytes(length).toString('hex');
// };

export function isEmptyObject(obj: any) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export function deepCopy(obj: any): any {
  var copy;

  // Handle the 3 simple types, and null or undefined
  if (null == obj || 'object' != typeof obj) return obj;

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (var i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepCopy(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    let copy: any = {};
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr]);
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
}

export function convertObjFromTemplateDef(orderDoc: any, objDefTemplate: any) {
  const incomingjson = deepCopy(objDefTemplate);
  delete incomingjson['_msg_'];
  var preorder: any = {};

  for (var key in incomingjson) {
    if (Array.isArray(incomingjson[key])) {
      preorder[key] = [];
      if (incomingjson[key].length > 0) {
        var listinfo = incomingjson[key][0]._name_;
        var listdetail = incomingjson[key][1];
        var listvalue = getincvalue(orderDoc, listinfo);
        if (listvalue && listvalue.length > 0) {
          for (var l = 0; l < listvalue.length; l++) {
            var tmppresub: any = {};
            for (var ldkey in listdetail) {
              if (listdetail[ldkey]) {
                if (Array.isArray(listdetail[ldkey])) {
                  tmppresub[ldkey] = [];
                } else {
                  tmppresub[ldkey] = getincvalue(
                    listvalue[l],
                    listdetail[ldkey]
                  );
                }
              } else {
                tmppresub[ldkey] = '';
              }
            }
            preorder[key].push(tmppresub);
          }
        }
      }
    } else {
      if (incomingjson[key]) {
        preorder[key] = getincvalue(orderDoc, incomingjson[key]);
      } else {
        preorder[key] = '';
      }
    }
  }
  return preorder;
}

function getincvalue(ipvalue: any, fieldstruct: any) {
  var fls = fieldstruct.split('#');
  var backvalue: any = ipvalue;
  for (var i = 0; i < fls.length; i++) {
    if (backvalue[fls[i]]) {
      backvalue = backvalue[fls[i]];
    } else {
      backvalue = '';
    }
  }

  return backvalue;
}

/**
 * return comac20032999 like orderNo
 * @param appID
 * @param prefix
 */
// export function genOrderNo(appID:string,prefix=""){

//   return `${appID}${prefix}${getISOTimeSeqNO()}${crypto.randomBytes(
//       1
//     ).toString("hex")}`
//   }

// import { UserInfo, orderPool } from 'baoguan-data-contract';
import { Query } from '@angular/core';
import { query } from '@angular/animations';

// export const genXYorderNo = async function () {
//   try {
//     var date = transformatdate(new Date(), 'yyyyMMdd');
//     var lastorder = await orderPool.findOne().sort({ order: -1 });
//     var currorder = 1;
//     if (lastorder) {
//       var currorder = lastorder.order + 1; //补齐6位
//     }
//     var poolinfo: any = {};
//     poolinfo.appID = 'comac';
//     poolinfo.prefix = 'XYSF';
//     poolinfo.order = currorder;
//     poolinfo.fullNo = poolinfo.prefix + date + PrefixZero(currorder, 6);
//     var backinfo = await orderPool.insertMany([poolinfo]);
//     if (backinfo) {
//       return poolinfo.fullNo;
//     } else {
//       return '';
//     }
//   } catch (e) {
//     return '';
//   }
// };

// head {authorization:"comac:okRC81YdPgMYmSNWJHBE4NiLHeFY"}
// export const patchLoginName = async function (obj: any, head: any) {
//   var loginperson = '';
//   var openid = '';
//   if (head.authorization) {
//     openid = head.authorization.substr(head.authorization.indexOf(':') + 1);
//     var user = await UserInfo.findOne({ openID: openid });
//     if (user) {
//       loginperson = user.name;
//     }
//   }
//   if (obj) {
//     if (!obj.createPerson) {
//       obj.createPerson = loginperson;
//       obj.createTime = new Date();
//     }
//     obj.lastUpdatePerson = loginperson;
//     obj.lastUpdateDate = new Date();
//     if (!obj.operNames) {
//       obj.operNames = [];
//     }
//     if (obj.operNames.indexOf(openid) == -1) {
//       obj.operNames.push(openid);
//     }
//   }
//   // return obj;
// };

// 部分不修改更新时间和operNames
// export const patchLoginWithoutDate = async function (obj: any, head: any) {
//   var loginperson = '';
//   var openid = '';
//   if (head.authorization) {
//     openid = head.authorization.substr(head.authorization.indexOf(':') + 1);
//     var user = await UserInfo.findOne({ openID: openid });
//     if (user) {
//       loginperson = user.name;
//     }
//   }
//   if (obj) {
//     if (!obj.createPerson) {
//       obj.createPerson = loginperson;
//       obj.createTime = new Date();
//     }
//     obj.lastUpdatePerson = loginperson;
//   }
//   // return obj;
// };

// export const getLoginName = async function (head: any) {
//   var loginperson = '';
//   var openid = '';
//   if (head.authorization) {
//     var user = await UserInfo.findOne({ openID: head.authorization });
//     openid = head.authorization;
//     if (user) {
//       loginperson = user.name;
//     }
//   }
//   return { loginName: loginperson, openid: openid };
// };

//字符补0
export const PrefixSepNo = function (str: string, n: number) {
  if (str.length < n) {
    return (str + Array(n).join('0')).slice(0, n);
  } else {
    return str;
  }
};

export const comparval = function (ori: any, tar: any) {
  if (ori == null || ori == '') {
    if (tar == null || tar == '') {
      return true;
    }
  } else {
    if (!isNaN(ori) && !isNaN(tar)) {
      if (Number(ori) != Number(tar)) {
        return false;
      } else {
        return true;
      }
    } else if (isNaN(ori) && isNaN(tar)) {
      if (ori != tar) {
        return false;
      } else {
        return true;
      }
    }
  }
  return false;
};

export const getfirstRegexresult = function (txt: string, regex: RegExp) {
  // const regex = /_(\d+).pdf$/gm;
  let m;

  while ((m = regex.exec(txt)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    let result = '';
    if (m.length > 0) {
      result = m[0];
    }
    return result;
  }
};

export const by = function (name: any) {
  return function (o: any, p: any) {
    var a, b;
    if (typeof o === 'object' && typeof p === 'object' && o && p) {
      a = o[name];
      b = p[name];
      if (a === b) {
        return 0;
      }
      if (typeof a === typeof b) {
        if (isNaN(a)) {
          return a < b ? -1 : 1;
        } else {
          return Number(a) < Number(b) ? -1 : 1;
        }
      }
      return typeof a < typeof b ? -1 : 1;
    } else {
      throw 'error';
    }
  };
};

export const descby = function (name: any) {
  return function (o: any, p: any) {
    var a, b;
    if (typeof o === 'object' && typeof p === 'object' && o && p) {
      a = o[name];
      b = p[name];
      if (a === b) {
        return 0;
      }
      if (typeof a === typeof b) {
        if (isNaN(a)) {
          return a > b ? -1 : 1;
        } else {
          return Number(a) > Number(b) ? -1 : 1;
        }
      }
      return typeof a > typeof b ? -1 : 1;
    } else {
      throw 'error';
    }
  };
};

export const fixnum = function (num: any, len: any) {
  return parseFloat(num.toFixed(len));
};

//获取文字的数字部分
export const changetonumberstr = function (ntext: any, dottype: any) {
  var pureNumberRegex = /(\d+[,\.])*\d+/;
  // 取最后一个, . 后面两位设为小数点 后面三位设为，（0开头一定是小数点）
  var gnum = getfirstRegexresult(ntext, pureNumberRegex);
  return gnum;
};

//liebher 重量重逗号代表小数点
export const changetoweight = function (ntext: any) {
  var pureNumberRegex = /(\d+[,\.])*\d+/;
  // 取最后一个, . 后面两位设为小数点 后面三位设为，（0开头一定是小数点）
  var gnum = getfirstRegexresult(ntext, pureNumberRegex);
  if (gnum != null) {
    // 取最后一个点
    var lastdot = -1;
    var commaflag = false;
    var dindex = gnum.lastIndexOf('.');
    if (dindex > lastdot) {
      lastdot = dindex;
    }
    var ecindex = gnum.lastIndexOf(',');
    if (ecindex > lastdot) {
      lastdot = ecindex;
      commaflag = true;
    }
    var ccindex = gnum.lastIndexOf('，');
    if (ccindex > lastdot) {
      lastdot = ccindex;
      commaflag = true;
    }
    var finresult = '';
    if (lastdot > -1) {
      var subnum = gnum.substring(lastdot + 1);
      finresult = gnum
        .substring(0, lastdot)
        .replace(/\./g, '')
        .replace(/，/g, '')
        .replace(/,/g, '');
      finresult += '.' + subnum;
    } else {
      finresult = gnum;
    }
    return Number(finresult);
  } else {
    return 0;
  }
};



export const deepcopy = function (obj: any) {
  return JSON.parse(JSON.stringify(obj));
};

export const changetonumber = function (ntext: any) {
  var pureNumberRegex = /(\d+[,\.])*\d+/;
  // 取最后一个, . 后面两位设为小数点 后面三位设为，（0开头一定是小数点）
  var gnum = getfirstRegexresult(ntext, pureNumberRegex);
  if (gnum != null) {
    // 取最后一个点
    var lastdot = -1;
    var commaflag = false;
    var dindex = gnum.lastIndexOf('.');
    if (dindex > lastdot) {
      lastdot = dindex;
    }
    var ecindex = gnum.lastIndexOf(',');
    if (ecindex > lastdot) {
      lastdot = ecindex;
      commaflag = true;
    }
    var ccindex = gnum.lastIndexOf('，');
    if (ccindex > lastdot) {
      lastdot = ccindex;
      commaflag = true;
    }
    var finresult = '';
    if (lastdot > -1) {
      var subnum = gnum.substring(lastdot + 1);
      finresult = gnum
        .substring(0, lastdot)
        .replace(/\./g, '')
        .replace(/，/g, '')
        .replace(/,/g, '');
      // ，和.会识别错误 暂且认为最后一个一定是小数点
      finresult += '.' + subnum;
    } else {
      finresult = gnum;
    }
    return Number(finresult);
  } else {
    return 0;
  }
};

export const getcolnum = function (index: any) {
  var num = changetonumber(index);
  var col = index.replace(num, '');
  var colnum = 0;
  for (var i = 0; i < col.length; i++) {
    colnum += col[i].charCodeAt();
  }
  return colnum;
};

export const groupBy = function (sourcelist: any[], groupargs: any[]) {
  var groupkey = groupargs.join(',');
  var gouplist: any[] = [];
  sourcelist.forEach((sor) => {
    sor[groupkey] = '';
    groupargs.forEach((gr) => {
      if (sor[gr]) {
        sor[groupkey] += sor[gr];
      } else {
        sor[groupkey] += 'empty';
      }
    });
    var keylist = [];
    for (var key in gouplist) {
      keylist.push(key);
    }
    if (keylist.indexOf(sor[groupkey]) == -1) {
      gouplist[sor[groupkey]] = [];
      gouplist[sor[groupkey]].push(sor);
    } else {
      gouplist[sor[groupkey]].push(sor);
    }
  });
  var result = [];
  for (var key in gouplist) {
    result.push(gouplist[key]);
  }
  return result;
};

export const sortglist = function (Glist: any) {
  var gmemolist = [];
  var gteam = [];
  var currgNo = -1;
  for (var i = 0; i < Glist.length; i++) {
    var sys = Glist[i];
    var gNo = sys.substring(1);
    if (currgNo == -1) {
      currgNo = Number(gNo);
      gteam.push(currgNo);
    } else {
      if (Number(gNo) == currgNo + 1) {
        currgNo = Number(gNo);
        gteam.push(currgNo);
      } else {
        currgNo = Number(gNo);
        gmemolist.push(deepcopy(gteam));
        gteam = [];
        gteam.push(currgNo);
      }
    }
  }
  if (gteam.length > 0) {
    gmemolist.push(deepcopy(gteam));
  }
  var textls = [];
  for (var g = 0; g < gmemolist.length; g++) {
    var tmpteam = gmemolist[g];
    if (tmpteam.length == 1) {
      var text = 'G' + tmpteam[0];
      textls.push(text);
    } else {
      var text = 'G' + tmpteam[0] + '-G' + tmpteam[tmpteam.length - 1];
      textls.push(text);
    }
  }
  return textls;
};

export function commonMoveUp(
  arr: any[],
  selGNo: number,
  indexKey: string,
  position: number = -1
) {
  let selindex = selGNo;
  let tmposition = 1;
  if (position < 0) {
    tmposition = -1;
    position = position * -1;
  }
  for (var i = 0; i < position; i++) {
    const sel = arr.find((ele) => ele[indexKey] == selindex);
    const selchange = arr.find((ele) => ele[indexKey] == selindex + tmposition);
    if (sel && selchange) {
      sel[indexKey] = selindex + tmposition;
      selchange[indexKey] = selindex;
      selindex = selindex + tmposition;
    }
  }
}

export function groupby(xs: any, key: any) {
  return xs.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

//转全角jjjsfje
export function ToDBC(txtstring) {
  var tmp = '';
  for (var i = 0; i < txtstring.length; i++) {
    if (txtstring.charCodeAt(i) == 32) {
      tmp = tmp + String.fromCharCode(12288);
    }
    if (txtstring.charCodeAt(i) < 127) {
      tmp = tmp + String.fromCharCode(txtstring.charCodeAt(i) + 65248);
    }
  }
  return tmp;
}



export type NzDropDownPosition = 'top' | 'center' | 'bottom';
export const flightls = [
  { name: "", id: "" },
  { name: "ARJ21", id: "新型涡扇支线飞机" },
  { name: "大客", id: "大型客机" },
  { name: "宽体", id: "无重装目录" }
]
//修改商品库需要重新计算一遍数量吗?
//选择商品处理在 fillInvoiceItemByProductInfo  保存商品处理在prodtomerge
export function fillInvoiceItemByProductInfo(
  prodinfo: Productmanage,
  decMerge: DecMergeListVo,
  heavyflightType = ""
) {
  if (decMerge.qty && Number(decMerge.qty) != 0) {
    decMerge.declPrice = Number(
      Number(decMerge.declTotal) / Number(decMerge.qty)
    ).toFixed(4);
  }

  // decMerge.productid = prodinfo.id;
  decMerge.productupdateDate = prodinfo.lastUpdateDate;
  decMerge.codeTs = prodinfo.hsCode;

  decMerge.ControlMark = prodinfo.controlmark;
  decMerge.TarifFlag = prodinfo.tarifflag;

  decMerge.gName = prodinfo.nameCn;
  decMerge.gModel = prodinfo.declareElementsStr;
  decMerge.mbgModel = prodinfo.declareElementsStr;
  if (!decMerge.extendjson) {
    decMerge.extendjson = {};
  }
  decMerge.extendjson.declareElements = prodinfo.declareElements;
  decMerge.extendjson.mbdeclareElements = prodinfo.declareElements;
  decMerge.extendjson.realWeight = prodinfo.realWeight;
  decMerge.extendjson.ogName = prodinfo.nameCn;
  decMerge.extendjson.singleusage = prodinfo.singleusage;
  decMerge.extendjson.exportDec = prodinfo.exportDec;
  decMerge.singleweight = prodinfo.netWeight;
  heavyeqpjudge(heavyflightType, decMerge);


  if (!decMerge.netWeight || Number(decMerge.netWeight) == 0) {
    var nwt = prodinfo.netWeight ? prodinfo.netWeight : 0;
    var countqty = Number(decMerge.qty);
    if (!countqty || countqty == 0 || nwt == 0) {
      // countqty = 1;
    } else {
      decMerge.netWeight = parseFloat((nwt * countqty).toFixed(6)).toString();
    }
  } else {
    decMerge.netWeight = parseFloat(
      Number(decMerge.netWeight.toString()).toFixed(6)
    ).toString();
  }

  //根据prodinfo填写表头
  if (prodinfo.gUnitCn && prodinfo.gUnitCn != '000') {
    if (decMerge.gUnit != prodinfo.gUnitCn || !decMerge.gUnitName) {
      decMerge.gUnit = prodinfo.gUnitCn;
      var gUnitName = getparainfo({ code: prodinfo.gUnitCn, type: 'unit' });
      if (gUnitName) {
        decMerge.gUnitName = gUnitName.name;
      }

      var parseqty = getQtybyUnit(
        decMerge.qty ? decMerge.qty : 0,
        decMerge.gUnit,
        decMerge.netWeight,
        decMerge.codeTs
      );
      if (parseqty) {
        decMerge.gQty = parseFloat(
          Number(parseqty.toString()).toFixed(6)
        ).toString();
      }
    }
  } else {
    decMerge.gQty = null;
    decMerge.gUnit = null;
    decMerge.gUnitName = null;
  }
  if (prodinfo.unit1 && prodinfo.unit1 != '000') {
    if (decMerge.unit1 != prodinfo.unit1 || !decMerge.unit1Name) {
      decMerge.unit1 = prodinfo.unit1;
      var unit1 = getparainfo({ code: prodinfo.unit1, type: 'unit' });
      if (unit1) {
        decMerge.unit1Name = unit1.name;
      }
      var parseqty = getQtybyUnit(
        decMerge.qty ? decMerge.qty : 0,
        decMerge.unit1,
        decMerge.netWeight,
        decMerge.codeTs
      );
      if (parseqty) {
        decMerge.qty1 = parseFloat(
          Number(parseqty.toString()).toFixed(6)
        ).toString();
      }
    }
  } else {
    decMerge.qty1 = null;
    decMerge.unit1 = null;
    decMerge.unit1Name = null;
  }
  console.log('unit', decMerge.qty);

  if (prodinfo.unit2 && prodinfo.unit2 != '000') {
    if (decMerge.unit2 != prodinfo.unit2 || !decMerge.unit2Name) {
      decMerge.unit2 = prodinfo.unit2;
      var unit2 = getparainfo({ code: prodinfo.unit2, type: 'unit' });
      if (unit2) {
        decMerge.unit2Name = unit2.name;
      }
      var parseqty = getQtybyUnit(
        decMerge.qty ? decMerge.qty : 0,
        decMerge.unit2,
        decMerge.netWeight,
        decMerge.codeTs
      );
      if (parseqty) {
        decMerge.qty2 = parseFloat(
          Number(parseqty.toString()).toFixed(6)
        ).toString();
      }
    }
  } else {
    decMerge.qty2 = null;
    decMerge.unit2 = null;
    decMerge.unit2Name = null;
  }
  // parsefeet(decMerge);
  //原产地区用原产国赋值

  if (decMerge.combinebf) {
    var combinebf = JSON.parse(decMerge.combinebf);
    mulwithcombef(decMerge, combinebf);
  }
  qtychange(decMerge);
  decMerge.ciqCode = prodinfo.ciqCode;
  decMerge.ciqName = prodinfo.ciqName;

  decMerge.nameDesc = prodinfo.nameDesc;
  decMerge.subsystem = prodinfo.subsystem;
  var heavyeqp = prodinfo.heavyeqp;
  if (heavyeqp) {
    var eqpls = [];
    if (heavyeqp.indexOf('|') != -1) {
      eqpls = heavyeqp.split('|');
    } else {
      eqpls = heavyeqp.split('/');
    }
    try {
      decMerge.eqpname = eqpls[0];
      decMerge.firstlevel = eqpls[1];
      decMerge.secondlevel = eqpls[2];
    } catch (eqperr) { }
  }
}


export function heavyeqpjudge(heavyflightType, decMerge) {
  // if (heavyflightType && decMerge.extendjson.singleusage && decMerge.extendjson.singleusage.length > 0) {

  //   var finfo = flightls.filter(f => {
  //     return f.id == heavyflightType;
  //   })
  //   if (finfo.length > 0) {
  //     decMerge.extendjson.flightName = finfo[0].name;
  //   }


  //   decMerge.extendjson.heavyflightType = heavyflightType;
  //   var hv = decMerge.extendjson.singleusage.filter(g => {
  //     return g.machineinfo == heavyflightType && g.amount
  //   })
  //   if (hv.length > 0) {
  //     decMerge.extendjson.mbflag = true;
  //     decMerge.gName = decMerge.extendjson.ogName;
  //   }
  //   else {
  //     decMerge.extendjson.mbflag = false;
  //     if (decMerge.extendjson.ogName.indexOf("(") != -1) {
  //       decMerge.gName = decMerge.extendjson.ogName.substr(0, decMerge.extendjson.ogName.indexOf("("));
  //     }
  //   }
  // }
}


//判断商品的申报单位和法定单位是否相符
//计件的申报单位和法定单位一致
// 申报单位允许是重量 长度
export function fitunit(prodinfo) {
  var unit1info = getunitinfo(prodinfo.unit1);
  var unit2info = getunitinfo(prodinfo.unit2);
  var gunitinfo = getunitinfo(prodinfo.gUnitCn);
  console.log('fitunit');

  var judgeunit = ["台", "个"];
  if (unit1info && gunitinfo) {
    if (judgeunit.indexOf(unit1info.name) != -1 && prodinfo.unit1 != prodinfo.gUnitCn) {
      return unit1info;
    }
    else {
      return null;
    }
  }
  else {
    return null;
  }

  // if (
  //   unit1info &&
  //   unit1info.remark3 == 'piece' &&
  //   prodinfo.unit1 != prodinfo.gUnitCn &&
  //   prodinfo.gUnitCn
  // ) {
  //   if (gunitinfo && gunitinfo.remark3 == 'piece') {
  //     return unit1info;
  //   } else {
  //     return null;
  //   }
  // } else if (
  //   unit2info &&
  //   unit2info.remark3 == 'piece' &&
  //   prodinfo.unit2 != prodinfo.gUnitCn &&
  //   prodinfo.gUnitCn
  // ) {
  //   if (gunitinfo && gunitinfo.remark3 == 'piece') {
  //     return unit2info;
  //   } else {
  //     return null;
  //   }
  // } else {
  //   return null;
  // }
}

export function fitsku(prodinfo) {
  console.log('fitsku');
  var skuls = ['型号', '规格型号', '规格或型号', '款号'];
  var realmaterialPartNo = prodinfo.materialPartNo;
  var backinfo = '';
  if (realmaterialPartNo.indexOf('@') != -1) {
    realmaterialPartNo = realmaterialPartNo.substr(
      0,
      realmaterialPartNo.indexOf('@')
    );
  }
  if (
    prodinfo.declareElementsStr &&
    prodinfo.declareElementsStr.replace(/\|/g, '')
  ) {
    var decls = prodinfo.declareElementsStr.split('|');
    for (var i = 0; i < prodinfo.declareElements.length - 1; i++) {
      if (decls.length == prodinfo.declareElements.length - 1) {
        prodinfo.declareElements[i + 1].value = decls[i];
      } else {
        backinfo = '规范申报栏位与海关不一致';
      }
    }
    prodinfo.declareElements.map((tmp) => {
      if (skuls.indexOf(tmp.key) != -1) {
        if (
          tmp.value.indexOf(realmaterialPartNo) == -1 ||
          tmp.value.indexOf(realmaterialPartNo + '等') != -1
        ) {
          if (tmp.value != "无型号") {
            backinfo = '规范申报中的型号必须与物料号一致';
          }
        }
      }
    });
  }
  // if (
  //   prodinfo.mbdeclareElementsStr &&
  //   prodinfo.mbdeclareElementsStr.replace(/\|/g, '')
  // ) {
  //   var decls = prodinfo.mbdeclareElementsStr.split('|');
  //   for (var i = 0; i < prodinfo.mbdeclareElements.length - 1; i++) {
  //     if (decls.length == prodinfo.mbdeclareElements.length - 1) {
  //       prodinfo.mbdeclareElements[i + 1].value = decls[i];
  //     } else {
  //       backinfo = '规范申报(免表)栏位与海关不一致';
  //     }
  //   }
  //   // prodinfo.mbdeclareElements.map((tmp) => {
  //   //   if (skuls.indexOf(tmp.key) != -1) {
  //   //     if (
  //   //       tmp.value.indexOf(realmaterialPartNo) == -1 ||
  //   //       tmp.value.indexOf(realmaterialPartNo + '等') != -1
  //   //     ) {
  //   //       backinfo = '规范申报(免表)中的型号必须与物料号一致';
  //   //     }
  //   //   }
  //   // });
  // }
  return backinfo;
}

export function getparainfo(condition) {
  // var condition = { "field": value, "paratype": _this.selinfo.paratype };
  let localmes = sessionStorage.getItem(condition.type);
  let localpara = [];
  if (localmes) {
    localpara = JSON.parse(localmes);
  }
  // var res = localpara.filter(item => {
  //   return (item['code'].indexOf(value) != -1 || item['name'].indexOf(value) != -1);
  // }).sort(this.by('code')).slice(0, 10);

  // console.log(localmes);
  var result = null;
  var currdata = localpara.filter((drcurr) => {
    var flag = true;
    for (var key in condition) {
      flag = flag && drcurr[key].indexOf(condition[key]) != -1;
    }
    return flag;
  });
  if (currdata.length > 0) {
    result = currdata[0];
  }
  return result;
}

export const genDeclareElementOptionList = function (
  code: string,
  name: string
): KeyValue[] {
  const result: KeyValue[] = [];
  if (code && name) {
    let codeArray = code.split('|');
    let nameArray = name.split('|');
    if (codeArray.length == nameArray.length) {
      for (let index = 0; index < codeArray.length; index++) {
        result.push({ id: codeArray[index], name: nameArray[index] });
      }
    }
  }
  return result;
};

const ignorFiledsNames = [''];
/**
 * 模板应用函数
 * @param tempalte 模板数据
 * @param kddPreOrderData 数据
 */
export const applyKddTemplate = function (
  template: KddPreOrderTemplate,
  kddPreOrderData: Kddpreorder,
  custCoutry: KeyValue[],
  ciqCountry: KeyValue[],
  shangjianOnlyFlag = false
) {
  if (template) {
    const templateValue = template.tempalteValue;
    console.log('applyKddTemplate');
    if (!shangjianOnlyFlag) {
      for (const key in templateValue) {
        if (templateValue.hasOwnProperty(key)) {
          const element = templateValue[key];
          const config = <ITemplateConfig>template.templateConfig[key];
          if (
            Array.isArray(element) ||
            kddPreOrderTemplateIgnoreKeys.includes(key) ||
            kddPreOrderShangjianHeadKeys.includes(key)
          ) {
          }
          else if (key == "cusIEFlag" || key == "billType") {
            if (config && config.overwriteType) {
              kddPreOrderData[key] = element;
              if (templateValue.hasOwnProperty(key + "Name")) {
                kddPreOrderData[key + "Name"] = templateValue[key + "Name"];
              }
            }
          }
          else if (
            (element && !kddPreOrderData[key]) ||
            (config && config.overwriteType)
          ) {
            kddPreOrderData[key] = element;
            const codeKey = key.replace('Name', '');
            if (
              config &&
              config.overwriteType &&
              key.endsWith('Name') &&
              kddPreOrderData.hasOwnProperty(`${key}`) &&
              templateValue.hasOwnProperty(codeKey)
            ) {
              kddPreOrderData[codeKey] = templateValue[codeKey];
            }
            // else if(key=="cusIEFlag" || key=="billtype"){
            //   if(config)
            // }
            // console.log("key", key, element, "result", kddPreOrderData[key]);
          }
        }
      }
    }
    //商品明细 模板

    //商品明细模板

    applyShangjian(template, kddPreOrderData, custCoutry, ciqCountry);
  }
};

export const fillInStockItemMemo = function (data: Kddpreorder) {
  console.log('fillmemo');
  // const instockNotesMid = '电子化，前置C，预检验，装货港：';
  const instockNotesMid = '';
  if (data.memoInstock) {
    // var reg = /料号：((\d+[-]*\d+[|,|，]*)+|(null))/;
    var reg = /料号：((\d+[-]*\d+[|,|，]*)+|(null)|$)/;
    const instockNotes = '料号：' + data.memoInstock + '，';
    if (data.noteS) {
      if (data.noteS.match(reg)) {
        data.noteS = data.noteS.replace(reg, instockNotes);
        if (data.noteS == instockNotes) {
          data.noteS = `${instockNotes}${instockNotesMid}`;
        }
      } else {
        data.noteS = `料号：${data.memoInstock}，` + data.noteS;
      }
    } else {
      data.noteS = `料号：${data.memoInstock}，${instockNotesMid}`;
    }
  }
};

var kddPreOrderTemplateIgnoreKeys = [
  'id',
  'createTime',
  'orderNo',
  'createPerson',
  'goodsCustname',
  'appID',
  'departCode',
  'departName',
  'drawtotalAmount',
  'uploadfile',
  'mianbaoFlag',
  'stockFlag',
  'splitLeft',
  'pageindex',
  'pageurl',
  'instockSku',
  'confidence',
  'drawamount',
  'sourceProdInfo',
  'targetProdInfo',
  'templatename',
  'lastUpdateDate',
  'lastUpdatePerson',
  'productupdateDate',
  'ordertype',
  'orderOutNo',
  'sepSeqno',
  'procmessage',
  'custNo',
  'cmbCutMode',
  'storeOutNo',
  'freeFlag',
  'statusCode',
  'statusName',
  'refNo',
  "patchobj"
];

const shangjianPackType = ['23', '33', '93'];

/**
 * 商检处理 清除 增加表头字段
 */
var kddPreOrderShangjianHeadKeys = [
  'orgCode',
  'orgCodeName',
  'vsaOrgCode',
  'vsaOrgCodeName',
  'inspOrgCode',
  'inspOrgCodeName',
  'purpOrgCode',
  'purpOrgCodeName',
];
/**
 * 商检处理的formlist 字段 清除或增加
 */
var kddPreOrderShangjianListKeys = [
  // "ciqName",
  // "ciqCode",
  // "origPlaceCode",
  // "origPlaceCodeName",
  'purpose',
  'purposeName',
  'goodsAttr',
  'goodsAttrName',
];

/**
 * formlist 可以修改的字段 报关模版
 */
var kddpreorderFormlistKeys = [
  'destinationCountryName',
  'destinationCountry',
  'districtCodeName',
  'districtCode',
  'dutyModeName',
  'dutyMode',
  'ciqDestCodeName',
  'ciqDestCode',
];

export const isShangjianControl = function (
  kddPreOrderData: Kddpreorder
): boolean {
  if (kddPreOrderData.cusIEFlag == "E") {
    return false;
  }
  else {
    // X 为商品单独用HS判断的也要商检的字段
    return (
      shangjianPackType.includes(
        kddPreOrderData.wrapType ? kddPreOrderData.wrapType : ''
      ) ||
      kddPreOrderData.cmbdecMergeListVo.some(
        (ele) =>
          (ele.ControlMark &&
            (ele.ControlMark.includes('A') || ele.ControlMark.includes('L') || ele.ControlMark.includes('Z'))) || ele.codeTs.includes('8538900000')
      ) ||
      kddPreOrderData.cmbdecMergeListVo.some(
        (ele) => ele.TarifFlag && ele.TarifFlag.length > 0
      )
    );
  }
};
/**
 *
 * @param wrapType 是否商检PackNo
 */
export const isShangjianPackNo = function (
  wrapType: string,
  ieFlag = 'I'
): boolean {
  return shangjianPackType.includes(wrapType) && ieFlag == 'I';
};

export const isShangjianControlMark = function (goodsInfo: DecMergeListVo) {
  return (
    goodsInfo.ControlMark.includes('A') ||
    goodsInfo.ControlMark.includes('L') ||
    (goodsInfo.TarifFlag && goodsInfo.TarifFlag.length > 0)
  );
};
/**
 * 填充或清空商检字段
 * @param template
 * @param kddPreOrderData
 */

export const applyShangjian = function (
  template: KddPreOrderTemplate,
  kddPreOrderData: Kddpreorder,
  custCoutry: KeyValue[],
  ciqCountry: KeyValue[]
) {
  //表体只允许更新确定几个字段
  var decmergekey = [
    'destinationCountryName',
    'destinationCountry',
    'districtCodeName',
    'districtCode',
    'ciqDestCodeName',
    'ciqDestCode',
    'dutyModeName',
    'dutyMode',
    'purpose',
    'purposeName',
    'goodsAttr',
    'goodsAttrName',
  ];

  const shangjianFlag = isShangjianControl(kddPreOrderData);
  // if (isShangjianControl(kddPreOrderData)) {

  kddPreOrderShangjianHeadKeys.forEach((key) => {
    // if (template.tempalteValue && template.tempalteValue[key]) {
    //   kddPreOrderData[key] = shangjianFlag ? template.tempalteValue[key] : '';
    // }
    if (template.tempalteValue && template.tempalteValue[key]) {
      kddPreOrderData[key] = shangjianFlag ? template.tempalteValue[key] : '';
    }
  });

  if (
    template &&
    template.tempalteValue &&
    template.tempalteValue.cusLicenseListVo &&
    template.tempalteValue.cusLicenseListVo.length > 0
  ) {
    if (
      !kddPreOrderData.cusLicenseListVo ||
      kddPreOrderData.cusLicenseListVo.length == 0
    ) {
      kddPreOrderData.cusLicenseListVo = fetchchange(
        template.tempalteValue.cusLicenseListVo
      );
    }
  }


  if (shangjianFlag) {
    if (!kddPreOrderData.preDecEntQualifListVo) {
      kddPreOrderData.preDecEntQualifListVo = [];
    }

    if (!kddPreOrderData.preDecEntQualifListVo.some(et => { return et.entQualifTypeCode == "101040" })) {
      kddPreOrderData.preDecEntQualifListVo.push({
        "entQualifTypeCode": "101040",
        "entQualifTypeCodeName": "合格保证"
      })
    }
  }
  else {
    if (!kddPreOrderData.preDecEntQualifListVo) {
      kddPreOrderData.preDecEntQualifListVo = [];
    }

    kddPreOrderData.preDecEntQualifListVo = kddPreOrderData.preDecEntQualifListVo.filter(et => {
      return et.entQualifTypeCode != "101040"
    })
  }



  kddPreOrderData.cmbdecMergeListVo.map((ele) => {
    // 报关单字段
    if (template.templateConfig) {
      kddpreorderFormlistKeys.forEach((key) => {
        const config = <ITemplateConfig>template.templateConfig["cmbdecMergeListVo@0@" + key];
        if (
          template &&
          template.tempalteValue &&
          template.tempalteValue.cmbdecMergeListVo &&
          template.tempalteValue.cmbdecMergeListVo.length > 0 &&
          (!!!ele[key] || (config && config.overwriteType))
        ) {
          if (decmergekey.indexOf(key) != -1) {
            //更新可以更新的值
            ele[key] = template.tempalteValue.cmbdecMergeListVo[0][key];
            const codeKey = key.replace('Name', '');
            if (template.tempalteValue.cmbdecMergeListVo[0][codeKey]) {
              ele[codeKey] = template.tempalteValue.cmbdecMergeListVo[0][codeKey];
            }
            else {
              ele[codeKey] = "";
            }
          }
        }
      });
    }
    kddPreOrderShangjianListKeys.map((key1) => {
      if (
        (template &&
          template.tempalteValue &&
          template.tempalteValue.cmbdecMergeListVo &&
          template.tempalteValue.cmbdecMergeListVo.length > 0) ||
        !shangjianFlag
      ) {
        console.log('shangjianlist', key1);
        if (shangjianFlag && !!!ele[key1]) {
          ele[key1] = template.tempalteValue.cmbdecMergeListVo[0][key1];
        } else if (!shangjianFlag) {
          ele[key1] = '';
        }
      } else {
        ele[key1] = '';
      }
    });
    //if (oml.TarifFlag && oml.TarifFlag.indexOf("L") != -1) { 12

    if (kddPreOrderData.cusIEFlag != "E") {
      var specCCC = ['8538900000','8518400090','8504401400'];
      if (
        (ele.TarifFlag && ele.TarifFlag.indexOf('L') != -1) ||
        specCCC.indexOf(ele.codeTs) != -1
      ) {
        if (!ele.goodsAttrName || ele.goodsAttrName.indexOf('3C目录外') == -1) {
          var gatr = ele.goodsAttr.split(',').filter((a) => {
            return a;
          });
          gatr.push('12');
          var gatrName = ele.goodsAttrName.split(',').filter((a) => {
            return a;
          });
          gatrName.push('3C目录外');
          ele.goodsAttr = gatr.join(',');
          ele.goodsAttrName = gatrName.join(',');
        }
      } else {
        ele.goodsAttr = ele.goodsAttr.replace('12', '').replace('12,', '');
        ele.goodsAttrName = ele.goodsAttrName
          .replace('3C目录外', '')
          .replace('3C目录外,', '');
      }
    }
    /**
     * 商检原产国 增加对应关系
     *
     */
    console.log('shangjian originCountry');

    const countryCode = ele.cusOriginCountry;
    if (
      countryCode &&
      !!!ele.origPlaceCode &&
      custCoutry &&
      ciqCountry &&
      shangjianFlag
    ) {
      const countryPara = custCoutry.find((ele) => ele.id == countryCode);
      if (countryPara && countryPara.values.ciqCode) {
        const ciqCountryPara = ciqCountry.find(
          (ele) => ele.id == countryPara.values.ciqCode
        );
        ele.origPlaceCode = ciqCountryPara.id;
        ele.origPlaceCodeName = ciqCountryPara.name;
      }
    } else {
      if (!shangjianFlag) {
        ele.origPlaceCode = '';
        ele.origPlaceCodeName = '';
      }
    }

    //检验检疫货物规格
    if (shangjianFlag && kddPreOrderData.tradeModeCode == '0110') {
      console.log('检验检疫货物规格');
      ele.goodsModel = ele.sku;
      if (ele.extendjson && ele.extendjson.declareElements) {
        var brand = ele.extendjson.declareElements.filter((dl) => {
          return dl.key == '品牌（中文及外文名称）';
        });
        if (brand.length > 0) {
          ele.goodsBrand = brand[0].value;
        }
      }
      ele.produceDate = manualfomatedate(
        new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
        'yyyy-MM-dd'
      );
    } else {
      ele.goodsModel = '';
      ele.goodsBrand = '';
      ele.produceDate = '';
    }

    // kddPreOrderData.cmbdecMergeListVo.map(prod => {
    //   const countryCode = prod.cusOriginCountry;
    //   if (
    //     countryCode &&
    //     !!!prod.origPlaceCode &&
    //     custCoutry &&
    //     ciqCountry &&
    //     shangjianFlag
    //   ) {
    //     const countryPara = custCoutry.find(ele => ele.id == countryCode);
    //     if (countryPara && countryPara.value.ciqCode) {
    //       const ciqCountryPara = ciqCountry.find(
    //         ele => ele.id == countryPara.value.ciqCode
    //       );
    //       prod.origPlaceCode = ciqCountryPara.id;
    //       prod.origPlaceCodeName = ciqCountryPara.name;
    //     }
    //   } else {
    //     if (!shangjianFlag) {

    //       prod.origPlaceCode = "";
    //       prod.origPlaceCodeName = "";

    //     }
    //   }
    // });
  });
  // }
};



export const freshorigPlace = function (dec, custCoutry, ciqCountry, shangjianFlag) {
  const countryCode = dec.cusOriginCountry;
  if (
    countryCode &&
    custCoutry &&
    ciqCountry &&
    shangjianFlag
  ) {
    const countryPara = custCoutry.find((ele) => ele.id == countryCode);
    if (countryPara && countryPara.value.ciqCode) {
      const ciqCountryPara = ciqCountry.find(
        (ele) => ele.id == countryPara.value.ciqCode
      );
      dec.origPlaceCode = ciqCountryPara.id;
      dec.origPlaceCodeName = ciqCountryPara.name;
    }
  } else {
    if (!shangjianFlag) {
      dec.origPlaceCode = '';
      dec.origPlaceCodeName = '';
    }
  }
}

export const unifyproddot = function (prod: any) {
  // ，：； 全角  （ 用半角
  var nameCn = prod.nameCn;
  if (nameCn) {
    nameCn = nameCn.replace(/（/g, '(').replace(/）/g, ')');
    nameCn = nameCn.replace(/,/g, '，').replace(/:/g, '：').replace(/;/g, '；').replace(/\s/g, '');
    prod.nameCn = nameCn;
  }
};

export interface IWeichatUserModel {
  openID: string; // id
  appID: string; // 公众号ID
  QRID: string; // 当前登录SessionQRID
  kuai00SocketID: string; // 当前socketID
  electronSign: string; // 快点查客户端ID
  activeDate?: Date;
  createDate?: Date;
  nickName?: string;
  headImgUrl?: string;
  companyName?: string;
  name?: string;
  UserName?: string;
  allUserFlag: boolean;
}

export class User implements IWeichatUserModel {
  _id: '';
  openID: 'pls login';
  QRID: 'pls login';
  kuai00SocketID: '';
  electronSign: '';
  appID: '';
  allUserFlag: false;
  companyName: '';
  name: '';
  headImgUrl: '';
  roles: [];
  departments: any;
}

//gql返回数据readonly并且带typename
export const fetchchange = function (obj: any) {
  if (!obj) {
    obj = [];
  }
  if (typeof obj != 'object') {
    try {
      obj = JSON.parse(obj);
    } catch (err) {
      return obj;
    }
  }
  obj = JSON.parse(JSON.stringify(obj));
  if (Array.isArray(obj)) {
    for (var j = 0; j < obj.length; j++) {
      detypename(obj[j]);
    }
  } else {
    detypename(obj);
  }
  return obj;
};


export const distinct = function (arr: any) {
  var result = [];
  var len = arr.length;
  for (var i = 0; i < len; i++) {
    for (var j = i + 1; j < len; j++) {
      if (arr[i] === arr[j]) {
        j = ++i;
      }
    }
    result.push(arr[i]);
  }
  return result;
}

export const detypename = function (obj: any) {
  for (var key in obj) {
    if (key == '__typename') {
      delete obj[key];
    } else if (typeof obj[key] == 'object') {
      if (Array.isArray(obj[key])) {
        for (var i = 0; i < obj[key].length; i++) {
          detypename(obj[key][i]);
        }
      } else {
        detypename(obj[key]);
      }
    }
  }
};

export const dfomatedate = function (ddate) {
  return formatDate(new Date(ddate), 'yyyy-MM-dd HH:mm:ss', 'zh-cn');
  // return ddate;
};

export const manualfomatedate = function (ddate, formate) {
  return formatDate(ddate, formate, 'zh-cn');
  // return ddate;
};


export const caltotal = function (kddinfo, detailkey) {
  var drawtotalAmount = (<Array<any>>(
    kddinfo[detailkey]
  )).reduce<number>(
    (a, b) => a + (b.declTotal ? parseFloat(b.declTotal.toString()) : 0),
    0
  );
  return drawtotalAmount;

}

export const gencondition = function(value) {
  console.log('fromCondtion', value);
  const result = [];
  for (const key in value) {
    let codiditionKeyValue: KeyValue;
    if (value.hasOwnProperty(key) && value[key]) {
      switch (typeof value[key]) {
        case 'object':
          {
          }
          for (var subsky in value[key]) {
            let dcodiditionKeyValue: any = {
              id: key,
              values: {},
            };
            dcodiditionKeyValue['values'][subsky] = value[key][subsky];
            result.push(dcodiditionKeyValue);
          }

          break;
        default:
          //string
          codiditionKeyValue = {
            id: key,
            name: JSON.stringify({
              $regex: `${value[key]}`,
              $options: 'i',
            }),
          };
          result.push(codiditionKeyValue);
      }
    }
  }
  return result;
}


// export const  getCondtionSubKey= function(key) {
//   const col = this.colData.find((ele) => ele.key === key);
//   if (col && col.colType === 'JSON' && col.subKey) {
//     return `${key}.${col.subKey}`;
//   } else {
//     return key;
//   }
// }

export const getLocalmachine = function (callback) {
  const url = 'ws://127.0.0.1:3125'
  const connection = new WebSocket(url)

  connection.onopen = () => {
    connection.send('connected')
  }

  connection.onerror = (error) => {
    console.log(`WebSocket error: ${error}`)
    // alert("请启动aeo客户端(小蜜蜂)进行操作");
    alert("本地小蜜蜂未启动，将随机调用");
    callback("");
  }

  connection.onmessage = (e) => {
    console.log(e.data)
    callback(e.data);
  }
}



export const sendPrint = function (printinfo, callback) {
  const url = 'ws://127.0.0.1:3125'
  const connection = new WebSocket(url)

  connection.onopen = () => {
    connection.send(printinfo)
  }

  connection.onerror = (error) => {
    console.log(`WebSocket error: ${error}`)
    alert("请启动aeo客户端(小蜜蜂)进行操作");
    // callback("");
  }

  connection.onmessage = (e) => {
    console.log(e.data)
    callback(e.data);
  }
}

// export const genSerialCode = function (prefix) {
//   var randnum = Math.floor(Math.random() * 1000);
//   return prefix + randnum;
// }
