import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { LogInComponent } from './pages/log-in/log-in.component';
import { LoginComponent } from '@ternsorinfo/shared/login';
import { environment } from '../environments/environment';

import { AuthGuard } from '@ternsorinfo/shared/backbase';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {
      redirectUri: environment.redirectUri,
      appID: environment.appID,
      dingAppId: environment.dingAppId,
      title: environment.title,
      logo: environment.logo,
    },
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/main',
    // canActivate: [AuthGuard],
  },
  {
    path: 'main',
    loadChildren: () =>
      import('./pages/welcome/welcome.module').then((m) => m.WelcomeModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'tms-cust',
    loadChildren: () =>
      import('@tensorinfo/biz/tms/tms-cust-ag-lib').then(
        (m) => m.BizTmsTmsCustAgLibModule
      ),
    canActivate: [AuthGuard],
  },
];
// tslint:disable-next-line: no-unused-expression

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
