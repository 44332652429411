

<div class="loginBox"> 
</div>
  <div class="loginMain">
    <div class="tensor"> 
      <!-- <img src="/assets/img/comacLogo.png" class="comacLogo-img" alt="" srcset="">    -->
      <img  [src]="logo"  class="samc_logo" alt="" srcset=""> 
    </div>
    <p class="loginMain-h2">{{ title }} </p>
    
    <!-- <nz-tabset class="loginTsb" [nzSelectedIndex]=1> -->
      <!-- <nz-tab nzTitle="账号登录"> 
    <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
      <nz-form-item>
        <nz-form-control>
          <nz-input-group [nzPrefix]="prefixUser">
            <input type="text" nz-input formControlName="userName" placeholder="Username">
          </nz-input-group>
          <nz-form-explain *ngIf="validateForm.get('userName').dirty && validateForm.get('userName').errors">请输入用户名!</nz-form-explain>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control>
          <nz-input-group [nzPrefix]="prefixLock">
            <input type="password" nz-input formControlName="password" placeholder="Password">
          </nz-input-group>
          <nz-form-explain *ngIf="validateForm.get('password').dirty && validateForm.get('password').errors">请输入密码！</nz-form-explain>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item> -->
        
        <!-- <nz-form-control>
          <button nz-button class="login-form-button" [nzType]="'primary'">登录</button>
        </nz-form-control>  
      </nz-form-item>
    </form>
    <ng-template #prefixUser><i nz-icon type="user"></i></ng-template>
    <ng-template #prefixLock><i nz-icon type="lock"></i></ng-template>
      </nz-tab> -->
      <!-- <nz-tab nzTitle="钉钉">
        <ng-template nz-tab> -->
          <nz-demo-tab-content-lazy [environment]='config'></nz-demo-tab-content-lazy>
      <!-- </ng-template>
      </nz-tab> -->
    <!-- </nz-tabset> -->
  </div>
<div class="footer">
</div>

