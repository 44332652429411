export interface IDecDicStaus {
  statusName: string;
  statusCode: number;
  statusDesc: string;
}
export const statusDic: { [key: string]: IDecDicStaus } = {
  委托初审结束: {
    statusName: '委托初审结束',
    statusCode: 9,
    statusDesc: '委托初审结束',
  },
  处理中: {
    statusName: '处理中',
    statusCode: 12,
    statusDesc: '处理中',
  },
  待制单: {
    statusName: '待制单',
    statusCode: 20,
    statusDesc: '待制单',
  },
  制单中: {
    statusName: '制单中',
    statusCode: 25,
    statusDesc: '制单中',
  },
  退回: {
    statusName: '退回',
    statusCode: 26,
    statusDesc: '退回',
  },
  初审提交: {
    statusName: '初审提交',
    statusCode: 27,
    statusDesc: '初审提交',
  },
  初审结束: {
    statusName: '初审结束',
    statusCode: 28,
    statusDesc: '初审结束',
  },
  待预录: {
    statusName: '待预录',
    statusCode: 30,
    statusDesc: '待预录',
  },
  预录中: {
    statusName: '预录中',
    statusCode: 33,
    statusDesc: '预录中',
  },
  复审退回: {
    statusName: '复审退回',
    statusCode: 35,
    statusDesc: '复审退回',
  },
  复审提交: {
    statusName: '复审提交',
    statusCode: 36,
    statusDesc: '复审提交',
  },
  复审结束: {
    statusName: '复审结束',
    statusCode: 37,
    statusDesc: '复审结束',
  },
  委托复审结束: {
    statusName: '委托复审结束',
    statusCode: 38,
    statusDesc: '委托复审结束',
  },
  对照: {
    statusName: '对照',
    statusCode: 47,
    statusDesc: '对照',
  },
  发送中: {
    statusName: '发送中',
    statusCode: 53,
    statusDesc: '发送中',
  },
  发送失败: {
    statusName: '发送失败',
    statusCode: 55,
    statusDesc: '发送中',
  },
  发送成功: {
    statusName: '发送成功',
    statusCode: 58,
    statusDesc: '发送中',
  },
  已入库: {
    statusName: '已入库',
    statusCode: 60,
    statusDesc: '已入库',
  },
};

export const tmsstatusDic: { [key: string]: IDecDicStaus } = {
  暂存: {
    statusName: '暂存',
    statusCode: 20,
    statusDesc: '暂存',
  },
  审核提交: {
    statusName: '审核提交',
    statusCode: 40,
    statusDesc: '审核提交',
  },
  审核退回: {
    statusName: '审核退回',
    statusCode: 45,
    statusDesc: '审核退回',
  },
  初审结束: {
    statusName: '初审结束',
    statusCode: 50,
    statusDesc: '初审结束',
  },
  主管审核结束: {
    statusName: '主管审核结束',
    statusCode: 55,
    statusDesc: '主管审核结束',
  },
  部门审批结束: {
    statusName: '部门审批结束',
    statusCode: 60,
    statusDesc: '部门审批结束',
  },
  运输室审批通过: {
    statusName: '运输室审批通过',
    statusCode: 70,
    statusDesc: '运输室审批通过',
  },
  翔运接单: {
    statusName: '翔运接单',
    statusCode: 80,
    statusDesc: '翔运接单',
  },
  分派完成: {
    statusName: '分派完成',
    statusCode: 85,
    statusDesc: '分派完成',
  },
  已委托: {
    statusName: '已委托',
    statusCode: 90,
    statusDesc: '已委托',
  },
  供应商接单: {
    statusName: '供应商接单',
    statusCode: 100,
    statusDesc: '供应商接单',
  },
  派车完成: {
    statusName: '派车完成',
    statusCode: 105,
    statusDesc: '派车完成',
  },
  车辆已确认: {
    statusName: '车辆已确认',
    statusCode: 110,
    statusDesc: '车辆已确认',
  },
  司机已确认: {
    statusName: '司机已确认',
    statusCode: 120,
    statusDesc: '司机已确认',
  },
  空车待就位: {
    statusName: '空车待就位',
    statusCode: 130,
    statusDesc: '空车待就位',
  },
  运输中: {
    statusName: '运输中',
    statusCode: 135,
    statusDesc: '运输中',
  },
  运输已完成: {
    statusName: '运输已完成',
    statusCode: 140,
    statusDesc: '运输已完成',
  },
};

/**
 * 一体化业务单证操作状态
 */
export const tmsBusinessNoDic: { [key: string]: IDecDicStaus } = {
  初始: {
    statusName: '初始',
    statusCode: 0,
    statusDesc: '初始',
  },
  未接单: {
    statusName: '未接单',
    statusCode: 10,
    statusDesc: '未接单',
  },
  业务清单已接单: {
    statusName: '业务清单已接单',
    statusCode: 20,
    statusDesc: '业务清单已接单',
  },
  业务清单已完成: {
    statusName: '业务清单已完成',
    statusCode: 30,
    statusDesc: '已完成',
  },
  一体化业务单证已接单: {
    statusName: '一体化业务单证已接单',
    statusCode: 40,
    statusDesc: '一体化业务单证已接单',
  },
  一体化业务单证已完成: {
    statusName: '一体化业务单证已完成',
    statusCode: 50,
    statusDesc: '一体化业务单证已完成',
  },

  

  
  


  已取消: {
    statusName: '已取消',
    statusCode: 90,
    statusDesc: '已取消',
  },
  异常: { statusName: '异常', statusCode: 99, statusDesc: '异常' },

};
