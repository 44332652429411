import { IConditionFormControl, ConditionControlType } from './component-types';
import { NzTableFilterFn, NzTableFilterList, NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';

export class SearchConditionBase<T> {
  value: T;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: string;
  type: string;
  options: { key: string; value: string }[];

  constructor(
    options: {
      value?: T;
      key?: string;
      label?: string;
      required?: boolean;
      order?: number;
      controlType?: string;
      type?: string;
    } = {}
  ) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
  }
}

export interface ITableColumnDef extends IConditionFormControl {
  key: string;
  caption: string;
  colWidth: number;
  colType?: string;
  subKey?: string;
  order: number;
  displayLen?: number;
  keyField?: boolean;
  displayPipe?: string;// 显示格式

  sortOrder?: NzTableSortOrder | null;
  sortFn?: NzTableSortFn | null;
  listOfFilter?: NzTableFilterList;
  filterFn?: NzTableFilterFn | null;
  filterMultiple?: boolean;
  sortDirections?: NzTableSortOrder[];
}
export function formConditionFromConfig(ele: ITableColumnDef) {
  let result: SearchConditionBase<string>;
  switch (ele.controlType) {
    case ConditionControlType.Textbox:
      result = Object.assign(new TextboxConditon({}), ele);
      break;
    case ConditionControlType.DropDownBox:
      result = Object.assign(new DropdownCondition(ele.options), ele);
      break;
    default:
      result = Object.assign(new TextboxConditon({}), ele);
  }
  return result;
}

export class TextboxConditon extends SearchConditionBase<string> {
  controlType = 'textbox';
  type: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
  }
}

export class DropdownCondition extends SearchConditionBase<string> {
  controlType = 'dropdown';
  options: { key: string; value: string }[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
  }
}
