import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { IconsProviderModule } from './icons-provider.module';

import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { zh_CN } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';

import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { RouteReuseStrategy } from '@angular/router';
import { RouterReuseService } from '@ternsorinfo/shared/backbase';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { GraphQLModule } from './graphql.module';
import { SocketIoModule } from 'ngx-socket-io';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

registerLocaleData(zh);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    FormsModule,
    NzButtonModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NzTableModule,
    NzDividerModule,
    NzInputModule,
    NzSelectModule,
    NzTabsModule,
    NzFormModule,
    NzAvatarModule,
    NzDropDownModule,
    NzDrawerModule,
    NzMessageModule,
    NzModalModule,
    NzSwitchModule,
    NzNotificationModule,
    NzModalModule,
    GraphQLModule,
    SocketIoModule.forRoot({
      url: '/',
      options: {},
      // HandleKoaGenericSession
    }),
  ],
  providers: [
    { provide: NZ_I18N, useValue: zh_CN },
    { provide: RouteReuseStrategy, useClass: RouterReuseService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
