import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TiSocketService } from '@ternsorinfo/shared/helper';
import { AppAppNameGQLService } from '@ternsorinfo/gql-service';
import { HIGH_CONTRAST_MODE_ACTIVE_CSS_CLASS } from '@angular/cdk/a11y/high-contrast-mode/high-contrast-mode-detector';

declare var DDLogin: any;

interface IAppEnvironment {
  appID: string;
  redirectUri: string;
  dingAppId: string;
  title: string;
  logo:string;
}
@Component({
  selector: 'ternsorinfo-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
})
// 钉钉js引用
export class LoginComponent implements OnInit {
  config: IAppEnvironment;
  returnUrl:string;
  title="";
  logo="";
  background="";
  constructor(
    private fb: FormBuilder,
    // private loginService: AuthenticationService,
    private messageService: NzMessageService,
    private activatedRoute: ActivatedRoute,
    private socketService: TiSocketService,
    private route: ActivatedRoute,
    private router: Router //  private router: Router // private socketService: SocketService, // private logger: NGXLogger
  ) {
    this.config = <IAppEnvironment>this.activatedRoute.snapshot.data;
    console.log('config', this.config);
    this.title=this.config.title;
    this.logo=this.config.logo;


    // this.router.routeReuseStrategy.shouldReuseRoute = function () {
    //   return false;
    // };
    // this.activatedRoute.params.subscribe(val => {
    //   console.log('para',val)

    //   this.ngOnInit()
    //   // put the code from `ngOnInit` here
    // });
    // if (this.loginService.currentUserValue) {
    //   this.logger.debug('redirct to /')
    //   this.router.navigate(['/']);
    // }
  
  }

  validateForm: FormGroup;
  /**
   * 二维码地址
   */
  qrsrc: string;

  @HostListener('window:message', ['$event'])
  handleMessage(event) {
    const origin = event.origin;
    console.log('origin', event.origin);
    if (origin == 'https://login.dingtalk.com') {
      //判断是否来自ddLogin扫码事件。
      const loginTmpCode = event.data;
      //获取到loginTmpCode后就可以在这里构造跳转链接进行跳转了

      console.log('loginTmpCode', loginTmpCode);
      this.socketService
        .dingSNSauth(loginTmpCode, this.config.appID)
        .subscribe((res) => {
          console.log('sns', res);
          this.router.navigate([this.returnUrl]);
        });

      //得到用户信息
    }
  }
  // wechatSocketSub: Subscription;
  // messageSocketSub: Subscription;
  ngOnDestroy(): void {
    // throw new Error("Method not implemented.");
    // this.wechatSocketSub.unsubscribe();
    // this.messageSocketSub.unsubscribe();
  }

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.status === 'VALID') {
      // this.validateForm.controls["userName"].status;
      // this.loginService
      //   .login(
      //     this.validateForm.controls["userName"].value,
      //     this.validateForm.controls["password"].value
      //   )
      //   .pipe(first())
      //   .subscribe(
      //     data => {
      //       this.logger.debug("login in login ", data);
      //       this.router.navigate(["/"]);
      //     },
      //     error => {
      //       this.messageService.error(error);
      //     }
      //   );
    } else {
      this.messageService.error('检查输入！');
    }
  }
  qrcodeLogin() {}

  ngOnInit(): void {
    // this.loginService.logout().subscribe(res => {
    //   this.logger.debug(`logout${res}`);
    // });

    this.validateForm = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]],
      remember: [true],
    });


    // 服务器端注销
    console.log('login init');
    this.socketService.logout(this.config.appID).subscribe((res) => {
      console.log('logout', res);
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    console.log('return url ',this.returnUrl)

    // this.wechatSocketSub = this.socketService.onLoginMessage().subscribe(
    //   data => {
    //     this.logger.debug(data);
    //     if (data) {

    //     } else {
    //       this.messageService.error("登录失败！");
    //     }
    //   },
    //   error => {
    //     this.messageService.error(error);
    //   }
    // );
    // this.messageSocketSub = this.socketService
    //   .onCommonMessage()
    //   .subscribe(data => {
    //     // console.log(data);
    //   });
    // this.loginService.getQRSrc().subscribe(data => {
    //   this.qrsrc = data;
    //   this.socketService.reConect();

    //   this.socketService.commonSendMessage("sessionok", "sessionok");
    //   //  this.socket.emit('sessionok','sessonok');
    // });
    // const scanUrl= ''
    // const url = encodeURIComponent(scanUrl);
    // const dingAppId=''

    // const goto = encodeURIComponent(
    //   `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${dingAppId}&response_type=code&scope=snsapi_login&state=${'TESTSTATE'}&redirect_uri=${url}`
    // );
    // var obj = DDLogin({
    //   id: 'login_container', //这里需要你在自己的页面定义一个HTML标签并设置id，例如<div id="login_container"></div>或<span id="login_container"></span>
    //   goto: goto,
    //   style: 'border:none;background-color:#FFFFFF;',
    //   width: '365',
    //   height: '400',
    // });
  }
}

@Component({
  selector: 'nz-demo-tab-content-lazy',
  template: ` <div id="login_container"></div> `,
})
export class NzDemoTabContentLazyComponent implements OnInit {
  @Input() environment: IAppEnvironment;
  ngOnInit(): void {
    console.log(`I will init when tab active`, this.environment);

    const scanUrl = this.environment.redirectUri;
    const url = encodeURIComponent(scanUrl);
    const dingAppId = this.environment.dingAppId;

    const goto = encodeURIComponent(
      `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${dingAppId}&response_type=code&scope=snsapi_login&state=${'TESTSTATE'}&redirect_uri=${url}`
    );
    var obj = DDLogin({
      id: 'login_container', //这里需要你在自己的页面定义一个HTML标签并设置id，例如<div id="login_container"></div>或<span id="login_container"></span>
      goto: goto,
      style: 'border:none;background-color:#fff;',
      width: '360',
      height: '290',
    });
  }
}
