import { kddpreorderDetailComponent } from '@ternsorinfo/shared/cplexcomponents';
import {
  SpecialTypeClass,
  Kddpreorder,
  WareHouse,
  SpecialTypeTemplate,
} from './data';

// export class SpecialTypeTemplate {
//   baoguanTemplate: Kddpreorder;

//   warehouseTemplate: WareHouse;
//   templateName: string;
// }

export const SpecialTypeClassTemplate = new Map<
  string,
  SpecialTypeTemplate[]
>();

SpecialTypeClassTemplate.set('一线进祝桥', [
  {
    templateName: '一线料件进境祝桥',
    warehouseTemplate: {
      // templateDefNe: '一线进境浦东祝桥',
      inExpType: '1', //一线进出
      businessTypecd: '3', //保税加工,
      mtpckEndprdTypecd: 'I', //料件
      ieTypecd: 'I', //进区
      supvModecd: '5015', //区内进料加工货物
      dclcusFlag: '2', //是 是否报关
      dclcusTypecd: '2', // 对应报关 报关类型
      districtCode: '3122S', // 境内目的地 洋山特殊综合保税区

      destinationNatcd: '142', //中国 最终目的国
      bizopEtpsNm: '上海飞机制造有限公司', // 经营企业
      bizopEtpsno: '3122S600J4', // 经营企业编码
      bizopEtpsSccd: '91310000132612172J', //   企业信用代码
      dclTypecd: '1', //确认类型 备案
      // 特殊业务类型
      allocationMode: '', //
    },
    baoguanTemplate: {
      billType: '1', // 进出境备案清单
      cusIEFlag: 'I', // 进境报关单
      customMasterName: '洋山特综',

      customMaster: '2249',
      consigneeCname: '上海飞机制造有限公司', //境内收发货人
      consigneeCode: '3100707436',
      rcvgdTradeCode: '3122S600J4',
      rcvgdTradeScc: '91310000132612172J',

      ownerName: '上海飞机制造有限公司',
      ownerScc: '91310000132612172J',
      ownerCiqCode: '3100707436',
      ownerCode: '311291D908', //

      tradeModeCodeName: '区内进料加工货物', //贸易方式
      tradeModeCode: '5015',

      purpOrgCode: '',
      agentName: '上海翔运国际货运有限公司', //
      agentCode: '3109980208',
      agentScc: '91310109132269327E',
      declRegNo: '3100700361',
      cmbdecMergeListVo: [
        {
          destinationCountryName: '中国',
          destinationCountry: 'CHN',
          districtCodeName: '洋山特殊综合保税区',
          districtCode: '3122S',
          ciqDestCodeName: '上海市浦东新区',
          ciqDestCode: '310115',
          dutyModeName: '全免',
          dutyMode: '3',
        },
      ],
      cusLicenseListVo: [
        {
          acmpFormNo: '104622WH********',
          acmpFormName: '提发货凭证',
          acmpFormCode: 'T',
          acmpFormCodeName: '提发货凭证',
        },
      ],
    },
  },
]);

/**
 * 一线进大场
 * 先报进仓 进大场2207保税仓
 * 再做区间流转
 */
SpecialTypeClassTemplate.set('一线进大场', [
  {
    templateName: '进大场保税仓库',
    warehouseTemplate: null, //无提发货凭证
    baoguanTemplate: {
      billType: '', //
      cusIEFlag: 'I', // 进口报关单
      customMasterName: '洋山海关',
      customMaster: '2207',
      consigneeCname: '上海飞机制造有限公司', //境内收发货人
      consigneeCode: '3100707436',
      rcvgdTradeCode: '3122S600J4',
      rcvgdTradeScc: '91310000132612172J',

      manualNo: 'L2207D22A001', //备案号

      ownerName: '上海飞机制造公用型保税仓库',
      ownerScc: '91310000132612172J',
      ownerCiqCode: '3100707436',
      ownerCode: '311291D908', //

      tradeModeCodeName: '保税仓库货物', //贸易方式
      tradeModeCode: '1233',
      purpOrgCodeName: '', //检验检疫受理机关
      purpOrgCode: '',

      orgCodeName: '', //目的地检验检疫机关
      orgCode: '', //目的地检验检疫机关

      agentName: '上海翔运国际货运有限公司', //
      agentCode: '3109980208',
      agentScc: '91310109132269327E',
      declRegNo: '3100700361',
      cmbdecMergeListVo: [
        {
          destinationCountryName: '中国',
          destinationCountry: 'CHN',
          districtCodeName: '静安',
          districtCode: '31069',
          ciqDestCodeName: '上海市静安区',
          ciqDestCode: '310106',
          dutyModeName: '全免',
          dutyMode: '3',
        },
      ],
      cusLicenseListVo: [
        {
          acmpFormNo: 'QD2207',
          acmpFormName: '保税核注清单',
          acmpFormCode: 'a',
          acmpFormCodeName: '保税核注清单',
        },
      ],
    },
  },
  {
    templateName: '区间流转进特综大场',
    warehouseTemplate: {
      // destTargetStock: { id: '1', name: '大场基地' },
      // templateDefName: '一线进大场保税仓',
      // transitWarehouse: null,
      inExpType: '5', // 区间流转
      businessTypecd: '3', //保税加工,
      mtpckEndprdTypecd: 'I', //料件
      ieTypecd: 'I', //进区
      supvModecd: '5015', //区内进料加工货物
      dclcusFlag: '2', //是 是否报关
      dclcusTypecd: '2', // 对应报关 报关类型
      districtCode: '3122S', // 境内目的地 洋山特殊综合保税区

      destinationNatcd: '142', //中国 最终目的国
      bizopEtpsNm: '上海飞机制造有限公司', // 经营企业
      bizopEtpsno: '3122S600J4', // 经营企业编码
      bizopEtpsSccd: '91310000132612172J', //   企业信用代码
      dclTypecd: '1', //确认类型 备案
      // 特殊业务类型
      allocationMode: '', //
    },
    baoguanTemplate: {
      cusIEFlag: 'E', // 出口报关单
      customMasterName: '洋山海关',
      customMaster: '2207',
      consignorCname: '上海飞机制造有限公司', //
      cnsnTradeScc: '91310000132612172J',
      cnsnTradeCode: '3122S600J4',

      manualNo: 'L2207D22A001', //备案号

      ownerName: '上海飞机制造公用型保税仓库',
      ownerScc: '91310000132612172J',
      ownerCiqCode: '3100707436',
      ownerCode: '311291D908', //

      tradeModeCodeName: '保税间货物', //贸易方式
      tradeModeCode: '1200',

      agentName: '上海翔运国际货运有限公司', //
      agentCode: '3109980208',
      agentScc: '91310109132269327E',
      declRegNo: '3100700361',
      cusTrafModeName: '特殊综合保税区', // 运输方式
      cusTrafMode: 'S',
      cusTradeCountryName: '中国', // 运抵国
      cusTradeCountry: 'CHN',
      cusTradeNationCodeName: '中国', //贸易国
      cusTradeNationCode: 'CHN',

      distinatePortName: '中国境内', //??? 离境口岸

      distinatePort: 'CHN000', //起运港
      transModeName: 'FOB',
      despPortCodeName: '上海', //离境口岸
      despPortCode: '310001', //离境口岸

      cmbdecMergeListVo: [
        {
          destinationCountryName: '中国', //最终目的国
          destinationCountry: 'CHN', //最终目的国
          districtCodeName: '静安', //境内货源地
          ciqDestCode: '310106',
          dutyModeName: '全免',
          dutyMode: '3',
        },
      ],
      cusLicenseListVo: [
        {
          acmpFormNo: 'QD2207',
          acmpFormName: '保税核注清单',
          acmpFormCode: 'a',
          acmpFormCodeName: '保税核注清单',
        },
        {
          acmpFormNo: '104623WH********',
          acmpFormName: '提发货凭证',
          acmpFormCode: 'T',
          acmpFormCodeName: '提发货凭证',
        },
      ],
    },
  },
]);
/**
 * 区间流转进大场?直接做第二部就行了?还是要做两步?
 */
SpecialTypeClassTemplate.set('区间流转进大场', [
  {
    templateName: '进大场保税仓库',
    warehouseTemplate: null, //无提发货凭证
    baoguanTemplate: {
      billType: '', //
      cusIEFlag: 'I', // 进口报关单
      customMasterName: '洋山海关',
      customMaster: '2207',
      consigneeCname: '上海飞机制造有限公司', //境内收发货人
      consigneeCode: '3100707436',
      rcvgdTradeCode: '3122S600J4',
      rcvgdTradeScc: '91310000132612172J',

      manualNo: 'L2207D22A001', //备案号

      ownerName: '上海飞机制造公用型保税仓库',
      ownerScc: '91310000132612172J',
      ownerCiqCode: '3100707436',
      ownerCode: '311291D908', //

      tradeModeCodeName: '保税仓库货物', //贸易方式
      tradeModeCode: '1233',
      purpOrgCodeName: '', //检验检疫受理机关
      purpOrgCode: '',

      orgCodeName: '', //目的地检验检疫机关
      orgCode: '', //目的地检验检疫机关

      agentName: '上海翔运国际货运有限公司', //
      agentCode: '3109980208',
      agentScc: '91310109132269327E',
      declRegNo: '3100700361',
      cmbdecMergeListVo: [
        {
          destinationCountryName: '中国',
          destinationCountry: 'CHN',
          districtCodeName: '静安',
          districtCode: '31069',
          ciqDestCodeName: '上海市静安区',
          ciqDestCode: '310106',
          dutyModeName: '全免',
          dutyMode: '3',
        },
      ],
      cusLicenseListVo: [
        {
          acmpFormNo: 'QD2207',
          acmpFormName: '保税核注清单',
          acmpFormCode: 'a',
          acmpFormCodeName: '保税核注清单',
        },
      ],
    },
  },
  {
    templateName: '区间流转进特综大场',
    warehouseTemplate: {
      // destTargetStock: { id: '1', name: '大场基地' },
      // templateDefName: '一线进大场保税仓',
      // transitWarehouse: null,
      inExpType: '1', //一线进出
      businessTypecd: '3', //保税加工,
      mtpckEndprdTypecd: 'I', //料件
      ieTypecd: 'I', //进区
      supvModecd: '5015', //区内进料加工货物
      dclcusFlag: '2', //是 是否报关
      dclcusTypecd: '2', // 对应报关 报关类型
      districtCode: '3122S', // 境内目的地 洋山特殊综合保税区

      destinationNatcd: '142', //中国 最终目的国
      bizopEtpsNm: '上海飞机制造有限公司', // 经营企业
      bizopEtpsno: '3122S600J4', // 经营企业编码
      bizopEtpsSccd: '91310000132612172J', //   企业信用代码
      dclTypecd: '1', //确认类型 备案
      // 特殊业务类型
      allocationMode: '', //
    },
    baoguanTemplate: {
      cusIEFlag: 'E', // 出口报关单
      customMasterName: '洋山海关',
      customMaster: '2207',
      consignorCname: '上海飞机制造有限公司', //
      cnsnTradeScc: '91310000132612172J',
      cnsnTradeCode: '3122S600J4',

      manualNo: 'L2207D22A001', //备案号

      ownerName: '上海飞机制造公用型保税仓库',
      ownerScc: '91310000132612172J',
      ownerCiqCode: '3100707436',
      ownerCode: '311291D908', //

      tradeModeCodeName: '保税间货物', //贸易方式
      tradeModeCode: '1200',

      agentName: '上海翔运国际货运有限公司', //
      agentCode: '3109980208',
      agentScc: '91310109132269327E',
      declRegNo: '3100700361',
      cusTrafModeName: '特殊综合保税区', // 运输方式
      cusTrafMode: 'S',
      cusTradeCountryName: '中国', // 运抵国
      cusTradeCountry: 'CHN',
      cusTradeNationCodeName: '中国', //贸易国
      cusTradeNationCode: 'CHN',

      distinatePortName: '中国境内', //??? 离境口岸
      distinatePort: 'CHN000', //起运港

      transModeName: 'FOB',
      despPortCodeName: '上海', //离境口岸
      despPortCode: '310001', //离境口岸

      cmbdecMergeListVo: [
        {
          destinationCountryName: '中国', //最终目的国
          destinationCountry: 'CHN', //最终目的国
          districtCodeName: '静安', //境内货源地
          ciqDestCode: '310106',
          dutyModeName: '全免',
          dutyMode: '3',
        },
      ],
      cusLicenseListVo: [
        {
          acmpFormNo: 'QD2207',
          acmpFormName: '保税核注清单',
          acmpFormCode: 'a',
          acmpFormCodeName: '保税核注清单',
        },
        {
          acmpFormNo: '104623WH********',
          acmpFormName: '提发货凭证',
          acmpFormCode: 'T',
          acmpFormCodeName: '提发货凭证',
        },
      ],
    },
  },
]);

SpecialTypeClassTemplate.set('区间流转进祝桥', [
  {
    templateName: '区间流转进祝桥',
    warehouseTemplate: null, //无提发货凭证
    baoguanTemplate: {
      billType: '', //
      cusIEFlag: 'I', // 进口报关单
      customMasterName: '洋山海关',
      customMaster: '2207',
      consigneeCname: '上海飞机制造有限公司', //境内收发货人
      consigneeCode: '3100707436',
      rcvgdTradeCode: '3122S600J4',
      rcvgdTradeScc: '91310000132612172J',

      manualNo: 'L2207D22A001', //备案号

      ownerName: '上海飞机制造公用型保税仓库',
      ownerScc: '91310000132612172J',
      ownerCiqCode: '3100707436',
      ownerCode: '311291D908', //

      tradeModeCodeName: '保税仓库货物', //贸易方式
      tradeModeCode: '1233',
      purpOrgCodeName: '', //检验检疫受理机关
      purpOrgCode: '',

      orgCodeName: '', //目的地检验检疫机关
      orgCode: '', //目的地检验检疫机关

      agentName: '上海翔运国际货运有限公司', //
      agentCode: '3109980208',
      agentScc: '91310109132269327E',
      declRegNo: '3100700361',
      cmbdecMergeListVo: [
        {
          destinationCountryName: '中国',
          destinationCountry: 'CHN',
          districtCodeName: '静安',
          districtCode: '31069',
          ciqDestCodeName: '上海市静安区',
          ciqDestCode: '310106',
          dutyModeName: '全免',
          dutyMode: '3',
        },
      ],
      cusLicenseListVo: [
        {
          acmpFormNo: 'QD2207',
          acmpFormName: '保税核注清单',
          acmpFormCode: 'a',
          acmpFormCodeName: '保税核注清单',
        },
      ],
    },
  },
  {
    templateName: '区间流转进特综大场',
    warehouseTemplate: {
      // destTargetStock: { id: '1', name: '大场基地' },
      // templateDefName: '一线进大场保税仓',
      // transitWarehouse: null,
      inExpType: '1', //一线进出
      businessTypecd: '3', //保税加工,
      mtpckEndprdTypecd: 'I', //料件
      ieTypecd: 'I', //进区
      supvModecd: '5015', //区内进料加工货物
      dclcusFlag: '2', //是 是否报关
      dclcusTypecd: '2', // 对应报关 报关类型
      districtCode: '3122S', // 境内目的地 洋山特殊综合保税区

      destinationNatcd: '142', //中国 最终目的国
      bizopEtpsNm: '上海飞机制造有限公司', // 经营企业
      bizopEtpsno: '3122S600J4', // 经营企业编码
      bizopEtpsSccd: '91310000132612172J', //   企业信用代码
      dclTypecd: '1', //确认类型 备案
      // 特殊业务类型
      allocationMode: '', //
    },
    baoguanTemplate: {
      cusIEFlag: 'E', // 出口报关单
      customMasterName: '洋山海关',
      customMaster: '2207',
      consignorCname: '上海飞机制造有限公司', //
      cnsnTradeScc: '91310000132612172J',
      cnsnTradeCode: '3122S600J4',

      manualNo: 'L2207D22A001', //备案号

      ownerName: '上海飞机制造公用型保税仓库',
      ownerScc: '91310000132612172J',
      ownerCiqCode: '3100707436',
      ownerCode: '311291D908', //

      tradeModeCodeName: '保税间货物', //贸易方式
      tradeModeCode: '1200',

      agentName: '上海翔运国际货运有限公司', //
      agentCode: '3109980208',
      agentScc: '91310109132269327E',
      declRegNo: '3100700361',
      cusTrafModeName: '特殊综合保税区', // 运输方式
      cusTrafMode: 'S',
      cusTradeCountryName: '中国', // 运抵国
      cusTradeCountry: 'CHN',
      cusTradeNationCodeName: '中国', //贸易国
      cusTradeNationCode: 'CHN',

      distinatePortName: '中国境内', //??? 离境口岸

      distinatePort: 'CHN000', //起运港
      transModeName: 'FOB',
      despPortCodeName: '上海', //离境口岸
      despPortCode: '310001', //离境口岸

      cmbdecMergeListVo: [
        {
          destinationCountryName: '中国', //最终目的国
          destinationCountry: 'CHN', //最终目的国
          districtCodeName: '静安', //境内货源地
          ciqDestCode: '310106',
          dutyModeName: '全免',
          dutyMode: '3',
        },
      ],
      cusLicenseListVo: [
        {
          acmpFormNo: 'QD2207',
          acmpFormName: '保税核注清单',
          acmpFormCode: 'a',
          acmpFormCodeName: '保税核注清单',
        },
        {
          acmpFormNo: '104623WH********',
          acmpFormName: '提发货凭证',
          acmpFormCode: 'T',
          acmpFormCodeName: '提发货凭证',
        },
      ],
    },
  },
]);

SpecialTypeClassTemplate.set('二线出口进祝桥', [
  {
    templateName: '二线出口进祝桥',
    warehouseTemplate: {
      // destTargetStock: { id: '1', name: '大场基地' },
      // templateDefName: '一线进大场保税仓',
      // transitWarehouse: null,
      inExpType: '1', //一线进出
      businessTypecd: '3', //保税加工,
      mtpckEndprdTypecd: 'I', //料件
      ieTypecd: 'I', //进区
      supvModecd: '5015', //区内进料加工货物
      dclcusFlag: '2', //是 是否报关
      dclcusTypecd: '2', // 对应报关 报关类型
      districtCode: '3122S', // 境内目的地 洋山特殊综合保税区

      destinationNatcd: '142', //中国 最终目的国
      bizopEtpsNm: '上海飞机制造有限公司', // 经营企业
      bizopEtpsno: '3122S600J4', // 经营企业编码
      bizopEtpsSccd: '91310000132612172J', //   企业信用代码
      dclTypecd: '1', //确认类型 备案
      // 特殊业务类型
      allocationMode: '', //
    }, //无提发货凭证
    baoguanTemplate: {
      billType: '', //
      cusIEFlag: 'E', // 出口报关单
      customMasterName: '洋山特综', //申报地海关
      customMaster: '2249',
      iEPort: '2249',
      iEPortName: '洋山特综', //出境关别

      tradeModeCodeName: '一般贸易', //贸易方式
      tradeModeCode: '0110',

      distinatePort: 'CHN000', // 指运港
      distinatePortName: '中国境内',

      cusTradeCountry: 'CHN', //贸易国
      cusTradeCountryName: '中国',

      cutMode: '101',
      cutModeName: '一般征税', //征免性质

      despPortCode: '310001', //离境口岸
      despPortCodeName: '上海',

      agentName: '上海翔运国际货运有限公司', //
      agentCode: '3109980208',
      agentScc: '91310109132269327E',
      declRegNo: '3100700361',

      cmbdecMergeListVo: [
        {
          destinationCountryName: '中国',
          destinationCountry: 'CHN',

          dutyModeName: '照章',
          dutyMode: '1',
        },
      ],
      cusLicenseListVo: [
        {
          acmpFormNo: 'QD2207',
          acmpFormName: '保税核注清单',
          acmpFormCode: 'a',
          acmpFormCodeName: '保税核注清单',
        },
      ],
    },
  },
]);

/* 
  ,{
    templateName:'区间流转进大场',
    warehouseTemplate:{

    },
    baoguanTemplate:{

    }
  }]
  },
  {
    destTargetStock: { id: 'code大场', name: '大场基地' },
    templateDefName: '一线大场保税仓区间流转大场特殊监管区进仓',
    transitWarehouse: null,
    inExpType: '1', //一线进出
    businessTypecd: '3', //保税加工,
    mtpckEndprdTypecd: 'I', //料件
    ieTypecd: 'I', //进区
    supvModecd: '5015', //区内进料加工货物
    dclcusFlag: '2', //是 是否报关
    dclcusTypecd: '2', // 对应报关 报关类型
    districtCode: '3122S', // 境内目的地 洋山特殊综合保税区

    destinationNatcd: '142', //中国 最终目的国
    bizopEtpsNm: '上海飞机制造有限公司', // 经营企业
    bizopEtpsNo: '3122S600J4', // 经营企业编码
    bizopEtpsSccd: '91310000132612172J', //   企业信用代码
    dclTypecd: '1', //确认类型 备案
    // 特殊业务类型
    allocationMode: '', //

    // 报关单模版

    billType: '1', // 进出境备案清单
    cusIEFlag: 'I', // 进境报关单

    consigneeCname: '上海飞机制造有限公司', //境内收发货人
    consigneeCode: '3100707436',
    rcvgdTradeCode: '3122S600J4',
    manualNo: 'L2207D22A001',
    customMaster: '2207',

    customMasterName: '洋山海关', //
    ownerName: '上海飞机制造公用型保税仓库',
    ownerScc: '91310000132612172J',
    ownerCiqCode: '3100707436',
    ownerCode: '311291D908', //

    tradeModeCodeName: '保税仓库货物',
    tradeModeCode: '1233',
    purpOrgCodeName: '洋山海关本部',
    agentName: '上海翔运国际货运有限公司', //
    agentCode: '3109980208',
    agentScc: '91310109132269327E',
    declRegNo: '3100700361',
  },
  {
    destTargetStock: { id: 'code大场', name: '大场基地' },

    transitWarehouse: null,
    inExpType: '1', //一线进出
    businessTypecd: '3', //保税加工,
    mtpckEndprdTypecd: 'I', //料件
    ieTypecd: 'I', //进区
    supvModecd: '5015', //区内进料加工货物
    dclcusFlag: '2', //是 是否报关
    dclcusTypecd: '2', // 对应报关 报关类型
    districtCode: '3122S', // 境内目的地 洋山特殊综合保税区

    destinationNatcd: '142', //中国 最终目的国
    bizopEtpsNm: '上海飞机制造有限公司', // 经营企业
    bizopEtpsNo: '3122S600J4', // 经营企业编码
    bizopEtpsSccd: '91310000132612172J', //   企业信用代码
    dclTypecd: '1', //确认类型 备案
    // 特殊业务类型
    allocationMode: '', //
    billType: '1', // 进出境备案清单
    cusIEFlag: 'I', // 进境报关单
  },
]);
*/
/**
 * 先报进保税区 2207
 */
/*
SpecialTypeClassTemplate.set('二线进大场', {
  destTargetStock: { id: 'code大场', name: '大场基地' },

  transitWarehouse: null,
  inExpType: '1', //一线进出
  businessTypecd: '3', //保税加工,
  mtpckEndprdTypecd: 'I', //料件
  ieTypecd: 'I', //进区
  supvModecd: '5015', //区内进料加工货物
  // 报关单也是5015

  dclcusFlag: '2', //是 是否报关
  dclcusTypecd: '2', // 对应报关 报关类型
  districtCode: '3122S', // 境内目的地 洋山特殊综合保税区

  destinationNatcd: '142', //中国 最终目的国
  bizopEtpsNm: '上海飞机制造有限公司', // 经营企业
  bizopEtpsNo: '3122S600J4', // 经营企业编码
  bizopEtpsSccd: '91310000132612172J', //   企业信用代码
  dclTypecd: '1', //确认类型 备案
  // 特殊业务类型
  allocationMode: '', //
});

// 一线进口 报关单 报2207 关,监管方式 保税仓库货物 1233
// 2207-2249 出口报关单 保税间, 提发货凭证 保税间

SpecialTypeClassTemplate.set('二线进祝桥', {
  destTargetStock: { id: 'code浦东', name: '浦东祝桥基地' },

  transitWarehouse: null,
  inExpType: '2', //一线进出
  businessTypecd: '3', //保税加工,
  mtpckEndprdTypecd: 'I', //料件
  ieTypecd: 'I', //进区
  supvModecd: '', //料件进出区

  dclcusFlag: '2', //是 是否报关
  dclcusTypecd: '2', // 对应报关 报关类型
  districtCode: '3122S', // 境内目的地 洋山特殊综合保税区

  destinationNatcd: '142', //中国 最终目的国
  bizopEtpsNm: '上海飞机制造有限公司', // 经营企业
  bizopEtpsNo: '3122S600J4', // 经营企业编码
  bizopEtpsSccd: '91310000132612172J', //   企业信用代码
  dclTypecd: '1', //确认类型 备案
  // 特殊业务类型
  allocationMode: '', //
  billType: '', // 进出境备案清单
  // 一般贸易

  cusIEFlag: 'E', // 出口报关单
});
SpecialTypeClassTemplate.set('区间进祝桥', {
  destTargetStock: { id: 'code浦东', name: '浦东祝桥基地' },

  transitWarehouse: null,
  inExpType: '2', //一线进出
  businessTypecd: '3', //保税加工,
  mtpckEndprdTypecd: 'I', //料件
  ieTypecd: 'I', //进区
  supvModecd: '5015', //区内进料加工货物
  dclcusFlag: '2', //是 是否报关
  dclcusTypecd: '2', // 对应报关 报关类型
  districtCode: '3122S', // 境内目的地 洋山特殊综合保税区

  destinationNatcd: '142', //中国 最终目的国
  bizopEtpsNm: '上海飞机制造有限公司', // 经营企业
  bizopEtpsNo: '3122S600J4', // 经营企业编码
  bizopEtpsSccd: '91310000132612172J', //   企业信用代码
  dclTypecd: '1', //确认类型 备案
  // 特殊业务类型
  allocationMode: '', //
  billType: '', // 进出境备案清单
  cusIEFlag: 'E', // 出口报关单
  transMode: '', //报关单号
  transModeName: '',
  //报关单监管方式
});
SpecialTypeClassTemplate.set('区间进进大场', {});

// 先报进嘉定保税仓 再区间进祝桥
SpecialTypeClassTemplate.set('中转进祝桥', {});
// 先报进嘉定保税仓 再区间进大场
SpecialTypeClassTemplate.set('中转进大场', {});
*/
