import { NzUploadFile } from 'ng-zorro-antd/upload';

export enum ConditionControlType {
  Textbox = 'textbox',
  DropDownBox = 'dropdownbox',
}

export interface IConditionFormControl {
  key: string;
  label: string;
  options?: { key: string; value: string }[];
  order: number;
  value: string;
  required: boolean;
  type: string;
  controlType: ConditionControlType;
}

export enum EnumColType {
  String = 'String',
  Date = 'Date',
  JSON = 'JSON',
  Array = 'Array',
}

export interface IColDef {
  key: string;
  caption: string;
  colWidth: number;
  colType: EnumColType;
  subKey: string;
  displayLen: number;
  keyField?: boolean;
  label: string;
  order: number;
  value: any;
  required: boolean;
  type: string;
  controlType: ConditionControlType;
}

export interface IListItem {
  id: string;
  key: string;
  lastUpdateDate: Date;
}


export interface IFileUpload extends NzUploadFile {
  url?: string;
  ossKey?: string;
}
