import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Apollo, gql } from 'apollo-angular';
import { IMenu } from '@ternsorinfo/data';

import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class JudgeRoleGQLService {
  constructor(private apollo: Apollo,private messageService:NzMessageService) {}
  judgeUrlRole(url: string) {
    return this.apollo
      .watchQuery({
        query: gql`
          query judgeRoleUrl($url: String) {
            judgeRoleUrl(url: $url)
          }
        `,

        variables: { url },
      })
      .valueChanges.pipe(
        tap((value) => {
          console.log(`fetch getlist`);
        },
        (error) => {
          console.warn('Error = ', error);
          this.messageService.error("请重新登录!");
        }),

        map((result) => result && result.data && result.data['judgeRoleUrl'])
        //catchError(this.handleError<CpcCreditDetail[]>(`gettest`));
       
      ) 
  }
  judgeRoleMenu(menuDef: IMenu[]) {
    return this.apollo
      .watchQuery({
        query: gql`
          query judgeRoleMenu($menuDef: JSON) {
            judgeRoleMenu(menuDef: $menuDef)
          }
        `,

        variables: { menuDef },
      })
      .valueChanges.pipe(
        tap((value) => {
          console.log(`fetch getlist`);
        }),

        map((result) => result && result.data && result.data['judgeRoleMenu'])

        // catchError(this.handleError<CpcCreditDetail[]>(`gettest`))
      );
  }
}
